import React, { useEffect, useState } from "react";
import { handleDateFormat } from "../../_modules/date";
import { OrderLocationService } from "../../_services/order_location";
import {
  Tabs,
  Row,
  Col,
  Pagination,
  Spin,
  Card, DatePicker, InputNumber, Button
} from "antd";
import {
  svgTabOne,
  svgTabTwo,
  svgHome,
  svgFalseAlarm,
} from "../../static/images/orders_customerlocation";
import { CaretUpOutlined, CaretDownOutlined, ReloadOutlined, CheckCircleOutlined } from "@ant-design/icons";
import "../../static/pages/orders/customerlocation.css";
import { openNotificationWithIcon } from '../../_modules/notification.js';
import ModalModify from "../../components/orders/modalmodify";
import moment from 'moment'
import { humanize } from "../../_modules/humanize";
import FrzDynamicDownload from '../../_controls/FRZDynamicDownload';

const orderLocationService = new OrderLocationService();

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const CustomerLocation = () => {
  const [currentTab, setCurrentTab] = useState("1");
  const [allData, setAllData] = useState(false);
  const [orderOfData, setOrderOfData] = useState("descending");
  const [flagData, setFlagData] = useState(false);
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const [rowData, setRowData] = useState(false)
  const [disable, setDisable] = useState(true)
  const [isReset, setIsReset] = useState(false)
  const [accessModify, setAccessModify] = useState(false)
  const [state, setState] = useState({
    start_date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD'), showDateValue: true, valueByMobNo: undefined, orderId: undefined
  });
  const { start_date, end_date, showDateValue, valueByMobNo, orderId } = state

  useEffect(() => {
    if (currentTab && !isReset) {
      getAllOrders();
    }
  }, [currentTab, current, isReset]);

  useEffect(() => {
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['customer_address_property_editor', 'developer_admin', 'super_admin'].includes(data)) {
        setAccessModify(true)
      }
    })
  }, [])

  function onChangeRangePicker(value, dateString) {
    setState(prevState => ({ ...prevState, showDateValue: true, start_date: dateString[0], end_date: dateString[1] }))
    setDisable(false)
    setCurrent(1)
  }

  // ------------------------ get orders functions -------------------------------
  function getAllOrders() {
    setAllData(false)
    setIsReset(true)
    const payload = {
      type: currentTab == 1 ? "created" :
        currentTab == 2 ? "resolved" :
          currentTab == 3 && "false_alarm",
      page: current,
      valueByMobNo: valueByMobNo ? valueByMobNo : '',
      orderId: orderId ? orderId : '',
      start_date: start_date ? start_date : '',
      end_date: end_date ? end_date : ''
    };

    orderLocationService.get_orders_flagged(payload).subscribe(
      (r) => {
        let data = r.response.data;
        data = data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setAllData(data);
        setTotal(r.response.meta.total_pages * 50)
        let meta = r.response.meta;
        setFlagData(r.response.flags_count);
      },
      (err) => {
        console.log(err);
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      }
    );
  }

  function disabledDate(current) {
    // Can select days last 2 months
    return current && (current < moment().subtract(61, "day") || current > moment().add(0, "day"));
  }

  // --------------------------- Tab Layouts --------------------------------------
  function callback(key) {
    setIsReset(false)
    setCurrent(1)
    setCurrentTab(key);
  }

  function pagination(page) {
    setIsReset(false)
    setCurrent(page)
  }


  const localtionGap = () => {
    return (
      <div className="frz-dis-flex tab-div">
        <div className="frz-dis-flex">
          <div>{svgTabOne()}</div>
          <div className="frz-fw-600 tab-title">Location gap</div>
          <div className="frz-clr-red frz-lm-5 frz-fw-600 tab-fs-18">
            ({flagData.created ? flagData?.created : "0"})
          </div>
        </div>
      </div>
    );
  };

  const rectified = () => {
    return (
      <div className="frz-dis-flex tab-div">
        <div className="frz-dis-flex">
          <div>{svgTabTwo()}</div>
          <div className="frz-fw-600 tab-title">Rectified</div>
          <div className="frz-clr-green frz-lm-5 frz-fw-600 tab-fs-18">
            ({flagData.resolved ? flagData?.resolved : "0"})
          </div>
        </div>
      </div>
    );
  };

  const falseAlarm = () => {
    return (
      <div className="frz-dis-flex tab-div">
        <div className="frz-dis-flex">
          <div>{svgFalseAlarm()}</div>
          <div className="frz-fw-600 tab-title">False Alarm</div>
          <div className="frz-clr-red frz-lm-5 frz-fw-600 tab-fs-18">
            ({flagData.false_alarm ? flagData?.false_alarm : "0"})
          </div>
        </div>
      </div>
    );
  };

  // handleSort function for both tables, called onClick with respective arguments.
  const handleSort = (data, setData, order, setOrder) => {
    if (order === "descending") {
      let newData = data.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
      setData(newData);
      setOrder("ascending");
    } else if (order === "ascending") {
      let newData = data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      setData(newData);
      setOrder("descending");
    }
  };

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }

  const onModify = (value, rowdata) => {
    setShowModal(value)
    setRowData(rowdata)
  }

  function reset() {
    setState({
      start_date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD'), showDateValue: true, valueByMobNo: undefined, orderId: undefined
    })
    setDisable(true)
    setCurrent(1)
    setAllData(false)
    setIsReset(false)
  }

  // Modify - Modal
  return (
    <div className="layer frz-tab-no-margin">
      <Tabs
        activeKey={currentTab}
        onChange={callback}
        className="frz-location-tabs">
        <TabPane tab={localtionGap()} key="1" />
        <TabPane tab={rectified()} key="2" />
        <TabPane tab={falseAlarm()} key="3" />
      </Tabs>
      <Card className="frz-location-border-card">
        <Row>
          <Col span={24}>
            <div className="frz-dis-flex frz-justify-sb">
              <div>
                <RangePicker onChange={onChangeRangePicker} className="frz-m-10"
                  value={showDateValue ? [moment(start_date, dateFormat),
                  moment(end_date, dateFormat)] : ''} format={dateFormat}
                  disabledDate={disabledDate}
                  allowClear={false}
                />

                <InputNumber
                  placeholder="Mobile No"
                  onChange={(e) => onChange(e, "valueByMobNo")}
                  value={valueByMobNo}
                  className="frz-width-150p frz-m-10" />

                <InputNumber
                  placeholder="Order No"
                  onChange={(e) => onChange(e, "orderId")}
                  value={orderId}
                  className="frz-width-150p frz-m-10" />

                <Button disabled={disable} onClick={getAllOrders}
                  className="frz-w-100px frz-m-10" type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
              </div>
              <span className="fl-right">
                <FrzDynamicDownload name={'Reports'} fileName={'Reports'} url={'admin_dashboard/v1/downloads/customer_address_flag_report'}
                  payload={`?type=${currentTab == 1 ? "created" : currentTab == 2 ? "resolved" : currentTab == 3 && "false_alarm"}&order_number=${orderId ?? ''}&mobile_number=${valueByMobNo ?? ''}&from_date=${start_date ?? ''}&to_date=${end_date ?? ''}`} type={'job'} reportFunc={'fraazo_report'} jobReportFunc={'download_reports_job_fraazo'}
                  base={'fraazo'} />
              </span>
            </div>
          </Col>
        </Row>
      </Card>
      {allData ?
        allData.length ?
          <div>
            <div>
              <div className="frz-tm-10 location-table-head">
                <Row className="location-table-head-row" gutter={[10, 0]}>
                  <Col
                    onClick={() => {
                      handleSort(
                        allData,
                        setAllData,
                        orderOfData,
                        setOrderOfData
                      );
                    }}
                    className="frz-cursor-pointer"
                    span={2}
                  >
                    Date
                    <span>
                      {orderOfData === "ascending" ? (
                        <CaretUpOutlined />
                      ) : (
                        <CaretDownOutlined />
                      )}
                    </span>
                  </Col>
                  <Col span={3}>
                    <div>Customer Name</div>
                  </Col>
                  <Col span={2}>
                    <div>Mobile No.</div>
                  </Col>
                  <Col span={2}>
                    <div>Order No.</div>
                  </Col>
                  <Col span={3}>
                    <div>Customer Address</div>
                  </Col>
                  <Col span={4}>
                    <div>Flat Address</div>
                  </Col>
                  <Col span={3}>
                    <div>Delivered Location</div>
                  </Col>
                  <Col span={2} style={{ textAlign: "center" }}>
                    <div>Status</div>
                  </Col>
                  {currentTab !== "1" ?
                    <Col span={3} style={{ textAlign: "center" }}>
                      Approved by
                    </Col>
                    : null}
                </Row>
              </div>
              <div className="location-table-body-main">
                {allData.map((data, index) => {
                  return (
                    <Row key={index} className="location-table-body-row" gutter={[10, 0]}>
                      <Col span={2}>{handleDateFormat(data.created_at)}</Col>
                      <Col span={3}>{data.customer_name}</Col>
                      <Col span={2}>{data.mobile_number}</Col>
                      <Col span={2}>{data.order_number}</Col>
                      <Col span={3}>
                        <div className="red-coordinates">
                          <div>Lat: {data.customer_address.latitude}</div>
                          <div>Lng: {data.customer_address.longitude}</div>
                        </div>
                        {data.corrected_by == "customer_address" && currentTab == 2
                          ?
                          <CheckCircleOutlined />
                          : null}
                      </Col>
                      <Col span={4}>
                        <div>{data.flat_address}</div>
                        {data.corrected_by == "flat_address" && currentTab == 2
                          ?
                          <CheckCircleOutlined />
                          : null}
                      </Col>
                      <Col span={3}>
                        <div className="delivered-location-flex">
                          <div className="green-coordinates">
                            <div>Lat: {data.delivered_address.latitude}</div>
                            <div>Lng: {data.delivered_address.longitude}</div>
                            {data.corrected_by == "delivered_address" && currentTab == 2
                              ?
                              <CheckCircleOutlined />
                              : null}
                          </div>
                          <div className="svgHome">{svgHome()}</div>
                        </div>
                      </Col>
                      <Col span={3} style={{ textAlign: "center" }}>
                        Delivered {" "}
                        {humanize(data?.delivered_via ? data.delivered_via : "")}
                      </Col>
                      {
                        currentTab == "1" ?
                          <Col span={2}>
                            <button className={accessModify ? "modify_btn" : "modify_btn_dis"} onClick={() => onModify(true, data)} disabled={!accessModify}>
                              Modify
                            </button>
                          </Col>
                          : null
                      }
                      {
                        currentTab !== "1" ?
                          <Col span={2}>
                            <div style={{ wordBreak: "break-word" }}>
                              {data?.approved_by?.email}
                            </div>
                          </Col>
                          : null
                      }
                    </Row>
                  );
                })}
              </div>

              <div className="frz-tm-15">
                <Pagination current={current}
                  pageSize={50} total={total} onChange={pagination}
                  showSizeChanger={false} />
              </div>
            </div>
          </div>
          :
          <div className="no-data">No Data Available</div>
        : <div className="spin-center">
          <Spin tip="...Loading" />
        </div>}
      {
        showModal ?
          <ModalModify showModal={showModal} setShowModal={(value) => setShowModal(value)} rowData={rowData}
            getAllOrders={getAllOrders} />
          :
          null
      }
    </div >
  );
};

export default CustomerLocation;
