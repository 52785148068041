import React, { useState, useEffect } from 'react'
import { Modal, Form, Tabs } from 'antd';
import FRZInput from '../../../../_controls/FRZInput';
import FRZSelect from '../../../../_controls/FRZSelect';
import FRZCustomRadioButon from '../../../../_controls/FRZCustomRadioButon';
import FRZRadioButton from '../../../../_controls/FRZRadioButton';
import FRZUpload from '../../../../_controls/FRZUpload';
import { formItemLayout } from '../../../../_modules/controllayout';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { PaymentsService } from "../../../../_services/payments";
import PaymentTabsChild from './tabs';
import { openNotificationWithIcon } from '../../../../_modules/notification';
import { humanize } from '../../../../_modules/humanize';

const payments_service = new PaymentsService();

const PGGateway = ({ PGActivationType, setPGAType, isSubmit, setIsSubmit, statusData, PGAType, rowData, activePayments,
  payType, activeParent, setActivePayments, getPayments }) => {
  const { TabPane } = Tabs;

  const onPGActivationTabChange = (value) => {
    // console.log("value", value)
    setPGAType(value)
  }

  return (
    <div>
      <Tabs defaultActiveKey={PGActivationType[0]} onChange={onPGActivationTabChange}>
        {PGActivationType.map((item, index) => {
          return (
            <TabPane tab={humanize(item)} key={item}>
              <PaymentTabsChild item={item} statusData={statusData} PGActivationType={PGActivationType} name={item}
                isSubmit={isSubmit} setIsSubmit={(value) => setIsSubmit(value)} PGAType={PGAType} rowData={rowData}
                payType={payType} activeParent={activeParent} setActivePayments={(value) => setActivePayments(value)}
                activePayments={activePayments} getPayments={getPayments} />
            </TabPane>
          )
        })}
      </Tabs>
    </div>
  )
}

export default PGGateway
