import React, { useEffect, useState, useRef } from "react";
import { DarkstoreService } from '../../_services/darkstore';
import { ReloadOutlined } from "@ant-design/icons";
import { Row, Card, Col, Button, Table, Spin, DatePicker } from "antd";
import { overageColumns } from '../../components/overage/overageColumns';
import Search from '../../components/antd/search';
import moment from 'moment';
import OverageNestedTable from "../../components/overage/OverageNestedTable";
import { overageNestedColumns, skuColumns } from "../../components/overage/overageNestedCol";
import OverageModal from "../../components/overage/OverageModal";
import FrzDynamicDownload from '../../_controls/FRZDynamicDownload';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const darkstore_service = new DarkstoreService()

const initialFilters = {
  page: 1,
  isSearch: true,
  valueBySource: undefined,
  start_date: '',
  end_date: '',
  showDateValue: false,
}

const initialState = {
  btnDisable: true,
  spinner: false,
  err: false
}

function Overage() {
  const modalRef = useRef(null);
  const [tableData, setTableData] = useState(false)
  const [filters, setFilters] = useState(initialFilters)
  const [state, setState] = useState(initialState)
  const [disable, setDisable] = useState(true)
  const [nestedCol, setNestedCol] = useState(false);
  const [skuCol, setSkuCol] = useState(false);
  const [id, setId] = useState(false);

  useEffect(() => {
    filters.isSearch && getTableList()
  }, [filters])

  useEffect(() => {
    getNestedCol();
  }, [])


  function getTableList() {
    setState(prevState => ({ ...prevState, spinner: true }))
    let urlParam = `?by_darkstores=${filters.valueBySource === undefined ? '' : filters.valueBySource
      }&start_date=${filters.start_date === '' ? '' : filters.start_date}&end_date=${filters.end_date === '' ? '' : filters.end_date}`

    darkstore_service.get_darkstore_level_view(urlParam).subscribe((r) => {
      setState(prevState => ({ ...prevState, spinner: false, }))
      setTableData(r.response.data)
    }, (err) => {
      setState(prevState => ({ ...prevState, spinner: false, err: true }))
      setTableData([])
    })
  }

  function getNestedCol() {
    setNestedCol(overageNestedColumns);
    setSkuCol(skuColumns);
  }
  function onChange(value, type, searchNow) {
    setFilters(prevState => ({ ...prevState, [type]: value, isSearch: searchNow }))
    setState(prevState => ({ ...prevState, btnDisable: false }))
    setDisable(false)
  }

  function onChangeRangePicker(value, dateString) {
    setFilters(prevState => ({ ...prevState, showDateValue: true, start_date: dateString[0], end_date: dateString[1], isSearch: false }))
    setState(prevState => ({ ...prevState, btnDisable: false, }))
    setDisable(false)
  }


  function reset() {
    setFilters({
      valueBySource: undefined, showDateValue: false, start_date: '',
      end_date: '', page: 1, isSearch: true
    })
    setState(prevState => ({ ...prevState, btnDisable: false, spinner: false }))
    setDisable(true)
  }

  function expandedRow(record) {
    return (
      <OverageNestedTable nestedTableData={record} overageNestedColumns={overageNestedColumns}
        showModalOverage={showModalOverage} nestedCol={nestedCol} filters={filters}
      />
    )
  }

  function showModalOverage(data, type) {
    setId(data);
    modalRef.current.showModal(type, data)
  }

  function disabledDate(current){
    return current && current > moment().add(0, "day");
  }

  return (
    <div className="layer">
      {tableData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Darkstore" value={filters.valueBySource}
                  onChange={(e) => onChange(e, 'valueBySource')} type="valueByDarkstore" />
                <RangePicker onChange={onChangeRangePicker} className="frz-m-10"
                  value={filters.showDateValue ? [moment(filters.start_date, dateFormat),
                  moment(filters.end_date, dateFormat)] : ''} format={dateFormat}
                  allowClear={false} disabledDate={disabledDate} />
                <Button disabled={disable} onClick={getTableList} className="frz-w-100px frz-m-10"
                  type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} className="frz-m-10"
                  type="primary" ghost shape="circle" icon={<ReloadOutlined />} />
                <span className="fl-right">
                  <FrzDynamicDownload name="Reports" reportFunc={'get_download'}
                    url={`admin_dashboard/v1/downloads/overage_report?by_darkstore=${filters.valueBySource === undefined ? '' : filters.valueBySource}&start_date=${filters.start_date}&end_date=${filters.end_date}`}
                    type={'job'} jobReportFunc={'download_reports_job_fraazo'} base={'fraazo'} />
                </span>
              </Col>
            </Card>
          </Row>
          {tableData.length > 0 && !state.err ?
            <Spin spinning={state.spinner}>
              <Row>
                <Col span={24}>
                  <Card className="frz-mt-5">
                    <Table dataSource={tableData} pagination={false} scroll={{ y: 'calc(100vh - 270px)' }} rowKey={'darkstore_id'}
                      columns={overageColumns((rowdata) => rowdata, setState)} expandedRowRender={expandedRow}
                    />
                  </Card>
                </Col>
              </Row>
            </Spin>
            :
            <div className="no-data">{state.err ? 'Backend API System Down' : 'No Data Available'}</div>
          }
          <OverageModal ref={modalRef} id={id} skuCol={skuCol} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}
export default Overage