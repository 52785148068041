import { Http } from '../_modules/http'

export class AppService {

  http = new Http()

  get_app_version(payload){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/get_app_versions?page='
                + payload.page
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  create_app_version(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/admin/create_app_versions'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload, headers)
  }

  activate_version(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/admin/activate_version'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers)
  }

  get_test_account(){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/testing_accounts'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  create_test_account(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/admin/testing_accounts'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload, headers)
  }

  update_test_account(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/admin/reset_account'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers)
  }

}
