import React, { useEffect, useState } from "react";
import { Steps, Card, Row, Button, Spin, Typography, notification, Popconfirm } from "antd";

import { DemandForecastingService } from "../../_services/demand_forecasting";
import EligibleSkusModal from "../../components/master/demand_forecasting/eligible_skus_modal";

import { LoadingOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;

const { Step } = Steps;

const { Text } = Typography;

const demand_forecasting_service = new DemandForecastingService();

function DemandForecasting() {
  const [spinEnable, setSpinEnable] = useState(true);
  const [errorEnabled, setErrorEnabled] = useState(false);
  const [renderData, setRenderData] = useState(false);
  const [demandForecastingData, setDemandForecastingData] = useState([]);
  const [triggerButtonEnabled, setTriggerButtonEnabled] = useState(false);
  const [current, setCurrent] = React.useState(0);
  const [completeEnabled, setCompletedEnabled] = React.useState(false);
  const [displaySkusEnabled, setDisplaySkusEnabled] = useState(false);
  const [skusModalEnabled, setSkusModalEnabled] = useState(false);
  const [subModulesData, setSubModulesData] = useState([]);

  useEffect(() => {
    if (!renderData) {
      setRenderData(true);
      getDemandForecastingModules();
    }
  });

  function getDemandForecastingModules() {
    setSpinEnable(true);
    setDemandForecastingData([]);
    demand_forecasting_service.get_demand_forecasting_modules().subscribe(
      (r) => {
        let responseData = r.response.data;
        // console.log(r.response.data);
        if (responseData.length > 0) {
          setCurrentStepOnResponse(responseData);
          setDemandForecastingData(responseData);
          responseData[current].is_trigger_completed = false;
          setSpinEnable(false);
        }
        setErrorEnabled(false);
      },
      (err) => {
        console.log(err);
        setErrorEnabled(true);
        setSpinEnable(false);
      }
    );
  }

  function setCurrentStepOnResponse(responseData) {
    let dataIndexedLength = responseData.length - 1;
    for (let i = dataIndexedLength; i >= 0; i--) {
      if (responseData[i].is_completed) {
        if (dataIndexedLength == i) {
          setCompletedEnabled(true);
          setCurrent(-1);
        } else {
          setCurrent(i + 1);
          setSubModulesData(responseData[i+1].sub_modules)
        }
        break;
      }
    }
  }

  function onTriggerForecasting() {
    setTriggerButtonEnabled(true);
    var myVar = setInterval(() => {
      getSubmodules(null)
    }, 3000);
    demand_forecasting_service.update_demand_forecasting(current).subscribe(
      (r) => {
        /*  const linkSource = `data:application/octet-stream;base64,${r.response.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `${demandForecastingData[current].module_name}_Sheet_${(new Date).toDateString().replace(/ /g,'_')}.csv`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.target = '_blank'
        downloadLink.click();
        downloadLink.remove() */
        clearInterval(myVar)
        getSubmodules(null)
        demandForecastingData[current].is_trigger_completed = true;
        setTriggerButtonEnabled(false);

        // step 5
        if (current == 4) {
          setDisplaySkusEnabled(true);
          downloadCSV(r.response.data);
        } else {
          downloadCSV(r.response.data);
        }
      },
      (err) => {
        console.log(err);
        clearInterval(myVar)
        openNotificationWithIcon("error", err.response.errors[0]);
        setTriggerButtonEnabled(false);
      }
    );
  }

  const onDone = () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      console.log("development enviroment so not calling api... :(");
      demandForecastingData[current].is_approved = true;
      getDemandForecastingModules();
      return;
    }

    let csvURL = demandForecastingData[current].csv_data_url;
    demand_forecasting_service.post_upload_zapper_bulk_orders(csvURL).subscribe(
      (_) => {
        demandForecastingData[current].is_approved = true;
        getDemandForecastingModules();
      },
      (err) => {
        console.log(err);
        openNotificationWithIcon("error", err.response.errors[0]);
      }
    );
  };

  function downloadCSV(csvURL) {
    window.open(csvURL, "_blank");
    openNotificationWithIcon("success", "Downloaded Successfully");
    setTriggerButtonEnabled(false);
    demandForecastingData[current].csv_data_url = csvURL;
  }

  function openNotificationWithIcon(type, msg) {
    notification[type]({
      message: type.toUpperCase(),
      duration: 3,
      description: msg,
    });
  }

  const onChange = (currentIndex) => {
    setCompletedEnabled(false);
    if (current == -1) {
      setCurrent(currentIndex);
      getSubmodules(currentIndex)
      return;
    }
    if (triggerButtonEnabled) {
      openNotificationWithIcon("info", "Please wait while it's processing.");
    } else if (
      currentIndex > current &&
      !demandForecastingData[currentIndex > 0 ? currentIndex - 1 : 0].is_completed
    ) {
      openNotificationWithIcon("info", "Please complete previous steps first.");
    } else {
      setCurrent(currentIndex);
      getSubmodules(currentIndex)
    }
  };

  function getSubmodules(indexVal){
    demand_forecasting_service.get_submodule_status(indexVal !== null ? demandForecastingData[indexVal].id : demandForecastingData[current].id).subscribe(
      (r) => {
        setSubModulesData(r.response.data[0].sub_modules)
      },
      (err) => {
        console.log(err);
        openNotificationWithIcon("error", err.response.errors[0]);
      }
    );
  }

  function handleCancelModal(csvURL) {
    setSkusModalEnabled(false);
    console.log(csvURL);
    if (csvURL) {
      downloadCSV(csvURL);
    }
  }

  function placeSocietyOrder() {
    demand_forecasting_service.place_society_order().subscribe(
      (r) => {
        downloadCSV(r.response.data)
        getDemandForecastingModules()
      },
      (err) => {
        console.log(err);
        openNotificationWithIcon("error", err.response.errors[0]);
      }
    );
  }

  function clusterAllocationSheet() {
    demand_forecasting_service.cluster_allocation_sheet().subscribe(
      (r) => {
        downloadCSV(r.response.data)
        // getDemandForecastingModules()
      },
      (err) => {
        console.log(err);
        openNotificationWithIcon("error", err.response.errors[0]);
      }
    );
  }

  return (
    <div className="layer-nofilter">
      <Card>
        {spinEnable ? (
          <div className="spin-center">
            <Spin tip="...Loading" />
          </div>
        ) : errorEnabled ? (
          <div className="spin-center">
            <span> Something went wrong. Please try again.</span>
            <div style={{ margin: 20 }}>
              <Button key="retry" onClick={getDemandForecastingModules}>
                Retry
              </Button>
            </div>
          </div>
        ) : (
          <Row style={{ padding: 30 }}>
            <Steps
              direction="horizontal"
              size="small"
              current={current}
              onChange={onChange}
              labelPlacement="vertical"
              style={{ marginBottom: 50 }}
            >
              {demandForecastingData.map((data, index) => (
                <Step
                  key={`df_step${index}`}
                  title={data.module_name}
                  subTitle={
                    <Text style={{ color: "#4FBB90" }}>
                      {data.is_completed ? "Completed" : "-"}
                    </Text>
                  }
                  status={
                    current == index
                      ? "process"
                      : data.is_completed
                      ? "finish"
                      : "wait"
                  }
                />
              ))}
            </Steps>
            <Card
              style={{
                textAlign: "center",
                marginLeft: 50,
                marginRight: 50,
                padding: 20,
                backgroundColor: "#FAFAFA",
              }}
            >
              {completeEnabled ? (
                <span style={{ fontSize: 40, margin: "auto" }}>
                  <b> Completed</b>
                </span>
              ) : (
                <div>
                  <span style={{ fontSize: 25, margin: "auto" }}>
                    <b> {demandForecastingData[current].module_name}</b>
                  </span>
                  <div style={{marginTop: 20 }}>
                    {subModulesData.map((data, index) => {
                      return <div key={index} style={{marginBottom: 10}}>
                                {data.sub_module_name}
                                {triggerButtonEnabled || demandForecastingData[current].is_trigger_completed ? 
                                <span style={{marginLeft: 20}} className="frz-green">
                                  {data.status === 'incomplete' ? 
                                    <Spin indicator={antIcon}></Spin>
                                  : (data.status === 'completed' ? <CheckCircleOutlined /> : <CloseCircleOutlined style={{color: 'red'}} />)}
                                </span> : null}
                             </div>
                    })}
                  </div>
                  {current == 4 ? (
                    <div style={{ margin: "20px" }}>
                      {displaySkusEnabled ? (
                        <Button
                          type="primary"
                          onClick={() => {
                            setSkusModalEnabled(true);
                          }}
                        >
                          Display Eligible SKUs
                        </Button>
                      ) : null}
                    </div>
                  ) : null}
                  <div className="spin-center">
                    <div className="steps-action">
                      <Button
                        style={{ margin: "0 8px" }}
                        onClick={onTriggerForecasting}
                        loading={triggerButtonEnabled}
                      >
                        Trigger {demandForecastingData[current].module_name}
                      </Button>

                      <Button
                        type="primary"
                        disabled={
                          triggerButtonEnabled ||
                          !demandForecastingData[current].is_trigger_completed
                        }
                        style={{ margin: "0 8px" }}
                        onClick={() =>
                          current === demandForecastingData.length - 1
                            ? onDone()
                            : getDemandForecastingModules()
                        }
                      >
                        {current === demandForecastingData.length - 1
                          ? "Approve & Submit"
                          : "Approve & Next"}
                      </Button>
                      {current == 4 ? 
                        <>
                          <Popconfirm
                            title="Are you sure you have checked eligible skus and want to place society order ?"
                            onConfirm={placeSocietyOrder}
                            okText="Yes"
                            cancelText="No"
                            placement="leftTop"
                            disabled={
                              triggerButtonEnabled ||
                              !demandForecastingData[current].is_trigger_completed
                            }
                          >
                            <Button
                            type="primary"
                            style={{ margin: "0 8px" }}
                            disabled={
                              triggerButtonEnabled ||
                              !demandForecastingData[current].is_trigger_completed
                            }
                            >
                              Place Society Order & Next
                            </Button>
                          </Popconfirm>
                          <Button
                            type="primary"
                            style={{ margin: "0 8px" }}
                            disabled={
                              triggerButtonEnabled ||
                              !demandForecastingData[current].is_trigger_completed
                            }
                            onClick={clusterAllocationSheet}
                          >
                              Generate Cluster Allocation Sheet
                          </Button>
                        </>
                        : null
                      }
                    </div>
                  </div>
                </div>
              )}
            </Card>
            ̵
          </Row>
        )}
      </Card>
      {skusModalEnabled ? (
        <EligibleSkusModal
          handleCancelModal={handleCancelModal}
        ></EligibleSkusModal>
      ) : null}
    </div>
  );
}

export default DemandForecasting;
