import { TOGGLE_LOGIN, DARKSTORE, CUSTOMER_TYPE, CITY } from "../_constants"

const initialState = {
  isAuthenticated: false,
  darkstore: false,
  customer_type: false,
  city: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LOGIN:
      return { ...state, isAuthenticated: action.payload.value };
    case DARKSTORE:
      return { ...state, darkstore: action.payload.value };
    case CUSTOMER_TYPE:
      return { ...state, customer_type: action.payload.value };      
    case CITY:
      return { ...state, city: action.payload.value };  
    default:
      return state;
  }
};
