import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification, Tag } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {AdsService} from '../../_services/ads';
import MainTable from '../../components/antd/table';
import Search from '../../components/antd/search';
import {discountPerformanceColumns} from '../../components/antd/columns/ads';
import {handleDateFormat} from '../../_modules/date';
import {getStaticTime} from '../../_modules/time';
import moment from 'moment';
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const ads_service = new AdsService()

function DiscountPerformance(){
  const modalRef = useRef(null);
  const [performanceData, setPerformanceData] = useState(false)
  const [discountTypeData, setDiscountTypeData] = useState(false)
  const [statsData, setStatsData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [state, setState] = useState({valueByType: undefined, valueByCode: undefined, 
                                      start_date: '', end_date: '', showDateValue: false});
  const { valueByType, valueByCode, start_date, end_date, showDateValue} = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getDiscountPerformance()
    }
  })

  useEffect(() => {
    getColumns()
    getDiscountType()
  }, [])

  function getDiscountPerformance(){
    setSpinEnable(true)
    setPerformanceData([])
    const payload = {
      page: current,
      by_discount_type: valueByType === undefined ? '' : valueByType,
      by_code: valueByCode === undefined ? '' : valueByCode.toUpperCase(),
      start_date: start_date,
      end_date: end_date,
    }
    ads_service.get_discount_performance(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setSpinEnable(false)
      setStatsData(r.response.data)
      setPerformanceData(r.response.data.log_data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getDiscountType(){
    ads_service.get_discount_type().subscribe((r) => {
      setDiscountTypeData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    discountPerformanceColumns.map(data => {
      columns.push(data)
    })
    // columns.push(
    // // {
    // //   title: "Created On",
    // //   dataIndex: "",
    // //   key: "",
    // //   align: "center",
    // //   width: 150,
    // //   render: (record) => {
    // //     return (
    // //       <>
    // //         <div>{handleDateFormat(record.created_at)}</div>
    // //         <div>{getStaticTime(record.created_at)}</div>
    // //       </>
    // //     );
    // //   },
    // // },
    // {
    //   title: "Customer Tags",
    //   dataIndex: "",
    //   key: "",
    //   align: "center",
    //   width: 150,
    //   render: (record) => {
    //     return record.customer_active_tags.map((data, index) => {
    //       return(
    //         <Tag key={index} color='purple'>{data.display_name}</Tag>
    //       )
    //     })
    //   },
    // })
    setColumns(columns)
  }

  function pagination(page) {
    setCurrent(page)
    setPerformanceData([])
    setRenderData(false)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function reset() {
    setState({valueByType: undefined, valueByCode: undefined, 
              start_date: '', end_date: '', showDateValue: false})
    setDisable(true)
    setCurrent(1)
    setPerformanceData([])
    setRenderData(false)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  function onChangeRangePicker(value, dateString){
    setState(prevState =>({...prevState, showDateValue: true, start_date :dateString[0], end_date:dateString[1]}))
    setDisable(false)
    setCurrent(1)
  }

  function disabledDate(current){
    // Can select days before today and today
    // return current && current > moment().add(0, "day");
    //Can select days before today and past 3 months
    return current && (current < moment().subtract(91, "day") || current > moment().add(0, "day"));
  }

  return(
    <div className="layer">
      {performanceData && discountTypeData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Discount Type" value={valueByType} width='medium'
                        onChange={onChange} type="valueByType" data={discountTypeData}/>
                <Input placeholder="Coupon Code"
                       value={valueByCode} style={{width:200, margin: 10}}
                       onChange={(e) => onChange(e.target.value, 'valueByCode')}/>
                <RangePicker onChange={onChangeRangePicker} style={{margin:10}}
                             value={showDateValue ? [moment(start_date, dateFormat),
                             moment(end_date, dateFormat)] : '' } format={dateFormat}
                             allowClear={false} disabledDate={disabledDate}/>
                <Button disabled={disable} onClick={getDiscountPerformance} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <span style={{padding: 15}}><b>Total Customers: </b>{statsData.total_customers}</span>
                <span style={{padding: 15}}><b>Total Discount: </b>₹ {statsData.total_discount}</span>
                <span style={{padding: 15}}><b>Unique Deliveries: </b>{statsData.uniq_deliveries}</span>
                <span style={{padding: 15}}><b>Revenue Generated: </b>₹ {statsData.revenue_generated}</span>
                <span style={{padding: 15}}><b>First Order: </b>{statsData.first_orders}</span>
              </Col>
            </Card>
          </Row>
          {performanceData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={performanceData} columns={columns}/>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default DiscountPerformance
