import React from 'react';
import { maskingNumber } from '../../../_modules/masking';
import { handleDateFormat } from '../../../_modules/date';

export const adsColumns = [
    {
        title: 'Notification Type',
        dataIndex: 'notification_type',
        key: 'notification_type',
        align: 'center'
    },
    {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
        width: 300,
        align: 'center'
    },
    {
        title: 'Campaign Name',
        dataIndex: 'campaign_name',
        key: 'campaign_name',
        align: 'center'
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        align: 'center'
    },
    {
        title: 'Customer Count',
        dataIndex: 'customers_count',
        key: 'customers_count',
        align: 'center'
    },
]

export const segmentColumns = [
    {
        title: 'Customer Name',
        dataIndex: 'customer_name',
        key: 'customer_name',
        align: 'center'
    },
    {
        title: 'Mobile No',
        dataIndex: 'mobile_number',
        key: 'mobile_number',
        align: 'center'
    },
]

export const discountPerformanceColumns = [
    {
        title: 'Customer Name',
        dataIndex: 'customer',
        key: 'customer',
        align: 'center'
    },
    {
        title: 'Order No',
        dataIndex: 'order_number',
        key: 'order_number',
        align: 'center'
    },
    {
        title: 'Discount Type',
        dataIndex: 'discount_type',
        key: 'discount_type',
        align: 'center'
    },
    {
        title: 'Coupon Code',
        dataIndex: 'coupon_code',
        key: 'coupon_code',
        align: 'center'
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        align: 'center'
    },
]

export const referralDiscountColumns = [
    {
        title: 'Recipient Name',
        dataIndex: 'recipient_name',
        key: 'recipient_name',
        align: 'center'
    },
    {
        title: 'Coupon Code',
        dataIndex: 'coupon_code',
        key: 'coupon_code',
        align: 'center'
    },
    {
        title: 'Discount Type',
        dataIndex: 'discount_type',
        key: 'discount_type',
        align: 'center'
    },
    {
        title: 'Value',
        dataIndex: 'discount_percentage_or_value',
        key: 'discount_percentage_or_value',
        align: 'center'
    },
    {
        title: 'Created On',
        dataIndex: 'created_date',
        key: 'created_date',
        align: 'center'
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        align: 'center'
    },
    {
        title: 'Is Applied',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
            return record.is_applied.toString()
        }
    },
    {
        title: 'Is Used',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
            return record.is_used.toString()
        }
    },
]

export const discountColumns = [
    {
        title: 'Coupon Code',
        dataIndex: 'coupon_code',
        key: 'coupon_code',
        align: 'center'
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        align: 'center'
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        align: 'center'
    },
    {
        title: 'Discount Type',
        dataIndex: 'discount_type',
        key: 'discount_type',
        align: 'center'
    },
    {
        title: 'Value',
        dataIndex: 'discount_percentage_or_value',
        key: 'discount_percentage_or_value',
        align: 'center'
    },
    {
        title: 'Applicable Count',
        dataIndex: 'applicable_count',
        key: 'applicable_count',
        align: 'center'
    },
    {
        title: 'Is Visible',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
            return record.is_visible.toString()
        }
    },
    {
        title: 'Valid From',
        dataIndex: 'valid_from',
        key: 'valid_from',
        align: 'center',
        width: 120
    },
    {
        title: 'Valid To',
        dataIndex: 'valid_to',
        key: 'valid_to',
        align: 'center',
        width: 120
    },
]

export const customerSourceColumns = [
    {
        title: 'Name',
        dataIndex: 'full_name',
        key: 'full_name',
        align: 'center'
    },
    {
        title: 'Mobile No',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
            return record.mobile_number ? maskingNumber(record.mobile_number) : ''
        }
    },
    {
        title: 'Signup Source Name',
        dataIndex: 'signup_source_name',
        key: 'signup_source_name',
        align: 'center'
    },
    {
        title: 'Campaign',
        dataIndex: 'campaign',
        key: 'campaign',
        align: 'center'
    },
    {
        title: 'Medium',
        dataIndex: 'medium',
        key: 'medium',
        align: 'center'
    },
]

export const cashbackColumns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        align: 'center'
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        align: 'center'
    },
    {
        title: 'Cashback Type',
        dataIndex: 'cashback_type',
        key: 'cashback_type',
        align: 'center'
    },
    {
        title: 'Value',
        dataIndex: 'cashback_percentage_or_value',
        key: 'cashback_percentage_or_value',
        align: 'center'
    },
    {
        title: 'Applicable Count',
        dataIndex: 'applicable_count',
        key: 'applicable_count',
        align: 'center'
    },
    {
        title: 'Is Active',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
            return record.is_active.toString()
        }
    },
    {
        title: 'Valid From',
        dataIndex: 'valid_from',
        key: 'valid_from',
        align: 'center',
        width: 120
    },
    {
        title: 'Valid To',
        dataIndex: 'valid_to',
        key: 'valid_to',
        align: 'center',
        width: 120
    },
]

export const dealsColumns = [
    {
        title: 'Product',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
    },
    {
        title: 'SKU Code',
        dataIndex: 'sku',
        key: 'sku',
        align: 'center',
    },
]

export const dealsSequenceColumns = [
    {
        title: 'Product',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
    },
    {
        title: 'SKU Code',
        dataIndex: 'sku',
        key: 'sku',
        align: 'center',
    },
    {
        title: 'Sequence',
        dataIndex: 'deals_sequence',
        key: 'deals_sequence',
        align: 'center',
    },
]

export const campaignListingColumns = [
    {
        title: 'Campaign Name',
        dataIndex: 'campaign_name',
        key: 'campaign_name',
        align: 'center'
    },
    {
        title: 'Landing Page Type',
        dataIndex: 'landing_page_type',
        key: 'landing_page_type',
        align: 'center'
    },
    {
        title: 'Is Active',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
            return record.is_active.toString()
        }
    },
]

export const freebieListingColumns = [
    {
        title: "Product Image",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
            return (
                <img src={record.product.image_url} height={80} />
            );
        },
    },
    {
        title: 'Product Name',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
            return (
                <p>
                    {record.product.name}
                </p>
            );
        },
    },
    {
        title: 'Freebie Name',
        dataIndex: 'name',
        key: 'name',
        align: 'center'
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'center'
    },
    {
        title: 'Visibility',
        dataIndex: 'visibility',
        key: 'visibility',
        align: 'center',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center'
    },
    {
        title: 'Visible From',
        dataIndex: 'visible_from',
        key: 'visible_from',
        width: 120,
        align: 'center',
        render: (record) => {
            return handleDateFormat(record)
        }
    },
    {
        title: 'Visible To',
        dataIndex: 'visible_to',
        key: 'visible_to',
        width: 120,
        align: 'center',
        render: (record) => {
            return handleDateFormat(record)
        }
    },
]

export const referralColumns = [
    {
        title: 'Discount',
        dataIndex: 'discount_percentage_or_value',
        key: 'discount_percentage_or_value',
        align: 'center'
    },
    {
        title: 'Discount Type',
        dataIndex: 'discount_type',
        key: 'discount_type',
        align: 'center'
    },
    {
        title: 'Min Order',
        dataIndex: 'min_order_value',
        key: 'min_order_value',
        align: 'center',
    },
]

export const referralCityColumns = [
    {
        title: 'City',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
            return record.city_details?.name ?? ''
        }
    },
    {
        title: 'Discount',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
            return record.referral_discount_details?.discount_percentage_or_value ?? ''
        }
    },
    {
        title: 'Discount Type',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
            return record.referral_discount_details?.discount_type ?? ''
        }
    },
    {
        title: 'Min Order',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
            return record.referral_discount_details?.min_order_value ?? ''
        }
    },
]

export const promotionListingColumns = [
    {
        title: "Image",
        dataIndex: "",
        key: "",
        width: 120,
        align: "center",
        render: (record) => {
            return record.image ? 
                <img src={record.image.image_url} className='frz-w-100' />
            : null
        }
    },
    {
        title: "Promotion Page Name",
        dataIndex: "title",
        key: "title",
        align: "center",
    },
    {
        title: 'Cluster',
        dataIndex: 'promotional_page_items',
        key: 'promotional_page_items',
        align: 'center',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center'
    },
    {
        title: 'Template',
        dataIndex: 'template',
        key: 'template',
        align: 'center'
    },
    {
        title: 'Start At',
        dataIndex: 'start_at',
        key: 'start_at',
        width: 120,
        align: 'center',
        render: (record) => {
            return handleDateFormat(record)
        }
    },
    {
        title: 'End At',
        dataIndex: 'end_at',
        key: 'end_at',
        width: 120,
        align: 'center',
        render: (record) => {
            return handleDateFormat(record)
        }
    },
]
