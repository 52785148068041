import React, { useEffect, useState } from "react";
import { Checkbox, Divider, Row, Col, Spin, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CityList from "./citylist";
import { useSelector } from 'react-redux'
import { DarkstoreService } from '../../../_services/darkstore'
import CityDetail from "./citydetail";
import { ProductService } from '../../../_services/product'
import { CategoryService } from '../../../_services/category'
import _ from 'lodash'
import { ErrorNotification } from "./notification";
import { openNotificationWithIcon } from "../../../_modules/notification";
import { SpecialDiscountService } from "../../../_services/specialdiscount";

const product_service = new ProductService()
const category_service = new CategoryService()
const darkstore_service = new DarkstoreService()
const special_discount_service = new SpecialDiscountService()

const { confirm } = Modal;

const CheckboxGroup = Checkbox.Group;

const ProductActivation = ({ productId, catId, discountId, clusterId }) => {
  //checked list
  const [checkedList, setCheckedList] = useState(false);
  const [cloneCheckedlist, setCloneCheckedlist] = useState(false)

  const [citiesId, setCitiesId] = useState(false)
  const [dsIds, setDsIds] = useState(false)

  const [activeDsList, setActiveDsList] = useState(false)
  const [cloneActiveDsList, setCloneActiveDsList] = useState(false)

  const [cityWiseDs, setCityWiseDs] = useState(false)
  const [activeCityId, setActiveCityId] = useState(false)
  const [activeCityData, setActiveCityData] = useState(false)
  const [isCurrentCityActive, setIsCurrentCityActive] = useState(false)

  const [isDistable, setIsDistable] = useState(true)

  // const [isAllCities, setIsAllCities] = useState(false)
  const [isAllDs, setIsAllDs] = useState(false)

  const allCityList = useSelector(state => state.auth.city);

  const [isChanged, setIsChanged] = useState(false)
  const [loading, setLoading] = useState(false)

  const [cityDetailLoader, setCityDetailLoader] = useState(false)

  useEffect(() => {
    if (!cityWiseDs) {
      getDsList()
    }
  }, [cityWiseDs])

  useEffect(() => {
    if (activeCityId) {
      getActivationDetail()
    }
  }, [activeCityId])

  const getDsList = () => {
    const payload = {
      valueByDarkStore: ''
    }
    darkstore_service.get_all_darkstores(payload).subscribe((r) => {
      let dsListing = r.response.data
      let completeListingData = []

      //make an array of city with nested ds array
      // let tempCitiesId = []
      for (let citydata of allCityList) {
        let temp = {};
        Object.assign(temp, {
          ...citydata,
          ds: dsListing.filter(dslist => dslist.city_id === citydata.id).
            map(cdata => {
              return {
                ...cdata
              }
            })
        })
        // tempCitiesId.push(citydata.id)
        completeListingData.push(temp)
      }
      // setCitiesId(tempCitiesId)
      //set default selected city
      setActiveCityId(completeListingData[0].id)
      setActiveCityData(completeListingData[0])
      setCityWiseDs(completeListingData)
    }, (err) => {
      console.log("err", err)
    })
  }

  // useEffect(() => {
  //   if (allCityList) {
  //     getCityId()
  //   }
  // }, [isAllCities])

  // const getCityId = () => {
  //   let temp = []
  //   allCityList.map((data) => {
  //     temp.push(data.id)
  //   })
  //   setCitiesId(temp)
  // }

  const onChange = (e, id) => {
    setIsChanged(!isChanged)
    // if (isAllCities) {
    //   setIsChanged(true)
    //   setIsAllCities(false)
    // }
    let tempCheckList = cloneCheckedlist
    if (e) {
      tempCheckList.push(id)
      setIsCurrentCityActive(true)
    } else {
      setIsCurrentCityActive(false)
      var index = tempCheckList.indexOf(id);
      if (index !== -1) {
        tempCheckList.splice(index, 1);
      }
    }
    setCloneCheckedlist([...tempCheckList])
  };

  const onChangeDs = (e, id) => {
    setIsDistable(false)
    setIsChanged(!isChanged)
    if (isAllDs) {
      setIsChanged(true)
      setIsAllDs(false)
    }
    let tempDsCheckList = cloneActiveDsList
    if (e.target.checked) {
      tempDsCheckList.push(id)
    } else {
      var index = tempDsCheckList.indexOf(id);
      if (index !== -1) {
        tempDsCheckList.splice(index, 1);
      }
    }
    if (tempDsCheckList.length > 0) {
      // select city customly
      onChange(true, activeCityId)
    }
    setCloneActiveDsList([...tempDsCheckList])
  }

  const onCityChange = (id) => {
    // check if there is some changes
    setActiveCityId(id)
    setActiveCityData(cityWiseDs.find((e) => e.id == id))
  }

  const getActivationDetail = () => {
    setCityDetailLoader(true)
    if (discountId) {
      //call activation for special dis
      const payload = {
        id: discountId,
        city: activeCityId
      }
      special_discount_service.get_special_discount_activation(payload).subscribe((r) => {
        // if (r.response.data.all_cities) {
        //   setIsAllCities(true)
        //   setCloneCheckedlist(citiesId)
        // } else {
        //   setIsAllCities(false)
        // }

        if (r.response.data.city_info) {
          setIsAllDs(r.response.data.city_info.all_darkstores)
        } else {
          setIsAllDs(false)
        }
        //city
        setCheckedList(r.response.data.active_cities)
        setCloneCheckedlist(r.response.data.active_cities)
        // console.log("r.response.data", r.response.data)
        //ds list
        setActiveDsList(r.response.data.city_info.active_darkstores)
        setCloneActiveDsList(r.response.data.city_info.active_darkstores)
        setCityDetailLoader(false)
      }, ((err) => {
        setCityDetailLoader(false)
        console.log("err", err)
      }))
    }
    else if (catId) {
      //call activation for category
      const payload = {
        id: catId,
        city: activeCityId
      }
      category_service.getCategoryActivation(payload).subscribe((r) => {
        // if (r.response.data.all_cities) {
        //   setIsAllCities(true)
        //   setCloneCheckedlist(citiesId)
        // } else {
        //   setIsAllCities(false)
        // }

        if (r.response.data.city_info) {
          setIsAllDs(r.response.data.city_info.all_darkstores)
        } else {
          setIsAllDs(false)
        }
        //city
        setCheckedList(r.response.data.active_cities)
        setCloneCheckedlist(r.response.data.active_cities)
        // console.log("r.response.data", r.response.data)

        //ds list
        setActiveDsList(r.response.data.city_info.active_darkstores)
        setCloneActiveDsList(r.response.data.city_info.active_darkstores)
        setCityDetailLoader(false)
      }, ((err) => {
        setCityDetailLoader(false)
        console.log("err", err)
      }))
    } else if (clusterId) {
      //call activation for category
      const payload = {
        id: clusterId,
        city: activeCityId
      }
      category_service.getClusterActivation(payload).subscribe((r) => {
        // if (r.response.data.all_cities) {
        //   setIsAllCities(true)
        //   setCloneCheckedlist(citiesId)
        // } else {
        //   setIsAllCities(false)
        // }

        if (r.response.data.city_info) {
          setIsAllDs(r.response.data.city_info.all_darkstores)
        } else {
          setIsAllDs(false)
        }
        //city
        setCheckedList(r.response.data.active_cities)
        setCloneCheckedlist(r.response.data.active_cities)
        // console.log("r.response.data", r.response.data)

        //ds list
        setActiveDsList(r.response.data.city_info.active_darkstores)
        setCloneActiveDsList(r.response.data.city_info.active_darkstores)
        setCityDetailLoader(false)
      }, ((err) => {
        setCityDetailLoader(false)
        console.log("err", err)
      }))
    } else {
      //call activation for product
      const payload = {
        id: productId,
        city: activeCityId
      }
      product_service.getProductActivation(payload).subscribe((r) => {
        // if (r.response.data.all_cities) {
        //   setIsAllCities(true)
        //   setCloneCheckedlist(citiesId)
        // } else {
        //   setIsAllCities(false)
        // }

        if (r.response.data?.city_info) {
          setIsAllDs(r.response.data.city_info.all_darkstores)
        } else {
          setIsAllDs(false)
        }

        //city
        setCheckedList(r.response.data.active_cities)
        setCloneCheckedlist(r.response.data.active_cities)

        //ds list
        setActiveDsList(r.response.data.city_info.active_darkstores)
        setCloneActiveDsList(r.response.data.city_info.active_darkstores)
        setCityDetailLoader(false)
      }, ((err) => {
        setCityDetailLoader(false)
        console.log("err", err)
      }))
    }

  }

  function showConfirm(nextCityId) {
    confirm({
      className: "frz-modal-no-padding",
      title: 'Do you Want to continue, you have unsaved changes ?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        resetFields(nextCityId)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const resetCheckList = (nextCityId) => {
    showConfirm(nextCityId)
  }

  const resetFields = (nextCityId) => {
    // setIsAllCities(false)
    setIsAllDs(false)
    setActiveCityId(nextCityId)
    setActiveCityData(cityWiseDs.find((e) => e.id == nextCityId))
    setIsChanged(false)
  }

  const onSubmit = () => {
    setLoading(true)
    if (discountId) {
      let payload;
      payload = {
        id: discountId,
        by_city: [
          {
            city_id: activeCityId,
            all_darkstore: isCurrentCityActive ? isAllDs : false,
            is_active: cloneActiveDsList.length > 0 ? isCurrentCityActive : false,
            active_darkstores: isCurrentCityActive ? cloneActiveDsList : []
          }
        ]
      }
      special_discount_service.update_special_discount_activation(payload).subscribe((r) => {
        setLoading(false)
        getActivationDetail()
        setIsChanged(false)
        openNotificationWithIcon('success', 'Updated Successfully')
      }, ((err) => {
        setLoading(false)
        ErrorNotification(err?.response?.errors?.[0] ?? "API ERROR");
        console.log("Err", err)
      }))
    } else if (catId) {
      let payload;
      payload = {
        product_category_id: catId,
        by_city: [
          {
            city_id: activeCityId,
            all_darkstore: isCurrentCityActive ? isAllDs : false,
            is_active: cloneActiveDsList.length > 0 ? isCurrentCityActive : false,
            active_darkstores: isCurrentCityActive ? cloneActiveDsList : []
          }
        ]
      }
      category_service.updateCateogryActivation(payload).subscribe((r) => {
        setLoading(false)
        getActivationDetail()
        setIsChanged(false)
        openNotificationWithIcon('success', 'Updated Successfully')
      }, ((err) => {
        setLoading(false)
        ErrorNotification(err?.response?.errors?.[0] ?? "API ERROR");
        console.log("Err", err)
      }))
    } else if (clusterId) {
      let payload;
      payload = {
        clusterId: clusterId,
        by_city: [
          {
            city_id: activeCityId,
            all_darkstore: isCurrentCityActive ? isAllDs : false,
            is_active: cloneActiveDsList.length > 0 ? isCurrentCityActive : false,
            active_darkstores: isCurrentCityActive ? cloneActiveDsList : []
          }
        ]
      }
      category_service.updateClusterActivation(payload).subscribe((r) => {
        setLoading(false)
        getActivationDetail()
        setIsChanged(false)
        openNotificationWithIcon('success', 'Updated Successfully')
      }, ((err) => {
        setLoading(false)
        ErrorNotification(err?.response?.errors?.[0] ?? "API ERROR");
        console.log("Err", err)
      }))
    } else {
      let payload;
      payload = {
        product_id: productId,
        by_city: [
          {
            city_id: activeCityId,
            all_darkstore: isCurrentCityActive ? isAllDs : false,
            is_active: cloneActiveDsList.length > 0 ? isCurrentCityActive : false,
            active_darkstores: isCurrentCityActive ? cloneActiveDsList : []
          }
        ]
      }

      // if (!isAllCities) {
      //   payload = {
      //     product_id: productId,
      //     all_cities: isAllCities,
      //   }
      // } else {
      //   payload = {
      //     product_id: productId,
      //     all_cities: isAllCities,
      //     by_city: [
      //       {
      //         city_id: activeCityId,
      //         all_darkstore: isCurrentCityActive ? isAllDs : false,
      //         is_active: isCurrentCityActive,
      //         active_darkstores: isCurrentCityActive ? cloneActiveDsList : []
      //       }
      //     ]
      //   }
      // }

      product_service.updateProductActivation(payload).subscribe((r) => {
        setLoading(false)
        getActivationDetail()
        setIsChanged(false)
        openNotificationWithIcon('success', 'Updated Successfully')
      }, ((err) => {
        setLoading(false)
        ErrorNotification(err?.response?.errors?.[0] ?? "API ERROR");
        console.log("Err", err)
      }))
    }
  }

  // const handleAllCityActive = (e) => {
  //   setIsChanged(true)
  //   if (e.target.checked) {
  //     setIsAllCities(true)
  //     setCloneCheckedlist(citiesId)
  //   } else {
  //     setIsAllCities(false)
  //     setCloneCheckedlist([])
  //   }
  // }


  const handleAllDsActive = (e) => {
    setIsDistable(false)
    setIsChanged(true)
    if (e.target.checked) {
      setIsAllDs(true)
      onChange(true, activeCityId)
      setCloneActiveDsList(dsIds)
    } else {
      setIsAllDs(false)
      setCloneActiveDsList([])
    }
  }

  const handleDsId = (value) => {
    setDsIds(value)
  }

  return (
    cityWiseDs && cloneCheckedlist && cloneActiveDsList ?
      <div>
        <div>
          {/* <div className="frz-dis-flex">
            <div className="frz-rm-10 frz-dis-flex frz-vertical-center">
              All Cities
            </div>

            <div className=" frz-dis-flex">
              <Checkbox checked={isAllCities}
                className={isAllCities ? "frz-custom-checkbox-test" : "frz-custom-checkbox-disable-test"}
                onChange={handleAllCityActive}>
                <div
                  className={isAllCities ? "frz-custom-checkbox-text" : "frz-custom-checkbox-text-disable"}
                >
                  Active
                </div>
              </Checkbox>
            </div>
          </div> */}

          {/* <div className="frz-hrz-division-less-height" /> */}

          {/* <Divider /> */}
          <Row gutter={[10, 10]}>
            <Col xl={8}>
              <div className="frz-city-list-container">
                <CityList onChange={onChange} allOptions={citiesId}
                  checkedList={checkedList} cityWiseDs={cityWiseDs} resetCheckList={resetCheckList}
                  activeCityId={activeCityId} onCityChange={onCityChange} isChanged={isChanged}
                  cloneCheckedlist={cloneCheckedlist} />
              </div>
            </Col>

            <Col xl={16}>
              <div>
                <CityDetail cityWiseDs={cityWiseDs} activeCityId={activeCityId}
                  activeCityData={activeCityData} onSubmit={onSubmit}
                  activeDsList={activeDsList} cloneActiveDsList={cloneActiveDsList}
                  onChangeDs={onChangeDs} handleDsId={handleDsId}
                  handleAllDsActive={handleAllDsActive} isAllDs={isAllDs} isChanged={isChanged}
                  loading={loading} cityDetailLoader={cityDetailLoader} isDistable={isDistable} />
              </div>
            </Col>
          </Row>
          {/* <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} /> */}
        </div>
      </div>
      :

      <div className="spin-center">
        <Spin tip="...Loading" />
      </div>
  )
}

export default ProductActivation