export const capacityColumns = [
  {
    title: "Available Slots",
    dataIndex: "available_slots",
    key: "available_slots",
    align: "center",
  },
  {
    title: "Blocked Slots",
    dataIndex: "blocked_slots",
    key: "blocked_slots ",
    align: "center",
  },
  {
    title: "Booked Slots",
    dataIndex: "booked_slots",
    key: "booked_slots ",
    align: "center",
  },
  {
    title: "Express Available",
    dataIndex: "express_available",
    key: "express_available",
    align: "center",
  },
  {
    title: "Express Booked",
    dataIndex: "express_booked",
    key: "express_booked",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    align: "center",
  },
  {
    title: "Delivery Slot Details",
    dataIndex: "delivery_slot_details",
    key: "delivery_slot_details",
    align: "center",
  },
  {
    title: "Slot Name",
    dataIndex: "slot_name",
    key: "slot_name",
    align: "center",
  }
]
