import React, {useEffect, useState} from 'react';
import {Card , Radio, Spin, Select, DatePicker} from 'antd';
import { Pie} from 'react-chartjs-2';
import {StatisticsService} from '../../_services/statistics';
import moment from 'moment';
import Search from '../antd/search';
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;

const stats_service =  new StatisticsService()

function Refund(){
    const [refundData, setRefundData] = useState(false)
    const [renderData, setRenderData] = useState(false)
    const [renderDataGraph, setRenderDataGraph] = useState(false)
    const [state, setState] = useState({type: 'daily', start_date: '', end_date: '', showDateValue: false,
                                        valueByDarkstore: 'all', valueByPayMode: undefined});
    const presetData = {
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [
            '#FF6384','#36A2EB','#FFCE56','green','orange','brown','cyan','darkgrey','pink','purple','peacock','berry','gold','sage','mauve'
            ],
            data1: []
        }]
    } 
    const [graphData, setGraphData] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [
            '#FF6384','#36A2EB','#FFCE56','green','orange','brown','cyan','darkgrey','pink','purple','peacock','berry','gold','sage','mauve'
            ],
            data1: []
        }]
    })
    const { type, start_date, end_date, showDateValue, valueByDarkstore, valueByPayMode } = state

    useEffect(() => {
        if(!renderData){
          setRenderData(true)
          getRefund()
        }
      })

    function getRefund(){
        const payload = {
          type: type === undefined ? '' : type,
          start_date: start_date,
          end_date: end_date,
          by_darkstore: valueByDarkstore === 'all' ? '' : valueByDarkstore,
          refund_method: valueByPayMode === undefined || valueByPayMode === null ? '' : valueByPayMode
        }
        stats_service.get_refund(payload).subscribe((r) => {
          setRefundData(r.response.data)
          setStatsData(r.response.data)
        },
        (err)=>{
          console.log(err)
        })
    }

    function onChangeRangePicker(value, dateString){
        setState(prevState =>({...prevState, showDateValue: true, start_date :dateString[0], end_date:dateString[1], type: undefined}))
        setRenderDataGraph(false)
        setRenderData(false)
        setGraphData(presetData)
    }

    function onChange(value, type) {
        if(type === 'type'){
            setState(prevState =>({...prevState, [type]: value, start_date: '', end_date: '', showDateValue: false}))
        }else{
            setState(prevState =>({...prevState, [type]: value}))
        }
        setRenderDataGraph(false)
        setRenderData(false)
        setGraphData(presetData)
     }

    function setStatsData(data) {
        data.comparison_data.map(val => {
            graphData.labels.push(val.name)
            graphData.datasets[0].data.push(val.count)
            graphData.datasets[0].data1.push(val.refund_amount)
        })
        // console.log(graphData)
        setGraphData(graphData)
        setRenderDataGraph(true)
    }

    function disabledDate(current){
        // Can select days before today and today
        return current && current > moment().add(0, "day");
      }

    return(
        <div>
            <Card>
                <h2 style={{margin: 10}}>Refund / Sales Loss : </h2>
                <div className="layer">
                    <Search placeholder="Darkstore Name" value={valueByDarkstore}
                        onChange={(e) => onChange(e, 'valueByDarkstore')} type="valueByDarkstoreStats"/>
                    <Select style={{ width: 130, float:'right', padding: 10}} value={valueByPayMode}
                            placeholder="Payment Method" onChange={(e) => onChange(e, 'valueByPayMode')}>
                        <Option value='razorpay'>Razorpay</Option>
                        <Option value='credit'>Credit</Option>
                        <Option value={undefined}>All</Option>
                    </Select>
                </div>
                <div style={{margin: 10, borderBottom: '1px solid rgb(240,240,240', paddingBottom: 20}}>
                    <Select style={{ width: 150}} value={type}
                            placeholder="Select Type" onChange={(e) => onChange(e, 'type')}>
                        <Option value='daily'>Yesterday</Option>
                        <Option value='weekly'>Weekly</Option>
                        <Option value='this_month'>This Month</Option>
                        <Option value='last_month'>Last Month</Option>
                        <Option value='quarterly'>Quarterly</Option>
                        {/* <Option value='half_yearly'>Half Yearly</Option> */}
                    </Select>
                    <RangePicker onChange={onChangeRangePicker} style={{float:'right'}}
                            value={showDateValue ? [moment(start_date, dateFormat),
                            moment(end_date, dateFormat)] : '' } format={dateFormat}
                            allowClear={false} disabledDate={disabledDate}/>
                </div>
                {refundData ? 
                    <div style={{margin: 10, borderBottom: '1px solid rgb(240,240,240', paddingBottom: 10}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div><b>Expected Sales:</b> ₹ {parseFloat(refundData.expected_sales_amount).toFixed(2)}</div>
                            <div><b>Final Sales:</b> ₹ {parseFloat(refundData.final_sales_amount).toFixed(2)}</div>
                        </div>
                        <div><b>Total Refund:</b> ₹ {parseFloat(refundData.total_refund_amount).toFixed(2)}</div>
                    </div>
                : null}
                {renderDataGraph ? 
                   <div>
                        <Pie data={graphData}
                            options={{
                                    tooltips: {
                                        titleFontSize:13,
                                        bodyFontSize:15,
                                        enabled: true,
                                        displayColors:false,
                                        callbacks: {
                                            title: (tooltipItem, data) => {
                                                return data['labels'][tooltipItem[0]['index']];;
                                            },
                                            label: (tooltipItem, data) => {
                                                let tooltipData = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                                return `Order Count : ${tooltipData.toLocaleString('en-IN')}`;
                                            },
                                            beforeLabel: (tooltipItem, data) => {
                                                let tooltipData = data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index];
                                                return `Revenue : ₹ ${tooltipData.toLocaleString('en-IN')}`;
                                            },
                                            afterLabel: (tooltipItem, data) => {
                                                let allData = data.datasets[tooltipItem.datasetIndex].data.reduce((a, b) => a + b, 0);
                                                let tooltipData = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                                let percentage =(tooltipData/allData * 100).toFixed(2);
                                                return `Percentage : ${percentage}%`;
                                            }
                                        }
                                    }
                            }}
                            width={50} height={25} redraw={true}
                        />
                   </div>
                : 
                  <div className="spin-center">
                    <Spin tip="...Loading"/>
                  </div>
                }
            </Card>
        </div>
    )
}

export default Refund