import React, { useEffect, useState } from 'react'
import { Card, DatePicker, Spin, Divider, Tabs, Select } from 'antd'
import moment from 'moment';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import lock from '../../static/images/lock.png'
import drag from '../../static/images/drag.png'
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from "react-grid-dnd";
import { LandingClusterService } from '../../_services/sdui/landing_cluster';
import FRZSelect from '../../_controls/FRZSelect';

const landing_cluster_service = new LandingClusterService()

const { Option } = Select;

const UnitListing = ({ listingData, setListingData, setActiveUnitId, setSegmentValue,
  activeUnitId, setAddInProgress, addInProgress, updateId, setDate, segmentData,
  setUpdateId, unitListSpin }) => {

  const [state, setState] = useState({ date: moment(), dateValue: '' });
  const { date, dateValue } = state

  const { TabPane } = Tabs;

  const [editableData, setEditableData] = useState(false)
  const [inActiveData, setInActiveData] = useState([])
  const [pendingData, setPendingData] = useState([])


  useEffect(() => {
    if (listingData) {
      setEditableData(listingData.filter((x) => x.is_editable && x.status === "active"))
      setInActiveData(listingData.filter((x) => x.status === "in_active"))
      setPendingData(listingData.filter((x) => x.status === "pending"))
    }
  }, [listingData])

  const disabledDate = (current) => {
    return current && (current < moment().subtract(6, "month") || current > moment().add(10, "month"));
  }

  const onChangeDate = (date, dateString) => {
    setUpdateId(false)
    setActiveUnitId(false)
    setDate(dateString)
    // console.log(date, dateString)
    setState(prevState => ({ ...prevState, date: date, dateValue: dateString }))
  }

  const onAddUnit = () => {
    if (addInProgress || activeUnitId) {
      return
    }
    setUpdateId(false)
    setAddInProgress(true)
    setActiveUnitId(false)
    //bug resolved in above commented line ----->
    // let temp = listingData.filter((x) => x.is_editable && x.status === "active")
    let temp = listingData.filter((x) => x.status === "active")
    temp.push({
      title: "Untitled unit", is_editable: true, status: "active"
      // meta_data: { title: "Untitled unit", is_editable: true }
    })
    setListingData([...temp])
  }

  const onDeleteItm = () => {
    let temp = listingData;
    temp.pop()
    setAddInProgress(false)
    setListingData([...temp])
  }

  const onActive = (id) => {
    if (addInProgress) {
      return
    }
    setAddInProgress(false)
    setActiveUnitId(id)
  }

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    if (sourceIndex == editableData.length) {
      return
    }
    const nextState = swap(editableData, sourceIndex, targetIndex);
    let seq = nextState.map((x) => x.id)
    const payload = {
      backend_driven_page_id: 100,
      sequence: [...listingData.filter((x) => !x.is_editable && x.status === "active").map((x) => x.id), ...seq]
    }
    // console.log(payload)
    landing_cluster_service.updateSequence(payload).subscribe((r) => {

    }, ((err) => {
      console.error(err)
    }))
    // console.log(listingData.filter((x) => !x.is_editable && x.status === "active").map((x) => x))
    setEditableData(nextState);
  }

  const onSegmentChange = (value) => {
    setSegmentValue(value)
  }

  const onChangeTabs = () => {
    setActiveUnitId(false)
  }

  return (
    <div className="frz-h-100vh-unit frz-custom-card">
      <div className='frz-m-10'>
        <div className='frz-dis-flex'>
          <DatePicker disabled={addInProgress} value={date} onChange={onChangeDate} placeholder="Select Date" picker="date"
            style={{ width: 120, marginRight: 10, height: 35 }} disabledDate={disabledDate} />

          {/* <Select
            disabled={addInProgress}
            placeholder="Select segment"
            style={{
              width: 140,
            }}
            allowClear
            onChange={onSegmentChange}
          >
            {segmentData?.map((data) => {
              return (
                <Option value={data.slug}>{data.name}</Option>
              )
            })}
          </Select> */}
          
          
          {/* <FRZSelect DropDownData={segmentData} name="segment" isRequired={true}
            value="slug" option="name"
            mode="multiple"
            setOnChange={onSegmentChange}
            isShowSearch={true} width={120} /> */}
        </div>
        {listingData ?
          listingData.length ?
            <Tabs defaultActiveKey="1" onChange={onChangeTabs}>
              <TabPane tab="Active" key="1" disabled={addInProgress}>
                {listingData.length ?
                  <div className="frz-h-100vh-unit frz-custom-card">
                    {/* <div className='frz-m-10'>
                    <DatePicker disabled={addInProgress} value={date} onChange={onChangeDate} placeholder="Select Date" picker="date"
                    style={{ width: 200, marginRight: 10 }} disabledDate={disabledDate} />
                    </div> */}

                    <div className="frz-txt-green frz-bm-5 frz-fw-500 frz-m-10">
                      Page sequencing
                      {/* for {localStorage.getItem('city')} */}
                    </div>

                    {!unitListSpin ?
                      editableData ?
                        editableData.length ?
                          <>
                            <GridContextProvider onChange={onChange}>
                              {listingData.map((item, index) => {
                                return !item.is_editable &&
                                  <div onClick={() => onActive(item.id)} key={index} className={`frz-p-10 ${(listingData.length - 1 == index && addInProgress) ?
                                    "frz-label-container-add frz-cursor-pointer" : `frz-label-container ${addInProgress ?
                                      'frz-cursor-disable' : "frz-cursor-pointer"}`} frz-m-10`}>
                                    <div className={`${(listingData.length - 1 == index && addInProgress) ? "frz-font-label-txt-add" : activeUnitId == item.id ? "frz-font-label-txt-active" : "frz-font-label-txt"} frz-pos-realtive`}>
                                      {(listingData.length - 1 == index && addInProgress) ?
                                        <div className="frz-pos-abs frz-r-5 frz-t-5 frz-font-10 frz-cursor-pointer" onClick={onDeleteItm}>
                                          <CloseOutlined style={{ color: "#fff" }} />
                                        </div>
                                        : null}
                                      <div className='frz-dis-flex'>
                                        <div className='frz-dis-flex frz-vt-center frz-rm-5'>
                                          <img src={item.is_permanent ? lock : ""} style={{ width: 14, height: 20 }} />
                                        </div>
                                        {item.title ?? 'backend null'}
                                      </div>
                                    </div>
                                  </div>
                              })}

                              {listingData.filter((x) => (!x.is_editable && x.status == "active")).length ?
                                <Divider orientation='vertical' className='frz-custom-divider' />
                                : null}

                              <GridDropZone
                                id="items"
                                disableDrag={addInProgress}
                                disableDrop={addInProgress}
                                boxesPerRow={1}
                                rowHeight={55}
                                style={{ height: `calc(65px * ${editableData.length})`, overflowY: "scroll" }}
                              >
                                {editableData.map((item, index) => (
                                  <GridItem key={item.id}>
                                    <div onClick={() => onActive(item.id)} key={index}
                                      className={`${(editableData.length - 1 == index && addInProgress) ?
                                        "frz-label-container-add frz-cursor-pointer" :
                                        `frz-label-container-editable 
                                ${addInProgress ? 'frz-cursor-disable' : "frz-cursor-dragg"}`} frz-m-10`}>
                                      <div className={`frz-m-5 ${(editableData.length - 1 == index && addInProgress) ? "frz-font-label-txt-add" : activeUnitId == item.id ? "frz-font-label-txt-active" : "frz-font-label-txt"} frz-pos-realtive`}>
                                        {(editableData.length - 1 == index && addInProgress) ?
                                          <div className="frz-pos-abs frz-r-5 frz-t-5 frz-font-10 frz-cursor-pointer" onClick={onDeleteItm}>
                                            <CloseOutlined style={{ color: "#fff" }} />
                                          </div>
                                          : null}
                                        <div className='frz-dis-flex'>
                                          <div className='frz-dis-flex frz-vt-center frz-rm-5'>
                                            <img src={item.is_permanent ? lock : drag} style={{ width: 14, height: 20 }} />
                                          </div>
                                          {item.title ?? 'backend null'}
                                        </div>
                                      </div>
                                    </div>
                                  </GridItem>
                                ))}
                                <GridItem>
                                  <div className={`${addInProgress || activeUnitId ? "frz-txt-disable frz-cursor-disable" : "frz-txt-green frz-cursor-pointer"} frz-dis-flex frz-hrz-center frz-fw-500 frz-m-10`}
                                    onClick={() => onAddUnit()}>
                                    <span className="frz-rm-5"><PlusOutlined /></span> Add New Unit
                                  </div>
                                </GridItem>
                              </GridDropZone>
                            </GridContextProvider>
                          </>
                          :
                          <div className='frz-dis-flex frz-hrz-center frz-tm-30'>
                            <h3>No Data Found</h3>
                          </div>
                        :
                        <div className="spin-center">
                          <Spin tip="...Loading" />
                        </div>
                      :
                      <div className="spin-center">
                        <Spin tip="...Loading" />
                      </div>}
                  </div>
                  :
                  <div className="frz-h-100vh-unit frz-custom-card frz-dis-flex frz-vt-center">
                    <div>
                      <div className="no-data">No Data Available</div>
                      <div className={`${addInProgress || activeUnitId ? "frz-txt-disable frz-cursor-disable" : "frz-txt-green frz-cursor-pointer"} frz-dis-flex frz-hrz-center frz-fw-500 frz-m-10`}
                        onClick={() => onAddUnit()}>
                        <span className="frz-rm-5"><PlusOutlined /></span> Add New Unit
                      </div>
                    </div>
                  </div>
                }
              </TabPane>
              <TabPane tab="In Active" key="2" disabled={addInProgress}>

                {inActiveData.length ? inActiveData.map((item, index) => (
                  <div onClick={() => onActive(item.id)} key={index}
                    className={`${(editableData.length - 1 == index && addInProgress) ?
                      "frz-label-container-add frz-cursor-pointer" :
                      `frz-label-container-in-active 
                                ${addInProgress ? 'frz-cursor-disable' : "frz-cursor-pointer"}`} frz-m-10`}>
                    <div className={`frz-m-5 ${(editableData.length - 1 == index && addInProgress) ? "frz-font-label-txt-add" : activeUnitId == item.id ? "frz-font-label-txt-in-active" : "frz-font-label-txt"} frz-pos-realtive`}>
                      {(editableData.length - 1 == index && addInProgress) ?
                        <div className="frz-pos-abs frz-r-5 frz-t-5 frz-font-10 frz-cursor-pointer" onClick={onDeleteItm}>
                          <CloseOutlined style={{ color: "#fff" }} />
                        </div>
                        : null}
                      <div className='frz-dis-flex'>
                        {/* <div className='frz-dis-flex frz-vt-center frz-rm-5'>
                      <img src={drag} style={{ width: 14, height: 20 }} />
                    </div> */}
                        {item.title ?? 'backend null'}
                      </div>
                    </div>
                  </div>
                ))
                  :
                  <div className='frz-dis-flex frz-hrz-center frz-tm-30'>
                    <h3>No Data Found</h3>
                  </div>}
              </TabPane>
              <TabPane tab="Pending" key="3" disabled={addInProgress}>
                {pendingData.length ? pendingData.map((item, index) => (
                  <div onClick={() => onActive(item.id)} key={index}
                    className={`${(editableData.length - 1 == index && addInProgress) ?
                      "frz-label-container-add frz-cursor-pointer" :
                      `frz-label-container-pending 
                                ${addInProgress ? 'frz-cursor-disable' : "frz-cursor-pointer"}`} frz-m-10`}>
                    <div className={`frz-m-5 ${(editableData.length - 1 == index && addInProgress) ? "frz-font-label-txt-add" : activeUnitId == item.id ? "frz-font-label-txt-pending" : "frz-font-label-txt"} frz-pos-realtive`}>
                      {(editableData.length - 1 == index && addInProgress) ?
                        <div className="frz-pos-abs frz-r-5 frz-t-5 frz-font-10 frz-cursor-pointer" onClick={onDeleteItm}>
                          <CloseOutlined style={{ color: "#fff" }} />
                        </div>
                        : null}
                      <div className='frz-dis-flex'>
                        {/* <div className='frz-dis-flex frz-vt-center frz-rm-5'>
                      <img src={drag} style={{ width: 14, height: 20 }} />
                    </div> */}
                        {item.title ?? 'backend null'}
                      </div>
                    </div>
                  </div>
                )) :
                  <div className='frz-dis-flex frz-hrz-center frz-tm-30'>
                    <h3>No Data Found</h3>
                  </div>}
              </TabPane>
            </Tabs>
            : <div>
              <div className='frz-dis-flex frz-hrz-center frz-tm-30'>
                <h3>No Data Found</h3>
              </div>
              <div className={`${addInProgress || activeUnitId ? "frz-txt-disable frz-cursor-disable" : "frz-txt-green frz-cursor-pointer"} frz-dis-flex frz-hrz-center frz-fw-500 frz-m-10`}
                onClick={() => onAddUnit()}>
                <span className="frz-rm-5"><PlusOutlined /></span> Add New Unit
              </div>
            </div>
          :
          <div className="spin-center">
            <Spin tip="...Loading" />
          </div>
        }
      </div>
    </div>
  )
}

export default UnitListing