import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import {
  Modal, Button, Select, Input, InputNumber, DatePicker,
  Radio, ConfigProvider
} from 'antd';
import { openNotificationWithIcon } from '../../_modules/notification';

const { Option } = Select;

const OrderCancel = forwardRef((props, ref) => {

  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [id, setId] = useState(false)
  const [isRiderPayment, setIsRiderPayment] = useState(undefined)
  const [raisedByType, setRaisedByType] = useState(false);
  const [raisedByMobile, setRaisedByMobile] = useState(false);
  const [raisedByName, setRaisedByName] = useState(false);
  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
      setId(value)
      setIsRiderPayment(undefined)
      setRaisedByType(undefined);
      setRaisedByName(undefined);
      setRaisedByMobile(undefined);
    },

    handleCancel() {
      setVisible(false)
      props.onCancelModal();
      setIsRiderPayment(undefined)
      setRaisedByType(undefined);
      setRaisedByName(undefined);
      setRaisedByMobile(undefined);
    },

    stopLoading() {
    }

  }));

  function handleOk() {
    if (raisedByType ==='store_team' || raisedByType ==='rider'){
      const payload = {
        id: id,
        rider_payment: isRiderPayment,
        raised_by_type: raisedByType,
        raised_by_name: raisedByName,
        raised_by_mobile: raisedByMobile
      }
      props.modalSubmit(payload)
    }else if(raisedByType ==='customer'){
      const payload = {
        id: id,
        rider_payment: isRiderPayment,
        raised_by_type: 'customer'
      }
      props.modalSubmit(payload)
    }else{
      openNotificationWithIcon('error', 'Please Fill All Fields')
      return
    }
  }

  function closeModal() {
    setVisible(false)
  }
  function handleChange(value) {
    setRaisedByType(value);
  }
  function handleChangeName(e){
    setRaisedByName(e.target.value);
  }
  function handleChangeMobile(value){
    setRaisedByMobile(value);
  }
  function handleCancelModal() {
    setVisible(false)
    props.onCancelModal();
  }

  const handleRiderPayment = (e) => {
    setIsRiderPayment(e.target.value)
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title='Cancel Order'
          onOk={handleOk}
          onCancel={handleCancelModal}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={props.loading} onClick={handleOk}>
              Submit
            </Button>,
          ]}
        >
          <div className="block" style={{ marginLeft: 8 }}> Cancel Reason :
            <span style={{ paddingLeft: 10 }}>
              <Select style={{ width: 200 }} placeholder="Select Reason"
                value={props.cancellationReason}
                onChange={(e) => props.onChangeValue(e, 'cancellationReason')}>
                {props.cancelReason.map(data => {
                  return (
                    <Option key={data.id} value={data.id}>
                      {data.display_name}
                    </Option>
                  )
                })}
              </Select>
            </span>
          </div>

          <div className="block" style={{ marginLeft: 38 }}> Raised By :
            <span className='frz-lp-10'>
            <Select value = {raisedByType} style={{ width: 200 }} label="Raised By" 
                    placeholder="Raised by" onChange={handleChange}>
              <Option value='customer'>Customer</Option>
              <Option value='store_team'>Store Team</Option>
              <Option value='rider'>Rider</Option>
            </Select>
            </span>
          </div>

       

          <div className="block" style={{ marginLeft: 8 }}> Rider Payment :
            <span style={{ paddingLeft: 10 }}>
              <Radio.Group value={isRiderPayment} onChange={handleRiderPayment}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </span>
          </div>

          {raisedByType==='store_team' || raisedByType === 'rider'  ? 
            <>
              <div className="block" style={{ marginLeft: 60 }}> Name :
                <span className='frz-lp-10'>
                  <Input style={{ width: 200 }} isRequired={true} placeholder="Name" 
                    value={raisedByName} onChange={handleChangeName}/>
                </span>
              </div>
              <div className="block" style={{ marginLeft: 35 }}> Mobile No :
                <span className='frz-lp-10'>
                  <InputNumber style={{ width: 200 }} isRequired={true} value = {raisedByMobile} onChange={handleChangeMobile} 
                    placeholder="Mobile No." />
                </span>
              </div>
            </>
          :
            null
          }

        </Modal>
      : null}
    </div>
  )
})

export default OrderCancel
