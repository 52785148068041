import React, { useEffect, useState } from "react";
import { Button, Spin, Radio, InputNumber, Input } from "antd";
import { ProductService } from "../../../_services/product";
import { ErrorNotification, SuccessNotification } from "./notification";
const productService = new ProductService();

function InventoryMgmnt(props) {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [radioValues, setRadioValues] = useState({});
  const [operations, setOperations] = useState(false);
  const inputStyle = {
    marginRight: 200,
    textAlign: "right",
  };

  useEffect(() => {
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if(['developer_admin','super_admin'].includes(data)){
        setOperations(true)
      }
    })
  }, []);

  const commonInputWidth = 200;
  const requiredValues = {};

  function saveProductDetails() {
    let notValidatedFields = isInputsValid();
    if (notValidatedFields.length > 0) {
      ErrorNotification(`Please fill all required* fields :  ${notValidatedFields.join(', ')}`);
      return;
    }
    setButtonLoading(true);
    productService.updateProductDSInventory(props.product).subscribe((r) => {
        SuccessNotification("Updated successfully");
        setButtonLoading(false);
      },
      (err) => {
        ErrorNotification("Failed to update details");
        setButtonLoading(false);
      }
    );
  }

  const FZInputRadioSelector = ({ inputName, keyName }) => {
    requiredValues[keyName] = inputName;
    return (
      <div className="block" style={inputStyle}>
        {inputName} :
        <span style={{ paddingLeft: 10 }}>
          <Radio.Group
            style={{ width: commonInputWidth, textAlign: "left" }}
            value={radioValues[keyName] ?? props.product.inventory_management[keyName]}
            onChange={(e) => {
              props.product.inventory_management[keyName] = e.target.value;
              setRadioValues({
                ...radioValues,
                [keyName]: e.target.value,
              });
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </span> 
      </div>
    );
  };

  const FZCustomInputRadioSelector = ({ inputName, keyName }) => {
    requiredValues[keyName] = inputName;
    return (
      <div className="block" style={inputStyle}>
        {inputName} :
        <span style={{ paddingLeft: 10 }}>
          <Radio.Group
            style={{ width: commonInputWidth, textAlign: "left" }}
            value={ 
              props.product.inventory_management[keyName] === 'procurement_unit' ? 
              radioValues[keyName] = false : 
              radioValues[keyName]  = true
          }
            onChange={(e) => { e.target.value===false ?
              props.product.inventory_management[keyName] =  "procurement_unit":
              props.product.inventory_management[keyName] =  "uom";
              setRadioValues({
                ...radioValues,
                [keyName]: e.target.value,
              });
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </span>
      </div>
    );
  };

  function isInputsValid() {
    let notValidFields = [];
    Object.keys(requiredValues).forEach((key) => {
      let value = requiredValues[key];
      if (value.includes("*") && props.product.inventory_management[key] == undefined) {
        // console.log("invalid", value)
        notValidFields.push(value.replace("*",""));
      }
    });
    return notValidFields;
  }

  function onInputChange(key, value) {
    props.product.inventory_management[key] = value;
  }

  const FZInputNumberPercent = ({
    inputName,
    keyName,
    placeholder,
    disabled = false,
  }) => {
    requiredValues[keyName] = inputName;
    return (
      <div className="block" style={inputStyle}>
        {" "}
        {inputName} :
        <span style={{ paddingLeft: 10 }}>
          <InputNumber
            placeholder={placeholder ?? inputName}
            style={{ width: commonInputWidth }}
            onChange={(v) => onInputChange(keyName, v)}
            defaultValue={props.product.inventory_management[keyName] ?? undefined}
            disabled={disabled}
            max={100}
            min={0}
          />
        </span>
      </div>
    );
  };

  const NameValue = ({ keyName, label, value }) => {
    return (
      <div className="block">
        {label} :
        <span style={{ paddingLeft: 10 }}>{value ?? props.product[keyName]}</span>
      </div>
    );
  };

  
  return (
    <div style={{ margin: "20px auto" }}>
      {props.product ? (
        <div style={{ textAlign: "center" }}>
          {!props.product.combo_sub_products?.length > 0 ?
            <>
              <FZInputRadioSelector inputName="Is Po Critical*" keyName="is_po_critical" />
              <FZInputRadioSelector inputName="Is Inventory Enabled*" keyName="is_zapper_enabled" />
              <FZInputRadioSelector inputName="Exclude Expected Stock" keyName="exclude_expected_stock" />
              <FZInputRadioSelector inputName="Allow Unlimited Stock" keyName="allow_unlimited_stock" />
              <FZInputNumberPercent inputName="Procurement Loss Percent" keyName="procurement_loss_pct"/>
              {/* <FZInputNumberPercent inputName="Stock Block Percent" keyName="stock_block_pct"/> */}
              {!props.product.inventory_management.allow_unlimited_stock ? 
                <FZInputRadioSelector inputName="Include Last GRN" keyName="include_last_grn" />
              : null}  
              <NameValue label="PO Unit" keyName="procurement_unit"/>
              <NameValue label="UOM" keyName="uom" />
              <NameValue label="Packsize" keyName="pack_size"/>
                {operations ? 
                  <FZCustomInputRadioSelector inputName= "Is UOM used as inventory unit?" 
                    keyName="inventory_unit" />
                : null}
              <Button type="primary" style={{ marginTop: 20, width: "100px" }}
                onClick={saveProductDetails} loading={buttonLoading}>
                Save
              </Button>
            </>
          : <h2><b>Not Available For Combo</b></h2>}
        </div>
      ) : (
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      )}
    </div>
  );
}

export default InventoryMgmnt;
