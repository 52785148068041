import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import FRZColorPickerTemp from '../../../_controls/FRZColorPickerTemp';
import FRZInput from '../../../_controls/FRZInput';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import FRZRadioButton from '../../../_controls/FRZRadioButton';
import FRZSelect from '../../../_controls/FRZSelect';
import FRZUpload from '../../../_controls/FRZUpload';
import { useSelector } from "react-redux";
import { openNotificationWithIcon } from '../../../_modules/notification';

const category_type = [{name: 'FnV', val: 'fnv'}, {name: 'FMCG', val: 'fmcg'},{name: 'Other', val: 'other'}]

const CategoryModal = forwardRef((props, ref) => {
  const { modalSubmit } = props
  const darkstore = useSelector(state => state.auth.darkstore);
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [pickerBGColor, setPickerBGColor] = useState("#36c")
  const [id, setId] = useState(false)
  const [isParent, setIsParent] = useState()
  const [ds, setDs] = useState()
  const [form] = Form.useForm();
  const [isClose, setIsClose] = useState(false)
  const [imageState, setImageState] = useState({})

  useEffect(() => {
    if ((data && title == "Edit") || isClose) {
      form.setFieldsValue({ ...data })
      setId(data.id)
      setIsParent(data.is_parent)
      setDs(data.all_stores)
      setPickerBGColor(data.background_color)
      resetUploads()
    } else {
      setId(false)
      resetUploads()
      setPickerBGColor("#36c")
    }
    if (isClose) {
      setIsClose(false)
    }
  }, [data, isClose])

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      form.resetFields()
      setData(false)
      setVisible(false)
      setLoading(false)
      setIsClose(true)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOnSubmit(values) {
    const payload = {
      ...values, ...imageState,
      background_color: pickerBGColor ? pickerBGColor : null,
      parent_id: values.parent_id ? values.parent_id : null,
      is_parent: data || values ? title === "Edit" ? data.is_parent : values.is_parent : null,
      active_stores: values.all_stores ? [] : values.active_stores,
      by_city: localStorage.getItem('city') ?? ''
    }
    if (payload.sketch_image) {
      if (!payload.sketch_image.includes('svg')) {
        openNotificationWithIcon('error', 'Please Upload SVG Image For SVG Img Icon')
        return
      }
    }
    setLoading(true)
    modalSubmit(payload, id)
  }

  function handleCancelModal() {
    form.resetFields()
    setData(false)
    setVisible(false)
    setLoading(false)
    setIsClose(true)
  }

  function handleClick({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  function changePickerColor(colors) {
    setPickerBGColor(colors)
  }

  const uploadButton = (
    <div>
      {props.imgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleOnChangeImage = (type, item) => {
    setImageState(prevState => ({ ...prevState, [type]: item }))
  }

  const resetUploads = () => {
    setImageState({})
  }

  const handleOnChangeParent = (value) => {
    setIsParent(value)
  }

  const handleOnChangeDS = (value) => {
    setDs(value)
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'50%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            <FRZInput name="name" isRequired={true}
              label="Name" placeholder="Name" width={200} />

            <FRZInput isTextArea={true} name="description"
              label="Description" placeholder="Description" width={200} />

            <FRZColorPickerTemp
              label="Background Color"
              value={pickerBGColor}
              pickerColor={pickerBGColor}
              changePickerColor={changePickerColor}
              setPickerColor={setPickerBGColor}
              columnSpan={7}
            />

            {/* <FRZInputNumber label="Sequence No" name="sequence" width={200} /> */}

            <FRZRadioButton isRequired={true} label="Is Active" name="is_active" />

            {title !== 'Edit' ?
              <FRZRadioButton setOnChange={handleOnChangeParent} isRequired={true} label="Is Category" name="is_parent" />
              : null}
            {isParent === false ?
              <FRZSelect DropDownData={props.listingData} name="parent_id" isRequired={true}
                label="Parent Category" placeholder="Select Category Name" value="id" option="name"
                isShowSearch={true} width={"100%"} />
              : null
            }
            {isParent === true ?
              <FRZSelect DropDownData={category_type} name="category_type" isRequired={true}
                label="Category Type" placeholder="Select Category Type" value="val" option="name"
                isShowSearch={true} width={'200px'}/>
              : null
            }

            <FRZUpload name="image" className="avatar-uploader" customRequest={handleClick}
              imgWidth={"100%"} label={"Old App Mobile Img"} listType="picture-card" setOnChangeType={"image"}
              showUploadList={false} uploadButton={uploadButton} handleOnChangeImage={handleOnChangeImage}
              imageState={imageState} dimension="(256px * 256px)" />

            <FRZUpload name="mobile_image" className="avatar-uploader" customRequest={handleClick}
              imgWidth={"100%"} label={"New App Mobile Img"} listType="picture-card" setOnChangeType={"mobile_image"}
              showUploadList={false} uploadButton={uploadButton} handleOnChangeImage={handleOnChangeImage}
              imageState={imageState} dimension="(361px * 241px)" />

            <FRZUpload name="icon_image" className="avatar-uploader"
              customRequest={handleClick} imgWidth={"100%"} label={"New App Mobile Grid Img"} listType="picture-card" setOnChangeType={"icon_image"}
              showUploadList={false} uploadButton={uploadButton} handleOnChangeImage={handleOnChangeImage}
              imageState={imageState} dimension="(571px * 351px)" />

            <FRZUpload name="sketch_image" className="avatar-uploader"
              customRequest={handleClick} imgWidth={"100%"} label={"SVG Img Icon"} listType="picture-card" setOnChangeType={"sketch_image"}
              showUploadList={false} uploadButton={uploadButton} handleOnChangeImage={handleOnChangeImage}
              imageState={imageState} dimension="(72px * 72px)" />

            <FRZUpload name="web_image" className="avatar-uploader"
              customRequest={handleClick} imgWidth={"100%"} label={"Web Img"} listType="picture-card" setOnChangeType={"web_image"}
              showUploadList={false} uploadButton={uploadButton} handleOnChangeImage={handleOnChangeImage}
              imageState={imageState} dimension="(601px * 529px)" />

            <FRZInput isTextArea={true} name="seo_title" isRequired={false}
              label="Seo Title" placeholder="Seo Title" width={200} />

            <FRZInput isTextArea={true} name="seo_description" isRequired={false}
              label="Seo Description" placeholder="Seo Description" width={200} />

            <FRZInput isTextArea={true} name="seo_keywords" isRequired={false}
              label="Seo Keywords" placeholder="Seo Keywords" width={200} />

            {/* <FRZRadioButton isRequired={true} label="All Darkstores" name="all_stores" setOnChange={handleOnChangeDS}/> */}

            {/* {ds === false ? 
              <FRZSelect mode="multiple" DropDownData={darkstore} name="active_stores"
                label="Darkstores" placeholder="Select Darkstores" value="id" option="name"
                isShowSearch={true} width={"100%"} isRequired={true}/>
              : null
            } */}
          </Form>
        </Modal>
        : <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})

export default CategoryModal
