import React, {useState} from 'react';
import { Card, Row, Col, Button } from 'antd';
import PricingUpload from '../../components/pricing/pricingupload';
import FrzDynamicDownload from '../../_controls/FRZDynamicDownload';

function Buying (){

  const [uploadDataBuying] = useState([{name: 'Upload Buying Prices', url: 'price/local/market/csv/buying'}])

  return(
    <div>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card>
            <FrzDynamicDownload name={'Buying Price'} fileName={'Buying Price'} url={'download/csv/format/buying'}
                                      payload={`?city_code=${localStorage.getItem('city')}`} type={'pricing'} reportFunc={'pricing_report'}
                                      base={'pricing'}/>  
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <PricingUpload uploadData={uploadDataBuying} />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Buying