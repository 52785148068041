import React, { useEffect, useState } from 'react'
import { Modal, Button, Row, Col, Tabs, Select, Spin, Pagination } from "antd";
import { LogsService } from '../../_services/logs'
import { captializestr } from '../../_modules/captialize'
import { ReloadOutlined } from "@ant-design/icons";
import LogsView from './logsview';

const log_services = new LogsService();

const LogModal = ({ type, showLogModal, handleLogModal, itemId }) => {

  const [dsList, setDsList] = useState(false)
  const [dsItemId, setDsItemId] = useState(undefined)
  const [dsWiseLogs, setDsWiseLogs] = useState(false)
  const { Option } = Select;

  const [logData, setLogData] = useState(false)
  const [activeCardId, setActiveCardId] = useState(false)
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(1)
  const [logDetail, setLogDetail] = useState(false)
  const [byDs, setByDs] = useState(false)
  const [isDsWise, setIsDsWise] = useState(false)

  useEffect(() => {
    getDsList()
  }, [])

  useEffect(() => {
    if (!isDsWise) {
      getCatalogLog(itemId, byDs)
    } else {
      getCatalogLog(isDsWise, byDs)
    }
  }, [itemId, current, isDsWise])

  const handleActiveCard = (value) => {
    setActiveCardId(value)
    let tempData = logData.logs.find((e) => e.object.id === value)
    setLogDetail(tempData)
  }

  const getCatalogLog = (id, isDsWiseLogs) => {
    setLogData(false)
    const payload = {
      page: current,
      type: isDsWiseLogs ? type === "product" ? "darkstore_product" : type === "product_category" ? "darkstore_product_category" : "" : type,
      itemId: id
    }
    log_services.get_log_catalog(payload).subscribe((r) => {
      setTotal(r.response.data?.meta.total_pages * 50)
      setLogData(r.response.data)
      defaultActiveCardId(r.response.data)
    }, ((err) => {
      console.log("err", err)
    }))
  }

  const defaultActiveCardId = (data) => {
    // console.log("data.logs[0].object?.object", data.logs[0])
    setActiveCardId(data?.logs[0].object?.id)
    setLogDetail(data?.logs[0])
  }

  function pagination(page) {
    setCurrent(page)
    setLogData(false)
  }

  const getDsList = () => {
    const payload = {
      type: type === "product" ? "darkstore_product" : type === "product_category" ? "darkstore_product_category" : "",
      itemId: itemId,
      dsName: ""
    }
    log_services.get_log_catalog_by_ds(payload).subscribe((r) => {
      setDsList(r.response.data)
    }, ((err) => {
      console.log("err", err)
    }))
  }

  const onDsSelect = (value) => {
    setDsItemId(value)
  }

  const getLogsByDs = (id) => {
    setByDs(true)
    setCurrent(1)
    setIsDsWise(id)
    // getCatalogLog(id, true, true)
  }

  const fetchCatLogData = () => {
    if (current == 1) {
      getCatalogLog(itemId)
    } else {
      setCurrent(1)
    }
  }

  const reset = () => {
    setDsItemId(undefined)
    setDsWiseLogs(false)
    setIsDsWise(false)
    setCurrent(1)
    // fetchCatLogData()
    setByDs(false)
  }

  return (
    dsList ?
      <Modal
        visible={showLogModal}
        title={
          <div className="frz-dis-flex">
            <div className="frz-dis-flex frz-vertical-center">
              {type === "product_category" ? "Product Category/Sub cateogry" : captializestr(type)} Logs
            </div>
            <div style={{ position: "relative" }}>
              {type === "product_company" || type === "product_brand" ? <div></div> :
                <div className="frz-lm-20 frz-logs-by-ds frz-vertical-center">
                  <Select showSearch style={{ width: 321 }} value={dsItemId} placeholder="Select Darkstore" onChange={onDsSelect}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {dsList.map((data, index) => {
                      return (
                        <Option key={index} value={data.darkstore_item_id}>{data.darkstore_name}</Option>
                      )
                    })}
                  </Select>

                  <Button disabled={!dsItemId} className="frz-lm-20 frz-w-100px" type="primary" onClick={() => getLogsByDs(dsItemId)}>
                    Search
                  </Button>

                  <Button
                    className="frz-lm-20"
                    disabled={!dsItemId}
                    onClick={reset}
                    style={{ margin: 10 }}
                    type="primary"
                    ghost
                    shape="circle"
                    icon={<ReloadOutlined />}
                  />
                </div>
              }
            </div>

          </div>
        }
        onCancel={() => handleLogModal(false)}
        width="98%"
        style={{ float: "right" }}
        className="order"
        footer={[
          <div className="frz-dis-flex frz-justify-sb">
            {logData ?
              <Pagination className="frz-bg-fff" current={current}
                pageSize={50} total={total} onChange={pagination}
                showSizeChanger={false} />
              : <div></div>}
            <Button key="back" onClick={() => handleLogModal(false)}>
              Close
            </Button>
          </div>,
        ]}
      >
        {logData ?
          [logData].length ?
            <div className="logs-modal-container">
              <LogsView itemId={itemId} type={type} dsWiseLogs={dsWiseLogs} dsItemId={dsItemId}
                logData={logData} activeCardId={activeCardId} current={current} logDetail={logDetail}
                total={total} logData={logData} pagination={pagination} handleActiveCard={handleActiveCard} />
            </div>
            :
            <div className="logs-modal-container">
              <h3 className="frz-tp-24 frz-text-center frz-dis-flex frz-vertical-center frz-hrz-center">No Logs Found</h3>
            </div>
          : logData === null ?
            <div className="logs-modal-container">
              <h3 className="frz-tp-24 frz-text-center frz-dis-flex frz-vertical-center frz-hrz-center">No Logs Found</h3>
            </div> : <div className="spin-center logs-modal-container">
              <Spin tip="...Loading" />
            </div>
        }
      </Modal> :

      <div className="spin-center logs-modal-container">
        <Spin tip="...Loading" />
      </div>
  )
}

export default LogModal
