import React from "react";
import { Modal, Row, Col } from "antd"
import { svgHome } from "../../static/images/orders_customerlocation";
import { CheckCircleOutlined } from "@ant-design/icons";

export const PreviousOrdersModal = ({
  isPOModalVisible,
  handlePOBack, prevOrdersModalData, handleDateFormat
}) => {
  return (
    <Modal
      title=""
      visible={isPOModalVisible}
      onCancel={handlePOBack}
      footer={null}
      data={prevOrdersModalData}
      width={"75%"}
      closable={false}
    >
      <div className="pom-modal">
        <Row className="pom-back-btn-div">
          <Col span={24}>
            <button className="pom-back-btn" onClick={handlePOBack}>
              Back
            </button>
          </Col>
        </Row>
        {prevOrdersModalData.length > 0 ? (
          <>
            <div>
              <div>
                <Row className="pom-table-head-row" gutter={6}>
                  <Col span={3}>Date</Col>
                  <Col span={3}>Order No.</Col>
                  <Col span={4}>Customer Address</Col>
                  <Col span={6}>Flat Address</Col>
                  <Col span={4}>Delivered Location</Col>
                  <Col span={3}>Status</Col>
                </Row>
              </div>
            </div>
            <div className="location-table-body-nested-modal">
              {prevOrdersModalData?.map((el, index) => {
                return (
                  <Row key={index} className="location-table-body-row" gutter={6}>
                    <Col span={3}>{handleDateFormat(el.created_at)}</Col>
                    <Col span={3}>{el?.order_number}</Col>
                    <Col span={4}>
                      <div className="green-coordinates">
                        <div>Lat: {el.customer_address.latitude}</div>
                        <div>Lng: {el.customer_address.longitude}</div>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className="green-coordinates">
                        <div>Lat: {el.delivered_address.latitude}</div>
                        <div>Lng: {el.delivered_address.longitude}</div>
                      </div>
                      {el.flat_address}
                    </Col>
                    <Col span={4}>
                      <div className="delivered-location-flex">
                        <div className="green-coordinates">
                          <div>Lat: {el.delivered_address.latitude}</div>
                          <div>Lng: {el.delivered_address.longitude}</div>
                        </div>
                        <div className="svgHome">{svgHome()}</div>
                      </div>
                    </Col>
                    <Col span={3}>{el.delivered_via}</Col>
                    {el.status === "resolved" || el.status === "false_alarm" ?
                      <div className={el.status === "resolved" ? "frz-location-verified" : "frz-location-verified-red"}>
                        <div className="frz-dis-flex">
                          <div className="frz-dis-flex frz-vt-center">
                            <CheckCircleOutlined />
                          </div>
                          <div className="frz-lm-5">Verified</div>
                        </div>
                      </div>
                      : null}
                  </Row>
                );
              })}
            </div>
          </>
        ) : (
          <div className="po-no-data">No Data Available</div>
        )}
      </div>
    </Modal>
  )
}
