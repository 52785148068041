import React, { useState, useEffect, useRef } from 'react'
import { Card, Divider, Steps, Row, Col, Button, Form, DatePicker, Checkbox, Spin, Select, Modal, Segmented, Avatar, Affix, Popover, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import FRZInput from '../../_controls/FRZInput';
import { formItemLayout, tailLayout } from '../../_modules/controllayout';
import FRZRadioButton from '../../_controls/FRZRadioButton';
import FRZColorPickerTemp from '../../_controls/FRZColorPickerTemp';
import { LandingClusterService } from '../../_services/sdui/landing_cluster'
import FRZSelect from '../../_controls/FRZSelect';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import { openNotificationWithIcon } from '../../_modules/notification';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import { humanize } from '../../_modules/humanize';
import FRZInputNumber from '../../_controls/FRZInputNumber';
import FRZUpload from '../../_controls/FRZUpload';
import DebounceApp from '../../_controls/FRZDebounceSelect'
import FRAAZO from '../../_modules/http_headers';
import CreateLanding from './landing_clusters/create_landing';
import moment from 'moment';
import StepOne from './steps/stepone';
import CreateCluster from './landing_clusters/create_clusters';
import CreateBanners from './landing_clusters/create_banners';

const landing_cluster_service = new LandingClusterService()

const { Step } = Steps;
const { Option } = Select;

const CreateForm = ({ addInProgress, setActiveUnitId, setAddInProgress, activeUnitId, discardUpdate,
  onDiscardItm, setActiveUnit, activeUnit, unitData, imgLoading, staticData, segmentData, getListingData, updateId,
  setUpdateId, current, setCurrent, rowData, isSDUISuperAdmin }) => {
  const landingModalRef = useRef(null);
  const productModalRef = useRef(null);
  const bannerModalRef = useRef(null);

  const [stepOne, setStepOne] = useState(false)
  const [stepTwo, setStepTwo] = useState(false)
  const [headerColor, setHeaderColor] = useState('#000')
  // step 2
  const [title, setTitle] = useState(false)
  const [spin, setSpin] = useState(null)
  const [itemsChanged, setItemsChanged] = useState(false)
  const [clusterData, setClusterData] = useState(false)
  const [items, setItems] = useState(false)
  const [gridDisble, setGridDisble] = useState(false)
  const [currentSKU, setCurrentSKU] = useState(undefined)
  const [dynamicData, setDynamicData] = useState(false)
  const [imageState, setImageState] = useState({})
  const [colorData, setColorData] = useState({})
  const [dragNdrop, setDragNdrop] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(false)
  const [isAddProduct, setIsAddProduct] = useState(false)
  const [sourceType, setSourceType] = useState(null)
  const [segmentType, setSegmentType] = useState(false)
  const [landingPage, setLandingPage] = useState(null)
  const [selectedLandingPage, setSelectedLandingPage] = useState(false)
  const [clusterType, setClusterType] = useState(false)
  const [cloneModal, setCloneModal] = useState(false)
  const [clusterTitle, setClusterTitle] = useState(undefined)
  const [isPermanent, setIsPermanent] = useState(false)

  const [form] = Form.useForm();

  useEffect(() => {
    // console.log("use effect , rowData", rowData)
    if (rowData && updateId) {
      setClusterType(rowData.meta_data.cluster_type)
      setFormValues(rowData)
    }
  }, [rowData])

  useEffect(() => {
    if (dynamicData) {
      setClusterType(dynamicData.meta_data.cluster_type)
    }
  }, [dynamicData])

  // useEffect(() => {
  //   console.log("data in form", form.getFieldsValue())
  // })

  useEffect(() => {
    //reset everything on change of left panel active unit id
    setStepOne(activeUnit)
    form.setFieldsValue({
      productlist: [{
        value: undefined,
        label: undefined
      }]
    })
    setImageState({})
    // setClusterType(false)
    setSelectedLandingPage(false)
    setTitle(false)
    setLandingPage(null)
    setSourceType(null)
    setItems(false)
    setSpin(null)
    setDragNdrop(false)
    form.resetFields()
  }, [activeUnit])

  useEffect(() => {
    if (dragNdrop) {
      setTitle(dragNdrop.title)
      getitemsById(dragNdrop.id)
    }
  }, [dragNdrop])

  useEffect(() => {
    if (selectedProduct) {
      handleOnAddProduct(selectedProduct.id)
    }
  }, [selectedProduct])

  useEffect(() => {
    if (selectedLandingPage) {
      showLandingClusterById(selectedLandingPage.id)
    }
  }, [selectedLandingPage])

  const setFormValues = (rowData) => {
    setSegmentType(rowData.segments.length ? true : false)
    let dynamicForm = []
    rowData.meta_data.fields_meta.map((x) => {
      dynamicForm.push({ [x.field_type + "^" + x.field_key]: x.value })
    })
    // console.log("dynamicForm", dynamicForm)
    let colorObj = Object.assign({}, ...dynamicForm)
    setImageState({ upload: rowData.bg_image })
    // imageState.upload = rowData.bg_image
    setColorData(colorObj)
    let object = Object.assign({}, ...dynamicForm);
    setLandingPage(rowData.promotional_page_id ? true : false)
    setIsPermanent(rowData.is_permanent)
    setSelectedProduct(rowData.cluster_id ? true : false)
    // console.log("row data here", rowData)
    if (rowData.promotional_page_id) {
      setSelectedLandingPage({ id: rowData.promotional_page_id })
      showLandingClusterById(rowData.promotional_page_id)
    } else if (rowData.cluster_id) {
      getitemsById(rowData.cluster_id)
    } else {
      setSourceType(false)
    }
    form.setFieldsValue({
      ...rowData, segmentvalue: rowData.segments.length ? true : false,
      segment: rowData.segments.map((x) => x.slug),
      landingpage: rowData.promotional_page_id ? true : false,
      start_at: moment(rowData.start_at),
      end_at: rowData.end_at ? moment(rowData.end_at) : undefined,
      ...object
    })
    setDynamicData(rowData)
  }

  const showLandingClusterById = (id) => {
    landing_cluster_service.showLandingPageData(id).subscribe((r) => {
      form.setFieldsValue({
        productlist: [{
          value: r.response.data.id,
          label: r.response.data.title
        }]
      })
      setClusterData(r.response.data.cluster_promotional_pages[0])
      setItems(r.response.data?.cluster_promotional_pages[0]?.cluster_details)
      setTitle(r.response.data?.cluster_promotional_pages[0]?.cluster_name)
    }, ((err) => {
      console.log("err", err)
    }))
  }

  const onChange = (value) => {
    setCurrent(value);
  };

  const onLandingPageChange = (value) => {
    form.setFieldsValue({ type_of_data_source: undefined, productlist: undefined, 'string^label': undefined })
    setSourceType(null)
    setItems(false)
    setSpin(null)
    setLandingPage(value)
  }

  const onTypeDataSrcChange = (value) => {
    form.setFieldsValue({ productlist: undefined, data_source: undefined })
    setItems(false)
    setSpin(null)
    setSourceType(value)
  }

  const onContinue = () => {
    let tempValidation = []
    if (current == 1) {
      dynamicData.meta_data.fields_meta.filter((x) => (x.place === "step2")).map((item) => {
        tempValidation.push(item.field_type + "^" + item.field_key)
      })
      tempValidation.push(updateId && "status", "is_editable", sourceType === "cluster" ? "productlist" : "data_source",
        "type_of_data_source", "title", "start_at", "end_at", "segmentvalue", "landingpage",
        landingPage && "productlist", segmentType && "segment")
    }
    if (current == 2) {
      dynamicData.meta_data.fields_meta.filter((x) => (x.place === "step3")).map((item) => {
        tempValidation.push(item.field_type + "^" + item.field_key)
      })
    }
    let stepTwoValidation = tempValidation
    let isHighlight = false
    if (items.length) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].in_show_case) {
          isHighlight = true
          break
        }
      }
    }
    if (items.length && !isHighlight) {
      openNotificationWithIcon("error", "please highlight at least one item")
      return
    }
    checkValidationstatus(stepTwoValidation)
  }
  const onChangeValidFrom = (date, dateString) => {
    // console.log(date, dateString);
  }

  const checkValidationstatus = (validation) => {
    form
      .validateFields(validation)
      .then(values => {
        if (current <= 1) {
          // setStepTwo(true)
          setCurrent(current + 1)
        } else {
          let formValues = form.getFieldValue()

          //form data parse
          let parseData = []
          Object.keys(formValues).map((x) => {
            if (x.includes("^") && !x.includes("color")) {
              parseData.push({ [x.split("^")[1]]: formValues[x] })
            }
          })
          let temp = []
          Object.keys(formValues).map((x) => {
            if (x.includes("^") && x.includes("pre_defined_color")) {
              temp.push({ [x.split("^")[1]]: formValues[x] })
            }
          })
          // console.log("temp", temp)
          // parse color data
          let parseColorData = []
          Object.keys(colorData).map((x) => {
            if (x.includes("^")) {
              parseColorData.push({ [x.split("^")[1]]: colorData[x] })
            }
          })
          parseColorData.push(...temp)
          //segment data parse
          let segmentData = []
          if (formValues.segment) {
            formValues.segment.map((itm) => {
              segmentData.push({ slug: itm })
            })
          }

          setSpin(true)

          let object = Object.assign({}, ...parseData);
          let colorObj = Object.assign({}, ...parseColorData);
          object = { ...colorObj, ...object }
          const payload = {
            ...formValues,
            segmentvalue: false,
            is_permanent: formValues.is_permanent ? formValues.is_permanent : false,
            unit_id: updateId ? rowData.unit_id : activeUnit,
            status: updateId ? formValues.status : "active",
            meta_data: object,
            bg_image: imageState.upload,
            start_at: moment.utc(formValues.start_at).startOf('day'),
            end_at: isPermanent ? null : moment.utc(formValues.end_at).endOf('day'),
            type_of_data_source: formValues.type_of_data_source,
            segments_attributes: segmentType ? [...segmentData] : [],
            cluster_id: sourceType === "cluster" ? dragNdrop.id : null,
            "backend_driven_page_id": 100,
            promotional_page_id: landingPage ? selectedLandingPage.id ? selectedLandingPage.id : null : null,
          }
          // console.log("payload", payload)
          if (!updateId) {
            landing_cluster_service.createUnit(payload).subscribe((r) => {
              getListingData()
              setSpin(null)
              onDiscardItm()
              setActiveUnitId(r.response.data.id)
              openNotificationWithIcon("success", "Created Successfully")
            }, ((err) => {
              setSpin(null)
              setCurrent(0)
              setItemsChanged(false)
              setActiveUnit(false)
              openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
              console.log("err", err)
            }))
          } else {
            payload.id = updateId
            landing_cluster_service.updateUnit(payload, updateId).subscribe((r) => {
              getListingData()
              setSpin(null)
              setActiveUnitId(r.response.data.id)
              discardUpdate()
              openNotificationWithIcon("success", "Updated Successfully")
            }, ((err) => {
              setSpin(null)
              openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
              console.log("err", err)
            }))
          }
        }
      })
      .catch(info => {
        console.log('Validate Failed00000:', info);
      });
  }

  function changeHeaderColor(value, type) {
    setColorData(prevState => ({ ...prevState, [type]: value }))
  }

  //step 2
  const removeSelectedCluster = () => {
    form.setFieldsValue({ productlist: undefined })
    setItems(false)
    setSpin(null)
  }

  const getitemsById = (value) => {
    setSpin(true)
    setItemsChanged(false)
    landing_cluster_service.getProductList(value).subscribe((r) => {
      console.log("on it", !landingPage && "cluster", r.response.data) // cluster id
      setSourceType(!landingPage && "cluster")
      console.log("sourceType", sourceType)
      // if (!landingPage && sourceType !== null) {
      if (!landingPage && "cluster") {
        //bug here
        console.log("heree", {
          value: r.response.data.id,
          label: r.response.data.title
        })
        form.setFieldsValue({
          productlist: [{
            value: r.response.data.id,
            label: r.response.data.title
          }]
        })
      }
      setTitle(r.response.data.title)
      setSpin(false)
      setClusterData(r.response.data)
      setItems(r.response.data.cluster_details)
    }, ((err) => {
      setSpin(false)
      console.error({ err })
    }))
  }

  const onDeleteItm = (id) => {
    if (items.length == 1) {
      openNotificationWithIcon("error", "At least one item is required")
      return
    }
    let temp = items;
    let index = temp.indexOf(temp.find((x) => x.component_id == id))
    if (index > -1) {
      temp.splice(index, 1);
    }
    setItems([...temp])
    setItemsChanged(true)
  }

  const handleOnClone = () => {
    setGridDisble(true)
    let tempCluster = clusterData
    tempCluster.cluster_details.forEach(function (v) { delete v.id });
    const payload = {
      title: clusterTitle,
      type_of_cluster: clusterType,
      cluster_details_attributes: [
        ...items
      ]
    }
    //creating a new cluster-----
    landing_cluster_service.createCluster(payload).subscribe((r) => {
      if (!landingPage) {
        form.setFieldsValue({
          productlist: [{
            value: r.response.data.id,
            label: r.response.data.title
          }]
        })
      } else {
        let updateLandingPayload = { promotional_page: { cluster_promotional_pages_attributes: [{ cluster_id: r.response.data.id }] }, is_sdui: true }
        updateLandingPayload.id = rowData.promotional_page_id;
        landing_cluster_service.updateLandingPageData(updateLandingPayload, rowData.promotional_page_id).subscribe((r) => {
          // setGlobalSpin(false)
          // handleCancelModal()
          // openNotificationWithIcon("success", "Updated succesfully")
        }, ((err) => {
          // setGlobalSpin(false)
          openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
          console.error("err", err)
        }))
      }
      setDragNdrop(r.response.data)
      getitemsById(r.response.data.id)

      setCloneModal(false);
      setItemsChanged(false)
      setClusterTitle(undefined)
      openNotificationWithIcon('success', 'Cluster clonned Successfully')
      // setItems(false)
      setGridDisble(false)
    }, ((err) => {
      openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
      setGridDisble(false)
      console.error("err", err)
    }))
  }

  function onChangeDNDGrid(sourceId, sourceIndex, targetIndex, targetId) {
    if (sourceIndex == items.length) {
      return
    }
    const nextState = swap(items, sourceIndex, targetIndex);
    setItems(nextState);
    setItemsChanged(true)
  }

  const onSaveCluster = () => {
    setGridDisble(true)
    const payload = {
      cluster: {
        cluster_details_attributes: [
          ...items
        ]
      }
    }
    const urlParam = items[0].cluster_id
    landing_cluster_service.updateCluster(payload, urlParam).subscribe((r) => {
      openNotificationWithIcon('success', 'Updated Successfully')
      setGridDisble(false)
      setItemsChanged(false)
      setCurrentSKU(undefined)
    }, ((err) => {
      setGridDisble(false)
      openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
      console.error("err", err)
    }))
  }

  function handleClick({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  const uploadButton = (
    <div>
      {imgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleOnChangeImage = (type, item) => {
    setImageState(prevState => ({ ...prevState, [type]: item }))
  }

  const handleOnAddProduct = (value) => {
    // setItemsChanged(true)
    setCurrentSKU(value)
  }

  const OnAddProduct = () => {
    //check if item id already exist in data
    for (let i = 0; i < items.length; i++) {
      if (items[i].component_id === selectedProduct.id) {
        openNotificationWithIcon('error', 'selected item already exist, please select another item.')
        return
      }
    }
    const itemObj = {
      // constant data
      cluster_id: items[0].cluster_id, status: items[0].status,
      component_type: items[0].component_type, component_type: "Product",
      // id: items[0].id,
      component_id: selectedProduct.id,
      //dynamic data
      component_sku: selectedProduct.sku,
      component_image_url: selectedProduct.image_url, component_name: selectedProduct.name
    }
    let temp = items
    temp.push(itemObj)
    setItems([...temp])
    setSelectedProduct(false)
    form.setFieldsValue({ prodnamne: undefined })
    setIsAddProduct(false)
    setItemsChanged(true)
  }

  const OnAddBanner = () => {
    //check if item id already exist in data
    for (let i = 0; i < items.length; i++) {
      if (items[i].component_id === selectedProduct.id) {
        openNotificationWithIcon('error', 'selected item already exist, please select another item.')
        return
      }
    }
    const itemObj = {
      // constant data
      cluster_id: items[0].cluster_id, status: items[0].status,
      component_type: items[0].component_type, component_type: "Banner",
      // id: items[0].id,
      component_id: selectedProduct.id,
      //dynamic data
      component_sku: selectedProduct?.sku,
      component_image_url: selectedProduct.new_banner_image_url, component_name: selectedProduct.banner_name
    }
    let temp = items
    temp.push(itemObj)
    setItems([...temp])
    setSelectedProduct(false)
    form.setFieldsValue({ prodnamne: undefined })
    setIsAddProduct(false)
    setItemsChanged(true)
  }

  const onChangeCheckBox = (e, id) => {
    let temp = items
    setItemsChanged(true)
    let objIndex = temp.findIndex((obj => obj.id == id));
    temp[objIndex].in_show_case = e.target.checked
    setItems([...temp])
  };

  const onCancelAddProd = () => {
    setIsAddProduct(false)
    setSelectedProduct(false)
    form.setFieldsValue({ prodnamne: undefined })
  }

  const handleVisibleChange = (newVisible) => {
    setCloneModal(newVisible);
    setClusterTitle(undefined)
  };

  const onChangePermanent = (value) => {
    setIsPermanent(value)
  }

  return (
    <div>
      <Steps current={current} onChange={onChange}>
        <Step title="Unit type" disabled={updateId} />
        <Step title="Title & Cluster" disabled={!stepOne && !updateId} />
        <Step title="Add Theme"
          disabled={!stepOne || !stepTwo}
        />
      </Steps>

      <div className="frz-dis-rel frz-form-container frz-mt-60">
        {/* step - 1 */}
        <div className={`${current == 0 ? "" : "frz-dis-none"}`}>
          <StepOne unitData={unitData} activeUnit={activeUnit} form={form}
            setActiveUnit={setActiveUnit} setDynamicData={setDynamicData} />
        </div>

        {/* step - 2 */}
        <div className={`${current == 1 ? "" : "frz-dis-none"}`}>
          <Row>
            <Col span={5}>
            </Col>
            <Col>
              <div className='frz-dis-flex'>
                <div>
                  Unit preview:
                </div>
                <img className='frz-lp-20 frz-bm-20' src={updateId ?
                  unitData?.find((x) => x?.id === rowData?.unit_id)?.example_image_url
                  : unitData?.find((x) => x?.id === activeUnit)?.example_image_url
                } style={{ width: 200, height: 170 }} />
              </div>
            </Col>
          </Row>

          <Form
            {...formItemLayout}
            onChange={() => setStepTwo(false)}
            form={form}
            layout="horizontal"
            name="form_in_modal">

            <FRZInput name="title" isRequired={true}
              label="Unit Title" placeholder="Unit Title" width={300} />

            <FRZRadioButton defaultValue={false} disabled={!isSDUISuperAdmin} isRequired={true} label={"Is permanent"}
              name={'is_permanent'} setOnChange={(value) => onChangePermanent(value)} valueFalse="No" valueTrue="Yes" />

            <Form.Item
              {...tailLayout}
              name={"start_at"}
              label="Valid from"
              rules={[
                {
                  required: true,
                  message: `Please input the value of valid from date!`,
                }
              ]}>
              <DatePicker style={{ width: 120 }} placeholder="date" onChange={onChangeValidFrom} />
            </Form.Item>

            {!isPermanent ?
              <Form.Item
                {...tailLayout}
                name={"end_at"}
                label="Valid till"
                rules={[
                  {
                    required: true,
                    message: `Please input the value of valid till date!`,
                  }
                ]}>
                <DatePicker style={{ width: 120 }} placeholder="date" onChange={onChangeValidFrom} />
              </Form.Item>
              : null}
            {/* {console.log("dynamicData", dynamicData)} */}
            {dynamicData?.meta_data?.fields_meta?.filter((x) => x.place === "step2").map((item, index) => {
              return (
                item.field_type === "string" ?
                  item.label !== "label" && <FRZInput key={index} name={item.field_type + "^" + item.field_key} isRequired={true}
                    label={humanize(item.label)} width={200} />
                  :
                  item.field_type === "boolean" ?
                    <FRZRadioButton key={index} isRequired={true} label={humanize(item.label)}
                      name={item.field_type + "^" + item.field_key} />
                    :
                    item.field_type === "integer" ?
                      <FRZInputNumber minValueZero={true} key={index} name={item.field_type + "^" + item.field_key} isRequired={true}
                        label={humanize(item.label)} width={200} />
                      : item.field_type === "color" ?
                        <FRZColorPickerTemp
                          key={index}
                          label={humanize(item.label)}
                          formItemClass="frz-form-group"
                          noStyle={true}
                          value={colorData[item.field_type + "^" + item.field_key]}
                          pickerColor={colorData[item.field_type + "^" + item.field_key] ? colorData[item.field_type + "^" + item.field_key] : "#000"}
                          changePickerColor={(e) => changeHeaderColor(e, item.field_type + "^" + item.field_key)}
                          setPickerColor={setHeaderColor}
                          columnSpan={7}
                          isRequired={true}
                        />
                        : item.field_type === "upload" ?
                          <FRZUpload key={index} name={item.field_type} className="avatar-uploader" customRequest={handleClick}
                            imgWidth={"100%"} imgHeight={"100%"} label={humanize(item.label)} listType="picture-card" setOnChangeType={item.field_type}
                            showUploadList={false} uploadButton={uploadButton} handleOnChangeImage={handleOnChangeImage}
                            imageState={imageState} /> : null
              )
            })}

            <FRZRadioButton isRequired={true} label={"Is Sequenceable"}
              name={'is_editable'} valueFalse="No" valueTrue="Yes" />

            <FRZRadioButton isRequired={true} label={"Is demo"}
              name={'is_demo'} valueFalse="No" valueTrue="Yes" />

            {/* <FRZRadioButton isRequired={true} label={"Add Segment"}
              name={'segmentvalue'} setOnChange={(value) => setSegmentType(value)} valueFalse="No" valueTrue="Yes" /> */}

            {segmentType ?
              <FRZSelect DropDownData={segmentData} name="segment" isRequired={true}
                label="Select segment" value="slug" option="name"
                mode="multiple"
                isShowSearch={true} width={200} />
              : null}
            {/* {console.log("clusterType", clusterType)} */}
            {clusterType !== "products" ?
              null :
              <FRZRadioButton isRequired={true} label={"Landing Page"}
                name={'landingpage'} setOnChange={(value) => onLandingPageChange(value)} valueFalse="No" valueTrue="Yes" />}

            {dynamicData?.meta_data?.fields_meta?.filter((x) => x.place === "step2").map((item, index) => {
              // console.log("item.field_type", item.field_type + "^" + item.field_key)
              return (
                item.field_type === "string" &&
                item.label === "label" &&
                landingPage && <FRZInput key={index} name={item.field_type + "^" + item.field_key} isRequired={true}
                  label={humanize(item.label)} width={200} />
              )
            })}

            {
              updateId ?
                <FRZSelect DropDownData={staticData.statuses
                } name="status" isRequired={true}
                  label="Select status"
                  isShowSearch={true} width={200} />
                : null}

            {/* landing pg -> yes || ds -> null || cluster id-> null
landing pg -> no || sys -> ds-> yes || cluster id-> null -> prom pg id-> null
landing pg -> no || manual -> ds -> null || cluster id-> yes ||  prom pg id-> null
*/}


            {landingPage ?
              <>
                {/* {console.log("clusterType", clusterType)} */}
                <DebounceApp isRequired={true} name="productlist" placeholder="Select Landing Page" label="Landing Page" method="get"
                  headers={FRAAZO()} labelTerm="title" valueTerm="id"
                  url={process.env.REACT_APP_API_URL +
                    `admin_dashboard/v1/marketing/promotional_pages?by_type_of_cluster=${clusterType}&by_title=`
                  }
                  dragNdrop={selectedLandingPage}
                  setDragNdrop={(value) => setSelectedLandingPage(value)} />
              </>
              :
              landingPage !== null && clusterType !== "banners" && clusterType !== "categories" && <FRZSelect DropDownData={staticData.type_of_data_sources} name="type_of_data_source" isRequired={true}
                label="Select data sources type"
                isShowSearch={true} width={200} setOnChange={(value) => onTypeDataSrcChange(value)} />
            }
            {clusterType == "banners" || clusterType == "categories" ?
              <FRZSelect DropDownData={clusterType == "categories" ? staticData.type_of_data_sources.filter((x) => x !== "cluster")
                : staticData.type_of_data_sources} name="type_of_data_source" isRequired={true}
                label="Select data sources type"
                isShowSearch={true} width={200} setOnChange={(value) => onTypeDataSrcChange(value)} />
              : null}


            {sourceType === "cluster" ?
              <>
                <DebounceApp isRequired={true} name="productlist" placeholder="Select Cluster"
                  label={"Select " + clusterType + " cluster"} method="get"
                  headers={FRAAZO()} labelTerm="title" valueTerm="id"
                  url={process.env.REACT_APP_API_URL +
                    `/admin_dashboard/v1/marketing/clusters?by_type_of_cluster=${clusterType}&by_cluster_title=`
                    // `/admin_dashboard/v1/marketing/clusters?by_type_of_cluster=${dynamicData?.meta_data?.cluster_type}&by_cluster_title=`
                  }
                  dragNdrop={dragNdrop}
                  setDragNdrop={(value) => setDragNdrop(value)} />
                <div className="frz-bm-20">
                  <Row>
                    <Col span={4}>
                    </Col>
                    <Col>
                      {clusterType == "banners" ?
                        <Button onClick={() => bannerModalRef.current.showModal(true, 'Create Banners')} className="frz-btn"
                          type="ghost">Create Banners</Button>
                        :
                        <Button onClick={() => productModalRef.current.showModal(true, 'Create Clusters')} className="frz-btn"
                          type="ghost">Create product cluster</Button>
                      }
                    </Col>
                  </Row>
                </div>
              </>
              : sourceType !== null && !landingPage &&
              <FRZSelect DropDownData={clusterType === "products" ? staticData.data_sources['products'] :
                clusterType === "categories" ? staticData.data_sources['categories'] :
                  staticData.data_sources['banners']}
                name="data_source" isRequired={true}
                label="Select data sources"
                isShowSearch={true} width={200} />}
            {landingPage &&
              <div className="frz-bm-20">
                <Row>
                  <Col span={4}>
                  </Col>
                  <Col>
                    <Button type="ghost" onClick={() => landingModalRef.current.showModal(true, 'Create Landing Page')}>
                      Create new landing page
                    </Button>
                  </Col>
                </Row>
              </div>

            }

            {!spin && items ?
              <Row>
                <Col span={4}>
                </Col>
                <Col span={17}>
                  <Spin tip="Loading..." spinning={gridDisble}>
                    <div className="frz-dis-flex frz-bm-20">
                      <div className="frz-selected-title frz-vt-center frz-dis-flex frz-justify-sb">
                        {title}
                        <div className="frz-lp-10 frz-font-10 frz-cursor-pointer" onClick={removeSelectedCluster}>
                          <CloseOutlined />
                        </div>
                      </div>
                      <div className="frz-lp-10 frz-dis-flex frz-vt-center">
                        <div className="frz-rp-12">
                          <Button type="primary" disabled={!itemsChanged} onClick={onSaveCluster} loading={gridDisble}>
                            Update cluster
                          </Button>
                        </div>
                        {itemsChanged ?
                          <Popover
                            // zIndex={!itemsChanged ? "-1" : "3"}
                            content={<div>
                              <Input value={clusterTitle} onChange={(e) => setClusterTitle(e.target.value)}
                                style={{ width: 120 }} placeholder='Enter title' />
                              <Button disabled={!clusterTitle || spin} className='frz-lm-10' onClick={() => handleOnClone()}>
                                Clone
                              </Button>
                            </div>}
                            title="Enter title for new cluster"
                            trigger="click"
                            visible={cloneModal}
                            onVisibleChange={handleVisibleChange}
                          >
                            <Button type="ghost" onClick={() => setCloneModal(true)}
                              disabled={!itemsChanged}
                            // onClick={handleOnClone}
                            >
                              Clone & create new cluster
                            </Button>
                          </Popover>
                          :
                          <Button type="ghost" onClick={() => setCloneModal(true)}
                            disabled={!itemsChanged}
                          // onClick={handleOnClone}
                          >
                            Clone & create new cluster
                          </Button>
                        }
                      </div>
                    </div>
                    {items.length ?
                      <div className='frz-bm-10'>
                        <span style={{ marginLeft: 10 }}>
                          Item count:  <span>{items.length}</span>
                        </span>
                      </div>
                      : null}
                    <GridContextProvider onChange={onChangeDNDGrid}>
                      <GridDropZone
                        disableDrag={gridDisble}
                        disableDrop={gridDisble}
                        id="items"
                        boxesPerRow={4}
                        rowHeight={135}
                        style={{ height: items.length == 1 ? "125px" : `calc(55px * ${items.length})`, overflow: "scroll" }}>
                        {items.map(item => (
                          <GridItem key={item.component_id}>
                            <div className={`frz-prod-contol-card frz-pos-realtive  ${gridDisble ? 'frz-cursor-disable' : 'frz-cursor-dragg'}`}>
                              <div className="frz-p-8 frz-dis-flex frz-hrz-center">
                                <div className="frz-pos-abs frz-r-5 frz-t-5 frz-font-10 frz-cursor-pointer" onClick={() => onDeleteItm(item.component_id)}>
                                  <CloseOutlined />
                                </div>
                                <div>
                                  <div className="frz-dis-flex frz-hrz-center">
                                    <img draggable={false} src={item.component_image_url} className="frz-prod-contol-img" />
                                  </div>
                                  <div className="frz-text-center frz-seq-grid-txt">
                                    {item.component_name}
                                  </div>
                                  <div className="frz-dis-flex frz-hrz-center">
                                    <Checkbox
                                      className={item.in_show_case ? "frz-custom-checkbox-test-high" : "frz-custom-checkbox-disable-test-high"}
                                      defaultChecked={item.in_show_case} onChange={(e) => onChangeCheckBox(e, item.id)}>
                                      <div className={item.in_show_case ? "frz-custom-checkbox-text" : "frz-custom-checkbox-text-disable"}>
                                        Highlight
                                      </div>
                                    </Checkbox>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </GridItem>
                        ))}
                        <GridItem>
                          {isAddProduct ?
                            <Modal title={landingPage ? "Add product" : clusterType === "products" ? "Add product" : "Add banner"}
                              visible={isAddProduct} footer={false} onCancel={onCancelAddProd}>

                              <DebounceApp isRequired={true} name="prodnamne"
                                placeholder={landingPage ? "Select product" : clusterType === "products" ? "Select product" : "Select banner"}
                                label={landingPage ? "Select product" : clusterType === "products" ? "Select product" : "Select banner"}
                                method="get" headers={FRAAZO()}
                                labelTerm={landingPage ? "name" : clusterType === "products" ? "name" : "banner_name"} valueTerm="id"
                                url={process.env.REACT_APP_API_URL + (landingPage ? '/admin_dashboard/v1/products/products?starts_with='
                                  : clusterType === "products" ? '/admin_dashboard/v1/products/products?starts_with='
                                    : '/admin_dashboard/v1/admin/banners?by_banner_name=')
                                }
                                dragNdrop={selectedProduct}
                                setDragNdrop={(value) => setSelectedProduct(value)} />

                              <div className="frz-dis-flex frz-justify-end frz-tm-15">
                                <Button type="primary"
                                  onClick={() => landingPage ? OnAddProduct() : clusterType === "products" ? OnAddProduct() : OnAddBanner()}
                                  disabled={!currentSKU}>
                                  Add
                                </Button>
                                <Button className="frz-lm-10" type="ghost" onClick={() => setIsAddProduct(false)}>
                                  Cancel
                                </Button>
                              </div>
                            </Modal>
                            :
                            <div className="frz-prod-contol-card frz-pos-realtive frz-cursor-pointer">
                              <div className="frz-p-8 frz-dis-flex frz-hrz-center" onClick={() => {
                                setIsAddProduct(true)
                                setCurrentSKU(undefined)
                              }}>
                                {landingPage ? "Add product" : clusterType === "products" ? "Add product" : "Add banner"}
                              </div>
                            </div>}
                        </GridItem>

                      </GridDropZone>
                    </GridContextProvider>
                  </Spin>
                </Col>
              </Row>
              :
              spin === null ? "" : <div className="spin-center">
                <Spin tip="...Loading" />
              </div>}
          </Form>
        </div>

        {/* step - 3 */}
        <div className={current !== 2 ? "frz-dis-none" : ""}>
          <Spin tip="Loading..." spinning={spin}>
            <Form
              // onChange={() => setStepTwo(false)}
              form={form}
              {...formItemLayout}
              layout="horizontal"
              name="form_in_modal">
              {
                dynamicData?.meta_data?.fields_meta.filter((x) => x.place === "step3").length ?
                  dynamicData?.meta_data?.fields_meta?.filter((x) => x.place === "step3").map((item, index) => {
                    return (
                      item.field_type === "string" ?
                        <FRZInput key={index} name={item.field_type + "^" + item.field_key} isRequired={true}
                          label={humanize(item.label)} width={200} />
                        :
                        item.field_type === "pre_defined_color" ?
                          <Form.Item
                            key={index}
                            {...tailLayout}
                            name={item.field_type + "^" + item.field_key}
                            label={humanize(item.label)}
                          >
                            <Segmented
                              key={index}
                              options={[
                                ...item.options.map((itm, index) => {
                                  return (
                                    {
                                      label: (
                                        <div key={index}>
                                          <Avatar style={{ backgroundColor: itm }} />
                                        </div>
                                      ),
                                      value: itm
                                    }
                                  )
                                })]}
                            />
                          </Form.Item>
                          :
                          item.field_type === "boolean" ?
                            <FRZRadioButton key={index} isRequired={true} label={humanize(item.label)}
                              name={item.field_type + "^" + item.field_key} />
                            :
                            item.field_type === "integer" ?
                              <FRZInputNumber key={index} name={item.field_type + "^" + item.field_key} isRequired={true}
                                label={humanize(item.label)} width={200} />
                              : item.field_type === "color" ?
                                <FRZColorPickerTemp
                                  key={index}
                                  label={humanize(item.label)}
                                  formItemClass="frz-form-group"
                                  noStyle={true}
                                  value={colorData[item.field_type + "^" + item.field_key]}
                                  pickerColor={colorData[item.field_type + "^" + item.field_key]}
                                  changePickerColor={(e) => changeHeaderColor(e, item.field_type + "^" + item.field_key)}
                                  setPickerColor={setHeaderColor}
                                  columnSpan={7}
                                  isRequired={true}
                                />
                                : item.field_type === "upload" ?
                                  <FRZUpload key={index} name={item.field_type} className="avatar-uploader" customRequest={handleClick}
                                    imgWidth={"100%"} imgHeight={"100%"} label={humanize(item.field_key)} listType="picture-card" setOnChangeType={item.field_type}
                                    showUploadList={false} uploadButton={uploadButton} handleOnChangeImage={handleOnChangeImage}
                                    imageState={imageState} /> : null
                    )
                  })
                  : <div className="no-data">No theme required for selected unit</div>
              }
            </Form>
          </Spin>
        </div>

        <div className='frz-dis-flex frz-flex-end'>
          <Affix offsetBottom={52}>
            <Row>
              <Col span={24}>
                <Button type="ghost" onClick={updateId ? () => discardUpdate(true) : onDiscardItm} disabled={spin}>
                  Discard
                </Button>

                <Button type="primary" className="frz-lm-10"
                  disabled={(!activeUnit && !updateId) || spin || itemsChanged}
                  onClick={() => onContinue()}>
                  {current == 2 ? "Publish" : "Continue"}
                </Button>
              </Col>
            </Row>
          </Affix>


        </div>
      </div>
      <CreateLanding ref={landingModalRef} />
      <CreateCluster ref={productModalRef} clusterType={clusterType} />
      <CreateBanners ref={bannerModalRef} />
    </div>
  )
}
export default CreateForm