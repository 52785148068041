import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form } from 'antd';
import FRZSelect from '../../_controls/FRZSelect';
import { formItemLayout } from '../../_modules/controllayout';


const BillingModal = forwardRef((props, ref) => {
  const { modalSubmit, roles } = props

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState(false)
  const [data, setData] = useState(false)
  const [status] = useState([{ id: 1, name: 'APPROVED' }, { id: 2, name: 'PAID' }, { id: 3, name: 'REJECTED' }])

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setData(false)
      form.resetFields()
      setVisible(false)
      setLoading(false)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleCancelModal() {
    setData(false)
    form.resetFields()
    setVisible(false)
    setLoading(false)
  }

  const handleOnSubmit = (values) => {
    setLoading(true)
    modalSubmit(values, data.id)
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            <FRZSelect DropDownData={status} name="status" isRequired={true}
                       label="Status" option="name" isShowSearch={true}
                       width={200} value="name"/>

          </Form>
        </Modal>
        :   <Form
        form={form}
        {...formItemLayout}
        layout="horizontal"
        name="form">
        </Form>}
    </div>
  )
})

export default BillingModal
