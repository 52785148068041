import React, { forwardRef, useState, useImperativeHandle, useRef } from 'react'
import { Spin, Modal, Button, Row, Col, Tabs, notification} from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import ReviewedItems from './reviewed_items';
import { maskingNumber } from '../../_modules/masking';

const { TabPane } = Tabs;


const ReviewModal = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

  }));

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onCancel={handleCancelModal}
          width='85%'
          style={{float: 'right'}}
          className="order"
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Close
            </Button>,
          ]}
        >
          <div className="order-modal-container">
            <div>
              <Row>
                <Col md={7}>
                  <div style={{borderRight: '1px solid #f0f0f0', paddingRight: 24,
                               height: '100%'}}>
                    <div>
                      <b>Customer Name :</b>
                      <span style={{paddingLeft:10}}>
                        {data.order_details.customer_details.name}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Mobile No :</b>
                      <span style={{paddingLeft:10}}>
                        {data.order_details.customer_details.mobile_number ? 
                         maskingNumber(data.order_details.customer_details.mobile_number) : ''}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Order No :</b>
                      <span style={{paddingLeft:10}}>
                        {data.order_details.order_number}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Delivery Date :</b>
                      <span style={{paddingLeft:10}}>
                        {data.order_details.delivery_date}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Confirmed At :</b>
                      <span style={{paddingLeft:10}}>
                        {data.order_details.confirmed_at}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Delivered At :</b>
                      <span style={{paddingLeft:10}}>
                        {data.order_details.delivered_at}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Order Type :</b>
                      <span style={{paddingLeft:10}}>
                        {data.order_details.order_type_detail}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Partially Delivered :</b>
                      <span style={{paddingLeft:10}}>
                        {data.order_details.is_partially_delivered.toString()}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Darkstore :</b>
                      <span style={{paddingLeft:10}}>
                        {data.order_details.darkstore_name}
                      </span>
                    </div>
                    {/*{data.order_details.delivery_slot_details ?
                        <div className="order-detail-padding">
                          <b>Delivery Slot Details :</b>
                          <span style={{paddingLeft:10}}>
                            {data.order_details.delivery_slot_details.from_time} to {data.order_details.delivery_slot_details.to_time} {data.order_details.delivery_slot_details.am_pm}
                          </span>
                        </div>
                    : null}*/}
                    {data.order_details.customer_address ?
                      <div>
                        <div className="order-detail-padding"
                             style={{paddingTop: 20, color: '#262624'}}>
                          <b>#Address</b>
                        </div>
                        <div className="order-detail-padding">
                          <b>Flat :</b>
                          <span style={{paddingLeft:10}}>
                            {data.order_details.customer_address.flat_detail}
                          </span>
                        </div>
                        <div className="order-detail-padding">
                          <b>Landmark :</b>
                          <span style={{paddingLeft:10}}>
                            {data.order_details.customer_address.landmark}
                          </span>
                        </div>
                        <div className="order-detail-padding">
                          <b>City :</b>
                          <span style={{paddingLeft:10}}>
                            {data.order_details.customer_address.city}
                          </span>
                        </div>
                        <div className="order-detail-padding">
                          <b>Pincode :</b>
                          <span style={{paddingLeft:10}}>
                            {data.order_details.customer_address.pincode}
                          </span>
                        </div>
                      </div>
                    : null}
                    {data.order_review ?
                      <div>
                        <div className="order-detail-padding"
                             style={{paddingTop: 20, color: '#262624'}}>
                          <b>#Review</b>
                        </div>
                        <div className="order-detail-padding">
                          <b>Rating :</b>
                          <span style={{paddingLeft:10}}>
                            {data.order_review.ratings}
                          </span>
                        </div>
                        <div className="order-detail-padding">
                          {data.order_review.ratings > 3 ?
                            <b>Customer Liked:</b>
                          : <b>Customer Disliked:</b>}
                          <span style={{paddingLeft:10}}>
                            {data.order_review.app ? (data.order_review.app + ', ') : ''}
                            {data.order_review.packaging ? (data.order_review.packaging + ', ') : ''}
                            {data.order_review.pricing ? (data.order_review.pricing + ', ') : ''}
                            {data.order_review.payment ? (data.order_review.payment + ', ') : ''}
                            {data.order_review.quality ? (data.order_review.quality + ', ') : ''}
                            {data.order_review.quantity ? (data.order_review.quantity + ', ') : ''}
                            {data.order_review.time ? (data.order_review.time + ', ') : ''}
                            {data.order_review.customer_support ? (data.order_review.customer_support + ', ') : ''}
                            {data.order_review.delivery_partner ? (data.order_review.delivery_partner + ', ') : ''}
                            {data.order_review.other ? (data.order_review.other) : ''}
                          </span>
                        </div>
                      </div>
                    : null}
                  </div>
                </Col>
                <Col md={17}>
                  <div style={{paddingLeft: 24}}>
                    <Tabs defaultActiveKey="1">
                      <TabPane
                        tab={<span>
                               <ShoppingCartOutlined />
                               Reviewed Items
                             </span>}
                        key="1"
                      >
                        <ReviewedItems orderDetail={data.order_details.order_line_items} />
                      </TabPane>
                    </Tabs>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Modal>
      : null }
    </div>
  )
})

export default ReviewModal
