import React, { useEffect, useState } from 'react';
import { Spin, Button, Card, Row, Col, Tabs, Input, 
         Pagination, DatePicker, Table } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {FulfillmentService} from '../../_services/fulfillment';
import Search from '../../components/antd/search';
import {dsfulfillmentColumns} from '../../components/antd/columns/fulfillment';
import {productunfulfillmentColumns} from '../../components/antd/columns/fulfillment';
import moment from 'moment';
import FrzDynamicDownload from '../../_controls/FRZDynamicDownload';

const fulfillment_service = new FulfillmentService()
const { TabPane } = Tabs;

function DarkstoreFullfillment(){
  const [listingData, setListingData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [statsData, setStatsData] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [currentTab, setCurrentTab] = useState(1);
  const [state, setState] = useState({valueBySource: undefined, valueBySKU: undefined, valueByDeliveryDate: '', date: ''});
  const { valueBySource, valueBySKU, valueByDeliveryDate, date } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getListing(currentTab)
    }
  }, [currentTab, renderData])

  useEffect(() => {
    getColumns(currentTab)
  }, [currentTab])

  function getListing(key){
    setSpinEnable(true)
    setListingData([])
    const payload = {
      page: current,
      darkstore_id: !valueBySource ? '' : valueBySource,
      date: !valueByDeliveryDate ? '' : valueByDeliveryDate,
      by_sku_name: !valueBySKU ? '': valueBySKU.toUpperCase(),
    }
    if(key==1){
      delete payload.by_sku_name
      fulfillment_service.darkstore_fulfillment(payload).subscribe((r)=>{
        setStatsData(r.response.meta)
        setState(prevState =>({...prevState}))
        setListingData(r.response.data)
        setSpinEnable(false)
      },
      (err)=>{
        setSpinEnable(false)
        setListingData([])
        console.log(err)
      })
  }
  else{
    
    fulfillment_service.product_unfulfillment(payload).subscribe((r)=>{
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setListingData(r.response.data)
      setSpinEnable(false)
    },
    (err)=>{
      setSpinEnable(false)
      setListingData([])
      console.log(err)
    })
  }
  }

  function changeTab(key) {
    setState({valueBySource: undefined, valueBySKU: undefined, valueByDeliveryDate: '', date: ''})
    setCurrentTab(key);
    setListingData([]);
    setDisable(true);
    setRenderData(false);
  }

  function getColumns(key){
    if(key==1){
    let tempCol = []
    dsfulfillmentColumns.map(data => {
      tempCol.push(data)
    })
    setColumns(tempCol)
  }
  else{
    let tempCol = []
    productunfulfillmentColumns.map(data => {
      tempCol.push(data)
    })
    setColumns(tempCol)
  }
  }

  function pagination(page) {
    setCurrent(page)
    setListingData([])
    setRenderData(false)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function onChangeDate(date, dateString) {
    setState(prevState =>({...prevState, date: date, valueByDeliveryDate: dateString}))
    setDisable(false)
    setCurrent(1)
  }

  function reset() {
    setState({valueBySource: undefined, valueBySKU: undefined, valueByDeliveryDate: '', date: ''})
    setDisable(true)
    setCurrent(1)
    setListingData([])
    setRenderData(false)
  }

  function disabledDate(current){
    // Can select days before today and today
    return current && current > moment().add(-1, "day");
  }

  return(
    <div className="layer">
      { localStorage.getItem('cityid') !== "" ?
      <div>
      <div className="ost">
        <Tabs defaultActiveKey="1" onChange={changeTab}>
          <TabPane tab="Darkstore Fulfillment" key="1"></TabPane>
          <TabPane tab="Product Unfulfillment" key="2"></TabPane>
        </Tabs>
      </div>
      
      {listingData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
             
                <Search placeholder="Darkstore" value={valueBySource} width="medium"
                        onChange={(e) => onChange(e, 'valueBySource')} type="valueByDSCode"/>
                 {currentTab == 2 ? (
                  <Input
                    placeholder="SKU Name"
                    onChange={(e) => onChange(e.target.value, "valueBySKU")}
                    value={valueBySKU}
                    style={{ width: 200, margin: 10 }}
                  />
                ) : null}
                <DatePicker value={date} onChange={onChangeDate} placeholder="Date"
                            style={{width: 200, margin: 10}} disabledDate={disabledDate}/>
                <Button disabled={disable} onClick={(e) => getListing(currentTab)} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                {currentTab==1 && statsData && Object.entries(statsData).map(([key, value]) => {
                    return(
                      <span key={key} className="stats"><b>{key.split('_').join(' ').charAt(0).toUpperCase() + key.split('_').join(' ').slice(1)}: </b>{value}</span> 
                    )
                })}
                <span className="fl-right">
                  {currentTab == 1 ?
                    <FrzDynamicDownload name={'Darkstore Fulfillment'} fileName={'Darkstore Fulfillment'}
                      url={'admin/darkstores_fulfillment'}
                      payload={`?date=${date ?? ''}&node_code=${valueBySource ?? ''}&type=report`} 
                      type={'job'} reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
                  :
                    <FrzDynamicDownload name={'Product Unfulfillment'} fileName={'Product Unfulfillment'}
                      url={'admin/product_unfulfilled'}
                      payload={`?page=&date=${date ?? ''}&by_sku_name=${valueBySKU ?? ''}&by_city=${localStorage.getItem('city') ?? ''}&node_code=${valueBySource ?? ''}&type=report`} 
                      type={'job'} reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
                  } 
                </span>
              </Col>
            </Card>
          </Row>           
          {listingData.length > 0 ?
            <Row>
              <Col span={24}>
                <Card style={{ paddingTop: 5, marginTop: 20 }}>
                    <Row>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                dataSource={listingData}
                                pagination={false}
                                scroll={{ y: 'calc(105vh - 410px)'}}
                                rowKey="name"
                            />
                        </Col>
                    </Row>
                </Card>
               {currentTab == 2 ? 
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                  pageSize={50} total={state.total} onChange={pagination}
                  showSizeChanger={false}/>
                : null}
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
      </div>
      :
      <div className="no-data">Please select city</div>
    }
    </div>
  )
}

export default DarkstoreFullfillment
