export const companyListColumns = [
    {
        title: "Company",
        dataIndex: "company_name",
        key: "company_name",
        align: "center",
    },
    {
        title: "Player Type",
        dataIndex: "type_of_player",
        key: "type_of_player",
        align: "center",
    },
]

export const brandListColumns = [
    {
        title: "Brand",
        dataIndex: "brand_name",
        key: "brand_name",
        align: "center",
    },
    {
        title: "Code",
        dataIndex: "code",
        key: "code",
        align: "center",
    },
    {
        title: "Company",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
          return record.product_company?.company_name
        }
    },
]