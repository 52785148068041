import React, { useEffect, useState } from 'react';
import ImsC from './ims_c';
import ImsD from './ims_d';
import ImsE from './ims_e';
import ImsF from './ims_f';

function ImsCDE(props) {
  const [period] = useState([{ period: 'DAILY', name: 'Daily', id: 1 },
  { period: 'WEEKLY', name: 'Weekly', id: 2 }, { period: 'MONTHLY', name: 'Monthly', id: 3 }])

  // useEffect(() => {
  //   props.form.setFieldsValue({distance_config:[{distance: 10, rate: 4}, {distance: 45, rate: 33}]})
  // }, [])

  return (
    <div className='frz-ims-box frz-fuel-radio'>
      <div className='frz-ims-text-incentive'>
        Incentives
      </div>
      <ImsC period={period} form={props.form} handleOnFormChange={props.handleOnFormChange} billingValue={props.billingValue} billingChange={props.billingChange} />
      <ImsD period={period} form={props.form} handleOnFormChange={props.handleOnFormChange} billingValue={props.billingValue} billingChange={props.billingChange} />
      <ImsE period={period} form={props.form} handleOnFormChange={props.handleOnFormChange} billingValue={props.billingValue} />
      <ImsF period={period} form={props.form} handleOnFormChange={props.handleOnFormChange} />
    </div>
  )
}

export default ImsCDE