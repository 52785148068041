import React, { useState, useEffect } from 'react';
import { Upload, Row, Col, Card, Button, Spin, notification, Select } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { UploadService } from '../../_services/uploads';
import FRZSelect from '../../_controls/FRZSelect';
import FRZDatepicker from '../../_controls/FRZDatepicker';
import FRZTimePicker from '../../_controls/FRZTimePicker';
import moment from 'moment';

const upload_service = new UploadService()

const { Dragger } = Upload;

function UploadList() {
  const [uploadFile, setUploadFile] = useState(false)
  const [current, setCurrent] = useState(false)
  const [currentSpin, setCurrentSpin] = useState(false)
  const [spinEnable, setSpinEnable] = useState(false)
  const [uploadData1, setUploadData1] = useState(
    [{ name: 'Upload Prices Sheet', url: 'upload_price_sheet', type:'price' },
    { name: 'Upload Max Quantity Sheet', url: 'upload_max_qty_sheet', type:'price' },
    { name: 'Upload Slot Changes', url: 'upload_slot_changes', type:'slot'},
    // { name: 'Upload Product Discount', url: 'upload_product_discount', type:'other' }
    ])
  const [uploadData, setUploadData] = useState(
    [
      { name: 'Bulk Upload', url: 'bulk_upload_sheet', type: "dropdown" },
      { name: 'Schedule Price Sheet', url: 'schedule_price_sheet', disabled: true, type: "dropdown1" },
      { name: 'Upload Scheduled Max Quantity Sheet', url: 'upload_scheduled_max_qty_sheet', type: "dropdown2" },
    ])
  const [zapperData, setZapperData] = useState(
    [
      { name: 'Upload Stock Buffer', url: 'admin/stock_buffer_pct', type:'zapper', isDownload: true }
    ]
  )
  const [fileList, setFileList] = useState([])
  const [pricingUpload, setPricingUpload] = useState(false)
  const [slotUpload, setSlotUpload] = useState(false)
  const [otherUpload, setOtherUpload] = useState(false)

  const { Option } = Select;

  const [action, setAction] = useState([])
  const [catalogName, setCatalogName] = useState([])
  const [catalogValue, setCatalogValue] = useState(undefined)
  const [actionValue, setActionValue] = useState(undefined)
  const [date, setDate] = useState(undefined)
  const [time, setTime] = useState(undefined)
  const [date1, setDate1] = useState(undefined)
  const [time1, setTime1] = useState(undefined)

  useEffect(() => {
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['admin', 'developer_admin', 'super_admin', 'accounts', 'pricing_download', 'pricing_executive'].includes(data)) {
        setOtherUpload(true)
      }
      if (['developer_admin', 'super_admin', 'pricing_executive'].includes(data)) {
        setPricingUpload(true)
      }
      if (['developer_admin', 'super_admin', 'capacity_planner'].includes(data)) {
        setSlotUpload(true)
      }
    })
    getCatlog()
  }, [])

  const getCatlog = () => {
    upload_service.get_catlog().subscribe((r) => {
      let newRes = r.response.data.catalog_name
      newRes.push('product_discount')
      setCatalogName(newRes)
      setAction(r.response.data.actions)
    }, ((err) => {
      console.log("err", err)
    }))
  }

  function beforeUploadFile(file, url) {
    setCurrent(url)
    setUploadFile(false)
    setFileList([])
    const formData = new FormData();
    formData.append('file', file)
    setFileList([file])
    setUploadFile(formData)
  }

  function afterUpload(url, type) {
    setSpinEnable(true)
    setCurrentSpin(url)
    if(type === 'Zapper'){
      let finalUrl = url;
      upload_service.upload_zapper(uploadFile, finalUrl).subscribe((r) => {
        setUploadFile(false)
        setSpinEnable(false)
        setFileList([])
        setCurrentSpin(false)
        setCurrent(false)
        openNotificationWithIcon('success', 'Upload File Succesfully')
      }, (err) => {
        setSpinEnable(false)
        openNotificationWithIcon('error', 'Upload Failed Please Check Internal File Format')
      })
    }
    else{
    let finalUrl = url
    if(catalogValue === 'product_discount'){
      finalUrl = 'upload_product_discount' + '?action_name=' + actionValue
    }else if(catalogValue === 'special_discount'){
      finalUrl = 'upload_special_discount' + '?action_name=' + actionValue
    }else if (actionValue && catalogValue) {
      finalUrl = url + '?action_name=' + actionValue + "&catalog_name=" + catalogValue
    }
    if (date && time) {
      finalUrl = url + '?date=' + moment(date).format("YYYY-MM-DD") + "&time=" + moment(time).format("HH:mm")
    }
    if (date1 && time1) {
      finalUrl = url + '?date=' + moment(date1).format("YYYY-MM-DD") + "&time=" + moment(time1).format("HH:mm")
    }
    upload_service.upload_reports(uploadFile, finalUrl).subscribe((r) => {
      setUploadFile(false)
      setSpinEnable(false)
      setFileList([])
      setCurrentSpin(false)
      setCurrent(false)
      setCatalogValue(undefined)
      setActionValue(undefined)
      setDate(undefined)
      setTime(undefined)
      setDate1(undefined)
      setTime1(undefined)
      openNotificationWithIcon('success', 'Upload File Succesfully')
    }, (err) => {
      setSpinEnable(false)
      openNotificationWithIcon('error', 'Upload Failed Please Check Internal File Format')
    })
  }
  }

  function handleClick({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  function handleRemove(url) {
    setUploadFile(false)
    setFileList([])
    setCurrent(false)
  }

  function openNotificationWithIcon(type, msg) {
    notification[type]({ message: type.toUpperCase(), duration: 3, description: msg });
  };

  const catalogChange = (value) => {
    setCatalogValue(value)
  }

  const actionChange = (value) => {
    setActionValue(value)
  }

  const dateChange = (value) => {
    setDate(value)
  }

  const timeChange = (value) => {
    setTime(value)
  }

  const dateChange1 = (value) => {
    setDate1(value)
  }

  const timeChange1 = (value) => {
    setTime1(value)
  }

  function disabledDate(current) {
    return current && current < moment().add(-1, "day");
  }

  return (
    <div className="layer">
      <Row>
        {uploadData1.map((data, index) => {
          return (
            <Col md={8} xs={24} key={index}>
              <Card>
                <>
                  <Dragger
                    disabled={(data.type === 'price' && !pricingUpload) || (data.type === 'slot' && !slotUpload) || (data.type === 'other' && !otherUpload)}
                    name='file'
                    fileList={current === data.url ? fileList : []}
                    showUploadList={true}
                    customRequest={handleClick}
                    beforeUpload={(e) => beforeUploadFile(e, data.url)}
                    accept=".csv"
                    onRemove={handleRemove}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{data.name}</p>
                    <p className="ant-upload-hint">
                      Format :- CSV File
                    </p>
                  </Dragger>

                  <div className='text-center'>
                    {!spinEnable ?
                      <Button disabled={current !== data.url} onClick={(e) => afterUpload(data.url)} className="frz-m-10 frz-mt-16 frz-w-100px"
                        type="primary">Upload</Button>
                      :
                      <div>
                        {currentSpin == data.url ?
                          <div className="frz-m-10 frz-mt-16">
                            <Spin tip="...Upload in Progress" />
                          </div>
                          : <Button disabled={true} onClick={(e) => afterUpload(data.url)} className="frz-m-10 frz-mt-16 frz-w-100px" type="primary">Upload</Button>}
                      </div>
                    }
                  </div>
                </>

              </Card>
            </Col>
          )
        })}
        {uploadData.map((data, index) => {
          return (
            <Col md={8} xs={24} key={index}>
              <Card>
                {data.type === "dropdown2"
                  ?
                  <>
                    <div className="frz-bm-5 frz-font-medium no-data-nested">Upload Scheduled Max Quantity Sheet</div>
                    <div className="frz-mb-15 frz-lp-10 frz-tp-10">
                      {/* <span>
                        <div className="frz-fw-600 frz-bm-5">
                          Date
                        </div>
                        <span>
                          <Select placeholder="Select Date" style={{ width: 200 }} onChange={catalogChange}
                                  value={catalogValue}>
                            {catalogName.map((data) => {
                              return (
                                <Option value={data}>{data}</Option>
                              )
                            })}
                          </Select>
                        </span>
                      </span> */}
                      <FRZDatepicker placeholder="Select Date" label="Date"
                        name="date"  disabledDate={disabledDate} setOnChange={dateChange1} />


                      {/* <span>
                        <div className="frz-fw-600 frz-bm-5 frz-tp-10">
                          Time
                        </div>
                        <span>
                          <Select placeholder="Select action type" style={{ width: 200 }} onChange={actionChange}
                            value={actionValue}>
                            {action.map((data) => {
                              return (
                                <Option value={data}>{data}</Option>
                              )
                            })}
                          </Select>
                        </span>
                      </span> */}

                      <FRZTimePicker  label="Time" name="time" setOnChange={timeChange1} />

                    </div>
                    {date1 && time1 ?
                      <div className="frz-tp-20">
                        <Dragger
                          disabled={!otherUpload && data.disabled}
                          name='file'
                          fileList={current === data.url ? fileList : []}
                          showUploadList={true}
                          customRequest={handleClick}
                          beforeUpload={(e) => beforeUploadFile(e, data.url)}
                          accept=".csv"
                          onRemove={handleRemove}>
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">{data.name}</p>
                          <p className="ant-upload-hint">
                            Format :- CSV File
                          </p>
                        </Dragger>

                        <div className='text-center'>
                          {!spinEnable ?
                            <Button disabled={current !== data.url} onClick={(e) => afterUpload(data.url)} className="frz-m-10 frz-mt-16 frz-w-100px"
                              type="primary">Upload</Button>
                            :
                            <div>
                              {currentSpin == data.url ?
                                <div className="frz-m-10 frz-mt-16">
                                  <Spin tip="...Upload in Progress" />
                                </div>
                                : <Button disabled={true} onClick={(e) => afterUpload(data.url)} className="frz-m-10 frz-mt-16 frz-w-100px" type="primary">Upload</Button>}
                            </div>
                          }
                        </div>
                      </div>
                      : null}


                  </>
                  : null
                  // <>
                  //   <Dragger
                  //     disabled={!pricingUpload && data.disabled}
                  //     name='file'
                  //     fileList={current === data.url ? fileList : []}
                  //     showUploadList={true}
                  //     customRequest={handleClick}
                  //     beforeUpload={(e) => beforeUploadFile(e, data.url)}
                  //     accept=".csv"
                  //     onRemove={handleRemove}>
                  //     <p className="ant-upload-drag-icon">
                  //       <InboxOutlined />
                  //     </p>
                  //     <p className="ant-upload-text">{data.name}</p>
                  //     <p className="ant-upload-hint">
                  //       Format :- CSV File
                  //     </p>
                  //   </Dragger>

                  //   <div style={{ textAlign: 'center' }}>
                  //     {!spinEnable ?
                  //       <Button disabled={current !== data.url} onClick={(e) => afterUpload(data.url)} style={{ width: 100, margin: 10, marginTop: 16 }}
                  //         type="primary">Upload</Button>
                  //       :
                  //       <div>
                  //         {currentSpin == data.url ?
                  //           <div style={{ margin: 10, marginTop: 16 }}>
                  //             <Spin tip="...Upload in Progress" />
                  //           </div>
                  //           : <Button disabled={true} onClick={(e) => afterUpload(data.url)} style={{ width: 100, margin: 10, marginTop: 16 }} type="primary">Upload</Button>}
                  //       </div>
                  //     }
                  //   </div>
                  // </>
                }
                {data.type === "dropdown1"
                  ?
                  <>
                    <div className="frz-bm-5 frz-font-medium no-data-nested">Schedule Price Sheet</div>
                    <div className="frz-mb-15 frz-lp-10 frz-tp-10">
                      {/* <span>
                        <div className="frz-fw-600 frz-bm-5">
                          Date
                        </div>
                        <span>
                          <Select placeholder="Select Date" style={{ width: 200 }} onChange={catalogChange}
                                  value={catalogValue}>
                            {catalogName.map((data) => {
                              return (
                                <Option value={data}>{data}</Option>
                              )
                            })}
                          </Select>
                        </span>
                      </span> */}
                      <FRZDatepicker placeholder="Select Date" label="Date"
                        name="date"  disabledDate={disabledDate} setOnChange={dateChange} />


                      {/* <span>
                        <div className="frz-fw-600 frz-bm-5 frz-tp-10">
                          Time
                        </div>
                        <span>
                          <Select placeholder="Select action type" style={{ width: 200 }} onChange={actionChange}
                            value={actionValue}>
                            {action.map((data) => {
                              return (
                                <Option value={data}>{data}</Option>
                              )
                            })}
                          </Select>
                        </span>
                      </span> */}

                      <FRZTimePicker  label="Time" name="time" setOnChange={timeChange} />

                    </div>
                    {date && time ?
                      <div className="frz-tp-20">
                        <Dragger
                          disabled={!otherUpload && data.disabled}
                          name='file'
                          fileList={current === data.url ? fileList : []}
                          showUploadList={true}
                          customRequest={handleClick}
                          beforeUpload={(e) => beforeUploadFile(e, data.url)}
                          accept=".csv"
                          onRemove={handleRemove}>
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">{data.name}</p>
                          <p className="ant-upload-hint">
                            Format :- CSV File
                          </p>
                        </Dragger>

                        <div className='text-center'>
                          {!spinEnable ?
                            <Button disabled={current !== data.url} onClick={(e) => afterUpload(data.url)} className="frz-m-10 frz-mt-16 frz-w-100px"
                              type="primary">Upload</Button>
                            :
                            <div>
                              {currentSpin == data.url ?
                                <div className="frz-m-10 frz-mt-16">
                                  <Spin tip="...Upload in Progress" />
                                </div>
                                : <Button disabled={true} onClick={(e) => afterUpload(data.url)} className="frz-m-10 frz-mt-16 frz-w-100px" type="primary">Upload</Button>}
                            </div>
                          }
                        </div>
                      </div>
                      : null}


                  </>
                  : null
                  // <>
                  //   <Dragger
                  //     disabled={!pricingUpload && data.disabled}
                  //     name='file'
                  //     fileList={current === data.url ? fileList : []}
                  //     showUploadList={true}
                  //     customRequest={handleClick}
                  //     beforeUpload={(e) => beforeUploadFile(e, data.url)}
                  //     accept=".csv"
                  //     onRemove={handleRemove}>
                  //     <p className="ant-upload-drag-icon">
                  //       <InboxOutlined />
                  //     </p>
                  //     <p className="ant-upload-text">{data.name}</p>
                  //     <p className="ant-upload-hint">
                  //       Format :- CSV File
                  //     </p>
                  //   </Dragger>

                  //   <div style={{ textAlign: 'center' }}>
                  //     {!spinEnable ?
                  //       <Button disabled={current !== data.url} onClick={(e) => afterUpload(data.url)} style={{ width: 100, margin: 10, marginTop: 16 }}
                  //         type="primary">Upload</Button>
                  //       :
                  //       <div>
                  //         {currentSpin == data.url ?
                  //           <div style={{ margin: 10, marginTop: 16 }}>
                  //             <Spin tip="...Upload in Progress" />
                  //           </div>
                  //           : <Button disabled={true} onClick={(e) => afterUpload(data.url)} style={{ width: 100, margin: 10, marginTop: 16 }} type="primary">Upload</Button>}
                  //       </div>
                  //     }
                  //   </div>
                  // </>
                }

                {data.type === "dropdown"
                  ?
                  <>
                    <div className="frz-bm-5 frz-font-medium no-data-nested">Bulk Upload</div>
                    <div className="frz-mb-15 frz-lp-10 frz-tp-10">
                      <span>
                        <div className="frz-fw-600 frz-padding-auto frz-bm-5 ">
                          Catalog Name:
                          <Select placeholder="Select catalog name" className='frz-lp-5 frz-w-200' onChange={catalogChange}
                            value={catalogValue}>
                            {catalogName.map((data) => {
                              return (
                                <Option value={data}>{data}</Option>
                              )
                            })}
                          </Select>
                        </div>
                      </span>
                      <span>
                        <div className="frz-fw-600 frz-padding-auto frz-bm-5 frz-tp-10">
                          Action Name: 
                          <span>
                          <Select placeholder="Select action type" className='download-dropdown' onChange={actionChange}
                            value={actionValue}>
                            {action.map((data) => {
                              return (
                                <Option value={data}>{data}</Option>
                              )
                            })}
                          </Select>
                        </span>
                        </div>
                        
                      </span>

                    </div>
                    {actionValue && catalogValue ?
                      <div className="frz-tp-20">
                        <Dragger
                          disabled={!otherUpload && data.disabled}
                          name='file'
                          fileList={current === data.url ? fileList : []}
                          showUploadList={true}
                          customRequest={handleClick}
                          beforeUpload={(e) => beforeUploadFile(e, data.url)}
                          accept=".csv"
                          onRemove={handleRemove}>
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">{data.name}</p>
                          <p className="ant-upload-hint">
                            Format :- CSV File
                          </p>
                        </Dragger>

                        <div className='text-center'>
                          {!spinEnable ?
                            <Button disabled={current !== data.url} onClick={(e) => afterUpload(data.url)} className="frz-m-10 frz-mt-16 frz-w-100px"
                              type="primary">Upload</Button>
                            :
                            <div>
                              {currentSpin == data.url ?
                                <div className="frz-m-10 frz-mt-16">
                                  <Spin tip="...Upload in Progress" />
                                </div>
                                : <Button disabled={true} onClick={(e) => afterUpload(data.url)} className="frz-m-10 frz-mt-16 frz-w-100px" type="primary">Upload</Button>}
                            </div>
                          }
                        </div>
                      </div>
                      : null}


                  </>
                  : null


                }
              </Card>
            </Col>

          )
        })}
        </Row>
        <Row>
        {zapperData.map((data, index) => {
          return (
            <Col md={8} xs={24} key={index}>
              <Card>
                <>
                  <Dragger
                    name='file'
                    fileList={current === data.url ? fileList : []}
                    showUploadList={true}
                    customRequest={handleClick}
                    beforeUpload={(e) => beforeUploadFile(e, data.url)}
                    accept=".csv"
                    onRemove={handleRemove}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{data.name}</p>
                    <p className="ant-upload-hint">
                      Format :- CSV File
                    </p>
                  </Dragger>
                  <Row>
                  <div className='text-center'>
                    {!spinEnable ?
                      <Button disabled={current !== data.url} onClick={(e) => afterUpload(data.url, 'Zapper')} className="frz-m-10 frz-mt-16 frz-w-100px"
                        type="primary">Upload</Button>
                      :
                      <div>
                        {currentSpin == data.url ?
                          <div className="frz-mt-16 align-item">
                            <Spin tip="...Upload in Progress" />
                          </div>
                          : <Button disabled={true} onClick={(e) => afterUpload(data.url, 'Zapper')} className="frz-m-10 frz-mt-16 frz-w-100px" type="primary">Upload</Button>}
                      </div>
                    }
                  </div>
                  {data.isDownload === true ? <div className='zapper-url '>
                    <a href={'https://sku-zapper.s3.ap-south-1.amazonaws.com/development_sample_upload/buffer_stock_update_report.csv'}>Download Sample File</a>
                  </div> : null}
                    </Row>
                </>
                
              </Card>
            </Col>
          )
        })}
      </Row>
    </div >
  )
}

export default UploadList
