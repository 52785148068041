
import React from "react";

export const overageColumns = (onEdit) => {
  return [
    {
      title: 'Darkstore Name',
      dataIndex: 'darkstore_name',
      key: 'darkstore_name',
      align: "center",
      sorter: (a, b) => a.darkstore_name.localeCompare(b.darkstore_name)
    },
    {
      title: 'Total Margin',
      dataIndex: 'total_margin',
      key: 'total_margin',
      align: "center",
      sorter: (a, b) => a.total_margin - b.total_margin
    },
    {
      title: 'Total Margin %',
      dataIndex: 'total_margin_p',
      key: 'total_margin_p',
      align: "center",
      sorter: (a, b) => a.total_margin_p - b.total_margin_p
    },
    {
      title: 'Overage Accounted Loss',
      dataIndex: 'overage_accounted_loss',
      key: 'overage_accounted_loss',
      align: "center",
      sorter: (a, b) => a.overage_accounted_loss - b.overage_accounted_loss
    },
    {
      title: 'Net effect on margin',
      dataIndex: 'net_effect_on_margin',
      key: 'net_effect_on_margin',
      align: "center",
      sorter: (a, b) => a.net_effect_on_margin - b.net_effect_on_margin
    },
    {
      title: 'Adherence',
      dataIndex: 'adherence',
      key: 'adherence',
      align: "center",
      sorter: (a, b) => a.adherence - b.adherence
    }
  ]
}