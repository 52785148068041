import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { Modal, Form, Select, Row, Col, Radio } from 'antd';
import { formItemLayout, tailLayout } from '../../../_modules/controllayout';
import FRZInput from '../../../_controls/FRZInput';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import FRZDatepicker from '../../../_controls/FRZDatepicker';
import FRZRadioButton from '../../../_controls/FRZRadioButton';
import FRZCustomRadioButon from '../../../_controls/FRZCustomRadioButon';
import FRZSelect from '../../../_controls/FRZSelect'
import {openNotificationWithIcon} from '../../../_modules/notification';
import moment from 'moment';
const { Option } = Select;

const initialModal = {
  data: false,
  title: false,
  loading: false,
  visible: false,
  paymentID:false
}

const BulkDiscountModal = forwardRef((props, ref) => {
  const { modalSubmit } = props
  const [form] = Form.useForm();
  const [modalState, setModalState] = useState(initialModal)
  const [isParent, setIsParent]=useState(false);
  const [segmentType, setSegmentType] = useState(false)
  const discountType = [
    { value: "value", option: "Value" },
    { value: "percentage", option: "Percentage" },
  ]
  useEffect(() => {
    if (modalState.title === "Edit Bulk Coupon" && modalState.data) {
     // bank_payment_type_id: modalState.data.bank_payment_type_id
      let data = form.getFieldValue()
      form.setFieldsValue({
        ...modalState.data,
        valid_from: moment(modalState.data.valid_from), valid_to: moment(modalState.data.valid_to),
        segments_attributes_prefix_other: modalState?.data?.segments_attributes_prefix_other?.map(item => item.slug),
        segments_attributes_prefix_city: modalState?.data?.segments_attributes_prefix_city?.map(item => item.slug)

      })
    }
  }, [modalState.data])

  useImperativeHandle(ref, () => ({
    handleCancel: handleCancelModal,
    showModal(data, title) {
      setModalState(prevState => ({ ...prevState, data: data, title: title, visible: true,
        paymentID: data.bank_payment_type_id }))
        setSegmentType((data?.segments_attributes_prefix_other?.length || data?.segments_attributes_prefix_city?.length) > 0 ? 'select_segment' : 'all_users')
    },
    stopLoading() {
      setModalState(prevState => ({ ...prevState, loading: false }))
    }
  }));

  function handleCancelModal() {
    form.resetFields()
    setModalState(initialModal)
    setIsParent(false);
  }

  const handleOnChangeParent = (value) => {
    setIsParent(value)
  }

  const getSegmentEdit = (dataset, value) => {
    var newSegment = []
    if(segmentType === 'all_users'){
      dataset.map(item => {
        newSegment.push({ id: item.id, _destroy: true })
      })
    }else{
      dataset.map(item => {
        if (value?.findIndex(val => val === item.slug) === -1) {
          newSegment.push({id: item.id, _destroy: true})
        }
      })
      value.map(item => {
        if (dataset?.findIndex(val => val.slug === item) === -1) {
          newSegment.push({slug: item})
        }
      })
    }
    return newSegment
  }

  const handleOnSubmit = (values) => {
    const payload = {
      ...values, valid_from: moment(values.valid_from).format("YYYY-MM-DD"),
      valid_to: moment(values.valid_to).format("YYYY-MM-DD")
    }
    if (modalState.title === "Edit Bulk Coupon") {
      payload.segments_attributes_prefix_city = getSegmentEdit(modalState.data.segments_attributes_prefix_city, values.segments_attributes_prefix_city)
      payload.segments_attributes_prefix_other = getSegmentEdit(modalState.data.segments_attributes_prefix_other, values.segments_attributes_prefix_other)
    } else {
      payload.segments_attributes_prefix_city = values.segments_attributes_prefix_city?.map(val => {return {slug: val}})
      payload.segments_attributes_prefix_other = values.segments_attributes_prefix_other?.map(val => {return {slug: val}})
    }
    setModalState(prevState => ({ ...prevState, loading: true }))
    modalSubmit(payload, modalState.data?.id)
  }

  return (
    <div>
      {modalState.data ?
        <Modal
          visible={modalState.visible}
          title={modalState.title}
          okText="Submit"
          width={'50%'}
          okButtonProps={{ loading: modalState.loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                if ((segmentType === 'select_segment' && (values.segments_attributes_prefix_city?.length ||
                  values.segments_attributes_prefix_other?.length)) || segmentType === 'all_users') {
                  handleOnSubmit(values)
                } else {
                  openNotificationWithIcon('error', 'Please Select Anyone Segments')
                }
              })
              .catch(info => {
                console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            <FRZInput name="campaign_name" isRequired={true} label="Campaign Name" 
            placeholder="Campaign Name" width={200} isDisabled={modalState.title === "Edit Bulk Coupon"} />
            
            <FRZInput name="campaign_code" isRequired={true} label="Campaign Code" 
            placeholder="Campaign Code" width={200} isDisabled={modalState.title === "Edit Bulk Coupon"}/>
            
            <FRZInput name="description" isRequired={true} label="Description" placeholder="Description" width={200} />
            
            <FRZInput name="success_message" isRequired={true} label="Success message" placeholder="Success message" width={200} />
            
            <FRZRadioButton name="is_active" isRequired={true} label="Is Active" width={200}/>
            
            <FRZCustomRadioButon name="discount_type" label="Discount type" isRequired={true} 
            optionData={discountType} option="option" value="value" disabled={modalState.title === "Edit Bulk Coupon"}/>
            
            <FRZInputNumber name="discount_type_value" isRequired={true} label="Discount Type Value" 
            placeholder="Discount Type Value" width={200} isDisabled={modalState.title === "Edit Bulk Coupon"}/>
            
            <FRZInputNumber name="min_order_value" isRequired={true} label="Min Order Value" 
            placeholder="Min Order Value" width={200} isDisabled={modalState.title === "Edit Bulk Coupon"}/>
            
            <FRZInputNumber name="max_discount_value" isRequired={true} label="Max Discount Value" 
            placeholder="Max Discount Value" width={200} isDisabled={modalState.title === "Edit Bulk Coupon"}/>
            
            <FRZInputNumber name="applicable_count" isRequired={true} label="Applicable Count" 
            placeholder="Applicable Count" width={200} isDisabled={modalState.title === "Edit Bulk Coupon"}/>
            
            {(modalState.title !== 'Edit Bulk Coupon') ?
              <FRZRadioButton setOnChange={handleOnChangeParent} isRequired={true} label="Banking Coupon" name="payment_flag" />
              : null}

             {(isParent === true) ?
              <FRZSelect DropDownData={props.bankDetails} name="bank_detail_id" isRequired={true}
                label="Select Bank" placeholder="Select Bank" value="id" option="name"
                isShowSearch={true} width={'200px'}selectDisabled={
                  modalState.title === 'Edit Bulk Coupon'}/>
              : null
            }
             {( (modalState.paymentID &&  modalState.title === 'Edit Bulk Coupon' ) || (isParent === true)) ?
              <FRZSelect DropDownData={props.paymentType} name="bank_payment_type_id" isRequired={true}
                label="Payment Type" placeholder="Select Payment Type" value="id" option="name"
                isShowSearch={true} width={'200px'}selectDisabled={
                  modalState.title === 'Edit Bulk Coupon'}/>
              : null
            }
                        
            {/* <FRZRadioButton name="first_order_flag" isRequired={true} label="First Order" di
            placeholder="First Order" width={200} disabled={modalState.title === "Edit Bulk Coupon"}/> */}
            
            <FRZDatepicker name="valid_from" isRequired={true} label="Valid From" 
            placeholder="Valid From" width={200} isDisabled={modalState.title === "Edit Bulk Coupon"} />
            
            <FRZDatepicker name="valid_to" isRequired={true} label="Valid To" placeholder="Valid To" 
            width={200}  isDisabled={modalState.title === "Edit Bulk Coupon"}/>
            
            <FRZInputNumber name="campaign_coupon_generation_count" isRequired={true} label="Generation count"
              isDisabled={modalState.title === "Edit Bulk Coupon"} placeholder="Generation count" width={200} />
            
            <FRZInputNumber name="customer_campaign_coupon_limit" isRequired={true} label="Coupon limit"
            isDisabled={modalState.title === "Edit Bulk Coupon"} placeholder="Coupon limit" width={200} />

            <Form.Item
              {...tailLayout}
              label={"Segment Type"}>
              <Radio.Group
                value={segmentType}
                onChange={(e) => setSegmentType(e.target.value)}
              >
                <Radio value={'all_users'}>All Users</Radio>
                <Radio value={'select_segment'}>Select Segment</Radio>
              </Radio.Group>
            </Form.Item>

            {segmentType === 'select_segment' &&
              <>
                <FRZSelect DropDownData={props.segmentData} name="segments_attributes_prefix_city" isRequired={false}
                  label="Segment City" placeholder="Select Segment City" value="slug" option="name"
                  isShowSearch={false} width={'300px'} mode="multiple" />
                <div className='frz-segment-and' style={{textAlign: 'left', padding: '0px 300px 20px'}}>AND</div>
                <FRZSelect DropDownData={props.segmentDataOther} name="segments_attributes_prefix_other" isRequired={false}
                  label="Segment Other" placeholder="Select Segment Other" value="slug" option="name"
                  isShowSearch={false} width={'300px'} mode="multiple" />
              </>}

          </Form>
        </Modal>
        : null}
    </div >

  )
})

export default BulkDiscountModal