export const sequenceColumns = [
    {
        title: 'Product Name',
        dataIndex: 'product_name',
        key: 'product_name',
        align: 'center'
    },
    {
        title: 'SKU',
        dataIndex: 'sku',
        key: 'sku',
        align: 'center'
    },
    {
        title: "Is Active",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
          return record.is_active.toString()
        }
    },
    {
        title: 'Sequence',
        dataIndex: 'sequence',
        key: 'sequence',
        align: 'center'
    },
]