import React, { useEffect, useState, useCallback } from 'react';
import { Table, Card, Spin } from 'antd';
import { openNotificationWithIcon } from '../../../_modules/notification.js';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';
import { CategoryService } from '../../../_services/category.js';

const category_service = new CategoryService();
const type = 'DraggableBodyRow';

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

const SubCategoryTable = ({ nestedCol, nestedTableData, }) => {
  const [productsColm, setProductsColm] = useState(false);
  const [updateNestedRow, setUpdateNestedRow] = useState(false);
  const [updateSeq, setUpdateSeq] = useState(false);
  const [spinEnable, setSpinEnable] = useState(false);
  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };
  
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      if(localStorage.getItem('city') !== ''){
      const dragRow = productsColm[dragIndex];
      setProductsColm(
        update(productsColm, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }));
        setUpdateSeq(true)
      }
      else{
        openNotificationWithIcon('error', "Please Select a City");
      }      
    },
    [productsColm],
  );

  useEffect(() => {
    if (updateSeq) {
      setUpdateSeq(false)
      updateSubCategorySequence(productsColm)
    }
  }, [updateSeq])

  useEffect(() => {
    setSpinEnable(true);
    if (!updateNestedRow) {
      setUpdateNestedRow(false)
      setSpinEnable(false);
      setProductsColm(nestedTableData.sub_categories);
    }
  }, [updateNestedRow]);

  function updateSubCategorySequence() {    
    let tempData = []
    productsColm.map((data, index) => {
      tempData.push({ id: data.id, sequence: index + 1 })
    })
    const payload = {
      sub_category_products: tempData
    }
      category_service.update_subcategory_sequence(payload).subscribe((r) => {
        openNotificationWithIcon('success', "Updated Successfully")
     },
      (err) => {
        console.log(err);
        openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
      }
    )
}

  return (
    <div>
      {productsColm.length > 0 ?
        (<Card className="nested-table">
            <DndProvider backend={HTML5Backend}>
              <Table columns={nestedCol} components={components}
                pagination={false} rowKey="id"
                scroll={{ x: 240}} dataSource={productsColm}
                 onRow={(record, index) => ({
                  index, moveRow,
                })}
              />
            </DndProvider>
          </Card>
        )
        : (spinEnable ?
          <div className="spin-center"><Spin tip="...Loading" /></div>
          :
          <div className="no-data">No Data Available</div>
        )}
    </div>
  )
}

export default SubCategoryTable;
