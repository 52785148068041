import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Radio, Button, InputNumber, DatePicker, TimePicker } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import FRZSelect from '../../../_controls/FRZSelect';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import FRZCustomRadioButon from '../../../_controls/FRZCustomRadioButon';
import rupee from '../../../static/images/rupee.svg';
import { tailLayout } from '../../../_modules/controllayout'

const fuelType = [{ value: 'PER_ORDER', option: 'Per Order' }, { value: 'MIN_ORDER', option: 'Min Order' }]

const ImsG = (props) => {
  const [peakHourIncentiveType, setPeakHourIncentiveType] = useState(props?.radioType ? props?.radioType?.type : null)
  let data = props.form.getFieldValue()
  console.log("data", data)
  function onChangeType(e) {
    setPeakHourIncentiveType(e)
    props.handleOnFormChange(true)
  }

  const onFormChange = () => {
    props.handleOnFormChange(true)
  }

  return (
    <div className='frz-ims-box frz-fuel-radio'>
      <div className='frz-ims-text'>
        Peak Hour Incentive (G)
      </div>
      <Form
        form={props.form}
        layout="horizontal"
        name="form"
        onChange={() => onFormChange()}>
        <Row>
          <Col span={10}>
            <FRZCustomRadioButon name={['G', 'type']} label="" setOnChange={onChangeType}
              optionData={fuelType} option="option" value="value" />
          </Col>
          <Col span={14}>
            {peakHourIncentiveType === 'PER_ORDER' ?
              <div className='frz-dis-flex frz-justify-end frz-tp-10'>
                <div className='frz-rm-10'>
                  <Form.Item
                    {...tailLayout}
                    name={['G', 'G_time']}
                    rules={[{ required: true, message: "Please enter time" }]}>
                    <TimePicker.RangePicker style={{ width: 200 }} use24Hours format={"HH:mm a"} />
                  </Form.Item>
                </div>
                <div className='frz-p-relative frz-ims-rs-custom frz-ims-placeholder'>
                  <div className='frz-rupee-ims'><img src={rupee} /></div>
                  <FRZInputNumber minValue={0} name={['G', 'rate']} label="" placeholder="per order rate" width={150} errorMsg="Please enter rate" />
                </div>
              </div>
              :
              (peakHourIncentiveType === 'MIN_ORDER' ?
                <div className='frz-dis-flex frz-justify-end frz-tp-10'>
                  <div className='frz-rm-10'>
                    <Form.Item
                      {...tailLayout}
                      name={['G', 'G_time']}
                      rules={[{ required: true, message: "Please enter time" }]}>
                      <TimePicker.RangePicker style={{ width: 200 }} use24Hours format={"HH:mm"} />
                    </Form.Item>
                  </div>
                  <div className='frz-rm-10'>
                    <FRZInputNumber width={100} name={['G', 'order_threshold']} placeholder='Min Order Count' />
                  </div>
                  <div className='frz-p-relative frz-ims-rs-custom frz-ims-placeholder'>
                    <div className='frz-rupee-ims'><img src={rupee} /></div>
                    <FRZInputNumber minValue={0} name={['G', 'rate']} label="" placeholder="per order rate" width={150} errorMsg="Please enter rate" />
                  </div>
                </div>
                : null)
            }
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default ImsG