import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, DatePicker,
         Pagination, notification } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {SocietyService} from '../../_services/society';
import Search from '../../components/antd/search';
import MainTable from '../../components/antd/table';
import PickupModal from '../../components/society/pickup_modal';
import PickupDetail from '../../components/society/pickup_detail';
import NotificationModal from '../../components/society/pickup_notification_modal';
import {pickupColumns} from '../../components/antd/columns/society';
import moment from 'moment';
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const society_service = new SocietyService()

function PickupPoints() {
  const modalRef = useRef(null);
  const notificationRef = useRef(null);
  const detailRef = useRef(null);
  const [pickupData, setPickupData] = useState(false)
  const [statsData, setStatsData] = useState(false)
  const [societyData, setSocietyData] = useState(false)
  const [virtualDsData, setVirtualDsData] = useState(false)
  const [societyDataPincode, setSocietyDataPincode] = useState(false)
  const [pickupTimeslot, setPickupTimeslot] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [mode, setMode] = useState(undefined)
  const [spinEnableDownload, setSpinEnableDownload] = useState(false)
  const [pickupError, setPickupError] = useState(false)
  const [state, setState] = useState({
                            valueByPickup: undefined, valueBySociety: undefined,
                            start_date: '', end_date: '', showDateValue: false});
  const [modalState, setModalState] = useState({
                                      pincode_modal: '', delivery_date: '',
                                      society_id: undefined, currentActive: undefined,
                                      valueByDeliveryDate: undefined, pickupSlot: undefined,
                                      is_expired: false, notification_type: undefined, message: undefined, 
                                      title: undefined, pickupCode: undefined, virtualDS: undefined});
  const {  pincode_modal, delivery_date, society_id, currentActive, pickupCode,
           valueByDeliveryDate, pickupSlot, is_expired, notification_type, message, title, virtualDS} = modalState
  const { valueByPickup, valueBySociety, start_date, end_date, showDateValue } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getPickup()
    }
  })

  useEffect(() => {
    getColumns()
    getSociety()
    getVirtualDs()
  }, [])

  function getPickup(){
    setSpinEnable(true)
    setPickupData([])
    const payload = {
      page: current,
      by_pickup_code: valueByPickup === undefined ? '' : valueByPickup.toUpperCase(),
      start_date: start_date,
      end_date: end_date,
      by_point_type: valueBySociety === undefined ? '' : 'Society',
      by_point_id: valueBySociety === undefined ? '' : valueBySociety,
    }
    society_service.get_pickup_points(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setSpinEnable(false)
      setStatsData(r.response.data)
      setPickupData(r.response.data.pickup_points_data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    pickupColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: '',
      dataIndex: '',
      key: '',
      align: 'right',
      render: (record) => {
        return (
          <div>
            <div>
              <Button type="primary" ghost
                      onClick={() => showModalNotification(record, 'Send Notification')}>Send Notifications</Button>
            </div>
          </div>
        )
      }
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'right',
      render: (record) => {
        return (
          <div>
            <div>
              <Button type="primary" ghost disabled={record.is_expired}
                      onClick={() => showModal(record, 'Edit Pickup')}>Edit</Button>
            </div>
            <Button type="primary" style={{marginTop: 10}}
                    onClick={() => showModalDetail(record, 'Pickup Details')}>View Details</Button>
          </div>
        )
      }
    })
    setColumns(columns)
  }

  function getSociety() {
    const payload = {
      by_pincode: ''
    }
    society_service.get_society_list(payload).subscribe((r) => {
      setSocietyData(r.response.data.societies)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getVirtualDs() {
    society_service.get_virtual_ds().subscribe((r) => {
      setVirtualDsData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getSocietyByPincode(val) {
    const payload = {
      by_pincode: val
    }
    society_service.get_society_list(payload).subscribe((r) => {
      setSocietyDataPincode(r.response.data.societies)
    },
    (err)=>{
      console.log(err)
    })
  }

  function onChangeRangePicker(value, dateString){
    setState(prevState =>({...prevState, showDateValue: true, start_date :dateString[0], end_date:dateString[1]}))
    setDisable(false)
    setCurrent(1)
  }

  function showModal(data, type) {
    setMode(type)
    society_service.get_pickup_timeslot().subscribe((r) => {
      setPickupTimeslot(r.response.data)
      if(type === 'Edit Pickup'){
        getSocietyByPincode(data.point_details.pincode)
        setModalState({
          delivery_date: moment(data.delivery_date),
          valueByDeliveryDate: data.delivery_date,
          pincode_modal: data.point_details.pincode,
          society_id: data.point_details.id,
          currentActive: data.id,
          is_expired: data.is_expired,
          virtualDS: data.darkstore_id,
          pickupSlot: data.delivery_slot_details ? data.delivery_slot_details.id : undefined})
      }
      modalRef.current.showModal(type, data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function showModalDetail(data, type) {
    detailRef.current.showModal(type, data)
  }

  function showModalNotification(data, type) {
    setModalState(prevState =>({...prevState, pickupCode: data.pickup_code}))
    notificationRef.current.showModal(type, data)
  }
  
  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function onChangeCheck(e) {
    // console.log(e, e.target.checked)
    setModalState(prevState =>({...prevState, is_expired: e.target.checked}))
  }

  function onChangeModal(value, type) {
    setModalState(prevState =>({...prevState, [type]: value === "" || value === null ? undefined : value}))
    if(type === 'pincode_modal'){
      setModalState(prevState =>({...prevState, [type]: value.split(' ').join(''),
                    society_id: undefined}))
      if(value.length === 6){
        getSocietyByPincode(value)
      }
    }
  }

  function reset() {
    setState({
      valueByPickup: undefined,
      valueBySociety: undefined,
      start_date: '', end_date: '', showDateValue: false})
    setDisable(true)
    setCurrent(1)
    setPickupData([])
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setPickupData([])
    setRenderData(false)
  }

  function onCancelModal() {
    setModalState({pincode_modal: '', delivery_date: '',
    society_id: undefined, currentActive: undefined, pickupCode: undefined,
    valueByDeliveryDate: undefined, pickupSlot: undefined, is_expired: false,
    notification_type: undefined, message: undefined, title: undefined, virtualDS: undefined})
  }

  function onChangeDateModal(date, dateString) {
    setModalState(prevState =>({...prevState, delivery_date: date, valueByDeliveryDate: dateString}))
  }

  function disabledDate(current){
    // Can select days before today and today
    // return current && current > moment().add(0, "day");
    //Can select days before today and past 3 months
    return current && (current < moment().subtract(91, "day") || current > moment().add(7, "day"));
  }

  function modalSubmit() {
    if(!delivery_date || !society_id || pincode_modal.length !== 6 || !pickupSlot || !virtualDS){
      if(pincode_modal.length !== 6){
        openNotificationWithIcon('error', "Please enter 6 digit pincode")
      }
      setPickupError(true)
      openNotificationWithIcon('error', "Please fill all pickup details")
       modalRef.current.stopLoading()
    }else{
      const payload = {
        id: mode === 'Edit Pickup' ? currentActive : undefined,
        body: {
          pickup_point: {
            point_id: society_id,
            point_type: 'Society',
            delivery_date: valueByDeliveryDate,
            delivery_slot_id: pickupSlot,
            darkstore_id: virtualDS
          }
        }
      }
      if(mode === 'Edit Pickup'){
        payload.body.pickup_point.is_expired = is_expired
        society_service.update_pickup(payload).subscribe((r) => {
          openNotificationWithIcon('success','Edited Successfully')
          getPickup()
          modalRef.current.handleCancel()
        },
        (err)=>{
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors[0])
        })
      }else{
        society_service.create_pickup(payload).subscribe((r) => {
          openNotificationWithIcon('success','Created Successfully')
          getPickup()
          modalRef.current.handleCancel()
        },
        (err)=>{
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors[0])
        })
      }
    }
  }

  function modalSubmitNotification(){
    if(!notification_type || !message){
      openNotificationWithIcon('error', "Please fill all fields")
      notificationRef.current.stopLoading()
    }else{
      if(notification_type === 'push'){
        if(!title){
          openNotificationWithIcon('error', "Please fill all fields")
          notificationRef.current.stopLoading()
          return
        }
      }
      const payload = {
        message_type: notification_type,
        message: message,
        pickup_code: pickupCode
      }
      if(notification_type === 'push'){
        payload.title = title
      }
      society_service.send_notification(payload).subscribe((r) => {
        openNotificationWithIcon('success','Sent Successfully')
        notificationRef.current.handleCancel()
      },
      (err)=>{
        console.log(err)
        notificationRef.current.stopLoading()
        openNotificationWithIcon('error', err.response.errors[0])
      })
    }
  }

  function getDownloadStock(){
    setSpinEnableDownload(true)
    const payload = {
        by_pickup_code: valueByPickup === undefined ? '' : valueByPickup.toUpperCase(),
        start_date: start_date,
        end_date: end_date,
        by_point_type: valueBySociety === undefined ? '' : 'Society',
        by_point_id: valueBySociety === undefined ? '' : valueBySociety,
    }
    society_service.download_delivery_report(payload).subscribe((r) => {
      const linkSource = `data:application/octet-stream;base64,${r.response.csv_data}`;
      const downloadLink = document.createElement("a");
      const fileName = `Delivery_Report_${(new Date).toDateString().replace(/ /g,'_')}.csv`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      openNotificationWithIcon('success','Downloaded Successfully')
      setSpinEnableDownload(false)
    }, (err) => {
      setSpinEnableDownload(false)
      openNotificationWithIcon('error','Download Failed')
    })
  }

  return(
    <div className="layer">
      {pickupData && societyData && virtualDsData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Society Name" value={valueBySociety}
                        onChange={onChange} type="valueBySociety"
                        data={societyData}/>
                <Input placeholder="Pickup Code" onChange={(e) => onChange(e.target.value, 'valueByPickup')}
                       value={valueByPickup} style={{width: 200, margin: 10}}/>
                <RangePicker onChange={onChangeRangePicker} style={{margin:10}}
                             value={showDateValue ? [moment(start_date, dateFormat),
                             moment(end_date, dateFormat)] : '' } format={dateFormat}
                             allowClear={false} disabledDate={disabledDate}/>
                <Button disabled={disable} onClick={getPickup} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Add Pickup')} style={{width: 100, margin:10}}
                        type="primary">Add Pickup</Button>
                <span style={{padding: 15}}><b>Order Count: </b>{statsData.order_count}</span>
                <span style={{padding: 15}}><b>Expected Sales: </b>₹ {statsData.expected_sales}</span>
                <span style={{padding: 15}}><b>Final Sales: </b>₹ {statsData.final_sales}</span>
                {!spinEnableDownload ?
                    <Button onClick={getDownloadStock} style={{width: 200, float: 'right', margin: 10}}
                            type="primary">Download Delivery Report</Button>
                    :
                    <span style={{margin: 10, float: 'right'}}>
                        <Spin tip="...Download in Progress"/>
                    </span>
                }
              </Col>
            </Card>
          </Row>
          {pickupData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={pickupData} columns={columns}/>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <PickupModal modalSubmit={modalSubmit} ref={modalRef}
                       onChangeModal={onChangeModal}
                       onCancelModal={onCancelModal}
                       onChangeDateModal={onChangeDateModal}
                       virtualDsData={virtualDsData}
                       pincode_modal={pincode_modal}
                       delivery_date={delivery_date}
                       society_id={society_id}
                       societyData={societyDataPincode}
                       pickupError={pickupError}
                       pickupTimeslot={pickupTimeslot}
                       pickupSlot={pickupSlot}
                       onChangeCheck={onChangeCheck}
                       is_expired={is_expired}
                       virtualDS={virtualDS}
                       />
          <PickupDetail ref={detailRef} />
          <NotificationModal ref={notificationRef} modalSubmit={modalSubmitNotification} 
                             onChangeModal={onChangeModal} onCancelModal={onCancelModal}
                             notification_type={notification_type} message={message}
                             title={title}/>

        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default PickupPoints
