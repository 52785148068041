import React, {useEffect, useState} from 'react';
import { Card, Row, Col, Select, Button, Spin, notification, DatePicker } from 'antd';
import {DownloadService} from '../../_services/downloads';
import { useSelector } from "react-redux";
import moment from 'moment';
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const download_service = new DownloadService()

const { Option } = Select;

function DateWise(){
    const [state, setState] = useState({start_date: '', end_date: '', showDateValue: false});
    const [spinEnable, setSpinEnable] = useState(false)
    const [downloadOption, setDownloadOption] = useState(undefined)
    const [d1, setD1] = useState(false)
    const [d2, setD2] = useState(false)

    const {start_date, end_date, showDateValue} = state

    useEffect(() => {
      JSON.parse(localStorage.getItem('roles')).forEach(data => {
        if(['admin','developer','super_admin','accounts','store_download'].includes(data)){
          setD1(true)
        }
      })
      JSON.parse(localStorage.getItem('roles')).forEach(data => {
        if(['admin','developer','super_admin','accounts','sales_download'].includes(data)){
          setD2(true)
        }
      })
    }, [])

    function onChange(val) {
        setDownloadOption(val)
    }

    function getDownloadList(){
        setSpinEnable(true)
        const payload = {
            start_date: start_date,
            end_date: end_date,
            url: downloadOption
        }
        download_service.get_downloads_datewise(payload).subscribe((r) => {
            var myVar = setInterval(() => {
                download_service.download_reports_job_fraazo(r.response.data.id).subscribe(res => {
                    if(res.response.csv_data.status === 'success'){
                        clearInterval(myVar)
                        window.open(res.response.csv_data.url, '_blank')
                        openNotificationWithIcon('success','Downloaded Successfully')
                        setSpinEnable(false)
                    }
                }, (err) => {
                    setSpinEnable(false)
                    clearInterval(myVar)
                    openNotificationWithIcon('error','Download Failed')
                })
            }, 8000);
            // const linkSource = `data:application/octet-stream;base64,${r.response.csv_data}`;
            // const downloadLink = document.createElement("a");
            // const fileName = `${downloadOption}_${(new Date).toDateString().replace(/ /g,'_')}.csv`;
            // downloadLink.href = linkSource;
            // downloadLink.download = fileName;
            // downloadLink.click();
            // openNotificationWithIcon('success','Downloaded Successfully')
            // setSpinEnable(false)
        }, (err) => {
            setSpinEnable(false)
            openNotificationWithIcon('error','Download Failed')
        })
    }

    function openNotificationWithIcon(type, msg) {
        notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
    };

    function onChangeRangePicker(value, dateString){
        setState(prevState =>({...prevState, showDateValue: true, start_date :dateString[0], end_date:dateString[1]}))
    }

    function disabledDate(current){
        // Can select days before today and today
        // return current && current > moment().add(0, "day");
        //Can select days before today and past 3 months
        return current && (current < moment().subtract(91, "day") || current > moment().add(0, "day"));
    }

    return(
        <div className="layer" style={{paddingTop: 20}}>
            {!spinEnable ?
                <Row>
                <Card>
                    <Col span={24} style={{marginLeft: 10}}>
                     <h3 style={{marginTop: 10, marginBottom: 0}}>Bad Quality SKU Report | Fraazo Valet Activation Report</h3>   
                    <RangePicker onChange={onChangeRangePicker}
                                 value={showDateValue ? [moment(start_date, dateFormat),
                                 moment(end_date, dateFormat)] : '' } format={dateFormat}
                                 allowClear={false} disabledDate={disabledDate}/>
                    <Select style={{ width: 300, margin:10}}
                      value={downloadOption}
                      placeholder="Select Download Item" onChange={(e) => onChange(e)}
                      filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        <Option key="bad_quality_sku_report" value="bad_quality_sku_report" disabled={!d1}>
                            Bad Quality SKU Report
                        </Option>
                        {/* <Option key="get_flash_sale_log" value="get_flash_sale_log">
                            Flash Sale Report
                        </Option> */}
                        <Option key="fraazo_valet_activation_report" value="fraazo_valet_activation_report" disabled={!d2}>
                            Fraazo Valet Activation Report
                        </Option>
                    </Select>
                    <Button disabled={!downloadOption || start_date === ''} onClick={getDownloadList} style={{width: 100, margin:10}}
                            type="primary">Download</Button>
                    </Col>
                </Card>
                </Row>
                :
                <div className="spin-center">
                    <Spin tip="...Download in Progress"/>
                </div>
            }
         </div>
    )
}

export default DateWise