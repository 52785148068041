import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button, Select, Input, InputNumber, DatePicker,
         Radio, ConfigProvider} from 'antd';
const { Option } = Select;

const PartialRefund = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOk() {
    setLoading(true)
    props.modalSubmit()
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.onCancelModal();
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onOk={handleOk}
          onCancel={handleCancelModal}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              Submit
            </Button>,
          ]}
        >
          <div className="block">Mode Of Refund :
            <span style={{paddingLeft:10}}>
                <Select style={{ width: 200}} value={props.refundMode}
                        placeholder="Refund Mode" onChange={(e) => props.onChangeModal(e, 'refundMode')}>
                    <Option key='original_source' value='original_source'>Original Source</Option>
                    <Option key='credit' value='credit'>Credit</Option>
                </Select>
            </span>
          </div>
          <div className="block" style={{marginLeft: 8}}> Refund Reason :
            <span style={{paddingLeft:10}}>
                <Select style={{ width: 200}} placeholder="Select Reason"
                        value={props.reason}
                        onChange={(e) => props.onChangeModal(e, 'reason')}>
                        {props.refundReason.map(data => {
                            return(
                                <Option key={data.id} value={data.id}>
                                    {data.name}
                                </Option>
                            )
                        })}
                </Select>
            </span>
          </div>
          <div className="block" style={{marginLeft: 50}}>Amount :
            <span style={{paddingLeft:10}}>
              <InputNumber
                placeholder="Enter Amount"
                value={props.partialRefundAmt}
                style={{width:200}}
                onChange={(e) => props.onChangeModal(e, 'partialRefundAmt')}/>
            </span>
          </div>
        </Modal>
      : null }
    </div>
  )
})

export default PartialRefund
