import React, { useState, useEffect } from 'react'
import { StateService } from '../../_services/state'
import { stateColumn, cityColumn } from '../../components/antd/columns/state'
import { Spin, Button, Card, Row, Col, Tabs, Table, Pagination } from "antd";
import ModalState from '../../components/master/state/ModalState';
import { openNotificationWithIcon } from '../../_modules/notification';

const state_service = new StateService()

const { TabPane } = Tabs;

const State = () => {

  const [listingData, setListingData] = useState(false)
  const [column, setColumn] = useState(false)
  const [rowData, setRowData] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState(1);
  const [stateList, setStateList] = useState(false)

  useEffect(() => {
    getListing(currentTab)
    getColumn(currentTab)
  }, [])


  // useEffect(() => {
  //   console.log("stateList", stateList)
  // }, [stateList])

  const getListing = (key) => {
    setListingData(false);
    if (key == 1) {
      state_service.get_state().subscribe((r) => {
        setListingData(r.response.data)
        setStateList(r.response.data)
      })
    } else {
      state_service.get_city().subscribe((r) => {
        setListingData(r.response.data)
      })
    }
  }

  const getAllState = () => {
    state_service.get_state().subscribe((r) => {
      setStateList(r.response.data)
    })
  }

  const getColumn = (key) => {
    let tempCol = [];
    if (key == 1) {
      stateColumn.map((data) => {
        tempCol.push(data)
      })
      tempCol.push({
        title: "Actions",
        dataIndex: "",
        key: "",
        align: "center",
        render: (_, record) => {
          return (
            <Button
              type="primary"
              onClick={() => {
                handleModalShow(true, record);
              }}
            >
              Edit
            </Button>
          );
        },
      });
    } else {
      cityColumn.map((data) => {
        tempCol.push(data)
      })
      tempCol.push({
        title: "Actions",
        dataIndex: "",
        key: "",
        align: "center",
        render: (_, record) => {
          return (
            <Button
              type="primary"
              onClick={() => {
                handleModalShow(true, record);
              }}
            >
              Edit
            </Button>
          );
        },
      });
    }

    setColumn(tempCol)
  }

  const handleModalShow = (value, data) => {
    if (data) {
      setRowData(data)
    } else {
      setRowData(false)
    }
    setShowModal(value)
  }

  const onSubmit = (data, key) => {
    setLoading(true)
    const payload = {
      ...data
    }
    if (key == 1) {
      if (rowData) {
        payload.id = rowData.id
      }
      if (rowData) {
        state_service.update_state(payload).subscribe((r) => {
          setShowModal(false)
          setLoading(false)
          getListing(key)
          openNotificationWithIcon('success', 'Successfully Created')
        }, ((err) => {
          console.log("err", err)
        }))
      } else {
        state_service.create_state(payload).subscribe((r) => {
          setShowModal(false)
          setLoading(false)
          getListing(key)
          openNotificationWithIcon('success', 'Successfully Created')
        }, ((err) => {
          console.log("err", err)
        }))
      }
    } else {
      if (rowData) {
        payload.id = rowData.id
      }
      if (rowData) {
        state_service.update_city(payload).subscribe((r) => {
          setShowModal(false)
          setLoading(false)
          getListing(key)
          openNotificationWithIcon('success', 'Successfully Created')
        }, ((err) => {
          console.log("err", err)
        }))
      } else {
        state_service.create_city(payload).subscribe((r) => {
          setShowModal(false)
          setLoading(false)
          getListing(key)
          openNotificationWithIcon('success', 'Successfully Created')
        }, ((err) => {
          console.log("err", err)
        }))
      }
    }
  }

  function changeTab(key) {
    setCurrentTab(key);
    setListingData(false);
    getListing(key)
    getColumn(key)
  }



  return (
    <div className="layer">
      <div className="ost">
        <Tabs defaultActiveKey="1" onChange={changeTab}>
          <TabPane tab="State" key="1"></TabPane>
          <TabPane tab="City" key="2"></TabPane>
        </Tabs>
      </div>
      {listingData ?
        listingData.length ?
          <div>
            <Row>
              <Card style={{ marginBottom: 20 }}>
                <Col span={24}>
                  {currentTab == 1 ?
                    <Button className="frz-m-10" type="primary" onClick={() => handleModalShow(true)}>Create State</Button>
                    :
                    <Button className="frz-m-10" type="primary" onClick={() => handleModalShow(true)}>Create City</Button>
                  }
                </Col>
              </Card>
            </Row>
            <Table
              columns={column}
              dataSource={listingData}
              pagination={false}
              scroll={{ x: 240 }}
              rowKey="id"
            />
            {showModal ?
              <ModalState showModal={showModal} handleModalShow={handleModalShow}
                onSubmit={onSubmit}
                rowData={rowData} loading={loading} currentTab={currentTab}
                stateList={stateList}
              />
              :
              null
            }
          </div> :
          <div className="no-data">No Data Available</div>
        : <div className="spin-center">
          <Spin tip="...Loading" />
        </div>}
    </div >
  )
}

export default State
