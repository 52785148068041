import React, { useEffect, useState } from 'react';
import {Spin, Button, Card, Row, Col, Pagination, Popconfirm, Table} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { FraudulentService } from '../../_services/fraudulent_refund';
import Search from '../../components/antd/search';
import MainTable from '../../components/antd/table';
import { fraudRefundColumns } from '../../components/antd/columns/master/fraud_refund';
import { openNotificationWithIcon } from '../../_modules/notification';

const fraudulent_service = new FraudulentService()

function FraudulentRefunds() {
  const [listData, setListData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [status] = useState([{ id: 'active', name: 'Active' }, { id: 'resolved', name: 'Resolved' }])
  const [priority] = useState([{ name: 'Low' }, { name: 'Medium' }, { name: 'High'}])
  const [state, setState] = useState({ valueByPriority: undefined, valueByStatus: undefined, valueByDarkstore: undefined});
  const { valueByPriority, valueByStatus, valueByDarkstore } = state

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getList()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getList() {
    setSpinEnable(true)
    setListData([])
    const payload = {
      page: current,
      priority: valueByPriority ?? '',
      status: valueByStatus ?? '',
      darkstore_id: valueByDarkstore ?? '',
    }

    fraudulent_service.get_refund_list(payload).subscribe((r) => {
      setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
      setSpinEnable(false)
      setListData(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getColumns() {
    fraudRefundColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: '',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return(
          <div>
            {record.status === 'active' ? 
              <Popconfirm
                title="Are you sure you want to resolve this?"
                onConfirm={() => resolveRefund(record.id)}
                okText="Yes"
                cancelText="No"
                placement="leftTop"
              >
              <Button type="primary" ghost>
                Resolve
              </Button>
            </Popconfirm>
            : null
            }
          </div>
        )
      }
    })
    setColumns(columns)
  }

  function resolveRefund(id){
    setSpinEnable(true)
    setListData([])
    fraudulent_service.update_refund(id).subscribe((r) => {
      openNotificationWithIcon('success', 'Resolved Successfully')
      getList()
    },
    (err) => {
      console.log(err)
      getList()
      openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
    })
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }

  function reset() {
    setState({valueByPriority: undefined, valueByStatus: undefined, valueByDarkstore: undefined})
    setDisable(true)
    setCurrent(1)
    setListData([])
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setListData(false)
    setRenderData(false)
  }

  return (
    <div className="layer">
      {listData  ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Select Priority" value={valueByPriority} data={priority}
                        onChange={(e) => onChange(e, 'valueByPriority')} type="valueByPriority" />
                <Search placeholder="Select Status" value={valueByStatus} data={status} 
                        onChange={(e) => onChange(e, 'valueByStatus')}  type="valueByCategory" />
                <Search placeholder="Darkstore Name" value={valueByDarkstore}
                        onChange={onChange} type="valueByDarkstore"/>
                <Button disabled={disable} onClick={getList}
                  className="frz-w-100px frz-m-10" type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          {listData.length > 0 ?
            <Row>
              <Col span={24}>
                <Card style={{ paddingTop: 5, marginTop: 11 }}>
                  <Row>
                    <Col span={24}>
                      <Table columns={columns} dataSource={listData}
                             pagination={false} scroll={{ x: 'max-content' }} rowKey="id"/>
                    </Col>
                  </Row>
                </Card>
                <Pagination className="frz-tm-10 frz-bm-10" current={current}
                  pageSize={50} total={state.total} onChange={pagination}
                  showSizeChanger={false} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  );
}

export default FraudulentRefunds