import { Http } from '../_modules/http'

export class UploadService {

  http = new Http()

  upload_reports(payload, urlValue) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/uploads/' + urlValue
    let headers = { ...JSON.parse(localStorage.getItem('auth')) }
    return this.http.post(url, payload, headers)
  }

  upload_zapper(payload, minUrl) {
    const url = process.env.REACT_APP_AWS_URL + minUrl
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.put(url, payload, headers);
  }

  upload_pricing_reports(payload, urlValue) {
    const url = process.env.REACT_APP_PRICING_URL + urlValue
    let headers = { "x-api-key": process.env.REACT_APP_PRICING_TOKEN }
    return this.http.post(url, payload, headers)
  }

  get_catlog() {
    const url = process.env.REACT_APP_API_URL + `admin_dashboard/v1/uploads/get_catalog_details`
    let headers = { ...JSON.parse(localStorage.getItem('auth')) }
    return this.http.get(url, headers)
  }

}
