import React from "react";
import { Table, Row, Col, Card } from "antd";

function MainTable(props) {
  return (
    <div>
      <Card style={{ paddingTop: 5, marginTop: 20 }}>
        <Row>
          <Col span={24}>
            <Table
              columns={props.columns}
              expandedRowRender={props.expandedRow}
              dataSource={props.dataSource}
              pagination={false}
              components={props.components}
              scroll={{ x: 240 }}
              loading={props.loading}
              onRow={props.onRow}
              rowKey="id"
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default MainTable;
