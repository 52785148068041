import React, { useEffect, useState } from 'react';
import { Table, Checkbox, Spin, Button, Tabs } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { EditOutlined } from '@ant-design/icons';
import { PaymentsService } from "../../../_services/payments";

const { TabPane } = Tabs;
const payments_service = new PaymentsService();
function ParentPayments({getPayments, childData, onChange, moveRow, components, activeParent, getExtensionData,
  showModal, setChildData, childDataExtension, setChildDataExtension, moveRow2, components_extension}){
  const [columns, setColumns] = useState([])
  const [currentTab, setCurrentTab] = useState(1);
  const [renderData, setRenderData] = useState(false)
 
  useEffect(() => {
    if(!renderData){
     if(activeParent?.name === 'UPI'){
       if(currentTab == 2){
        setRenderData(true)
        getExtensionData()
       }
       else{
        getPayments(activeParent);
       }
     } 
    }
  }, [activeParent, currentTab, renderData])

  useEffect(() => {
    if(activeParent?.name === 'UPI')
      getColumns(currentTab)
    else{
      setCurrentTab(1)
      getColumns()
    }
  }, [activeParent, currentTab])

  useEffect(()=>{
    if(childDataExtension){
      childDataExtension.forEach(function(el,idx){
        el.id=idx
      })
    }
  }, [childDataExtension])

  function changeTab(key) {
   
    setCurrentTab(key);
    setChildData([])
    setRenderData(false)
  }

function handleClick(e,record){
  record.id = childDataExtension.find(x=> x?.id=== record?.id)?.id
  showModal(e, record, 'Edit UPI Extension', )
}

  function getColumns(key){
    if(activeParent?.name === 'UPI' && key == 2){
      let colm = []
      colm.push(
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        align: 'center'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 100,
        align: 'center'
      },
      {
        title: 'Edit',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return <Button title="Edit" icon={<EditOutlined />} disabled={record.status === 'disabled'}
                         onClick={(e) => handleClick(e, record)}>
                 </Button>
        }
      })
      setColumns([...colm])
    }
    else{
      let colm = []
      colm.push({
        title: 'Image',
        dataIndex: '',
        key: '',
        width: 80,
        height: 80,
        render: (record) => {
          return (record?.image?.image_url ? 
            <img src={record?.image?.image_url} alt={record.name} className='frz-w-100'/>
          : null)
        }
      },
      {
        title: 'Payment Mode',
        dataIndex: 'name',
        key: 'name',
        align: 'center'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 100,
        align: 'center'
      },
      {
        title: 'Sequence',
        dataIndex: 'sequence',
        key: 'sequence',
        align: 'center',
      },
      {
        title: 'Edit',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return <Button title="Edit" icon={<EditOutlined />} disabled={record.status === 'disabled'}
                        onClick={(e) => showModal(e, record,  'Edit Payment Methods')}>
                </Button>
        }
      })
    setColumns([...colm])
  }
}

  return(
    <div className='frz-payment-box'>
      {childData ?  
        <>
        {activeParent?.name === 'UPI' ? 
      <div className="ost">
        <Tabs defaultActiveKey="1" onChange={changeTab}>
          <TabPane tab="UPI" key="1"></TabPane>
          <TabPane tab="UPI Extension" key="2"></TabPane>
        </Tabs>
      </div> : null}
          <h2>{(activeParent?.name === 'UPI' && currentTab == 2) ? "UPI Extension" : activeParent?.name}</h2>
          {childData.length > 0 || childDataExtension.length > 0? 
            <DndProvider backend={HTML5Backend}>
            <Table dataSource={(activeParent?.name === 'UPI' && currentTab == 2) ? childDataExtension: childData} columns={columns} 
                     pagination={false} components={(activeParent?.name === 'UPI' && currentTab == 2) ? components_extension:components}
                     scroll={(activeParent?.name === 'UPI') ? {y: 'calc(100vh - 330px)' } : { y: 'calc(100vh - 285px)' }}
                     rowKey={ (activeParent?.name === 'UPI' && currentTab == 2) ? 'name' : 'id'}
                     onRow={(record, index) => ( (activeParent?.name === 'UPI' && currentTab == 2) ? {
                      index,
                     moveRow2,
                    }:{index,
                      moveRow})}/>
            </DndProvider>
          : <div className="no-data">No Data Available</div>  
          }
        </>
      :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default ParentPayments