import React, { useState, useEffect } from 'react'
import {
  Spin, Button, Card, Row, Col, Input, InputNumber, Select,
  Pagination, DatePicker, Popconfirm, notification, Tag, Tabs, Table
} from 'antd';
import { SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import FRZInput from '../../_controls/FRZInput';
import { pendingApprovalColumns } from '../../components/antd/columns/store';
import MainTable from '../../components/antd/table';
import { StoreService } from '../../_services/store';
import ModalViewSku from '../../components/store/modalviewsku';
import ModalReason from '../../components/store/modalreason';
import { openNotificationWithIcon } from '../../_modules/notification';
import Filter from '../../components/filter';
import { humanize } from '../../_modules/humanize';
import FrzDynamicSearch from '../../_controls/FrzDynamicSearch';
import CountdownTimer from '../../components/store/countdowntimer';


const nodeType = `DS`
const { TabPane } = Tabs;

const store_service = new StoreService()

const StoreApprovals = () => {
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [listingData, setListingData] = useState(false)
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [showSkuModal, setShowSkuModal] = useState(false)
  const [onRowReject, setOnRowReject] = useState(false)
  const [rowId, setRowId] = useState(false)
  const [loading, setLoading] = useState(false)
  const [viewOnly, setViewOnly] = useState(false)
  const [pendingCount, setPendingCount] = useState(false)
  const [rowData, setRowData] = useState(false)
  const [statusType, setStatusType] = useState(false)
  const [currentTab, setCurrentTab] = useState(false)
  const [isRest, setIsRest] = useState(false)
  const [pedningReqCount, setPedningReqCount] = useState(false)
  const [isTimerUp, setIsTimerUp] = useState(false)
  const [searchValue, setSearchValue] = useState(undefined)
  const [nodeData, setNodeData] = useState([])
  const [status] = useState([{ name: 'BILLED' }, { name: 'NOT_BILLED' }, { name: 'APPROVED' }, { name: 'PAID' }, { name: 'REJECTED' }])
  const [state, setState] = useState({
    valueByRider: undefined, valueByPartner: undefined, valueByStatus: undefined, valueByNode: undefined, valueByRequester: undefined,
    from_date: '', to_date: '', valueByStore: undefined, showDateValue: false
  });
  const { valueByRider, valueByPartner, valueByStatus, valueByStore, from_date, to_date, showDateValue, valueByNode, valueByRequester } = state


  useEffect(() => {
    if (isRest) {
      getListing(currentTab)
    }
  }, [isRest])

  useEffect(() => {
    if (valueByNode) {
      getListing(currentTab)
    }
  }, [valueByNode])

  useEffect(() => {
    getStatusType()
    getNodeListing()
  }, [])

  useEffect(() => {
    if (currentTab) {
      setListingData(false)
      getListing(currentTab)
    }
  }, [currentTab, current])


  // useEffect(() => {
  //   if (valueByNode || current) {
  //     getListing(currentTab)
  //   }
  // }, [valueByNode, current])

  // useEffect(() => {
  //   getListing()
  // }, [current])

  useEffect(() => {
    getColumn()
  }, [listingData, rowId, loading])

  const getStatusType = () => {
    store_service.get_pending_approval_status().subscribe((r) => {
      setStatusType(r.response.data)
      setCurrentTab(r.response.data[0])
    }, (err) => {
      console.log(err);
    })
  }

  const getNodeListing = () => {
    const payload = {
      nodeType: nodeType
    }
    store_service.get_node_listing(payload).subscribe((r) => {
      setNodeData(r.response.data)
    }, (err) => {
      console.log(err);
    })
  }

  const onChangeTab = (e) => {
    setCurrentTab(e)
  }

  const handleTimerUp = (value) => {
    setIsTimerUp(value)
  }

  const handleOnRowApprove = (id) => {
    setLoading(true)
    const payload = {
      request_id: id,
      status: "approved",
      comment: ""
    }
    store_service.sku_row_approval(payload).subscribe((r) => {
      setLoading(false)
      openNotificationWithIcon('success', 'Approved Successfully')
      getListing()
    }, ((err) => {
      setLoading(false)
      console.log("err", err)
      openNotificationWithIcon('error', err.response.errors[0])
    }))
  }

  const setEndTimeNull = (id) => {
    let temp = listingData
    temp.map((data) => {
      if (data.id === id) {
        Object.assign(data, { ...data, expiry_at: null, can_take_action: false })
      }
    })
    setListingData([...temp])
  }

  const getColumn = () => {
    let tempCol = []

    pendingApprovalColumns.map(data => {
      tempCol.push(data)
    })

    if (currentTab === "approved") {
      tempCol.push(
        {
          title: 'Approved By',
          dataIndex: 'approved_by',
          key: 'approved_by',
          align: 'center',
          width: 120
        },
        {
          title: 'Approve On',
          dataIndex: 'last_action_at',
          key: 'last_action_at',
          align: 'center',
          width: 120
        },
        {
          title: 'Action',
          dataIndex: '',
          key: '',
          align: 'center',
          width: 400,
          render: (record) => {
            return (
              record.has_child_requests ?
                <div className="frz-dis-flex frz-vt-center frz-hrz-center">
                  <Button style={{ marginLeft: 10 }} loading={loading && record.id === rowId} className="frz-green-dark" type="primary"
                    onClick={() => showModalSku(true, record, true)}>
                    View SKU
                  </Button>
                </div> : null
            )
          }
        }
      )
    }

    if (currentTab == "partially_approved") {
      tempCol.push({
        title: 'Action',
        dataIndex: '',
        key: '',
        align: 'center',
        width: 400,
        render: (record) => {
          return (
            record.has_child_requests ?
              <div className="frz-dis-flex frz-vt-center frz-hrz-center">
                <Button style={{ marginLeft: 10 }} loading={loading && record.id === rowId} className="frz-green-dark" type="primary"
                  onClick={() => showModalSku(true, record, true)}>
                  View SKU
                </Button>
              </div> : null
          )
        }
      })
    }

    if (currentTab == "expired") {
      tempCol.push({
        title: 'Action',
        dataIndex: '',
        key: '',
        align: 'center',
        width: 400,
        render: (record) => {
          return (
            record.has_child_requests ?
              <div className="frz-dis-flex frz-vt-center frz-hrz-center">
                <Button style={{ marginLeft: 10 }} loading={loading && record.id === rowId} className="frz-green-dark" type="primary"
                  onClick={() => showModalSku(true, record, true)}>
                  View SKU
                </Button>
              </div> : null
          )
        }
      })
    }

    if (currentTab === "rejected") {
      tempCol.push({
        title: 'Rejection Reason',
        dataIndex: 'rejection_reason',
        key: 'rejection_reason',
        align: 'center',
        width: 120
      },
        {
          title: 'Rejected By',
          dataIndex: 'rejected_by',
          key: 'rejected_by',
          align: 'center',
          width: 120
        },
        {
          title: 'Action',
          dataIndex: '',
          key: '',
          align: 'center',
          width: 400,
          render: (record) => {
            return (
              record.has_child_requests ?
                <div className="frz-dis-flex frz-vt-center frz-hrz-center">
                  <Button style={{ marginLeft: 10 }} loading={loading && record.id === rowId} className="frz-green-dark" type="primary"
                    onClick={() => showModalSku(true, record, true)}>
                    View SKU
                  </Button>
                </div> : null
            )
          }
        }
      )
    }

    if (currentTab === "pending") {
      tempCol.push({
        title: 'Action',
        dataIndex: '',
        key: '',
        align: 'center',
        width: 400,
        render: (record) => {
          return (
            <div className="frz-dis-flex frz-vt-center">
              {record.status === "pending" ?
                <div>
                  {record.has_child_requests ?
                    <>
                      {record.has_child_requests ?
                        <Button style={{ marginLeft: 10 }} loading={loading && record.id === rowId} className="frz-green-dark" type="primary"
                          onClick={() => showModalSku(true, record)}>
                          View SKU
                        </Button>
                        : null}
                    </>
                    : <>
                      <Button disabled={!record.can_take_action || record.expiry_at == null} className="frz-lm-10 frz-rm-10 frz-red-border" type="ghost"
                        onClick={() => handleOnRowReject(true, record.id)}>
                        Reject
                      </Button>

                      <Button disabled={!record.can_take_action || record.expiry_at == null} loading={loading && record.id === rowId} className="frz-green-dark" type="primary"
                        onClick={() => {
                          setRowId(record.id)
                          handleOnRowApprove(record.id)
                        }}>
                        Approve
                      </Button>

                    </>}
                </div> : null
              }
            </div >
          )
        }
      },
        {
          title: 'Time',
          dataIndex: '',
          key: '',
          align: 'center',
          width: 220,
          render: (record) => {
            return (
              <div className="show-counter">
                <CountdownTimer targetDate={record.expiry_at} handleTimerUp={handleTimerUp} id={record.id} setEndTimeNull={setEndTimeNull} />
              </div>
            )
          }
        }
      )
    }

    // tempCol.push({
    //   title: 'Action',
    //   dataIndex: '',
    //   key: '',
    //   align: 'center',
    //   render: (record) => {
    //     return (
    //       <div className="frz-dis-flex frz-vt-center">
    //         {record.status === "pending" ? record.approval_type == "Enable Closing Request" ?
    //           <div>
    //             <Button className="frz-lm-10 frz-rm-10 frz-red-border" type="ghost" onClick={() => handleOnRowReject(true, record.id)}>
    //               Reject
    //             </Button>

    //             <Button loading={loading && record.id === rowId} className="frz-green-dark" type="primary"
    //               onClick={() => {
    //                 setRowId(record.id)
    //                 handleOnRowApprove(record.id)
    //               }}>
    //               Approve
    //             </Button>
    //           </div>
    //           : record.approval_type == "Added SKU to closing" ?
    //             <Button className="frz-grey-border" type="ghost" onClick={() => showModalSku(true, record)}>
    //               View SKU
    //             </Button>
    //             : null
    //           : (record.status === "approved" || record.status === "partially_approved") && record.approval_type == "Added SKU to closing" ?
    //             <Button className="frz-grey-border" type="ghost" onClick={() => showModalSku(true, record, true)}>
    //               View SKU
    //             </Button> : null
    //         }
    //       </div>
    //     )
    //   }
    // })
    setColumns([...tempCol])
  }

  const showModalSku = (value, data, approved) => {
    // record.approval_type == "Added SKU to closing"
    if (approved) {
      setViewOnly(true)
    } else {
      setViewOnly(false)
    }
    if (value) {
      setRowData(data)
    } else {
      setRowData(false)
    }
    setShowSkuModal(value)
  }

  const getListing = (type) => {
    setPedningReqCount(false)
    // console.log("api call")
    setSpinEnable(true)
    setIsRest(false)
    setListingData(false)
    // const payload = {
    //   page: current,
    //   created_by: createdBy ? createdBy : '',
    // }

    const payload = {
      page: current ? current : 1,
      // by_city: valueByCity ? valueByCity : '',
      by_requester: valueByRequester ? valueByRequester : '',
      status: currentTab ? currentTab : type ? type : "pending",
      by_node: valueByNode ? valueByNode : ''
    }

    store_service.get_pending_approval_detail(payload).subscribe((r) => {
      setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
      setPedningReqCount(r.response.meta.total_data_count)
      setSpinEnable(false)
      setPendingCount(r.response.meta.total_pending_request_count)
      setListingData(r?.response?.data ?? [])
    },
      (err) => {
        console.log(err)
      })
  }

  function pagination(page) {
    setCurrent(page)
    setListingData(false)
  }

  const handleOnRowReject = (value, id) => {
    setRowId(id)
    setOnRowReject(value)
  }

  const handleOnSearch = (value) => {
    setSearchValue(value)
    getListing(value)
  }

  function onChange(value, type) {
    setListingData(false)
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(false)
  }

  function reset() {
    setState({
      valueByCity: undefined, valueByNode: undefined,
    })
    setIsRest(true)
    // setListingData(false)
    // getListingData(statusType[0])
    setDisable(true)
    setCurrent(false)
    // setRenderData(false)
  }

  return (
    <div className="layer">
      <div>
        <Row>
          <Card>
            <Col span={24}>
              <FrzDynamicSearch DropDownData={nodeData}
                placeholder="Select Node" value="id" option="name"
                isShowSearch={true} width={300} setOnChange={(e) => onChange(e, 'valueByNode')}
                type="valueByNode" margin={10} defValue={valueByNode} />

              <Button disabled={disable} onClick={reset} type="primary"
                className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
            </Col>
          </Card>
        </Row>
        {statusType ?
          statusType.length ?
            <div className="card-container frz-mt-10">
              <Tabs type="card" className="frz-approval-tab" onChange={(e) => onChangeTab(e)}>
                {statusType.map((data) => {
                  return (
                    <TabPane tab={data === "pending" ? <div className="frz-dis-flex">
                      {currentTab === "pending" ?
                        <div className="frz-count-pending frz-dis-flex frz-vertical-center frz-hrz-center">
                          {pedningReqCount}
                        </div>
                        : null}
                      <div className="frz-dis-flex frz-vertical-center">
                        {humanize(data)}
                      </div>
                    </div> : humanize(data)} key={data}>
                      <Card className="frz-pending-approval-card">
                        {listingData ?
                          listingData.length ?
                            <>
                              <Table
                                columns={columns}
                                dataSource={listingData}
                                pagination={false}
                                scroll={{ x: 240, y: `calc(100vh - 320px)` }}
                                rowKey="id"
                              />
                            </>
                            : <div className="no-data">No Data Available</div>
                          : <div className="spin-center">
                            <Spin tip="...Loading" />
                          </div>}
                      </Card>
                      {listingData.length ?
                        <Pagination className="frz-tm-10 frz-bm-10" current={current ? current : 1}
                          pageSize={50} total={state.total} onChange={pagination}
                          showSizeChanger={false} />
                        : null}
                    </TabPane>
                  )
                })}
              </Tabs>
            </div>
            : <div className="no-data">No Data Available</div>
          : <div className="spin-center">
            <Spin tip="...Loading" />
          </div>}
      </div>
      {showSkuModal ?
        <ModalViewSku viewOnly={viewOnly} showSkuModal={showSkuModal} showModalSku={showModalSku} rowData={rowData} getParentListing={getListing} />
        : null}

      {onRowReject ?
        <ModalReason onRowReject={onRowReject} handleOnRowReject={handleOnRowReject} rowId={rowId} getParentListing={getListing} />
        : null}
    </div>
  )
}

export default StoreApprovals