import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button, Select, Input, InputNumber,
         Radio, TimePicker} from 'antd';
import Search from '../../antd/search';
import moment from 'moment';

const { Option } = Select;
const format = 'HH';

const DeliverySlotModal = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOk() {
    setLoading(true)
    props.modalSubmit()
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.onCancelModal();
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onOk={handleOk}
          onCancel={handleCancelModal}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              Submit
            </Button>,
          ]}
        >
          <div className="block" style={{marginLeft:10}}>From Time :
            <span style={{paddingLeft:10}}>
              <TimePicker format={format}
                          use24Hours
                          value={!props.from_time ? '' : moment(props.from_time, 'HH')}
                          onChange={(e, f) => props.onChangeModal(e, f, 'from_time')}/>
            </span>
          </div>
          <div className="block" style={{marginLeft:28}}>To Time :
            <span style={{paddingLeft:10}}>
              <TimePicker format={format}
                          use24Hours
                          value={!props.to_time ? '' : moment(props.to_time, 'HH')}
                          onChange={(e, f) => props.onChangeModal(e, f, 'to_time')}/>
            </span>
          </div>
          <div className="block" style={{marginLeft:24}}>Is Active :
            <span style={{paddingLeft:10}}>
              <Radio.Group
                     value={props.valueByisactive}
                     onChange={(e) => props.onChangeModalRadio(e.target.value, 'valueByisactive')}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </span>
          </div>
          <div className="block" style={{marginLeft:24}}>Is Pickup :
            <span style={{paddingLeft:10}}>
              <Radio.Group
                     value={props.valueByispickup}
                     onChange={(e) => props.onChangeModalRadio(e.target.value, 'valueByispickup')}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </span>
          </div>
          <div className="block">Is Scheduled :
            <span style={{paddingLeft:10}}>
              <Radio.Group
                     value={props.valueByisscheduled}
                     onChange={(e) => props.onChangeModalRadio(e.target.value, 'valueByisscheduled')}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </span>
          </div>
          <div className="block" style={{marginLeft:18}}>Is Society :
            <span style={{paddingLeft:10}}>
              <Radio.Group
                     value={props.valueByissociety}
                     onChange={(e) => props.onChangeModalRadio(e.target.value, 'valueByissociety')}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </span>
          </div>
        </Modal>
      : null }
    </div>
  )
})

export default DeliverySlotModal
