import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Select, Button, Spin, notification, DatePicker } from 'antd';
import { DownloadService } from '../../_services/downloads';
import { useSelector } from "react-redux";
import moment from 'moment';
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const download_service = new DownloadService()

const { Option } = Select;

function DarkstoreSales() {
    const darkstore = useSelector(state => state.auth.darkstore);
    const [state, setState] = useState({ start_date: '', end_date: '', showDateValue: false, valueByDarkstore: [] });
    const [spinEnable, setSpinEnable] = useState(false)
    const [downloadOption, setDownloadOption] = useState(undefined)
    const [d1, setD1] = useState(false)
    const [d2, setD2] = useState(false)
    const [d3, setD3] = useState(false)

    const { valueByDarkstore, start_date, end_date, showDateValue } = state

    useEffect(() => {
        JSON.parse(localStorage.getItem('roles')).forEach(data => {
            if (['admin', 'developer', 'super_admin', 'accounts', 'store_download'].includes(data)) {
                setD1(true)
            }
        })
        JSON.parse(localStorage.getItem('roles')).forEach(data => {
            if (['admin', 'developer', 'super_admin', 'accounts', 'sales_download'].includes(data)) {
                setD2(true)
            }
        })
        JSON.parse(localStorage.getItem('roles')).forEach(data => {
            if (['admin', 'developer', 'super_admin', 'accounts', 'pricing_download'].includes(data)) {
                setD3(true)
            }
        })
    }, [])

    function getDownloadList() {
        setSpinEnable(true)
        const payload = {
            start_date: start_date,
            end_date: end_date,
            darkstore: valueByDarkstore,
            url: downloadOption
        }
        if (downloadOption == 'get_edited_items_report') {
            download_service.download_darkstore_report_store(payload).subscribe((r) => {
                var myVar = setInterval(() => {
                    download_service.download_reports_job_fraazo(r.response.data.id).subscribe(res => {
                        if (res.response.csv_data.status === 'success') {
                            clearInterval(myVar)
                            window.open(res.response.csv_data.url, '_blank')
                            openNotificationWithIcon('success', 'Downloaded Successfully')
                            setSpinEnable(false)
                        }
                    }, (err) => {
                        setSpinEnable(false)
                        clearInterval(myVar)
                        openNotificationWithIcon('error', 'Download Failed')
                    })
                }, 8000);
            }, (err) => {
                setSpinEnable(false)
                openNotificationWithIcon('error', 'Download Failed')
            })
        } else {
            download_service.download_darkstore_report(payload).subscribe((r) => {
                if (downloadOption === 'darkstore_sales_report' || downloadOption === 'darkstore_sales_qty_report' ||
                    downloadOption === 'order_level_sales_report' || downloadOption === 'packer_performance_report' ||
                    downloadOption === 'shadowfax_report') {
                    var myVar = setInterval(() => {
                        download_service.download_reports_job_fraazo(r.response.data.id).subscribe(res => {
                            if (res.response.csv_data.status === 'success') {
                                clearInterval(myVar)
                                window.open(res.response.csv_data.url, '_blank')
                                openNotificationWithIcon('success', 'Downloaded Successfully')
                                setSpinEnable(false)
                            }
                        }, (err) => {
                            setSpinEnable(false)
                            clearInterval(myVar)
                            openNotificationWithIcon('error', 'Download Failed')
                        })
                    }, 8000);
                } else {
                    const linkSource = `data:application/octet-stream;base64,${r.response.csv_data}`;
                    const downloadLink = document.createElement("a");
                    const fileName = `${downloadOption}_Report_${(new Date).toDateString().replace(/ /g, '_')}.csv`;
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                    openNotificationWithIcon('success', 'Downloaded Successfully')
                    setSpinEnable(false)
                }
            }, (err) => {
                setSpinEnable(false)
                openNotificationWithIcon('error', 'Download Failed')
            })
        }
    }

    function openNotificationWithIcon(type, msg) {
        notification[type]({ message: type.toUpperCase(), duration: 3, description: msg });
    };

    function onChangeDarkstore(values) {
        // console.log(values)
        setState(prevState => ({ ...prevState, valueByDarkstore: values }))
    }

    function onChangeRangePicker(value, dateString) {
        setState(prevState => ({ ...prevState, showDateValue: true, start_date: dateString[0], end_date: dateString[1] }))
    }

    function disabledDate(current) {
        // Can select days before today
        // return current && current > moment().add(0, "day");
        //Can select days before today and past 3 months
        return current && (current < moment().subtract(91, "day") || current > moment().add(0, "day"));
    }

    function onChange(val) {
        setDownloadOption(val)
    }

    return (
        <div className="layer" style={{ paddingTop: 20 }}>
            {!spinEnable ?
                <Row>
                    <Card>
                        <Col span={24} style={{ marginLeft: 10 }}>
                            <h3 style={{ marginTop: 10, marginBottom: 0 }}>
                                Darkstore Sales | SKU Price List | SKU Qty |
                                Delivery Grouping | Darkstore Sales Qty | Order Sales |
                                Packer Performance | Shadowfax Report
                            </h3>
                            <RangePicker onChange={onChangeRangePicker}
                                value={showDateValue ? [moment(start_date, dateFormat),
                                moment(end_date, dateFormat)] : ''} format={dateFormat}
                                allowClear={false} disabledDate={disabledDate} />
                            {darkstore ?
                                <Select mode="multiple" style={{ width: 400, marginLeft: 10 }}
                                    value={valueByDarkstore}
                                    placeholder="Select Darkstore (Optional)" onChange={onChangeDarkstore}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {darkstore.map(data => {
                                        return (
                                            <Option value={data.id} key={data.id}>{data.name}</Option>
                                        )
                                    })}
                                </Select>
                                : null
                            }
                            <Select style={{ width: 200, margin: 10 }}
                                value={downloadOption}
                                placeholder="Select Download Item" onChange={(e) => onChange(e)}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                <Option key="darkstore_sales_report" value="darkstore_sales_report" disabled={!d2}>
                                    Darkstore Sales
                                </Option>
                                <Option key="delivery_grouping_report" value="delivery_grouping_report" disabled={!d1}>
                                    Delivery Grouping
                                </Option>
                                <Option key="sku_price_list" value="sku_price_list" disabled={!d3}>
                                    SKU Price List
                                </Option>
                                <Option key="darkstore_wise_qty_report" value="darkstore_wise_qty_report" disabled={!d1}>
                                    SKU Qty
                                </Option>
                                <Option key="darkstore_sales_qty_report" value="darkstore_sales_qty_report" disabled={!d1}>
                                    Darkstore Sales Qty
                                </Option>
                                <Option key="order_level_sales_report" value="order_level_sales_report" disabled={!d2}>
                                    Order Sales
                                </Option>
                                <Option key="packer_performance_report" value="packer_performance_report" disabled={!d1}>
                                    Packer Performance
                                </Option>
                                <Option key="get_edited_items_report" value="get_edited_items_report" disabled={!d1}>
                                    Edited Items Report
                                </Option>
                                <Option key="shadowfax_report" value="shadowfax_report" disabled={!d2}>
                                    Shadowfax Report
                                </Option>
                            </Select>
                            <Button disabled={start_date === '' || downloadOption === undefined} onClick={getDownloadList} style={{ width: 100, margin: 10 }}
                                type="primary">Download</Button>
                        </Col>
                    </Card>
                </Row>
                :
                <div className="spin-center">
                    <Spin tip="...Download in Progress" />
                </div>
            }
        </div>
    )
}

export default DarkstoreSales