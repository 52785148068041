import { Http } from "../_modules/http";

export class OrderLocationService {
  http = new Http();

  get_orders_flagged(payload) {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/orders/customer_property_flag?type=" +
      payload.type + "&page=" + payload.page + "&order_number=" + payload.orderId +
      "&from_date=" + payload.start_date + "&to_date=" + payload.end_date +
      "&mobile_number=" + payload.valueByMobNo + "&by_city=" + `${localStorage.getItem('city') ?? ''}`;

    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_prev_orders(payload) {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/orders/previous_orders_address?customer_id=" +
      payload.customer_id;

    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  update_customer_address(payload) {
    const url =
      process.env.REACT_APP_API_URL +
      "/admin_dashboard/v1/orders/customer_property_flag/" +
      payload.order_id;

    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.put(url, payload.body, headers);
  }

  get_coordinate_by_address(address) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyCmijT_PoZ6_j28v0Of37JWKu7ACqMolmY`;
    return this.http.get(url);
  }

  get_addtess_by_coordinates(address) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${address.lat + "," + address.long}&key=AIzaSyCmijT_PoZ6_j28v0Of37JWKu7ACqMolmY`
    return this.http.get(url);
  }

}
