import React, { useEffect, useState } from "react";
import { Input, Button, Spin, InputNumber, Modal, Table, Radio, notification } from "antd";
import { ProductService } from "../../../_services/product";
import { darkStoreFlashSalesColumns } from "../../../components/antd/columns/master/product";

function DarkStoreFlashSales(props) {
  const productService = new ProductService();
  const [darkStoreFlashSales, setDarkStoreFlashSales] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [operations, setOperations] = useState(false)
  const [columns, setColumns] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalRecord, setModalRecord] = useState(false);
  const commonInputWidth = 250;
  const editedRecordValues = {};

  const inputStyle = {
    marginRight: 100,
    textAlign: "right",
  };

  const inputStyle2 = {
    marginRight: 100,
    textAlign: "right",
    display: 'flex'
  };

  const inputStyle3 = {
    marginRight: 50,
    textAlign: "right",
  };

  useEffect(() => {
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if(['admin','developer','super_admin'].includes(data)){
        setOperations(true)
      }
    })
    getColumns();
    getDarkStoreFlashSales();
  }, []);

  function getColumns() {
    darkStoreFlashSalesColumns.map((data) => {
      columns.push(data);
    });

    columns.push({
      title: "Actions",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, record) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              setModalRecord(record);
              setModalVisible(true);
            }}
          >
            Edit
          </Button>
        );
      },
    });
    setColumns(columns);
  }

  function handleCancel() {
    setModalVisible(false);
  }

  function getDarkStoreFlashSales() {
    setTableLoading(true);
    productService.darkstore_flash_sales(props.productId).subscribe(
      (r) => {
        setDarkStoreFlashSales(r.response.data);
      },
      (err) => {
        openNotificationWithIcon('error', "Failed to get details")
      },
      () => {
        setTableLoading(false);
      }
    );
  }

  function saveProductDetails() {
    // if (!checkValidation()) {
    //   ErrorNotification("Please fill all details");
    //   return;
    // }
    setButtonLoading(true);
    const payload = {
      ...editedRecordValues,
      id: modalRecord.id,
    };
    // console.log(payload);

    productService.update_flash_sales(payload).subscribe(
      (r) => {
        getDarkStoreFlashSales();
        openNotificationWithIcon('success', 'Details updated successfully')
        setButtonLoading(false);
        setModalVisible(false);
      },
      (err) => {
        openNotificationWithIcon('error', err.response.errors[0])
        setButtonLoading(false);
      }
    );
  }

  function openNotificationWithIcon(type, msg) {
    notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  function checkValidation() {
    Object.keys(editedRecordValues).forEach((key) => {
      if (editedRecordValues[key] == undefined) {
        return false;
      }
    });
    return true;
  }

  function onInputChange(key, value) {
    editedRecordValues[key] = value;
    if (value === "") {
      editedRecordValues[key] = undefined;
    }
  }

  const FZInputNumber = ({ keyName }) => {
    editedRecordValues[keyName] = modalRecord[keyName];
    return (
      <div className="block" style={inputStyle}>
        <span style={{ verticalAlign: "top" }}>{keyName.toUpperCase()}*</span>
        <span style={{ paddingLeft: 10 }}>
          <InputNumber
            placeholder={keyName}
            style={{ width: 200 }}
            min={0}
            onChange={(v) => onInputChange(keyName, v)}
            defaultValue={modalRecord[keyName] ?? undefined}
          />
        </span>
      </div>
    );
  };

  const FZInputRadioSelector = ({ keyName }) => {
    editedRecordValues[keyName] = modalRecord[keyName];
    return (
      <div className="block" style={inputStyle3}>
        <span style={{ verticalAlign: "top" }}>{keyName.toUpperCase()}*</span>
        <span style={{ paddingLeft: 10 }}>
          <Radio.Group
            style={{ width: commonInputWidth, textAlign: "left" }}
            defaultValue={modalRecord[keyName] ?? undefined}
            disabled={!operations && keyName === 'is_active'}
            onChange={(e) => onInputChange(keyName, e.target.value)}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </span>
      </div>
    );
  };

  return (
    <div>
      {darkStoreFlashSales ? (
        <div>
          <Table
            columns={columns}
            dataSource={darkStoreFlashSales}
            pagination={false}
            scroll={{ x: 240 }}
            loading={tableLoading}
            rowKey="id"
          />
          <Modal
            title={modalRecord?.darkstore_name}
            visible={modalVisible}
            onOk={saveProductDetails}
            confirmLoading={buttonLoading}
            onCancel={handleCancel}
            width='45%'
          >
            <FZInputNumber keyName="min_sale_qty" />
            <FZInputNumber keyName="min_discount_percentage" />
            <FZInputNumber keyName="max_discount_percentage" />
            <FZInputRadioSelector keyName="is_active" />
          </Modal>
        </div>
      ) : (
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      )}
    </div>
  );
}

export default DarkStoreFlashSales;
