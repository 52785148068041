import React from 'react';
import { Tabs } from 'antd';
import GrowthAndPlanningComponent from '../../components/distribution/growthAndPlanning';

const { TabPane } = Tabs;

const GrowthAndPlanningTab = () => {
  return (
    <Tabs centered>
      <TabPane
        tab="Order Count"
        key="1"
      >
        <GrowthAndPlanningComponent tab="growth" />
      </TabPane>
      <TabPane
        tab="Manpower Planning"
        key="2"
      >
        <GrowthAndPlanningComponent tab="planning" />
      </TabPane>
    </Tabs>
  )
}

export default GrowthAndPlanningTab;