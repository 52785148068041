import React, { useEffect, useState, useRef } from "react";
import { DSPropertiesService } from '../../_services/master/darkstore_properties';
import { Row, Card, Col, Table, Spin, Input } from "antd";
import { dsPropertiesColumns } from '../../components/master/darkstore_properties/dsPropertiesColumns';
import DsProperties from '../../components/master/darkstore_properties/ds_properties';

const dsproperties_service = new DSPropertiesService()

const initialInternalFilters = {
  dsName: null,
}

const initialState = {
  spinner: false,
  err: false
}

function DarkstoreProperties() {
  const modalRef = useRef(null);
  const [tableData, setTableData] = useState(false)
  const [filteredList, setFilteredList] = useState(false)
  const [state, setState] = useState(initialState)
  const [internalFilters, setInternalFilters] = useState(initialInternalFilters)

  useEffect(() => {
    getTableList()
  }, [])

  function getTableList() {
    setState(prevState => ({ ...prevState, spinner: true }))
    let urlParam = `?by_city=${localStorage.getItem('cityid') ?? ''}` 
    dsproperties_service.get_ds_properties(urlParam).subscribe((r) => {
      setState(prevState => ({ ...prevState, spinner: false }))
      setTableData(r.response.data)
      setFilteredList(r.response.data)
    }, (err) => {
      setState(prevState => ({ ...prevState, spinner: false, err: true }))
      setTableData([])
      setFilteredList([])
    })
  }

  function updateTableList(data){
    tableData[tableData.findIndex(x => x.id === data.id)] = data
    setTableData([...tableData])
    filteredList[filteredList.findIndex(x => x.id === data.id)] = data
    setFilteredList([...filteredList])
  }

  function onChangeFilters(value, type) {
    setInternalFilters(prevState => ({ ...prevState, [type]: value }))                             
  }

  useEffect(() => {
    tableData && setFilteredList(tableData.filter(x => (internalFilters.dsName ? (x.darkstore_name?.toLowerCase().includes(internalFilters?.dsName?.toLowerCase())) : true)))
  },[internalFilters])

  return (
    <div className="layer-nofilter">
      {tableData ?
        <div>
          {!state.err && tableData?.length > 0 ? 
            <Row>
              <Col span={24}>
                <Card>
                  <Input placeholder="Search Darkstore" allowClear
                    onChange={(e) => onChangeFilters(e.target.value, 'dsName')}
                    value={internalFilters.dsName} className="frz-w-200 frz-m-8"
                    disabled={state.spinner}/>
                </Card>
              </Col>
            </Row>
          : null}
          {filteredList?.length > 0 && !state.err ?
            <Spin spinning={state.spinner}>
              <Row>
                <Col span={24}>
                  <Card className="frz-mt-5">
                    <Table dataSource={filteredList} pagination={false} scroll={{ y: 'calc(100vh - 194px)' }} 
                      rowKey={'id'} columns={dsPropertiesColumns((rowdata) => modalRef.current.showModal(rowdata, 'Edit Properties'))} />
                  </Card>
                </Col>
              </Row>
            </Spin>
          : <div className="no-data">{state.err ? 'Backend API System Down' : 'No Data Available'}</div>}
          <DsProperties ref={modalRef} updateTableList={updateTableList} dsproperties_service={dsproperties_service}/>
        </div>
      :
        <div className="spin-center"><Spin tip="...Loading" /></div>
      }
    </div>
  )
}

export default DarkstoreProperties