import React, { useEffect, useState } from 'react';
import {
  Spin, Button, Card, Row, Col, Tabs, Input,
  Pagination, DatePicker, Table
} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import Search from '../../components/antd/search';
import { Select } from 'antd';
import { attendanceColumns, cityWiseAttendanceColumns, cityWiseNestedCol } from '../../components/antd/columns/attendance_report';
import moment from 'moment';
import { DarkstoreService } from '../../_services/darkstore';
import AttendanceTable from '../../components/master/darkstore/attendance_table';
import FrzDynamicDownload from '../../_controls/FRZDynamicDownload';
const darkstore_service = new DarkstoreService();
const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

function AttendanceReport() {
  const [listingData, setListingData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [currentTab, setCurrentTab] = useState(1);
  const [roles, setRoles] = useState(false)
  const [pnpVendors, setPnpVendors] = useState(false);
  const [state, setState] = useState({
    valueByRoles: 'store_boy', valueByPnpVendors: undefined,
    valueBySource: undefined, valueByDeliveryDate: '', date: moment().add(0, "day"),
    start_date: moment().add(0, "day").format('YYYY-MM-DD'), end_date: moment().add(0, "day").format('YYYY-MM-DD'),
    showDateValue: true
  });

  const { valueBySource, valueByDeliveryDate, valueByRoles, date, valueByPnpVendors,
    start_date, end_date, showDateValue } = state
  const [nestedCol, setNestedCol] = useState(false);

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getListing(currentTab)
    }
  }, [currentTab, renderData])

  useEffect(() => {
    getColumns(currentTab)
  }, [currentTab])

  useEffect(() => {
    getRoles();
    getPnpVendors();
  }, [])

  function getRoles() {
    darkstore_service.get_roles().subscribe((r) => {
      let temp = r.response;
      temp.push('all')
      setRoles(temp)
    },
      (err) => {
        console.log(err)
      })
  }

  function getPnpVendors() {
    darkstore_service.get_pnp_vendors().subscribe((r) => {
      setPnpVendors(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getListing(key) {
    setSpinEnable(true)
    setListingData([])
    const payload = {
      node_code: !valueBySource ? '' : valueBySource,
      date: !valueByDeliveryDate ? moment().add(0, "day").format(dateFormat) : valueByDeliveryDate,
      roles: !valueByRoles ? [] : valueByRoles == "all" ? [] : valueByRoles,
      pnp_vender_id: !valueByPnpVendors ? '' : valueByPnpVendors,
      start_date: start_date,
      end_date: end_date,
    }
    if (key == 1) {
      delete payload.start_date
      delete payload.end_date
      darkstore_service.get_darkstore_wise_attendance(payload).subscribe((r) => {
        setState(prevState => ({ ...prevState }))
        setListingData(r.response.data)
        setSpinEnable(false)
      },
        (err) => {
          setSpinEnable(false)
          setListingData([])
          console.log(err)
        })
    }
    else {
      delete payload.darkstore_id
      delete payload.date
      darkstore_service.get_all_city_wise_attendance(payload).subscribe((r) => {
        setState(prevState => ({ ...prevState }))
        setListingData(r.response.data)
        setSpinEnable(false)
      },
        (err) => {
          setSpinEnable(false)
          setListingData([])
          console.log(err)
        })
    }
  }

  function changeTab(key) {
    setState({
      valueBySource: undefined, valueByPnpVendors: undefined,
      valueByRoles: "store_boy", showDateValue: true, start_date: moment().add(0, "day").format('YYYY-MM-DD'), end_date: moment().add(0, "day").format('YYYY-MM-DD'),
      valueByDeliveryDate: '', date: moment().add(0, "day")
    })
    setCurrentTab(key);
    setListingData([]);
    setDisable(true);
    setRenderData(false);
  }

  function getColumns(key) {
    if (key == 1) {
      let tempCol = []
      attendanceColumns.map(data => {
        tempCol.push(data)
      })
      setColumns(tempCol)
    }
    else {
      let tempCol = []
      cityWiseAttendanceColumns.map(data => {
        tempCol.push(data)
      })
      setColumns(tempCol)
      setNestedCol(cityWiseNestedCol)
    }
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
  }

  function onChangeRoles(value, type) {
    setState(prevState => ({ ...prevState, valueByRoles: value }))
    setDisable(false)
  }

  function onChangeDate(date, dateString) {
    setState(prevState => ({ ...prevState, date: date, valueByDeliveryDate: dateString }))
    setDisable(false)
  }

  function onChangeRangePicker(value, dateString) {
    setState(prevState => ({ ...prevState, showDateValue: true, start_date: dateString[0], end_date: dateString[1] }))
    setDisable(false)
  }

  function reset() {
    setState({
      valueBySource: undefined, valueByPnpVendors: undefined, showDateValue: true, start_date: moment().add(0, "day").format(dateFormat),
      end_date: moment().add(0, "day").format(dateFormat),
      valueByRoles: "store_boy", valueByDeliveryDate: '', date: moment().add(0, "day")
    })
    setDisable(true)
    setListingData([])
    setRenderData(false)
  }

  function disabledDate(current) {
    return current && current > moment().add(0, "day");
  }

  function expandedRow(record) {
    return (
      <AttendanceTable nestedTableData={record} nestedCol={nestedCol} valueByPnpVendors={valueByPnpVendors}
        valueByDeliveryDate={valueByDeliveryDate} valueByRoles={valueByRoles} />
    )
  }

  return (
    <div className="layer">
      <div>
        <div className="ost">
          <Tabs defaultActiveKey="1" onChange={changeTab}>
            <TabPane tab="Darkstore Wise" key="1"></TabPane>
            <TabPane tab="City Wise" key="2"></TabPane>
          </Tabs>
        </div>

        {listingData && roles && pnpVendors ?
          <div>
            <Row>
              <Card>
                <Col span={24}>
                  {currentTab == 1 ? <Search placeholder="Darkstore" value={valueBySource} width="medium"
                    onChange={(e) => onChange(e, 'valueBySource')} type="valueByDarkstoreCode" /> : null}
                  <Search placeholder="Roles" value={valueByRoles}
                    onChange={onChangeRoles} type="valueByRoles"
                    data={roles} />
                  <Search placeholder="Vendors" value={valueByPnpVendors}
                    onChange={onChange} type="valueByPnpVendors"
                    data={pnpVendors} />
                  {currentTab == 1 ?
                    <DatePicker value={date} onChange={onChangeDate} placeholder="Date"
                      style={{ width: 200, margin: 10 }} disabledDate={disabledDate} />
                    :
                    <RangePicker onChange={onChangeRangePicker} style={{ margin: 10 }}
                      value={showDateValue ? [moment(start_date, dateFormat),
                      moment(end_date, dateFormat)] : ''} format={dateFormat}
                      allowClear={false} disabledDate={disabledDate} />
                  }

                  <Button disabled={disable} onClick={(e) => getListing(currentTab)}
                    style={{ width: 100, margin: 10 }} type="primary">Search</Button>
                  <Button disabled={disable} onClick={reset} style={{ margin: 10 }}
                    type="primary" ghost shape="circle" icon={<ReloadOutlined />} />
                  {currentTab == 1 ? <span className="fl-right">
                    <FrzDynamicDownload name={'Reports'} fileName={'Reports'} url={'daily_attendance_reports/node_wise'}
                      payload={`?date=${valueByDeliveryDate}&platform=store_dashboard&node_code=${valueBySource === undefined ? '' : valueBySource}&roles=${valueByRoles === undefined ? '' : valueByRoles}&pnp_vender_id=${valueByPnpVendors === undefined ? '' : valueByPnpVendors}&download=true`}
                      type={'job'} reportFunc={'fraazo_report_auth'} jobReportFunc={'download_reports_job_fraazo'} base={'fraazo'} />
                  </span> :
                    <span className="fl-right">
                      <FrzDynamicDownload name={'Reports'} fileName={'Reports'} url={'daily_attendance_reports/all_city'}
                        payload={`?start_date=${start_date}&end_date=${end_date}&roles=${valueByRoles === undefined ? '' : valueByRoles}&pnp_vender_id=${valueByPnpVendors === undefined ? '' : valueByPnpVendors}&download=true`}
                        type={'job'} reportFunc={'fraazo_report_auth'} jobReportFunc={'download_reports_job_fraazo'} base={'fraazo'} />
                    </span>}
                </Col>
              </Card>
            </Row>
            {listingData.length > 0 ?
              <Row>
                <Col span={24}>
                  <Card style={{ paddingTop: 5, marginTop: 20 }}>
                    <Row>
                      <Col span={24}>
                        {currentTab == 1 ? <Table
                          columns={columns}
                          dataSource={listingData}
                          pagination={false}
                          scroll={{ y: 'calc(96vh - 325px)' }}
                          rowKey="darkstore_name"
                        />
                          :
                          <Table
                            columns={columns}
                            dataSource={listingData}
                            pagination={false}
                            expandedRowRender={expandedRow}
                            scroll={{ y: 'calc(96vh - 350px)' }}
                            rowKey="date"
                          />}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              : (disable || spinEnable ?
                (spinEnable ?
                  <div className="spin-center"><Spin tip="...Loading" /></div>
                  :
                  <div className="no-data">No Data Available</div>)
                :
                <div className="no-data">No Data Available On Selected Filters</div>)
            }
          </div>
          :
          <div className="spin-center">
            <Spin tip="...Loading" />
          </div>
        }
      </div>

    </div>
  )
}

export default AttendanceReport
