import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Popconfirm, Form, Row, Card, Col, Select, Spin, InputNumber, DatePicker, Modal } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { capacityColumns } from '../../components/antd/columns/master/capacity';
import { CapacityService } from '../../_services/capacity'
import Search from '../../components/antd/search';
import moment from 'moment';
import FrzDynamicDownload from '../../_controls/FRZDynamicDownload';
import { openNotificationWithIcon } from '../../_modules/notification';
import ReasonModal from '../../components/capacity/reasonModal';

const capacity_service = new CapacityService()
const EditableContext = React.createContext(null);

let referanceData;

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  setPrevState,
  setRowId,
  setCurrentRowForm,
  ...restProps
}) => {
  const [editing, setEditing] = useState(true);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  // useEffect(() => {
  //   if (editing) {
  //     // inputRef.current.focus();
  //   }
  // }, [editing]);

  const toggleEdit = (data) => {
    if (true) {
      setTimeout(() => {
        form.setFieldsValue({
          [dataIndex]: data.total_capacity,
        });
      }, 0);
    }
    // setEditing(!editing);
    // form.setFieldsValue({
    //   [dataIndex]: record[dataIndex],
    // });
  };

  const handleOnBlur = (time) => {
    setRowId(null)
    form.resetFields()
  }

  const save = (number, type) => {
    setRowId(record.id)
    setPrevState({ [record.id]: number, type, id: record.id });
    setCurrentRowForm(form);
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form form={form} initialValues={{ scheduled_percentage: record['scheduled_percentage'], total_capacity: record['total_capacity'] }}>
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          <InputNumber ref={inputRef}
            onChange={(e) => save(e, dataIndex)}
            onBlur={handleOnBlur}
          />
        </Form.Item>
      </Form>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const Capicity = () => {
  const [listingData, setListingData] = useState(false)
  // const [count, setCount] = useState(2)
  const [state, setState] = useState({ valueByDate: undefined, date: moment(), valueByDarkStore: undefined, });
  const { valueByDarkStore, date, valueByDate } = state
  const [disable, setDisable] = useState(true)
  const [column, setColumn] = useState(false)
  const [spinEnable, setSpinEnable] = useState(false)
  const [rowId, setRowId] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  // const [isDataSearched, setIsDataSearched] = useState(false)
  const [rowUpdate, setRowUpdate] = useState(false)
  const [expressStatus, setExpressStatus ] = useState(undefined)
  const [prevState, setPrevState] = useState(false)
  const [operations, setOperations] = useState(false)
  const [isModalVisible, setModalVisibility] = useState(false);
  const [reasonData, setReasonData] = useState(false);
  const [selectedReason, setSelectedReason] = useState(false);
  const [currentRowForm, setCurrentRowForm] = useState(null);

  const modalRef = useRef();
  // useEffect(() => {
  //   if (isDataSearched) {
  //     getListingData()
  //   }
  // }, [isDataSearched])

  useEffect(() => {
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if(['capacity_planner_admin','capacity_planner','developer_admin','super_admin'].includes(data)){
        setOperations(true)
      }
    })
    getReasonData();
  },[])

  useEffect(() => {
    getColumn()
  }, [listingData, rowId, prevState, isLoading])

  const getColumn = () => {
    let tempCol = []
    capacityColumns.map((data) => {
      tempCol.push(data)
    })
    tempCol.push(
      {
        title: 'Scheduled Percentage',
        dataIndex: 'scheduled_percentage',
        key: "scheduled_percentage",
        editable: true,
      },
      {
        title: 'Total Capacity',
        dataIndex: 'total_capacity',
        key: "total_capacity",
        editable: true,
      },
      {
        title: 'Action',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (
            <Button
              // loading={isLoading && rowId == record.id}
              disabled={record.id !== rowId || !operations}
              type="primary" onMouseDown={(e) => {setFormValue(); openModal(e, record)}}>Save</Button>
          )
        }
      })
    tempCol = tempCol.map((col) => {
      if (!col.editable || !operations) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          setRowId,
          setPrevState,
          setCurrentRowForm
        }),
      };
    });
    setColumn([...tempCol])
  }

  const getListingData = () => {
    setSpinEnable(true)
    setListingData([])
    const payload = {
      date: valueByDate ? moment(valueByDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
      id: valueByDarkStore ? valueByDarkStore : '',
    }
    capacity_service.get_capacity(payload).subscribe((r) => {
      setSpinEnable(false)
      setListingData(r.response.data)
      setExpressStatus(r.response.express_status);
      referanceData = [...r.response.data]
    },
      (err) => {
        
      })

  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };


  const reset = () => {
    setState({ date: moment() })
    setDisable(true)
    setListingData(false)
    setExpressStatus(undefined)
    // setRenderData(false)
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
  }

  function onChangeDate(date, dateString) {
    setState(prevState => ({ ...prevState, date: date, valueByDate: dateString }))
  }

  const openModal = (e, record) => {
    let availableSlotVal = currentRowForm.getFieldValue('total_capacity');
    if (availableSlotVal < 0) {
      openNotificationWithIcon("error", "Total capacity cannot be less than 0")
      setRowId(false)
      return
    } else if (availableSlotVal < record.available_slots) {
      setModalVisibility(!isModalVisible);
      setSelectedReason(false);
    } else {
      handleOnRowSave()
    }
  }

  const closeModal = () => {
    setModalVisibility(!isModalVisible);
    setSelectedReason(false);
    currentRowForm.resetFields();
    setRowId(null)
  }

  const setFormValue = () => {
    let formVal = currentRowForm.getFieldValue();
    currentRowForm.setFieldsValue({...formVal, [prevState.type]: prevState[prevState.id]});
  }

  const handleOnRowSave = (reason) => {
    setIsLoading(true)
    if (prevState && (prevState.id !== undefined || prevState.id !== null || prevState.id !== false)) {
      const paylaod = {
        id: prevState.id,
        // total_capacity: prevState[id],
        // scheduled_percentage: prevState[id]
      }
      if (prevState.type == "scheduled_percentage") {
        paylaod.scheduled_percentage = prevState[prevState.id]
        paylaod.total_capacity = listingData.find((x) => x.id == prevState.id).total_capacity
      } else {
        paylaod.total_capacity = prevState[prevState.id]
        paylaod.scheduled_percentage = listingData.find((x) => x.id == prevState.id).scheduled_percentage
      }
      // console.log("paylaod.scheduled_percentage", paylaod.scheduled_percentage)
      if (paylaod.scheduled_percentage > 100) {
        openNotificationWithIcon("error", "Scheduled percentage cannot be greater than 100")
        setIsLoading(false)
        setRowId(false)
        return
      }
      if (paylaod.scheduled_percentage < 0) {
        openNotificationWithIcon("error", "Scheduled percentage cannot be less than 0")
        setIsLoading(false)
        setRowId(false)
        return
      }

      if (reason) {
        paylaod.change_reason = reason;
      }

      capacity_service.update_capacity(paylaod).subscribe((r) => {
        let temp = listingData;
        for (let row of temp) {
          if (prevState.id === row.id) {
            Object.assign(row, {
              ...r.response.data
            })
          }
        }
        // getListingData()
        setListingData([])
        setListingData([...temp])
        setIsLoading(false)
        setRowId(false)
        setModalVisibility(false)
        currentRowForm.resetFields()
        setSelectedReason(false);
      }, ((err) => {
        setIsLoading(false)
        console.log("err", err)
        let erroMsg = err.response.error ? err.response.error : err.response.errors[0] ? err.response.errors[0] : "API Failed"
        openNotificationWithIcon('error', erroMsg)
      }))
    }
  }

  const getReasonData = () => {
    capacity_service.get_reason().subscribe((res) => {
      setReasonData(res.response.data)
    }, (err) => {
      openNotificationWithIcon('error', err?.response?.errors[0])
    });
  }

  const onReasonSelect = (e) => {
    setSelectedReason(e)
  }

  const onSubmitModal = () => {
    modalRef.current.validateForm();
  }

  return (
    <div className="layer">
      <Row>
        <Card>
          <Col span={24}>  
            <DatePicker value={date} onChange={onChangeDate} allowClear={false} className="frz-w-200 frz-m-10" placeholder="Select Date" />

            <Search placeholder="Darkstore" value={valueByDarkStore}
              onChange={(e) => onChange(e, 'valueByDarkStore')} type="valueByDarkstore" />
              
            {expressStatus !== undefined ? 
              expressStatus === true ?
              (<><b> Express Status: </b>
              <span style={{ color: 'green' }}>On</span></>)
            : (<><b> Express Status: </b><
              span style={{ color: 'red' }}>Off</span></>)
            : null}
          
            <Button disabled={disable} onClick={getListingData} style={{ width: 100, margin: 10 }}
              type="primary">Search</Button>

            <Button onClick={reset} style={{ margin: 10 }} disabled={disable}
              type="primary" ghost shape="circle" icon={<ReloadOutlined />} />
            <span className="fl-right">
              <FrzDynamicDownload name={'Booked Slots'} fileName={'Booked Slots'} url={'admin_dashboard/v1/downloads/darkstore_wise_booked_slots'}
                payload={`?date=${valueByDate}`} type={'job'} reportFunc={'fraazo_report'} jobReportFunc={'download_reports_job_fraazo'}
                base={'fraazo'} />
            </span>
          </Col>
        </Card>
      </Row>
      {listingData ?
        listingData.length > 0 ?
          <div>
            <Card style={{ paddingTop: 5, marginTop: 20 }}>
              <Row>
                <Col span={24}>
                  <Table
                    components={components}
                    columns={column}
                    rowClassName={() => 'editable-row'}
                    dataSource={[...listingData]}
                    pagination={false}
                    scroll={{ x: 240 }}
                    rowKey="id"
                  />
                </Col>
              </Row>
            </Card>
            {/* <Table
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={listingData}
          columns={columns}
        /> */}
          </div>
          : ((listingData && disable) || spinEnable ?
            (spinEnable ?
              <div className="spin-center"><Spin tip="...Loading" /></div>
              :
              <div className="no-data">No Data Available</div>)
            :
            <div className="no-data">No Data Available On Selected Filters</div>)
        :
        <div className="no-data">Please Select Filters</div>}
        <Modal title="Capacity Reason" visible={isModalVisible} 
          onOk={onSubmitModal} onCancel={closeModal} okText="Submit"
          destroyOnClose={true} okButtonProps={{ disabled: !selectedReason, loading: isLoading }}>
            <ReasonModal data={reasonData} onChange={onReasonSelect} setFormValue={setFormValue} 
            selectedReason={selectedReason} handleOnRowSave={handleOnRowSave} ref={modalRef} />
        </Modal>
    </div>
  );
}


export default Capicity