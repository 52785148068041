import React, { useEffect, useState } from "react";
import { Input, Button, Spin, InputNumber, Modal, Table, Radio } from "antd";
import { ProductService } from "../../../_services/product";
import { ErrorNotification, SuccessNotification } from "./notification";
import { localNamesColumns } from "../../../components/antd/columns/master/product";

function ProductLocalNames(props) {
  const productService = new ProductService();
  const [localNames, setLocalNames] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [operations, setOperations] = useState(false)
  const [columns, setColumns] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalRecord, setModalRecord] = useState(false);
  const commonInputWidth = 200;
  const editedRecordValues = {};

  const inputStyle = {
    marginRight: 100,
    textAlign: "right",
  };

  const inputStyle2 = {
    marginRight: 100,
    textAlign: "right",
    display: 'flex'
  };

  const inputStyle3 = {
    marginRight: 50,
    textAlign: "right",
  };

  useEffect(() => {
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if(['admin','developer','super_admin'].includes(data)){
        setOperations(true)
      }
    })
    getColumns();
    getProductLocalNames();
  }, []);

  function getColumns() {
    localNamesColumns.map((data) => {
      columns.push(data);
    });

    columns.push({
      title: "Actions",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, record) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              setModalRecord(record);
              setModalVisible(true);
            }}
          >
            Edit
          </Button>
        );
      },
    });
    setColumns(columns);
  }

  function handleCancel() {
    setModalVisible(false);
  }

  function getProductLocalNames() {
    setTableLoading(true);
    productService.get_local_names(props.productId).subscribe(
      (r) => {
        setLocalNames(r.response);
      },
      (err) => {
        ErrorNotification("Failed to get details", (err = err));
      },
      () => {
        setTableLoading(false);
      }
    );
  }

  function saveProductDetails() {
    // if (editedRecordValues.regional_name === '' || editedRecordValues.regional_name === undefined) {
    //   ErrorNotification("Please fill regional name");
    //   return;
    // }
    setButtonLoading(true);
    const payload = {
      ...editedRecordValues,
      city_id: modalRecord.id,
      product_id: props.productId,
    };
    // console.log(payload);
    productService.update_local_names(payload).subscribe(
      (r) => {
        getProductLocalNames();
        SuccessNotification("Details updated successfully");
        setButtonLoading(false);
        setModalVisible(false);
      },
      (err) => {
        ErrorNotification("Failed to update details");
        setButtonLoading(false);
      }
    );
  }

  function onInputChange(key, value) {
    editedRecordValues[key] = value;
    if (value === "") {
      editedRecordValues[key] = null;
    }
  }

  const FZInput = ({ inputName, keyName, placeholder, isCaps = false, onChange, suffix }) => {
    editedRecordValues[keyName] = modalRecord[keyName];
    return (
      <div className="block" style={inputStyle}>
        {" "}
        {inputName} :
        <span style={{ paddingLeft: 10 }}>
          <Input
            placeholder={placeholder ?? inputName}
            suffix= {suffix}
            style={{
              width: commonInputWidth,
              textTransform: isCaps ? "uppercase" : "none",
            }}
            onChange={(e) =>onChange !=null ? onChange(e): onInputChange(keyName, e.target.value)}
            required={true}
            defaultValue={modalRecord[keyName] ?? undefined}
          />
        </span>
      </div>
    );
  };

  return (
    <div>
      {localNames ? (
        <div>
          <Table
            columns={columns}
            dataSource={localNames}
            pagination={false}
            scroll={{ x: 240 }}
            loading={tableLoading}
            rowKey="id"
          />
          <Modal
            title={modalRecord?.name}
            visible={modalVisible}
            onOk={saveProductDetails}
            confirmLoading={buttonLoading}
            onCancel={handleCancel}
          >
            <FZInput inputName="Regional Name" keyName="regional_name" />
          </Modal>
        </div>
      ) : (
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      )}
    </div>
  );
}

export default ProductLocalNames;
