import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input,
         Pagination, notification } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {SlotService} from '../../_services/delivery_slot';
import Search from '../../components/antd/search';
import MainTable from '../../components/antd/table';
import DeliverySlotModal from '../../components/master/delivery/slot_modal';
import {slotColumns} from '../../components/antd/columns/master/delivery_slot';

const slot_service = new SlotService()

function DeliverySlotList() {
  const modalRef = useRef(null);
  const [slotData, setSlotData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [mode, setMode] = useState(undefined)
  const [modalState, setModalState] = useState({
                            from_time: undefined, valueByfrom_time: undefined, to_time: undefined,
                            valueByto_time: undefined, valueByispickup: undefined,
                            valueByisactive: undefined, valueByisscheduled: undefined,
                            valueByissociety: undefined, currentActive: undefined});
  const { from_time, to_time, valueByispickup, valueByfrom_time,
          valueByisactive, valueByisscheduled, valueByissociety, currentActive,
          valueByto_time} = modalState

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getSlot()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getSlot(){
    setSpinEnable(true)
    setSlotData([])
    slot_service.get_delivery_slot().subscribe((r) => {
      setSpinEnable(false)
      setSlotData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    slotColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (
          <Button type="primary" onClick={() => showModal(record, 'Edit Slots')}>Edit</Button>
        )
      }
    })
    setColumns(columns)
  }

  function showModal(data, type) {
    setMode(type)
    if(type === 'Edit Slots'){
      setModalState({
        from_time: data.from_time, to_time: data.to_time,
        valueByfrom_time: data.from_time, valueByto_time: data.to_time,
        valueByispickup: data.is_pickup,
        valueByisactive: data.is_active, valueByisscheduled: data.is_scheduled,
        valueByissociety: data.is_society, currentActive: data.id})
    }
    modalRef.current.showModal(type, data)
  }

  function onChangeModal(time, timeString, type) {
    // console.log(time, timeString, type)
    if(type === 'from_time'){
      setModalState(prevState =>({...prevState, [type]: time, valueByfrom_time: parseInt(timeString)}))
    }else{
      setModalState(prevState =>({...prevState, [type]: time, valueByto_time: parseInt(timeString)}))
    }
  }

  function onChangeModalRadio(value, type) {
    setModalState(prevState =>({...prevState, [type]: value}))
  }

  function onCancelModal() {
    setModalState({from_time: undefined, to_time: undefined, valueByfrom_time: undefined,
                   valueByto_time: undefined, valueByispickup: undefined,
                   valueByisactive: undefined, valueByisscheduled: undefined,
                   valueByissociety: undefined, currentActive: undefined})
  }

  function modalSubmit() {
    if(!valueByfrom_time || !valueByto_time || valueByispickup === undefined
       || valueByisactive === undefined || valueByisscheduled ===  undefined
       || valueByissociety === undefined){
         openNotificationWithIcon('error', "Please fill all fields")
         modalRef.current.stopLoading()
    }else{
      const payload = {
        id: mode === 'Edit Slots' ? currentActive : undefined,
        body: {
          from_time: valueByfrom_time,
          to_time: valueByto_time,
          is_active: valueByisactive,
          is_pickup: valueByispickup,
          is_scheduled: valueByisscheduled,
          is_society: valueByissociety
        }
      }
      // console.log(payload)
      if(mode === 'Edit Slots'){
        slot_service.update_delivery_slot(payload).subscribe((r) => {
          openNotificationWithIcon('success','Edited Successfully')
          getSlot()
          modalRef.current.handleCancel()
        },
        (err)=>{
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors[0])
        })
      }else{
        slot_service.create_delivery_slot(payload).subscribe((r) => {
          openNotificationWithIcon('success','Created Successfully')
          getSlot()
          modalRef.current.handleCancel()
        },
        (err)=>{
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors[0])
        })
      }
    }
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  return (
    <div className="layer-nofilter">
      {slotData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Add Slot')} style={{width: 150, margin:10}}
                        type="primary">Add Delivery Slot</Button>
              </Col>
            </Card>
          </Row>
          {slotData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={slotData} columns={columns}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <DeliverySlotModal modalSubmit={modalSubmit} ref={modalRef}
                             onChangeModal={onChangeModal}
                             onCancelModal={onCancelModal}
                             onChangeModalRadio={onChangeModalRadio}
                             from_time={from_time} to_time={to_time}
                             valueByispickup={valueByispickup}
                             valueByisactive={valueByisactive}
                             valueByisscheduled={valueByisscheduled}
                             valueByissociety={valueByissociety}/>
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  );
}

export default DeliverySlotList
