import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Card} from 'antd';
import {customerRefundsColumns1, customerRefundsColumns2} from '../antd/columns/customers';
import {handleDateFormat} from '../../_modules/date';
import {getStaticTime} from '../../_modules/time';

function CustomerRefunds(props){
  const [columns, setColumns] = useState([])
  const [columns2, setColumns2] = useState([])

  useEffect(() => {
    getColumns()
  }, [])

  function getColumns(){
    let temCol = []
    customerRefundsColumns1.map(data => {
      temCol.push(data)
    })
    temCol.push({
        title: 'Created At',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return(
            <div>
              <div>{handleDateFormat(record.created_at)}</div>
              <div>{getStaticTime(record.created_at)}</div>
            </div>
          )
        }
    })
    customerRefundsColumns2.map(data => {
      columns2.push(data)
    })
    setColumns(temCol)
    setColumns2(columns2)
  }

  function expandedRow(record){
    return <Card className="nested-table">
            <Table columns={columns2} pagination={false} rowKey="id" dataSource={record.refunds}/>
           </Card>
  }

  return(
    <div>
      <Row>
        <Col span={24}>
          {props.customerDetail.length > 0 ?
            <Table columns={columns} dataSource={props.customerDetail}
                   pagination={false} scroll={{ x: 240 }} expandedRowRender={expandedRow}
                   rowKey="id"/>
          :
            <h3 style={{textAlign: 'center', paddingTop: 24}}>No Refunds Found</h3>
          }
        </Col>
      </Row>
    </div>
  )
}

export default CustomerRefunds
