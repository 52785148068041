import React, { useEffect } from 'react'
import { Button, Modal, Form } from 'antd'
import FRZInput from '../../../_controls/FRZInput';
import FRZRadioButton from '../../../_controls/FRZRadioButton';
import { formItemLayout } from '../../../_modules/controllayout';

const ModalCheckList = ({ handleCheckListModal, checklistModal, submitChecklist, rowData }) => {
  const [form] = Form.useForm();

  const onCreate = (values) => {
    submitChecklist(values)
  }

  const onCancel = () => {
    handleCheckListModal(false)
  }

  return (
    <div>
      <Modal
        visible={checklistModal}
        title="Create a new collection"
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onCreate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}>
        <Form
          form={form}
          initialValues={rowData ? { ...rowData } : null}
          {...formItemLayout}
          layout="horizontal"
          name="form">
          <FRZInput name="title" label="Title" isRequired={true} placeholder="Enter Title" />
          <FRZInput name="description" label="Description" isRequired={true} placeholder="Enter Description" />
          <FRZRadioButton name="is_active" label="Is Active" isRequired={true} />
        </Form>
      </Modal>
    </div>
  )
}

export default ModalCheckList
