import React, { useEffect, useState, useRef } from 'react'
import { Card, Row, Col, Affix, Divider, Tag } from 'antd'
import { handleDateFormat } from '../../_modules/date'
import { getStaticTime } from '../../_modules/time'

const LogCard = ({ data, activeCardId, handleActiveCard }) => {

  const [height, setHeight] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    setHeight(ref.current.clientHeight)
  })

  const humanize = (str) => {
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  return (
    <div>
      <div>
        <Row>
          <Col xl={24}>
            {/* //Header */}
            <div ref={ref} className={`${"frz-log-card frz-cursor-pointer"} ${data.event === "update" ? activeCardId === data.object.id ? "frz-log-update-card-active-border" : "" : activeCardId === data.object.id ? "frz-log-create-card-active-border" : ""}`} onClick={() => handleActiveCard(data.object.id)}>
              <div className={`${data.event === "update" ? activeCardId === data.object.id ? "frz-card-update-active" : "frz-card-update" : activeCardId === data.object.id ? "frz-card-create-active" : "frz-card-create"}`}>
                {data.event.toUpperCase()}
              </div>
              <Row>
                <Col xl={11} className={`${"frz-sepration-right"}`}>
                  {/* //body */}
                  <Row className="frz-lm-10 frz-tp-10">
                    <Col xl={8}>
                      <div className="frz-rp-12 frz-lable-key">
                        Log No:
                      </div>
                    </Col>

                    <Col xl={16}>
                      <div className="frz-rp-12 frz-wb-break-all">
                        {data.object.id}
                      </div>
                    </Col>
                  </Row>

                  <Row className="frz-lm-10 frz-tp-10">
                    <Col xl={8}>
                      <div className="frz-rp-12 frz-lable-key">
                        Who Done it:
                      </div>
                    </Col>

                    <Col xl={16}>
                      <div className="frz-rp-12 frz-wb-break-all">
                        {data.object.whodunnit}
                      </div>
                    </Col>
                  </Row>

                  <Row className="frz-lm-10 frz-tp-10">
                    <Col xl={8}>
                      <div className="frz-rp-12 frz-lable-key">
                        Created At:
                      </div>
                    </Col>

                    <Col xl={16}>
                      <div className="frz-rp-12 frz-wb-break-all">
                        <>{handleDateFormat(data.object.created_at)} {"at"} {getStaticTime(data.object.created_at)} </>
                        {/* {handleDateFormat(data.object.created_at)} */}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xl={13}>
                  <div className="frz-card-data-container">
                    {Object.entries(data.object.object_changes).map(([key, value], index) => {
                      return (
                        <div key={index} className="frz-log-card-body">
                          <Row>
                            <Col xl={5} className="frz-vt-center">
                              <div className="frz-rp-12 frz-lable-key">
                                {humanize(key)}:
                              </div>
                            </Col>

                            <Col xl={8} className="frz-vt-center">
                              <div className="custom-tag-magenta"> {value[0] === null ? "null" : value[0] === true ? "True" : value[0] === false ? "false" :
                                key.includes("created_at") || key.includes("updated_at") ?
                                  <>{handleDateFormat(value[0])} {"at"} {getStaticTime(value[0])} </>
                                  : key.includes("image_url") ? <img src={value[0]} className="frz-w-80" /> :
                                    key.includes("color") ?
                                      <div style={{
                                        backgroundColor: value[0],
                                        height: 30,
                                        width: 30,
                                        border: "#888",
                                        borderStyle: "solid",
                                        borderWidth: "1px"
                                      }} /> :
                                      value[0]}
                              </div>
                            </Col>

                            <Col xl={2} className="frz-vt-center">
                              <div className="frz-update-arrow frz-dis-flex frz-hrz-center">
                                =={'>'}
                              </div>
                            </Col>

                            <Col xl={9} className="frz-vt-center">
                              <div className="custom-tag-green">
                                {key.includes("created_at") || key.includes("updated_at") ?
                                  <>{handleDateFormat(value[1])} {"at"} {getStaticTime(value[1])} </>
                                  : value[1] === true ? "True" : value[1] === false ? "False" :
                                    key.includes("image_url") ? <img src={value[1]} className="frz-w-80" /> :
                                      key.includes("color") ?
                                        <div style={{
                                          backgroundColor: value[1],
                                          height: 30,
                                          width: 30,
                                          border: "#888",
                                          borderStyle: "solid",
                                          borderWidth: "1px"
                                        }} /> :
                                        value[1]}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      )
                    })}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div >
  )
}

export default LogCard