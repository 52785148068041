import { Http } from '../_modules/http'

export class FulfillmentService {

  http = new Http()

  darkstore_fulfillment(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin/darkstores_fulfillment?page=" + payload.page + 
                `&date=${payload.date}` + `&by_city=${localStorage.getItem('city') ?? ''}` +
                `&node_code=` + payload.darkstore_id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

  product_unfulfillment(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin/product_unfulfilled?page=" + 
    payload.page  + `&date=${payload.date}` +
    `&by_city=${localStorage.getItem('city') ?? ''}` +
    `&node_code=` + payload.darkstore_id +
    `&by_sku_name=` + payload.by_sku_name    
                 
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

}
