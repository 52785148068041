import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, notification } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {CustomerService} from '../../_services/customer';
import Search from '../../components/antd/search';
import MainTable from '../../components/antd/table';
import CustomerDetail from '../../components/customers/customer_detail';
import {customerColumns} from '../../components/antd/columns/customers';
import FrzDynamicUpload from '../../_controls/FRZDynamicUpload';

const customer_service = new CustomerService()

function CustomerList() {
  const detailRef = useRef(null);
  const [customerData, setCustomerData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [state, setState] = useState({valueByMobno: undefined, valueById: undefined});
  const { valueByMobno, valueById } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getCustomer()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getCustomer(){
    setSpinEnable(true)
    setCustomerData([])
    const payload = {
      page: current,
      valueByMobno: valueByMobno ?? '' ,
      valueById: valueById ?? ''
    }
    customer_service.get_customer_list(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setSpinEnable(false)
      setCustomerData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    customerColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: '',
      dataIndex: '',
      key: '',
      align: 'right',
      render: (record) => {
        return (
          <div>
            <Button type="primary"
                    onClick={() => showModalDetail(record, 'Customer Details')}>View Details</Button>
          </div>
        )
      }
    })
    setColumns(columns)
  }

  function showModalDetail(data, type) {
    detailRef.current.showModal(type, data)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function reset() {
    setState({
      valueByMobno: undefined,
      valueById: undefined
    })
    setDisable(true)
    setCurrent(1)
    setCustomerData([])
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setCustomerData([])
    setRenderData(false)
  }

  return(
    <div className="layer">
      {customerData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <InputNumber placeholder="Mobile No"
                             onChange={(e) => onChange(e, 'valueByMobno')}
                             value={valueByMobno} style={{width: 200, margin: 10}}/>
                <InputNumber placeholder="Customer Id"
                             onChange={(e) => onChange(e, 'valueById')}
                             value={valueById} style={{width: 200, margin: 10}}/>
                <Button disabled={disable} onClick={getCustomer} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
                <div className="frz-dis-upload dynamic-upload r-0">
                  <FrzDynamicUpload name={'Coupons'} url={'upload_spl_discount_sheet'} 
                                    uploadFunc={'upload_reports'} callBack={getCustomer}/>
                </div>
              </Col>
            </Card>
          </Row>
          {customerData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={customerData} columns={columns}/>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <CustomerDetail ref={detailRef} />

        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default CustomerList
