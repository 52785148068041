import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Table, Pagination } from 'antd';
import { ProductService } from "../../_services/product";
import MainTable from '../../components/antd/table';
import { productSlabColumns } from "../../components/antd/columns/master/product";
import ProductTaxSlabModal from '../../components/master/product_tax_slab/ProductTaxSlabModal'
import { openNotificationWithIcon } from '../../_modules/notification';
import FrzDynamicDownload from '../../_controls/FRZDynamicDownload';

const product_service = new ProductService();
const ProducTaxSlab = () => {
  const modalRef = useRef(null);
  const [listingData, setListingData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [mode, setMode] = useState(undefined)
  const [total, setTotal] = useState(false)
  const [current, setCurrent] = useState(1);
  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getListing()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getListing() {
    setSpinEnable(true)
    setListingData([])
    const payload = {
      page: current
    }
    product_service.get_product_tax_slab(payload).subscribe((r) => {
      setTotal(r.response.meta.total_pages * 50);
      setSpinEnable(false);
      setListingData(r.response.data);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  function getColumns() {
    productSlabColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: "Actions",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <Button type="primary" onClick={() => showModal(record, 'Edit')}>Edit</Button>
        );
      },
    });
    setColumns(columns)
  }

  function showModal(data, type) {
    setMode(type)
    modalRef.current.showModal(type, data)
  }

  function pagination(page) {
    setCurrent(page);
    setListingData([]);
    setRenderData(false);
  }

  function modalSubmit(payloadData, id) {
    const payload = { ...payloadData, id: id ? id : undefined }
    if (mode === 'Edit') {
      product_service.update_product_tax_slab(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Edited Successfully')
        getListing()
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors?.[0])
        })
    } else {
      product_service.add_product_tax_slab(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Created Successfully')
        getListing()
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors?.[0])
        })
    }
  }

  return (
    <div className="layer-nofilter">
      {listingData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Create Tax Slab')} className="frz-w-250p frz-m-10"
                  type="primary">Create Tax Slab</Button>
                <span className="fl-right">
                  <FrzDynamicDownload name={'Tax Slab Master'} fileName={'Tax Slab Master'} url={'admin_dashboard/v1/downloads/tax_slab_master_report'}
                    payload={'?'} type={'job'} reportFunc={'fraazo_report'} jobReportFunc={'download_reports_job_fraazo'}
                    base={'fraazo'} />
                </span>
              </Col>
            </Card>
          </Row>
          {listingData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={listingData} columns={columns} />
                <Pagination style={{ marginTop: 10, marginBottom: 10 }} current={current}
                  pageSize={50} total={total} onChange={pagination} showSizeChanger={false} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <ProductTaxSlabModal modalSubmit={modalSubmit} ref={modalRef} listingData={listingData} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default ProducTaxSlab
