import React from 'react';
import { Select } from 'antd';
import { useSelector } from "react-redux";

const { Option } = Select;

function Search(props) {
  const darkstore = useSelector(state => state.auth.darkstore);
  const { data, value, type, placeholder, disabled, width } = props
  // console.log(data);
  return (
    <span>
      {darkstore ?
        <Select
          showSearch
          value={value}
          style={type === 'valueByDarkstore' || type === 'valueByDarkstoreStats' || type === 'valueByDarkstore2'
            || type === 'valueBySubCategory' || width === 'medium' ||
            type === 'valueByDarkstoreStatsCode' ? { width: 310, padding: 10 } : { width: 200, padding: 10 }}
          placeholder={placeholder}
          optionFilterProp="children"
          disabled={disabled}
          onChange={(value) => props.onChange(value, type)}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {
            type === 'valueByDarkstore' ?
              darkstore.map(data =>
                <Option value={data.id} key={data.id}>{data.name}</Option>)
              : (type === 'valueByDarkstore2' ?
                darkstore.map(data =>
                  <Option value={data.id} key={data.id}>{data.name}</Option>)
                :
                (type === 'valueByDarkstoreCode' ?
                  darkstore.map(data =>
                    <Option value={data.code} key={data.id}>{data.name}</Option>)
                  : (type === 'valueBySociety' || type === 'valueByVirtualDs' ?
                    data.map(data =>
                      <Option value={data.id} key={data.id}>{data.name}</Option>)
                    : (type === 'valueByStatus' ?
                      data.map(data =>
                        <Option value={data.id} key={data.id}>{data.name}</Option>)
                      : (type === 'valueByCustomer' ?
                        data.map(data =>
                          <Option value={data.id} key={data.id}>{data.full_name}</Option>)
                        : (type === 'valueByOrderType' ?
                          data.map(data =>
                            <Option value={data.id} key={data.id}>{data.name}</Option>)

                          : (type === 'valueByCategory' || type === 'valueBySubCategory' ?
                            data.map(data =>
                              <Option value={data.id} key={data.id}>{data.name}</Option>)
                            : (type === 'valueByFreshvnf' ?
                              data.map((data, index) =>
                                <Option value={data.name} key={index}>{data.name}</Option>)
                              : (type === 'valueByFLName' ?
                                data.map((data, index) =>
                                  <Option value={data.id} key={data.id}>{data.first_name + ' ' + data.last_name}</Option>)
                                : (type === 'valueByDarkstoreStats' ?
                                  <><Option value='all' key='all'>All Darkstores</Option>
                                    {darkstore.map(data =>
                                      <Option value={data.id} key={data.id}>{data.name}</Option>)}</>
                                  : (type === 'valueByDSCode' ?
                                    darkstore.map((data, index) =>
                                      <Option value={data.code} key={index}>{data.name}</Option>)
                                    : (type === 'valueByDarkstoreStatsCode' ?
                                      <><Option value='all' key='all'>All Darkstores</Option>
                                        {darkstore.map(data =>
                                          <Option value={data.code} key={data.code}>{data.name}</Option>)}</>
                                      : (type === 'valueByZapperCode' ?
                                        <><Option value='all' key='all'>All Nodes</Option>
                                          {data.map((data, index) =>
                                            <Option value={data.code} key={index}>{data.name}</Option>)}</>
                                        : (type === 'valueByProductCode' ?
                                          data.map((data, index) =>
                                            <Option value={data.sku} key={index}>{data.name}</Option>)
                                          : (type === 'valueByData' ?
                                            data.map((data, index) =>
                                              <Option value={data} key={index}>{data}</Option>)
                                            : (type === 'valueByCompany' ?
                                              data.map(data =>
                                                <Option value={data.id} key={data.id}>{data.company_name}</Option>)
                                              : (type === 'valueByBrand' ?
                                                data.map(data =>
                                                  <Option value={data.id} key={data.id}>{data.brand_name}</Option>)
                                                : (type === 'valueByPaymentMode' ?
                                                  data.map(data =>
                                                    <Option value={data.filter_value} key={data.filter_value}>{data.filter_name}</Option>)
                                                  : (type === 'valueByRoles' ? data.map((data) =>
                                                    <Option value={data} key={data}>{data}</Option>)
                                                    : (type === 'valueByPnpVendors' ? data.map(data =>
                                                      <Option value={data.id} key={data.id}>{data.name}</Option>)
                                                      : type === 'valueBySeg' ? data.map((data, index) =>
                                                        <Option value={data.slug} key={index}>{data.name}</Option>)
                                                        : data.map((data, index) =>
                                                          <Option value={data.name} key={index}>{data.name}</Option>
                                                        )))))))))))))))))))))
          }
        </Select>
        : null}
    </span>
  )
}

export default Search
