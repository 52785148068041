import React, { useEffect, useState } from 'react';
import { Row, Col, Form, InputNumber, Select } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import FRZSelect from '../../../_controls/FRZSelect';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import rupee from '../../../static/images/rupee.svg';
import { tailLayout } from '../../../_modules/controllayout'

const { Option } = Select;

function ImsE(props) {

  // useEffect(() => {
  //   props.form.setFieldsValue({distance_config:[{distance: 10, rate: 4}, {distance: 45, rate: 33}]})
  // }, [])

  const [isFieldRequired, setIsFieldRequired] = useState(false);

  const onFormChange = () => {
    props.handleOnFormChange(true)
    let data = props.form.getFieldValue()
    if (data.E.period || data.E.hour_count || data.E.rate) {
      setIsFieldRequired(true)
    } else {
      props.form.validateFields()
      setIsFieldRequired(false)
    }
  }

  return (
    <div>
      <Form
        form={props.form}
        layout="horizontal"
        name="form"
        onChange={() => onFormChange()}>
        <Row>
          <Col span={12}>
            <div className='frz-ims-incentive-subtext frz-fuel-km-padding'>
              <div className='frz-tp-8'>Logged In Hours (E)</div>
            </div>
          </Col>
          <Col span={12}>
            <div className='frz-fuel-km-padding'>
              <div className='frz-dis-flex frz-justify-end frz-ims-placeholder'>
                <FRZSelect
                  optionDisabledKey="id"
                  DropDownData={props.period} name={['E', 'period']}
                  isDisabledVal={props.period.filter((x) => x.id > props.billingValue).map(a => a.id)}
                  label="" placeholder="Select Period" option="name" isShowSearch={true}
                  width={150} value="period" setOnChange={onFormChange} isRequired={isFieldRequired} valueOf={"period"} />
                <div className='frz-lp-10'></div>
                <FRZInputNumber minValue={0} name={['E', 'hour_count']}
                  label="" placeholder="hour count" width={150} isRequired={isFieldRequired} valueOf={"hour count"} />
                <div className='frz-lp-10'></div>
                <div className='frz-p-relative frz-ims-rs-custom'>
                  <div className='frz-rupee-ims'><img src={rupee} /> </div>
                  <FRZInputNumber minValue={0} name={['E', 'rate']}
                    label="" placeholder="rate" width={150} errorMsg="Please enter rate" isRequired={isFieldRequired} valueOf={"rate"} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default ImsE