import React, { useEffect, useState, useRef } from 'react';
import MainTable from '../antd/table';
import { Row, Col, Table, Button, notification, Popconfirm, Pagination} from 'antd';
import {CustomerService} from '../../_services/customer'
import DiscountModal from '../ads/discount_modal';
import {couponColumns} from '../antd/columns/customers';

const customer_service = new CustomerService()

function CustomerCoupons(props){
  const modalRef = useRef(null);
  const [columns, setColumns] = useState([])
  const [modalState, setModalState] = useState({title: undefined, description: undefined,
                                                discountValue: undefined, discountType: undefined, min_order: undefined, 
                                                max_order: undefined, valid_from_date: '', valid_from: undefined, 
                                                valid_to_date: '', valid_to: undefined,
                                                currentActive: undefined});
const {valueByCoupon, title, description, discountValue, discountType, min_order,
       max_order, valid_from_date, valid_from, valid_to_date, valid_to, 
       currentActive} = modalState

  useEffect(() => {
    getColumns()
  }, [])

  function getColumns(){
    let temCol = []
    couponColumns.map(data => {
      temCol.push(data)
    })
    temCol.push({
      title: '',
      dataIndex: '',
      key: '',
      align: 'right',
      render: (record) => {
        return (
          <Popconfirm
              title="Are you sure you want to send SMS ?"
              onConfirm={() => sendSMS(record.id)}
              okText="Yes"
              cancelText="No"
              placement="leftTop"
              disabled={!record.is_active}
            >
              <Button type="primary" disabled={!record.is_active}>
                Send SMS
              </Button>
          </Popconfirm>
        )
      }
    })
    setColumns(temCol)
  }

  function sendSMS(id){
    const payload = {
      id: id
    }
    customer_service.send_discount_sms(payload).subscribe((r) => {
      openNotificationWithIcon('success','SMS Sent Successfully')
    },
    (err)=>{
      console.log(err)
      openNotificationWithIcon('error', err.response.errors[0])
    })
  }

  function createCoupons(){
    modalRef.current.showModal('Create Customer Discount', true)
  }

  function onChangeModal(value, type) {
    if(type === 'valueByRadio' || type === 'valueByRadioFirst'){
      setModalState(prevState =>({...prevState, [type]: value}))
    }else{
      setModalState(prevState =>({...prevState, [type]: value === "" || value === null ? undefined : value}))
    }
  }

  function onChangeDate(date, dateString, type, valueDate) {
    setModalState(prevState =>({...prevState, [type]: date, [valueDate]: dateString}))
  }

  function onCancelModal() {
    setModalState({title: undefined, description: undefined,
                   discountValue: undefined, discountType: undefined, min_order: undefined, 
                   max_order: undefined, valid_from_date: '', valid_from: undefined, 
                   valid_to_date: '', valid_to: undefined,
                   currentActive: undefined})
  }

  function modalSubmit() {
    if(!title || !description || discountValue === undefined || !discountType ||
       min_order === undefined || !valid_from || !valid_to){
          openNotificationWithIcon('error', "Please fill all required fields")
          modalRef.current.stopLoading()
    }else{
        const payload = {
            customer_discount: {
                recipient_id: props.customer_id,
                title: title,
                description: description,
                discount_percentage_or_value: discountValue,
                discount_type: discountType,
                min_order_value: min_order,
                valid_from: valid_from,
                valid_to: valid_to,
                max_value: max_order === undefined ? null : max_order,
            },
        }
        customer_service.create_customer_discount(payload).subscribe((r) => {
            openNotificationWithIcon('success','Created Successfully')
            props.getCoupons(props.customer_mobile_no)
            modalRef.current.handleCancel()
        },
        (err)=>{
            console.log(err)
            openNotificationWithIcon('error', err.response.errors[0])
            modalRef.current.stopLoading()
        })    
    }
  }

  function openNotificationWithIcon(type, msg) {
    notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
 };

  return(
    <div>
      <Row>
        <Col span={24}>
            <Button type="primary" onClick={() => createCoupons()} style={{marginBottom: 20}}>
                Create Coupons
            </Button>
        </Col>
      </Row>  
      <Row>
        <Col span={24}>
          {props.coupons.length > 0 ?
          <div>
            <Table columns={columns} dataSource={props.coupons}
                   pagination={false} scroll={{ x: 240 }}
                   rowKey="id"/>
            <Pagination style={{marginTop: 10, marginBottom: 10}} current={props.current}
                        pageSize={50} total={props.total} onChange={props.pagination}
                        showSizeChanger={false}/>
          </div>
          :
            <h3 style={{textAlign: 'center', paddingTop: 24}}>No Coupons Created</h3>
          }
        </Col>
      </Row>
      <DiscountModal modalSubmit={modalSubmit} ref={modalRef}
                    onChangeModal={onChangeModal}
                    onCancelModal={onCancelModal}
                    onChangeDate={onChangeDate}
                    title={title} 
                    description={description}
                    discountValue={discountValue} 
                    discountType={discountType}
                    min_order={min_order}
                    max_order={max_order}
                    valid_from_date={valid_from_date}
                    valid_to_date={valid_to_date}
                    is_coupon={true}/>
    </div>
  )
}

export default CustomerCoupons
