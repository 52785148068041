export const opcColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
    align: 'center',
  },
  {
    title: 'OPC Sequence',
    dataIndex: 'opc_sequence',
    key: 'opc_sequence',
    align: 'center',
  }
]