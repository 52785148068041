import React, { useState, useEffect } from 'react'
import { Row, Col, Spin, Pagination, Affix } from 'antd'
import { LogsService } from '../../_services/logs'
import LogData from './logdata'
import LogDetail from './logdetails'

const logs_service = new LogsService()

const LogsView = ({ activeCardId, current, total, logDetail, logData, pagination, handleActiveCard }) => {

  return (
    logData && logDetail ?
      <div>
        <div>
          <Row gutter={[20, 20]}>
            <Col xl={13} className="frz-seperate-scroll">
              <LogData logData={logData} activeCardId={activeCardId} handleActiveCard={handleActiveCard}
                total={total} pagination={pagination} current={current} />
            </Col>

            <Col className="frz-left-border" xl={11}>
              <LogDetail logDetail={logDetail} />
            </Col>
          </Row>
        </div>
        {/* <Affix offsetBottom={70}> */}
        {/* <div className="frz-log-bottom-pagination">
          <Pagination className="frz-bg-fff" current={current}
            pageSize={50} total={total} onChange={pagination}
            showSizeChanger={false} />
        </div> */}
        {/* </Affix> */}
      </div>
      :

      <div className="spin-center">
        <Spin tip="...Loading" />
      </div>
  )
}

export default LogsView
