import React from 'react'
import { Form, InputNumber } from 'antd'
import { tailLayout } from '../_modules/controllayout'
const FRZInputNumber = ({ name, label, isRequired, placeholder, width, isDisabled, setOnChange, errorMsg, minValueZero }) => {
    const onChange = (e, type) => {
        if (setOnChange) {
            setOnChange(e, type)
        }
    }

    return (
        <Form.Item
            {...tailLayout}
            name={name}
            label={label}
            rules={[{ required: isRequired, message: errorMsg ? errorMsg : "Please enter " + label ? label : valueOf }]}
        >
            <InputNumber onChange={onChange} min={minValueZero ? 0 : null} placeholder={placeholder ? placeholder : label} style={{ width: width }}
                disabled={isDisabled} />
        </Form.Item>
    )
}

export default FRZInputNumber
