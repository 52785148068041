import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import FRZInput from '../../../_controls/FRZInput';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import { formItemLayout } from '../../../_modules/controllayout';

const ProductCessSlabModal = forwardRef((props, ref) => {
  const { modalSubmit } = props
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [id, setId] = useState(false)
  const [form] = Form.useForm();
  const [isClose, setIsClose] = useState(false)

  useEffect(() => {
    if ((data && title == "Edit") || isClose) {
      form.setFieldsValue({ ...data })
      setId(data.id)
    } else {
      setId(false)
    }
    if (isClose) {
      setIsClose(false)
    }
  }, [data, isClose])

  function handleOnSubmit(values) {
    const payload = { ...values }
    setLoading(true)
    modalSubmit(payload, id)
  }

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      setIsClose(true)
      setData(false)
      form.resetFields()
    },

    stopLoading() {
      setLoading(false)
    }

  }));


  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    setIsClose(true)
    setData(false)
    form.resetFields()
  }
  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            <FRZInput name="name" isRequired={true}
              label="Name" placeholder="Name" width={200} />

            <FRZInputNumber isTextArea={true} name="cess_percentage" isRequired={true}
              label="Cess Percentage" placeholder="Cess Percentage" width={200} />

          </Form>
        </Modal>
        : null}
    </div>
  )
})

export default ProductCessSlabModal
