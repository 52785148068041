import { Http } from '../_modules/http'

export class DistributionService {

  http = new Http()

  get_distribution(payload){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/orders/delivery_distribution/get_details?list=' 
                + payload.list +'&by_darkstore=' + payload.by_darkstore 
                + `${payload.date ? '&by_delivery_date=' + payload.date : ''}`
                + `${payload.slot ? '&by_delivery_slot=' + payload.slot : ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  update_distribution(payload){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/orders/delivery_distribution/update_details'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers)
  }

  get_distribution_slot(payload){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/orders/delivery_distribution/active_slots?darkstore_id=' 
                + payload.by_darkstore
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_packer_list(payload){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/get_packer_list?from_darkstore=' 
                + payload.by_darkstore
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  update_packer(payload){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/move_packers'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers)
  }

}
