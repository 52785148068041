import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form } from 'antd';
import FRZInput from '../../../_controls/FRZInput';
import FRZSelect from '../../../_controls/FRZSelect';
import { formItemLayout } from '../../../_modules/controllayout';

const UPIExtensionModal = forwardRef((props, ref) => {

  const { modalSubmit } = props

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [form] = Form.useForm();
  const statusData =[
    {name: "active", option: "active"},
    {name: "inactive", option: "inactive"},
    {name: "deactivated", option: "deactivated"}
  ]

  useEffect(() => {
    if (data && title == "Edit UPI Extension") {
      //console.log(props)
      form.setFieldsValue({ ...data })
    }
  }, [data])

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      setData(false)
      form.resetFields()
    },

    stopLoading() {
      setLoading(false)
    },

  }));

  function handleOnSubmit(values) {
    //console.log(data?.id)
    setLoading(true)
    modalSubmit(values, data?.id ?? null, 'UPI Extension')
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    setData(false)
    form.resetFields()
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'50%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            <FRZInput name="name" isRequired={true} label="Name" placeholder="Name" width={200} />

            <FRZSelect name = "status" isRequired={true} label="Status" placeholder="Status" width={200} DropDownData={statusData} value="option" option="name"/>

          </Form>
        </Modal>
        :
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})

export default UPIExtensionModal;
