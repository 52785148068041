import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button, Select, Input, InputNumber,
         Radio} from 'antd';
import {SocietyService} from '../../_services/society';

const society_service = new SocietyService()
const { Option } = Select;

const SocietyModal = forwardRef((props, ref) => {
  const [societyListData, setSocietyListData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      getSocietyList(value, data)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function getSocietyList(value, data){
    society_service.get_allsociety_list().subscribe((r) => {
      setVisible(true)
      setData(data)
      setTitle(value)
      setSocietyListData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function handleOk() {
    setLoading(true)
    props.modalSubmit()
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.onCancelModal();
  }

  return (
    <div>
      {data && societyListData ?
        <Modal
          visible={visible}
          title={title}
          onOk={handleOk}
          width="55%"
          onCancel={handleCancelModal}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              Submit
            </Button>,
          ]}
        >
        <div className="block" style={{marginLeft:17}}>Add Societies To Merge :
          <span style={{paddingLeft:10}}>
          <Select mode="tags" style={{ width: 400}} value={props.mergingSociety}
                  placeholder="Select Societies" onChange={props.societyMerge}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                      option.props.society.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {societyListData.map((data, index) => {
              return(
                <Option key={data.id} value={data.id} society={data.name}>
                  {data.name} - {data.pincode}
                </Option>
              )
            })}
          </Select>
          </span>
        </div>
        <div className="block">Select Society To Merge In :
          <span style={{paddingLeft:10}}>
          <Select style={{ width: 400}} value={props.mainSociety}
                  showSearch
                  optionFilterProp="children"
                  placeholder="Select Society" onChange={(e) => props.onChangeModal(e, 'mainSociety')}
                  filterOption={(input, option) =>
                      option.props.society.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {societyListData.map((data, index) => {
              return(
                <Option key={data.id} value={data.id} society={data.name}>
                  {data.name} - {data.pincode}
                </Option>
              )
            })}
          </Select>
          </span>
        </div>
        </Modal>
      : null }
    </div>
  )
})

export default SocietyModal
