import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification, Tabs, Table } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {OrderService} from '../../_services/orders';
import Search from '../../components/antd/search';
import MainTable from '../../components/antd/table';
import OfflineCustomerModal from '../../components/orders/offline_customer_modal';
import {orderColumns, customerColumns} from '../../components/antd/columns/orders';
import moment from 'moment';
import OfflineOrderModal from '../../components/orders/offline_order_sheet';
import OrderModal from '../../components/orders/modal';
 
const { TabPane } = Tabs;
const order_service = new OrderService()

function OfflineOrders(){
  const modalRef = useRef(null);
  const offlineModalRef = useRef(null)
  const detailModalRef = useRef(null)
  const [orderData, setOrderData] = useState(false)
  const [statsTotalOrders, setStatsTotalOrders] = useState('')
  const [statsTotalCustomers, setStatsTotalCustomers] = useState('')
  const [offlineCustomerData, setOfflineCustomerData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [renderCustomerData, setRenderCustomerData] = useState(false)
  const [renderCoupon, setRenderCoupon] = useState(true)
  const [orderType, setOrderType] = useState(false)
  const [updateCart, setUpdateCart] = useState(false)
  const [productCatalogue, setProductCatalogue] = useState([])
  const [addressList, setAddressList] = useState([])
  const [admins, setAdmins] = useState(false)
  const [orderStatus, setOrderStatus] = useState(false)
  const [current, setCurrent] = useState(1)
  const [currentCust, setCurrentCust] = useState(1)
  const [columns, setColumns] = useState([])
  const [columnsCustomer, setColumnsCustomer] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [spinEnableCust, setSpinEnableCust] = useState(false)
  const [disable, setDisable] = useState(true)
  const [currentCart, setCurrentCart] = useState([])
  const [currentTab, setCurrentTab] = useState(1)
  const [spinEnableCart, setSpinEnableCart] = useState(true)
  const [showFilters, setShowFilters] = useState(false)
  const [total, setTotal] = useState(0)
  const [totalCust, setTotalCust] = useState(false)
  const stateValues = {
    valueByDarkstore: undefined, valueByOrderNo: undefined,
    valueByOrderStatus: undefined, valueByCustomer: undefined,
    valueByMobno: undefined, valueByDeliveryDate: undefined,
    valueByOrderType: undefined, date: '', 
    valueByExec: localStorage.getItem('roles').includes('offline_partner') ? JSON.parse(localStorage.getItem('auth')).id : undefined }
  const [state, setState] = useState({stateValues});
  const [modalState, setModalState] = useState({modalMobileNo: '', modalOTP: '',
                                                mobileLock: false, showOTP: false, showAddressSearch: false,
                                                addressLock: false, isServiceable: true,
                                                tempAddressSearch: '', valueByRadioWhatsapp: undefined});
                                                //below new key
  const [detailModalState, setDetailModalState] = useState({lastSearch: '', customerData: false, modalFirstName: undefined,
                                                            modalLastName: undefined, modalEmail: undefined,
                                                            modalTitle: undefined, modalProfile: false, modalAddress: false,
                                                            modalFlat: undefined, modalLandmark: undefined, modalLat: undefined,
                                                            modalLng: undefined, modalPincode: undefined, 
                                                            modalAddressDetail: undefined, modalCity: undefined,
                                                            modalAddressType: undefined, modalAddressIsServiceable: undefined,
                                                            modalDate: undefined, modalSlot: undefined, slotData: false,
                                                            timeData:[], reRenderDetail: true, productDrop: undefined,
                                                            modalCoupon: undefined, modalCouponId: undefined, modalCouponType: undefined,
                                                            modalCouponManual: undefined, modalCouponIdManual: undefined, 
                                                            modalCouponTypeManual: undefined, valueByRadio: undefined, 
                                                            valueByRadioAddress: undefined, modalSpinner: false, 
                                                            freeDelivery: false
                                                            });
  const { valueByOrderNo, valueByDarkstore, valueByOrderStatus, valueByCustomer,
          valueByMobno, valueByDeliveryDate, valueByOrderType, date, valueByExec} = state
  const { modalMobileNo, modalOTP, mobileLock, showOTP, showAddressSearch, tempAddressSearch, 
          addressLock, isServiceable, valueByRadioWhatsapp } = modalState
  const { lastSearch, customerData, modalFirstName, modalLastName, modalEmail, modalTitle,
          modalProfile, modalAddress, modalFlat, modalLandmark, modalLat, modalCoupon, modalCouponId,
          modalLng, modalPincode, modalAddressDetail, modalCity, slotData, timeData, productDrop, modalCouponType,
          modalAddressType, modalAddressIsServiceable, modalDate, modalSlot, reRenderDetail, valueByRadio,
          modalCouponManual, modalCouponIdManual, modalCouponTypeManual, valueByRadioAddress,
          modalSpinner, freeDelivery } = detailModalState

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getOrders()
    }
  })

  useEffect(() => {
    if(!renderCustomerData){
      setRenderCustomerData(true)
      getCustomers()
    }
  })

  useEffect(() => {
    getColumns()
    getOrderTypes()
    getAdmins()
    getOrderStatus()
    if(localStorage.getItem('roles').includes('offline_partner')){
      setShowFilters(false)
    }else{
      setShowFilters(true)
    }
  }, [])

  function getOrders(){
    setSpinEnable(true)
    setOrderData([])
    const payload = {
      page: current,
      by_order_number: !valueByOrderNo ? '' : valueByOrderNo,
      by_darkstore: !valueByDarkstore ? '' : valueByDarkstore,
      by_status: !valueByOrderStatus ? '' : valueByOrderStatus,
      by_customer: !valueByCustomer ? '' : valueByCustomer,
      by_mobile_number: !valueByMobno ? '' : valueByMobno,
      by_delivery_date: !valueByDeliveryDate ? '' : valueByDeliveryDate,
      by_order_type: !valueByOrderType ? '' : valueByOrderType,
      by_customer_exec: !valueByExec ? '' : valueByExec,
    }
    order_service.get_offline_orders(payload).subscribe((r) => {
      setTotal(r.response.meta.total_pages * 50)
      setSpinEnable(false)
      setOrderData(r.response.data)
      setStatsTotalOrders(r.response.order_count)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getCustomers(){
    setSpinEnableCust(true)
    setRenderCustomerData([])
    const payload = {
      page: currentCust,
      by_mobile_number: !valueByMobno ? '' : valueByMobno,
      by_customer_exec: !valueByExec ? '' : valueByExec,
    }
    order_service.get_customer_listing(payload).subscribe((r) => {
      setTotalCust(r.response.meta.total_pages * 50)
      setSpinEnableCust(false)
      setOfflineCustomerData(r.response.data)
      setStatsTotalCustomers(r.response.customer_count)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    orderColumns.map(data => {
      columns.push(data)
    })
    customerColumns.map(data => {
      columnsCustomer.push(data)
    })
    columns.push({
      title: '',
      dataIndex: '',
      key: '',
      align: 'right',
      render: (record) => {
        return (
          <div>
            <div>
              {record.order_status !== 'offline_draft' ?
                <Button type="primary" onClick={() => showModalDetail(record, 'Order Details')}>
                  View Details
                </Button>
              : null}
            </div>
            {record.order_status === 'confirmed' || record.order_status === 'choosing_best' ?
              <Popconfirm
                title="Are you sure you want to cancel this order?"
                onConfirm={() => cancelOrder(record.id)}
                okText="Yes"
                cancelText="No"
                placement="leftTop"
              > 
                  <Button type="primary" ghost style={{marginTop: 10}}>
                    Cancel Order
                  </Button>
              </Popconfirm>
            : null}
          </div>
        )
      }
    })
    setColumns(columns)
    setColumnsCustomer(columnsCustomer)
  }

  function showModalDetail(data, type) {
    detailModalRef.current.showModal(type, data)
  }

  function cancelOrder(id){
    const payload = {
      id: id
    }
    order_service.cancel_order(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Order cancelled sucessfully')
      setRenderData(false)
    },
    (err)=>{
      openNotificationWithIcon('error', err.response.errors[0])
      console.log(err)
    })
  }

  function getOrderTypes() {
    order_service.get_order_types().subscribe((r) => {
      setOrderType(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getAdmins() {
    order_service.get_admins().subscribe((r) => {
      setAdmins(r.response)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getOrderStatus() {
    order_service.get_order_status().subscribe((r) => {
      setOrderStatus(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function changeTab(key) {
    setState({stateValues})
    setCurrentTab(key)
  }

  function search() {
    if(currentTab == 1){
      getOrders()
    }else{
      getCustomers()
    }
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function onChangeDate(date, dateString) {
    // console.log(date)
    setState(prevState =>({...prevState, date: date, valueByDeliveryDate: dateString}))
    setDisable(false)
    setCurrent(1)
  }

  function reset() {
    setDisable(true)
    setSpinEnableCust(true)
    setSpinEnable(true)
    setState({stateValues})
    setCurrent(1)
    setCurrentCust(1)
    setOrderData([])
    setOfflineCustomerData([])
    setRenderData(false)
    setRenderCustomerData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setOrderData([])
    setRenderData(false)
  }

  function paginationCust(page) {
    setCurrentCust(page)
    setOfflineCustomerData([])
    setRenderCustomerData(false)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  function showModalCustomer(data, type) {
    modalRef.current.showModal(type, data)
  }

  function onChangeModal(value, type) {
    if(type == 'valueByRadioWhatsapp'){
      setModalState(prevState =>({...prevState, [type]: value}))
    }else{
      setModalState(prevState =>({...prevState, [type]: value === "" || value === null ? '' : value}))
    }
  }

  function validateMobile() {
    if(valueByRadioWhatsapp === undefined){
        openNotificationWithIcon('error', 'Please select order type')
    }else{
      if(!modalMobileNo){
        openNotificationWithIcon('error', 'Please enter mobile no')
      }else{
        if(modalMobileNo.length !== 10){
          openNotificationWithIcon('error', 'Mobile no should be 10 Digit')
        }else{
          setDetailModalState(prevState =>({...prevState, modalSpinner: true}))
          order_service.check_offline_customer(modalMobileNo, valueByRadioWhatsapp).subscribe((r) => {
              let apiData = r.response.data
              setDetailModalState(prevState =>({...prevState, customerData: apiData, modalSpinner: false}))
              if(apiData.customer_address && !apiData.is_verified_valid){
                  setModalState(prevState =>({...prevState, mobileLock: true}))
                  generateOTP()
              }else if(apiData.is_verified_valid){
                  if(apiData.customer_profile){
                      setDetailModalState(prevState =>({...prevState, modalFirstName: apiData.customer_profile.first_name,
                          modalLastName: apiData.customer_profile.last_name, modalEmail: apiData.customer_profile.email,
                          modalTitle: apiData.customer_profile.title, modalProfile: true}))
                  }
                  if(apiData.customer_address){
                      if(apiData.customer_address.address_type !== 'internal_temp'){
                          getUpdateCartItem(apiData)
                          // getProductCatalogue(apiData.id)
                          setDetailModalState(prevState =>({...prevState, modalFlat: apiData.customer_address.flat_detail,
                              modalLandmark: apiData.customer_address.landmark, modalLat: apiData.customer_address.latitude,
                              modalLng: apiData.customer_address.longitude, modalPincode: apiData.customer_address.pincode, 
                              modalAddressDetail: apiData.customer_address.latlong_address, modalCity: apiData.customer_address.city,
                              modalAddressType: apiData.customer_address.address_type.toLowerCase(), 
                              modalAddressIsServiceable: apiData.customer_address.is_serviceable,
                              modalAddress: true}))
                      }else{
                        setDetailModalState(prevState =>({...prevState, modalFlat: undefined,
                          modalLandmark: undefined, modalLat: apiData.customer_address.latitude,
                          modalLng: apiData.customer_address.longitude, modalPincode: apiData.customer_address.pincode, 
                          modalAddressDetail: apiData.customer_address.latlong_address, modalCity: apiData.customer_address.city,
                          modalAddressType: undefined, 
                          modalAddressIsServiceable: apiData.customer_address.is_serviceable}))
                        setSpinEnableCart(false)
                      }
                      getAddresses(apiData.id)
                      getSlot(apiData.id)
                  }else{
                    setSpinEnableCart(false)
                  } 
                  offlineModalRef.current.showModal('Create Offline Order', true)
                  modalRef.current.handleCancel()
              }else{
                  setModalState(prevState =>({...prevState, showAddressSearch: true, mobileLock: true}))
              }
          },
          (err)=>{
              if(valueByRadioWhatsapp){
                manualVerify()
              }else{
                setModalState(prevState =>({...prevState, showAddressSearch: true, mobileLock: true}))
                setDetailModalState(prevState =>({...prevState, customerData: false, modalSpinner: false}))
                console.log(err)
              }
          })
        }
      }
    }
  }

  function manualVerify(){
    const payload = {
      mobile_number: modalMobileNo,
      is_manually_verified: true
    }
    order_service.generate_otp(payload).subscribe((r) => {
        setDetailModalState(prevState =>({...prevState, customerData: r.response.data, 
                            modalSpinner: false}))
        setSpinEnableCart(false)
        offlineModalRef.current.showModal('Create Offline Order', true)
        openNotificationWithIcon('success', 'Verified Successfully')
        modalRef.current.handleCancel()
    },
    (err)=>{
        console.log(err)
    })
  }

  function generateOTP(){
    const payload = {
        mobile_number: modalMobileNo
    }
    order_service.generate_otp(payload).subscribe((r) => {
        setModalState(prevState =>({...prevState, showOTP: true}))
        openNotificationWithIcon('success', 'OTP sent successfully')
    },
    (err)=>{
        console.log(err)
    })
  }

  function editMobile() {
      setModalState(prevState =>({...prevState, showOTP: false, mobileLock: false, addressLock: false, showAddressSearch: false}))
  }

  function searchAddress(lat, lng, address, data){
      setModalState(prevState =>({...prevState, tempAddressSearch: address, addressLock: true}))
      const payload = {
          latitude: lat,
          longitude: lng
      }
      let pincode = data[data.length - 1].types[0] === 'postal_code' ? parseInt(data[data.length - 1].long_name) : undefined
      order_service.validate_address(payload).subscribe((r) => {
        setModalState(prevState =>({...prevState, isServiceable: true}))
        setDetailModalState(prevState =>({...prevState, modalLat: lat,
          modalLng: lng, modalAddressDetail: address, modalCity: 'Mumbai',
          modalAddressIsServiceable: true, modalPincode: pincode}))
        generateOTP()
      },
      (err)=>{
        setModalState(prevState =>({...prevState, isServiceable: false}))
        console.log(err)
      })   
  }

  function searchAddressDetail(lat, lng, address, data){
    // console.log(data)
    setModalState(prevState =>({...prevState, tempAddressSearch: address}))
    const payload = {
        latitude: lat,
        longitude: lng
    }
    let pincode = data[data.length - 1].types[0] === 'postal_code' ? parseInt(data[data.length - 1].long_name) : undefined
    order_service.validate_address(payload).subscribe((r) => {
      setDetailModalState(prevState =>({...prevState, modalLat: lat,
        modalLng: lng, modalAddressDetail: address, modalCity: 'Mumbai',
        modalAddressIsServiceable: true, modalPincode: pincode}))
    },
    (err)=>{
      setDetailModalState(prevState =>({...prevState, modalLat: lat,
        modalLng: lng, modalAddressDetail: address, modalCity: 'Mumbai',
        modalFlat: undefined, modalLandmark: undefined, modalPincode: undefined, 
        modalAddressIsServiceable: false}))
      console.log(err)
    })   
}

  function editAddress() {
      setModalState(prevState =>({...prevState, showOTP: false, addressLock: false}))
  }

  function validateOTP() {
    if(!modalOTP){
      modalRef.current.stopLoading()
      openNotificationWithIcon('error', 'Please Enter OTP')
    }else{
        if(modalOTP.length !== 6){
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', 'OTP should be 6 Digit')
        }else{
            const payload = {
                mobile_number: modalMobileNo,
                otp: modalOTP
            }
            order_service.validate_otp(payload).subscribe((r) => {
                let apiData = r.response.data
                if(customerData){
                    if(customerData.customer_address){
                        getSlot(customerData.id)
                        getAddresses(customerData.id)
                        if(customerData.customer_address.address_type !== 'internal_temp'){
                            getUpdateCartItem(customerData)
                            // getProductCatalogue(customerData.id)
                            setDetailModalState(prevState =>({...prevState, modalFlat: customerData.customer_address.flat_detail,
                                modalLandmark: customerData.customer_address.landmark, modalLat: customerData.customer_address.latitude,
                                modalLng: customerData.customer_address.longitude, modalPincode: customerData.customer_address.pincode, 
                                modalAddressDetail: customerData.customer_address.latlong_address, modalCity: customerData.customer_address.city,
                                modalAddressType: customerData.customer_address.address_type, 
                                modalAddressIsServiceable: customerData.customer_address.is_serviceable,
                                modalAddress: true}))
                        }else{
                          setDetailModalState(prevState =>({...prevState, modalFlat: undefined,
                            modalLandmark: undefined, modalLat: customerData.customer_address.latitude,
                            modalLng: customerData.customer_address.longitude, modalPincode: customerData.customer_address.pincode, 
                            modalAddressDetail: customerData.customer_address.latlong_address, modalCity: customerData.customer_address.city,
                            modalAddressType: undefined, 
                            modalAddressIsServiceable: customerData.customer_address.is_serviceable}))
                          setSpinEnableCart(false)
                        }
                    }else{
                      setSpinEnableCart(false)
                    }
                }else{
                    setSpinEnableCart(false)
                    setDetailModalState(prevState =>({...prevState, modalAddressDetail: tempAddressSearch === '' ? undefined : tempAddressSearch, 
                                                                    customerData: apiData, 
                                                                    modalAddressIsServiceable: isServiceable}))
                }
                if(apiData.customer_profile){
                    setDetailModalState(prevState =>({...prevState, modalFirstName: apiData.customer_profile.first_name,
                        modalLastName: apiData.customer_profile.last_name, modalEmail: apiData.customer_profile.email,
                        modalTitle: apiData.customer_profile.title, modalProfile: true}))
                }           
                offlineModalRef.current.showModal('Create Offline Order', true)
                modalRef.current.handleCancel()
                openNotificationWithIcon('success', 'OTP verified sucessfully')
            },
            (err)=>{
                modalRef.current.stopLoading()
                openNotificationWithIcon('error', err.response.errors[0])
                console.log(err)
            })
        }
    }
  }

  function onCancelModal() {
    setModalState({
        modalMobileNo: '', modalOTP: '',
        mobileLock: false, showOTP: false, showAddressSearch: false,
        addressLock: false, isServiceable: true,
        tempAddressSearch: '', valueByRadioWhatsapp: undefined})
    setDetailModalState(prevState =>({...prevState, modalSpinner: false}))
  }

  //function
  function onChangeModalOrder(value, type) {
    if(type === 'valueByRadio' || type === 'modalAddressType'){
      setDetailModalState(prevState =>({...prevState, [type]: value}))
    }else{
      setDetailModalState(prevState =>({...prevState, [type]: value === "" || value == null ? undefined : value}))
    }
    if(type === 'modalDate'){
      setDetailModalState(prevState =>({...prevState, timeData: [], reRenderDetail: false}))
    }
  }

  useEffect(() => {
    if(!reRenderDetail){
      setDetailModalState(prevState =>({...prevState, reRenderDetail: true}))
      for(var i=0; i<slotData.length; i++){
        if(slotData[i].date === modalDate){
          setDetailModalState(prevState =>({...prevState, timeData: slotData[i].time_slot, modalSlot: undefined}))
          break
        }
      }
    }
  })

  function onCancelModalOrder() {
    setDetailModalState({
      lastSearch: '', customerData: false, modalFirstName: undefined,
      modalLastName: undefined, modalEmail: undefined,
      modalTitle: undefined, modalProfile: false, modalAddress: false,
      modalFlat: undefined, modalLandmark: undefined, modalLat: undefined,
      modalLng: undefined, modalPincode: undefined, 
      modalAddressDetail: undefined, modalCity: undefined,
      modalAddressType: undefined, modalAddressIsServiceable: undefined,
      modalDate: undefined, modalSlot: undefined, slotData: false,
      timeData:[], reRenderDetail: true, productDrop: undefined,
      modalCoupon: undefined, modalCouponId: undefined, modalCouponType: undefined,
      modalCouponManual: undefined, modalCouponIdManual: undefined, modalSpinner: false,
      modalCouponTypeManual: undefined, valueByRadio: undefined, valueByRadioAddress: undefined, 
      freeDelivery: false})
      setUpdateCart(false)
      setProductCatalogue([])
      setCurrentCart([])
      setAddressList([])
      setSpinEnableCart(true)
      setRenderData(false)
      setRenderCustomerData(false)
  }

  function editOrder(type) {
      if(type === 'profile'){
        setDetailModalState(prevState =>({...prevState, modalProfile: false}))
      }
      if(type === 'address'){
        setDetailModalState(prevState =>({...prevState, modalAddress: false}))
      }
  }

  function saveOrder(type) {
    setDetailModalState(prevState =>({...prevState, modalSpinner: true}))
    if(type === 'profile'){
      if(!modalFirstName || !modalLastName || modalTitle === undefined){
         openNotificationWithIcon('error', 'Please fill all profile details')
         setDetailModalState(prevState =>({...prevState, modalSpinner: false}))
      }else{
         updateProfile()
      }
    }
    if(type === 'address'){
      if(!modalFlat || !modalLandmark || modalPincode === undefined || !modalAddressDetail || !modalAddressIsServiceable || !modalAddressType){
        openNotificationWithIcon('error', 'Please fill all address details & location should be serviceable')
        setDetailModalState(prevState =>({...prevState, modalSpinner: false}))
      }else{
        updateAddress()
      }
    }
  }

  function cancelEdit(type) {
    if(type === 'profile'){
        setDetailModalState(prevState =>({...prevState, modalProfile: true}))
    }
  }

  function updateProfile() {
    const payload = {
        id: customerData.id,
        title: modalTitle,
        first_name: modalFirstName,
        last_name: modalLastName,
        email: !modalEmail ? '' : modalEmail
    }
    if(customerData.customer_profile){
        order_service.update_profile(payload, customerData.customer_profile.id).subscribe((r) => {
            setDetailModalState(prevState =>({...prevState, modalProfile: true, modalSpinner: false}))
            openNotificationWithIcon('success', 'Profile updated successfully')
        },
        (err)=>{
            setDetailModalState(prevState =>({...prevState, modalSpinner: false}))
            openNotificationWithIcon('error', 'Profile update failed')
            console.log(err)
        }) 
    }else{
        order_service.create_profile(payload).subscribe((r) => {
            setDetailModalState(prevState =>({...prevState, modalProfile: true, customerData: r.response.data, modalSpinner: false}))
            openNotificationWithIcon('success', 'Profile created successfully')
        },
        (err)=>{
            setDetailModalState(prevState =>({...prevState, modalSpinner: false}))
            openNotificationWithIcon('error', 'Profile creation failed')
            console.log(err)
        }) 
    }  
  }

  function getAddresses(id){
    order_service.customer_address(id).subscribe((r) => {
      r.response.data.map(val => {
        if(val.is_active){
          setDetailModalState(prevState =>({...prevState, valueByRadioAddress: val.id}))
        }
      })
      setAddressList(r.response.data)
    },
    (err)=>{
        console.log(err)
    })
  }

  function createNew(){
    setDetailModalState(prevState =>({...prevState, modalFlat: undefined,
      modalLandmark: undefined, modalLat: undefined,
      modalLng: undefined, modalPincode: undefined, 
      modalAddressDetail: undefined, modalCity: undefined,
      modalAddressType: undefined, 
      modalAddressIsServiceable: undefined,
      modalAddress: false, valueByRadioAddress: undefined}))
  }

  function onChangeModalAddress(value, type){
    setDetailModalState(prevState =>({...prevState, [type]: value}))
    const payload = {
      id: customerData.id,
    }
    addressList.map(data => {
      if(data.id == value){
        payload.customer_address = {
          flat_detail: data.flat_detail,
          landmark: data.landmark,
          latitude: data.latitude,
          longitude: data.longitude,
          address_type: data.address_type,
          latlong_address: data.latlong_address,
          city: data.city,
          pincode: data.pincode,
          is_active: true
        }
      }
    })
    updateAddressApi(payload, value)
  }

  function updateAddress(){
    const payload = {
        id: customerData.id,
        customer_address: {
            flat_detail: modalFlat,
            landmark: modalLandmark,
            latitude: modalLat,
            longitude: modalLng,
            address_type: modalAddressType.toLowerCase(),
            latlong_address: modalAddressDetail,
            city: modalCity,
            pincode: modalPincode,
            is_active: true
        }
    }
    order_service.customer_address(customerData.id).subscribe((r) => {
      for(var i=0; i<r.response.data.length; i++){
        if(r.response.data[i].address_type.toLowerCase() === modalAddressType.toLowerCase()){
          var customerAddressId = r.response.data[i].id
          updateAddressApi(payload, customerAddressId)
          return
        }
      }
      order_service.create_address(payload).subscribe((r) => {
        setDetailModalState(prevState =>({...prevState, modalAddress: true, customerData: r.response.data, modalSpinner: false}))
        setModalState(prevState =>({...prevState, tempAddressSearch: ''}))
        openNotificationWithIcon('success', 'Address created successfully')
        getSlot(customerData.id)
        getAddresses(customerData.id)
        // getProductCatalogue(customerData.id)
        getUpdateCartItem(r.response.data)
      },
      (err)=>{
          setDetailModalState(prevState =>({...prevState, modalSpinner: false}))
          openNotificationWithIcon('error', 'Address creation failed')
          console.log(err)
      })
    },
    (err)=>{
        console.log(err)
    }) 
  }

  function updateAddressApi(payload, customerAddressId){
    order_service.update_address(payload, customerAddressId).subscribe((r) => {
      customerData.customer_address = r.response.data
      setDetailModalState(prevState =>({...prevState, modalFlat: customerData.customer_address.flat_detail,
        modalLandmark: customerData.customer_address.landmark, modalLat: customerData.customer_address.latitude,
        modalLng: customerData.customer_address.longitude, modalPincode: customerData.customer_address.pincode, 
        modalAddressDetail: customerData.customer_address.latlong_address, modalCity: customerData.customer_address.city,
        modalAddressType: customerData.customer_address.address_type.toLowerCase(), modalAddress: true, customerData,
        modalAddressIsServiceable: customerData.customer_address.is_serviceable, modalSpinner: false}))
      setModalState(prevState =>({...prevState, tempAddressSearch: ''}))
      openNotificationWithIcon('success', 'Address updated successfully')
      getSlot(customerData.id)
      getAddresses(customerData.id)
      // getProductCatalogue(customerData.id)
      let data = {
        id: customerData.id,
        customer_address: {
          id: customerAddressId
        }
      }
      getUpdateCartItem(data)
    },
    (err)=>{
        setDetailModalState(prevState =>({...prevState, modalSpinner: false}))
        openNotificationWithIcon('error', 'Address update failed')
        console.log(err)
    }) 
  }

  function getSlot(id){
    order_service.delivery_slots(id).subscribe((r) => {
      setDetailModalState(prevState =>({...prevState, modalDate: undefined, modalSlot: undefined, slotData: r.response.data, timeData: []}))
    },
    (err)=>{
        console.log(err)
    })
  }

  // function getProductCatalogue(id){
  //   order_service.get_product_catalogue(id).subscribe((r) => {
  //     let product = r.response.data
  //     let productData = []
  //     for(var i=0; i<product.length; i++){
  //       for(var j=0; j<product[i].sub_categories.length; j++){
  //         for(var k=0; k<product[i].sub_categories[j].products.length; k++){
  //           productData.push(product[i].sub_categories[j].products[k])
  //         }
  //       }
  //     }
  //     setProductCatalogue(productData)
  //   },
  //   (err)=>{
  //       console.log(err)
  //   })
  // }

  function getUpdateCartItem(data){
    setDetailModalState(prevState =>({...prevState, valueByRadio: undefined}))
    const payload = {
      id: data.id,
      checkout_details: {
        customer_address_id: data.customer_address.id
      },
      client: 'fraazo_admin',
    }
    if(modalCoupon){
      payload.checkout_details.discount_details = {
        coupon_code: modalCoupon,
        discount_id: modalCouponId,
        discount_type: modalCouponType,
      }
    }
    if(freeDelivery) {
      payload.checkout_details.free_delivery = freeDelivery
    }
    order_service.update_cart_item(payload).subscribe((r) => {
      setSpinEnableCart(false)
      setUpdateCart(r.response.data)
      if(r.response.data.cart_items){
        let newCart = []
        r.response.data.cart_items.map(data => {
          newCart.push({id: data.product_detail.id, qty: data.qty})
        })
        setCurrentCart(newCart)
        if(r.response.data.discount_amount === 0 && modalCoupon && r.response.data.discount_details == null){
          applyCoupon('remove')
        }
        if(r.response.data.discount_amount > 0){
          if(r.response.data.discount_details.auto_applied){
            setDetailModalState(prevState =>({...prevState, modalCouponManual: r.response.data.discount_details.coupon_code, 
                                                            modalCouponIdManual: r.response.data.discount_details.discount_id, 
                                                            modalCouponTypeManual: r.response.data.discount_details.discount_type}))
          }else{
            setDetailModalState(prevState =>({...prevState, 
              modalCouponManual: undefined, 
              modalCouponIdManual: undefined, 
              modalCouponTypeManual: undefined}))
          }
        }else{
          setDetailModalState(prevState =>({...prevState, 
            modalCouponManual: undefined, 
            modalCouponIdManual: undefined, 
            modalCouponTypeManual: undefined}))
        }
      }else{
        if(modalCoupon){
          applyCoupon('remove')
        }
        setCurrentCart([])
        setDetailModalState(prevState =>({...prevState, 
          modalCouponManual: undefined, 
          modalCouponIdManual: undefined, 
          modalCouponTypeManual: undefined}))
      }
    },
    (err)=>{
      setSpinEnableCart(false)
      if(err.response.errors[0] === 'No item in cart'){
        setUpdateCart({})
        if(modalCoupon){
          applyCoupon('remove')
        }
        setCurrentCart([])
        setDetailModalState(prevState =>({...prevState, 
          modalCouponManual: undefined, 
          modalCouponIdManual: undefined, 
          modalCouponTypeManual: undefined}))
      }
      console.log(err)
    })
  }

  function addCart(type, id){
    if(type === 'firstAdd'){
      if(!productDrop){
        openNotificationWithIcon('error', 'Please select a product')
      }else{
        const payload = {
          id: customerData.id,
          active_cart: {
            client: 'fraazo_admin',
            active_cart_item: {
              product_id: productDrop,
              qty: currentCart.find(x => x.id === productDrop) ? currentCart.find(x => x.id === productDrop).qty + 1 : 1
            }
          }
        }
        updateCartAPI(payload)
      }
    }else{
      const payload = {
        id: customerData.id,
        active_cart: {
          client: 'fraazo_admin',
          active_cart_item: {
            product_id: id,
            qty: currentCart.find(x => x.id === id) ?
                 type === 'min' ?
                 currentCart.find(x => x.id === id).qty - 1
                 :
                 currentCart.find(x => x.id === id).qty + 1
                 :
                 1
          }
        }
      }
      updateCartAPI(payload)
    }
  }

  function updateCartAPI(payload) {
    order_service.update_cart(payload).subscribe((r) => {
      getUpdateCartItem(customerData)
    },
    (err)=>{
        console.log(err)
    })
  }

  function applyCoupon(type){
    if(type === 'apply'){
      if(!modalCoupon){
        openNotificationWithIcon('error', 'Please add a coupon')
      }else{
        const payload = {
          id: updateCart.id,
          coupon_code: modalCoupon.toUpperCase()
        }
        order_service.apply_coupon(payload).subscribe((r) => {
          openNotificationWithIcon('success', 'Coupon Applied')
          setDetailModalState(prevState =>({...prevState, modalCoupon: r.response.data.discount_code, 
                                                          modalCouponId: r.response.data.discount_id, 
                                                          modalCouponType: r.response.data.discount_type}))
          setRenderCoupon(false)
        },
        (err)=>{
            openNotificationWithIcon('error', err.response.errors[0])
            console.log(err)
        })
      }
    }else{
      setDetailModalState(prevState =>({...prevState, modalCoupon: undefined, 
                                                      modalCouponId: undefined, 
                                                      modalCouponType: undefined}))
      setRenderCoupon(false)
    }
  }

  useEffect(() => {
    if(!renderCoupon){
      setRenderCoupon(true)
      getUpdateCartItem(customerData)
    }
  })

  useEffect(() => {
    if (customerData) {
      getUpdateCartItem(customerData)
    }
  }, [freeDelivery])

  function createOrder(){
    if(customerData.customer_profile && modalProfile){
      if(customerData.customer_address && modalAddress){
        if(updateCart.cart_items){
          if(modalSlot){
            if(valueByRadio){
               const payload = {
                order_id: updateCart.id,
                deliver_now: false,
                client: 'fraazo_admin',
                delivery_slot_id: modalSlot,
                delivery_date: modalDate,
                mode_of_payment: valueByRadio,
                customer_address_id: customerData.customer_address.id,
                order_type_id: 2
               }
               if(modalCoupon){
                let discountData = {
                    discount_id: modalCouponId,
                    coupon_code: modalCoupon,
                    discount_type: modalCouponType,
                 }
                 payload.discount_details = discountData
               }
               if(modalCouponManual !== undefined){
                 let discountData = {
                   discount_id: modalCouponIdManual,
                   coupon_code: modalCouponManual,
                   discount_type: modalCouponTypeManual,
                }
                payload.discount_details = discountData
               }
               if(freeDelivery) {
                 payload.free_delivery = freeDelivery
               }
               order_service.create_order(payload).subscribe((r) => {
                  openNotificationWithIcon('success', 'Order Created')
                  setRenderData(false)
                  offlineModalRef.current.handleCancel()
              },
              (err)=>{
                  offlineModalRef.current.stopLoading()
                  openNotificationWithIcon('error', err.response.errors[0])
                  console.log(err)
              })
            }else{
              offlineModalRef.current.stopLoading()
              openNotificationWithIcon('error', 'Please select a payment mode')
            }
          }else{
            offlineModalRef.current.stopLoading()
            openNotificationWithIcon('error', 'Please select delivery date & slot')
          }
        }else{
          offlineModalRef.current.stopLoading()
          openNotificationWithIcon('error', 'Please add products')
        }
      }else{
        offlineModalRef.current.stopLoading()
        openNotificationWithIcon('error', 'Please add & save customer address')
      }
    }else{
      offlineModalRef.current.stopLoading()
      openNotificationWithIcon('error', 'Please add & save customer profile')
    }
  }

  return(
    <div className="layer">
      {orderData && orderStatus && orderType && admins && offlineCustomerData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                {showFilters ? 
                  <Search placeholder="Darkstore Name" value={valueByDarkstore}
                          onChange={onChange} type="valueByDarkstore" disabled={currentTab == 2}/>
                : null}
                <Search placeholder="Order Status" value={valueByOrderStatus}
                        onChange={onChange} type="valueByOrderStatus"
                        data={orderStatus} disabled={currentTab == 2}/>
                {showFilters ?
                  <>
                    <Search placeholder="Executive Name" value={valueByExec}
                            onChange={(e) => onChange(e, 'valueByExec')} type="valueByFLName"
                            data={admins}/>
                    <Search placeholder="Order Type" value={valueByOrderType}
                            onChange={onChange} type="valueByOrderType"
                            data={orderType} disabled={currentTab == 2}/>
                  </>
                : null}
                <DatePicker value={date} onChange={onChangeDate} placeholder="Delivery Date"
                            style={{width: 200, marginLeft: 10, marginRight: 10}} disabled={currentTab == 2}/>
                <InputNumber placeholder="Order No" disabled={currentTab == 2}
                             onChange={(e) => onChange(e, 'valueByOrderNo')}
                             value={valueByOrderNo} style={{width: 200, margin: 10}}/>
                <InputNumber placeholder="Mobile No"
                             onChange={(e) => onChange(e, 'valueByMobno')}
                             value={valueByMobno} style={{width: 200, margin: 10}}/>
                <Button disabled={disable} onClick={search} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModalCustomer(true, 'Offline Order')} style={{width: 220, margin:10}}
                        type="primary">Create / Edit Offline Order</Button>
                <span style={{padding: 15}}><b>Total Orders : </b>{statsTotalOrders}</span>
                <span style={{padding: 15}}><b>Total Customers : </b>{statsTotalCustomers}</span>
              </Col>
            </Card>
          </Row>
            <Row>
              <Col span={24} className="offline">
                <Tabs defaultActiveKey="1" onChange={changeTab}>
                  <TabPane tab="Orders" key="1">
                    {orderData.length > 0 ?
                      <Row>
                        <Col span={24}>
                          <Card>
                            <Table
                              columns={columns}
                              dataSource={orderData}
                              pagination={false}
                              scroll={{ x: 240 }}
                              rowKey="id"
                            />
                          </Card>
                          <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={total} onChange={pagination}
                            showSizeChanger={false}/>
                        </Col>
                      </Row>
                    : (disable || spinEnable ?
                        (spinEnable ?
                          <div className="spin-center"><Spin tip="...Loading" /></div>
                          :
                          <div className="no-data">No Data Available</div>)
                        :
                        <div className="no-data">No Data Available On Selected Filters</div>)
                    }
                  </TabPane>
                  <TabPane tab="Customers" key="2">
                    {offlineCustomerData.length > 0 ?
                      <Row>
                        <Col span={24}>
                          <Card>
                            <Table
                              columns={columnsCustomer}
                              dataSource={offlineCustomerData}
                              pagination={false}
                              scroll={{ x: 240 }}
                              rowKey="id"
                            />
                          </Card>
                          <Pagination style={{marginTop: 10, marginBottom: 10}} current={currentCust}
                            pageSize={50} total={totalCust} onChange={paginationCust}
                            showSizeChanger={false}/>
                        </Col>
                      </Row>
                    : (disable || spinEnableCust ?
                        (spinEnableCust ?
                          <div className="spin-center"><Spin tip="...Loading" /></div>
                          :
                          <div className="no-data">No Data Available</div>)
                        :
                        <div className="no-data">No Data Available On Selected Filters</div>)
                    }
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
            <OrderModal ref={detailModalRef} refundReason={false} operations={false}/>
            <OfflineCustomerModal ref={modalRef}
                                  onCancelModal={onCancelModal}
                                  onChangeModal={onChangeModal}
                                  validateMobile={validateMobile}
                                  validateOTP={validateOTP}
                                  editMobile={editMobile}
                                  searchAddress={searchAddress}
                                  editAddress={editAddress}
                                  generateOTP={generateOTP}
                                  modalSpinner={modalSpinner}
                                  modalMobileNo={modalMobileNo}
                                  modalOTP={modalOTP} showOTP={showOTP}
                                  showAddressSearch={showAddressSearch}
                                  mobileLock={mobileLock}
                                  isServiceable={isServiceable}
                                  tempAddressSearch={tempAddressSearch}
                                  valueByRadioWhatsapp={valueByRadioWhatsapp}
                                  addressLock={addressLock}/>
            <OfflineOrderModal ref={offlineModalRef}
                              onCancelModal={onCancelModalOrder}
                              onChangeModal={onChangeModalOrder}
                              onChangeModalAddress={onChangeModalAddress}
                              modalSubmit={createOrder}
                              editOrder={editOrder}
                              cancelEdit={cancelEdit}
                              saveOrder={saveOrder}
                              searchAddress={searchAddressDetail}
                              applyCoupon={applyCoupon}
                              isServiceable={modalAddressIsServiceable}
                              tempAddressSearch={tempAddressSearch}
                              customerData={customerData} slotData={slotData}
                              modalFirstName={modalFirstName} timeData={timeData}
                              modalLastName={modalLastName}
                              modalEmail={modalEmail}
                              modalProfile={modalProfile}
                              modalTitle={modalTitle} addressList={addressList}
                              modalAddress={modalAddress} spinEnableCart={spinEnableCart}
                              modalFlat={modalFlat} modalCoupon={modalCoupon}
                              modalLandmark={modalLandmark}
                              modalLat={modalLat} productDrop={productDrop}
                              modalLng={modalLng} modalSlot={modalSlot}
                              modalPincode={modalPincode} modalCouponType={modalCouponType}
                              modalAddressDetail={modalAddressDetail} 
                              modalCity={modalCity} modalDate={modalDate}
                              createNew={createNew} modalSpinner={modalSpinner}
                              modalAddressType={modalAddressType} addCart={addCart}
                              modalAddressIsServiceable={modalAddressIsServiceable}
                              currentCart={currentCart}
                              updateCart={updateCart} productCatalogue={productCatalogue}
                              valueByRadio={valueByRadio} valueByRadioAddress={valueByRadioAddress}/>
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default OfflineOrders
