import React, {
  forwardRef,
  useState,
  useEffect,
  useImperativeHandle,
} from "react";
import {
  Modal,
  Button,
  Select,
  Input,
  Form,
  Space,
  InputNumber,
  Row,
  Col,
  Radio,
  Upload,
  notification,
  Divider,
  message
} from "antd";
import { PlusCircleOutlined, DeleteOutlined, UploadOutlined, MinusCircleOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { ErrorNotification } from "./notification";
import FRZColorPicker from "../../../_controls/FRZColorPicker";
import { ProductService } from "../../../_services/product";
import FRZUpload from "../../../_controls/FRZUpload";

const productService = new ProductService();

const { Option } = Select;

const inputStyle = {
  textAlign: "right",
  marginRight: 10,
};

let tempList = [];

const ProductAddFormModal = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [fileName, setFileName] = useState("")
  const [title, setTitle] = useState(false);
  const [data, setData] = useState(false);
  const [dynamicBrandList, setDynamicBrandList] = useState(false)
  const [count, setCount] = useState([]);
  const [skuList, setSkuList] = useState([]);
  const [state, setState] = useState({});
  const [renderData, setRenderData] = useState(false);
  const [resetState, setResetState] = useState(false);
  const [pickerBGColor, setPickerBGColor] = useState("#36c")
  const [selectedVnfProduct, setSelectedVnfProduct] = useState(false);
  const [imageState, setImageState] = useState({})
  const [imageList, setImageList] = useState(false)
  const [multipleImagePayload, setMultipleImagePayload] = useState(false)
  const [uomVal, setUomVal] = useState(false)

  const [categoryId, setCategoryId] = useState(undefined)

  const [form] = Form.useForm();

  const [radioValues, setRadioValues] = useState({
    is_active: undefined,
    is_master: undefined,
    is_special_discount: undefined,
  });
  const requiredValues = {};
  const commonInputWidth = 180;

  const uomList = [
    "kg",
    "pcs",
    "unit",
    "bundle",
    "packet",
    "dozen",
    "grams",
    "box",
    "litre",
    "ml"
  ];

  const uomListUnitQty = [
    "pcs",
    "unit",
    "packet",
    "dozen",
    "box"
  ];

  const unitList = [
    "kg",
    "pcs",
    "packets",
    "crates",
    "unit"
  ];

  useImperativeHandle(ref, () => ({
    showModal(value, data) {
      setVisible(true);
      setData(data);
      setTitle(value);
      setRadioValues({
        is_active: undefined,
        is_master: undefined,
        is_special_discount: undefined,
      });
      setSelectedVnfProduct(false);
    },

    handleCancel() {
      setVisible(false);
      form.resetFields()
      setImageList([])
      tempList = []
      setLoading(false);
      setResetState(true);
      setDynamicBrandList(false);
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false);
    },
  }));

  function getImageDimensions(file, detail) {
    return new Promise(function (resolved, rejected) {
      var img = new Image()
      img.onload = function () {
        resolved({
          w: img.width, h: img.height, n: detail.file.name
        })
      };
      img.src = file
    })
  }

  const checkImageDimension = (w, h, n) => {
    let isAccepted = false;
    if (w <= 512 && h <= 512) {
      if (h === w) {
        isAccepted = true
      } else {
        isAccepted = false
        ErrorNotification(`Uploaded image ${n ? n : null} dimension ${w} x ${h} is not equal,
           please upload equal dimension's image.`)

      }
      // upload logic here
    } else {
      isAccepted = false
      ErrorNotification(`Uploaded image dimension ${w} x ${h} is too large,
         please upload standard 512 x 512 dimension image.`)
    }
    return isAccepted;
  }

  const imageVerification = async (fileData) => {
    for (let i = 0; i <= fileData.length - 1; i++) {
      var dimensions = await getImageDimensions(fileData[i].image, fileData[i].fileDetail)
      let isValidate = checkImageDimension(dimensions.w, dimensions.h, dimensions.n)
      if (!isValidate) {
        return false
      }
    }
  }

  const handleOk = async (imageListPayload) => {
    let imgValid = await imageVerification(imageListPayload)
    if (!imgValid && imgValid !== undefined) {
      return
    }
    if (!imageListPayload.length) {
      ErrorNotification("Please upload at least one image");
      return;
    }
    // console.log(props.product);
    if (!isInputsValid()) {
      ErrorNotification("Please fill all required* fields");
      return;
    }
    if (props.product["local_names"] != undefined) {
      props.product["local_names"] = props.product["local_names"].split(",");
    }
    setLoading(true);
    if (title === "Add Combo Product") {
      let skuList = [];
      if (count.length < 1) {
        openNotificationWithIcon("error", "Please Add Minimum 1 SKUs");
        setLoading(false);
        return;
      }
      for (var i = 0; i < count.length; i++) {
        if (state["sku" + count[i]] === undefined) {
          openNotificationWithIcon("error", "Please fill all sku fields");
          setLoading(false);
          return;
        } else if (state["qty" + count[i]] === undefined) {
          openNotificationWithIcon("error", "Please fill all qty fields");
          setLoading(false);
          return;
        } else {
          skuList.push({
            sub_product_id: state["sku" + count[i]],
            pack_qty: state["qty" + count[i]],
          });
        }
      }
      let finalPayload = { ...props.product, images_attributes: imageListPayload }
      props.modalSubmit(finalPayload, skuList);
    } else {
      if(!uomListUnitQty.includes(uomVal)){
        delete props.product.unit_qty_in_pack
      }
      let finalPayload = { ...props.product, images_attributes: imageListPayload }
      // delete props.product.company_name
      props.modalSubmit(finalPayload);
    }
  }

  function isInputsValid() {
    let isValidated = true;
    Object.keys(requiredValues).forEach((key) => {
      let value = requiredValues[key];
      if (value.includes("*") && props.product[key] == undefined) {
        console.log("Invalid Value", value);
        isValidated = false;
      }
    });
    return isValidated;
  }

  function onInputChange(key, value) {
    props.product[key] = value;
    if (value === "") {
      props.product[key] = undefined;
    }
  }

  function handleCancelModal() {
    setVisible(false);
    setLoading(false);
    form.resetFields()
    setImageList([])
    tempList = []
    setResetState(true);
    setDynamicBrandList(false);
    setCategoryId(undefined)
    props.onCancelModal();
  }

  const FZInput = ({
    inputName,
    keyName,
    placeholder,
    isCaps = false,
    disabled = false,
    maxLength = undefined
  }) => {
    requiredValues[keyName] = inputName;
    return (
      <div className="block" style={inputStyle}>
        {" "}
        {inputName} :
        <span style={{ paddingLeft: 10 }}>
          <Input
            placeholder={placeholder ?? inputName}
            style={{
              width: commonInputWidth,
              textTransform: isCaps ? "uppercase" : "none",
            }}
            onChange={(e) => onInputChange(keyName, e.target.value)}
            required={true}
            disabled={disabled}
            defaultValue={props.product[keyName] ?? undefined}
            maxLength={maxLength}
          />
        </span>
      </div>
    );
  };
  const FZInputText = ({ inputName, keyName }) => {
    return (
      <div className="block" style={inputStyle}>
        <span style={{ verticalAlign: "top" }}>{inputName} :</span>
        <span style={{ paddingLeft: 10 }}>
          <Input.TextArea
            placeholder={inputName}
            style={{ width: 250 }}
            onChange={(e) => onInputChange(keyName, e.target.value)}
            rows={4}
            cols={10}
            defaultValue={props.product[keyName] ?? undefined}
          />
        </span>
      </div>
    );
  };

  const FZInputNumber = ({ inputName, keyName, placeholder }) => {
    requiredValues[keyName] = inputName;
    return (
      <div className="block" style={inputStyle}>
        {" "}
        {inputName} :
        <span style={{ paddingLeft: 10 }}>
          <InputNumber
            placeholder={placeholder ?? inputName}
            style={{ width: commonInputWidth }}
            min={0}
            onChange={(v) => onInputChange(keyName, v)}
            defaultValue={props.product[keyName] ?? undefined}
          />
        </span>
      </div>
    );
  };
  const FZInputSelector = ({ inputName, keyName, data, hasId = true, disabled, isFilter, customWidth }) => {
    let tempData = data
    if (isFilter) {
      tempData = tempData.filter((e) => e.parent_id === isFilter)
    }
    requiredValues[keyName] = inputName;
    return (
      <div className="block" style={inputStyle}>
        {inputName} :
        <span style={{ paddingLeft: 10 }}>
          <Select
            disabled={disabled}
            style={{ width: customWidth ? customWidth : commonInputWidth, textAlign: "left" }}
            value={props.product[keyName]}
            placeholder={inputName}
            showSearch={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(v) => {props.product[keyName] = v; keyName === 'uom' && setUomVal(v)}}
          >
            {tempData.map((item) =>
              hasId ? (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ) : (
                <Option key={item} value={item}>
                  {item}
                </Option>
              )
            )}
          </Select>
        </span>
      </div>
    );
  };

  function changePickerColor(colors) {
    setPickerBGColor(colors)
    onInputChange('background_color', colors)
  }

  const FZBrandSelector = () => {
    requiredValues["product_brand_id"] = "Brand Name*";
    return (dynamicBrandList ?
      <div className="block" style={inputStyle}>
        Brand Name* :
        <span style={{ paddingLeft: 10 }}>
          <Select
            style={{ width: commonInputWidth, textAlign: "left" }}
            value={props.product["product_brand_id"]}
            placeholder="Brand Name"
            showSearch={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(v) => (props.product["product_brand_id"] = v)}
          >
            {dynamicBrandList.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.brand_name}
              </Option>
            ))}
          </Select>
        </span>
      </div> : null
    );
  };

  function onChangeCompany(e) {
    props.product["product_company_id"] = e
    props.product["product_brand_id"] = undefined
    setDynamicBrandList(false)
    productService.get_dynamic_brands(e).subscribe(
      (r) => {
        setDynamicBrandList(r.response.data.product_brands_based_on_company);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  const FZCompanySelector = () => {
    requiredValues["product_company_id"] = "Company Name*";
    return (
      <div className="block" style={inputStyle}>
        Company Name* :
        <span style={{ paddingLeft: 10 }}>
          <Select
            style={{ width: commonInputWidth, textAlign: "left" }}
            value={props.product["product_company_id"]}
            placeholder="Company Name"
            showSearch={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={onChangeCompany}
          >
            {props.companyList.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.company_name}
              </Option>
            ))}
          </Select>
        </span>
      </div>
    );
  };

  const FZProductTaxSlabSelector = () => {
    requiredValues["product_tax_slab_id"] = "Product Tax Slab*";
    return (
      <div className="block" style={inputStyle}>
        Product Tax Slab* :
        <span style={{ paddingLeft: 10 }}>
          <Select
            style={{ width: commonInputWidth, textAlign: "left" }}
            value={props.product["product_tax_slab_id"]}
            placeholder="Product Tax Slab"
            showSearch={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(v) => (props.product["product_tax_slab_id"] = v)}
          >
            {props.productTaxSlab.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </span>
      </div>
    );
  };

  const FZProductCessSlabSelector = () => {
    requiredValues["product_cess_slab_id"] = "Product Cess Slab*";
    return (
      <div className="block" style={inputStyle}>
        Product Cess Slab* :
        <span style={{ paddingLeft: 10 }}>
          <Select
            style={{ width: commonInputWidth, textAlign: "left" }}
            value={props.product["product_cess_slab_id"]}
            placeholder="Product Cess Slab"
            showSearch={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(v) => (props.product["product_cess_slab_id"] = v)}
          >
            {props.productCessSlab.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </span>
      </div>
    );
  };

  const FZInputRadioSelector = ({ inputName, keyName }) => {
    requiredValues[keyName] = inputName;
    return (
      <div className="block" style={inputStyle}>
        {inputName} :
        <span style={{ paddingLeft: 10 }}>
          <Radio.Group
            style={{ width: commonInputWidth, textAlign: "left" }}
            value={radioValues[keyName] ?? props.product[keyName]}
            onChange={(e) => {
              props.product[keyName] = e.target.value;
              setRadioValues({
                ...radioValues,
                [keyName]: e.target.value,
              });
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </span>
      </div>
    );
  };

  function handleClick({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  function addDropdown() {
    if (count.length === 0) {
      count.push(1);
    } else {
      count.push(count[count.length - 1] + 1);
    }
    setCount(count);
    setRenderData(true);
  }

  useEffect(() => {
    if (renderData) {
      setRenderData(false);
      setCount(count);
      setSkuList(skuList);
      // console.log(count)
    }
    if (resetState) {
      setResetState(false);
      setCount([]);
      setSkuList([]);
      setState({});
    }
  });

  function deleteDropdown(val) {
    skuList.splice(
      count.findIndex((e) => e === val),
      1
    );
    count.splice(
      count.findIndex((e) => e === val),
      1
    );
    setCount(count);
    setSkuList(skuList);
    // console.log(skuList)
    setRenderData(true);
  }

  function blockDuplicates(id) {
    // console.log(skuList)
    if (skuList.find((e) => e === id)) {
      return true;
    }
  }

  function onChange(value, indexval, type, index) {
    if (type === "sku") {
      let stateType = "sku" + indexval;
      // console.log(skuList)
      if (skuList[index] !== undefined) {
        skuList[index] = value;
      } else {
        skuList.push(value);
      }
      setSkuList(skuList);
      setState((prevState) => ({
        ...prevState,
        [stateType]: value === "" ? undefined : value,
      }));
    } else if (type === "qty") {
      let stateType = "qty" + indexval;
      setState((prevState) => ({
        ...prevState,
        [stateType]: value === "" || value === null ? undefined : value,
      }));
    }
    // console.log(skuList)
  }

  function openNotificationWithIcon(type, msg) {
    notification[type]({
      message: type.toUpperCase(),
      duration: 3,
      description: msg,
    });
  }

  const uploadButton = (
    <div>
      {props.imgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleChangeCategory = (e) => {
    props.product['product_category_id'] = undefined
    setCategoryId(e)
  }

  function getBase64File(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const onFinish = async values => {
    let allPrimis = []
    let tempPayload = [];
    if (values.imageList) {
      values.imageList.map((data) => {
        allPrimis
          .push(getBase64File(data?.image?.file.originFileObj));
      })
      let res = await Promise.all(allPrimis)
      values.imageList.map((data, index) => {
        tempPayload.push({ image: res[index], tag: data.tag, fileDetail: data.image })
      })
      setMultipleImagePayload(tempPayload)
    }

    handleOk(tempPayload)
  };


  const handleOnChangeImage = (type, item) => {
    setImageState(prevState => ({ ...prevState, [type]: item }))
  }

  const onChangeImage = (e) => {
    handleChangeImage(e, 'image')
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function handleChangeImage(info, type) {
    setFileName(info.file.name)
    if (info.file.status === 'uploading') {
      // setImgLoading(true)
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, item => handleOnChangeImage(type, item))
    }
  };

  // function getBase64(img, callback) {
  //   const reader = new FileReader();
  //   reader.addEventListener('load', () => callback(reader.result));
  //   reader.readAsDataURL(img);
  //   let img1 = new Image()
  //   img1.src = window.URL.createObjectURL(img)
  //   img1.onload = () => {
  //     if (img1.width <= 512 && img1.height <= 512) {
  //       if (img1.height === img1.width) {
  //         setImageSizeAcceptable(true)
  //       } else {
  //         setImageSizeAcceptable(false)
  //         setImageErrMsg(`Uploaded image dimension ${img1.width} x ${img1.height} is not equal,
  //          please upload equal dimension's image.`)
  //       }
  //       // upload logic here
  //     } else {
  //       setImageSizeAcceptable(false)
  //       setImageErrMsg(`Uploaded image dimension ${img1.width} x ${img1.height} is too large,
  //        please upload standard 512 x 512 dimension image.`)
  //     }
  //   }
  // }

  const validateImageDimension = (img1) => {
    let isAccepted = false;
    if (img1.width <= 512 && img1.height <= 512) {
      if (img1.height === img1.width) {
        isAccepted = true
      } else {
        isAccepted = false
        ErrorNotification(`Uploaded image dimension ${img1.width} x ${img1.height} is not equal,
           please upload equal dimension's image.`)
      }
      // upload logic here
    } else {
      isAccepted = false
      ErrorNotification(`Uploaded image dimension ${img1.width} x ${img1.height} is too large,
         please upload standard 512 x 512 dimension image.`)
    }
    return isAccepted;
  }

  // const beforeUpload = (file) => {
  //   const reader = new FileReader();
  //   reader.addEventListener('load', file);
  //   reader.readAsDataURL(file);
  //   //accepted img type svg/png/jpeg
  //   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg';
  //   //check for dimension validation
  //   let accepted = (async () => {
  //     let isAccepted = false;
  //     let img1 = new Image()
  //     img1.src = window.URL.createObjectURL(file)
  //     await img1.decode();
  //     if (img1.width <= 512 && img1.height <= 512) {
  //       if (img1.height === img1.width) {
  //         isAccepted = true
  //       } else {
  //         isAccepted = false
  //         ErrorNotification(`Uploaded image dimension ${img1.width} x ${img1.height} is not equal,
  //            please upload equal dimension's image.`)
  //       }
  //       // upload logic here
  //     } else {
  //       isAccepted = false
  //       ErrorNotification(`Uploaded image dimension ${img1.width} x ${img1.height} is too large,
  //          please upload standard 512 x 512 dimension image.`)
  //     }
  //     return isAccepted
  //   })();


  //   if (!isJpgOrPng) {
  //     message.error('You can only upload JPG/PNG/SVG file!');
  //   }
  //   const isLt2M = file.size / 1024 / 1024 < 2;
  //   if (!isLt2M) {
  //     message.error('Image must smaller than 2MB!');
  //   }

  //   return isJpgOrPng && isLt2M;
  // }

  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.addEventListener('load', file);
    reader.readAsDataURL(file);
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml' || file.type === 'image/webp';

    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG/SVG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }


  const handleChangeMultipleUpload = (info, index) => {
    // if (info.file.status === 'uploading') {
    //   this.setState({ loading: true });
    //   return;
    // }
    if (info.file.status === undefined) {
      form.setFieldsValue({ imageList: [undefined] })
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        //if editing the existing image
        if (tempList[index]) {
          tempList[index] = imageUrl
          setImageList({ ...tempList })
        } else {
          tempList.push(imageUrl)
          setImageList({ ...tempList })
          // tempList.push(imageUrl)
        }
      }
        // this.setState({
        //   imageUrl,
        //   loading: false,
        // }),
      );
    }
  };

  const handleOnImageRemove = (index) => {
    tempList.splice(index, 1);
    setImageList(tempList)
  }


  return (
    <div>
      {data ? (
        <Modal
          visible={visible}
          title={title}
          onOk={() => form.submit()}
          style={{ float: "right" }}
          className="order"
          width={title === "Create Product" ? "95%" : "98%"}
          onCancel={handleCancelModal}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => form.submit()}
            >
              Submit
            </Button>,
          ]}
        >
          <div
            className="order-modal-container"
            style={{ paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}
          >
            <Row>
              <Col
                span={title === "Add Combo Product" ? 7 : 7}
                style={{ borderRight: "1px solid #f0f0f0", paddingTop: 15 }}
              >

                {title === "Create Product" ? (
                  <FZInput
                    inputName="VnF Converstion Factor"
                    keyName="vnf_conversion_factor"
                    disabled={true}
                  />
                ) : null}
                <FZCompanySelector />
                <FZBrandSelector />
                <FZInput inputName="Product Name*" keyName="name" />
                {/* <FZInput inputName="SKU Code*" keyName="sku" isCaps={true} /> */}
                <FZInput inputName="Variant Name" keyName="variant_name" />
                {/* <FZInput inputName="Description*" keyName="description" /> */}
                {/* <div style={{ position: "relative", height: 125 }}>
                  <div
                    className="block"
                    style={{ display: "flex", right: 78, position: "absolute" }}
                  >
                    Upload Image :
                    <span style={{ paddingLeft: 10 }}>
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        customRequest={handleClick}
                        onChange={props.handleChange}
                      >
                        {props.imageUrl ? (
                          <img
                            src={props.imageUrl}
                            alt="avatar"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </span>
                  </div>
                </div> */}
                {/* <FZInput inputName="Image URL*" keyName="image_url" /> */}
                {title === "Create Product" ? (
                  <div>
                    <FZInputSelector
                      inputName="uom*"
                      keyName="uom"
                      data={uomList}
                      hasId={false}
                    />
                    {uomListUnitQty.includes(uomVal) ?
                      <FZInputNumber inputName="Unit Qty In Pack*" keyName="unit_qty_in_pack" />
                    : null}
                    {/* ----------------------------------/ /------------------------------ */}
                    <FZInput
                      inputName="Pack Size*"
                      keyName="pack_size"
                      placeholder="eg: 250 g"
                    />
                  </div>
                ) : null}
                {/* for create these are here in edit these will be in prod desc tabs */}
                <FZInput
                  inputName="HSN*"
                  keyName="hsn_code"
                  placeholder="HSN code"
                />
                <FZInput
                  inputName="SKU*"
                  keyName="sku"
                  placeholder="SKU ID"
                  maxLength={32}
                />
                <FZInput
                  inputName="EAN"
                  keyName="ean_no"
                  placeholder="EAN code"
                />
                <FZInputNumber inputName="OPC Sequence" keyName="opc_sequence" />
                <FZInput
                  inputName="Pack Size Desc"
                  keyName="pack_size_desc"
                  placeholder="eg: Approx 600-800g"
                />
                {/* <FZInputRadioSelector
                  inputName="Is Active*"
                  keyName="is_active"
                /> */}
                <FZInputRadioSelector
                  inputName="Is Special Discount*"
                  keyName="is_special_discount"
                />
                {/* {title === "Create Product" ? (
                  <>
                    <FZInputRadioSelector inputName="Is Inventory Enabled*" keyName="is_zapper_enabled" />
                    <FZInputRadioSelector inputName="Allow Unlimited Stock" keyName="allow_unlimited_stock" />
                    {!props.product.allow_unlimited_stock ? 
                      <FZInputRadioSelector inputName="Include Last GRN" keyName="include_last_grn" />
                    : null}  
                  </>
                ) : null} */}
                <FZInputNumber inputName="moq*" keyName="moq" />
                <FZInputNumber
                  inputName="Max Quantity*"
                  keyName="max_qty"
                  placeholder="Max Qty"
                />
                <FZInputNumber
                  inputName="Multiple Quantity"
                  keyName="multiple_quantity"
                  placeholder="In number"
                />
                {title === "Create Product" ?
                  <>
                    <FZInputSelector
                      inputName="Procurement Unit*"
                      keyName="procurement_unit"
                      data={unitList}
                      hasId={false}
                    />
                    <FZInput
                      inputName="Lot Size"
                      keyName="lot_size"
                      placeholder="lot_size"
                    />
                    <FZInputNumber
                      inputName="Weights In Kgs Factor*"
                      keyName="weight_in_kgs"
                      placeholder="weight_in_kgs"
                    />
                  </>
                  : null}
                <FZInputNumber
                  inputName="Conversion Factor*"
                  keyName="conversion_factor"
                  placeholder="eg: 0.25 for pack size of 250g"
                />
                {title === "Add Combo Product" ? (
                  <FZInput
                    inputName="VnF Converstion Factor"
                    keyName="vnf_conversion_factor"
                    disabled={false}
                  />
                ) : null}
                <FZInputText inputName="Description" keyName="description" />
                <FZInputText
                  inputName="Sourcing Message"
                  keyName="sourcing_message"
                />
                <FZInputText inputName="Seo Title" keyName="seo_title" />
              </Col>

              <Col
                span={title === "Add Combo Product" ? 7 : 7}
                style={{ borderRight: "1px solid #f0f0f0", paddingTop: 15 }}
              >
                {/* productCategories */}
                <div className="block" style={inputStyle}>
                  Category :
                  <span style={{ paddingLeft: 10 }}>
                    <Select
                      placeholder="Category Name"
                      value={categoryId}
                      showSearch={true}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ width: 300, textAlign: "left" }}
                      onChange={(e) => handleChangeCategory(e)}>
                      {props.productCategories.map((data, index) => {
                        return (
                          <Option key={index} value={data.id}>{data.name}</Option>
                        )
                      })}
                    </Select>
                  </span>
                </div>

                <FZInputSelector
                  disabled={!categoryId}
                  inputName="Sub-Category*"
                  keyName="product_category_id"
                  data={props.subCategoryList}
                  isFilter={categoryId}
                  customWidth={300}
                />
                <FZInputNumber inputName="mrp" keyName="mrp" />
                <FZInputNumber inputName="sp" keyName="sp" />
                <FZInputNumber inputName="moq_mrp*" keyName="moq_mrp" />
                <FZInput inputName="moq_sp*" keyName="moq_sp" />
                <FZInput
                  inputName="Local Names"
                  keyName="local_names"
                  placeholder="Comma separated values"
                />
                <FZInput
                  inputName="Sequence"
                  keyName="sequence"
                  placeholder="Order of display in app"
                />
                <FZInputRadioSelector
                  inputName="Is Master"
                  keyName="is_master"
                />
                <FZProductTaxSlabSelector />
                <FZProductCessSlabSelector />
                <FZInputNumber
                  inputName="CGST Percentage"
                  keyName="cgst_percentage"
                />
                <FZInputNumber
                  inputName="SGST Percentage"
                  keyName="sgst_percentage"
                />
                <FZInputText inputName="Info" keyName="info" />
                <FZInputText inputName="Benefits" keyName="benefits" />
                <FRZColorPicker
                  label="Background Color"
                  // value={props.modalState.background_color}
                  pickerColor={pickerBGColor}
                  changePickerColor={changePickerColor}
                  setPickerColor={setPickerBGColor}
                  columnSpan={10}
                />
                <FZInputText inputName="Seo Description" keyName="seo_description" />
                <FZInputText inputName="Seo Keywords" keyName="seo_keywords" />
                {/* <FZInput inputName="Background Color" keyName="background_color" placeholder="Background Color"/> */}
              </Col>
              {title === "Add Combo Product" ? null :
                <Col span={10} style={{ borderRight: "1px solid #f0f0f0", paddingTop: 15 }}>
                  {/* <input type="file" id="myFile" name="filename" /> */}
                  <div className="frz-multiple-image-uploader">
                    <Form form={form} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
                      <Form.List name="imageList">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }, index) => (
                              <Space key={key}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'image']}
                                  rules={[{ required: true, message: 'Missing image' }]}
                                // getValueFromEvent={getFile}
                                >
                                  {/* <Upload className="avatar-uploader" showUploadList={false} name="logo" action="/upload.do" listType="picture" customRequest={handleClick}>
                                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                                </Upload> */}
                                  <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="/upload.do"
                                    customRequest={handleClick}
                                    beforeUpload={beforeUpload}
                                    onChange={(e) => handleChangeMultipleUpload(e, name)}>
                                    {imageList[name] ? <img src={imageList[name]} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                  </Upload>
                                  {/* <Upload className="avatar-uploader" showUploadList={false}
                                  name="avatar" action="/upload.do" listType="picture-card" customRequest={handleClick}>
                                  {imageState['image'] ? <img src={imageState['image']} alt="avatar" style={{ width: "100%" }} /> : uploadButton}
                                </Upload> */}
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'tag']}
                                  rules={[{ required: true, message: 'Missing tag' }]}>
                                  <Input placeholder="Tag" />
                                </Form.Item>
                                <MinusCircleOutlined style={{ marginBottom: 25 }} onClick={() => {
                                  remove(name)
                                  handleOnImageRemove(name)
                                }} />
                              </Space>
                            ))}
                            <Form.Item>
                              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add Image
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                      {/* <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item> */}
                    </Form>
                  </div>
                </Col>}

              {title === "Add Combo Product" ? (
                <Col span={10}>
                  <div className="refund-padding">
                    {count.length > 0 ? (
                      <div>
                        <div style={{ textAlign: 'right', paddingBottom: 10 }}><b>Qty in procurement units</b></div>
                        {count.map((val, index) => (
                          <div className="refund-inline" key={val}>
                            <Select
                              style={{ width: 330 }}
                              placeholder="Select SKU"
                              onChange={(e) => onChange(e, val, "sku", index)}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.skuName
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {props.productList.map((data) => {
                                return (
                                  <Option
                                    key={data.id}
                                    value={data.id}
                                    disabled={blockDuplicates(data.id)}
                                    skuName={data.name}
                                  >
                                    {data.name} - ({data.pack_size})
                                  </Option>
                                );
                              })}
                            </Select>
                            <InputNumber
                              placeholder="Qty"
                              style={{ width: 70 }}
                              onChange={(e) => onChange(e, val, "qty")}
                            />
                            <Button
                              type="primary"
                              icon={<DeleteOutlined />}
                              style={{ width: 36, padding: 0, paddingTop: 2 }}
                              shape="round"
                              onClick={() => deleteDropdown(val)}
                            ></Button>
                          </div>
                        ))}
                      </div>
                    ) : null}
                    <Button
                      type="primary"
                      icon={<PlusCircleOutlined />}
                      style={{ width: 104 }}
                      onClick={addDropdown}
                    >
                      Add Sku
                    </Button>
                  </div>
                  <Divider />
                  <div className="frz-multiple-image-uploader">
                    <Form form={form} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
                      <Form.List name="imageList">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }, index) => (
                              <Space key={key}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'image']}
                                  rules={[{ required: true, message: 'Missing image' }]}
                                // getValueFromEvent={getFile}
                                >
                                  {/* <Upload className="avatar-uploader" showUploadList={false} name="logo" action="/upload.do" listType="picture" customRequest={handleClick}>
                                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                                </Upload> */}
                                  <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="/upload.do"
                                    customRequest={handleClick}
                                    beforeUpload={beforeUpload}
                                    onChange={(e) => handleChangeMultipleUpload(e, name)}>
                                    {imageList[name] ? <img src={imageList[name]} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                  </Upload>
                                  {/* <Upload className="avatar-uploader" showUploadList={false}
                                  name="avatar" action="/upload.do" listType="picture-card" customRequest={handleClick}>
                                  {imageState['image'] ? <img src={imageState['image']} alt="avatar" style={{ width: "100%" }} /> : uploadButton}
                                </Upload> */}
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'tag']}
                                  rules={[{ required: true, message: 'Missing tag' }]}>
                                  <Input placeholder="Tag" />
                                </Form.Item>
                                <MinusCircleOutlined style={{ marginBottom: 25 }} onClick={() => {
                                  remove(name)
                                  handleOnImageRemove(name)
                                }} />
                              </Space>
                            ))}
                            <Form.Item>
                              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add Image
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                      {/* <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item> */}
                    </Form>
                  </div>
                </Col>
              ) : null}
            </Row>
          </div>
        </Modal>
      ) : null}
    </div>
  );
});

export default ProductAddFormModal;
