import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { Modal, Form } from 'antd';
import { useSelector } from "react-redux";
import FRZInput from '../../../_controls/FRZInput';
import FRZSelect from '../../../_controls/FRZSelect';
import FRZRadioButton from '../../../_controls/FRZRadioButton';
import { formItemLayout } from '../../../_modules/controllayout';

const LocationModal = forwardRef((props, ref) => {
  const { modalSubmit } = props

  const [form] = Form.useForm();

  const [id, setId] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [isClose, setIsClose] = useState(false)
  const darkstore = useSelector(state => state.auth.darkstore);

  useEffect(() => {
    if (data && title == "Edit Location") {
      form.setFieldsValue(data)
      setId(data.id)
    } else {
      setId(false)
    }
  }, [data])

  useEffect(() => {
    if (isClose) {
      if (title == "Edit Location") {
        form.setFieldsValue(data)
        setId(data.id)
      } else {
        setId(false)
      }
    }
    setIsClose(false)
  }, [isClose])

  useImperativeHandle(ref, () => ({
    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      form.resetFields()
      setData(false)
      setVisible(false)
      setLoading(false)
      setIsClose(true)
    },

    stopLoading() {
      setLoading(false)
    }
  }));

  function handleCancelModal() {
    form.resetFields()
    setData(false)
    setVisible(false)
    setLoading(false)
    setIsClose(true)
  }

  const handleOnSubmit = (values) => {
    setLoading(true)
    modalSubmit(values, id)
  }

  const cities = [{ id: 1, city: "Mumbai" }, { id: 2, city: "Pune" }, {id: 4, city: "Hyderabad"}, {id: 67, city: "Bengaluru"}]

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">
            <FRZInput name="area_name" isRequired={true}
              label="Area Name" placeholder="Area Name" width={200} />

            <FRZSelect DropDownData={cities} name="city_id" isRequired={true}
              label="City" placeholder="Select City" value="id" option="city" isShowSearch={true} width={200} />

            <FRZRadioButton label="Is Active" isRequired={true} name="is_active" />

            <FRZSelect DropDownData={darkstore} name="darkstore_id" isRequired={true}
              label="Darkstore Name" placeholder="Select Darkstore Name" value="id" option="name"
              isShowSearch={true} width={"100%"} />
          </Form>
        </Modal>
        : null}
    </div>

  )
})

export default LocationModal
