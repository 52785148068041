import React, { useEffect, useState } from 'react';
import { Spin, Button, Card, Row, Col,
         Pagination, DatePicker, Table } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {FulfillmentService} from '../../_services/fulfillment';
import Search from '../../components/antd/search';
import {productunfulfillmentColumns} from '../../components/antd/columns/fulfillment';
import moment from 'moment';

const fulfillment_service = new FulfillmentService()

function ProductUnfullfillment(){
  const [listingData, setListingData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [state, setState] = useState({valueBySource: undefined, valueByDeliveryDate: '', date: ''});
  const { valueBySource, valueByDeliveryDate, date } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getListing()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getListing(){
    setSpinEnable(true)
    setListingData([])
    const payload = {
      page: current,
      darkstore_id: !valueBySource ? '' : valueBySource,
      date: !valueByDeliveryDate ? '' : valueByDeliveryDate,
    }

    fulfillment_service.product_unfulfillment(payload).subscribe((r)=>{
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setListingData(r.response.data)
      setSpinEnable(false)
    },
    (err)=>{
      setSpinEnable(false)
      setListingData([])
      console.log(err)
    })
  }


  function getColumns(){
    productunfulfillmentColumns.map(data => {
      columns.push(data)
    })
    setColumns(columns)
  }

  function pagination(page) {
    setCurrent(page)
    setListingData([])
    setRenderData(false)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function onChangeDate(date, dateString) {
    setState(prevState =>({...prevState, date: date, valueByDeliveryDate: dateString}))
    setDisable(false)
    setCurrent(1)
  }

  function reset() {
    setState({valueBySource: undefined, valueByDeliveryDate: '', date: ''})
    setDisable(true)
    setCurrent(1)
    setListingData([])
    setRenderData(false)
  }

  function disabledDate(current){
    // Can select days before today and today
    return current && current > moment().add(-1, "day");
  }

  return(
    <div className="layer">
      {listingData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Darkstore" value={valueBySource} width="medium"
                        onChange={(e) => onChange(e, 'valueBySource')} type="valueByDSCode"/>
                <DatePicker value={date} onChange={onChangeDate} placeholder="Date"
                            style={{width: 200, margin: 10}} disabledDate={disabledDate}/>
                <Button disabled={disable} onClick={getListing} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
              </Col>
            </Card>
          </Row>
          {listingData.length > 0 ?
            <Row>
              <Col span={24}>
                <Card style={{ paddingTop: 5, marginTop: 20 }}>
                    <Row>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                dataSource={listingData}
                                pagination={false}
                                scroll={{ x: 240 }}
                                rowKey="name"
                            />
                        </Col>
                    </Row>
                </Card>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default ProductUnfullfillment
