import React, { useEffect, useState, useRef } from 'react';
import {
  Spin, Button, Card, Row, Col, DatePicker,
  Pagination, notification
} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { DownloadService } from '../../_services/downloads';
import Search from '../../components/antd/search';
import MainTable from '../../components/antd/table';
import AdminModal from '../../components/master/admin/admin_modal';
import { monthlyColumns } from '../../components/antd/columns/monthly';
import { openNotificationWithIcon } from '../../_modules/notification';
import moment from 'moment';

const download_service = new DownloadService()

function MonthlyDownloads() {
  const modalRef = useRef(null);
  const [reportData, setReportData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [reports] = useState([{ id:'sku_level_sales_report', name: 'SKU Level Sales Report' }, 
                              { id:'order_level_sales_report', name: 'Order Level Sales Report' }])
  const [state, setState] = useState({ date: '', dateValue: '', valueByReport: undefined });
  const [salesDownload, setSalesDownload] = useState(false)
  const [fetchColm, setFetchColm] = useState(false) 
  const { date, dateValue, valueByReport } = state

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getReports()
    }
  })

  useEffect(() => {
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if(['admin','developer','super_admin','accounts','sales_download'].includes(data)){
        setSalesDownload(true)
      }
    })
    setFetchColm(true)
  }, [])

  useEffect(() => {
    if(fetchColm){
      setFetchColm(false)
      getColumns()
    }
  })

  function getReports() {
    setSpinEnable(true)
    setReportData([])
    const payload = {
      page: current,
      date: dateValue ? dateValue+"-01" : '',
      url: valueByReport ?? '',
    }

    download_service.get_monthly_reports(payload).subscribe((r) => {
      setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
      setSpinEnable(false)
      setReportData(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getColumns() {
    monthlyColumns.map(data => {
      columns.push(data)
    })
    columns.push(
      {
        title: 'Downloads',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (
            <>  
              <Button type="primary" onClick={() => download(record.report_url, 'Download')} className="frz-m-10"
                      disabled={!salesDownload}>
                  Download
              </Button>
              <Button type="primary" onClick={() => download(record.id, 'Regenerate')} className="frz-m-10"
                      disabled={!salesDownload}>
                  Regenerate Report
              </Button>
            </>
          )
        }
      })
    setColumns(columns)
  }

  function download(data, type) {
    if(type === 'Download'){
      window.open(data, '_blank')
    }else{
      const payload = {
        id: data
      }
      download_service.download_reports_regenerate(payload).subscribe((r) => {
        openNotificationWithIcon('success', "Report Regenerated Successfully. Please check your email for latest reports")
      },
      (err) => {
        console.log(err)
        openNotificationWithIcon('error', "Report Regeneration Failed")
      })
    }   
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }

  function reset() {
    setState({ date: '', dateValue: '', valueByReport: undefined })
    setDisable(true)
    setCurrent(1)
    setReportData([])
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setReportData(false)
    setRenderData(false)
  }

  function disabledDate(current){
    // Can select days before today and today
    // return current && current > moment().add(0, "day");
    //Can select days before today and past 3 months
    return current && (current < moment().subtract(12, "month") || current > moment().add(0, "month"));
}

function onChangeDate(date, dateString) {
    setState(prevState =>({...prevState, date: date, dateValue: dateString}))
    setDisable(false)
    setCurrent(1)
}

  return (
    <div className="layer">
      {reportData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <DatePicker value={date} onChange={onChangeDate} placeholder="Month" picker="month"
                            className="frz-w-200 frz-m-10" disabledDate={disabledDate}/>
                <Search placeholder="Report Name" value={valueByReport} width='medium'
                        onChange={(e) => onChange(e, 'valueByReport')} type="valueByCategory" data={reports} />
                <Button disabled={disable} onClick={getReports}
                  className="frz-w-100px frz-m-10" type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          {reportData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={reportData} columns={columns} />
                <Pagination className="frz-tm-10 frz-bm-10" current={current}
                  pageSize={50} total={state.total} onChange={pagination}
                  showSizeChanger={false} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  );
}

export default MonthlyDownloads