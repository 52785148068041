import React, {useEffect, useState} from 'react';
import {Card , Radio, Spin, Select, DatePicker} from 'antd';
import { Bar} from 'react-chartjs-2';
import {StatisticsService} from '../../_services/statistics';
import moment from 'moment';
import Search from '../antd/search';
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;

const stats_service =  new StatisticsService()

function ExpressStockOut(){
    const [renderData, setRenderData] = useState(false)
    const [renderDataGraph, setRenderDataGraph] = useState(false)
    const [state, setState] = useState({type: 'daily', start_date: '', end_date: '', showDateValue: false,
                                        valueByDarkstore: 'all'});
    const presetData = {
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [
            '#FF6384','#36A2EB','#FFCE56','green','orange','brown','cyan','darkgrey','pink','purple','peacock','berry','gold','sage','mauve'
            ],
            data1: []
        }]
    } 
    const [graphData, setGraphData] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [
            '#FF6384','#36A2EB','#FFCE56','green','orange','brown','cyan','darkgrey','pink','purple','peacock','berry','gold','sage','mauve'
            ],
            data1: []
        }]
    })
    const { type, start_date, end_date, showDateValue, valueByDarkstore } = state

    useEffect(() => {
        if(!renderData){
          setRenderData(true)
          getExpressStockOut()
        }
      })

    function getExpressStockOut(){
        const payload = {
          type: type === undefined ? '' : type,
          start_date: start_date,
          end_date: end_date,
          by_darkstore: valueByDarkstore === 'all' ? '' : valueByDarkstore,
        }
        stats_service.get_out_stock_express(payload).subscribe((r) => {
          setStatsData(r.response.data)
        },
        (err)=>{
          console.log(err)
        })
    }

    function onChangeRangePicker(value, dateString){
        setState(prevState =>({...prevState, showDateValue: true, start_date :dateString[0], end_date:dateString[1], type: undefined}))
        setRenderDataGraph(false)
        setRenderData(false)
        setGraphData(presetData)
    }

    function onChange(value, type) {
        if(type === 'type'){
            setState(prevState =>({...prevState, [type]: value, start_date: '', end_date: '', showDateValue: false}))
        }else{
            setState(prevState =>({...prevState, [type]: value}))
        }
        setRenderDataGraph(false)
        setRenderData(false)
        setGraphData(presetData)
     }

    function setStatsData(data) {
        data.map(val => {
            graphData.labels.push(val.name)
            graphData.datasets[0].data.push(val.out_of_stock_time)
        })
        // console.log(graphData)
        setGraphData(graphData)
        setRenderDataGraph(true)
    }

    function disabledDate(current){
        // Can select days before today and today
        return current && current > moment().add(0, "day");
      }

    return(
        <div>
            <Card>
                <h2 style={{margin: 10}}>Express Out Of Stock SKU's: </h2>
                <div className="layer">
                    <Search placeholder="Darkstore Name" value={valueByDarkstore}
                        onChange={(e) => onChange(e, 'valueByDarkstore')} type="valueByDarkstoreStats"/>
                </div>
                <div style={{margin: 10, borderBottom: '1px solid rgb(240,240,240', paddingBottom: 20}}>
                    <Select style={{ width: 150}} value={type}
                            placeholder="Select Type" onChange={(e) => onChange(e, 'type')}>
                        <Option value='daily'>Yesterday</Option>
                        <Option value='weekly'>Weekly</Option>
                        <Option value='this_month'>This Month</Option>
                        <Option value='last_month'>Last Month</Option>
                        <Option value='quarterly'>Quarterly</Option>
                        {/* <Option value='half_yearly'>Half Yearly</Option> */}
                    </Select>
                    <RangePicker onChange={onChangeRangePicker} style={{float:'right'}}
                            value={showDateValue ? [moment(start_date, dateFormat),
                            moment(end_date, dateFormat)] : '' } format={dateFormat}
                            allowClear={false} disabledDate={disabledDate}/>
                </div>
                {renderDataGraph ? 
                   <div>
                        <Bar data={graphData}
                            options={{
                                    tooltips: {
                                        titleFontSize:13,
                                        bodyFontSize:15,
                                        enabled: true,
                                        displayColors:false,
                                        callbacks: {
                                            title: (tooltipItem, data) => {
                                                return data['labels'][tooltipItem[0]['index']];;
                                            },
                                            label: (tooltipItem, data) => {
                                                let tooltipData = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                                return `Out Of Stock Duration : ${tooltipData.toLocaleString('en-IN')} hr`;
                                            },
                                            afterLabel: (tooltipItem, data) => {
                                                let allData = data.datasets[tooltipItem.datasetIndex].data.reduce((a, b) => a + b, 0);
                                                let tooltipData = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                                let percentage =(tooltipData/allData * 100).toFixed(2);
                                                return `Percentage : ${percentage}%`;
                                            }
                                        }
                                    },
                                    scales: {
                                        yAxes : [{
                                          ticks : {
                                            min : 0
                                          }
                                        }]
                                    }
                            }}
                            legend={{"display": false}}
                            width={50} height={29} redraw={true}
                        />
                   </div>
                : 
                  <div className="spin-center">
                    <Spin tip="...Loading"/>
                  </div>
                }
            </Card>
        </div>
    )
}

export default ExpressStockOut