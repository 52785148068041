import React, { useState } from 'react'
import { Button } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import { DownloadService } from '../../../_services/downloads';
import { openNotificationWithIcon } from '../../../_modules/notification';

const download_service = new DownloadService();

function download(data, type, setState) {
  setState(prevState => ({ ...prevState, spinner: true }))
  const payload = {
    id: data
  }
  download_service.download_discount_reports(payload).subscribe((r) => {
    setState(prevState => ({ ...prevState, spinner: false }))
    if (r.response.data != null) {
      window.open(r.response.data, '_blank')
      openNotificationWithIcon('success', "Report Generated Successfully. Please check your email for latest reports")

    }
    else {
      openNotificationWithIcon('error', "Check Mail. Contact Tech if not received!");
      setState(prevState => ({ ...prevState, spinner: false }))
    }
  },
    (err) => {
      setState(prevState => ({ ...prevState, spinner: false }))
      console.log(err)
      openNotificationWithIcon('error', "Report Generation Failed")
    })
}

export const bulkDiscountColumns = (onEdit, setState, operations) => {
  return [
    {
      title: 'Campaign Name',
      dataIndex: 'campaign_name',
      key: 'campaign_name',
      align: "center",
    },
    {
      title: 'Campaign Code',
      dataIndex: 'campaign_code',
      key: 'campaign_code',
      align: 'center'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'center'
    },
    {
      title: 'Valid From',
      dataIndex: 'valid_from',
      key: 'valid_from',
      align: 'center',
      width: 120
    },
    {
      title: 'Valid To',
      dataIndex: 'valid_to',
      key: 'valid_to',
      align: 'center',
      width: 120
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: "center",
      render: (record) => <div>
        <Button onClick={() => onEdit(record)} icon={<EditOutlined />} disabled={!operations}></Button>
      </div>
    },
    {
      title: 'Downloads',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (
          <>
            <Button type="primary" onClick={() => download(record.id, 'Download', setState)} className="frz-m-10"
              disabled={!operations} >
              Download
            </Button>
          </>
        )
      }
    }
  ]
}