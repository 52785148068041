import React, { useState } from 'react';
import { Tabs } from 'antd';
import Landing from '../../components/sdui/landing_clusters/landing';
import Clusters from '../../components/sdui/landing_clusters/clusters';
import Banner from '../../components/sdui/landing_clusters/banners';
import { useSelector } from 'react-redux';

const { TabPane } = Tabs;

function LandingClusters() {
  const [currentTab, setCurrentTab] = useState(1);

  function changeTab(key) {
    setCurrentTab(key);
  }

  return (
    <div className="layer">
      <div className="ost">
        <Tabs defaultActiveKey="1" onChange={changeTab}>
          <TabPane tab="Landing Page" key="1">
            <Landing />
          </TabPane>
          <TabPane tab="Product Clusters" key="2">
            <Clusters />
          </TabPane>
          <TabPane tab="Banners Clusters" key="3">
            <Banner />
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default LandingClusters