import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, InputNumber, Pagination, Table, DatePicker, Popconfirm } from 'antd';
import { ReloadOutlined, EditOutlined } from '@ant-design/icons';
import { OperationsService } from '../../../_services/operations';
import Search from '../../../components/antd/search';
import MainTable from '../../../components/antd/table';
import { stoColumns, productColumns } from '../../../components/antd/columns/operations/sto';
import CreateStoModal from '../../../components/operations/sto/create_sto_modal';
import { openNotificationWithIcon } from '../../../_modules/notification';
import FrzDynamicUpload from '../../../_controls/FRZDynamicUpload';
import FrzDynamicDownload from '../../../_controls/FRZDynamicDownload';
import UpdateSTOModal from '../../../components/operations/sto/UpdateSTOModal';
import AddProductModalSTO from '../../../components/operations/sto/add_products';

const ops_service = new OperationsService()

function STOList() {
  const modalRef = useRef(null);
  const updateModalRef = useRef(null);
  const modalRefProducts = useRef(null);

  const [stoData, setStoData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [columnsProducts, setColumnsProducts] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [nodes, setNodes] = useState(false)
  const [products, setProducts] = useState(false)
  const [destinations, setDestinations] = useState(false)
  const [operations, setOperations] = useState(false)
  const [ccoperations, setCCOperations] = useState(false)
  const [whoperations, setWHOperations] = useState(false)
  const [fetchColm, setFetchColm] = useState(false) 
  const [status] = useState([{ id: 'dispatched', name: 'Dispatched' }, { id: 'delivered', name: 'Delivered' },
                             { id: 'cancelled', name: 'cancelled'}])
  const [state, setState] = useState({
    valueByNode: undefined, valueByDestination: undefined, valueByStatus: undefined,
    valueByDate: undefined, date: ''
  });
  const { valueByNode, valueByDestination, valueByStatus, valueByDate, date } = state

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getSto()
    }
  })

  useEffect(() => {
    getNodes()
    getNodeDestinations()
    getProducts()

    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if(['admin','developer','super_admin','warehouse_staff','warehouse_admin','coldex'].includes(data)){
        setOperations(true)
        setWHOperations(true)
      }
    })
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if(['admin','developer','super_admin','category','category_admin','cc_admin'].includes(data)){
        setCCOperations(true)
      }
    })
    setFetchColm(true)

  }, [])

  useEffect(() => {
    if(fetchColm){
      setFetchColm(false)
      getColumns()
    }
  })

  function getNodes() {
    const payload = {
      nodeType: `["CC","WH"]`
    }
    ops_service.get_node_listing(payload).subscribe((r) => {
      setNodes(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getNodeDestinations() {
    const payload = {
      nodeType: '',
      show_all: true
    }
    ops_service.get_node_listing(payload).subscribe((r) => {
      setDestinations(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getProducts() {
    ops_service.get_product_list().subscribe((r) => {
      setProducts(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getSto() {
    setSpinEnable(true)
    setStoData([])
    const payload = {
      page: current,
      by_source: !valueByNode ? '' : valueByNode,
      by_destination: !valueByDestination ? '' : valueByDestination,
      by_status: !valueByStatus ? '' : valueByStatus,
      transfer_date: !valueByDate ? '' : valueByDate,
      admin_id: JSON.parse(localStorage.getItem('auth')).id
    }

    ops_service.get_sto_list(payload).subscribe((r) => {
      setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
      setSpinEnable(false)
      setStoData(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  const handleOnUpdate = (values, type) => {
    showUpdateModal(values, type)
  }

  function getColumns() {
    stoColumns.map(data => {
      columns.push(data)
    })
    // columns.push({
    //   title: 'Edit',
    //   dataIndex: '',
    //   key: '',
    //   align: 'center',
    //   render: (record) => {
    //     return <Button title="Edit" icon={<EditOutlined />} disabled={!record.is_updatable}
    //                     onClick={() => showModal(record, 'Edit Payments')}>
    //             </Button>
    //   }
    // })
    productColumns.map(data => {
      columnsProducts.push(data)
    })
    columns.push({
      title: 'Edit STO',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return <Button icon={<EditOutlined />} 
                       disabled={!record.is_editable || (record.source_node_type === 'CC' && !ccoperations) || 
                                 (record.source_node_type === 'WH' && !whoperations) || record.source_node_type === 'DS'}
          onClick={() => handleOnUpdate(record, "Update STO")}>
      </Button>
      }
    },
    {
      title: 'Create Inwarding',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return <Button disabled={record.status === "cancelled" || record.status === "delivered" || 
                                 record.destination_node_type === 'DS' || (record.source_node_type === 'WH' && !whoperations) 
                                 || (record.source_node_type === 'CC' && !ccoperations) } 
                       type="primary"
                       onClick={() => handleOnUpdate(record, "Update Inward")}>
          Create Inwarding
        </Button>
      }
    },
    {
      title: 'Cancel STO',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return <Popconfirm
                  title="Are you sure you want to cancel this sto?"
                  onConfirm={() => cancelSTO(record.id)}
                  okText="Yes"
                  cancelText="No"
                  placement="leftTop"
                  disabled={record.status === "cancelled" || record.status === "delivered" || (record.source_node_type === 'CC' && !ccoperations) || 
                                          (record.source_node_type === 'WH' && !whoperations) || record.source_node_type === 'DS'}
                >
                <Button type="primary" ghost disabled={record.status === "cancelled" || record.status === "delivered" || (record.source_node_type === 'CC' && !ccoperations) || 
                                            (record.source_node_type === 'WH' && !whoperations) || record.source_node_type === 'DS'}>
                  Cancel
                </Button>
              </Popconfirm>
      }
    })

    setColumnsProducts(columnsProducts)
    setColumns(columns)
  }

  function cancelSTO(id){
    const payload = {
      id: id
    }
    setSpinEnable(true)
    setStoData([])
    ops_service.cancel_sto(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'STO Cancelled Successfully')
      getSto()
    },
    (err) => {
      console.log(err)
      getSto()
      openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
    })
  }

  function showModal(data, type) {
    modalRef.current.showModal(type, data)
  }

  function showUpdateModal(data, type) {
    updateModalRef.current.showUpdateModal(type, data)
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }

  function reset() {
    setState({
      valueByNode: undefined, valueByDestination: undefined, valueByStatus: undefined,
      valueByDate: undefined, date: ''
    })
    setDisable(true)
    setCurrent(1)
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setRenderData(false)
  }

  function expandedRow(record) {
    // let productsList = record.line_items.map(v => ({...v, is_editable: record.is_editable, po_id: record.id}))
    return <>
      <Button type="primary" className="frz-bm-20" onClick={() => showModalProducts(record, 'Add Product')}
              disabled={record.status !== 'dispatched' || (record.source_node_type === 'CC' && !ccoperations) || 
              (record.source_node_type === 'WH' && !whoperations) || record.source_node_type === 'DS'}>
        Add Products
      </Button>
      <Card className="nested-table">
        <Table columns={columnsProducts} pagination={false} rowKey="id" dataSource={record.line_items} />
      </Card>
    </>
  }

  function showModalProducts(data, type) {
    modalRefProducts.current.showModal(type, data)
  }

  function onChangeDate(date, dateString) {
    setState(prevState => ({ ...prevState, date: date, valueByDate: dateString }))
    setDisable(false)
    setCurrent(1)
  }

  function modalSubmit(payloadData) {
    const payload = { ...payloadData }
    ops_service.create_sto(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Created Successfully')
      getSto()
      modalRef.current.handleCancel()
    },
      (err) => {
        console.log(err)
        modalRef.current.stopLoading()
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      })
  }

  function modalUpdate(payloadData, title) {
    if (title === "Update Inward") {
      ops_service.update_inward(payloadData).subscribe((r) => {
        openNotificationWithIcon('success', 'Created Successfully')
        getSto()
        updateModalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          updateModalRef.current.stopLoading()
          openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
        })
    } else if (title === "Update STO") {
      ops_service.update_sto(payloadData).subscribe((r) => {
        openNotificationWithIcon('success', 'Created Successfully')
        getSto()
        updateModalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          updateModalRef.current.stopLoading()
          openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
        })
    }
  }

  function modalSubmitProduct(payloadData, id){
    const payload = {...payloadData, id: id}
    ops_service.sto_products(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Success')
      getSto()
      modalRefProducts.current.handleCancel()
    },
    (err) => {
      console.log(err)
      modalRefProducts.current.stopLoading()
      openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
    })
  }

  return (
    <div className="layer">
      {stoData && nodes && destinations && products ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <DatePicker value={date} onChange={onChangeDate} className="frz-w-200 frz-m-10" />
                <Search placeholder="Select Source" value={valueByNode} data={nodes}
                  onChange={(e) => onChange(e, 'valueByNode')} type="valueByCategory" />
                <Search placeholder="Select Destination" value={valueByDestination} data={destinations}
                  onChange={(e) => onChange(e, 'valueByDestination')} type="valueByCategory" />
                <Search placeholder="Select Status" value={valueByStatus} data={status}
                  onChange={(e) => onChange(e, 'valueByStatus')} type="valueByCategory" />
                <Button disabled={disable} onClick={getSto}
                  className="frz-w-100px frz-m-10" type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Create STO')} className="frz-w-100px frz-m-10" type="primary">
                  Create STO
                </Button>
                <div className="frz-dis-upload dynamic-upload">
                  <FrzDynamicUpload name={'STO'} url={'admin_dashboard/procurement/upload_sto_create_file'}
                    uploadFunc={'upload_zapper'} callBack={getSto}/>
                </div>
                <span className="fl-right">
                  <FrzDynamicDownload name={'Order Sheet'} fileName={'Order Sheet'} url={'admin_dashboard/procurement/sto_report'}
                                      payload={'?by_source='+`${valueByNode ?? ''}`+'&by_destination='+`${valueByDestination ?? ''}`
                                              +'&by_status='+`${valueByStatus ?? ''}`+'&transfer_date='+`${valueByDate ?? ''}`
                                              +'&admin_id='+`${JSON.parse(localStorage.getItem('auth')).id}`+'&order_sheet=true'} 
                                      type={'job'} reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'}/> 
                  <FrzDynamicDownload name={'STO'} fileName={'STO'} url={'admin_dashboard/procurement/sto_report'}
                                      payload={'?by_source='+`${valueByNode ?? ''}`+'&by_destination='+`${valueByDestination ?? ''}`
                                              +'&by_status='+`${valueByStatus ?? ''}`+'&transfer_date='+`${valueByDate ?? ''}`
                                              +'&admin_id='+`${JSON.parse(localStorage.getItem('auth')).id}`+'&operations=true'} 
                                      type={'job'} reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'}/>     
                  <FrzDynamicDownload name={'Shortfall'} fileName={'Shortfall'} url={`admin_dashboard/procurement/shortfall_report`}
                                      payload={'?date=' + `${valueByDate ?? ''}`} type={'job'}
                                      reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} />                    
                </span>
              </Col>
            </Card>
          </Row>
          {stoData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={stoData} columns={columns} expandedRow={expandedRow} />
                <Pagination className="frz-tm-10 frz-bm-10" current={current}
                  pageSize={50} total={state.total} onChange={pagination}
                  showSizeChanger={false} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <CreateStoModal modalSubmit={modalSubmit} ref={modalRef} nodes={nodes} destinations={destinations}
            products={products} />
          <UpdateSTOModal modalUpdate={modalUpdate} ref={updateModalRef} />
          <AddProductModalSTO modalSubmit={modalSubmitProduct} ref={modalRefProducts} products={products}/>
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  );
}

export default STOList