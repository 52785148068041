import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Select,
  Input,
  InputNumber,
  Row,
  Col,
  Radio,
  Button,
  Upload,
  TimePicker,
  Checkbox,
  Tabs,
  Table,
  Spin, Form,
} from "antd";
import { ErrorNotification, SuccessNotification } from "./notification";
import { productImagesColumns } from '../../antd/columns/master/product'
import { ProductService } from "../../../_services/product";
import { PlusCircleOutlined, DeleteOutlined, LoadingOutlined, PlusOutlined, CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import moment from "moment";
import FRZColorPicker from "../../../_controls/FRZColorPicker";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, useDrag, useDrop, createDndContext } from 'react-dnd';
import MainTable from '../../../components/antd/table';
import update from 'immutability-helper';
import { openNotificationWithIcon } from "../../../_modules/notification";
import ModalImage from "./modal_images";
import { formItemLayout, tailLayout } from '../../../_modules/controllayout';

const { Option } = Select;

const { TabPane } = Tabs;
const RNDContext = createDndContext(HTML5Backend);
const type = 'DragableBodyRow';

const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};


const productService = new ProductService();

const ProductEditForm = (props) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [pickerBGColor, setPickerBGColor] = useState("#36c")
  const [showActive, setShowActive] = useState(true)
  const [dynamicBrandList, setDynamicBrandList] = useState(false)
  const [categoryId, setCategoryId] = useState(props.product?.category?.id ?? undefined)
  const [listingData, setListingData] = useState(false)
  const [updateData, setUpdateData] = useState(false)
  const [columns, setColumns] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [rowData, setRowData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(1)
  const [skuList, setSkuList] = useState([]);
  const [form] = Form.useForm();
  useEffect(() => {
    if (props.product) {
      // setListingData(props.product.product_images)
      getColumns()
    }
  }, [])

  useEffect(() => {
    if (activeTab == "2") {
      const getImagesPayload = {
        type: "Product",
        id: props.product.id,
      }
      getMultipleImages(getImagesPayload)
    }
  }, [activeTab])

  useEffect(() => {
    if (updateData) {
      setUpdateData(false)
      updateListingSequence()
    }
  })

  useEffect(() => {
      setSkuList(skuList);
  });

  function callback(key) {
    setActiveTab(key)
  }

  const handleLoading = (value) => {
    setLoading(value)
  }

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const handleModal = (value, data) => {
    if (data) {
      setRowData(data)
    } else {
      setRowData(false)
    }
    setShowModal(value)
  }

  function handleCancel() {
    setButtonLoading(false);
    props.handleCancelModal();
  }
  
  function onChange() {
    let res = form.getFieldValue("items")
    setSkuList(res);
  }
  
  function handleSubmit(values) {
    if (values.items === undefined || values.items.length === 0) {
      openNotificationWithIcon("error", "Please Add Minimum 1 SKUs");
      setButtonLoading(false);
    } else {
      setButtonLoading(true)
      modalSubmitCombo({ ...values })
    }
  }

  function modalSubmitCombo(modalsProps) {
    const payload = {
      product_id: props.product.id,
      sub_product_list: modalsProps.items
    }
    productService.add_combo(payload).subscribe(
      (r) => {
        openNotificationWithIcon("success", "Created Combo Successfully");
        setButtonLoading(false);
        handleCancel();
      },
      (err) => {
        console.log(err);
        openNotificationWithIcon("error", err?.response?.errors?.[0] ?? 'API Error');
        setButtonLoading(false);
      }
    );

  }

  function getColumns() {
    let tempCol = []
    productImagesColumns.map(data => {
      tempCol.push(data)
    })
    tempCol.push({
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (
          <Button type="primary" onClick={() => handleModal(true, record)}>Edit</Button>
        )
      }
    })
    setColumns(tempCol)
  }

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = listingData[dragIndex];
      setListingData(
        update(listingData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
      setUpdateData(true)
    },
    [listingData],
  );

  const manager = useRef(RNDContext);

  function updateListingSequence() {
    let tempID = []
    listingData.map((data, index) => {
      tempID.push(data.id)
    })
    let tempPayload = {
      id: props.product.id,
      image_sequence: tempID
    }
    const payload = {
      ...tempPayload
    }
    const getImagesPayload = {
      type: "Product",
      id: props.product.id,
    }
    productService.update_product_image_sequence(payload).subscribe((r) => {
      openNotificationWithIcon("success", "Sequence Updated Successfully");
      getMultipleImages(getImagesPayload)
    },
      (err) => {
        console.log(err)
      })
  }

  const getMultipleImages = (payload) => {
    productService.get_multiple_image(payload).subscribe((r) => {
      let ActiveOnly = r.response.data.filter((e) => e.is_active)
      let tempSorted = ActiveOnly.sort((a, b) => {
        return a.sequence - b.sequence
      })
      let notActive = r.response.data.filter((e) => !e.is_active).sort((a, b) => {
        return a.sequence - b.sequence
      })
      let completeData = [...tempSorted, ...notActive]
      console.log("first", completeData[0]);
      let primaryImg = completeData[0].image_url
      props.handleFirstSeq(primaryImg)
      setListingData(completeData)
    },
      (err) => {
        console.log(err)
      })
  }

  const onImageAddUpdate = (data) => {
    setLoading(true)
    const payload = {
      ...data
    }
    const getImagesPayload = {
      type: "Product",
      id: props.product.id,
    }
    productService.update_single_image(payload).subscribe((r) => {
      openNotificationWithIcon("success", "Updated Successfully");
      getMultipleImages(getImagesPayload)
      handleModal(false)
      setLoading(false)
    },
      (err) => {
        setLoading(false)
        console.log(err)
      })
  }

  const operations = <Button type="primary" onClick={() => handleModal(true)}>Add Images</Button>;

  const [radioValues, setRadioValues] = useState({
    is_active: undefined,
    is_master: undefined,
    all_ds_update: false,
    in_stock: "default",
  });
  const [selectorItem, setSelectorItem] = useState({});
  const requiredValues = {};
  const uomList = [
    "kg",
    "pcs",
    "unit",
    "bundle",
    "packet",
    "dozen",
    "grams",
    "box",
    "litre",
    "ml"
  ];

  const uomListUnitQty = [
    "pcs",
    "unit",
    "packet",
    "dozen",
    "box"
  ];

  const unitList = [
    "kg",
    "pcs",
    "packets",
    "crates",
    "unit"
  ];

  const inputStyle = {
    textAlign: "right",
    marginRight: 20,
  };

  const commonInputWidth = 200;

  useEffect(() => {
    // console.log(props.product)
    props.product.product_category_id =
      props.product["sub_category"]?.id ?? props.product.product_category_id;
    props.product.all_ds_update = false;
    props.product.in_stock = "default";
    if (Array.isArray(props.product["local_names"])) {
      props.product["local_names"] = props.product["local_names"].join(",");
    }
    if(props.product?.product_company_id) {
      getDynamicBrand(props.product.product_company_id)
    }
    setShowDetail(true)
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['catalog_admin_third_party'].includes(data)) {
        setShowActive(false)
      }
    })
  }, []);

  function handleOk() {
    // console.log("payload",props.product);
    // if(props.product.product_category_id == undefined){
    //   props.product.product_category_id = props.product.sub_category.id 
    // }
    if (props.product.extended_stock_time === "") {
      props.product.extended_stock_time = null;
    }
    let notValidatedFields = isInputsValid();
    if (notValidatedFields.length > 0) {
      ErrorNotification(`Please fill all required* fields :  ${notValidatedFields.join(', ')}`);
      return;
    }
    // console.log(props.product["local_names"]);

    if (props.product["local_names"] != undefined) {
      if (Array.isArray(props.product["local_names"])) {
        props.product["local_names"] = props.product["local_names"].join(",");
      } else {
        props.product["local_names"] = [props.product["local_names"]];
      }
    }
    updateProductData();
  }

  function updateProductData() {
    setButtonLoading(true);
    if (props.imageUrl) {
      props.product.product_image = props.imageUrl;
    }
    // console.log(props.product)
    if(!uomListUnitQty.includes(props.product.uom)){
      delete props.product.unit_qty_in_pack
    }
    delete props.product.image_url;
    delete props.product.is_special_discount;
    props.product.by_city = localStorage.getItem('city') ?? ''
    productService.updateProduct(props.product).subscribe(
      (r) => {
        props.onProductUpdate(r.response.data);
        SuccessNotification("Details updated successfully.");
        setButtonLoading(false);
      },
      (err) => {
        setButtonLoading(false);
        ErrorNotification(err?.response?.errors?.[0] ?? "Failed to update product");
      },
    );
  }

  function isInputsValid() {
    let notValidFields = [];
    Object.keys(requiredValues).forEach((key) => {
      let value = requiredValues[key];
      if (value.includes("*") && props.product[key] == undefined) {
        // console.log("invalid", value)
        notValidFields.push(value.replace("*", ""));
      }
    });
    return notValidFields;
  }

  function changePickerColor(colors) {
    setPickerBGColor(colors)
    onInputChange('background_color', colors)
  }


  function onInputChange(key, value) {
    props.product[key] = value;
    // console.log(key, value)
    if (value === "") {
      if (key === "local_names") {
        props.product["local_names"] = [];
      } else {
        props.product[key] = undefined;
      }
    }
  }

  function onInputSelectorChange(keyName, v) {
    props.product[keyName] = v;
    setSelectorItem({
      ...selectorItem,
      keyName: v,
    });
  }

  const onChangeRadio = (keyName, e) => {
    props.product[keyName] = e.target.value;
    if (e.target.value) {
      props.product.secretly_viewed = false
    }
    setRadioValues({
      ...radioValues,
      [keyName]: e.target.value,
    });
  }

  const FZInput = ({ inputName, keyName, placeholder, isCaps = false, onChange, suffix }) => {
    requiredValues[keyName] = inputName;
    return (
      <div className="block" style={inputStyle}>
        {" "}
        {inputName} :
        <span style={{ paddingLeft: 10 }}>
          <Input
            placeholder={placeholder ?? inputName}
            suffix={suffix}
            style={{
              width: commonInputWidth,
              textTransform: isCaps ? "uppercase" : "none",
            }}
            onChange={(e) => onChange != null ? onChange(e) : onInputChange(keyName, e.target.value)}
            required={true}
            defaultValue={props.product[keyName] ?? undefined}
          />
        </span>
      </div>
    );
  };

  const FZInputText = ({ inputName, keyName }) => {
    return (
      <div className="block" style={inputStyle}>
        <span style={{ verticalAlign: "top" }}>{inputName} :</span>
        <span style={{ paddingLeft: 10 }}>
          <Input.TextArea
            placeholder={inputName}
            style={{ width: 250 }}
            onChange={(e) => onInputChange(keyName, e.target.value)}
            rows={4}
            cols={10}
            defaultValue={props.product[keyName] ?? undefined}
          />
        </span>
      </div>
    );
  };

  const FZCheckBox = ({ inputName, keyName }) => {
    return (
      <div className="block" style={inputStyle}>
        <span style={{ verticalAlign: "top" }}>{inputName} :</span>
        <span style={{ paddingLeft: 10, marginRight: 185 }}>
          <Checkbox
            placeholder={inputName}
            onChange={(e) => onInputChange(keyName, e.target.checked)}
            rows={4}
            cols={10}
            defaultChecked={props.product[keyName] ?? undefined}
          />
        </span>
      </div>
    );
  };

  const FZInputNumber = ({
    inputName,
    keyName,
    placeholder,
    disabled = false,
    min
  }) => {
    requiredValues[keyName] = inputName;
    return (
      <div className="block" style={inputStyle}>
        {" "}
        {inputName} :
        <span style={{ paddingLeft: 10 }}>
          <InputNumber
            placeholder={placeholder ?? inputName}
            style={{ width: commonInputWidth }}
            onChange={(v) => onInputChange(keyName, v)}
            defaultValue={props.product[keyName] ?? undefined}
            disabled={disabled}
            min={min}
          />
        </span>
      </div>
    );
  };

  const FZInputSelector = ({
    inputName,
    keyName,
    data,
    hasId = true,
    valueName,
  }) => {
    requiredValues[keyName] = inputName;
    return (
      <div className="block" style={inputStyle}>
        {inputName} :
        <span style={{ paddingLeft: 10 }}>
          <Select
            style={{ width: commonInputWidth, textAlign: "left" }}
            value={props.product[keyName]}
            defaultValue={
              hasId ? props.product[valueName]?.id : props.product[keyName]
            }
            placeholder={inputName}
            showSearch={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(v) => onInputSelectorChange(keyName, v)}
          >
            {data.map((item) =>
              hasId ? (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ) : (
                <Option key={item} value={item}>
                  {item}
                </Option>
              )
            )}
          </Select>
        </span>
      </div>
    );
  };
  const FZSubCategorySelector = ({ inputName, keyName, data, isFilter }) => {
    let tempData = data
    if (isFilter) {
      tempData = tempData.filter((e) => e.parent_id === isFilter)
    }
    requiredValues[keyName] = inputName;
    return (
      <div className="block" style={inputStyle}>
        {inputName} :
        <span style={{ paddingLeft: 10 }}>
          <Select
            style={{ width: commonInputWidth, textAlign: "left" }}
            value={props.product[keyName]}
            placeholder={inputName}
            showSearch={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(v) => onInputSelectorChange("product_category_id", v)}
          >
            {tempData.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </span>
      </div>
    );
  };

  const FZBrandSelector = () => {
    requiredValues["product_brand_id"] = "Brand Name*";
    return (dynamicBrandList ?
      <div className="block" style={inputStyle}>
        Brand Name* :
        <span style={{ paddingLeft: 10 }}>
          <Select
            style={{ width: commonInputWidth, textAlign: "left" }}
            value={props.product["product_brand_id"]}
            placeholder="Brand Name"
            showSearch={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(v) => onInputSelectorChange("product_brand_id", v)}
          >
            {dynamicBrandList.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.brand_name}
              </Option>
            ))}
          </Select>
        </span>
      </div> : null
    );
  };

  function getDynamicBrand(id) {
    if (id) {
      productService.get_dynamic_brands(id).subscribe(
        (r) => {
          setDynamicBrandList(r.response.data.product_brands_based_on_company);
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      props.product["product_company_id"] = undefined
    }
  }

  function onChangeCompany(e) {
    props.product["product_company_id"] = e
    props.product["product_brand_id"] = undefined
    setDynamicBrandList(false)
    getDynamicBrand(e)
  }

  const FZCompanySelector = () => {
    requiredValues["product_company_id"] = "Company Name*";
    return (
      <div className="block" style={inputStyle}>
        Company Name* :
        <span style={{ paddingLeft: 10 }}>
          <Select
            style={{ width: commonInputWidth, textAlign: "left" }}
            value={props.product["product_company_id"]}
            placeholder="Company Name"
            showSearch={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={onChangeCompany}
          >
            {props.companyList.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.company_name}
              </Option>
            ))}
          </Select>
        </span>
      </div>
    );
  };

  const FZProductTaxSlabSelector = () => {
    requiredValues["product_tax_slab_id"] = "Product Tax Slab*";
    return (
      <div className="block" style={inputStyle}>
        Product Tax Slab* :
        <span style={{ paddingLeft: 10 }}>
          <Select
            style={{ width: commonInputWidth, textAlign: "left" }}
            value={props.product["product_tax_slab_id"]}
            placeholder="Product Tax Slab"
            showSearch={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(v) => onInputSelectorChange("product_tax_slab_id", v)}
          >
            {props.productTaxSlab.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </span>
      </div>
    );
  };

  const FZProductCessSlabSelector = () => {
    requiredValues["product_cess_slab_id"] = "Product Cess Slab*";
    return (
      <div className="block" style={inputStyle}>
        Product Cess Slab* :
        <span style={{ paddingLeft: 10 }}>
          <Select
            style={{ width: commonInputWidth, textAlign: "left" }}
            value={props.product["product_cess_slab_id"]}
            placeholder="Product Cess Slab"
            showSearch={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(v) => onInputSelectorChange("product_cess_slab_id", v)}
          >
            {props.productCessSlab.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </span>
      </div>
    );
  };

  const getDisabledHours = (instock) => {
    var hours = [];
    if (!instock) {
      for (var i = 0; i <= moment().hour(); i++) {
        hours.push(i);
      }
      // hours.push(23)
    }
    return hours;
  }

  const getDisabledMinutes = (e, instock) => {
    var minutes = [];
    if (!instock && moment().hour() >= 23) {
      minutes.push(0, 30)
    }
    if (!instock && e <= moment().hour()) {
      for (var i = 0; i < moment().hour(); i++) {
        minutes.push(i);
      }
    }
    return minutes;
  }


  const FZTimepicker = ({ inputName, keyName, placeholder, onChange }) => {
    requiredValues[keyName] = inputName;
    return (
      <div className="block" style={inputStyle}>
        {inputName} :
        <span style={{ paddingLeft: 10 }}>
          <TimePicker
            placeholder="Select Time"
            format="HH:mm"
            use24Hours
            minuteStep={30}
            showNow={false}
            style={{ width: commonInputWidth }}
            disabledHours={() => getDisabledHours(props.product.in_stock)}
            disabledMinutes={(e) => getDisabledMinutes(e, props.product.in_stock)}
            value={props.product.extended_stock_time === undefined ?
              null : moment(props.product.extended_stock_time, "HH:mm")}
            onSelect={(value) => {
              const timeString = moment(value).format("HH:mm");
              onInputSelectorChange("extended_stock_time", timeString)
            }} />
        </span>
      </div>
    );
  };

  const FZInputRadioSelector = ({ inputName, keyName, disabled }) => {
    requiredValues[keyName] = inputName;
    return (
      <div className="block" style={inputStyle}>
        {inputName} :
        <span style={{ paddingLeft: 10 }}>
          <Radio.Group
            style={{ width: commonInputWidth, textAlign: "left" }}
            value={radioValues[keyName] ?? props.product[keyName]}
            onChange={(e) => onChangeRadio(keyName, e)}
            disabled={disabled}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </span>
      </div>
    );
  };

  const FZInputRadioSelectorStock = ({ inputName, keyName }) => {
    requiredValues[keyName] = inputName;
    return (
      <div className="block" style={inputStyle}>
        {inputName} :
        <span style={{ paddingLeft: 10 }}>
          <Radio.Group
            disabled={true}
            style={{ textAlign: "left" }}
            value={radioValues[keyName] ?? props.product[keyName]}
            onChange={(e) => {
              props.product[keyName] = e.target.value;
              setRadioValues({
                ...radioValues,
                [keyName]: e.target.value,
              });
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
            <Radio value="default">Default</Radio>
          </Radio.Group>
        </span>
      </div>
    );
  };

  function handleClick({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  const uploadButton = (
    <div>
      {props.imgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleChangeCategory = (e) => {
    props.product['product_category_id'] = undefined
    setCategoryId(e)
  }

  return (
    <div>
      <Tabs className="custom-tabs" defaultActiveKey="1" onChange={callback} tabBarExtraContent={activeTab == 2 ? operations : null}>
        <TabPane tab="Details" key="1">
          {props && showDetail ? (
            <Row>
              <Col span={12} style={{ borderRight: "1px solid #f0f0f0" }}>
                <FZCompanySelector />
                <FZBrandSelector />
                <FZInput inputName="Product Name*" keyName="name" />
                {/*<FZInput inputName="SKU Code*" keyName="sku" isCaps={true} />*/}
                <FZInput inputName="Variant Name" keyName="variant_name" />
                <FZInput inputName="Description*" keyName="description" />
                {/* <div className="block" style={{ display: "flex", marginLeft: 66 }}>
                  Upload Image :
                  <span style={{ paddingLeft: 10 }}>
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      customRequest={handleClick}
                      onChange={props.handleChange}
                    >
                      {props.imageUrl ? (
                        <img
                          src={props.imageUrl}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </span>
                </div> */}
                {/* <FZInput inputName="Image URL*" keyName="image_url" /> */}
                <FZInputSelector
                  inputName="uom*"
                  keyName="uom"
                  data={uomList}
                  hasId={false}
                />
                {uomListUnitQty.includes(props.product.uom) ?
                  <FZInputNumber inputName="Unit Qty In Pack*" keyName="unit_qty_in_pack" />
                : null}
                <FZInput
                  inputName="Pack Size*"
                  keyName="pack_size"
                  placeholder="eg: 250 g"
                />
                <FZInput
                  inputName="Pack Size Desc"
                  keyName="pack_size_desc"
                  placeholder="eg: Approx 600-800g"
                />
                <FZInputRadioSelector inputName="Is Active*" keyName="is_active" disabled={!showActive} />
                {!props.product.is_active ?
                  <FZCheckBox inputName="Limited View" keyName="secretly_viewed" />
                  : null}
                <FZInputNumber inputName="moq*" keyName="moq" disabled={true} />
                <FZInputRadioSelectorStock
                  inputName="In Stock"
                  keyName="in_stock"
                />
                {props.product.in_stock !== 'default' ?
                  <>
                    <FZTimepicker
                      inputName="Extended Stock Time*"
                      keyName="extended_stock_time"
                      placeholder="Extended Stock Time"
                      onChange={(e) => {
                        props.product['extended_stock_time'] = e.target.value;
                      }}
                    />
                    <FZInputText inputName="Reason For Stock Time Change" keyName="comment" />
                  </> : null}
                <FZProductTaxSlabSelector />
                <FZProductCessSlabSelector />
                {!props.isCombo ?
                  <>
                    <FZInputNumber
                      inputName="Qty For 10k Store Target"
                      keyName="qty_for_10k"
                      placeholder="Qty For 10k Store Target"
                    />
                    <FZInputSelector
                      inputName="Procurement Unit*"
                      keyName="procurement_unit"
                      data={unitList}
                      hasId={false}
                    />
                    <FZInputNumber
                      inputName="Lot Size*"
                      keyName="lot_size"
                      placeholder="lot_size"
                      min={1}
                    />
                    <FZInputNumber
                      inputName="Weights In Kgs Factor*"
                      keyName="weight_in_kgs"
                      placeholder="weight_in_kgs"
                    />
                    {/* <FZInputRadioSelector inputName="Is Po Critical*" keyName="is_po_critical" />
              <FZInputRadioSelector inputName="Is Inventory Enabled*" keyName="is_zapper_enabled" />
              <FZInputRadioSelector inputName="Allow Unlimited Stock" keyName="allow_unlimited_stock" />
              {!props.product.allow_unlimited_stock ? 
                <FZInputRadioSelector inputName="Include Last GRN" keyName="include_last_grn" />
              : null}   */}
                  </>
                  : null}
                <FRZColorPicker
                  label="Background Color"
                  value={props.product['background_color']}
                  pickerColor={pickerBGColor}
                  changePickerColor={changePickerColor}
                  setPickerColor={setPickerBGColor}
                  columnSpan={10}
                />
                <FZInputText
                  inputName="Sourcing Message"
                  keyName="sourcing_message"
                />
                {/* <FZInput
              inputName="Background Color"
              keyName="background_color"
              placeholder="Background Color"
            /> */}
                <FZInputText inputName="Seo Title" keyName="seo_title" />
              </Col>

              <Col span={12}>
                <FZInputNumber
                  inputName="Max Quantity*"
                  keyName="max_qty"
                  placeholder="Max Qty"
                />
                {/* productCategories */}
                <div className="block" style={inputStyle}>
                  Category :
                  <span style={{ paddingLeft: 10 }}>
                    <Select
                      placeholder="Category Name"
                      value={categoryId}
                      showSearch={true}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ width: 200, textAlign: "left" }}
                      onChange={(e) => handleChangeCategory(e)}>
                      {props.productCategories.map((data, index) => {
                        return (
                          <Option key={index} value={data.id}>{data.name}</Option>
                        )
                      })}
                    </Select>
                  </span>
                </div>
                <FZSubCategorySelector
                  disabled={!categoryId}
                  inputName="Sub-Category*"
                  keyName="product_category_id"
                  data={props.subCategoryList}
                  isFilter={categoryId}
                />
                <FZInputNumber
                  inputName="Multiple Qty"
                  keyName="multiple_quantity"
                  placeholder="In number"
                />
                <FZInputNumber
                  inputName="Conv. Factor*"
                  keyName="conversion_factor"
                  placeholder="eg: 0.25 for pack size of 250g"
                />
                <FZInputNumber
                  inputName="VnF Conv. Factor"
                  keyName="vnf_conversion_factor"
                  placeholder="VnF Conversion Factor"
                  disabled={props.isCombo ? false : true}
                />
                {/* <FZInputNumber inputName="mrp" keyName="mrp" />
            <FZInputNumber inputName="sp" keyName="sp" /> */}
                <FZInputRadioSelector
                  inputName="Update All DS Prices"
                  keyName="all_ds_update"
                />
                <FZInputNumber inputName="moq_mrp*" keyName="moq_mrp" />
                <FZInput inputName="moq_sp*" keyName="moq_sp" />
                <FZInput
                  inputName="Local Names"
                  keyName="local_names"
                  placeholder="Comma separated values"
                />
                <FZInput
                  inputName="Sequence"
                  keyName="sequence"
                  placeholder="Order of display in app"
                />
                <FZInputRadioSelector inputName="Is Master" keyName="is_master" />
                <FZInputNumber inputName="CGST %" keyName="cgst_percentage" />
                <FZInputNumber inputName="SGST %" keyName="sgst_percentage" />
                <FZInputText inputName="Seo Keywords" keyName="seo_keywords" />
                <FZInputText inputName="Seo Description" keyName="seo_description" />
              </Col>
              <div
                style={{ textAlign: "center", width: "100%", marginTop: "20px" }}
              >
                <Button
                  key="submit"
                  type="primary"
                  style={{ width: "100px" }}
                  loading={buttonLoading}
                  onClick={handleOk}
                >
                  Save
                </Button>
              </div>
            </Row>
          ) : null}
        </TabPane>
        <TabPane tab="Add/Edit Images" key="2">
          {listingData ?
            listingData.length ?
              <DndProvider manager={manager.current.dragDropManager}>
                <Table dataSource={listingData} columns={columns}
                  rowKey="id"
                  pagination={false}
                  components={components} onRow={(record, index) => ({
                    index,
                    moveRow,
                  })} />
              </DndProvider>
              :
              <div className="no-data">No Data Available</div>
            : listingData === null ?
              <div className="no-data">No Data Available</div>
              : <div className="spin-center">
                <Spin tip="...Loading" />
              </div>}

        </TabPane>
        {props.product.combo_sub_products.length === 0 ?
          <TabPane tab="Create Combo" key="3">
            <Form form={form}
              {...formItemLayout}
              layout="horizontal"
              name="form" >
              <Form.List name="items" >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <div className="refund-inline" key={index}>
                        <Form.Item name={[field.name, 'id']} fieldKey={[field.fieldKey, 'id']}
                          rules={[{ required: true, message: `Please select SKU!` }
                          ]}>
                          <Select className="frz-w-330" placeholder="Select SKU" 
                            onChange={onChange} showSearch optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.skuName.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {props.productList.map((data) => {
                              return (
                                <Option key={data.id} value={data.id}
                                  disabled={skuList?.map(e => e?.id).includes(data.id)} skuName={data.name}>
                                  {data.name} - ({data.pack_size})
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        <Form.Item name={[field.name, 'qty']} fieldKey={[field.fieldKey, 'qty']}
                          rules={[{ required: true, message: `Missing Qty!` }]}>
                          <InputNumber placeholder="Qty" className="frz-w-70" required={true}
                            onChange={onChange} />
                        </Form.Item>
                        <Button type="primary" icon={<DeleteOutlined />}
                          className="frz-width-36p frz-p-0 frz-tp-2"
                          shape="round" onClick={() => {
                            remove(field.name)
                            let response = form.getFieldValue("items")
                            setSkuList(response) }}>
                        </Button>
                      </div>
                    ))}
                    <Button type="primary" icon={<PlusCircleOutlined />}
                      className="frz-width-104p" onClick={() => add()} >
                      Add Sku
                    </Button>
                  </>
                )}
              </Form.List>
                <Button key="submit" type="primary" className="frz-lm-500 frz-width-104p"
                  disabled={!skuList.length}
                  loading={buttonLoading} onClick={() => {
                    form
                      .validateFields()
                      .then(values => {
                        handleSubmit(values)
                      })
                      .catch(info => {
                      })}}>
                  Submit
                </Button>           
            </Form>
          </TabPane> : null}
      </Tabs>
      {showModal ?
        <ModalImage showModal={showModal} handleModal={handleModal} rowData={rowData}
          handleChange={props.handleChange} imageUrl={props.imageUrl} uploadButton={uploadButton}
          handleImageUrl={props.handleImageUrl} onImageAddUpdate={onImageAddUpdate}
          loading={loading} handleLoading={handleLoading} productId={props.product.id}
          getMultipleImages={getMultipleImages} />
        : null}
    </div>
  );
};

export default ProductEditForm;
