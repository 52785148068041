import { Http } from '../_modules/http'

export class DeliveryApp {

  http = new Http()

  get_bills(payload) {
    const url = process.env.REACT_APP_RIDER_URL +
      'admin/bills/list?page=' + payload.page +
      '&from_date=' + payload.from_date + '&to_date=' + payload.to_date +
      '&rider_id=' + payload.rider_id + '&partner_id=' + payload.partner_id +
      '&status=' + payload.status + '&store_code=' + payload.store_code;
    let headers = { 'authorization': process.env.REACT_APP_RIDER_TOKEN, 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  delivery_partner() {
    const url = process.env.REACT_APP_RIDER_URL + 'admin/delivery-partners'
    let headers = { 'authorization': process.env.REACT_APP_RIDER_TOKEN, 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  update_bills(payload) {
    const url = process.env.REACT_APP_RIDER_URL + 'admin/bills/status'
    let headers = { 'authorization': process.env.REACT_APP_RIDER_TOKEN, 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  download_reports_delivery(payload) {
    const url = process.env.REACT_APP_RIDER_URL + payload.url + payload.store + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = { 'authorization': process.env.REACT_APP_RIDER_TOKEN, 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_ims(payload) {
    const url = process.env.REACT_APP_RIDER_URL +
      'admin/payplan/' + `${localStorage.getItem('city') ?? ''}`
      + '?store_code=' + payload.store_code + '&executive_type=' + payload.executive_type
      + '&job_type=' + payload.job_type + '&executive_shift=' + payload.executive_shift
    let headers = { 'authorization': process.env.REACT_APP_RIDER_TOKEN, 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }
  // v1/admin/payplan

  update_ims(payload) {
    const url = process.env.REACT_APP_RIDER_URL + 'admin/payplan'
    let headers = { 'authorization': process.env.REACT_APP_RIDER_TOKEN, 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  get_ims_shift() {
    const url = process.env.REACT_APP_RIDER_URL + 'rider/shift-time'
    let headers = { 'authorization': process.env.REACT_APP_RIDER_TOKEN, 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }
}
