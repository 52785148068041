import React, { useEffect, useState, useCallback } from "react";
import { OPCService } from '../../_services/master/opc_sequence';
import { Row, Card, Col, Button, Table, Spin, Input } from "antd";
import { opcColumns } from '../../components/master/opc/opc_columns';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { openNotificationWithIcon } from "../../_modules/notification";

const opc_service = new OPCService()
const type = 'DraggableBodyRow';

const initialState = {
  spinner: false,
  err: false
}

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

function OPCSequence() {
  const [tableData, setTableData] = useState(false)
  const [state, setState] = useState(initialState)
  const [updateSeq, setUpdateSeq] = useState(false)

  useEffect(() => {
    getTableList()
  }, [])

  function getTableList() {
    setState(prevState => ({ ...prevState, spinner: true }))
    opc_service.get_opc_list().subscribe((r) => {
      setState(prevState => ({ ...prevState, spinner: false }))
      setTableData(r.response.data)
    }, (err) => {
      setState(prevState => ({ ...prevState, spinner: false, err: true }))
      setTableData([])
    })
  }

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = tableData[dragIndex];
      setTableData(
        update(tableData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
      setUpdateSeq(true)
    },
    [tableData],
  );

  useEffect(() => {
    if (updateSeq) {
      setUpdateSeq(false)
      updateSequence(tableData)
    }
  }, [updateSeq])

  function updateSequence(data) {
    setState(prevState => ({ ...prevState, spinner: true }))
    const payload = {
      sequence: data.map(a => a.id)
    }
    opc_service.opc_update_sequence(payload).subscribe((r) => {
      getTableList()
      openNotificationWithIcon('success', 'Updated Successfully')
    },
      (err) => {
        console.log(err);
        openNotificationWithIcon('error', err?.response?.errors?.[0] ?? "API Error")
      }
    );
  }

  return (
    <div className="layer-nofilter">
      {tableData ?
        <div>
          {tableData?.length > 0 && !state.err ?
            <Spin spinning={state.spinner}>
              <Row>
                <Col span={24}>
                  <Card className="frz-mt-5">
                    <DndProvider backend={HTML5Backend}>
                      <Table dataSource={tableData} pagination={false} scroll={{ y: 'calc(100vh - 146px)' }} 
                        rowKey={'id'} columns={opcColumns} onRow={(record, index) => ({
                          index,
                          moveRow,
                        })} components={components}/>
                    </DndProvider>
                  </Card>
                </Col>
              </Row>
            </Spin>
          : <div className="no-data">{state.err ? 'Backend API System Down' : 'No Data Available'}</div>}
        </div>
      :
        <div className="spin-center"><Spin tip="...Loading" /></div>
      }
    </div>
  )
}

export default OPCSequence