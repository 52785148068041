import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Radio, Select } from 'antd';
import { OrderService } from '../../_services/orders'
import { openNotificationWithIcon } from '../../_modules/notification';
import { tailLayout } from '../../_modules/controllayout'
import FRZSelect from '../../_controls/FRZSelect'
import FRZRadioButton from '../../_controls/FRZRadioButton';
const order_service = new OrderService()
const { Option } = Select;

const ModalReshedule = ({ handleResheduleModal, resheduleModal, onsubmit, orderDetail, loading }) => {
  const [form] = Form.useForm();

  const [deliverySlots, setDeliverySlots] = useState([])
  const [modalSlot, setModalSlot] = useState(undefined)
  const [timeData, setTimeData] = useState([])
  const [deliveryDate, setDeliveryDate] = useState(undefined)

  useEffect(() => {
    getDeliverySlots()
  }, [])

  useEffect(() => {
    form.setFieldsValue({ slot: undefined })
    setModalSlot(undefined)
    const getTimeSlot = () => {
      for (var i = 0; i < deliverySlots.length; i++) {
        if (deliverySlots[i].date === deliveryDate) {
          setTimeData(deliverySlots[i].time_slot)
          break
        }
      }
    }
    getTimeSlot();
  }, [deliveryDate])

  const onCreate = (value) => {
    onsubmit(value)
  }

  const onCancel = () => {
    handleResheduleModal(false)
  }

  // GET API FOR DELIVERY DATE AND TIME SLOT
  function getDeliverySlots() {
    const payload = {
      delivery_id: orderDetail.delivery.id,
    }
    order_service.get_delivery_slots(payload).subscribe((r) => {
      setDeliverySlots(r.response.data)
    },
      (err) => {
        openNotificationWithIcon('error', err.response.errors[0])
        console.log(err)
      })
  }

  return (
    <Modal
      visible={resheduleModal}
      title="Reshedule Delivery"
      okText="Submit"
      cancelText="Cancel"
      okButtonProps={{ loading: loading }}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="horizontal"
        name="form"
        {...tailLayout}
      // initialValues={{
      //   modifier: 'public',
      // }}
      >

        <Form.Item
          {...tailLayout}
          name="delivery_date"
          label="Delivery Date"
          rules={true ? [{
            required: true,
            message: `Please input the value of day!`,
          }
          ] : []}>
          <Select style={{ width: 190 }} value={deliveryDate}
            placeholder="Select Date"
            onChange={(e) => setDeliveryDate(e)}>
            {deliverySlots.map((data, index) => {
              return (
                (data.time_slot.length > 0 ?
                  <Option key={index} value={data.date}>{data.date}</Option>
                  : null)
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          {...tailLayout}
          name="delivery_slot"
          label="Delivery Slot"
          rules={true ? [{
            required: true,
            message: `Please input the value of slot!`,
          }
          ] : []}>
          <Select style={{ width: 190 }} value={modalSlot}
            placeholder="Select Time"
            disabled={!timeData.length}
            onChange={(e) => setModalSlot(e)}>
            {timeData.length > 0 ?
              (timeData.filter(e => e.is_active).map(data => {
                return (
                  <Option key={data.id} value={data.id}
                    disabled={!data.is_active}>
                    {data?.slot_string ?? data.from_time + ' to ' + data.to_time + ' ' + data.am_pm}
                  </Option>
                )
              }))
              : null}
          </Select>
        </Form.Item>

        <FRZRadioButton name="rider_payment" label="Rider Payment" isRequired={true} />

      </Form>
    </Modal>
  )
}

export default ModalReshedule
