import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Pagination } from 'antd';
import { ProductTagService } from '../../_services/product_tag';
import MainTable from '../../components/antd/table';
import { tagColumns } from '../../components/antd/columns/master/product';
import NestedTable from '../../components/master/product_tag/nested_table';
import TagModal from '../../components/master/product_tag/tag_modal';
import AddProductModal from '../../components/master/product_tag/add_product_modal';
import { BannersService } from '../../_services/banners';
import { openNotificationWithIcon } from '../../_modules/notification';

const banner_service = new BannersService()

const tag_service = new ProductTagService()

function ProductTag() {
  const modalRef = useRef(null);
  const modalRefProducts = useRef(null);
  const [listingData, setListingData] = useState(false)
  const [productData, setProductData] = useState(false)
  const [tagType, setTagType] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [mode, setMode] = useState(undefined)
  const [state, setState] = useState({});
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(false)
  const [productList, setProductList] = useState(false);

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getListing()
    }
  })

  useEffect(() => {
    getTagType()
    getColumns()
    getProductList()
  }, [])

  function getListing() {
    const payload = {
      page: current
    }
    setSpinEnable(true)
    setListingData([])
    tag_service.get_product_tags(payload).subscribe((r) => {
      setState(prevState => ({ ...prevState }))
      setSpinEnable(false)
      setTotal(r.response.meta.total_pages * 50);
      setListingData(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getTagType() {
    tag_service.get_product_tag_type().subscribe((r) => {
      setTagType(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getProductList() {
    banner_service.get_product_list().subscribe((r) => {
      const obj =[];
      r.response.data.forEach(function (elem) {
        obj.push({
          id: elem.id,
          name: elem.name + ' - ' + elem.sku
        });
      });
      setProductList(obj)
      setProductData(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getColumns() {
    tagColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: "Tag Image",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <img src={record.product_tag_image_url} height={80} />
        );
      },
    },
      {
        title: 'Edit',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (
            <Button type="primary" onClick={() => showModal(record, 'Edit Tags')}>Edit Tags</Button>
          )
        }
      });
    setColumns(columns)
  }

  function expandedRow(record) {
    return <>
      <Button type="primary" className="frz-bm-20" onClick={() => showModalProducts(record, 'Add Products')}>
        Add Products
      </Button>
      <NestedTable data={record.id} />
    </>
  }

  function showModal(data, type) {
    setMode(type)
    modalRef.current.showModal(type, data)
  }

  function showModalProducts(data, type) {
    modalRefProducts.current.showModal(type, data)
  }

  function modalSubmit(payloadData, id) {
    if (!payloadData.background_color) {
      openNotificationWithIcon('error', "Please Select Background Color")
      modalRef.current.stopLoading()
    } else {
      if (mode === 'Create Tags') {
        if (!payloadData.product_tag_image) {
          openNotificationWithIcon('error', "Please upload image")
          modalRef.current.stopLoading()
          return
        }
      }
      const payload = {
        id: id ? id : undefined,
        body: { ...payloadData }
      }

      if (mode === 'Edit Tags') {
        tag_service.update_tags(payload).subscribe((r) => {
          openNotificationWithIcon('success', 'Edited Successfully')
          getListing()
          modalRef.current.handleCancel()
        },
          (err) => {
            console.log(err)
            modalRef.current.stopLoading()
            openNotificationWithIcon('error', err.response.errors?.[0])
          })
      } else {
        tag_service.create_tags(payload).subscribe((r) => {
          openNotificationWithIcon('success', 'Created Successfully')
          getListing()
          modalRef.current.handleCancel()
        },
          (err) => {
            console.log(err)
            modalRef.current.stopLoading()
            openNotificationWithIcon('error', err.response.errors?.[0])
          })
      }
    }
  }


  function pagination(page) {
    setCurrent(page)
    setListingData([])
    setRenderData(false)
  }

  function modalSubmitProduct(payloadData) {

    tag_service.add_products(payloadData).subscribe((r) => {
      openNotificationWithIcon('success', 'Added Successfully')
      getListing()
      modalRefProducts.current.handleCancel()
    },
      (err) => {
        console.log(err)
        modalRefProducts.current.stopLoading()
        openNotificationWithIcon('error', err.response.errors?.[0])
      })
  }

  return (
    <div className="layer-nofilter">
      {listingData && tagType ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Create Tags')} className="frz-width-150p frz-m-10"
                  type="primary">Create Tags</Button>
              </Col>
            </Card>
          </Row>
          {listingData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={listingData} columns={columns} expandedRow={expandedRow} />
                <Pagination className="frz-tm-10 frz-bm-10" current={current}
                  pageSize={50} total={total} onChange={pagination} showSizeChanger={false} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <TagModal modalSubmit={modalSubmit} ref={modalRef}
            tagType={tagType} />
          <AddProductModal modalSubmit={modalSubmitProduct} ref={modalRefProducts}
            productData={productData} productList={productList} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default ProductTag
