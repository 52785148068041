import React, { forwardRef, useState, useEffect, useRef } from "react";
import { Modal, Button, Table, notification, Spin, Space, Input } from "antd";

import { DemandForecastingService } from "../../../_services/demand_forecasting";
import { demandForecastingColumns } from "../../antd/columns/master/demand_forecasting";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

const demand_forecasting_service = new DemandForecastingService();

const EligibleSkusModal = forwardRef((props, _) => {
  const [renderData, setRenderData] = useState(false);
  const [skusData, setSkusData] = useState(false);
  const [columns, setColumns] = useState([]);
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [submitSkusDataEnabled, setSubmitSkusDataEnabled] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  useEffect(() => {
    if (!renderData) {
      setRenderData(true);
      getEligibleSkus();
    }
  });

  function getEligibleSkus() {
    demand_forecasting_service.put_display_eligible_skus().subscribe(
      (r) => {
        setSkusData(r.response.data);
        // demandForecastingColumns.map((data) => {
        //   columns.push(data);
        // });
        setColumns([
          {
            title: "Cluster Name",
            dataIndex: "cluster_name",
            key: "cluster_name",
            align: "center",
            ...getColumnSearchProps('cluster_name')  
          },
          {
            title: "Product Name",
            dataIndex: "product_name",
            key: "product_name",
            align: "center",
            ...getColumnSearchProps('product_name')
          },
          {
            title: "Cluster Level Closing",
            dataIndex: "cluster_level_closing",
            key: "cluster_level_closing",
            align: "center",
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.cluster_level_closing - b.cluster_level_closing,  
          },
          {
            title: "Required Order At Cluster",
            dataIndex: "reqd_order_at_cluster",
            key: "reqd_order_at_cluster",
            align: "center",
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.reqd_order_at_cluster - b.reqd_order_at_cluster,  
          },
          {
            title: "Society Requirement",
            dataIndex: "society_requirement",
            key: "society_requirement",
            align: "center",
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.society_requirement - b.society_requirement,  
          }
        ]);
        setSelectedSkus([]);
      },
      (err) => {
        console.log(err);
        openNotificationWithIcon("error", "Error! Please try again.");
      }
    );
  }
  function openNotificationWithIcon(type, msg) {
    notification[type]({
      message: type.toUpperCase(),
      duration: 3,
      description: msg,
    });
  }

  const rowSelection = {
    selectedRows: selectedSkus,
    onChange: (_, selectedRows) => onSelectChange(_, selectedRows),
  };

  const onSelectChange = (_, selectedRows) => {
    setSelectedSkus(selectedRows);
  };

  function submitSkusData() {
    if (selectedSkus.length <= 0) {
      openNotificationWithIcon("info", "Please select products first.");
      return;
    }
    setSubmitSkusDataEnabled(true);
    demand_forecasting_service.post_make_order_zero(selectedSkus).subscribe(
      (r) => {
        setSubmitSkusDataEnabled(false);
        props.handleCancelModal(r.response.data);
      },
      (err) => {
        console.log(err);
        setSubmitSkusDataEnabled(false);

        openNotificationWithIcon("error", "Error! Please try again.");
      }
    );
  }

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: text =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    }
  };

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  return (
    <Modal
      visible={true}
      onCancel={() => props.handleCancelModal()} // no parameters should be sent so ()=>{}
      title="Enabled SKUs"
      width={750}
      footer={[
        <Button key="back" onClick={() => props.handleCancelModal()}>
          Close
        </Button>,
        <Button
          key="submitSkus"
          type="primary"
          onClick={submitSkusData}
          loading={submitSkusDataEnabled}
        >
          Submit
        </Button>,
      ]}
    >
      {skusData ? (
        <div>
          <Table
            columns={columns}
            rowSelection={rowSelection}
            dataSource={skusData}
            pagination={false}
            rowKey={(record) => record._id.$oid}
          />
        </div>
      ) : (
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      )}
    </Modal>
  );
});

export default EligibleSkusModal;
