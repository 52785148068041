import React, { useEffect, useState, useRef } from "react";
import { SegmentCreationService } from '../../_services/segments/segment_creation';
import { ReloadOutlined } from "@ant-design/icons";
import { Row, Card, Col, Button, Table, Pagination, Spin, Input } from "antd";
import { segmentColumns } from '../../components/segments/columns';
import CreateSegments from "../../components/segments/create_segments";

const segment_service = new SegmentCreationService()

const initialFilters = {
  page: 1,
  segmentSearch: null,
  isSearch: true
}

const initialState = {
  btnDisable: true,
  spinner: false,
  err: false
}

const initialExternalData = {
  metrics: false
}

function SegmentCreation() {
  const modalRef = useRef(null);
  const [tableData, setTableData] = useState(false)
  const [externalData, setExternalData] = useState(initialExternalData)
  const [filters, setFilters] = useState(initialFilters)
  const [state, setState] = useState(initialState)

  useEffect(() => {
    filters.isSearch && getTableList()
  }, [filters])

  useEffect(() => {
    getSegmentMetrics()
  }, [])

  function getTableList() {
    setState(prevState => ({ ...prevState, spinner: true }))
    let urlParam = `?page=${filters.page}&by_name=${filters.segmentSearch ?? ''}&by_city=${localStorage.getItem('cityid') ?? ''}`
    segment_service.get_segments(urlParam).subscribe((r) => {
      setState(prevState => ({ ...prevState, spinner: false, total: r.response.meta.total_pages * 50 }))
      setTableData(r.response.segments)
    }, (err) => {
      setState(prevState => ({ ...prevState, spinner: false, err: true }))
      setTableData([])
    })
  }

  function getSegmentMetrics() {
    segment_service.get_segment_metrics().subscribe((r) => {
      setExternalData(prevState => ({ ...prevState, metrics: r.response.fields }))
    }, (err) => {
      setState(prevState => ({ ...prevState, err: true }))
      setExternalData(prevState => ({ ...prevState, metrics: [] }))
    })
  }

  function onChangeFilters(value, type, searchNow) {
    setFilters(prevState => ({ ...prevState, [type]: value, isSearch: searchNow }))
    setState(prevState => ({ ...prevState, btnDisable: false }))
  }

  return (
    <div className="layer-nofilter">
      {tableData && externalData.metrics ?
        <div>
          {!state.err ? 
            <Row>
              <Col span={24}>
                <Card>
                  <Input placeholder="Search Segment" onChange={(e) => onChangeFilters(e.target.value, "segmentSearch", false)}
                    value={filters.segmentSearch} className="frz-w-200 frz-m-10"/>
                  <Button disabled={state.btnDisable} onClick={() => {onChangeFilters(1, 'page', true)}}
                    className="frz-btn" type="primary">Search</Button>
                  <Button disabled={state.btnDisable} onClick={() => {setFilters(initialFilters); setState(initialState)}} 
                    type="primary" className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
                  <span className="fl-right">
                    <Button onClick={() => modalRef.current.showModal(true, 'Create Segments')} className="frz-dwn"
                      type="primary">Create Segments</Button>
                  </span>
                </Card>
              </Col>
            </Row>
          : null}
          {tableData.length > 0 && !state.err ?
            <Spin spinning={state.spinner}>
              <Row>
                <Col span={24}>
                  <Card className="frz-mt-5">
                    <Table dataSource={tableData} pagination={false} scroll={{ y: 'calc(100vh - 278px)' }} rowKey={'id'}
                      columns={segmentColumns((rowdata) => modalRef.current.showModal(rowdata, 'Edit Segments'))} />
                  </Card>
                  <Pagination current={filters.page} pageSize={50} total={state.total} className="frz-mt-5"
                    onChange={(e) => onChangeFilters(e, 'page', true)} showSizeChanger={false} />
                </Col>
              </Row>
            </Spin>
            :
            <div className="no-data">{state.err ? 'Backend API System Down' : 'No Data Available'}</div>
          }
          <CreateSegments ref={modalRef} metrics={externalData.metrics} segment_service={segment_service}
            getTableList={getTableList}/>
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default SegmentCreation