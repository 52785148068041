import React, { useEffect, useState } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber, Select,
         Pagination, DatePicker, Popconfirm, notification, Table } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {StoreService} from '../../_services/store';
import MainTable from '../../components/antd/table';
import Search from '../../components/antd/search';
import {marginColumns} from '../../components/antd/columns/store';
import moment from 'moment';

const store_service = new StoreService()

function DarkstoreMargins(){
  const [rankingData, setRankingData] = useState(false)
  const [listingData, setListingData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [state, setState] = useState({valueBySource: undefined, valueByDeliveryDate: new Date().toJSON().slice(0,10), date: ''});
  const { valueBySource, valueByDeliveryDate, date } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getListing()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  useEffect(()=>{
    if(listingData){
    addRankingData()
  }
  },[listingData])

  function getListing(){
    setSpinEnable(true)
    setListingData([])
    const payload = {
      page: current,
      darkstore_id: !valueBySource ? '' : valueBySource,
      date: !valueByDeliveryDate ? '' : valueByDeliveryDate,
    }

    store_service.darkstore_rankings(payload).subscribe((r)=>{
      setRankingData(r.response.data)

      //if get success in response of get_reanking then only fetch the main data
      if(r.response.status==="success"){
        store_service.darkstore_margins(payload).subscribe((r) => {
          //   setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
          setSpinEnable(false)
          setListingData(r.response.data)
        },
        (err)=>{
          //if error then update the data with empty array
          setRankingData([]);
          console.log(err);
        })
      }
    },
    (err)=>{
      setListingData([])
      console.log(err)
    })
  }

  //function for merging objects of two array i.e array of ranking and listing data 
  function addRankingData(){
    if(listingData!==false && listingData.length>0){
      listingData.map((list)=>{
        let itemIndex = rankingData.findIndex(rank=>rank.darkstore_code===list.dk_code);
        Object.assign(list,rankingData[itemIndex])
      })
  }
}


  function getColumns(){
    marginColumns.map(data => {
      columns.push(data)
    })
    setColumns(columns)
  }

  function pagination(page) {
    setCurrent(page)
    setListingData([])
    setRenderData(false)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function onChangeDate(date, dateString) {
    setState(prevState =>({...prevState, date: date, valueByDeliveryDate: dateString}))
    setDisable(false)
    setCurrent(1)
  }

  function reset() {
    setState({valueBySource: undefined, valueByDeliveryDate: new Date().toJSON().slice(0,10), date: ''})
    setDisable(true)
    setCurrent(1)
    setListingData([])
    setRenderData(false)
  }

  function disabledDate(current){
    // Can select days before today and today
    return current && current > moment().add(0, "day");
  }

  return(
    <div className="layer">
      {listingData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Darkstore" value={valueBySource}
                        onChange={(e) => onChange(e, 'valueBySource')} type="valueByDarkstore"/>
                <DatePicker value={date} onChange={onChangeDate} placeholder="Date"
                            style={{width: 200, margin: 10}} disabledDate={disabledDate}/>
                <Button disabled={disable} onClick={getListing} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
              </Col>
            </Card>
          </Row>
          {listingData.length > 0 ?
            <Row>
              <Col span={24}>
                <Card style={{ paddingTop: 5, marginTop: 20 }}>
                    <Row>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                dataSource={listingData}
                                pagination={false}
                                scroll={{ x: 240 }}
                                rowKey="dk_id"
                            />
                        </Col>
                    </Row>
                </Card>
                {/* <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/> */}
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default DarkstoreMargins
