export const logicPosColumns = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        align: "center"
    },
    {
        title: "User Id",
        dataIndex: "logic_pos_user_id",
        key: "logic_pos_user_id",
        align: "center"
    },
    {
        title: "Logic Pos Code",
        dataIndex: "logic_pos_code",
        key: "logic_pos_code",
        align: "center"
    },
    {
        title: "Description",
        dataIndex: "description",
        key: "description",
        align: "center"
    },
    {
        title: "Is Active",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
          return record.is_active !== null ? record.is_active.toString() : null
        }
    },
]