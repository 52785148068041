import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Table, Row, Col, Tabs, Button } from 'antd';
import { bankColumns, cultivationColumns, documentsColumns, familyColumns } from '../../antd/columns/operations/supplier'
import {  DownloadOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

const SupplierDetailModal = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useEffect(() => {
    documentsColumns.push({
      title: 'Download',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (
          <div>
            {record.document_url ?
              <DownloadOutlined type="primary" onClick={() => downloadURL(record.document_url, 'Invoice')} ></DownloadOutlined>
              : null
            }
          </div>
        )

      }
    })
  }, [])

  const downloadURL = (url) => {
    window.open(url, '_blank');
  }

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      setData(false)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    setData(false)
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onCancel={handleCancelModal}
          width='85%'
          className="order fl-right"
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Close
            </Button>,
          ]}>
          <div className="order-modal-container">
            <div>
              <Row>
                <Col md={7}>
                  <div className="frz-height-100 frz-br-1px-s-f0f0f0 frz-rp-24 frz-font-13">
                    <div>
                      <b>Name :</b>
                      <span className="frz-lp-10">
                        {data.name}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Type :</b>
                      <span className="frz-lp-10">
                        {data.supplier_type}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Contact No :</b>
                      <span className="frz-lp-10">
                        {data.mobile_number}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Other Contact No :</b>
                      <span className="frz-lp-10">
                        {data.other_mobile_number}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Date Of Birth :</b>
                      <span className="frz-lp-10">
                        {data.dob}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Email :</b>
                      <span className="frz-lp-10">
                        {data.email}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Gender :</b>
                      <span className="frz-lp-10">
                        {data.gender}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Married :</b>
                      <span className="frz-lp-10">
                        {data.marital_status !== null ? data.marital_status.toString() : ''}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Age :</b>
                      <span className="frz-lp-10">
                        {data.age}
                      </span>
                    </div>
                    <div className="order-detail-padding frz-tp-20 frz-clr-light-black frz-font-16">
                      <b>#Address</b>
                    </div>
                    <div className="order-detail-padding">
                      <b>Shop No :</b>
                      <span className="frz-lp-10">
                        {data.shop_no}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Address :</b>
                      <span className="frz-lp-10">
                        {data.address}
                      </span>
                    </div>
                    {/* <div className="order-detail-padding">
                                    <b>Region :</b>
                                    <span style={{paddingLeft:10}}>
                                    {data.region_name}
                                    </span>
                                </div>
                                <div className="order-detail-padding">
                                    <b>Region Id :</b>
                                    <span style={{paddingLeft:10}}>
                                    {data.region_id}
                                    </span>
                                </div> */}
                    {/* <div className="order-detail-padding">
                      <b>Pincode :</b>
                      <span className="frz-lp-10">
                        {data.pincode}
                      </span>
                    </div> */}
                    <div className="order-detail-padding">
                      <b>Latitude :</b>
                      <span className="frz-lp-10">
                        {data.latitude}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Longitude :</b>
                      <span className="frz-lp-10">
                        {data.longitude}
                      </span>
                    </div>
                    <div className="order-detail-padding frz-tp-20 frz-clr-light-black frz-font-16">
                      <b>#Other Details</b>
                    </div>
                    <div className="order-detail-padding">
                      <b>Organization Name :</b>
                      <span className="frz-lp-10">
                        {data.organisation_name}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Credit Cycle Type:</b>
                      <span className="frz-lp-10">
                        {data.credit_cycle_type}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Credit Period:</b>
                      <span className="frz-lp-10">
                        {data.credit_period}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Pay Day In Week:</b>
                      <span className="frz-lp-10">
                        {data.pay_day_in_week}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Cultivation Land:</b>
                      <span className="frz-lp-10">
                        {data.cultivation_land}
                      </span>
                    </div>
                    <div className="order-detail-padding">
                      <b>Land Holding:</b>
                      <span className="frz-lp-10">
                        {data.land_holding}
                      </span>
                    </div>
                    {/* <div className="order-detail-padding">
                      <b>Is Bidder:</b>
                      <span className="frz-lp-10">
                        {data.is_bidder !== null ? data.is_bidder.toString() : ''}
                      </span>
                    </div> */}
                    <div className="order-detail-padding">
                      <b>Pan Uid:</b>
                      <span className="frz-lp-10">
                        {data.pan_uid}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col md={17}>
                  <div className="frz-lp-24">
                    <Tabs defaultActiveKey="1">
                      <TabPane
                        tab={<span>Farm Cultivation</span>}
                        key="1">
                        {data.farm_cultivations ?
                          <Table columns={cultivationColumns} dataSource={data.farm_cultivations}
                            pagination={false} scroll={{ x: 240 }}
                            rowKey="id" />
                          : <h3 className="frz-tp-24 frz-text-center">No Data Found</h3>}
                      </TabPane>
                      <TabPane
                        tab={<span>Family Members</span>}
                        key="2">
                        {data.family_members ?
                          <Table columns={familyColumns} dataSource={data.family_members}
                            pagination={false} scroll={{ x: 240 }}
                            rowKey="id" />
                          : <h3 className="frz-tp-24 frz-text-center">No Data Found</h3>}
                      </TabPane>
                      <TabPane
                        tab={<span>Bank Details</span>}
                        key="3">
                        {data.supplier_bank_details ?
                          <Table columns={bankColumns} dataSource={data.supplier_bank_details}
                            pagination={false} scroll={{ x: 240 }}
                            rowKey="id" />
                          : <h3 className="frz-tp-24 frz-text-center">No Data Found</h3>}
                      </TabPane>
                      <TabPane
                        tab={<span>Documents</span>}
                        key="4">
                        {data.supplier_documents ?
                          <Table columns={documentsColumns} dataSource={data.supplier_documents}
                            pagination={false} scroll={{ x: 240 }}
                            rowKey="id" />
                          : <h3 className="frz-tp-24 frz-text-center">No Data Found</h3>}
                      </TabPane>
                    </Tabs>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Modal>
        : null}
    </div>
  )
})

export default SupplierDetailModal

