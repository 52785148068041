import React, { useCallback, useEffect, useState } from 'react';
import { Spin, Table, Card } from 'antd';
import { BannersService } from '../../../_services/banners.js';
import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AdsService } from '../../../_services/ads.js';
import { openNotificationWithIcon } from '../../../_modules/notification.js';
import update from 'immutability-helper';

const banner_service = new BannersService()
const ads_service = new AdsService();
const type = 'DraggableBodyRow';

const DraggableBodyRow = ({ index, moveParentRow, className, style, ...restProps }) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveParentRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

const ClusterProductTable = ({ nestedCol, nestedTableData, updateData, setUpdateData }) => {
  const [updateNestedRow, setUpdateNestedRow] = useState(false);
  const [spinEnable, setSpinEnable] = useState(false);
  const [products, setproducts] = useState(false)
  const [updateSeq, setUpdateSeq] = useState(false)

  useEffect(() => {
    if (!updateNestedRow || updateData.id === nestedTableData.id) {
      setUpdateData(false)
      setUpdateNestedRow(false)
      getproducts(nestedTableData);
    }
  }, [updateNestedRow, updateData]);


  function getproducts(nestedTableData) {
    setSpinEnable(true)
    setUpdateNestedRow(true);
    banner_service.get_cluster_items(nestedTableData.id).subscribe((r) => {
      let newResponse = r.response.data.cluster_promotional_pages
      setproducts(newResponse)
      setSpinEnable(false);
    },
      (err) => {
        console.log(err)
      })
  }

  function updatePromotionPageSequence(data) {
    const payload = {
      sequence: data.map(a => a.id)
    }
    ads_service.update_cluster_pages_sequence(nestedTableData.id,payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Updated Successfully')
    },
      (err) => {
        console.log(err);
        openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
      }
    );
  }

  const moveParentRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = products[dragIndex];
      setproducts(
        update(products, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
      setUpdateSeq(true)
    },
    [products],
  );

  useEffect(() => {
    if (updateSeq) {
      setUpdateSeq(false)
      updatePromotionPageSequence(products)
    }
  }, [updateSeq])

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  return (
    <div>
      {products ?
        <div>
          <Card className="nested-table">
            <DndProvider backend={HTML5Backend}>
              <Table columns={nestedCol}
                scroll={{ x: 240, y: 'calc(100vh - 254px)'}}
                components={components}
                rowKey="id"
                dataSource={products}
                pagination={false}
                onRow={(record, index) => ({
                  index,
                  moveParentRow,
                  onClick: (e) => getproducts(record)
                })}
              />
            </DndProvider>
          </Card>
        </div>
        : <div className="spin-center">
        <Spin tip="...Loading" />
      </div>
      }
    </div>
  )
}
export default ClusterProductTable;