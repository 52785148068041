import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { Spin, Modal, Form, Select } from "antd";
import { formItemLayout } from '../../../_modules/controllayout';
import FRZInput from '../../../_controls/FRZInput';
import FRZSelect from "../../../_controls/FRZSelect";
import FRZDatepicker from "../../../_controls/FRZDatepicker";
import moment from 'moment';
import { ClusterService } from "../../../_services/clusters";
import { openNotificationWithIcon } from '../../../_modules/notification';

const cluster_service = new ClusterService();
const { Option } = Select;
let inputTimer

const CreateProductCluster = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(false);
  const [bannerData, setBannerData] = useState([])
  const [bannerSelectedData, setBannerSelectedData] = useState([])
  const [bannerSelectedStrings, setBannerSelectedStrings] = useState([])
  const [searchString, setSearchString] = useState(undefined)
  const [spinEnable, setSpinEnable] = useState(false);
  const [form] = Form.useForm();
  const { modalSubmit } = props;
  const [state, setState] = useState({ clusterType: false });
  const [updateForm, setUpdateForm] = useState(false)

  const { clusterType } = state;
  
  function showProductCluster(id) {
    setSpinEnable(true)
    cluster_service.showClusters(id).subscribe((r) => {
      setSpinEnable(false);
      form.setFieldsValue({
        ...r.response.data,
        // product_component: props.productData.filter(({ id: x }) => r.response.data.cluster_details.some(({ component_id: y }) => x === y)).map(a => a.id),
        product_component: r.response.data.cluster_details.map(x => x.component_id),
        start_at: r.response.data.start_at ? moment(r.response.data.start_at) : "",
        end_at: r.response.data.end_at ? moment(r.response.data.end_at) : ""
      })
      setState({ clusterType: r.response.data.type_of_cluster })
      setBannerSelectedData(r.response.data.cluster_details.map(x => x.component_id))
      setBannerSelectedStrings(r.response.data.cluster_details.map(x => ({ component_id: x.component_id, component_name: x.component_name })))
    },
      (err) => {
        handleCancelModal();
        console.log(err);
        openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
      }
    );
  }

  useEffect(() => {
    if (title === "Edit Product Cluster") {
      showProductCluster(data.id)
    }
  }, [title])

  useImperativeHandle(ref, () => ({
    handleCancel: handleCancelModal,
    showModal(value, data) {
      setVisible(true);
      setTitle(value);
      setData(data);
    },
    stopLoading() {
      setLoading(false)
    }
  }));

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    setData(false)
    setTitle(false)
    form.resetFields()
  }

  function handleOnSubmit(values) {
    setLoading(true)
    modalSubmit({ ...values }, data.id)
  }

  function onChange(value, type) {
    setState((prevState) => ({ ...prevState, [type]: value }));
    form.setFieldsValue({ product_component: [] })
    setBannerSelectedData([])
    setBannerSelectedStrings([])
    setSearchString(undefined)
    setBannerData([])
  }

  function handleBannerSearch(e) {
    setSearchString(e)
    clearTimeout(inputTimer);
    if (e.length >= 3) {
      inputTimer = setTimeout(() => {
        cluster_service.bannerSearch(e).subscribe((r) => {
          setBannerData(r.response.data)
        },
        (err) => {
          setBannerData([])
          console.log(err);
          openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
        });
      }, 2000)
    } else if (e.length === 0) {
      setSearchString(undefined)
      setBannerData([])
    } else {
      setBannerData([])
    }
  }

  function arrayRemove(arr, value) {

    return arr.filter(function (ele) {
      return ele != value;
    });
  }

  function handleBannerChange(e, f) {
    if (bannerSelectedData.length === 0 || !bannerSelectedData.includes(e)) {
      bannerSelectedData.push(e)
      bannerSelectedStrings.push({ component_id: e, component_name: f.name })
      setBannerSelectedData([...bannerSelectedData])
      setBannerSelectedStrings([...bannerSelectedStrings])
    } else {
      var removed = arrayRemove(bannerSelectedData, e);
      var filtered = bannerSelectedStrings.filter(function (el) { return el.component_id != e })
      setBannerSelectedData(removed)
      setBannerSelectedStrings(filtered)
    }
    setUpdateForm(true)
  }

  function handleBannerSelected(e, f) {
    setBannerSelectedData(e)
    var newVal = []
    f.map(val => {
      newVal.push({ component_id: val.value, component_name: val.children })
    })
    setBannerSelectedStrings(newVal)
    setUpdateForm(true)
  }

  useEffect(() => {
    if (updateForm) {
      setUpdateForm(false)
      form.setFieldsValue({ product_component: bannerSelectedData })
    }
  }, [updateForm])

  return (
    <div>
      {data ?
        <Modal
          visible={visible} title={title} okText='Submit' width={'50%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
              });
          }}
          onCancel={handleCancelModal}>
          {!spinEnable ?
            <Form
              form={form}
              {...formItemLayout}
              layout="horizontal"
              name="form">

              <FRZInput name="title" isRequired={true} label="Cluster Name" placeholder="Cluster Name" width={200} />

              <FRZInput name="tag" label="Tag" placeholder="Tag" width={200} isRequired={true}/>

              <FRZSelect name="type_of_cluster" label="Cluster Type" isRequired={true} value="cluster_type" option="cluster_type"
                DropDownData={props.typeOfCluster} placeholder="Select Type of Cluster" width={200}
                setOnChange={(e) => onChange(e, 'clusterType')} />

              <FRZSelect DropDownData={props.status} name="status" isRequired={true}
                label="Status" placeholder="Select Status" width={200} />

              {clusterType === 'products' ?
                <FRZSelect name='product_component' label='Select Products' mode="multiple"
                  DropDownData={props.productData.sort((a, b) => a.name > b.name ? 1 : -1)}
                  isRequired={true} isShowSearch={true} width={400} placeholder="Select Products"
                  value="component_id" option="component_name" />
                : (clusterType === 'banners' ?
                  <>
                    <FRZSelect name='product_component' label='Selected Banners' mode="multiple"
                      DropDownData={bannerSelectedStrings} setOnChange={handleBannerSelected}
                      isRequired={true} isShowSearch={true} width={400} placeholder="Selected Banners"
                      value="component_id" option="component_name" />
                    <div className="search-banners">
                      Search Banners :
                      <span>
                        <Select placeholder='Search Banner' showSearch value={searchString}
                          showArrow={false} filterOption={false} className="frz-w-200"
                          onSearch={handleBannerSearch} onChange={(e, f) => handleBannerChange(e, f)}
                          notFoundContent={null}>
                          {bannerData.map(data => {
                            return <Option value={data.id} key={data.id} name={data.banner_name}>
                              {data.banner_name}
                            </Option>
                          })}
                        </Select>
                      </span>
                    </div>
                  </>
                  : null)
              }

              <FRZDatepicker placeholder="Select Start Date" label="Start Date" isRequired={true} name="start_at" />
              <FRZDatepicker placeholder="Select End Date" label="End Date" isRequired={true} name="end_at" />
            </Form>
            :
            <div className="spin-center"><Spin tip="...Loading" /></div>
          }
        </Modal>
        :
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>
      }
    </div>
  )
})

export default CreateProductCluster;