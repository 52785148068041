import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button, Select, Input, InputNumber,
         Radio} from 'antd';
const { Option } = Select;
const { TextArea } = Input;

const AddReviewModal = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOk() {
    setLoading(true)
    props.modalSubmit()
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.onCancelModal();
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onOk={handleOk}
          onCancel={handleCancelModal}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              Submit
            </Button>,
          ]}
        >
            <div className="block">Issues :
                <span style={{paddingLeft:10}}>
                    <Select mode="tags" style={{ width: 300, marginLeft: 10}}
                            value={props.valueByReviews}
                            placeholder="Select Issues" onChange={props.onChangeReview}>
                        {props.issueData.map((data, index) => {
                            return(
                                <Option value={data} key={index}>{data}</Option>
                            )
                        })}
                    </Select>
                </span>
            </div>
        </Modal>
      : null }
    </div>
  )
})

export default AddReviewModal
