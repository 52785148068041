import { Http } from '../_modules/http'

export class GlobalPropertiesService {

  http = new Http()

  get_properties(payload){
    const url = process.env.REACT_APP_API_URL +'admin_dashboard/v1/global_properties/global_properties?page='+payload.page +"&by_name="+payload.by_name
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }
  create_properties(payload){
    const url = process.env.REACT_APP_API_URL +'admin_dashboard/v1/global_properties/global_properties'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload, headers)
  }
  update_properties(payload){
    const url = process.env.REACT_APP_API_URL +'admin_dashboard/v1/global_properties/global_properties/'+ payload.id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers)
  }

}
