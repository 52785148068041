import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form } from 'antd';
import FRZInputNumber from '../../_controls/FRZInputNumber';
import { formItemLayout } from '../../_modules/controllayout';
import FRZSelect from '../../_controls/FRZSelect';

const ReferralModal = forwardRef((props, ref) => {
  const { modalSubmit } = props
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [discountType] = useState([{id: 'percentage', name: 'Percentage'},{id: 'value', name: 'Value'}])
  const [form] = Form.useForm();

  useEffect(() => { 
    if ((data && title == "Edit Discounts")) {
      form.setFieldsValue({ ...data })
    }
  }, [data])

  function handleOnSubmit(values) {
    setLoading(true)
    modalSubmit(values)
  }

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      setData(false)
      form.resetFields()
    },

    stopLoading() {
      setLoading(false)
    }

  }));


  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    setData(false)
    form.resetFields()
  }
  
  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            {title === 'Edit Discounts' ? 
              <>
                <FRZSelect DropDownData={props.referralData} name="city_id" isRequired={true}
                  label="City" placeholder="City" value="city_id" option="city_name"
                  isShowSearch={true} width={200} selectDisabled={true} />

                <FRZSelect DropDownData={props.discountData} name="referral_discount_id" isRequired={true}
                  label="Select Discount" placeholder="Discount Amt" value="referral_discount_id" option="option"
                  isShowSearch={true} width={200} isDataKey={true}/>
              </>
            : 
              <>
                <FRZSelect DropDownData={discountType} name="discount_type" isRequired={true}
                  label="Discount Type" placeholder="Discount Type" value="id" option="name"
                  isShowSearch={true} width={200} />

                <FRZInputNumber name="discount_percentage_or_value" isRequired={true}
                  label="Discount" placeholder="Discount" width={200} />

                <FRZInputNumber name="min_order_value" isRequired={true}
                  label="Min Order Value" placeholder="Min Order Value" width={200} />
              </>
            }

          </Form>
        </Modal>
        : <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})

export default ReferralModal