import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { Modal, Form, Select, Row, Col, Spin } from 'antd';
import { formItemLayout, tailLayout } from '../../../_modules/controllayout';
import FRZInput from '../../../_controls/FRZInput';
import DebounceApp from '../../../_controls/FRZDebounceSelect';
import FRAAZO from '../../../_modules/http_headers';
import { LandingClusterService } from '../../../_services/sdui/landing_cluster'
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import { openNotificationWithIcon } from '../../../_modules/notification';

const landing_cluster_service = new LandingClusterService()

const { Option } = Select;

const initialModal = {
  data: false,
  title: false,
  loading: false,
  visible: false
}

const CreateBanners = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const [modalState, setModalState] = useState(initialModal)
  const [selectedValue, setSelectedValue] = useState([]);
  const [loading, setLoading] = useState(false)
  const [dragNdrop, setDragNdrop] = useState([])
  const [updateId, setUpdateId] = useState(false)

  useEffect(() => {
    if (modalState.data) {
      if (modalState.data.id) {
        getShowClusterApi(modalState.data.id)
      }
      // form.setFieldsValue({ testname: [{ value: "FPIN2", label: "Pineapplea" }] })
    }
  }, [modalState.data])

  const getShowClusterApi = (id) => {
    setLoading(true)
    let productList = []
    landing_cluster_service.getProductList(id).subscribe((r) => {
      setUpdateId(r.response.data.id)
      r.response.data.cluster_details.map((itm) => {
        productList.push(Object.assign({}, {
          value: itm.component_id, label: itm.component_name, id: itm.component_id,
          new_banner_image_url: itm.component_image_url
        }))
      })
      setSelectedValue(productList)
      setDragNdrop(productList)
      form.setFieldsValue({ title: r.response.data.title, productlist: productList })
      setLoading(false)
    }, ((err) => {
      setLoading(false)
      console.log("err", err)
    }))
  }

  useImperativeHandle(ref, () => ({
    handleCancel: handleCancelModal,
    showModal(data, title) {
      setModalState(prevState => ({ ...prevState, data: data, title: title, visible: true }))
    },
    stopLoading() {
      setModalState(prevState => ({ ...prevState, loading: false }))
    }
  }));

  function handleCancelModal() {
    form.resetFields()
    setSelectedValue([])
    setDragNdrop([])
    setUpdateId(false)
    setModalState(initialModal)
  }

  const handleOnSubmit = (values) => {
    setLoading(true)
    let temp = []
    dragNdrop.map((data) => {
      temp.push(Object.assign({}, {
        component_id: data.id, component_type: "Banner"
      }))
    })
    const payload = {
      title: values.title,
      type_of_cluster: "banners",
      cluster_details_attributes: [...temp]
    }
    if (updateId) {

      payload.id = updateId

      landing_cluster_service.updateCluster(payload, updateId).subscribe((r) => {
        setLoading(false)
        handleCancelModal()
        if (props.getListing()) {
          props.getListing()
        }
        openNotificationWithIcon('success', 'Cluster updated successfully')
      }, ((err) => {
        openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
        setLoading(false)
        console.error("err", err)
      }))
    } else {
      landing_cluster_service.createCluster(payload).subscribe((r) => {
        setLoading(false)
        handleCancelModal()
        if (props.getListing()) {
          props.getListing()
        }
        openNotificationWithIcon('success', 'Cluster created successfully')
      }, ((err) => {
        openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
        setLoading(false)
        console.error("err", err)
      }))
    }
    // modalSubmit(values, data?.id)
  }

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    if (sourceIndex == dragNdrop.length) {
      return
    }
    const nextState = swap(dragNdrop, sourceIndex, targetIndex);
    setDragNdrop(nextState);
    // setItemsChanged(true)
  }

  const onDeleteItm = (id) => {
    let temp = selectedValue;
    let index = temp.indexOf(temp.find((x) => x.id == id))
    if (index > -1) {
      temp.splice(index, 1);
    }
    setSelectedValue([...temp])
    // setItemsChanged(true)
  }

  const setDNDData = (data) => {
    let temp = [...dragNdrop]
    temp.push(data)
    setDragNdrop(temp)
  }

  return (
    <div>
      {modalState.data ?
        <Modal
          visible={modalState.visible}
          title={modalState.title}
          okText="Submit"
          width={'70%'}
          // okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Spin tip="Loading..." spinning={loading}>
            <Form
              form={form}
              {...formItemLayout}
              layout="horizontal"
              name="form">

              <FRZInput name="title" isRequired={true} label="Banner title" placeholder="Banner title" width={200} />

              <DebounceApp isMultiple="multiple" isRequired={true} name="productlist" placeholder="Select banners" label="Select banners" method="get" headers={FRAAZO()}
                labelTerm="banner_name" valueTerm="id"
                url={process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/banners?by_banner_name='}
                setSelectedValue={setSelectedValue} selectedValue={selectedValue}
                setDNDData={setDNDData} dragNdrop={dragNdrop}
                setDragNdrop={(value) => setDragNdrop(value)} />
              {dragNdrop.length ?
                <>
                  <Row>
                    <Col span={4}>
                    </Col>
                    <Col>
                      <div className="frz-preview-txt">
                        Cluster Preview
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={7}>
                    </Col>
                    <Col span={17}>
                      <GridContextProvider onChange={onChange}>
                        <GridDropZone
                          // disableDrag={gridDisble}
                          // disableDrop={gridDisble}
                          id="items"
                          boxesPerRow={4}
                          rowHeight={150}
                          style={{ height: "300px", overflow: "scroll" }}>
                          {dragNdrop.map(item => (
                            <GridItem key={item.id}>
                              <div className={`frz-prod-contol-card frz-pos-realtive  ${false ? 'frz-cursor-disable' : 'frz-cursor-dragg'}`}>
                                <div className="frz-p-8 frz-dis-flex frz-hrz-center">
                                  {/* <div className="frz-pos-abs frz-r-5 frz-t-5 frz-font-10 frz-cursor-pointer" onClick={() => onDeleteItm(item.id)}>
                                  <CloseOutlined />
                                </div> */}
                                  <div>
                                    <div className="frz-dis-flex frz-hrz-center">
                                      <img draggable={false} src={item.new_banner_image_url} className="frz-prod-contol-img" />
                                    </div>
                                    <div className="frz-text-center frz-seq-grid-txt">
                                      {item.label}
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </GridItem>
                          ))}
                        </GridDropZone>
                      </GridContextProvider>
                    </Col>
                  </Row>
                </>
                : null}
            </Form>
          </Spin>
        </Modal>
        : <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form"></Form>}
    </div >

  )
})

export default CreateBanners
