import { notification } from "antd";

export const SuccessNotification = (msg, title = "SUCCESS") => {
  notification["success"]({
    message: title,
    duration: 3,
    description: msg,
  });
};

export const ErrorNotification = (msg, title = "ERROR", err={}) => {
  // console.log(msg, err)
  notification["error"]({
    message: title,
    duration: 3,
    description: msg,
  });
  // if (err) console.log(err);
};

