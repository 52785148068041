import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import {
  Modal, Button, Select, Input, InputNumber,
  Radio, Row, Col, Form, Checkbox, Popconfirm, message, TimePicker
} from 'antd';
import Search from '../../antd/search';
import { formItemLayout, tailLayout } from '../../../_modules/controllayout';
import { useSelector } from "react-redux";
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;
const format = 'HH';

const DarkstoreModal = forwardRef((props, ref) => {
  const { listingChecklist, onEditCheckList } = props
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [dsCheckList, setDsCheckList] = useState(false)
  const [checkList, setCheckList] = useState(false)
  const [assignDsListing, setAssignDsListing] = useState(false)
  const [abbrList, setAbbrList] = useState(false)
  const city = useSelector(state => state.auth.city);
  const [form] = Form.useForm();

  useEffect(() => {
    if (title === "Edit Darkstore") {
      if (!dsCheckList && listingChecklist) {
        setCheckList(listingChecklist)
      }
      if (dsCheckList && listingChecklist) {
        let tempLisitng = listingChecklist;
        let tempDsListing = dsCheckList;

        tempDsListing = tempDsListing.map((dsData) => {
          if (dsData.darkstore_checklist) {
            return Object.assign(dsData, { id: dsData.darkstore_checklist.id })
          }
        })

        for (let row of tempLisitng) {
          let temp = tempDsListing.filter(data => data && data.id === row.id)
          if (temp && temp[0]) {
            Object.assign(row, { 'status': temp[0]['status'] })
          }
        }

        let temp = {};
        for (let row of tempDsListing) {
          if (row)
            Object.assign(temp, { [row.id]: row.status })
        }
        setAssignDsListing(temp)
        setCheckList(listingChecklist)
      }
      if (listingChecklist) {
        const tempAbbrList = [...new Set(listingChecklist.map(item => item.title))];
        setAbbrList(tempAbbrList)
      }
    }
  }, [dsCheckList, title])

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setDsCheckList(data.darkstore_checklist_mapping)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      props.modalDOM("remove")
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOk() {
    setLoading(true)
    props.modalSubmit()
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.modalDOM("remove")
    props.onCancelModal();
  }

  const onCreate = (values) => {
    let tempPayload;
    tempPayload = Object.keys(values).map((key) => {
      return Object.assign({ id: Number(key), status: values[key] ? values[key] : false })
    });
    onEditCheckList(tempPayload)
    setLoading(true)
  }

  const onOkChecklist = () => {
    form
      .validateFields()
      .then((values) => {
        onCreate(values);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          width={title === "Add Darkstore" ? "40%" : "90%"}
          title={title}
          // onOk={title === "Add Darkstore" ? handleOk : onOkChecklist}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Popconfirm
              title="Are you sure you want to submit ?"
              onConfirm={title === "Add Darkstore" ? handleOk : onOkChecklist}
              okButtonProps={{ htmlType: "submit" }}
              okText="Yes, Submit"
              cancelText="Cancel"
              placement="leftTop"
            >
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Popconfirm>

          ]}
          onCancel={handleCancelModal}
        >
          <Row gutter={[10, 10]}>
            <Col xl={title === "Add Darkstore" ? 24 : 9}>
              {title === "Add Darkstore" ? null :
                <div className="frz-hrz-left-div">
                </div>}
              <div className="block">Darkstore Name :
                <span style={{ paddingLeft: 10 }}>
                  <TextArea placeholder="Darkstore Name" autoSize={{ minRows: 2, maxRows: 3 }}
                    value={props.name} style={{ width: 230 }}
                    onChange={(e) => props.onChangeModal(e.target.value, 'name')} disabled={title !== "Add Darkstore"} />
                </span>
              </div>
              <div className="block" style={{ marginLeft: 47 }}>Latitude :
                <span style={{ paddingLeft: 10 }}>
                  <Input placeholder="Latitude"
                    value={props.latitude} style={{ width: 230 }}
                    onChange={(e) => props.onChangeModal(e.target.value, 'latitude')} />
                </span>
              </div>
              <div className="block" style={{ marginLeft: 36 }}>Longitude :
                <span style={{ paddingLeft: 10 }}>
                  <Input placeholder="Longitude"
                    value={props.longitude} style={{ width: 230 }}
                    onChange={(e) => props.onChangeModal(e.target.value, 'longitude')} />
                </span>
              </div>
              <div className="block" style={{ marginLeft: 48 }}>Address :
                <span style={{ paddingLeft: 10 }}>
                  <TextArea placeholder="Address" autoSize={{ minRows: 2, maxRows: 6 }}
                    value={props.address} style={{ width: 230 }}
                    onChange={(e) => props.onChangeModal(e.target.value, 'address')} />
                </span>
              </div>
              <div className="block" style={{ marginLeft: 48 }}>Pincode :
                <span style={{ paddingLeft: 10 }}>
                  <Input placeholder="Pincode"
                    value={props.pincode} style={{ width: 230 }}
                    onChange={(e) => props.onChangeModal(e.target.value, 'pincode')} />
                </span>
              </div>
              <div className="block" style={{ marginLeft: 73 }}>City :
                <span style={{ paddingLeft: 10 }}>
                  <Select style={{ width: 230 }} value={props.city_id}
                    placeholder="Select City"
                    onChange={(e) => props.onChangeModal(e, 'city_id')}>
                    {city.map(data => {
                      return (
                        <Option key={data.id} value={data.id}>{data.name}</Option>
                      )
                    })}
                  </Select>
                </span>
              </div>
              <div className="block" style={{ marginLeft: 36 }}>Mobile No :
                <span style={{ paddingLeft: 10 }}>
                  <Input placeholder="Mobile Number"
                    value={props.mobile_number} style={{ width: 230 }}
                    onChange={(e) => props.onChangeModal(e.target.value, 'mobile_number')} />
                </span>
              </div>
              <div className="block" style={{ marginLeft: 4 }}>Darkstore Code :
                <span style={{ paddingLeft: 10 }}>
                  <Input placeholder="Darkstore Code"
                    value={props.code} style={{ width: 230 }}
                    onChange={(e) => props.onChangeModal(e.target.value, 'code')}
                    disabled={title !== "Add Darkstore"} />
                </span>
              </div>
              <div className="block" style={{ marginLeft: 40 }}>ERP Code :
                <span style={{ paddingLeft: 10 }}>
                  <Input placeholder="ERP Code"
                    value={props.erp_code} style={{ width: 230 }}
                    onChange={(e) => props.onChangeModal(e.target.value, 'erp_code')} />
                </span>
              </div>
              <div className="block" style={{ marginLeft: -15 }}>
                Serviceable Radius :
                <span style={{ paddingLeft: 10 }}>
                  <InputNumber
                    placeholder="Serviceable Radius"
                    value={props.serviceable_radius}
                    style={{ width: 230 }}
                    onChange={(e) => props.onChangeModal(e, "serviceable_radius")}
                  />
                </span>
              </div>
              <div className="block" style={{ marginLeft: 15 }}>
                Delivery Limit :
                <span style={{ paddingLeft: 10 }}>
                  <InputNumber
                    placeholder="Delivery Limit"
                    value={props.delivery_limit}
                    style={{ width: 230 }}
                    onChange={(e) => props.onChangeModal(e, "delivery_limit")}
                  />
                </span>
              </div>
              <div className="block" style={{ marginLeft: 48 }}>
                Static Ip :
                <span style={{ paddingLeft: 10 }}>
                  <Input
                    disabled={!JSON.parse(localStorage.getItem('roles')).includes('super_admin') && 
                              !JSON.parse(localStorage.getItem('roles')).includes('developer_admin') &&
                              !JSON.parse(localStorage.getItem('roles')).includes('admin') }
                    placeholder="Static Ip (Optional)"
                    value={props.static_ip}
                    style={{ width: 230 }}
                    onChange={(e) => props.onChangeModal(e.target.value, "static_ip")}
                  />
                </span>
              </div>
              <div className="block" style={{marginLeft:15}}>Opening Time :
                <span style={{paddingLeft:10}}>
                  <TimePicker format={format}
                              use24Hours
                              value={!props.opening_time ? '' : moment(props.opening_time, 'HH')}
                              onChange={(e, f) => props.onChangeModalTime(e, f, 'opening_time')}/>
                </span>
              </div>
              <div className="block" style={{marginLeft:21}}>Closing Time :
                <span style={{paddingLeft:10}}>
                  <TimePicker format={format}
                              use24Hours
                              value={!props.closing_time ? '' : moment(props.closing_time, 'HH')}
                              onChange={(e, f) => props.onChangeModalTime(e, f, 'closing_time')}/>
                </span>
              </div>
              {title !== 'Edit Darkstore' ?
                <div className="block" style={{ marginLeft: 15 }}>Is Serviceable :
                  <span style={{ paddingLeft: 10 }}>
                    <Radio.Group
                      value={props.valueByRadio}
                      onChange={(e) => props.onChangeModal(e.target.value, 'valueByRadio')}>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </span>
                </div>
                : null}
              <div className="block" style={{ marginLeft: 9 }}>Fraazo Partner :
                <span style={{ paddingLeft: 10 }}>
                  <Radio.Group
                    value={props.is_fraazo}
                    onChange={(e) => props.onChangeModal(e.target.value, 'is_fraazo')}>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </span>
              </div>
              <div className="block" style={{ marginLeft: 18 }}>Is Superstore :
                <span style={{ paddingLeft: 10 }}>
                  <Radio.Group
                    //  disabled={title === 'Edit Darkstore'}
                    value={props.is_superstore}
                    onChange={(e) => props.onChangeModal(e.target.value, 'is_superstore')}>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </span>
              </div>
              <div className="block" style={{ marginLeft: -11 }}>Image Attendance :
                <span style={{ paddingLeft: 10 }}>
                  <Radio.Group
                    //  disabled={title === 'Edit Darkstore'}
                    value={props.image_attendance}
                    onChange={(e) => props.onChangeModal(e.target.value, 'image_attendance')}>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </span>
              </div>
              <div className="block" style={{ marginLeft: 4 }}>Otp Attendance :
                <span style={{ paddingLeft: 10 }}>
                  <Radio.Group
                    //  disabled={title === 'Edit Darkstore'}
                    value={props.otp_attendance}
                    onChange={(e) => props.onChangeModal(e.target.value, 'otp_attendance')}>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </span>
              </div>
              <div className="block" style={{ marginLeft: 45 }}>Is Virtual :
                <span style={{ paddingLeft: 10 }}>
                  <Radio.Group
                    disabled={title === 'Edit Darkstore'}
                    value={props.is_virtual}
                    onChange={(e) => props.onChangeModal(e.target.value, 'is_virtual')}>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </span>
              </div>
              <div className="block" style={{ marginLeft: -20 }}>Is Night Serviceable :
                <span style={{ paddingLeft: 10 }}>
                  <Radio.Group
                    value={props.is_night_serviceable}
                    onChange={(e) => props.onChangeModal(e.target.value, 'is_night_serviceable')}>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </span>
              </div>
              {title !== "Add Darkstore" ?
                <div className="block" style={{ marginLeft: 17 }}>Is Serviceable :
                  <span style={{ paddingLeft: 10 }}>
                    <Radio.Group
                      value={props.is_serviceable}
                      onChange={(e) => props.onChangeModal(e.target.value, 'is_serviceable')}
                      disabled={!JSON.parse(localStorage.getItem('roles')).includes('super_admin') && !JSON.parse(localStorage.getItem('roles')).includes('developer_admin')}>
                      <Radio value={true}>True</Radio>
                      <Radio value={false}>False</Radio>
                    </Radio.Group>
                  </span>
                </div> : null}
              {props.is_virtual ?
                <div className="layer" style={{ marginLeft: 5 }}>Parent Darkstore :
                  <span>
                    <Search placeholder="Parent Darkstore Name" value={props.parent_id} data={props.darkstoreNVData}
                      width="medium" onChange={(e) => props.onChangeModal(e, 'parent_id')} type="valueByVirtualDs" />
                  </span>
                </div>
                : null}
            </Col>
            {checkList && title === "Edit Darkstore" ?
              <>
                <Col xl={15}>
                  <Form initialValues={{ ...assignDsListing }} form={form} name="formData" onFinish={onCreate} autoComplete="off">
                    <div className="frz-font-24 frz-fw-600 frz-bm-10 frz-hrz-center frz-dis-flex">
                      Checklist
                    </div>
                    <Row gutter={[10, 10]} className="frz-checklist-abbreviation frz-dis-flex frz-hrz-scroll">
                      {abbrList.map((data, index) => {
                        return (
                          <div className="frz-dis-flex frz-bm-10 frz-rp-30" key={index}>
                            <div className="frz-dis-flex frz-vertical-center frz-fw-600">
                              {data} ({data.split(" ").map((shortForm, ind) => <div key={ind}>{shortForm[0]}</div>)})-
                              {/* Store Closure (SC)- */}
                            </div>
                            <div className="frz-checklist-abbr-box" style={{
                              background: data === "Store Closure" ? "#C70039" :
                                data === "Setup" ? "#4fbb90" :
                                  data === "Preops" ? "#F1C40F" : "#AF7AC5"
                            }} />
                          </div>
                        )
                      })}
                    </Row>
                    <div className="frz-abbr-hrz-div" />
                    <Row>
                      {checkList.map((data, index) => {
                        return (
                          <Col xl={8} key={index}>
                            {/* <FRZCheckBox name={data.id} label={data.title} /> */}
                            <Form.Item name={data.id} valuePropName="checked"
                              {...tailLayout}>
                              <Checkbox className="frz-custom-checkbox-fix">
                                <div className="frz-dis-flex frz-lh-15">
                                  <div className="frz-fw-600" style={{
                                    color: data.title === "Store Closure" ? "#C70039" :
                                      data.title === "Setup" ? "#4fbb90" :
                                        data.title === "Preops" ? "#F1C40F" : "#AF7AC5"
                                  }}>
                                    {data.title.split(" ").map((str) => str[0])}
                                  </div>
                                  <div className="className=frz-lp-10">
                                  </div>
                                  <div>
                                    -{data.description}
                                  </div>
                                </div>
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        )
                      })}
                    </Row>
                  </Form>
                </Col>
              </>
              : null}
          </Row>
        </Modal>
        : null}
    </div>
  )
})

export default DarkstoreModal
