import React from 'react'
export const productColumns = [
  {
    title: "Product Name",
    dataIndex: "",
    key: "name",
    align: "center",
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (record) => {
      return record.name
    }
  },
  {
    title: "Category Name",
    dataIndex: "category_name",
    key: "category_name",
    align: "center",
    render: (t, record) => {
      return record.category?.name;
    },
  },
  {
    title: "Sub-Category Name",
    dataIndex: "sub_category_name",
    key: "sub_category_name",
    align: "center",
    render: (t, record) => {
      return record.sub_category?.name;
    },
  },
  {
    title: "MRP",
    dataIndex: "moq_mrp",
    key: "moq_mrp",
    align: "center",
  },
  {
    title: "SP",
    dataIndex: "moq_sp",
    key: "moq_sp",
    align: "center",
  },
  {
    title: "Pack Size",
    dataIndex: "",
    key: "pack_size",
    align: "center",
    render: (record) => {
      return `${record.pack_size}  ${record.pack_size_desc ?? ""}`;
    },
  },
  {
    title: "SKU Code",
    dataIndex: "sku",
    key: "sku",
    align: "center",
  },
];

export const darkStoreWisePricesColumns = [
  {
    title: "No",
    dataIndex: "index",
    key: "index",
    align: "center",
    render: (_, __, index) => {
      return index + 1;
    },
  },

  {
    title: "Dark Store Name",
    dataIndex: "record.darkstore_detail.name",
    key: "record.darkstore_detail.name",
    align: "center",
    render: (_, record) => {
      return record.darkstore_detail.name;
    },
  },
  {
    title: "MOQ MRP",
    dataIndex: "moq_mrp",
    key: "moq_mrp",
    align: "center",
  },
  {
    title: "MOQ SP",
    dataIndex: "moq_sp",
    key: "moq_sp",
    align: "center",
  },
  {
    title: "Stock",
    dataIndex: "qty",
    key: "qty",
    align: "center",
  },
  {
    title: "Max Qty",
    dataIndex: "max_qty",
    key: "max_qty",
    align: "center",
  },
  {
    title: "Is Active",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_active.toString()
    }
  },
];

export const darkStoreFlashSalesColumns = [
  {
    title: "No",
    dataIndex: "index",
    key: "index",
    align: "center",
    render: (_, __, index) => {
      return index + 1;
    },
  },

  {
    title: "DarkStore Name",
    dataIndex: "darkstore_name",
    key: "darkstore_name",
    align: "center"
  },
  {
    title: "Min Sale Qty",
    dataIndex: "min_sale_qty",
    key: "min_sale_qty",
    align: "center",
  },
  {
    title: "Min Discount Percentage",
    dataIndex: "min_discount_percentage",
    key: "min_discount_percentage",
    align: "center",
  },
  {
    title: "Max Discount Percentage",
    dataIndex: "max_discount_percentage",
    key: "max_discount_percentage",
    align: "center",
  },
  {
    title: "Is Sale Active",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_active.toString()
    }
  },
  {
    title: "On Sale",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.on_sale.toString()
    }
  },
];

export const comboItemColumns = [
  {
    title: "Product Name",
    dataIndex: "record.sub_product_details.name",
    key: "record.sub_product_details.name",
    align: "center",
    render: (_, record) => {
      return record.sub_product_details.name;
    },
  },
  {
    title: "Pack Size",
    dataIndex: "record.sub_product_details.pack_size",
    key: "record.sub_product_details.pack_size",
    align: "center",
    render: (_, record) => {
      return record.sub_product_details.pack_size;
    },
  },
  {
    title: "Qty",
    dataIndex: "qty",
    key: "qty",
    align: "center",
  },
]

export const tagColumns = [
  {
    title: "Tag Name",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "Tag Type",
    dataIndex: "product_tag_type_name",
    key: "product_tag_type_name",
    align: "center",
  },
  {
    title: "Tag Group",
    dataIndex: "tag_group",
    key: "tag_group",
    align: "center",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    align: "center",
  },
  {
    title: "Sequence",
    dataIndex: "sequence",
    key: "sequence",
    align: "center",
  },
  {
    title: "Is Active",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_active.toString()
    }
  },
  {
    title: "Is Category",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_category.toString()
    }
  },
]

export const productImagesColumns = [
  {
    title: "Image",
    dataIndex: "image_url",
    key: "image_url",
    align: "center",
    render: (recorc) => {
      return (
        <img src={recorc} style={{ width: 60, height: 60 }} />
      )
    }
  },
  {
    title: "Tag",
    dataIndex: "tag",
    key: "tag",
    align: "center",
  },
  {
    title: "Is Active",
    dataIndex: "is_active",
    key: "is_active",
    align: "center",
    render: (data) => data ? "Yes" : "No"
  },
  {
    title: "Sequence",
    dataIndex: "sequence",
    key: "sequence",
    align: "center",
  }
]

export const nestedItemsColumns = [
  {
    title: "Product Name",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "SKU",
    dataIndex: "sku",
    key: "sku",
    align: "center",
  },
  {
    title: "Pack Size",
    dataIndex: "pack_size",
    key: "pack_size",
    align: "center",
  },
  {
    title: "Sequence",
    dataIndex: "sequence",
    key: "sequence",
    align: "center",
  },
  {
    title: "Is Active",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_active.toString()
    }
  },
]

export const productSlabColumns = [
  {
    title: "GST Slab",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "GST Percentage",
    dataIndex: "gst_percentage",
    key: "gst_percentage",
    align: "center",
  },
  {
    title: "CGST Percentage",
    dataIndex: "cgst_percentage",
    key: "cgst_percentage",
    align: "center",
  },
  {
    title: "SGST Percentage",
    dataIndex: "sgst_percentage",
    key: "sgst_percentage",
    align: "center",
  },
]

export const localNamesColumns = [
  {
    title: "City",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "Regional Name",
    dataIndex: "regional_name",
    key: "regional_name",
    align: "center",
  },
]

export const productCessSlabColumns = [
  {
    title: "Cess Slab",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "Cess Percentage",
    dataIndex: "cess_percentage",
    key: "cess_percentage",
    align: "center",
  },
]
