import React from 'react'
import { Spin, Modal, Button, Row, Col, Tabs, notification, 
    Select, Input, InputNumber, Radio} from 'antd';
import { EditOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import LocationSearch from './location_search';
const { Option } = Select;

function ApplyCoupon(props){
    return(
        <div>
            <div className="order-header">
                <b>#Apply Coupon</b>
            </div>
            {props.updateCart.cart_items ?
                <div className="order-detail-padding">
                    <span>
                        <Input
                            placeholder="Enter Coupon Code"
                            value={props.modalCoupon}
                            style={{width:159}}
                            disabled={props.modalCouponType}
                            onChange={(e) => props.onChangeModal(e.target.value, 'modalCoupon')}/>
                        {props.modalCouponType ?     
                        <Button type="danger" ghost
                                style={{marginLeft: 10}}
                                onClick={() => props.applyCoupon('remove')}>
                            Remove        
                        </Button>
                        : 
                        <Button type="primary" ghost
                                style={{marginLeft: 10}}
                                onClick={() => props.applyCoupon('apply')}>
                            Apply        
                        </Button>}
                    </span>
                </div>
            : null}
        </div>
    )
}

export default ApplyCoupon