import React, { useEffect, useState } from 'react';
import Search from '../../components/antd/search';
import { DeliveryApp } from '../../_services/delivery_app';
import { Spin, Card, Row, Col, Form } from 'antd';
import ImsType from '../../components/deliveryapp/ims/ims_type';
import ImsA from '../../components/deliveryapp/ims/ims_a';
import ImsB from '../../components/deliveryapp/ims/ims_b';
import ImsCDE from '../../components/deliveryapp/ims/ims_cde';
import ImsFormula from '../../components/deliveryapp/ims/ims_formula';
import ImsSubmit from '../../components/deliveryapp/ims/ims_submit';
import ImsBilling from '../../components/deliveryapp/ims/ims_billing';
import { openNotificationWithIcon } from '../../_modules/notification';
import ImsG from '../../components/deliveryapp/ims/ims_g';
import ImsH from '../../components/deliveryapp/ims/ims_h';
import ImsJ from '../../components/deliveryapp/ims/ims_j';
import moment from 'moment'

const ims_service = new DeliveryApp()

function IMS() {
  const [form] = Form.useForm();
  const [spinEnable, setSpinEnable] = useState(true)
  const [id, setId] = useState(false)
  const [imsData, setImsData] = useState(false)
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [shiftTiming, setShiftTiming] = useState(false)
  const [state, setState] = useState({ valueByDarkstore: 'ALL', timeType: "Shift_FT1", shiftType: 'FULL_TIME', type: 'NORMAL', deduct_cod: false });
  const [billingValue, setBillingValue] = useState(false)
  const { valueByDarkstore, timeType, shiftType, type, deduct_cod } = state
  const [period] = useState([
    { period: '15_DAYS', name: '15 Days', id: 1 }, { period: 'WEEKLY', name: 'Weekly', id: 2 }, { period: 'MONTHLY', name: 'Monthly', id: 3 }])
  const [billingChange, setBillingChange] = useState(false)
  useEffect(() => {
    getShift()
  }, [])

  useEffect(() => {
    if (id) {
      getImsList()
    }
  }, [id, valueByDarkstore])

  useEffect(() => {
    if (imsData && imsData.billing_cycle !== "") {
      setBillingValue(period.find((x) => x.period == imsData.billing_cycle).id)
    }
  }, [imsData])

  const getShift = () => {
    ims_service.get_ims_shift().subscribe((r) => {
      setId(r.response.data[0].id)
      setShiftTiming(r.response.data)
    }, ((err) => {
      console.log("err", err)
    }))
  }


  const handleOnFormChange = (value, billingValue) => {
    console.log("hereeeeeee")
    if (billingValue) {
      setBillingChange(true)
      setBillingValue(billingValue)
    }
    setIsFormChanged(value)
  }

  function getImsList() {
    setImsData(false)
    const payload = {
      store_code: valueByDarkstore,
      executive_type: type,
      job_type: shiftType,
      executive_shift: timeType
    }
    ims_service.get_ims(payload).subscribe((r) => {
      setSpinEnable(false)
      setImsData(r?.response?.data ?? null)
    },
      (err) => {
        console.log(err)
      })
  }

  function onChangeDropdown(value) {
    setState(prevState => ({ ...prevState, valueByDarkstore: value }))
  }

  function selectTime(time, shift, type, ID) {
    setId(ID)
    handleOnFormChange(false)
    form.resetFields()
    setState(prevState => ({ ...prevState, timeType: time, shiftType: shift, type: type }))
  }

  function handleOnSubmit(values) {
    let patternCheck = ['A', 'B', 'C', 'D', 'E', 'M', 'I', 'N', 'M', 'A', 'X', "(", ")", "/", "*", "O", "R", "&", "+", "-", "F", "[", "]", "F", "G", "H", "I", "J"]
    let formulaArray = values.formula.trim().toUpperCase().split('')
    let formulaOk = false
    let min = false
    let max = false
    const counts = {};

    for (let i = 0; i <= formulaArray.length - 1; i++) {
      if (patternCheck.includes(formulaArray[i])) {
        if (formulaArray[i] === 'M') {

          if ((formulaArray[i] === 'M' && formulaArray[i + 1] === 'I' && formulaArray[i + 2] === 'N')) {
            min = true
          } else if (formulaArray[i] === 'M' && formulaArray[i + 1] === 'A' && formulaArray[i + 2] === 'X') {
            max = true
          } else {
            formulaOk = false
            min = false
          }

          if (formulaArray[i] === 'M' && formulaArray[i + 1] === 'A' && formulaArray[i + 2] === 'X') {
            max = true
          } else if ((formulaArray[i] === 'M' && formulaArray[i + 1] === 'I' && formulaArray[i + 2] === 'N')) {
            min = true
          } else {
            max = false
            formulaOk = false
          }

          if (formulaArray[i] === 'O' && formulaArray[i + 1] === 'R') {
          } else {
            formulaOk = false
          }
        }
        formulaOk = true
      } else {
        formulaOk = false
        break
      }
    }

    formulaArray.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
    if (max) {
      if (counts['A'] !== 2) {
        formulaOk = false
      }
      if (counts['X'] > 1) {
        formulaOk = false
      }
    }

    if (min) {
      if (counts['I'] > 1) {
        formulaOk = false
      }
      if (counts['N'] > 1) {
        formulaOk = false
      }
    }

    if (counts['A'] > 1 && !max) {
      formulaOk = false
    }

    if (counts['B'] > 1) {
      formulaOk = false
    }
    if (counts['F'] > 1) {
      formulaOk = false
    }
    if (counts['C'] > 1) {
      formulaOk = false
    }
    if (counts['D'] > 1) {
      formulaOk = false
    }
    if (counts['E'] > 1) {
      formulaOk = false
    }
    if (!formulaOk) {
      openNotificationWithIcon("error", "Invalid formula")
      return
    }
    console.log("heree", values['G'])
    const payload = {
      ...values,
      city: localStorage.getItem('city'),
      job_type: shiftType,
      executive_type: type,
      executive_shift: timeType,
      store_code: valueByDarkstore ? valueByDarkstore : '',
      created_by: JSON.parse(localStorage.getItem('auth')).uid,
      deduct_cod: deduct_cod, // for the switch
      A: {
        ...values['A'],
        login_time: values?.A?.login_time ? moment(values.A?.login_time).format("HH:mm") : null
      },
      // G: {
      //   ...values.G ? [...values.G] : null,
      //   start_time: values?.G?.G_time?.length ? moment(values.G?.G_time[0]).format("HH:mm") : null,
      //   end_time: values?.G?.G_time?.length ? moment(values.G?.G_time[1]).format('HH:mm') : null,
      // },
      // I: {
      //   earning_based_incentive_config: values.I,
      //   period: values.I[0]?.period,
      // },
      J: {
        rain_surge_incentive_config: values['J'],
      },
      // ${localStorage.getItem('city')
    }

    if (payload?.G) {
      if (payload.G.type) {
        payload.G = {
          ...values.G,
          start_time: values?.G?.G_time?.length ? moment(values.G?.G_time[0]).format("HH:mm") : null,
          end_time: values?.G?.G_time?.length ? moment(values.G?.G_time[1]).format('HH:mm') : null,
        }
      }
      if (payload?.G?.type === "PER_ORDER") {
        delete payload.G.order_threshold
      }
    }

    if (payload?.I) {
      for (let i = 0; i < values.I.length; i++) {
        if (payload.I.length > 1) {
          if (payload.I[i].earning_threshold > payload.I[i + 1].earning_threshold) {
            openNotificationWithIcon("error", "Order count Value must be greater than previous fields - I")
            return
          }
        }
        break
      }
      payload.I = {
        earning_based_incentive_config: payload.I ? payload.I : null,
        period: payload.I[0]?.period,
      }
    } else {
      payload.I = {
        earning_based_incentive_config: null,
      }
    }

    // if (payload?.G?.type === "PER_ORDER") {
    //   delete payload.G.order_threshold
    // }

    if (values.C) {
      if (values?.C?.isArray) {
        payload.C = {}
      } else {
        // console.log("values.C", values.C)
        for (let i = 0; i < values.C.length; i++) {
          if (values.C.length > 1) {
            if (values.C[i].order_threshold > values.C[i + 1].order_threshold) {
              openNotificationWithIcon("error", "Order count Value must be greater than previous fields")
              return
            }
          }
          break
        }
        // console.log("4")
        payload.C = {
          period: values?.C[0]?.period,
          order_incentive_config: values?.C
        }
      }
    } else {
      payload.C = {}
    }

    // console.log("payload, values", payload)

    ims_service.update_ims(payload).subscribe((r) => {
      openNotificationWithIcon("success", "Plan updated")
      // getImsList()
      setIsFormChanged(false)
    }, ((err) => {
      console.log("err", err)
      openNotificationWithIcon('error', err.response?.errors?.[0] ?? 'API Error')
    }))
  }

  return (
    <div>
      {localStorage.getItem('city') && shiftTiming ?
        <div>
          <div className='frz-vertical-center layer'>
            <div className='frz-ims-text'>
              Incentive Management
            </div>
            <Search placeholder="Select Darkstore Name" value={valueByDarkstore}
              onChange={(e) => onChangeDropdown(e, 'valueByDarkstore')} type="valueByDarkstoreStatsCode" />
          </div>
          <Card className='no-padding-card'>
            <div className='frz-ims-container'>
              <Row>
                <Col span={6} className='frz-ims-border'>
                  <ImsType id={id} selectTime={selectTime} timeType={timeType} shiftType={shiftType} type={type}
                    isFormChanged={isFormChanged} shiftTiming={shiftTiming} />
                </Col>
                <Col span={18}>
                  <div className='frz-ims-container-card'>
                    {[imsData].length && imsData ?
                      <>
                        <ImsBilling imsData={imsData} form={form} handleOnFormChange={handleOnFormChange} />
                        <ImsA imsData={imsData['A']} form={form} handleOnFormChange={handleOnFormChange} billingValue={billingValue} />
                        <ImsB form={form} handleOnFormChange={handleOnFormChange} radioType={imsData["B"]} />
                        <ImsCDE form={form} handleOnFormChange={handleOnFormChange} billingValue={billingValue} billingChange={billingChange} />
                        <ImsG form={form} handleOnFormChange={handleOnFormChange} radioType={imsData["G"]} />
                        <ImsH form={form} handleOnFormChange={handleOnFormChange} billingValue={billingValue} />
                        <ImsJ form={form} handleOnFormChange={handleOnFormChange} />
                        <ImsFormula form={form} handleOnFormChange={handleOnFormChange} />
                        <ImsSubmit form={form} setState={setState} isDeductCOD={imsData["deduct_cod"]} handleOnSubmit={handleOnSubmit} />
                      </>
                      : <div className="spin-center">
                        <Spin tip="...Loading" />
                      </div>}
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
        :
        (localStorage.getItem('city') ?
          <div className="spin-center">
            <Spin tip="...Loading" />
          </div>
          :
          <div className="no-data">Please Select A City</div>
        )
      }
    </div>
  )
}

export default IMS