import React, {useEffect, useState} from 'react';
import {Card , Radio, Spin, Select, DatePicker} from 'antd';
import { Bar } from 'react-chartjs-2';
import {StatisticsService} from '../../_services/statistics';
import moment from 'moment';
import Search from '../antd/search';
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;

const stats_service =  new StatisticsService()

function TopSkus(props){
    const [renderData, setRenderData] = useState(false)
    const [renderDataGraph, setRenderDataGraph] = useState(false)
    const [state, setState] = useState({type: 'daily', start_date: '', end_date: '', showDateValue: false,
                                        valueByDarkstore: 'all', currentProduct: 'all', limit: 'top10'});
    const presetData = {
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [
            '#FF6384','#36A2EB','#FFCE56','green','orange','brown','cyan','darkgrey','pink','purple','peacock','berry','gold','sage','mauve'
            ],
            data1: []
        }]
    } 
    const [graphData, setGraphData] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [
            '#FF6384','#36A2EB','#FFCE56','green','orange','brown','cyan','darkgrey','pink','purple','peacock','berry','gold','sage','mauve'
            ],
            data1: []
        }]
    })
    const { type, start_date, end_date, showDateValue, valueByDarkstore, currentProduct, limit } = state

    useEffect(() => {
        if(!renderData){
          setRenderData(true)
          getTopSkus()
        }
      })

    function getTopSkus(){
        const payload = {
          type: type === undefined ? '' : type,
          start_date: start_date,
          end_date: end_date,
          by_darkstore: valueByDarkstore === 'all' ? '' : valueByDarkstore,
          by_product: currentProduct === 'all' ? '' : currentProduct,
          limit: limit === undefined ? '' : limit
        }
        stats_service.get_top_skus(payload).subscribe((r) => {
          setStatsData(r.response.data)
        },
        (err)=>{
          console.log(err)
        })
    }

    function onChangeRangePicker(value, dateString){
        setState(prevState =>({...prevState, showDateValue: true, start_date :dateString[0], end_date:dateString[1], type: undefined}))
        setRenderDataGraph(false)
        setRenderData(false)
        setGraphData(presetData)
    }

    function onChange(value, type) {
        if(type === 'type'){
            setState(prevState =>({...prevState, [type]: value, start_date: '', end_date: '', showDateValue: false}))
        }else if(type === 'limit'){
            setState(prevState =>({...prevState, [type]: value, currentProduct: 'all'}))
        }else if(type === 'currentProduct' && value !== 'all'){
            setState(prevState =>({...prevState, [type]: value, limit: undefined}))
        }else if(type === 'currentProduct' && value == 'all'){
            setState(prevState =>({...prevState, [type]: value, limit: 'top10'}))
        }else{
            setState(prevState =>({...prevState, [type]: value}))
        }
        setRenderDataGraph(false)
        setRenderData(false)
        setGraphData(presetData)
     }

    function setStatsData(data) {
        data.top_seller_skus.map(val => {
            graphData.labels.push(val.name)
            graphData.datasets[0].data.push(val.total_order_count)
            graphData.datasets[0].data1.push(val.percentage)
        })
        // console.log(graphData)
        setGraphData(graphData)
        setRenderDataGraph(true)
    }

    function disabledDate(current){
        // Can select days before today and today
        return current && current > moment().add(0, "day");
      }

    return(
        <div>
            <Card>
                <h2 style={{margin: 10}}>Top Selling SKU's By Customer Orders: </h2>
                <div className="layer">
                    <Search placeholder="Darkstore Name" value={valueByDarkstore}
                        onChange={(e) => onChange(e, 'valueByDarkstore')} type="valueByDarkstoreStats"/>
                    <Select style={{ width: 130, float: 'right', padding: 10}} value={limit}
                            placeholder="Limit" onChange={(e) => onChange(e, 'limit')}>
                        <Option value='top10'>Top 10</Option>
                        <Option value='bottom10'>Bottom 10</Option>
                        <Option value='zero_orders'>Zero Orders</Option>
                    </Select>
                </div>
                <div style={{margin: 10, marginTop: 0}}>
                    <Select style={{ width: 150}} value={type}
                            placeholder="Select Type" onChange={(e) => onChange(e, 'type')}>
                        <Option value='daily'>Yesterday</Option>
                        <Option value='weekly'>Weekly</Option>
                        <Option value='this_month'>This Month</Option>
                        <Option value='last_month'>Last Month</Option>
                        <Option value='quarterly'>Quarterly</Option>
                        {/* <Option value='half_yearly'>Half Yearly</Option> */}
                    </Select>
                    <RangePicker onChange={onChangeRangePicker} style={{float:'right'}}
                            value={showDateValue ? [moment(start_date, dateFormat),
                            moment(end_date, dateFormat)] : '' } format={dateFormat}
                            allowClear={false} disabledDate={disabledDate}/>
                </div>
                <div style={{margin: 10, borderBottom: '1px solid rgb(240,240,240', paddingBottom: 20}}>
                    {props.productData ? 
                        <Select style={{ width: 200}} value={currentProduct} showSearch
                            placeholder="Select Product" onChange={(e) => onChange(e, 'currentProduct')}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                    <Option key='all'value='all'>All SKU's</Option>
                            {props.productData.map(data => {
                                return(
                                    <Option key={data.id} value={data.id}>{data.name}</Option>
                                )
                            })}
                        </Select>
                    : null}
                </div>
                {renderDataGraph ? 
                   <div>
                        <Bar 
                            data={graphData}
                            options={{
                                    tooltips: {
                                        titleFontSize:13,
                                        bodyFontSize:15,
                                        enabled: true,
                                        displayColors:false,
                                        callbacks: {
                                            title: (tooltipItem, data) => {
                                                return data['labels'][tooltipItem[0]['index']];;
                                            },
                                            label: (tooltipItem, data) => {
                                                let tooltipData = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                                return `Order Count : ${tooltipData.toLocaleString('en-IN')}`;
                                            },
                                            afterLabel: (tooltipItem, data) => {
                                                let tooltipData = data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index].toFixed(2);
                                                return `Percentage : ${tooltipData.toLocaleString('en-IN')}%`;
                                            }
                                        }
                                    },
                                    scales: {
                                        yAxes : [{
                                          ticks : {
                                            min : 0
                                          }
                                        }]
                                    }
                            }}
                            legend={{"display": false}}
                            width={50} height={35} redraw={true}
                        />
                   </div>
                : 
                  <div className="spin-center">
                    <Spin tip="...Loading"/>
                  </div>
                }
            </Card>
        </div>
    )
}

export default TopSkus