import React, { useEffect, useState } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {LogsService} from '../../_services/logs';
import MainTable from '../../components/antd/table';
import Search from '../../components/antd/search';
import {serviceableColumns} from '../../components/antd/columns/logs';
import {handleDateFormat} from '../../_modules/date';
import {getStaticTime} from '../../_modules/time';

const log_service = new LogsService()

function Serviceable(){
  const [logData, setLogData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [state, setState] = useState({valueByDarkstore: undefined});
  const { valueByDarkstore } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getIsServiceableLogs()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getIsServiceableLogs(){
    setSpinEnable(true)
    setLogData([])
    const payload = {
      page: current,
      by_darkstore: valueByDarkstore === undefined ? '' : valueByDarkstore,
    }
    log_service.get_is_serviceable_logs(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setSpinEnable(false)
      setLogData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    serviceableColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: "Updated On",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <>
            <div>{handleDateFormat(record.created_at)}</div>
            <div>{getStaticTime(record.created_at)}</div>
          </>
        );
      },
    })
    setColumns(columns)
  }

  function pagination(page) {
    setCurrent(page)
    setLogData([])
    setRenderData(false)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function reset() {
    setState({valueByDarkstore: undefined})
    setDisable(true)
    setCurrent(1)
    setLogData([])
    setRenderData(false)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  return(
    <div className="layer">
      {logData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Darkstore Name" value={valueByDarkstore}
                        onChange={onChange} type="valueByDarkstore"/>
                <Button disabled={disable} onClick={getIsServiceableLogs} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
              </Col>
            </Card>
          </Row>
          {logData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={logData} columns={columns}/>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Logs Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default Serviceable
