import React, { useState } from 'react'
import { Spin, Modal, Button, Row, Col, Tabs, notification, 
    Select, Input, InputNumber, Radio} from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import Search from './search_product';
import {OrderService} from '../../_services/orders';

const order_service = new OrderService()
const { Option } = Select;
let timeout = null

function ActiveCart(props){
    const [showError, setShowError] = useState(false)
    const [searchData, setSearchData] = useState(false)
    const [searchValue, setSearchValue] = useState('')

    function showErrorDetail(e){
        e.stopPropagation();
        setShowError(true)
        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    function onChange (val) {
        let payload
        if(val === " "){
          payload = {
            value: val.split(' ').join('')
          }
          setSearchValue(val.split(' ').join(''))
        }else{
          payload = {
            value: val
          }
          setSearchValue(val)
        }
        clearTimeout(timeout);
        if(payload.value.length >= 3){
          timeout = setTimeout(() => {
            order_service.get_search(payload, props.customerData.id).subscribe((r) => {
              // console.log(r.response)
              setSearchData(r.response.data)
              document.getElementsByTagName("body")[0].style = 'overflow-y: hidden'
            })
          }, 100);
        }else{
          setSearchData(false)
          document.getElementsByTagName("body")[0].style.removeProperty('overflow-y')
        }
      }

    return(
        <div>
            <div className="order-header">
                <b>#Customer Cart</b>
                {/* {props.updateCart && props.productCatalogue.length > 0 ?
                    <span>
                        <Button type="primary" ghost shape="round" icon={<PlusOutlined />} 
                                size="small" style={{float: 'right', marginLeft: 16, paddingTop: 1}}
                                onClick={() => props.addCart('firstAdd')}/>
                        <Select style={{ width: 300, float: 'right', marginTop: '-4px'}} value={props.productDrop}
                                placeholder="Select Product"
                                onChange={(e) => props.onChangeModal(e, 'productDrop')}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {props.productCatalogue.map((data, index) => {
                                return (
                                    <Option key={index} value={data.id}>{data.name}</Option>
                                )
                            })}
                        </Select>
                    </span>
                    : null
                } */}
            </div>
            {props.updateCart ? 
                <div className="search-spacing">
                    <div className={`${searchData ? "frz-overlay" : false}`}
                        onClick={() => onChange("")}></div>
                    <div className="frz-p-relative" style={{zIndex: 100}}>
                    <div className={`${"frz-tm-20"} ${searchData ? "frz-search-active" : false}`}>
                    <input type="text" className="frz-search frz-w-100 frz-search-padding"
                            placeholder="Search fresh vegetables, fruits"
                            onChange={(e) => onChange(e.target.value)}
                            value={searchValue} required/>
                    </div>
                        <Search searchData={searchData} currentCart={props.currentCart}
                                addCart={props.addCart}/>
                    </div>
                </div>
            : null}
            {!props.spinEnableCart ? 
                <div>
                    {props.updateCart ?
                        <div>
                            {props.updateCart.cart_items ? 
                                <div>
                                    <Row className="order-detail-row">
                                    <Col xs={10}>
                                        Items
                                    </Col>
                                    <Col xs={6} className="text-center">
                                        Qty
                                    </Col>
                                    <Col xs={4} className="text-center">
                                        Unit Price
                                    </Col>
                                    <Col xs={4} className="text-center">
                                        Amount
                                    </Col>
                                    </Row>
                                    {props.updateCart.cart_items.map((data, index) => {
                                        return(
                                        <Row key={index} className="order-detailitem-row">
                                            <Col xs={2}>
                                            <img className="order-detail-img" src={data.product_detail.image_url} alt="item"/>
                                            </Col>
                                            <Col xs={8}>
                                            <div className="vertical-center" style={{paddingLeft: 8}}>
                                                <div className="line-item-name">{data.product_detail.name}</div>
                                                <div className="line-item-moq">
                                                {data.product_detail.pack_size}
                                                {!data.product_detail.in_stock ? 
                                                    <span className="out-stock">(Out of Stock)</span>
                                                : null}
                                                </div>
                                            </div>
                                            </Col>
                                            <Col xs={6}>
                                            <div className="line-item-name vertical-center-metric">
                                                <button className="frz-product-minus"
                                                        onClick={() => props.addCart('min', data.product_detail.id)}>
                                                        -   
                                                </button>
                                                {data.qty}
                                                {data.qty < data.product_detail.max_qty ?
                                                    <button className="frz-product-plus"
                                                            onClick={() => props.addCart('max', data.product_detail.id)}>
                                                            +   
                                                    </button>
                                                    : 
                                                    <span className="frz-Qty">
                                                        <button className="frz-product-plus"
                                                            onClick={(e) => showErrorDetail(e)}>
                                                            +   
                                                        </button>
                                                        {showError ? 
                                                            <div className="tooltiptext">
                                                                Max Qty {data.product_detail.max_qty}
                                                            </div>
                                                        : null}
                                                    </span>
                                                }
                                            </div>
                                            </Col>
                                            <Col xs={4}>
                                            <div className="line-item-name vertical-center-metric">
                                                ₹ {data.moq_effective_unit_price}
                                            </div>
                                            </Col>
                                            <Col xs={4}>
                                            <div className="line-item-name vertical-center-metric">
                                                ₹ {data.nett_amount}
                                            </div>
                                            </Col>
                                        </Row>
                                        )
                                    })}
                                </div>
                                : 
                                <h3 style={{paddingTop: 30, textAlign: 'center'}}>Please add SKU's</h3>
                            }
                        </div>
                    : <h3 style={{paddingTop: 30, textAlign: 'center'}}>Please fill customer profile & address</h3>}
                </div>
            : 
                <div className="spin-center">
                    <Spin tip="...Loading"/>
                </div>
            }
        </div>
    )
}

export default ActiveCart