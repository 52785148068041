import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Pagination, Input, DatePicker } from 'antd';
import { AdsService } from "../../_services/ads";
import MainTable from '../../components/antd/table';
import { campaignListingColumns } from "../../components/antd/columns/ads";
import { openNotificationWithIcon } from '../../_modules/notification'
import { BannersService } from '../../_services/banners';
import WebAppCampaignModal from '../../components/ads/campaign_modal';

const ads_service = new AdsService();
const banner_service = new BannersService();

const WebAppCampaign = () => {
  const modalRef = useRef(null);
  const [listingData, setListingData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [mode, setMode] = useState(undefined)
  const [total, setTotal] = useState(false)
  const [current, setCurrent] = useState(1);
  const [products, setProducts] = useState(false)

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getListing()
      getProducts()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getListing() {
    setSpinEnable(true)
    setListingData([])
    const payload = {
      page: current,
    }
    ads_service.get_webapp_campaign(payload).subscribe((r) => {
      setTotal(r.response.meta.total_pages * 50);
      setSpinEnable(false);
      setListingData(r.response.data);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  function getProducts() {
    banner_service.get_product_list().subscribe((r) => {
      let newResponse = r.response.data.map(x => ({...x, customname: x.name + ' - ' + x.pack_size}))
      setProducts(newResponse)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns() {
    campaignListingColumns.map(data => {
      columns.push(data)
    })
    columns.push(
    {
        title: "Web Image",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
            return (
            <img src={record.web_url} height={80} />
            );
        },
    },
    {
        title: "Mobile Image",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
            return (
            <img src={record.mobile_url} height={80} />
            );
        },
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <Button type="primary" onClick={() => showModal(record, 'Edit')}>Edit</Button>
        );
      },
    });
    setColumns(columns)
  }

  function showModal(data, type) {
    setMode(type)
    modalRef.current.showModal(type, data)
  }

  function reset() {
    setDisable(true)
    setCurrent(1)
    setListingData([])
    setRenderData(false)
  }


  function onChange(value, type) {
    setDisable(false)
    setCurrent(1)
  }

  function pagination(page) {
    setCurrent(page);
    setListingData([]);
    setRenderData(false);
  }

  function modalSubmit(payloadData, id) {
    const payload = {
      id: id, 
      body: {
        ...payloadData
      }
    }
    if (mode === 'Edit') {
      ads_service.update_webapp_campaign(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Edited Successfully')
        getListing()
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors?.[0] ?? 'API Error')
        })
    } else {
      ads_service.create_webapp_campaign(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Created Successfully')
        getListing()
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors?.[0] ?? 'API Error')
        })
    }
  }

  return (
    <div className="layer-nofilter">
      {listingData && products ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Create Campaign')} className="frz-dwn"
                  type="primary">Create Campaign</Button>
              </Col>
            </Card>
          </Row>
          {listingData.length > 0 ?
            <>
              <Row>
                <Col span={24}>
                  <MainTable dataSource={listingData} columns={columns} />
                  <Pagination style={{ marginTop: 10, marginBottom: 10 }} current={current}
                    pageSize={50} total={total} onChange={pagination} showSizeChanger={false} />
                </Col>
              </Row>
            </>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          {/* <ForcastListingModal modalSubmit={modalSubmit} ref={modalRef}  products={products} city={city}/> */}
          <WebAppCampaignModal modalSubmit={modalSubmit} ref={modalRef} products={products}/>
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default WebAppCampaign
