import React, { useEffect, useState } from "react";
import { Table, Button, Spin, Card } from "antd";
import { EditOutlined } from '@ant-design/icons';
import { cityPriceColumns } from "../../../antd/columns/master/city_price";

const CityPriceUpdate = ({ showModal, allCityList, getDarkstores, activeCity, onCityChange, spinEnable }) => {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    getColumns()
  }, [allCityList])

  function getColumns() {
    let col = [];
    cityPriceColumns.map(data => {
      col.push(data)
    })
    col.push(
      {
        title: 'Edit',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (<Button title="Edit Details" icon={<EditOutlined />}
            onClick={(e) => {
              showModal(record, '', 'checkbox')
            }} >
          </Button>)
        }
      })
    setColumns(col);
  }

  const handleOnRowClick = (value) => {
    if (activeCity != value.name) {
      getDarkstores(value.id)
    }
  }

  return (
    <div>
      {allCityList && !spinEnable ?
        <Card>
          <Table className="frz-cursor-pointer"
            columns={columns} dataSource={[...allCityList]}
            pagination={false} scroll={{ y: 'calc(100vh - 240px)' }}
            rowClassName={(record) => {
              return activeCity === record.name ? 'frz-payment-highlight' : ''
            }}
            rowKey="id"
            onRow={(record) => ({
              onClick: (e) => handleOnRowClick(record)
            })}
          />
        </Card>
        : <div className="spin-center"> <Spin tip="...Loading" /> </div>}
    </div>

  );

};

export default CityPriceUpdate;

// { if(activeCity!== record.name) getDarkstores(record.id); }