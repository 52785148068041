import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form } from 'antd';
import FRZInput from '../../_controls/FRZInput';
import FRZInputNumber from '../../_controls/FRZInputNumber';
import { formItemLayout } from '../../_modules/controllayout';
import FRZDatepicker from '../../_controls/FRZDatepicker';
import moment from 'moment';
import FRZSelect from '../../_controls/FRZSelect';
import FRZRadioButton from '../../_controls/FRZRadioButton';
import FRZUpload from '../../_controls/FRZUpload';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const WebAppCampaignModal = forwardRef((props, ref) => {
  const { modalSubmit } = props
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [form] = Form.useForm();
  const [isClose, setIsClose] = useState(false)
  const [imageState, setImageState] = useState({})
  const [pageType] = useState([{option: 'image', name: 'Image'},{option: 'html', name: 'HTML'}])
  const [actionType] = useState([{option: 'add_to_cart', name: 'Add To Cart'},{option: 'registration', name: 'Registration'},
                                 {option: 'login', name: 'Login'}])
  const [pageTypeVal, setPageTypeVal] = useState(false)
  const [actionTypeVal, setActionTypeVal] = useState(false)

  useEffect(() => {
    if ((data && title == "Edit")) {
      form.setFieldsValue({ ...data, valid_from: data && moment(data.valid_from), valid_to: data && moment(data.valid_to)})
    }
  }, [data])

  function handleOnSubmit(values) {
    const payload = { ...values, valid_from: moment(values.valid_from).format("YYYY-MM-DD"),
                     valid_to: moment(values.valid_to).format("YYYY-MM-DD"),
                     ...imageState }
    setLoading(true)
    modalSubmit(payload, data.id)
  }

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
      setPageTypeVal(data.landing_page_type)
      setActionTypeVal(data.action_type)
      resetUploads()
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      setIsClose(true)
      setData(false)
      form.resetFields()
    },

    stopLoading() {
      setLoading(false)
    }

  }));


  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    setIsClose(true)
    setData(false)
    form.resetFields()
  }

  function handleClick({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  const handleOnChangeImage = (type, item) => {
    setImageState(prevState => ({ ...prevState, [type]: item }))
  }

  const uploadButton = (
    <div>
      {props.imgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const resetUploads = () => {
    setImageState({})
  }

  function handleOnChangeType (value) {
    setPageTypeVal(value)
  }

  function handleOnChangeActionType (value) {
    setActionTypeVal(value)
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            <FRZInput name="campaign_name" isRequired={true}
              label="Campaign Name" placeholder="Campaign Name" width={200} />

            <FRZInput name="campaign_id" isRequired={true}
              label="Campaign Id" placeholder="Campaign Id" width={200} />

            <FRZInput name="campaign_url" isRequired={true} isTextArea={true}
              label="Campaign Url" placeholder="Campaign Url" width={200} />

            <FRZSelect DropDownData={pageType} name="landing_page_type" isRequired={true}
              label="Landing Page Type" placeholder="Select Page Type" value="option" option="name"
              isShowSearch={true} width={200} setOnChange={handleOnChangeType}/>

            {pageTypeVal === 'image' ? 
              <>
                <FRZUpload name="mobile_image" className="avatar-uploader" customRequest={handleClick}
                  imgWidth={"100%"} label={"Mobile Image"} listType="picture-card" setOnChangeType={"mobile_image"}
                  showUploadList={false} uploadButton={uploadButton} handleOnChangeImage={handleOnChangeImage}
                  imageState={imageState} isRequired={false}/>
            
                <FRZUpload name="web_image" className="avatar-uploader" customRequest={handleClick}
                  imgWidth={"100%"} label={"Web Image"} listType="picture-card" setOnChangeType={"web_image"}
                  showUploadList={false} uploadButton={uploadButton} handleOnChangeImage={handleOnChangeImage}
                  imageState={imageState} isRequired={false}/>
              </> 
            : null}
            
            {pageTypeVal === 'html' ? 
              <>
                <FRZInput name="mobile_url" isRequired={true} isTextArea={true}
                  label="Mobile Url" placeholder="Mobile Image Url" width={200} />
                
                <FRZInput name="website_url" isRequired={true} isTextArea={true}
                  label="Website Url" placeholder="Web Image Url" width={200} />
              </> 
            : null}  

            <FRZSelect DropDownData={actionType} name="action_type" isRequired={true}
              label="Action Type" placeholder="Select Action Type" value="option" option="name"
              isShowSearch={true} width={200} setOnChange={handleOnChangeActionType} isDisabledVal={['registration', 'login']}
              optionDisabledKey='option'/>
            
            {actionTypeVal === 'add_to_cart' ? 
              <>
                <FRZInput name="coupon_code" isRequired={false}
                  label="Coupon Code" placeholder="Coupon Code" width={200} />

                <FRZSelect DropDownData={props.products} name="product_id" isRequired={true}
                  label="Product" placeholder="Select Product Name" value="id" option="customname"
                  isShowSearch={true} width={"100%"} />

                <FRZInputNumber name="unit" isRequired={true}
                  label="Quantity" placeholder="Quantity" width={200} />
              </>
            : null}

            <FRZRadioButton name="is_active" label="Is Active" isRequired={true} />

            {/* <FRZDatepicker placeholder="Select Date" label="Valid From" 
              name="valid_from" isRequired={true} />

            <FRZDatepicker placeholder="Select Date" label="Valid To" 
              name="valid_to" isRequired={true} /> */}

          </Form>
        </Modal>
        : <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})

export default WebAppCampaignModal
