import React from 'react'
import { Form, TimePicker } from 'antd'
import { tailLayout } from '../_modules/controllayout'
const FRZTimePicker = ({ name, label, isRequired, placeholder, isDisabled, setOnChange, format }) => {

    const onChange = (e, timeString) => {
        if (setOnChange) {
            setOnChange(e, timeString)
        }
    }

    return (
        <Form.Item
            {...tailLayout}
            name={name}
            label={label}
            rules={isRequired ? [
                {
                    required: true,
                    message: `Please input the value of ${label}!`,
                },
            ] : []}
        >
            <TimePicker placeholder="Select Time"
                onChange={onChange}
                format={format ? format : "HH:mm"}
                use24Hours
                showNow={false}
                disabled={isDisabled}
                style={{ width: '200px' }} />
        </Form.Item>
    )
}

export default FRZTimePicker
