import { Http } from '../_modules/http'

export class RolesAndShiftsService {

  http = new Http()
  get_roles_and_shifts(id) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/admin/shift?city_id=' + id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  create_roles_and_shifts(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/admin/shift?city_id=' + payload.city_wise_shift.city_id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  update_roles_and_shifts(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/admin/shift/'+ payload.id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }
}
