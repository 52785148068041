import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button, Select, Input, InputNumber,
         Radio} from 'antd';
import { useSelector } from "react-redux";
const { Option } = Select;
const { TextArea } = Input;

const FBPixelModal = forwardRef((props, ref) => {
  const darkstore = useSelector(state => state.auth.darkstore);
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOk() {
    setLoading(true)
    props.modalSubmit()
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.onCancelModal();
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onOk={handleOk}
          width='50%'
          onCancel={handleCancelModal}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              Submit
            </Button>,
          ]}
        >
          {title === 'Add Deep Link' ? 
            <div>
              <div className="block">URL :
                <span style={{paddingLeft:10}}>
                  <TextArea placeholder="Enter URL" autoSize={{ minRows: 2, maxRows: 3 }}
                            value={props.url} style={{width:300}}
                            onChange={(e) => props.onChangeModal(e.target.value, 'url')}/>
                </span>
              </div>
              {props.firebaseLink ? 
                <div className="block">Firebase Link :
                    <span style={{paddingLeft:10}}>
                    <b>{props.firebaseLink}</b>
                    </span>
                </div>
              : null}
            </div>
          : <div>
              <div className="block" style={{marginLeft:27}}>Send SMS To <span style={{color: 'red'}}>* &nbsp;</span> :
                <span style={{paddingLeft:10}}>
                <Radio.Group
                        value={props.valueByRadio}
                        onChange={(e) => props.onChangeModalFilter(e.target.value, 'valueByRadio')}>
                    <Radio value='all_darkstores'>All Darkstores Customers</Radio>
                    <Radio value='individual_darkstores'>Individual Darkstore Customers</Radio>
                </Radio.Group>
                </span>
              </div>
              {props.valueByRadio === 'individual_darkstores' ? 
                  <div className="block" style={{marginLeft:36}}>Darkstores <span style={{color: 'red'}}>* &nbsp;</span> :
                    <span style={{paddingLeft:10}}>
                      <Select style={{ width: 430}} 
                              mode="tags"
                              value={props.valueByDarkstore}
                              placeholder="Darkstores" onChange={props.onChangeDarkstore}>
                        {darkstore.map(data => {
                          return(
                            <Option key={data.id} value={data.id}>
                              {data.name}
                            </Option>
                          )
                        })}
                      </Select>
                    </span>
                  </div>
              : null}
              {props.valueByRadio === 'all_darkstores' ? 
                  <div className="block">Exclude Darkstores :
                    <span style={{paddingLeft:10}}>
                      <Select style={{ width: 430}} 
                              mode="tags"
                              value={props.exclude_darkstore}
                              placeholder="Exclude Darkstores" onChange={props.onChangeModalExcludeDS}>
                        {darkstore.map(data => {
                          return(
                            <Option key={data.id} value={data.id}>
                              {data.name}
                            </Option>
                          )
                        })}
                      </Select>
                    </span>
                  </div>
              : null}
              {props.valueByRadio ? 
                <div className="block" style={{marginLeft:50}}>Message <span style={{color: 'red'}}>* &nbsp;</span> :
                    <span style={{paddingLeft:10}}>
                    <TextArea placeholder="Message" autoSize={{ minRows: 2, maxRows: 3 }}
                                value={props.message} style={{width:290}}
                                onChange={(e) => props.onChangeModal(e.target.value, 'message')}/>
                    </span>
                </div>
              : null}
            </div>
          }
        </Modal>
      : null }
    </div>
  )
})

export default FBPixelModal
