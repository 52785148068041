import React, { useState } from 'react'
import { Form, Upload } from 'antd'
import { tailLayout } from '../_modules/controllayout';
import { openNotificationWithIcon } from '../_modules/notification';

const FRZUpload = ({ name, label, listType, customRequest, className, showUploadList, dimension, customValidations,
  imgWidth, uploadButton, setOnChangeType, isRequired, handleOnChangeImage, imageState, showFileName, handleOriginalFileDetail }) => {

  const [fileName, setFileName] = useState("")

  const onChange = (e) => {
    handleChangeImage(e, setOnChangeType)
  }
  //14708
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
    if(customValidations){
      let img1 = new Image()
      img1.src = window.URL.createObjectURL(img)
      img1.onload = () => {
        if (img1.width <= customValidations && img1.height <= customValidations) {
          if (img1.height === img1.width) {
          } else {
            openNotificationWithIcon('error', `Uploaded image dimension ${img1.width} x ${img1.height} is not equal,
             please upload equal dimension's image.`)
          }
          // upload logic here
        } else {
          openNotificationWithIcon('error', `Uploaded image dimension ${img1.width} x ${img1.height} is too large,
           please upload standard ${customValidations} x ${customValidations} dimension image.`)
        }
      }
    }
  }

  function handleChangeImage(info, type) {
    setFileName(info.file.name)
    if (handleOriginalFileDetail) {
      handleOriginalFileDetail(info.file.name, info.file.type)
    }
    if (info.file.status === 'uploading') {
      // setImgLoading(true)
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, item => handleOnChangeImage(type, item))
    }
  };

  return (
    <Form.Item
      {...tailLayout}
      label={label}
      extra={showFileName ? fileName : ""}
      rules={isRequired ? [
        {
          required: true,
          message: `Please ${label}!`,
        },
      ] : []}
    >
      {dimension ?
        <b>
          {dimension}
        </b>
        : null
      }
      <Upload onChange={onChange} className={className} showUploadList={showUploadList}
        name="avatar" action="/upload.do" listType={listType} customRequest={customRequest}>
        {imageState[name] ? <img src={imageState[name]} alt="avatar" style={{ width: imgWidth }} /> : uploadButton}
      </Upload>
    </Form.Item>
  )
}

export default FRZUpload
