export const adminColumns = [
  {
    title: "First Name",
    dataIndex: "first_name",
    key: "first_name",
    align: "center",
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    key: "last_name",
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email ",
    align: "center",
  },
  {
    title: "Mobile No",
    dataIndex: "mobile_number",
    key: "mobile_number",
    align: "center",
  },
  {
    title: "Verified",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_verified.toString()
    }
  },
]
