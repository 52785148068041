import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form } from 'antd';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import FRZInput from '../../../_controls/FRZInput';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZSelect from '../../../_controls/FRZSelect';

const CompanyBrandModal = forwardRef((props, ref) => {
  const { modalSubmit } = props
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [form] = Form.useForm();

  useEffect(() => { 
    if (data && (title == "Edit Company" || title == 'Edit Brand')) {
      form.setFieldsValue({ ...data })
    }
  }, [data])

  function handleOnSubmit(values) {
    setLoading(true)
    modalSubmit(values, data?.id)
  }

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      setData(false)
      form.resetFields()
    },

    stopLoading() {
      setLoading(false)
    }

  }));


  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    setData(false)
    form.resetFields()
  }
  
  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            {title === 'Edit Company' || title === 'Create Company' ? 
              <>
                <FRZInput name="company_name" isRequired={true}
                  label="Company" placeholder="Company" width={200} />

                <FRZSelect DropDownData={props.playerData} name="type_of_player" isRequired={true}
                  label="Select Player" placeholder="Player Type" isShowSearch={true} width={200} isDataKey={true}/>
              </>
            : 
              <>
                <FRZInput name="brand_name" isRequired={true}
                  label="Brand" placeholder="Brand" width={200} />

                <FRZSelect DropDownData={props.companyData} name="product_company_id" isRequired={true}
                  label="Company" placeholder="Company" value="id" option="company_name"
                  isShowSearch={true} width={200} />

              </>
            }

          </Form>
        </Modal>
        : <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})

export default CompanyBrandModal