import React, { useEffect, useState, useRef } from 'react';
import {Spin, Button, Card, Row, Col, InputNumber, Pagination, Table, DatePicker} from 'antd';
import { ReloadOutlined, EditOutlined } from '@ant-design/icons';
import { OperationsService } from '../../../_services/operations';
import Search from '../../../components/antd/search';
import MainTable from '../../../components/antd/table';
import { paymentColumns, productGrnColumns } from '../../../components/antd/columns/operations/category';
import PaymentModal from '../../../components/operations/category/payments_modal';
import { openNotificationWithIcon } from '../../../_modules/notification';
import FrzDynamicUpload from '../../../_controls/FRZDynamicUpload';
import FrzDynamicDownload from '../../../_controls/FRZDynamicDownload';
import moment from 'moment';

const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const ops_service = new OperationsService()

function PaymentList() {
  const modalRef = useRef(null);
  const [payData, setPayData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [columnsProducts, setColumnsProducts] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [nodes, setNodes] = useState(false)
  const [suppliers, setSuppliers] = useState(false)
  const [status] = useState([{ id: true, name: 'Active' }, { id: false, name: 'Inactive' }])
  const [state, setState] = useState({ valueByNode: undefined, valueBySupplier: undefined, valueByOrder: undefined,
                                       start_date: '', end_date: '', showDateValue: false, valueByStatus: undefined});
  const { valueByNode, valueBySupplier, valueByOrder, start_date, end_date, 
          showDateValue, valueByStatus } = state

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getPayments()
    }
  })

  useEffect(() => {
    getColumns()
    getNodes()
    getSuppliers()
  }, [])

  function getNodes() {
    const payload = {
      nodeType: 'CC'
    }
    ops_service.get_node_listing(payload).subscribe((r) => {
      setNodes(r.response.data)
    },
    (err) => {
      console.log(err)
    })
  }

  function getSuppliers() {
    ops_service.get_suppliers().subscribe((r) => {
      setSuppliers(r.response.data)
    },
    (err) => {
      console.log(err)
    })
  }

  function getPayments() {
    setSpinEnable(true)
    setPayData([])
    const payload = {
      page: current,
      by_node: !valueByNode ? '' : valueByNode,
      by_supplier: !valueBySupplier ? '' : valueBySupplier,
      by_order_number: !valueByOrder ? '' : valueByOrder,
      start_date: start_date,
      end_date: end_date,
      by_status: valueByStatus === undefined ? '' : valueByStatus
    }

    ops_service.get_payment_list(payload).subscribe((r) => {
      setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
      setSpinEnable(false)
      setPayData(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getColumns() {
    paymentColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return <Button title="Edit" icon={<EditOutlined />} disabled={!record.is_updatable}
                        onClick={() => showModal(record, 'Edit Payments')}>
                </Button>
      }
    })
    productGrnColumns.map(data => {
      columnsProducts.push(data)
    })
    setColumnsProducts(columnsProducts)
    setColumns(columns)
  }

  function showModal(data, type) {
    modalRef.current.showModal(type, data)
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }

  function reset() {
    setState({valueByNode: undefined, valueBySupplier: undefined, valueByOrder: undefined,
              start_date: '', end_date: '', showDateValue: false, valueByStatus: undefined})
    setDisable(true)
    setCurrent(1)
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setRenderData(false)
  }

  function expandedRow(record) {
    return <>
      <Card className="nested-table">
        <Table columns={columnsProducts} pagination={false} rowKey="id" dataSource={record.line_items} />
      </Card>
    </>
  }

  function onChangeRangePicker(value, dateString){
    setState(prevState =>({...prevState, showDateValue: true, start_date :dateString[0], end_date:dateString[1]}))
    setDisable(false)
    setCurrent(1)
  }

  function disabledDate(current){
    return current && (current < moment().subtract(91, "day") || current > moment().add(0, "day"));
  }

  function modalSubmit(payloadData, id) {
    const payload = { ...payloadData, id: id}
    ops_service.update_payments(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Updated Successfully')
      getPayments()
      modalRef.current.handleCancel()
    },
    (err) => {
      console.log(err)
      modalRef.current.stopLoading()
      openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
    })
  }

  return (
    <div className="layer">
      {payData && nodes && suppliers ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <RangePicker onChange={onChangeRangePicker} className="frz-m-10"
                             value={showDateValue ? [moment(start_date, dateFormat),
                             moment(end_date, dateFormat)] : '' } format={dateFormat}
                             allowClear={false} disabledDate={disabledDate}/>
                <Search placeholder="Select Nodes" value={valueByNode} data={nodes}
                        onChange={(e) => onChange(e, 'valueByNode')} type="valueByCategory" />
                <Search placeholder="Select Suppliers" value={valueBySupplier} data={suppliers} 
                        onChange={(e) => onChange(e, 'valueBySupplier')}  type="valueByCategory" />
                <Search placeholder="Select Status" value={valueByStatus} data={status}
                        onChange={(e) => onChange(e, 'valueByStatus')}  type="valueByCategory" />
                <InputNumber
                  placeholder="Order No"
                  onChange={(e) => onChange(e, "valueByOrder")}
                  value={valueByOrder}
                  className="frz-width-150p frz-m-10" />
                <Button disabled={disable} onClick={getPayments}
                  className="frz-w-100px frz-m-10" type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}> 
                <div className="frz-dis-upload dynamic-upload">
                  <FrzDynamicUpload name={'Payments'} url={'admin_dashboard/procurement/upload_update_payment'} 
                                    uploadFunc={'upload_zapper'} callBack={getPayments}/>
                </div>
                <span className="fl-right">
                  <FrzDynamicDownload name={'Supplier Bank Details'} fileName={'Supplier Bank Details'} 
                                      url={`admin_dashboard/procurement/bank_details_report`} payload={'?'} type={'job'}
                                      reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} /> 
                  <FrzDynamicDownload name={'Payments'} fileName={'Payments'} url={'admin_dashboard/procurement/po_payment_report'}
                                      payload={'?by_node='+`${valueByNode ?? ''}`+'&by_supplier='+`${valueBySupplier ?? ''}`
                                              +'&by_order_number='+`${valueByOrder ?? ''}`+'&start_date='+`${start_date}`
                                              +'&end_date='+`${end_date}`+'&by_status='+`${valueByStatus === undefined ? '' : valueByStatus}`} 
                                      type={'job'} reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'}/>                         
                </span>
              </Col>
            </Card>
          </Row>
          {payData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={payData} columns={columns} expandedRow={expandedRow}/>
                <Pagination className="frz-tm-10 frz-bm-10" current={current}
                  pageSize={50} total={state.total} onChange={pagination}
                  showSizeChanger={false} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <PaymentModal modalSubmit={modalSubmit} ref={modalRef}/>
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  );
}

export default PaymentList