import React, { useEffect, useState } from 'react';
import MainTable from '../antd/table';
import { Row, Col, Table} from 'antd';
import {customerWalletColumns} from '../antd/columns/customers';
import {handleDateFormat} from '../../_modules/date';
import {getStaticTime} from '../../_modules/time';

function WalletLedger(props){
  const [columns, setColumns] = useState([])

  useEffect(() => {
    getColumns()
  }, [])

  function getColumns(){
    let temCol = []
    customerWalletColumns.map(data => {
      temCol.push(data)
    })
    temCol.push(
      {
        title: 'Transaction Date',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return <div>
                    {handleDateFormat(record.transaction_at)} {getStaticTime(record.transaction_at)}
                 </div>
        }
      },
      {
        title: 'Amount',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return <div>
                    {record.credit_ledger_type === 'debit' ?
                    <span style={{color: 'red'}}>
                      - Rs. {record.amount}
                    </span>
                    :
                    <span style={{color: 'green'}}>
                      + Rs. {record.amount}
                    </span>}
                 </div>
        }
      })
    setColumns(temCol)
  }

  return(
    <div>
      <Row>
        <Col span={24}>
          {props.customerDetail.length > 0 ?
            <Table columns={columns} dataSource={props.customerDetail}
                   pagination={true} scroll={{ x: 240 }}
                   rowKey="id"/>
          :
            <h3 style={{textAlign: 'center', paddingTop: 24}}>No Details Found</h3>
          }
        </Col>
      </Row>
    </div>
  )
}

export default WalletLedger
