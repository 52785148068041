import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button, Select, Input, InputNumber, DatePicker,
         Radio, ConfigProvider, Checkbox} from 'antd';
import Search from '../antd/search';
const { Option } = Select;

const customizeRenderEmpty = () => (
  <div style={{ textAlign: 'center' }}>
    <p>No society found on above pincode</p>
  </div>
);

const PickupModal = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOk() {
    setLoading(true)
    props.modalSubmit()
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.onCancelModal();
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onOk={handleOk}
          onCancel={handleCancelModal}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              Submit
            </Button>,
          ]}
        >
          <div className="block"style={{marginLeft: 23}}>Society Pincode :
            <span style={{paddingLeft:10}}>
              <Input
                placeholder="Society Pincode" maxLength="6"
                value={props.pincode_modal}
                style={props.pickupError && props.pincode_modal.length !== 6 ?
                       {width:200, borderColor: 'red'} : {width:200}}
                onChange={(e) => props.onChangeModal(e.target.value, 'pincode_modal')}/>
            </span>
          </div>
          {props.societyData && props.pincode_modal.length === 6 ?
          <div className="block" style={{marginLeft: 37}}>Society Name :
            <span style={{paddingLeft:10}}>
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Select value={props.society_id}
                        className={`${props.pickupError && props.society_id === undefined ?
                                      "select-error" : false}`}
                        style={{width:200}}
                        placeholder="Select Society"
                        onChange={(e) => props.onChangeModal(e, 'society_id')}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                    {props.societyData.length > 0 ?
                    (props.societyData.map(data => {
                      return (
                        <Option key={data.id} value={data.id}>{data.name}</Option>
                      )
                    }))
                    : null}
                </Select>
              </ConfigProvider>
            </span>
          </div>
          : null}
          <div className="block" style={{marginLeft: 37}}>Delivery Date :
            <span style={{paddingLeft:10}}>
              <DatePicker placeholder="Select Date"
                          value={props.delivery_date}
                          onChange={props.onChangeDateModal}
                          style={props.pickupError && props.delivery_date === '' ?
                                 {width:200, borderColor: 'red'} : {width:200}}/>
            </span>
          </div>
          <div className="block" style={{marginLeft: 64}}>Time Slot :
            <span style={{paddingLeft:10}}>
              <Select value={props.pickupSlot}
                      style={{width:200}}
                      placeholder="Select Slot" onChange={(e) => props.onChangeModal(e, 'pickupSlot')}>
                  {props.pickupTimeslot.map(data => {
                    return (
                      <Option key={data.id} value={data.id}>
                        {data?.slot_string ?? data.from_time + ' to ' + data.to_time + ' ' + data.am_pm}
                      </Option>
                    )
                  })}
              </Select>
            </span>
          </div>
          <div className="layer" style={{marginLeft: 70}}>Virtual DS :
            <span>
              <Search placeholder="Virtual Darkstore Name" value={props.virtualDS} data={props.virtualDsData} 
                      width="medium" onChange={(e) => props.onChangeModal(e, 'virtualDS')} type="valueByVirtualDs"/>
            </span>
          </div>
          {title === 'Edit Pickup' ? 
            <div className="block" style={{marginLeft: 135}}>
              <Checkbox checked={props.is_expired} onChange={props.onChangeCheck}>Deactivate Pickup</Checkbox>
            </div>
          : null
          }
        </Modal>
      : null }
    </div>
  )
})

export default PickupModal
