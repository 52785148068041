export const fraudRefundColumns = [
    {
      title: "Name",
      dataIndex: "customer_name",
      key: "customer_name",
      align: "center",
      width: 150
    },
    {
      title: "Mobile No",
      dataIndex: "customer_mobile_no",
      key: "customer_mobile_no",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "customer_email",
      key: "customer_email",
      align: "center",
    },
    {
      title: "DS Name",
      dataIndex: "darkstore_name",
      key: "darkstore_name",
      align: "center",
      width: 200
    },
    {
      title: "Frequency Of Refund",
      dataIndex: "frequency_of_refund",
      key: "frequency_of_refund",
      align: "center",
      width: 100
    },
    {
      title: "Max Consecutive Refund",
      dataIndex: "max_consecutive_refund",
      key: "max_consecutive_refund",
      align: "center",
      width: 100
    },
    {
      title: "Total Lifetime Value",
      dataIndex: "total_lifetime_value",
      key: "total_lifetime_value",
      align: "center",
      width: 100
    },
    {
      title: "Total Refund Value",
      dataIndex: "total_refund_value",
      key: "total_refund_value",
      align: "center",
      width: 100
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
    },
  ]
  