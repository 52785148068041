import React, { useEffect, useRef, useState } from 'react';
import { Spin, Card, Row, Col, Pagination, Table, Tabs, Button, DatePicker, InputNumber } from 'antd';
import { fraudLogsColumns } from '../../components/antd/columns/orders';
import { OrderService } from '../../_services/orders';
import OrderModal from '../../components/orders/modal';
import Search from '../../components/antd/search';
import { ReloadOutlined } from '@ant-design/icons'
const order_service = new OrderService()
const { TabPane } = Tabs;

function FraudLogs() {
  const modalRef = useRef(null);
  const [listingData, setListingData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [current, setCurrent] = useState(1)
  const [currentTab, setCurrentTab] = useState(1);
  const [disable, setDisable] = useState(true)
  const [orderStatus, setOrderStatus] = useState(false);
  const [orderType, setOrderType] = useState(false);
  const [state, setState] = useState({
    valueByDarkstore: undefined, valueByOrderNo: undefined,
    valueByOrderStatus: undefined, valueByCustomer: undefined,
    valueByMobno: undefined, valueByDeliveryDate: undefined,
    valueByOrderType: undefined, date: ''
  });
  const { valueByOrderNo, valueByDarkstore, valueByOrderStatus,
    valueByMobno, valueByDeliveryDate, valueByOrderType, date } = state

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getListing(currentTab)
    }
  }, [currentTab, renderData])

  useEffect(() => {
    getColumns()
    getOrderStatus()
    getOrderTypes()
  }, [])

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }

  function onChangeDate(date, dateString) {
    setState(prevState => ({ ...prevState, date: date, valueByDeliveryDate: dateString }))
    setDisable(false)
    setCurrent(1)
  }

  function reset() {
    setState({
      valueByDarkstore: undefined,
      valueByOrderNo: undefined,
      valueByOrderStatus: undefined,
      valueByCustomer: undefined,
      valueByMobno: undefined,
      valueByDeliveryDate: undefined,
      valueByOrderType: undefined,
      date: ''
    })
    setDisable(true)
    setCurrent(1)
    setListingData([])
    setRenderData(false)
  }

  function getSearch() {
    getListing(currentTab);
  }

  function getOrderStatus() {
    order_service.get_order_status().subscribe((r) => {
      setOrderStatus(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getOrderTypes() {
    order_service.get_order_types().subscribe((r) => {
      setOrderType(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }
  
  function getListing(key) {
    setSpinEnable(true)
    setListingData([])
    const payload = {
      page: current,
      by_order_number: !valueByOrderNo ? '' : valueByOrderNo,
      by_darkstore: !valueByDarkstore ? '' : valueByDarkstore,
      by_status: !valueByOrderStatus ? '' : valueByOrderStatus,
      by_mobile_number: !valueByMobno ? '' : valueByMobno,
      by_delivery_date: !valueByDeliveryDate ? '' : valueByDeliveryDate,
      by_order_type: !valueByOrderType ? '' : valueByOrderType,
      fraud_reason_code: key == 1 ? "FAKE" : key == 2 ? "EDIT" : key == 3 ? "RAD" : "CANCEL"
    }
    order_service.get_fraud_logs_filter(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setListingData(r.response.data)
      setSpinEnable(false)
    },
      (err) => {
        setSpinEnable(false)
        setListingData([])
        console.log(err)
      })
  }

  function showModal(data, type) {
    modalRef.current.showModal(type, data)
  }

  function getColumns() {
    fraudLogsColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: '',
      dataIndex: '',
      key: '',
      align: 'right',
      render: (record) => {
        return (
          <div>
            <Button type="primary" onClick={() => showModal(record, 'Order Details')}>
              View Details
            </Button>
          </div>
        )
      }
    })
    setColumns(columns)
  }

  function pagination(page) {
    setCurrent(page)
    setListingData([])
    setRenderData(false)
  }
  function changeTab(key) {
    setState({
      valueByDarkstore: undefined, valueByOrderNo: undefined,
      valueByOrderStatus: undefined, valueByCustomer: undefined,
      valueByMobno: undefined, valueByDeliveryDate: undefined,
      valueByOrderType: undefined, date: ''
    });
    setCurrentTab(key);
    setListingData([]);
    setDisable(true)
    setRenderData(false);
    setCurrent(1)
  }

  return (
    <div className="layer">
      {listingData && orderStatus && orderType ?
        <div>
         
          <div className="ost">
            <Tabs defaultActiveKey="1" onChange={changeTab}>
              <TabPane tab="Fake Orders" key="1"></TabPane>
              <TabPane tab="Edited Orders" key="2"></TabPane>
              <TabPane tab="Rescheduled After Delivery" key="3"></TabPane>
              <TabPane tab="Cancelled After Delivery" key="4"></TabPane>
            </Tabs>
          </div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Darkstore Name" value={valueByDarkstore}
                  onChange={onChange} type="valueByDarkstore" />
                <Search placeholder="Order Status" value={valueByOrderStatus}
                  onChange={onChange} type="valueByOrderStatus"
                  data={orderStatus} />
                <Search placeholder="Order Type" value={valueByOrderType}
                  onChange={onChange} type="valueByOrderType"
                  data={orderType} />

                <DatePicker value={date} onChange={onChangeDate} placeholder="Delivery Date"
                  style={{ width: 200, marginLeft: 10, marginRight: 10 }} />
                <InputNumber placeholder="Order No"
                  onChange={(e) => onChange(e, 'valueByOrderNo')}
                  value={valueByOrderNo} style={{ width: 200, margin: 10 }} />
                <InputNumber placeholder="Mobile No"
                  onChange={(e) => onChange(e, 'valueByMobno')}
                  value={valueByMobno} style={{ width: 200, margin: 10 }} />
                <Button disabled={disable} onClick={getSearch}
                  type="primary" className='frz-w-100px frz-m-10'>Search</Button>
                <Button disabled={disable} onClick={reset} style={{ margin: 10 }}
                  type="primary" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          {listingData.length > 0 ?
            <Row>
              <Col span={24}>
                <Card className='frz-mt-20 frz-tp-5'>
                  <Row>
                    <Col span={24}>
                      <Table
                        columns={columns} dataSource={listingData}
                        pagination={false} scroll={{ x: 240 }} rowKey="id"
                      />
                    </Col>
                  </Row>
                </Card>
                <Pagination className="frz-tm-10" current={current} pageSize={50}
                  total={state.total} onChange={pagination} showSizeChanger={false} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <OrderModal ref={modalRef} getOrders={getListing} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default FraudLogs
