import { Select, Spin, Form } from 'antd';
import debounce from 'lodash/debounce';
import React, { useMemo, useRef, useState } from 'react';
import { tailLayout } from '../_modules/controllayout';

function DebounceSelect({ fetchOptions, debounceTimeout = 800, isRequired, label, name, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Form.Item
      {...tailLayout}
      name={name}
      label={label}
      rules={isRequired ? [{
        required: true,
        message: `Please input the value of ${label ? label : valueOf}!`,
      }
      ] : []}>
      <Select
        labelInValue
        filterOption={false}
        showSearch
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : 'Search & select product'}
        {...props}
        onChange={(e, f, g) => props.onChange(f, e, g)}
        options={options}
      // onDeselect={(itm) => props.onChange(itm, null, true)}
      >
        {/* {options.map((itm, index) => {
          return (
            <Select.Option key={index} value={itm.label}>
              {itm.label}
            </Select.Option>
          )
        })} */}

      </Select>
    </Form.Item>
  );
} // Usage of DebounceSelect

async function fetchUserList(searchTerm, url, labelTerm, valueTerm, method, headers) {
  return fetch(url + searchTerm, {
    method: method,
    headers: headers
  }).then((response) => response.json()).then((body) =>
    body.data.map((data) => ({
      label: data[labelTerm],
      value: data[valueTerm],
      image: data.image_url,
      id: data.id,
      ...data
    })),
  );
}

const DebounceApp = (props) => {

  return (
    props.isMultiple ?
      <DebounceSelect
        mode="multiple"
        isRequired={props.isRequired}
        label={props.label}
        name={props.name}
        // value={props.selectedValue}
        placeholder={props.placeholder}
        fetchOptions={(e) => fetchUserList(e, props.url, props.labelTerm, props.valueTerm, props.method, props.headers)}
        onChange={(f, e, g) => {
          if (e.length > props.dragNdrop.length) {
            props.setDNDData(f[f.length - 1])
          } else if (e.length < props.dragNdrop.length) {
            let temp = []
            temp = e.map((itm) => {
              return itm.value
            })
            let arr = props.dragNdrop.filter((e) => temp.includes(e.value))
            props.setDragNdrop(arr)
          }
        }}
        className="frz-w-330"
      /> :
      <DebounceSelect
        isRequired={props.isRequired}
        label={props.label}
        name={props.name}
        // value={props.selectedValue}
        placeholder={props.placeholder}
        fetchOptions={(e) => fetchUserList(e, props.url, props.labelTerm, props.valueTerm, props.method, props.headers)}
        onChange={(f, e, g) => {
          props.setDragNdrop(f)
         
          // if (!isDeselect) {
          //   let response = false
          //   console.log("dragNdrop", props.dragNdrop)
          //   if (props.editMode) {

          //   } else {
          //     response = props.dragNdrop.find((x) => x.id === f[f.length - 1].id)
          //   }
          //   if (response) {
          //     return
          //   }
          //   //alawys f push
          //   props.setDNDData(f[f.length - 1])
          // } else {
          //   // console.log("edit in de seelct", f)
          //   props.removeDNDdata(f)
          // }
          // props.setSelectedValue([...e]);
        }}
        className="frz-w-330"
      />
  );
};

export default DebounceApp;