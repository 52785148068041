import React, { useEffect, useState } from 'react'
import { Tag } from 'antd'
import { handleDateFormat } from '../../_modules/date'
import { getStaticTime } from '../../_modules/time'
import { Row, Col } from 'antd'

let tempKeys = []
let tempChangeData = []

const LogDetail = ({ logDetail }) => {
  const [changedKeys, setChangedKeys] = useState([])

  const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }


  useEffect(() => {
    tempKeys = []
    let elmnt = document.getElementById("Top");
    elmnt.scrollIntoView({ behavior: 'instant', block: 'center' });
    if (logDetail.event !== "create") {
      getChangedKeys()
    }
  }, [logDetail])

  const getChangedKeys = () => {
    Object.entries(logDetail.object.object_changes).map(([key, value], index) => {
      tempChangeData.push({ key: value })
      tempKeys.push(key)
    })
    setChangedKeys(tempKeys)
  }

  const humanize = (str) => {
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  // const isValidURL = (string) => {
  //   let isString = isNaN(string)
  //   if (string === null) {
  //     return null
  //   }
  //   if (!isString) {
  //     return false
  //   } else {
  //     var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  //     return (res !== null)
  //   }
  // };

  return (
    <div>
      <div className="frz-tm-15">
        <div className={`${logDetail.event === "update" ? "frz-card-update-active" : "frz-card-create-active"}`}>
          {logDetail.object.id}
        </div>
      </div>

      <div className="frz-details-scroll">
        <div id="Top" />
        {logDetail.event === "create" ?
          Object.entries(logDetail.object.object_changes).map(([key, value], index) => {
            return (
              <div key={uuidv4()} className="frz-log-card-body">
                <Row>
                  <Col xl={4} className="frz-log-detail-humanize">
                    <div className="frz-rp-12 frz-lable-key">
                      {humanize(key)}:
                    </div>
                  </Col>

                  <Col xl={7} className="frz-vt-center">
                    <div className="custom-tag-magenta"> {value[0] === null ? "null" :
                      value[0] === true ? "True" : value[0] === false ? "false" :
                        key.includes("created_at") || key.includes("updated_at") ?
                          <>{handleDateFormat(value[0])} {"at"} {getStaticTime(value[0])} </>
                          : key.includes("image_url") ? <img src={value[0]} className="frz-w-80" /> :
                            key.includes("color") ?
                              <div style={{
                                backgroundColor: value[0],
                                height: 30,
                                width: 30,
                                border: "#888",
                                borderStyle: "solid",
                                borderWidth: "1px"
                              }} /> :
                              value[0]}
                    </div>
                  </Col>

                  <Col xl={2} className="frz-vt-center">
                    <div className="frz-update-arrow frz-dis-flex frz-hrz-center">
                      =={'>'}
                    </div>
                  </Col>

                  <Col xl={11} className="frz-vt-center">
                    <div className="custom-tag-green-create">
                      {key.includes("created_at") || key.includes("updated_at") ?
                        <>{handleDateFormat(value[1])} {"at"} {getStaticTime(value[1])} </>
                        : value[1] === true ? "True" : value[1] === false ? "False" :
                          key.includes("image_url") ? <img src={value[1]} className="frz-w-80" /> :
                            key.includes("color") ?
                              <div style={{
                                backgroundColor: value[1],
                                height: 30,
                                width: 30,
                                border: "#888",
                                borderStyle: "solid",
                                borderWidth: "1px"
                              }} /> :
                              value[1]}
                    </div>
                  </Col>
                </Row>
              </div>
            )
          })
          :
          Object.entries(logDetail.object.object).map(([key, value], index) => {
            return (
              <div key={uuidv4()} className="frz-log-card-body">
                <Row>
                  <Col xl={4} className="frz-vt-center">
                    <div className="frz-rp-12 frz-lable-key">
                      {humanize(key)}:
                    </div>
                  </Col>

                  {changedKeys.includes(key) ?
                    <>
                      {Object.entries(logDetail.object.object_changes).map(([key2, changedValue], index) => {
                        if (key2 === key) {
                          return (
                            <React.Fragment key={uuidv4()}>
                              <Col xl={7} className="frz-vt-center">
                                <div className="custom-tag-magenta">{value === null ? "null" : value === true ? "True" : value === false ? "false" :
                                  key.includes("created_at") || key.includes("updated_at") ?
                                    <>{handleDateFormat(value)} {"at"} {getStaticTime(value)} </> :
                                    key.includes("image_url") ? <img src={value} className="frz-w-80" /> :
                                      key.includes("color") ?
                                        <div style={{
                                          backgroundColor: value,
                                          height: 30,
                                          width: 30,
                                          border: "#888",
                                          borderStyle: "solid",
                                          borderWidth: "1px"
                                        }} /> : value}
                                </div>
                              </Col>

                              <Col xl={2} className="frz-vt-center">
                                <div className="frz-update-arrow frz-dis-flex frz-hrz-center">
                                  =={'>'}
                                </div>
                              </Col>

                              <Col xl={11} className="frz-vt-center">
                                <div className="custom-tag-green-create">
                                  {key.includes("created_at") || key.includes("updated_at") ?
                                    <>{handleDateFormat(changedValue[1])} {"at"} {getStaticTime(changedValue[1])} </>
                                    : changedValue[1] === true ? "True" : changedValue[1] === false ? "False" :
                                      key.includes("image_url") ? <img src={changedValue[1]} className="frz-w-80" /> :
                                        key.includes("color") ?
                                          <div style={{
                                            backgroundColor: changedValue[1],
                                            height: 30,
                                            width: 30,
                                            border: "#888",
                                            borderStyle: "solid",
                                            borderWidth: "1px"
                                          }} /> : changedValue[1]}
                                </div>
                              </Col>
                            </React.Fragment>
                          )
                        }
                      })}

                    </>
                    :
                    <>
                      {/* {value === null ? "null" : value === true ? "True" : value === false ? "false" : key === "created_at" || key === "updated_at" || key === "product_image_updated_at" ?
                      handleDateFormat(value) : value}
                    -----{'>'} */}
                      <Col xl={20}>
                        {key === "created_at" || key.includes("updated_at") ? <> {handleDateFormat(value)}  {"at"} {getStaticTime(value)} </> :
                          value === true ? "True" : value === false ? "False" :
                            key.includes("image_url") ? <img src={value} className="frz-w-80" /> :
                              key.includes("color") ?
                                <div style={{
                                  backgroundColor: value,
                                  height: 30,
                                  width: 30,
                                  border: "#888",
                                  borderStyle: "solid",
                                  borderWidth: "1px"
                                }} /> : value}
                      </Col>
                    </>
                  }

                </Row>

                {/* <div className="frz-rp-12 frz-wb-break-all">


              </div> */}
              </div>
            )
          })
        }
      </div>

    </div >
  )
}

export default LogDetail
