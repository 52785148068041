import { Http } from '../_modules/http'

export class DownloadService {

  http = new Http()

  get_downloads(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/downloads/' + payload.url
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  download_darkstore_report(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/downloads/'+ payload.url + '?start_date=' + payload.start_date + 
                '&end_date=' + payload.end_date + '&by_darkstore=[' + payload.darkstore + ']'
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  download_darkstore_report_store(payload){
    const url = process.env.REACT_APP_API_URL +
                'store_dashboard/v1/store/reports/'+ payload.url + '?start_date=' + payload.start_date + 
                '&end_date=' + payload.end_date + '&by_darkstore=[' + payload.darkstore + ']'
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  download_singular_date_report(payload){
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/' + payload.url + '?date=' + payload.date 
                + '&node_code=' + payload.darkstore
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}` + `${payload.operations ? '&operations=true' : ''}`
                + `${payload.by_category_code ? '&by_category_code='+payload.by_category_code : ''}`
                + `${payload.by_sub_category_code ? '&by_sub_category_code='+payload.by_sub_category_code : ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_downloads_datewise(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/downloads/' + payload.url + '?start_date=' + payload.start_date 
                + '&end_date=' + payload.end_date + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_downloads_datewise_refund(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/downloads/' + payload.url + '?date=' + payload.start_date 
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_downloads_custom(payload, startsWith){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/downloads/' + payload.url + '?' + startsWith.filter + '=' + startsWith.value 
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_download(minUrl){
    const url = process.env.REACT_APP_API_URL + minUrl
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  // Dynamic Downloads

  zapper_report(minurl, payload){
    const url = process.env.REACT_APP_AWS_URL + minurl + payload + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  zapper_job_report(id){
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/get_job_details?id=' + id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_monthly_reports(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/downloads/monthly_reports?start_date=' + payload.date + '&report_name=' +
                 payload.url + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  download_reports_regenerate(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/downloads/regenerate_report'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload, headers)
  }

  pricing_report(minurl, payload) {
    const url = process.env.REACT_APP_PRICING_URL + minurl + payload
    let headers = {"x-api-key": process.env.REACT_APP_PRICING_TOKEN}
    return this.http.get(url, headers)
  }

  fraazo_report(minurl, payload){
    const url = process.env.REACT_APP_API_URL + minurl + payload + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  fraazo_report_auth(minurl, payload){
    const url = process.env.REACT_APP_AUTH_URL + minurl + payload + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  download_reports_job_fraazo(id){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/downloads/get_report_update?id=' + id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  download_discount_reports(payload){
    console.log(payload)
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/marketing/'+ payload.id + '/download_bulk_coupon_sheet'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  download_segment_report(minurl){
    const url = process.env.REACT_APP_SEGMENT_URL + minurl
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  download_job_segment_report(minurl){
    const url = process.env.REACT_APP_SEGMENT_URL + 'admin/api/v1/export/' + minurl.job_name + '/' + minurl.id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

}
