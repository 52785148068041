import React, { useState } from 'react';
import { Row, Col, Form, Radio } from 'antd';
import FRZInput from '../../../_controls/FRZInput';

function ImsFormula(props) {

  const onFormChange = () => {
    props.handleOnFormChange(true)
  }

  return (
    <div className='frz-ims-box frz-ims-green-box'>
      <Row>
        <Col span={12}>
          <div className='frz-ims-text-white'>
            Earnings Per Rider
          </div>
        </Col>
        <Col span={12}>
          <Form
            form={props.form}
            layout="horizontal"
            name="form"
            onChange={() => onFormChange()}>
            <div className='frz-dis-flex frz-justify-end frz-ims-placeholder'>
              <FRZInput customClass="frz-txt-uc" name="formula" isRequired={true}
                label="" placeholder="Add Calculation Formula" width={300} />
            </div>
            <div className='frz-formula-text'>For Ex. A + B + (C or D + E)</div>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default ImsFormula