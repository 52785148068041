import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Spin, Modal, Button, Row, Col, Tabs, Tag, Switch, Popconfirm } from 'antd';
import { CustomerService } from '../../_services/customer'
import {
  WalletOutlined, HomeOutlined, ContainerOutlined, PicCenterOutlined,
  UserSwitchOutlined, SwapOutlined
} from '@ant-design/icons';
import CustomerAddress from './customer_address';
import WalletLedger from './wallet_ledger';
import OrderHistory from './order_history';
import CustomerCoupons from './customer_coupons';
import CustomerReferrals from './customer_referrals';
import CustomerRefunds from './customer_refunds';

const { TabPane } = Tabs;

const customer_service = new CustomerService()

const PickupDetail = forwardRef((props, ref) => {
  const [customerDetail, setCustomerDetail] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [segmentsData, setSegmentsData] = useState([])
  const [coupons, setCoupons] = useState(false)
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(1)
  const [isMarketing, setIsMarketing] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [isActivationHit, setIsActivationHit] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
      getCustomerDetail(data.id)
      getCustomerSegment(data.id)
      getCoupons(data.mobile_number)
    },

  }));

  function getCustomerDetail(id) {
    const payload = {
      id: id
    }
    customer_service.get_customer_detail(payload).subscribe((r) => {
      setCustomerDetail(r.response.data)
      setIsMarketing(r.response.data.receive_marketing_offers)
      setIsActive(r.response.data.is_active)
    },
      (err) => {
        console.log(err)
      })
  }

  function getCustomerSegment(id){
    const payload = {
      id: id
    }
    customer_service.get_customer_segment(payload).subscribe((r) => {
      setSegmentsData(r.response?.customer?.segments)
    },
      (err) => {
        console.log(err)
      })
  }

  function getCoupons(mob) {
    const payload = {
      mobile_number: mob,
      page: current
    }
    customer_service.get_customer_coupons(payload).subscribe((r) => {
      setTotal(r.response.meta.total_pages * 50)
      setCoupons(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (isActivationHit) {
      onSubmitActivation()
    }
  }, [isActive, isMarketing])

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
  }

  function pagination(page) {
    setCurrent(page)
    getCoupons(data.mobile_number)
  }

  const onChangeMarketing = (value) => {
    console.log("value", value)
    setIsMarketing(value)
  }

  const onChangeActive = (value) => {
    setIsActive(value)
  }

  const updateActivation = (active, marketing) => {
    if (active) {
      setIsActive(!isActive)
      setIsActivationHit(true)
    } else if (marketing) {
      setIsMarketing(!isMarketing)
      setIsActivationHit(true)
    }
  }

  const onSubmitActivation = () => {
    const payload = {
      receive_marketing_offers: isMarketing,
      is_active: isActive,
      id: customerDetail.id
    }
    console.log("Hit update api", payload)
    customer_service.update_customer_activation(payload).subscribe((r) => {
      console.log("res", r.response.data)
      getCustomerDetail(customerDetail.id)
      setIsActivationHit(false)
    }, ((err) => {
      console.log("err", err)
    }))
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onCancel={handleCancelModal}
          width='85%'
          style={{ float: 'right' }}
          className="order"
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Close
            </Button>,
          ]}
        >
          <div className="order-modal-container">
            {customerDetail && coupons ?
              <div>
                <Row>
                  <Col md={7}>
                    <div style={{
                      borderRight: '1px solid #f0f0f0', paddingRight: 24,
                      height: '100%'
                    }}>
                      <div>
                        <b>Customer Name :</b>
                        <span style={{ paddingLeft: 10 }}>
                          {customerDetail.customer_name}
                        </span>
                      </div>
                      <div className="order-detail-padding">
                        <b>Mobile No :</b>
                        <span style={{ paddingLeft: 10 }}>
                          {customerDetail.mobile_number}
                        </span>
                      </div>
                      <div className="order-detail-padding">
                        <b>Current Credits :</b>
                        <span style={{ paddingLeft: 10 }}>
                          {customerDetail.current_credits}
                        </span>
                      </div>
                      {customerDetail.active_address ?
                        <div>
                          <div className="order-detail-padding"
                            style={{ paddingTop: 20, color: '#262624' }}>
                            <b>#Active Address</b>
                          </div>
                          <div className="order-detail-padding">
                            <b>Address Type :</b>
                            <span style={{ paddingLeft: 10 }}>
                              {customerDetail.active_address.address_type}
                            </span>
                          </div>
                          <div className="order-detail-padding">
                            <b>Is Serviceable :</b>
                            <span style={{ paddingLeft: 10 }}>
                              {customerDetail.active_address.is_serviceable.toString()}
                            </span>
                          </div>
                          <div className="order-detail-padding">
                            <b>Address :</b>
                            <span style={{ paddingLeft: 10 }}>
                              {customerDetail.active_address.flat_detail} {customerDetail.active_address.landmark}
                            </span>
                          </div>
                          {customerDetail.active_address.society_details ?
                            <div className="order-detail-padding">
                              <b>Society Name :</b>
                              <span style={{ paddingLeft: 10 }}>
                                {customerDetail.active_address.society_details.name}
                              </span>
                            </div>
                            : null}
                          {customerDetail.active_address.darkstore_details ?
                            <div className="order-detail-padding">
                              <b>Darkstore Name :</b>
                              <span style={{ paddingLeft: 10 }}>
                                {customerDetail.active_address.darkstore_details.name}
                              </span>
                            </div>
                            : null}
                        </div>
                        : null}
                      {/* <div className="order-detail-padding"
                        style={{ paddingTop: 20, color: '#262624' }}>
                        <b>#Active Tags</b>
                      </div>
                      <div className="order-detail-padding">
                        {customerDetail.customer_active_tags.map((data, index) => {
                          return <Tag key={index} color='purple'>{data.display_name}</Tag>
                        })}
                      </div> */}
                      <div className="order-detail-padding"
                        style={{ paddingTop: 20, color: '#262624' }}>
                        <b>#Active Segments</b>
                      </div>
                      <div className="order-detail-padding">
                        {segmentsData?.map((data, index) => {
                          return <Tag key={index} color='purple'>{data}</Tag>
                        })}
                      </div>
                      {/* {"receive_marketing_offers": false, "is_active": false} */}
                      <div className="order-detail-padding"
                        style={{ paddingTop: 20, color: '#262624' }}>
                        <b>#Receive Marketing Offers</b>
                      </div>
                      <div className="order-detail-padding">
                        <Popconfirm placement="bottomRight" title="Are you sure you want to change this status?" okText="Yes" cancelText="No" onConfirm={() => updateActivation(false, true)}>
                          <Switch checked={isMarketing} />
                        </Popconfirm>
                      </div>

                      <div className="order-detail-padding"
                        style={{ paddingTop: 20, color: '#262624' }}>
                        <b>#Is Active</b>
                      </div>
                      <div className="order-detail-padding">
                        <Popconfirm placement="bottomRight" title="Are you sure you want to change active status？" okText="Yes" cancelText="No" onConfirm={() => updateActivation(true, false)}>
                          <Switch checked={isActive} />
                        </Popconfirm>
                      </div>
                    </div>
                  </Col>
                  <Col md={17}>
                    <div style={{ paddingLeft: 24 }}>
                      <Tabs defaultActiveKey="1">
                        <TabPane
                          tab={<span>
                            <WalletOutlined />
                            Wallet Ledger
                          </span>}
                          key="1"
                        >
                          <WalletLedger customerDetail={customerDetail.wallet_ledger} />
                        </TabPane>
                        <TabPane
                          tab={<span>
                            <ContainerOutlined />
                            Order History
                          </span>}
                          key="2"
                        >
                          <OrderHistory customerDetail={customerDetail.order_history_list} />
                        </TabPane>
                        <TabPane
                          tab={<span>
                            <HomeOutlined />
                            Addresses
                          </span>}
                          key="3"
                        >
                          <CustomerAddress customerDetail={customerDetail.address_list} />
                        </TabPane>
                        <TabPane
                          tab={<span>
                            <PicCenterOutlined />
                            Coupons
                          </span>}
                          key="4"
                        >
                          <CustomerCoupons customer_id={customerDetail.id} coupons={coupons} getCoupons={getCoupons}
                            customer_mobile_no={customerDetail.mobile_number} pagination={pagination}
                            total={total} current={current} />
                        </TabPane>
                        <TabPane
                          tab={<span>
                            <UserSwitchOutlined />
                            Referrals
                          </span>}
                          key="5"
                        >
                          <CustomerReferrals customerDetail={customerDetail.customer_referrals} />
                        </TabPane>
                        <TabPane
                          tab={<span>
                            <SwapOutlined />
                            Refunds
                          </span>}
                          key="6"
                        >
                          <CustomerRefunds customerDetail={customerDetail.customer_refunds} />
                        </TabPane>
                      </Tabs>
                    </div>
                  </Col>
                </Row>
              </div>
              :
              <div className="spin-center">
                <Spin tip="...Loading" />
              </div>
            }
          </div>
        </Modal>
        : null}
    </div>
  )
})

export default PickupDetail
