import { Http } from '../_modules/http'

export class ValetService {

  http = new Http()

  orders_valet(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/orders/fraazo_valet_order_list?by_valet_product=' + payload.valet_product +
                '&by_mobile_number=' + payload.by_mobile_number + '&page=' + payload.page +
                '&start_date=' + payload.start_date + '&end_date=' + payload.end_date+'&by_city='+`${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  customers_valet(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/orders/fraazo_valet_customer_list?by_valet_product=' + payload.valet_product +
                '&by_mobile_number=' + payload.by_mobile_number + '&page=' + payload.page +
                '&by_status=' + payload.by_status +'&by_city='+`${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  valet_plans(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/orders/fraazo_valet_plans'
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  valet_status(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/orders/fraazo_valet_status'
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

}
