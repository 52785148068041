export const forecastingColumns = [
    {
        title: "DS Name",
        dataIndex: "node_name",
        key: "node_name",
        align: "center"
    },
    {
        title: "Marketing Increment",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
            return record.marketing_increment + ' %'
        }
    },
    {
        title: "Organic Growth",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
            return record.organic_growth_flag.toString()
        }
    },
    {
        title: "Organic Growth Value",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
            return record.organic_growth_percentage + ' %'
        }
    },
    {
        title: "Tp Available",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
            return record.tp_available_flag.toString()
        }
    },
    {
        title: "Tp Increment",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
            return record.tp_increment_percentage + ' %'
        }
    },
]