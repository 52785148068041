import React, { useState, useEffect } from 'react'
import { Modal, Form, Slider } from 'antd';
import FRZInput from '../../../../_controls/FRZInput';
import FRZSelect from '../../../../_controls/FRZSelect';
import FRZCustomRadioButon from '../../../../_controls/FRZCustomRadioButon';
import FRZRadioButton from '../../../../_controls/FRZRadioButton';
import FRZUpload from '../../../../_controls/FRZUpload';
import { formItemLayout, tailLayout } from '../../../../_modules/controllayout';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { openNotificationWithIcon } from '../../../../_modules/notification';
import { PaymentsService } from "../../../../_services/payments";
import { humanize } from '../../../../_modules/humanize';

const payments_service = new PaymentsService();

const PGDistribution = ({ PGActivationType, rowData, activePayments, setIsSubmit, isSubmit,
  setActivePayments, getPayments, activeParent, payType }) => {
  let Obj = PGActivationType.map((item) => Object.assign({}, { [item]: undefined }))
  var newObj = Object.assign({}, ...Obj);

  const [form] = Form.useForm();

  useEffect(() => {
    if (isSubmit === "PGD") {
      setIsSubmit(false)
      form
        .validateFields()
        .then(values => {
          const payload = {
            id: rowData.id,
            body: {
              activate_master_distribution: values.activate_master_distribution, //upi->
              pg_distribution: {
                ...values
              }
            }
          }
          let isInvalid = false
          Object.keys(values).map((x) => {
            if (x !== 'activate_master_distribution') {
              isInvalid += values[x]
            }
          })
          if (payType) {
            // delete payload.body.activate_master_distribution
            delete payload.body.pg_distribution.activate_master_distribution
          }
          if (isInvalid !== 100) {
            openNotificationWithIcon('error', "PG distribution must be equal to 100")
            return
          }
          payments_service.update_payments(payload).subscribe((r) => {
            setActivePayments(r.response.data.payment_gateway_details)
            openNotificationWithIcon('success', "Updated Successfully")
            getPayments()
          }, ((err) => {
            console.log("err", err)
          }))
        })
        .catch(info => {
          // console.log('Validate Failed:', info);
        });
    }
  }, [isSubmit])

  useEffect(() => {
    if (rowData) {
      // console.log("update the values ibn form", activePayments.find((x) => x.payment_gateway === PGAType))
      let formvalues = {}
      activePayments.map((x) => {
        Object.assign(formvalues, { [x.payment_gateway]: x.load_percentage })
      })
      formvalues.activate_master_distribution = rowData.activate_master_distribution
      form.setFieldsValue(formvalues)
    }
  }, [rowData])


  const [maxValues, setMaxValues] = useState(newObj)
  const [lastSlider, setLastSlider] = useState(false)
  // 100 - 20 = 80
  // 

  const manageOverallValue = (type) => {
    let temp = maxValues
    const sliderData = form.getFieldValue()

    let Obj = {}
    // console.log("type", type, lastSlider)
    Object.keys(temp).map((item) => {
      if (type !== item && lastSlider !== item) {
        if (lastSlider && lastSlider !== type) {
          Obj[item] = ((100 - (sliderData[lastSlider] + sliderData[type])))
        } else {
          Obj[item] = ((100 - sliderData[type]) / 2)
        }
      }
    })
    //
    Obj[type] = sliderData[type]
    // console.log("Obj", Obj)
    form.setFieldsValue({ ...Obj })
    // console.log("sliderData", sliderData)
  }

  const setMax = (value) => {
    const sliderData = form.getFieldValue()
    let temp = sliderData;

    if ((100 - (sliderData[lastSlider] + sliderData[value])) < 0) {
      Object.assign(temp, { ...temp, [lastSlider]: (temp[lastSlider] + (100 - (sliderData[lastSlider] + sliderData[value]))) })
    }
    // console.log("temp", temp)
    // console.log("cccc", (100 - (sliderData[lastSlider] + sliderData[value])))
    // console.log("lastSlider", lastSlider)
    if (lastSlider === value) {
      setLastSlider(false)
    } else {
      setLastSlider(value)
    }
    // console.log('hereee')
  }

  return (
    <div>
      <Form
        form={form}
        {...formItemLayout}
        layout="horizontal"
        name={"PG_DISTRIBUTION"}>
        {/* {console.log("rowData", rowData)} */}
        {payType ?
          <FRZRadioButton name="activate_master_distribution" label={humanize('activate_master_distribution')} isRequired={true} />
          : null}
        {activePayments.filter((x) => x.status !== "deactivated").map((item, index) => {
          return (
            <>
              <Form.Item key={item.payment_gateway} name={item.payment_gateway} label={humanize(item.payment_gateway)} {...tailLayout}>
                {/* <Slider onAfterChange={() => setMax(item)} key={item} tooltipPlacement='top' onChange={() => manageOverallValue(item)} /> */}
                <Slider key={item.payment_gateway} tooltipPlacement='top' />
              </Form.Item>
            </>
          )
        })}
      </Form>
    </div>
  )
}

export default PGDistribution
