import React, { useEffect, useState } from 'react';
import { Row, Col, Form, InputNumber, Select } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import FRZSelect from '../../../_controls/FRZSelect';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import rupee from '../../../static/images/rupee.svg';
import { tailLayout } from '../../../_modules/controllayout'

const { Option } = Select;

function ImsD(props) {

  // useEffect(() => {
  //   props.form.setFieldsValue({distance_config:[{distance: 10, rate: 4}, {distance: 45, rate: 33}]})
  // }, [])
  const [isFieldRequired, setIsFieldRequired] = useState(false);

  useEffect(() => {
    if (props.billingValue && props.billingChange) {
      props.form.setFieldsValue({ D: { period: undefined } })
    }
  }, [props.billingValue])

  const onFormChange = () => {
    props.handleOnFormChange(true)
    let data = props.form.getFieldValue()
    if (data.D.duration || data.D.period?.length || data.D.percent || data.D.rate) {
      setIsFieldRequired(true)
    } else {
      props.form.validateFields()
      setIsFieldRequired(false)
    }
  }

  return (
    <div>
      <Form
        form={props.form}
        layout="horizontal"
        name="form"
        onChange={() => onFormChange()}>
        <Row>
          <Col span={4}>
            <div className='frz-ims-incentive-subtext frz-fuel-km-padding'>
              Timely <br />
              Delivery (D)
            </div>
          </Col>
          <Col span={20}>
            <div className='frz-fuel-km-padding'>
              <div className='frz-dis-flex frz-justify-end frz-ims-placeholder'>
                <FRZInputNumber minValue={0} name={['D', 'duration']}
                  label="" placeholder="duration" width={150} isRequired={isFieldRequired} valueOf={"duration"} />
                <div className='frz-lp-10'></div>
                <FRZSelect DropDownData={props.period} name={['D', 'period']}
                  optionDisabledKey={"name"}
                  isDisabledVal={props.period.filter((x) => x.id > props.billingValue).map(a => a.id)}
                  optionDisabledKey="id"
                  label="" placeholder="Period" option="name" isShowSearch={true}
                  width={150} value="period" setOnChange={onFormChange} isRequired={isFieldRequired} valueOf={"period"} />
                <div className='frz-lp-10'></div>
                <FRZInputNumber minValue={0} name={['D', 'percent']}
                  label="" placeholder="percent" width={150} isRequired={isFieldRequired} valueOf={"percent"} />
                <div className='frz-lp-10'></div>
                <div className='frz-p-relative frz-ims-rs-custom'>
                  <div className='frz-rupee-ims'><img src={rupee} /> </div>
                  <FRZInputNumber minValue={0} name={['D', 'rate']}
                    label="" placeholder="rate" width={150} errorMsg="Please enter rate" isRequired={isFieldRequired} valueOf={"rate"} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default ImsD