import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button, Select, Input, InputNumber,
         Radio, Upload, DatePicker} from 'antd';
import { useSelector } from "react-redux";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
const { Option } = Select;
 
const BannerModal = forwardRef((props, ref) => {
  const darkstore = useSelector(state => state.auth.darkstore);
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [segmentType, setSegmentType] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
      setSegmentType((data?.segments_attributes_prefix_city?.length || data?.segments_attributes_prefix_other?.length) > 0 ? 'select_segment' : 'all_users')
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOk() {
    setLoading(true)
    props.modalSubmit(segmentType)
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.onCancelModal();
  }

  function handleClick({ file, onSuccess }){
    setTimeout(() => {
    onSuccess("ok");
    }, 0);
  }

  const webuploadButton = (
    <div>
      {props.webimgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const mobuploadButton = (
    <div>
      {props.mobimgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const actionuploadButton = (
    <div>
      {props.actionimgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onOk={handleOk}
          width='60%'
          onCancel={handleCancelModal}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              Submit
            </Button>,
          ]}
        >
          <div className="block" style={{marginLeft: 24}}>Banner Name :
            <span style={{paddingLeft:10}}>
              <Input placeholder="Banner Name"
                value={props.banner_name} style={{width:200}}
                onChange={(e) => props.onChangeModal(e.target.value, 'banner_name')}/>
            </span>
          </div>
          <div className="block" style={{display: 'flex', marginLeft: 70}}>Web Banner :
            <span style={{paddingLeft:10}}>
                <b>(1280px * 531px)</b>
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    customRequest={handleClick}
                    onChange={props.handleChangeWeb}
                >
                    {props.webimageUrl ? <img src={props.webimageUrl} alt="avatar" style={{ width: '100%' }} /> : webuploadButton}
                </Upload>
            </span>
          </div>
          <div className="block" style={{display: 'flex', marginLeft: 5}}>Old App Mobile Banner :
            <span style={{paddingLeft:10}}>
                <b>(573px * 339px)</b>
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    customRequest={handleClick}
                    onChange={props.handleChangeMob}
                >
                    {props.mobimageUrl ? <img src={props.mobimageUrl} alt="avatar" style={{ width: '100%' }} /> : mobuploadButton}
                </Upload>
            </span>
          </div>
          <div className="block" style={{display: 'flex'}}>New App Mobile Banner :
            <span style={{paddingLeft:10}}>
                <b>(472px * 500px)</b>
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    customRequest={handleClick}
                    onChange={props.handleChangeNewMob}
                >
                    {props.newMobImageUrl ? <img src={props.newMobImageUrl} alt="avatar" style={{ width: '100%' }} /> : mobuploadButton}
                </Upload>
            </span>
          </div>
          {/* <div className="block" style={{marginLeft: 52}}>Web URL :
            <span style={{paddingLeft:10}}>
              <Input placeholder="Web URL"
                value={props.web_url} style={{width:200}}
                onChange={(e) => props.onChangeModal(e.target.value, 'web_url')}/>
            </span>
          </div>
          <div className="block" style={{marginLeft: 39}}>Mobile URL :
            <span style={{paddingLeft:10}}>
              <Input placeholder="Mobile URL"
                value={props.mobile_url} style={{width:200}}
                onChange={(e) => props.onChangeModal(e.target.value, 'mobile_url')}/>
            </span>
          </div> */}
          <div className="block" style={{marginLeft: 38}}>Description :
            <span style={{paddingLeft:10}}>
              <Input placeholder="Description"
                value={props.description} style={{width:200}}
                onChange={(e) => props.onChangeModal(e.target.value, 'description')}/>
            </span>
          </div>

          <div className="block" style={{marginLeft:38}}>Banner Type:
              <span style={{paddingLeft:10}}>
              <Select style={{ width: 200}} value={props.type_of_data}
                      placeholder="Banner Type" onChange={(e) => props.onChangeModal(e, 'type_of_data')}
                      >
                {props.bannerTypeData.type_of_data.map(data => {
                  return(
                    <Option key={data}>{data}</Option>
                  )
                })}
              </Select>
              </span>
            </div>

          <div className="block" style={{marginLeft:8}}>Associate Action :
            <span style={{paddingLeft:10}}>
              <Radio.Group
                     value={props.valueByProduct}
                     onChange={(e) => props.onChangeModal(e.target.value, 'valueByProduct')}>
                <Radio value='product'>Product</Radio>
                <Radio value='offer'>Offer</Radio>
                <Radio value='product_category'>Sub-Category</Radio>
                <Radio value='category'>Category</Radio>
                <Radio value='promotional_page'>Page</Radio>
                <Radio value='noproduct'>None</Radio>
              </Radio.Group>
            </span>
          </div>
          {props.valueByProduct == 'product' ?
            <div className="block" style={{marginLeft:62}}>Product :
              <span style={{paddingLeft:10}}>
              <Select style={{ width: 300}} value={props.currentProduct} showSearch
                      placeholder="Select Product" onChange={(e) => props.onChangeModal(e, 'currentProduct')}
                      filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                {props.productData.map(data => {
                  return(
                    <Option key={data.id} value={data.id}>{data.name}</Option>
                  )
                })}
              </Select>
              </span>
            </div>
          : null}
          {props.valueByProduct == 'product_category' ?
            <div className="block" style={{marginLeft: 28}}>Sub Category :
              <span style={{paddingLeft:10}}>
              <Select style={{ width: 300}} value={props.currentProductSubCat}
                      placeholder="Select Sub Category" onChange={(e) => props.onChangeModal(e, 'currentProductSubCat')}
                      filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                {props.productSubCatData.map(data => {
                  return(
                    <Option key={data.id} value={data.id}>{data.name}</Option>
                  )
                })}
              </Select>
              </span>
            </div>
          : null}
          {props.valueByProduct == 'category' ?
            <div className="block" style={{marginLeft: 54}}>Category :
              <span style={{paddingLeft:10}}>
              <Select style={{ width: 300}} value={props.currentProductCat}
                      placeholder="Select Category" onChange={(e) => props.onChangeModal(e, 'currentProductCat')}
                      filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                {props.catData.map(data => {
                  return(
                    <Option key={data.id} value={data.id}>{data.name}</Option>
                  )
                })}
              </Select>
              </span>
            </div>
          : null}
          {props.valueByProduct == 'promotional_page' ?
            <div className="block" style={{marginLeft: 74}}>Pages :
              <span style={{paddingLeft:10}}>
              <Select style={{ width: 200}} value={props.currentProductAction}
                      placeholder="Select Pages" onChange={(e) => props.onChangeModal(e, 'currentProductAction')}
                      filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                {props.pagesData.map(data => {
                  return(
                    <Option key={data.id} value={data.id}>{data.title}</Option>
                  )
                })}
              </Select>
              </span>
            </div>
          : null}
          {props.valueByProduct == 'offer' ?
            <div className="block" style={{display: 'flex', marginLeft: 28}}>Action Image :
              <span style={{paddingLeft:10}}>
                  <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      customRequest={handleClick}
                      onChange={props.handleChangeAction}
                  >
                      {props.actionimageUrl ? <img src={props.actionimageUrl} alt="avatar" style={{ width: '100%' }} /> : actionuploadButton}
                  </Upload>
              </span>
            </div>
          : null}
          <div className="block" style={{marginLeft:60}}>Is Active :
            <span style={{paddingLeft:10}}>
              <Radio.Group
                     value={props.valueByRadio}
                     onChange={(e) => props.onChangeModal(e.target.value, 'valueByRadio')}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </span>
          </div>
          {/* <div className="block" style={{marginLeft:32}}>Is First Order :
            <span style={{paddingLeft:10}}>
              <Radio.Group
                     value={props.is_first_order}
                     onChange={(e) => props.onChangeModal(e.target.value, 'is_first_order')}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </span>
          </div> */}
          <div className="block" style={{marginLeft: 52}}>Sequence :
            <span style={{paddingLeft:10}}>
              <InputNumber placeholder="Sequence no"
                value={props.sequence} style={{width:200}}
                onChange={(e) => props.onChangeModal(e, 'sequence')}/>
            </span>
          </div>
          <div className="block" style={{marginLeft:35}}>Valid From <span style={{color: 'red'}}>* &nbsp;</span> :
            <span style={{paddingLeft:10}}>
                <DatePicker value={props.valid_from_date} onChange={(e, f) => props.onChangeDate(e, f, 'valid_from_date', 'valid_from')} 
                            placeholder="Valid From Date"
                            style={{width: 200}}/>
            </span>
          </div>
          <div className="block" style={{marginLeft:53}}>Valid To <span style={{color: 'red'}}>* &nbsp;</span> :
              <span style={{paddingLeft:10}}>
                  <DatePicker value={props.valid_to_date} onChange={(e, f) => props.onChangeDate(e, f, 'valid_to_date', 'valid_to')} 
                              placeholder="Valid To Date"
                              style={{width: 200}}/>
              </span>
          </div>
          <div className="block" style={{marginLeft:24}}>Activation Day :
            <span style={{paddingLeft:10}}>
              <Select mode="multiple" style={{ width: 300}}
                      value={props.valueByActivation}
                      placeholder="Select Activation Day (Optional)" onChange={(e) => props.onChangeModal(e, 'valueByActivation')}
                      filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Option value="Monday" key="Monday">Monday</Option>
                <Option value="Tuesday" key="Tuesday">Tuesday</Option>
                <Option value="Wednesday" key="Wednesday">Wednesday</Option>
                <Option value="Thursday" key="Thursday">Thursday</Option>
                <Option value="Friday" key="Friday">Friday</Option>
                <Option value="Saturday" key="Saturday">Saturday</Option>
                <Option value="Sunday" key="Sunday">Sunday</Option>
              </Select>
            </span>
          </div>
          <div className="block" style={{marginLeft:27}}>Segment Type :
            <span style={{paddingLeft:10}}>
              <Radio.Group
                     value={segmentType}
                     onChange={(e) => setSegmentType(e.target.value)}>
                <Radio value={'all_users'}>All Users</Radio>
                <Radio value={'select_segment'}>Select Segment</Radio>
              </Radio.Group>
            </span>
          </div>
          {segmentType === 'select_segment' ? 
          <>
              <div className="block" style={{ marginLeft: 32 }}>Segment City :
                <span style={{ paddingLeft: 10 }}>
                  <Select mode="multiple" style={{ width: 400 }}
                    value={props.valueBySegment}
                    placeholder="Select Segment City" onChange={(e) => props.onChangeModal(e, 'valueBySegment')}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {props?.segmentData?.map((data, index) => {
                      return (
                        <Option value={data.slug} key={index}>{data.name}</Option>
                      )
                    })}
                  </Select>
                </span>
              </div>
              <div className='frz-segment-and' style={{textAlign: 'left', padding: '10px 300px'}}>AND</div>
              <div className="block" style={{ marginLeft: 21 }}>Segment Other :
                <span style={{ paddingLeft: 10 }}>
                  <Select mode="multiple" style={{ width: 400 }}
                    value={props.valueBySegmentOther}
                    placeholder="Select Segment Other" onChange={(e) => props.onChangeModal(e, 'valueBySegmentOther')}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {props?.segmentOtherData?.map((data, index) => {
                      return (
                        <Option value={data.slug} key={index}>{data.name}</Option>
                      )
                    })}
                  </Select>
                </span>
              </div>
            </> : null}
          {/* <div className="block" style={{marginLeft:27}}>All Darkstores :
            <span style={{paddingLeft:10}}>
              <Radio.Group
                     value={props.valueByRadioDS}
                     onChange={(e) => props.onChangeModal(e.target.value, 'valueByRadioDS')}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </span>
          </div>
          {props.valueByRadioDS === false? 
            <div className="block" style={{marginLeft:45}}>Darkstores :
              <span style={{paddingLeft:10}}>
                {darkstore ?   
                  <Select mode="multiple" style={{ width: 400}}
                          value={props.valueByDarkstore}
                          placeholder="Select Darkstore" onChange={(e) => props.onChangeModal(e, 'valueByDarkstore')}
                          filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                      {darkstore.map(data => {
                          return(
                              <Option value={data.id} key={data.id}>{data.name}</Option>
                          )
                      })}
                  </Select>
                : null
                }
              </span>
            </div>
          : null} */}
        </Modal>
      : null }
    </div>
  )
})

export default BannerModal
