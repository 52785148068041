import React from 'react';
import { Card, Row, Col } from 'antd';
import { DistributionService } from "../../_services/distribution";
import PackerDistribution from '../../components/master/distribution/packer';

const distribution_service = new DistributionService();

const PackerDistributionComp = () => {

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card>
            <PackerDistribution />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default PackerDistributionComp
