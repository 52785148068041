import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Pagination, Input, DatePicker } from 'antd';
import { OperationsService } from "../../../_services/operations";
import MainTable from '../../../components/antd/table';
import { forcastListingColumns } from "../../../components/antd/columns/operations/procurement";
import { openNotificationWithIcon } from '../../../_modules/notification'
import ForcastListingModal from '../../../components/operations/procurement/ForcastListingModal';
import { ReloadOutlined } from '@ant-design/icons';
import moment from 'moment'
import FrzDynamicDownload from '../../../_controls/FRZDynamicDownload';
import FrzDynamicUpload from '../../../_controls/FRZDynamicUpload';

const operation_service = new OperationsService();

const ProcurementList = () => {
  const modalRef = useRef(null);
  const [listingData, setListingData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [mode, setMode] = useState(undefined)
  const [total, setTotal] = useState(false)
  const [current, setCurrent] = useState(1);
  const [products, setProducts] = useState(false)
  const [operations, setOperations] = useState(false)
  const [city, setCity] = useState(false)
  const [fetchColm, setFetchColm] = useState(false) 
  const [state, setState] = useState({ valueByName: "", date: "" });
  const { valueByName, date } = state

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getListing()
      getCity()
    }
  })

  useEffect(() => {
    getProducts()
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if(['admin','developer','super_admin','category','category_admin'].includes(data)){
        setOperations(true)
      }
    })
    setFetchColm(true)
  }, [])

  useEffect(() => {
    if(fetchColm){
      setFetchColm(false)
      getColumns()
    }
  })

  function getListing() {
    setSpinEnable(true)
    setListingData([])
    const payload = {
      page: current,
      date: moment(date).isValid() ? date : "",
      valueByName: valueByName,
    }
    operation_service.get_forcast_listing(payload).subscribe((r) => {
      setTotal(r.response.meta.total_pages * 50);
      setSpinEnable(false);
      setListingData(r.response.data);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  function getCity() {
    operation_service.get_city().subscribe((r) => {
      setCity(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getProducts() {
    operation_service.get_product_list().subscribe((r) => {
      setProducts(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getColumns() {
    forcastListingColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: "Actions",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <Button type="primary" onClick={() => showModal(record, 'Edit')} disabled={!operations}>Edit</Button>
        );
      },
    });
    setColumns(columns)
  }

  function showModal(data, type) {
    setMode(type)
    modalRef.current.showModal(type, data)
  }

  function reset() {
    setState({ valueByName: "", date: "" })
    setDisable(true)
    setCurrent(1)
    setListingData([])
    setRenderData(false)
  }


  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }

  function pagination(page) {
    setCurrent(page);
    setListingData([]);
    setRenderData(false);
  }

  function modalSubmit(payloadData, id) {
    const payload = {
      ...payloadData, id: id ? id : undefined
    }
    if (mode === 'Edit') {
      operation_service.update_forcast_listing(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Edited Successfully')
        getListing()
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors?.[0])
        })
    } else {
      operation_service.create_forcast_listing(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Created Successfully')
        getListing()
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors?.[0])
        })
    }
  }

  const getDateFormat = date => {
    let d = moment(date);
    return date && d.isValid() ? d : '';
  };

  return (
    <div className="layer">
      {listingData && products && city?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Input
                  placeholder="SKU Name"
                  onChange={(e) => onChange(e.target.value, "valueByName")}
                  value={valueByName}
                  className="frz-w-200 frz-m-10" />
                <DatePicker
                  placeholder="Date"
                  onChange={(e) => onChange(moment(e).format("YYYY-MM-DD"), "date")}
                  value={getDateFormat(date)}
                  className="frz-w-200 frz-m-10" />
                <Button disabled={disable} onClick={getListing}
                  className="frz-w-100px frz-m-10" type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Create Forcast Listing')} className="frz-dwn"
                  type="primary" disabled={!operations}>Create Forcasting</Button>
                <div className="frz-dis-upload dynamic-upload">
                  <FrzDynamicUpload name={'Forecasting'} url={'admin_dashboard/procurement/upload_sheet'} uploadFunc={'upload_zapper'}
                                    callBack={getListing} disabled={!operations}/>
                </div>
                <span className="fl-right">
                  <FrzDynamicDownload name={'Forecast'} fileName={'Forecast'} url={`admin_dashboard/procurement/forecast_report`}
                    payload={'?date=' + `${date ?? ''}` + '&by_name=' + `${valueByName ?? ''}`} type={'job'}
                    reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} />
                </span>
              </Col>
            </Card>
          </Row>
          {listingData.length > 0 ?
            <>
              <Row>
                <Col span={24}>
                  <MainTable dataSource={listingData} columns={columns} />
                  <Pagination style={{ marginTop: 10, marginBottom: 10 }} current={current}
                    pageSize={50} total={total} onChange={pagination} showSizeChanger={false} />
                </Col>
              </Row>
            </>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <ForcastListingModal modalSubmit={modalSubmit} ref={modalRef} products={products} city={city}/>
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default ProcurementList
