import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Radio, Table, Spin, Popconfirm } from 'antd';
import { viewSKUColumn } from '../antd/columns/store';
import { StoreService } from '../../_services/store';
import { openNotificationWithIcon } from '../../_modules/notification';
import { humanize } from '../../_modules/humanize';

const store_service = new StoreService()

const ModalViewSku = ({ showSkuModal, showModalSku, rowData, getParentListing, viewOnly }) => {
  const [form] = Form.useForm();

  const [column, setColumn] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [listingData, setListingData] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getLisiting()
  }, [])

  const getLisiting = () => {
    const payload = {
      id: rowData.id
    }
    store_service.show_sku_list(payload).subscribe((r) => {
      setListingData(r.response.data.items)
    })
  }

  useEffect(() => {
    getColumn()
  }, [])

  const getColumn = () => {
    let tempCol = []
    viewSKUColumn.map((data) => {
      tempCol.push(data)
    })
    if (viewOnly) {
      tempCol.push({
        title: 'status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: (record) => {
          return (
            <div className={record === "approved" ? "frz-status-approved" : "frz-status-rejected"}>
              {humanize(record)}
            </div>
          )
        }
      })
    }

    setColumn(tempCol)
  }

  const onSelectChange = selectedRowKeys => {
    // console.log('selectedRowKeys changed: ', selectedRowKeys);
    // console.log("selectedRowKeys", selectedRowKeys)
    setSelectedRows(selectedRowKeys);
  };


  const rowSelection = {
    selectedRows,
    onChange: onSelectChange,
  };

  const handleOnApprove = () => {
    setLoading(true)
    let tempkeys = selectedRows
    let tempListingData = listingData.map((itm) => itm.id)
    tempkeys.map((item) => {
      let sumbittedData = tempListingData.find((itm) => itm == item)
      let indexOfSubmittedData = tempListingData.indexOf(sumbittedData)
      if (indexOfSubmittedData > -1) {
        tempListingData.splice(indexOfSubmittedData, 1)
      }
    })
    const payload = {
      id: rowData.id,
      approve: selectedRows,
      reject: tempListingData
    }
    store_service.sku_bulk_approval(payload).subscribe((r) => {
      setLoading(false)
      openNotificationWithIcon('success', `${!selectedRows.length ? "Rejected" : "Approved"} Successfully`)
      getParentListing()
      showModalSku(false)
    }, ((err) => {
      setLoading(false)
      openNotificationWithIcon('error', err.response.errors[0])
      console.log("err", err)
    }))
  }


  return (
    <Modal
      visible={showSkuModal}
      title="View SKU"
      onCancel={() => showModalSku(false)}
      width="90%"
      footer={[
        <Button key="back" onClick={() => showModalSku(false)}>
          Cancel
        </Button>,
        <>
          {!viewOnly ? !selectedRows.length ?
            <Popconfirm disabled={!rowData.can_take_action || rowData.expiry_at == null}
              placement="topLeft" title={"Are you sure you want to Reject all SKU?"}
              onConfirm={handleOnApprove} okText="Yes, reject all" cancelText="No">
              <Button disabled={!rowData.can_take_action || rowData.expiry_at == null}
                className={!selectedRows.length && "frz-lm-8"} type="primary" loading={loading}>
                Reject all
              </Button >
            </Popconfirm>
            :
            <Popconfirm disabled={!selectedRows.length || (!rowData.can_take_action || rowData.expiry_at == null)}
              placement="topLeft"
              title={"Are you sure you want to approve selected SKU? Unselected SKU will get rejected"}
              onConfirm={handleOnApprove} okText="Yes, Approve" cancelText="No">
              <Button disabled={!selectedRows.length || (!rowData.can_take_action || rowData.expiry_at == null)}
                className={(!selectedRows.length || (!rowData.can_take_action || rowData.expiry_at == null)) && "frz-lm-8"} type="primary" loading={loading}>
                Approve
              </Button >
            </Popconfirm> : null}
        </>
      ]}
    >
      {
        listingData ?
          listingData.length ?
            <div>
              <Table rowSelection={viewOnly || (!rowData.can_take_action || rowData.expiry_at == null) ? false : rowSelection} pagination={false} columns={column} dataSource={listingData} rowKey={'id'} scroll={{ y: 340 }} />
            </div>
            :
            <div className="no-data">No Data Available</div>
          :
          < div className="spin-center" >
            <Spin tip="...Loading" />
          </div >
      }
    </Modal >
  )
}

export default ModalViewSku