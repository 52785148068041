import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Select, Input, Pagination } from 'antd';
import { OperationsService } from "../../../_services/operations";
import MainTable from '../../../components/antd/table';
import { CCWHIncentoryColumns } from "../../../components/antd/columns/operations/category.js";
import { ReloadOutlined } from '@ant-design/icons';
import FrzDynamicDownload from '../../../_controls/FRZDynamicDownload';

const operation_service = new OperationsService();

const CCWHInventory = () => {
  const [listingData, setListingData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [nodeListing, setNodeLisiting] = useState([])
  const [state, setState] = useState({ nodeId: undefined, valueByName: undefined });

  const [total, setTotal] = useState(false)
  const [current, setCurrent] = useState(1);

  const { nodeId, valueByName } = state

  const { Option } = Select;

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getListing()
      getNodeListing()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  const getNodeListing = () => {
    const payload = {
      nodeType: `["CC","WH"]`
    }
    operation_service.get_node_listing(payload).subscribe((r) => {
      setNodeLisiting(r.response.data)
    }, (err) => {
      console.log(err);
    })
  }

  function getListing() {
    setSpinEnable(true)
    setListingData([])
    const payload = {
      nodeId: nodeId === undefined ? "" : nodeId,
      valueByName: valueByName === undefined ? "" : valueByName,
      page: current
    }
    operation_service.get_cc_wh_inventory(payload).subscribe((r) => {
      setTotal(r.response.meta.total_pages * 50);
      setSpinEnable(false);
      setListingData(r.response.data);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  function getColumns() {
    CCWHIncentoryColumns.map(data => {
      columns.push(data)
    })
    setColumns(columns)
  }

  function pagination(page) {
    setCurrent(page);
    setListingData([]);
    setRenderData(false);
  }

  function reset() {
    setState({ nodeId: undefined })
    setDisable(true)
    setListingData([])
    setRenderData(false)
  }


  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
  }

  return (
    <div className="layer">
      {listingData && nodeListing ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Select style={{ width: 200, margin: 10 }} value={nodeId} showSearch
                  onChange={(e) => onChange(e, 'nodeId')} placeholder="Node Name">
                  {nodeListing.map(data => {
                    return (
                      <Option key={data.id} value={data.id}>{data.name}</Option>
                    )
                  })}
                </Select>
                <Input
                  placeholder="Product Name"
                  onChange={(e) => onChange(e.target.value, "valueByName")}
                  value={valueByName}
                  className="frz-w-200 frz-m-10" />
                <Button disabled={disable} onClick={getListing}
                  className="frz-w-100px frz-m-10" type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
                <span className="fl-right">
                  <FrzDynamicDownload name={'Inventory'} fileName={'Inventory'} url={'admin_dashboard/procurement/get_inventory_report'}
                                      payload={'?node_id='+`${nodeId ?? ''}`+'&by_name='+`${valueByName ?? ''}`
                                              +'&admin_id='+`${JSON.parse(localStorage.getItem('auth')).id}`} 
                                      type={'job'} reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'}/>                          
                </span>
              </Col>
            </Card>
          </Row>
          {listingData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={listingData} columns={columns} />
                <Pagination style={{ marginTop: 10, marginBottom: 10 }} current={current}
                  pageSize={50} total={total} onChange={pagination} showSizeChanger={false} />
              </Col>
            </Row>
          : (disable || spinEnable ?
            (spinEnable ?
              <div className="spin-center"><Spin tip="...Loading" /></div>
              :
              <div className="no-data">No Data Available</div>)
            :
            <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default CCWHInventory