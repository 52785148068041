import React, { useEffect, useState, useRef } from "react";
import { GrowthAndPlanningService } from '../../_services/growthAndPlanning';
import { GeneralService } from '../../_services/general';
import { ReloadOutlined, EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Row, Card, Col, Button, Table, Pagination, Spin, Input, DatePicker, Modal, Divider, Form, Popconfirm, Dropdown, Menu} from "antd";
import Search from '../antd/search';
import { formItemLayout } from '../../_modules/controllayout';
import FrzDynamicDownload from "../../_controls/FRZDynamicDownload";
import FrzDynamicUpload from "../../_controls/FRZDynamicUpload";
import moment from 'moment'
import { openNotificationWithIcon } from "../../_modules/notification";
import LogsModal from './logsModal';

const initialState = {
  btnDisable: true,
  spinner: true,
  err: false,
  logsModal: false,
  dateFormat: 'YYYY-MM-DD',
  darkstore: [],
  rowId: null,
  saveBtnSpinner: false
}
const initialFilters = {
  page: 1,
  segmentSearch: null,
  isSearch: false,
  selectedDate: moment().add(0, 'days').format(initialState.dateFormat),
  selectedDarkstore: ""
}

const growth_and_planning = new GrowthAndPlanningService();
const general_service = new GeneralService();

const GrowthAndPlanningComponent = (props) => {
  const modalRef = useRef(null);
  const [tableData, setTableData] = useState(false)
  const [filters, setFilters] = useState(initialFilters)
  const [state, setState] = useState(initialState)
  const [columns, setColumns] = useState([])
  const [form] = Form.useForm();
  const { tab } = props;
  useEffect(() => {
    filters.isSearch && getTableData(false)
  }, [filters])
  useEffect(() => {
    getDarkstores()
    getTableData(false)
  }, [])
  useEffect(() => {
    if (tableData.length) {
      getColumns()
    }
  }, [tableData, state.rowId, state.saveBtnSpinner])
  function getTableData(isReset = false) {
    setState(prevState => ({ ...prevState, spinner: true }))
    const payload = {
      date: isReset ? initialFilters.selectedDate : filters.selectedDate,
      download: false,
      by_darkstores: isReset ? initialFilters.selectedDarkstore : filters.selectedDarkstore,
      url: tab === 'growth' ? 'predicted_capacity' : 'predicted_pnp'
    }
    growth_and_planning.get_growth_and_planning(payload).subscribe((res) => {
      let formattedData = res?.response?.data.map((item) => {
        let data = {}
        item.data.map(val => {
          data[val.date] = {...val};
        })
        delete item.data;
        return {
          ...item,
          ...data
        }
      })
      setTableData(formattedData)
      setLocalState('spinner', false)
    }, (err) => {
      setLocalState('spinner', false)
      setTableData([])
      openNotificationWithIcon('error', 'Something went wrong please try again!')
      console.log(err.response)
    })
  }

  function getTitle(title) {
    switch (title) {
      case 'darkstore_name': return 'Dark Stores';
      case 'growth_avg': return 'Avg Order';
      case 'avg_man_power': return 'Avg Man Power';
      default: return '';
    }
  }

  function getColumns() {
    let tempCol = []
    let colKeys = Object.keys(tableData[0])
    let isGrowthTab =  tab === 'growth';
    colKeys.map(key => {
      if (key !== 'darkstore_id' && key !== "avg_est" && key !== 'daily_avg' && !moment(key, state.dateFormat, true).isValid()) {
        tempCol.push({
          title: getTitle(key),
          dataIndex: key,
          key: key,
          align: 'center',
          width: '23%',
          editable: false,
          sorter: (a, b) => key === 'darkstore_name' ? a.darkstore_name.localeCompare(b.darkstore_name) : a[key] - b[key]
        })
      } else if (moment(key, state.dateFormat, true).isValid()) {
        tempCol.push({
          title: () => <span>{key}<br/>{moment(key).format('ddd')}</span>,
          dataIndex: isGrowthTab ? 'record[key].growth_capacity' : 'record[key].actual_pnp',
          key: key,
          align: 'center',
          width: '30%',
          editable: true,
          sorter: (a, b) => isGrowthTab ? a[key]?.growth_capacity - b[key]?.growth_capacity : a[key]?.actual_pnp - b[key]?.actual_pnp,
          render: (_, record) => {
            return isGrowthTab ? record[key]?.growth_capacity : record[key]?.actual_pnp;
          },
        })
      }
    })
    // if(tab === 'growth'){
    //   tempCol.push({
    //     title: 'Edit',
    //     dataIndex: '',
    //     key: '',
    //     align: 'center',
    //     width: '21%',
    //     render: (record) => {
    //       const isEditMode = isRowEditable(record)
    //       return (
    //         isEditMode ?
    //           <div className="frz-vt-center">
    //             <Popconfirm
    //               title="Are you sure you want to save ?"
    //               onConfirm={() => saveData(record)}
    //               okText="Yes"
    //               cancelText="No"
    //             >
    //               <Button
    //                 shape="circle"
    //                 icon={state.saveBtnSpinner ? <Spin /> : <CheckOutlined />}
    //                 className="frz-rm-10"
    //                 type="primary" ghost />
    //             </Popconfirm>
    //             <Button shape="circle" icon={<CloseOutlined />} onClick={() => setLocalState('rowId', null)} type="danger" ghost />
    //           </div> : <Button type="primary" shape="circle" ghost onClick={() => onEditClick(record)} icon={<EditOutlined />} />
    //       )
    //     }
    //   })
    // }
    setColumns(tempCol)
  }

  const mergeColumns = columns.map((col) => {
    if (!col.editable) {
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: false,
          dataIndex: col.dataIndex,
          data: col.key,
        }),
      };
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: isRowEditable(record),
        dataIndex: col.dataIndex,
        data: col.key,
      }),
    };
  });

  const onEditClick = (record) => {
    let newFormObj = {}
    let isGrowthTab =  tab === 'growth';
    Object.keys(record).map(val => {
      if (moment(val, state.dateFormat, true).isValid()) {
        return newFormObj[val] = isGrowthTab ? record[val]?.peak_adjustment : record[val]?.actual_pnp
      } else {
        return newFormObj[val] = record[val]
      }
    });
    form.setFieldsValue({
      ...newFormObj
    })
    setLocalState('rowId', record.darkstore_id)
  }

  const isRowEditable = (record) => state.rowId === record.darkstore_id

  function setLocalState(type, value) {
    setState(prevState => ({ ...prevState, [type]: value }))
  }

  const EditableCell = ({
    editable,
    dataIndex,
    data,
    record,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editable ? (
          <>
            <Form.Item
              name={data}
              // rules={[
              //   {
              //     required: true
              //   },
              // ]}
              validateStatus={(form.getFieldValue(data) === '' || +form.getFieldValue(data) < 0 || isNaN(+form.getFieldValue(data))) && 'error'}
              className="frz-margin-0"
            >
              <Input className="frz-text-center" />
            </Form.Item>
            {tab === 'planning' && moment(data?.toString(), state.dateFormat, true).isValid() &&
              <>
                <Divider className="frz-divider" />
                <span className="frz-color-gray">Est: {record[data]?.est}</span>
              </>}
            {tab === 'growth' && (moment(data?.toString(), state.dateFormat, true).isValid() || data === 'growth_avg') &&
              <>
                <Divider className="frz-divider" />
                <span className="frz-color-gray">Est: {data === 'growth_avg' ? record.daily_avg : record[data]?.peak_adjustment}</span>
              </>
            }
          </>
        ) : (
          <>
            {children}
              {tab === 'planning' && (moment(data?.toString(), state.dateFormat, true).isValid() || data === 'avg_man_power') &&
                <>
                  <Divider className="frz-divider" />
                  <span className="frz-color-gray">Est: {data === 'avg_man_power' ? record.avg_est : record[data]?.est}</span>
                </>
              }
              {tab === 'growth' && (moment(data?.toString(), state.dateFormat, true).isValid() || data === 'growth_avg') &&
                <>
                  <Divider className="frz-divider" />
                  <span className="frz-color-gray">Est: {data === 'growth_avg' ? record.daily_avg : record[data]?.peak_adjustment}</span>
                </>
              }
          </>
        )}
      </td>
    );
  };

  const saveData = (record) => {
    form.validateFields().then(values => {
      setLocalState('saveBtnSpinner', true)
      let editedData = []
      let isGrowthTab = tab === 'growth';
      let isAllValidValue = false;
      Object.keys(values).map(key => {
        let prevValue = isGrowthTab ? record[key]?.peak_adjustment : record[key]?.actual_pnp;
        if (prevValue !== +values[key]) {
          let newCap = isGrowthTab ? 'peak_adjustment' : 'actual_pnp'
          editedData.push({
            date: key,
            darkstore_id: record.darkstore_id,
            [newCap]: +values[key]
          })
        }
        if (values[key] === '' || +values[key] < 0 || isNaN(+values[key])) {
          isAllValidValue = true
        }
      })
      if (isAllValidValue) {
        openNotificationWithIcon('error', 'Field cannot have blank/negative/alphabets values')
        setLocalState('saveBtnSpinner', false)
        return
      }
      const payload = {
        data: editedData
      }
      let url = tab === 'growth' ? 'edit_predicted_capacity' : 'edit_predicted_pnp';
      growth_and_planning.update_growth_and_planning(url, payload).subscribe(() => {
        setLocalState('rowId', null)
        setLocalState('saveBtnSpinner', false)
        getTableData(false)
      }, 
      (err) => {
        setLocalState('saveBtnSpinner', false)
        console.log(err?.response)
      })
    })
    .catch(info => {
      // console.log('Validate Failed:', info);
    });
  }

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  function getDarkstores() {
    general_service.get_darkstore().subscribe((res) => {
      setLocalState('darkstore', res.response.data)
    }, (err) => {
      console.log(err.response)
    });
  }
  function onChangeFilters(value, type, searchNow) {
    value === '' ? setState(prevState => ({ ...prevState, btnDisable: true })) :
      setState(prevState => ({ ...prevState, btnDisable: false }))
    setFilters(prevState => ({ ...prevState, [type]: value, isSearch: searchNow }))
  }
  const reset = () => {
    setFilters(initialFilters); 
    setState(initialState);
    getTableData(true)
  }
  function disabledDate(current){
    return current && current > moment().add(30, "day");
  }
  const downloadOverlay = () => {
    return (
      <Menu style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Menu.Item>
          <FrzDynamicDownload name="Report" reportFunc={'get_download'}
            url={`admin_dashboard/v1/admin/${tab === 'growth' ? 'predicted_capacity' : 'predicted_pnp'}?date=${filters.selectedDate}&download=true&by_darkstores=${filters.selectedDarkstore}`}
            type={'job'} jobReportFunc={'download_reports_job_fraazo'} base={'fraazo'} />
        </Menu.Item>
        <Menu.Item>
          <FrzDynamicDownload name="Format" reportFunc={'get_download'}
            url={`admin_dashboard/v1/downloads/sample_growth_capacity_sheet?start_date=${filters.selectedDate}`}
            type={'job'} jobReportFunc={'download_reports_job_fraazo'} base={'fraazo'} />
        </Menu.Item>
        <Menu.Item><Button type="primary" className="frz-dwn" onClick={() => setState(prevState => ({ ...prevState, logsModal: true }))}>Logs</Button></Menu.Item>
      </Menu >
    )
  }
  
  return (
    <div className="layer">
      {tableData ?
        <div>
            <Row>
              <Col span={24}>
                <Card>
                  <Search placeholder="Select Darkstore" type="valueByDarkstore" data={state.darkstore}
                    onChange={(e) => onChangeFilters(e, 'selectedDarkstore', false)} value={filters.selectedDarkstore || undefined} />
                  <DatePicker placeholder="Date" style={{ width: 200, marginLeft: 10, marginRight: 10 }}
                    value={moment(filters.selectedDate)} format={state.dateFormat} allowClear={false}
                    onChange={(e, f) => onChangeFilters(f, 'selectedDate', false)} disabledDate={disabledDate} />
                  <Button disabled={state.btnDisable} onClick={() => { onChangeFilters(1, 'page', true) }}
                    className="frz-btn" type="primary">Search</Button>
                  <Button disabled={state.btnDisable} onClick={() => {reset()}}
                    type="primary" className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
                {tab !== 'growth' ? <span className="frz-float-right">
                  <FrzDynamicDownload name="Report" reportFunc={'get_download'}
                    url={`admin_dashboard/v1/admin/${tab === 'growth' ? 'predicted_capacity' : 'predicted_pnp'}?date=${filters.selectedDate}&download=true&by_darkstores=${filters.selectedDarkstore}`}
                    type={'job'} jobReportFunc={'download_reports_job_fraazo'} base={'fraazo'} />
                  <Button className="frz-dwn" type="primary" onClick={() => setState(prevState => ({ ...prevState, logsModal: true }))}>Logs</Button>
                </span> :
                  <span className="frz-float-right" style={{ display: 'inline-flex' }}>
                    <div><FrzDynamicUpload name={'Growth Capacity'} url={'upload_growth_capacity_sheet'} uploadFunc={'upload_reports'} callBack={() => getTableData(false)} /></div>
                    <Dropdown placement="bottom" overlay={downloadOverlay} className="frz-dwn">
                      <Button type="primary">Download</Button>
                    </Dropdown>
                  </span>}
                </Card>
              </Col>
            </Row>
          {tableData.length > 0 && !state.err ?
            <Spin spinning={state.spinner}>
              <Row>
                <Col span={24}>
                  <Card className="frz-mt-5">
                    <Form
                      form={form}
                      {...formItemLayout}
                      layout="horizontal"
                      name="form"
                    >
                      <Table dataSource={tableData} pagination={false} scroll={{ y: 'calc(100vh - 282px)' }} rowKey={'darkstore_id'}
                        columns={mergeColumns} components={components} />
                    </Form>
                  </Card>
                  {/* <Pagination current={filters.page} pageSize={20} total={tableData.length} className="frz-mt-5"
                    onChange={(e) => onChangeFilters(e, 'page', true)} showSizeChanger={false} /> */}
                </Col>
              </Row>
            </Spin>
            :
            <div className="no-data">{state.err ? 'Backend API System Down' : 'No Data Available'}</div>
          }
          <LogsModal logsModal={state.logsModal} setState={setLocalState} tab={tab} data={state.logsData} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
};

export default GrowthAndPlanningComponent;