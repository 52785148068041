export const societyColumns = [
  {
    title: 'Society Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center'
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    width: 200,
    align: 'center'
  },
  {
    title: 'Assigned Darkstore',
    dataIndex: 'darkstore_name',
    key: 'darkstore_name',
    align: 'center'
  },
  {
    title: 'Is Verified',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.is_verified.toString()
    }
  }
]

export const societyCampaignColumns = [
  {
    title: 'Campaign Name',
    dataIndex: 'campaign_name',
    key: 'campaign_name',
    align: 'center'
  },
  {
    title: 'Society Name',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.society_details.name
    }
  },
  {
    title: 'Society Type',
    dataIndex: 'society_type',
    key: 'society_type',
    align: 'center'
  },
  {
    title: 'Completed Order',
    dataIndex: 'completed_order_count',
    key: 'completed_order_count',
    align: 'center'
  },
  {
    title: 'Targeted Order',
    dataIndex: 'targeted_order_count',
    key: 'targeted_order_count',
    align: 'center'
  },
  {
    title: 'Status',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.society_campaign_status_details.name
    }
  },
]

export const societyCampaignDiscountColumns = [
  {
    title: 'Min Order',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.min_order
    }
  },
  {
    title: 'Max Order',
    dataIndex: 'max_order',
    key: 'max_order',
    align: 'center'
  },
  {
    title: 'Offer Percentage',
    dataIndex: 'offer_percentage',
    key: 'offer_percentage',
    align: 'center'
  }
]

export const pickupColumns = [
  {
    title: 'Society Name',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.point_details ? record.point_details.name : null
    }
  },
  {
    title: 'Address',
    dataIndex: '',
    key: '',
    width: 200,
    align: 'center',
    render: (record) => {
      return record.point_details ? record.point_details.address : null
    }
  },
  {
    title: 'Pickup Code',
    dataIndex: 'pickup_code',
    key: 'pickup_code',
    align: 'center'
  },
  {
    title: 'Delivery Date',
    dataIndex: 'delivery_date',
    key: 'delivery_date',
    align: 'center',
  }
]

export const pickupOrderColumns = [
  {
    title: 'Order No',
    dataIndex: 'order_number',
    key: 'order_number',
    align: 'center'
  },
  {
    title: 'Nett Amount',
    dataIndex: 'nett_amount',
    key: 'nett_amount',
    align: 'center'
  },
  {
    title: 'Payable Amount',
    dataIndex: 'payable_amount',
    key: 'payable_amount',
    align: 'center'
  },
  {
    title: 'Delivery Date',
    dataIndex: 'delivery_date',
    key: 'delivery_date',
    align: 'center'
  },
  {
    title: 'Order Status',
    dataIndex: 'order_status',
    key: 'order_status',
    align: 'center'
  },
  {
    title: 'Delivered At',
    dataIndex: 'delivered_at',
    key: 'delivered_at',
    align: 'center'
  },
  {
    title: 'Customer Name',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.customer_details.name
    }
  },
  {
    title: 'Mobile No',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.customer_details.mobile_number
    }
  },
]

export const dsTargetColumns = [
  {
    title: 'Darkstore Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center'
  },
  {
    title: 'Darkstore Code',
    dataIndex: 'code',
    key: 'code',
    align: 'center'
  },
  {
    title: 'Society Target',
    dataIndex: 'society_target',
    key: 'society_target',
    align: 'center'
  },
]
