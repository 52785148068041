import { Http } from '../_modules/http'

export class CapacityService {

  http = new Http()

  get_capacity(payload) {
    const url = process.env.REACT_APP_API_URL + `store_dashboard/v1/store/slots?darkstore_id=${payload.id}&date=${payload.date}`
    // &date=${payload.date}
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  update_capacity(payload) {
    const url = process.env.REACT_APP_API_URL + 'store_dashboard/v1/store/edit_slot_capacity'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  get_reason() {
    const url = process.env.REACT_APP_API_URL + 'store_dashboard/v1/store/edit_slot_capacity_reasons'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }
}
