import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Spin, Button, Card, Row, Col, Table, Pagination } from 'antd';
import { openNotificationWithIcon } from '../../_modules/notification';
import { PaymentsService } from "../../_services/payments";
import ParentPayments from '../../components/master/payments/parent_payments';
import ChildPayments from '../../components/master/payments/child_payments';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';
import PaymentsModal from '../../components/master/payments/payments_modal'
import UPIExtensionModal from '../../components/master/payments/upi_extension_modal';

const type = 'DraggableBodyRow';
const payments_service = new PaymentsService();

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

const DraggableBodyRow2 = ({ index, moveRow2, className, style, ...restProps }) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveRow2(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

function Payments() {

  const modalRef = useRef(null);
  const modalRef2 = useRef(null)
  const [parentData, setParentData] = useState(false)
  const [childData, setChildData] = useState(false)
  const [childDataExtension, setChildDataExtension] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [updateParentSeq, setUpdateParentSeq] = useState(false)
  const [updateChildSeq, setUpdateChildSeq] = useState(false)
  const [activeParent, setActiveParent] = useState(undefined)
  const [rowData, setRowData] = useState(false)
  const [paymentsType, setPaymentsType] = useState(false)

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getPayments()
      getExtensionData()
    }
  })

  useEffect(() => {
    if (childDataExtension) {
      childDataExtension.forEach(function (el, idx) {
        el.id = idx
      })
    }
  }, [childDataExtension])

  function getPayments(data) {
    if (data?.actual_payment) {
      setActiveParent(data)
      setChildData([])
    } else {
      if (data) {
        setChildData(false);
      } else {
        setSpinEnable(true)
      }
      const payload = {
        parent_id: data?.payment_mode_id ?? null
      }
      payments_service.get_payments(payload).subscribe((r) => {
        setPaymentsType(r.response.payment_gateways)
        setSpinEnable(false);
        if (data) {
          setActiveParent(data)
          setChildData(r.response.data.sort((a, b) => a.sequence - b.sequence));
        } else {
          setParentData(r.response.data.sort((a, b) => a.sequence - b.sequence));
          if (r.response.data.length > 0) {
            getPayments(r.response.data[0])
          }
        }
      },
        (err) => {
          console.log(err);
          if (data) {
            setActiveParent(data)
            setChildData([]);
          } else {
            setParentData([]);
          }
        }
      );
    }
  }

  function getExtensionData() {
    const payload = {
      name: "upi_extension"
    }
    payments_service.get_upi_extensions(payload).subscribe((r) => {
      setSpinEnable(false);
      setChildDataExtension(r.response.data.properties);
    },
      (err) => {
        console.log(err);
        setChildDataExtension([]);
      }
    );
  }

  function onChangeStatus(e, id, type) {
    e.stopPropagation();
    const payload = {
      id: id,
      body: {
        status: e.target.checked ? 'active' : 'inactive'
      }
    }
    payments_service.update_payments(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Updated Successfully')
      if (type === 'parent') {
        parentData[parentData.findIndex(x => x.id === r.response.data.id)] = r.response.data
        setParentData([...parentData])
      } else {
        childData[childData.findIndex(x => x.id === r.response.data.id)] = r.response.data
        setChildData([...childData])
      }
    },
      (err) => {
        console.log(err);
        openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
      }
    );
  }

  function updatePaymentSequence(data, type) {
    if (type === 'upi extension') {
      const payload2 = {
        name: "upi_extension",
        properties: data,
      }

      payments_service.update_upi_extension(payload2).subscribe((r) => {
        setChildDataExtension(r.response.data.properties)
        openNotificationWithIcon('success', 'Edited Successfully')
        modalRef2.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef2.current.stopLoading()
          openNotificationWithIcon("error", err?.response?.errors?.[0] ?? 'API Error');
        })
    }
    else {
      const payload = {
        by_city: localStorage.getItem('city') ?? '',
        parent_id: type === 'parent' ? null : activeParent?.payment_mode_id ?? null,
        sequence: data.map(a => a.id)
      }
      payments_service.update_payments_sequence(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Updated Successfully')
      },
        (err) => {
          console.log(err);
          openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
        }
      );
    }
  }

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const components_extension = {
    body: {
      row: DraggableBodyRow2,
    },
  };

  const moveParentRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = parentData[dragIndex];
      setParentData(
        update(parentData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
      setUpdateParentSeq(true)
    },
    [parentData],
  );

  useEffect(() => {
    if (updateParentSeq) {
      setUpdateParentSeq(false)
      updatePaymentSequence(parentData, 'parent')
    }
  }, [updateParentSeq])

  const moveChildRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = childData[dragIndex];
      setChildData(
        update(childData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
      setUpdateChildSeq(true)
    },
    [childData],
  );

  const moveChildExtensionRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = childDataExtension[dragIndex];
      setChildDataExtension(
        update(childDataExtension, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
      setUpdateChildSeq(true)
    },
    [childDataExtension],
  );

  useEffect(() => {
    if (updateChildSeq) {
      if (childData[0]?.id) {
        setUpdateChildSeq(false)
        updatePaymentSequence(childData, 'child')
      }
      else {
        setUpdateChildSeq(false)
        updatePaymentSequence(childDataExtension, 'upi extension')
      }
    }
  }, [updateChildSeq])

  function showModal(e, data, type, id) {
    setRowData(data)
    e.stopPropagation();
    type === 'Edit UPI Extension' ? modalRef2.current.showModal(type, data, id) :
      modalRef.current.showModal(type, data)
  }

  function modalSubmit(values, id, type) {
    if (type === 'UPI Extension') {
      const upis = [];
      childDataExtension.forEach(function (el, idx) {
        el.id = idx
      })
      childDataExtension[childDataExtension.findIndex(x => x.id === id)] = values
      childDataExtension.forEach(function (v) { delete v.id });
      upis.push(childDataExtension)

      const payload2 = {
        name: "upi_extension",
        properties: upis[0],
      }
      payments_service.update_upi_extension(payload2).subscribe((r) => {
        setChildDataExtension([...r.response.data.properties])
        openNotificationWithIcon('success', 'Edited Successfully')
        modalRef2.current.handleCancel()
        // getExtensionData()
      },
        (err) => {
          console.log(err)
          modalRef2.current.stopLoading()
          openNotificationWithIcon("error", err?.response?.errors?.[0] ?? 'API Error');
        })
    }
    else {
      const payload = {
        id: id,
        body: values
      }
      if (id && rowData && !rowData.image && !("image_attributes" in payload.body)) {
        openNotificationWithIcon("error", "Please upload an image")
        modalRef.current.stopLoading()
        return
      }

      if (!id && !("image_attributes" in payload.body)) {
        openNotificationWithIcon("error", "Please upload an image")
        modalRef.current.stopLoading()
        return
      }

      if (id) {
        delete payload.body.parent_id
        payments_service.update_payments(payload).subscribe((r) => {
          openNotificationWithIcon('success', 'Edited Successfully')
          if (r.response.data.parent_id) {
            childData[childData.findIndex(x => x.id === r.response.data.id)] = r.response.data
            setChildData([...childData])
          } else {
            parentData[parentData.findIndex(x => x.id === r.response.data.id)] = r.response.data
            setParentData([...parentData])
          }
          modalRef.current.handleCancel()
          // modalRef.current.setPayTrue()
        },
          (err) => {
            console.log(err)
            modalRef.current.stopLoading()
            openNotificationWithIcon('error', err?.response?.errors?.[0] ?? 'API Error')
          })
      } else {
        payments_service.create_payments(payload).subscribe((r) => {
          openNotificationWithIcon('success', 'Created Successfully')
          if (payload.body?.parent_id) {
            let data = {
              payment_mode_id: payload.body.parent_id
            }
            getPayments(data)
          } else {
            getPayments()
          }
          modalRef.current.handleCancel()
          modalRef.current.setPayTrue()
        },
          (err) => {
            console.log(err)
            modalRef.current.stopLoading()
            openNotificationWithIcon('error', err?.response?.errors?.[0] ?? 'API Error')
          })
      }
    }
  }

  return (
    <div className="layer-nofilter">
      {parentData && localStorage.getItem('city') ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={(e) => showModal(e, true, 'Create Payment Methods')} className="frz-w-200 frz-m-10"
                  type="primary">Create Payment Methods</Button>
              </Col>
            </Card>
          </Row>
          {parentData.length > 0 ?
            <Row>
              <Col span={10}>
                <Card>
                  <ParentPayments parentData={parentData} onChange={onChangeStatus}
                    moveRow={moveParentRow} components={components}
                    getPayments={getPayments} activeParent={activeParent}
                    showModal={showModal} />
                </Card>
              </Col>
              <Col span={14}>
                <Card>
                  <ChildPayments childData={childData} onChange={onChangeStatus} getExtensionData={getExtensionData}
                    moveRow={moveChildRow} components={components} getPayments={getPayments}
                    activeParent={activeParent} setChildData={setChildData} components_extension={components_extension}
                    childDataExtension={childDataExtension} setChildDataExtension={setChildDataExtension}
                    showModal={showModal} moveRow2={moveChildExtensionRow} />
                </Card>
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available</div>)
          }
          <PaymentsModal rowData={rowData} modalSubmit={modalSubmit} ref={modalRef} parentData={parentData} activeParent={activeParent} paymentsType={paymentsType}
            getPayments={getPayments} />
          <UPIExtensionModal modalSubmit={modalSubmit} ref={modalRef2} />
        </div>
        :
        (localStorage.getItem('city') ?
          <div className="spin-center">
            <Spin tip="...Loading" />
          </div>
          :
          <div className="no-data">Please Select A City</div>
        )
      }
    </div>
  )
}

export default Payments