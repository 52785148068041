import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Radio, TimePicker } from 'antd';
import FRZSelect from '../../../_controls/FRZSelect';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import FRZCustomRadioButon from '../../../_controls/FRZCustomRadioButon';
import rupee from '../../../static/images/rupee.svg';
import moment from 'moment';

const hours = [1, 2, 3, 4, 5, 6, 7]
const logicData = [{ value: "AND" }, { value: "OR" }]

function ImsA(props) {
  const [period] = useState([{ period: 'DAILY', name: 'Daily', id: 1 },
  { period: 'WEEKLY', name: 'Weekly', id: 2 }, { period: 'MONTHLY', name: 'Monthly', id: 3 }])
  const [currentPeriod, setcurrentPeriod] = useState(false)

  const onFormChange = () => {
    setcurrentPeriod(props.form.getFieldValue()['A']?.period === 'DAILY')
    props.handleOnFormChange(true)
  }

  useEffect(() => {
    setcurrentPeriod(props.form.getFieldValue()['A']?.period === 'DAILY')
  }, [])

  return (
    <div className='frz-ims-box'>
      <Row>
        <Col span={12}>
          <div className='frz-ims-text'>
            Base Pay (A)
          </div>
        </Col>
        <Col span={12}>
          <Form
            form={props.form}
            layout="horizontal"
            name="form"
            onChange={() => onFormChange()}>
            <div className='frz-dis-flex frz-justify-end'>
              <FRZSelect
                optionDisabledKey="id"
                isDisabledVal={period.filter((x) => x.id > props.billingValue).map(a => a.id)}
                DropDownData={period} name={['A', 'period']} isRequired={true}
                label="" placeholder="Period" option="name" isShowSearch={true}
                width={150} value="period" setOnChange={onFormChange} />
              <div className='frz-lp-10'></div>
              <div className='frz-p-relative frz-ims-rs-custom frz-ims-placeholder'>
                <div className='frz-rupee-ims'><img src={rupee} /> </div>
                <FRZInputNumber minValue={0} name={['A', 'rate']} isRequired={true}
                  label="" placeholder="rate" width={150} errorMsg="Please enter rate" />
              </div>
            </div>
            <div className='frz-dis-flex frz-justify-end frz-ims-placeholder'>
              <FRZInputNumber minValue={0} name={['A', 'min_order_count']} isRequired={true}
                label="Min. Orders" placeholder="00" width={150} />
            </div>
            <div className='frz-dis-flex frz-justify-end'>
              <Form.Item label="" name={['A', 'min_order_logic']} rules={[{ required: true, message: 'Please select logic' }]}>
                <Radio.Group>
                  <Radio.Button value="AND">AND</Radio.Button>
                  <Radio.Button value="OR">OR</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className='frz-dis-flex frz-justify-end'>
              <FRZSelect DropDownData={hours} name={['A', 'min_hour_available']} isRequired={true}
                label="Min. Hours Available" placeholder="Select Hours"
                width={150} setOnChange={onFormChange} />
            </div>
            {currentPeriod ?
              <>
                <div className='frz-dis-flex frz-justify-end'>
                  <Form.Item label="" name={['A', 'first_time_login_logic']} rules={[{ required: true, message: 'Please select logic' }]}>
                    <Radio.Group>
                      <Radio.Button value="AND">AND</Radio.Button>
                      <Radio.Button value="OR">OR</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className='frz-dis-flex frz-justify-end'>
                  <Form.Item label="" name={['A', 'login_time']} rules={[{ required: true, message: 'Please select logic' }]}>
                    <TimePicker style={{ width: 200 }} use24Hours format={"HH:mm"} onChange={(e, timestring, f) => {
                      console.log('Time', e, timestring, f)
                    }} />
                  </Form.Item>
                </div>
              </>
              : null}
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default ImsA