import { Http } from '../_modules/http'

export class ProductTagService {

  http = new Http()

  get_product_tags(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/products/product_tags?'   + "page=" + `${payload.page}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_product_tag_type(){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/products/product_tag_types'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_line_items(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/products/product_tag_mappings?by_product_tag=' + payload.id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  update_tags(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/products/' + payload.id + '/product_tag'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload.body, headers)
  }

  create_tags(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/products/product_tag'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload.body, headers)
  }

  update_product_tag_mapping(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/products/' + payload.id + '/product_tag_mapping'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload.body, headers)
  }

  add_products(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/products/product_tag_mapping'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload, headers)
  }
}
