import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { Modal, Form, Select, Radio, Row, Col, Spin, Button } from 'antd';
import { formItemLayout, tailLayout } from '../../../_modules/controllayout';
import FRZInput from '../../../_controls/FRZInput';
import DebounceApp from '../../../_controls/FRZDebounceSelect';
import FRZRadioButton from '../../../_controls/FRZRadioButton';
import FRZSelect from '../../../_controls/FRZSelect';
import ProductControl from '../common/productcontrol';
import FRZColorPickerTemp from '../../../_controls/FRZColorPickerTemp';
import FRZUpload from '../../../_controls/FRZUpload';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { LandingClusterService } from '../../../_services/sdui/landing_cluster'
import { openNotificationWithIcon } from '../../../_modules/notification';
import { CopyOutlined } from '@ant-design/icons';

const landing_cluster_service = new LandingClusterService()

const { Option } = Select;

const initialModal = {
  data: false,
  title: false,
  loading: false,
  visible: false
}

const CreateLanding = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const [modalState, setModalState] = useState(initialModal)
  const [headerColor, setHeaderColor] = useState('#000')
  const [subHeaderColor, setSubHeaderColor] = useState('#000')
  const [itemsChanged, setItemsChanged] = useState(false)
  const [imageState, setImageState] = useState({})
  const [isBGTemplate, setIsBGTemplate] = useState(false)
  const [spin, setSpin] = useState(null)
  const [items, setItems] = useState(false)
  const [clusterData, setClusterData] = useState(false)
  // const [titleTxt, setTitleTxt] = useState(false)
  const [updateId, setUpdateId] = useState(false)
  const [globalSpin, setGlobalSpin] = useState(false)
  const [clusterId, setClusterId] = useState(false)
  const [dataSourceType, setDataSourceType] = useState(false)
  const [staticData, setStaticData] = useState(false)
  const [shortLinkData, setShortLinkData] = useState(false)

  useEffect(() => {
    getStaticData()
  }, [])

  const getStaticData = () => {
    landing_cluster_service.getLandingStaticData().subscribe((r) => {
      setStaticData(r.response.data)
    }, ((err) => {
      console.error(err)
    }))
  }

  const getitemsById = (value) => {
    setSpin(true)
    setItemsChanged(false)
    landing_cluster_service.getProductList(value).subscribe((r) => {
      setSpin(false)
      setClusterData(r.response.data)
      setItems(r.response.data.cluster_details)
    }, ((err) => {
      setSpin(false)
      console.error({ err })
    }))
  }

  const showLadingPageData = (id) => {
    setGlobalSpin(true)
    landing_cluster_service.showLandingPageData(id).subscribe((r) => {
      setDataSourceType(r.response.data?.type_of_data_source)
      setImageState({ image: r?.response?.data?.image?.image_url })
      form.setFieldsValue({
        data_source: r.response.data?.data_source,
        type_of_data_source: r.response.data?.type_of_data_source,
        title: r.response.data.title,
        layout: r.response.data.template === "row_boxes_with_banner" ? true : false,
        headertxt: r.response.data.header_text,
        subheadertxt: r.response.data.sub_header_text,
        cluster: r.response.data.cluster_promotional_pages[0]?.cluster_id,
        productlist: [{ value: r.response.data.cluster_promotional_pages[0]?.cluster_id, label: r.response.data.cluster_promotional_pages[0]?.cluster_name }],
      })
      setUpdateId(r.response.data.id)
      setClusterData(r.response.data.cluster_promotional_pages[0])
      // setTitleTxt(r.response.data.title)
      setClusterId(r.response.data.cluster_promotional_pages[0]?.cluster_id)
      setItems(r.response.data.cluster_promotional_pages[0]?.cluster_details)
      setIsBGTemplate(r.response.data.template === "row_boxes_with_banner" ? true : false)
      setHeaderColor(r.response.data.background_color)
      setSubHeaderColor(r.response.data.font_color)
      setShortLinkData(r.response.data.short_link_data)
      setGlobalSpin(false)
    }, ((err) => {
      setGlobalSpin(false)
      console.error("err", err)
    }))
  }


  useImperativeHandle(ref, () => ({
    handleCancel: handleCancelModal,
    showModal(data, title) {
      if (typeof data !== "boolean") {
        showLadingPageData(data.id)
      }
      setHeaderColor("#000")
      setSubHeaderColor("#000")
      setUpdateId(false)
      setIsBGTemplate(false)
      // console.log("data", typeof data data)
      // form.setFieldsValue({ title: "hello" })
      setModalState(prevState => ({ ...prevState, data: data, title: title, visible: true }))
    },
    stopLoading() {
      setModalState(prevState => ({ ...prevState, loading: false }))
    }
  }));
  function handleCancelModal() {
    form.resetFields()
    setSpin(null)
    setClusterData(false)
    setUpdateId(false)
    setImageState({})
    setItems(false)
    setItemsChanged(false)
    setModalState(initialModal)
    setShortLinkData(false)
  }

  const handleOnSubmit = (values) => {
    setGlobalSpin(true)
    let temp = []
    if (items) {
      items.map((itm) => {
        temp.push(Object.assign({}, { cluster_id: itm.cluster_id }))
      })
    }
    const payload = {
      promotional_page: {
        type_of_data_source: values.type_of_data_source,
        data_source: !items ? values.data_source : null,
        title: values.title,
        header_text: values.headertxt,
        background_color: headerColor,
        sub_header_text: values.subheadertxt,
        font_color: subHeaderColor,
        template: !values.layout ? "row_boxes" : "row_boxes_with_banner",
        cluster_promotional_pages_attributes: items ? [
          // ...temp
          temp[0]
        ] : [],
        image_attributes: { image: imageState['image'] }
      }
    }
    // console.log("payload", payload)
    if (!updateId && !imageState['image'] && isBGTemplate) {
      openNotificationWithIcon("error", "Please update background image")
      setGlobalSpin(false)
      return
    }
    if (updateId) {
      payload.id = updateId;
      landing_cluster_service.updateLandingPageData(payload, updateId).subscribe((r) => {
        setGlobalSpin(false)
        handleCancelModal()
        openNotificationWithIcon("success", "Updated succesfully")
      }, ((err) => {
        setGlobalSpin(false)
        openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
        console.error("err", err)
      }))
    } else {
      landing_cluster_service.createLanding(payload).subscribe((r) => {
        setGlobalSpin(false)
        handleCancelModal()
        openNotificationWithIcon("success", "Created succesfully")
      }, ((err) => {
        openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
        setGlobalSpin(false)
        console.error("err", err)
      }))
    }
    // setLoading(true)
    // modalSubmit(values, data?.id)
  }

  function changeHeaderColor(colors, type) {
    if (type === "header") {
      setHeaderColor(colors)
    } else {
      setSubHeaderColor(colors)
    }
  }

  function handleClick({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  const uploadButton = (
    <div>
      {props.imgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleOnChangeImage = (type, item) => {
    setImageState(prevState => ({ ...prevState, [type]: item }))
  }

  const onRadioChange = (e) => {
    setImageState({})
    setIsBGTemplate(e.target.value)
  }

  function copyToClipboard(text){
    navigator.clipboard.writeText(text)
    openNotificationWithIcon('success', 'Text Copied To Clipboard')
  }

  return (
    <div>
      {modalState.data ?
        <Modal
          visible={modalState.visible}
          title={modalState.title}
          okText={updateId ? "Update" : "Submit"}
          width={'70%'}
          getContainer={false}
          okButtonProps={{ loading: modalState.loading, disabled: (itemsChanged || globalSpin) }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Spin tip="Loading..." spinning={globalSpin}>
            <Form
              form={form}
              {...formItemLayout}
              layout="horizontal"
              name="form">

              <FRZInput name="title" isRequired={true} label="Promotion Name" width={200} />

              <Row>
                <Col span={7} className="frz-col-label">
                  Background color:
                </Col>
                <Col>
                  <FRZColorPickerTemp
                    noStyle={true}
                    value={headerColor}
                    pickerColor={headerColor}
                    changePickerColor={(e) => changeHeaderColor(e, "header")}
                    setPickerColor={setHeaderColor}
                    columnSpan={7}
                    isRequired={true}
                  />
                </Col>
              </Row>

              <Row>
                <Col span={7} className="frz-col-label">
                  Font color:
                </Col>
                <Col>
                  <FRZColorPickerTemp
                    noStyle={true}
                    value={subHeaderColor}
                    pickerColor={subHeaderColor}
                    changePickerColor={(e) => changeHeaderColor(e, "subheader")}
                    setPickerColor={setSubHeaderColor}
                    columnSpan={7}
                    isRequired={true}
                  />
                </Col>
              </Row>

              <Form.Item
                {...tailLayout}
                name={"layout"}
                label={"Layout"}
                rules={true ? [
                  {
                    required: true,
                    message: `Please input the value of layout!`,
                  },
                ] : []}>
                <Radio.Group onChange={onRadioChange}>
                  <Radio value={true}>With BG template</Radio>
                  <Radio value={false}>Without BG templage</Radio>
                </Radio.Group>
              </Form.Item>

              {isBGTemplate ?
                <FRZUpload name="image" className="avatar-uploader" customRequest={handleClick}
                  imgWidth={"100%"} imgHeight={"100%"} label={"Background Image"} listType="picture-card" setOnChangeType={"image"}
                  showUploadList={false} uploadButton={uploadButton} handleOnChangeImage={handleOnChangeImage}
                  imageState={imageState} />
                : null}

              {/* <FRZInput name="headertxt" isRequired={true} label="Header Text" width={200}
                height={32} /> */}

              {/* <Row>
                <Col span={7} className="frz-col-label">
                  Header color:
                </Col>
                <Col>
                  <FRZColorPickerTemp
                    noStyle={true}
                    value={headerColor}
                    pickerColor={headerColor}
                    changePickerColor={(e) => changeHeaderColor(e, "header")}
                    setPickerColor={setHeaderColor}
                    columnSpan={7}
                    isRequired={true}
                  />
                </Col>
              </Row> */}

              {/* <FRZInput name="subheadertxt" isRequired={true} label="Sub Header Text" width={200} /> */}
              {/* <Row>
                <Col span={7} className="frz-col-label">
                  Sub Header color:
                </Col>
                <Col>
                  <FRZColorPickerTemp
                    noStyle={true}
                    value={subHeaderColor}
                    pickerColor={subHeaderColor}
                    changePickerColor={(e) => changeHeaderColor(e, "subheader")}
                    setPickerColor={setSubHeaderColor}
                    columnSpan={7}
                    isRequired={true}
                  />
                </Col>
              </Row> */}


              <ProductControl form={form}
                setItemsChanged={(value) => setItemsChanged(value)} itemsChanged={itemsChanged}
                clusterData={clusterData} items={items}
                setItems={(value) => setItems(value)} spin={spin} setSpin={(value) => setSpin(value)}
                getitemsById={(value) => getitemsById(value)} dataSourceType={dataSourceType}
                clusterId={clusterId} staticData={staticData} setDataSourceType={(value) => setDataSourceType(value)}
              // titleTxt={titleTxt} 
              />

            {shortLinkData ? 
              <div className='frz-search-layer'>
                <h3 className='frz-tp-20'>
                  Use below keys and values on AppsFlyer custom params for Onelink, copy paste it as it is.
                </h3>  
                {Object.keys(shortLinkData).map(function(key, index) {
                  return(
                    <div className='frz-tp-10' key={index}>
                      <Button type="primary" ghost icon={<CopyOutlined />} 
                              onClick={() => copyToClipboard(key)} 
                              size="small" className='frz-rm-10'></Button>
                      {key} &nbsp; : &nbsp; <b>{shortLinkData[key]}</b> 
                      <Button type="primary" ghost icon={<CopyOutlined />} 
                              onClick={() => copyToClipboard(shortLinkData[key])} 
                              size="small" className='frz-lm-10'></Button>
                    </div>
                  )
                })}
              </div>
            : null}

            </Form>
          </Spin>
        </Modal>
        : null}
    </div>

  )
})

export default CreateLanding
