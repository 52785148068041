import React from 'react'
import { Spin, Modal, Button, Row, Col, Tabs, notification, 
    Select, Input, InputNumber, Radio} from 'antd';
import { EditOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import LocationSearch from './location_search';

function CustomerAddress(props){

    function truncateString (str, num) {
        if (str.length <= num) {
          return str
        }
        return str.slice(0, num) + '...'
    }

    return(
        <div style={{marginTop: 30}}>
            <div className="order-header" style={props.modalAddress ? !props.modalSpinner ? {paddingBottom: 0} : {paddingBottom: 10} : {paddingBottom: 10}}>
                <b>#Customer Address</b>
                {props.modalSpinner ? 
                        <Spin style={{float: 'right'}}/>
                    :
                    <>
                        {props.modalAddress ? 
                            <>
                                <Button type="link"
                                        onClick={() => props.createNew()}>(Create New)</Button>
                                <Button type="primary" ghost icon={<EditOutlined />} 
                                        size="small" style={{float: 'right', paddingTop: 1}}
                                        shape="round"
                                        onClick={() => props.editOrder('address')}/>
                            </>        
                        :
                        <Button type="primary" ghost shape="round" icon={<CheckOutlined />} 
                                size="small" style={{float: 'right', marginLeft: 16, paddingTop: 1}}
                                onClick={() => props.saveOrder('address')}/>
                        }
                    </>
                }
            </div>
            <div className="order-detail-padding">
                <LocationSearch searchAddress={props.searchAddress}
                                addressLock={props.modalAddress}
                                isServiceable={props.isServiceable}
                                tempAddressSearch={props.tempAddressSearch}
                                showEdit={false}/>
            </div>
            {props.modalAddressDetail ? 
                <div className="order-detail-padding">Address : 
                    <span style={{paddingLeft:10}}>
                        <b>{props.modalAddressDetail}</b>
                        {!props.isServiceable ? 
                        <div style={{color: 'red', paddingTop: 4}}>
                            (Location Not Serviceable)
                        </div>
                        :
                        <div style={{color: 'green', paddingTop: 4, paddingBottom: 10}}>
                            (Location Serviceable)
                        </div>}
                    </span>
                </div>
            : null}
            {props.isServiceable ?
                <div> 
                    <div className="order-detail-padding" style={{marginLeft: 17}}>Flat No : 
                        <span style={{paddingLeft:10}}>
                            <Input
                                placeholder="Enter Flat No"
                                value={props.modalFlat}
                                style={{width:190}}
                                disabled={props.modalAddress}
                                onChange={(e) => props.onChangeModal(e.target.value, 'modalFlat')}/>
                        </span>
                    </div>
                    <div className="order-detail-padding">Landmark : 
                        <span style={{paddingLeft:10}}>
                            <Input
                                placeholder="Enter Landmark"
                                value={props.modalLandmark}
                                style={{width:190}}
                                disabled={props.modalAddress}
                                onChange={(e) => props.onChangeModal(e.target.value, 'modalLandmark')}/>
                        </span>
                    </div>
                    <div className="order-detail-padding" style={{marginLeft: 11}}>Pincode : 
                        <span style={{paddingLeft:10}}>
                            <InputNumber
                                placeholder="Enter Pincode"
                                value={props.modalPincode}
                                style={{width:190}}
                                disabled={props.modalAddress}
                                onChange={(e) => props.onChangeModal(e, 'modalPincode')}/>
                        </span>
                    </div>
                    <div className="order-detail-padding">Address Type : 
                        <span style={{paddingLeft:10}}>
                            {/* <Input
                                placeholder="Home, Work etc"
                                value={props.modalAddressType}
                                style={{width:170}}
                                disabled={props.modalAddress}
                                onChange={(e) => props.onChangeModal(e.target.value, 'modalAddressType')}/> */}
                            <Radio.Group value={props.modalAddressType} disabled={props.modalAddress}
                                         onChange={(e) => props.onChangeModal(e.target.value, 'modalAddressType')}>
                                    <Radio value='home'>Home</Radio>
                                    <Radio value='work'>Work</Radio>
                                    {props.modalAddressType? props.modalAddressType.toLowerCase() !== 'home' && props.modalAddressType.toLowerCase() !== 'work' ?
                                        <Radio value={props.modalAddressType}>{props.modalAddressType}</Radio>
                                        : null : null
                                    }
                            </Radio.Group>
                        </span>
                    </div>
                </div>
            : null}
            {props.addressList.length > 0 ?
                <div style={{marginTop: 30}}>
                    <div className="order-header">
                        <b>#Saved Addresses</b>
                    </div>
                    <div className="order-detail-padding addressRadio">
                        <span>
                            <Radio.Group value={props.valueByRadioAddress}
                                         onChange={(e) => props.onChangeModalAddress(e.target.value, 'valueByRadioAddress')}>
                                {props.addressList.map(data => {
                                    return(data.address_type !== 'internal_temp' ? 
                                        <Radio value={data.id} key={data.id} style={{width: '100%'}}>
                                            <div className="address-radio">
                                                <div><b>{data.address_type.charAt(0).toUpperCase() + data.address_type.slice(1)}</b></div>
                                                {data.landmark ? 
                                                    <div>{truncateString(data.landmark, 40)}</div>
                                                : null}
                                            </div>
                                        </Radio> : null
                                    )
                                })}
                            </Radio.Group>
                        </span>
                    </div>
                </div>
            : null
            }
        </div>
    )
}

export default CustomerAddress