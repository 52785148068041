import React from 'react'
import { Timeline } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

function DeliveryStatus(props) {
  return(
    <div style={{paddingTop: 20}}>
      <Timeline mode="alternate">
        {props.orderDetail.map(data => {
          return(
          <Timeline.Item key={data.id}>
            <div>{data.order_status}</div>
            <div>{data.created_at}</div>
            {data.updated_by && <div>{data.updated_by}</div>}
          </Timeline.Item>
          )
        })}
      </Timeline>
    </div>
  )
}

export default DeliveryStatus
