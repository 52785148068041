import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form, Input, InputNumber, Row, Col } from 'antd';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import FRZTimePicker from '../../../_controls/FRZTimePicker';
import FRZInput from '../../../_controls/FRZInput';
import moment from "moment"; 

const UpdateSTOModal = forwardRef((props, ref) => {
  const { modalUpdate } = props

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState(false)
  const [data, setData] = useState(false)


  useEffect(() => {
    if ((data && (title == "Update Inward" || title == "Update STO"))) {
      form.setFieldsValue({ formData: data.line_items, driver_mobile_number: data.driver_mobile_number, 
                            expected_arrival_time: data.expected_arrival_time ? moment(data.expected_arrival_time, "HH:mm") : undefined })
    }
  }, [data])

  useImperativeHandle(ref, () => ({

    showUpdateModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setData(false)
      form.resetFields()
      setVisible(false)
      setLoading(false)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleCancelModal() {
    setData(false)
    form.resetFields()
    setVisible(false)
    setLoading(false)
  }

  const handleOnSubmit = (values) => {
    let sto_id
    let payloadData = values.formData.map((e) => {
      sto_id = e.sto_id
      return ({ id: e.id, qty: e.transfer_unit_qty, rejected_qty: e.rejected_qty ?? 0 })
    })
    setLoading(true)
    const payload = {
      sto_id: sto_id,
      items: payloadData,
    }
    if(title === 'Update STO'){
      payload.driver_mobile_number = values?.driver_mobile_number ?? ''
      payload.expected_arrival_time = values.expected_arrival_time ? moment(values.expected_arrival_time).format("HH:mm") : ''
      payload.vehicle_number = values?.vehicle_number ?? ''
    }
    modalUpdate(payload, title)
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'60%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="formData">

            {title === 'Update STO' ? 
            <>
              <FRZInputNumber isRequired={false} label="Mobile No" name="driver_mobile_number" width={200} />
              <FRZTimePicker isRequired={false} label="Extended Arrival Time" name="expected_arrival_time"/>
              <FRZInput name="vehicle_number" label="Vehicle No" isRequired={false} placeholder="Vehicle No" width={200}/>
            </> : null}

            <Row>
              <Col span={8}>
                <div>
                  SKU
                </div>
              </Col>

              <Col span={4}>
                <div>
                  Unit
                </div>
              </Col>

              <Col span={4}>
                <div>
                  Transfer Converted Qty
              </div>
              </Col>

              <Col span={4}>
                <div>
                  QTY
                </div>
              </Col>

              {title !== 'Update STO' ? 
                <Col span={4}>
                  <div>
                    Rejected QTY
                  </div>
                </Col>
              : null}
            </Row>

            <Row>
              <Col span={24}>
                <div className="frz-division"></div>
              </Col>
            </Row>
            <Form.List name="formData">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <div key={key} align="baseline">
                      <Row gutter={[0,0]}>
                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            name={[name, 'name']}
                            fieldKey={[fieldKey, 'name']}
                            // label="SKU"
                            rules={[{ required: true, message: 'Missing first name' }]}>
                            <Input placeholder="First Name" disabled={true} />
                          </Form.Item>
                        </Col>

                        <Col span={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'transfer_unit_type']}
                            fieldKey={[fieldKey, 'transfer_unit_type']}
                            // label="Unit"
                            rules={[{ required: false, message: 'Missing Unit type' }]}>
                            <Input placeholder="Unit" disabled={true} />
                          </Form.Item>
                        </Col>

                        <Col span={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'transfer_qty']}
                            fieldKey={[fieldKey, 'transfer_qty']}
                            // label="Transfer Unit Qty"
                            rules={[{ required: false, message: 'Missing Transfer Qty' }]}>
                            <Input disabled={true} />
                          </Form.Item>
                        </Col>

                        <Col span={4}>
                          <Form.Item
                            {...restField}
                            name={[name, 'transfer_unit_qty']}
                            // label="Qty"
                            fieldKey={[fieldKey, 'transfer_unit_qty']}
                            rules={[{ required: true, message: 'Missing Qty' }]}>
                            <InputNumber placeholder="Qty" />
                          </Form.Item>
                        </Col>
                        {title !== 'Update STO' ? 
                          <Col span={4}>
                            <Form.Item
                              {...restField}
                              name={[name, 'rejected_qty']}
                              // label="Qty"
                              fieldKey={[fieldKey, 'rejected_qty']}
                              rules={[{ required: false }]}>
                              <InputNumber placeholder="Rejected Qty"/>
                            </Form.Item>
                          </Col>
                        : null}
                      </Row>
                    </div>
                  ))}
                </>
              )}
            </Form.List>

            {/* <FRZInputNumber name="qty" isRequired={true}
              label="Qty" placeholder="Qty" width={200} /> */}

          </Form>
        </Modal>
        :
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})

export default UpdateSTOModal
