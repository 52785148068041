import { Http } from '../_modules/http'

export class CategoryService {

  http = new Http()

  get_category() {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/products/all_product_categories?by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  update_category(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/products/product_categories/' + payload.id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload.body, headers)
  }

  create_category(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/products/product_categories'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload.body, headers)
  }

  get_category_sequence() {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/products/product_category_sequence?by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  update_category_sequence(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/products/product_category_sequence_update?by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  update_subcategory_sequence(payload) {
    const url = process.env.REACT_APP_API_URL +
      '/admin_dashboard/v1/products/product_sub_category_sequence_update?by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  getCategoryActivation(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/products/get_darkstore_wise_activation_category?product_category_id=' + payload.id + '&city_id=' + payload.city;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  updateCateogryActivation(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/products/darkstore_wise_activation_category';
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }
  // admin_dashboard/v1/marketing/clusters/1123/get_darkstore_wise_activation
  getClusterActivation(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/clusters/' + payload.id + '/get_darkstore_wise_activation?city_id=' + payload.city;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  updateClusterActivation(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/clusters/' + payload.clusterId + "/darkstore_wise_activation";
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }
}
