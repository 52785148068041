import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Radio, Select } from 'antd';
import FRZRadioButton from '../../_controls/FRZRadioButton';
import FRZInputNumber from '../../_controls/FRZInputNumber';
import FRZInput from '../../_controls/FRZInput';
import FRZSelect from '../../_controls/FRZSelect';
import { formItemLayout } from '../../_modules/controllayout';

const { Option } = Select;

const ModalMarkDeliver = ({ handleMarkDeliver, markDeliverModal, deliveredOrder, loading, deliverNow, partners }) => {
  const [form] = Form.useForm();

  const onCreate = (value) => {
    let tempValue = { ...value }
    if (deliverNow) {
      tempValue.id = deliverNow
    }
    deliveredOrder(tempValue)
  }

  const onCancel = () => {
    handleMarkDeliver(false)
  }

  return (
    <Modal
      visible={true}
      title="Mark Delivered"
      okText="Submit"
      cancelText="Cancel"
      width={'45%'}
      okButtonProps={{ loading: loading }}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="horizontal"
        name="form"
        {...formItemLayout}
      >
        {!markDeliverModal.runner_details && !deliverNow ?
          <>
            <FRZInput name="name" label="Rider Name" isRequired={true} placeholder="Rider Name" width={200} />
            <FRZInputNumber name="phone_number" label="Phone Number" isRequired={true} 
                            placeholder="Phone No" width={200} />
            <FRZSelect DropDownData={partners} name="delivery_partner_type" isRequired={true}
                       label="Select Partner" placeholder="Partner Type" isShowSearch={true} width={200} 
                       isDataKey={true}/>
          </>
        : null}
        {markDeliverModal.mode_of_payment === 'cod' && !deliverNow ? 
          <FRZInputNumber name="cod_collected_amount" label="COD Collected Amount" isRequired={true} 
                          placeholder="Cod Amount" width={200} />
        : null}
        <FRZRadioButton name="rider_payment" label="Rider Payment" isRequired={true} />
      </Form>
    </Modal>
  )
}

export default ModalMarkDeliver
