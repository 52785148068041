export const checklistColumns = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    align: "center",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    align: "center",
    width: 200
  },
  {
    title: "Unique Code",
    dataIndex: "unique_code",
    key: "unique_code",
    align: "center",
  },
  {
    title: "Is Active",
    dataIndex: "is_active",
    key: "is_active",
    align: "center",
    render: (record) => {
      return record ? "Yes" : "No"
    }
  }
]

export const darkstoreColumns = [
  {
    title: "Darkstore Name",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    align: "center",
    width: 200
  },
  {
    title: "Mobile No",
    dataIndex: "mobile_number",
    key: "mobile_number",
    align: "center",
  },
  {
    title: "Is Serviceable",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_serviceable.toString()
    }
  },
  {
    title: "Avg Rating",
    dataIndex: "avg_rating",
    key: "avg_rating",
    align: "center",
  },
]

export const refundStatsColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "Order Count",
    dataIndex: "order_count",
    key: "order_count",
    align: "center",
    sorter: (a, b) => a.order_count - b.order_count,
  },
  {
    title: "Quality Issue Count",
    dataIndex: "quality_issue_count",
    key: "quality_issue_count",
    align: "center",
    sorter: (a, b) => a.quality_issue_count - b.quality_issue_count,
  },
  {
    title: "Stock Out Issue Count",
    dataIndex: "stock_out_issue_count",
    key: "stock_out_issue_count",
    align: "center",
    sorter: (a, b) => a.stock_out_issue_count - b.stock_out_issue_count,
  },
  {
    title: "Missing Issue Count",
    dataIndex: "missing_issue_count",
    key: "missing_issue_count",
    align: "center",
    sorter: (a, b) => a.missing_issue_count - b.missing_issue_count,
  },
  {
    title: "Quality Sales Loss",
    dataIndex: "quality_sale_loss",
    key: "quality_sale_loss",
    align: "center",
    sorter: (a, b) => a.quality_sale_loss - b.quality_sale_loss,
  },
  {
    title: "Stock Out Sales Loss",
    dataIndex: "stock_out_sale_loss",
    key: "stock_out_sale_loss",
    align: "center",
    sorter: (a, b) => a.stock_out_sale_loss - b.stock_out_sale_loss,
  },
  {
    title: "Missing Sales Loss",
    dataIndex: "missing_sale_loss",
    key: "missing_sale_loss",
    align: "center",
    sorter: (a, b) => a.missing_sale_loss - b.missing_sale_loss,
  },
  {
    title: "Refund Sales Loss Amount",
    dataIndex: "total_refund_sales_loss_amount",
    key: "total_refund_sales_loss_amount",
    align: "center",
    sorter: (a, b) => a.total_refund_sales_loss_amount - b.total_refund_sales_loss_amount,
  },
  {
    title: "Total Refund Amt",
    dataIndex: "total_refund_amount",
    key: "total_refund_amount",
    align: "center",
    sorter: (a, b) => a.total_refund_amount - b.total_refund_amount,
  },
]

export const locationColumns = [
  {
    title: "Area Name",
    dataIndex: "area_name",
    key: "area_name",
    align: "center",
  },
  {
    title: "Darkstore Name",
    dataIndex: "darkstore_name",
    key: "darkstore_name",
    align: "center",
  },
  {
    title: "Is Active",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_active.toString()
    }
  },
]

export const shiftsColumns = [
  {
    title: "Roles",
    dataIndex: "roles",
    key: "roles",
    align: "center",
  },
  {
    title: "Shift hrs",
    dataIndex: "shift_hrs",
    key: "shift_hrs",
    align: "center",
  },
  {
    title: "Start Time",
    dataIndex: "starting_time",
    key: "starting_time",
    align: "center",
  },
  {
    title: "End Time",
    dataIndex: "ending_time",
    key: "ending_time",
    align: "center",
  },
  {
    title: "Is Active",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_active.toString()
    }
  },
]

export const darkstoreSalesColumns = [
  {
    title: "Darkstore Name",
    dataIndex: "darkstore_name",
    key: "darkstore_name",
    align: "center",
  },
  {
    title: "Order Count",
    dataIndex: "darkstore_order_count",
    key: "darkstore_order_count",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.darkstore_order_count - b.darkstore_order_count,
  },
  {
    title: "Pending",
    dataIndex: "pending_count",
    key: "pending_count",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.pending_count - b.pending_count,
  },
  {
    title: "New",
    dataIndex: "active_count",
    key: "active_count",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.active_count - b.active_count,
  },
  {
    title: "Preparing",
    dataIndex: "preparing_count",
    key: "preparing_count",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.preparing_count - b.preparing_count,
  },
  {
    title: "Ready",
    dataIndex: "ready_count",
    key: "ready_count",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.ready_count - b.ready_count,
  },
  {
    title: "Dispatched & Delivered",
    dataIndex: "dispatched_and_delivered_count",
    key: "dispatched_and_delivered_count",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.dispatched_and_delivered_count - b.dispatched_and_delivered_count,
  },
  {
    title: "Avg Order Value",
    dataIndex: "avg_order_value",
    key: "avg_order_value",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.avg_order_value - b.avg_order_value,
  },
  {
    title: "Total Review",
    dataIndex: "total_review_count",
    key: "total_review_count",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.total_review_count - b.total_review_count,
  },
]

export const darkstoreDeliveryTimeColumns = [
  {
    title: "Darkstore Name",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "Order Count",
    dataIndex: "order_count",
    key: "order_count",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.order_count - b.order_count,
  },
  {
    title: "Delays",
    dataIndex: "delays",
    key: "delays",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.delays - b.delays,
  },
  {
    title: "Avg New Time",
    dataIndex: "avg_new_time",
    key: "avg_new_time",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.avg_new_time - b.avg_new_time,
  },
  {
    title: "Avg Packaging Time",
    dataIndex: "avg_packaging_time",
    key: "avg_packaging_time",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.avg_packaging_time - b.avg_packaging_time,
  },
  {
    title: "Avg Ready To Dispatch Time",
    dataIndex: "avg_ready_to_dispatch_time",
    key: "avg_ready_to_dispatch_time",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.avg_ready_to_dispatch_time - b.avg_ready_to_dispatch_time,
  },
  {
    title: "Avg Delivery Time",
    dataIndex: "avg_delivery_time",
    key: "avg_delivery_time",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.avg_delivery_time - b.avg_delivery_time,
  },
  {
    title: "On Time Percentage",
    dataIndex: "",
    key: "",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.on_time_percentage - b.on_time_percentage,
    render: (record) => {
      return record.on_time_percentage?.toFixed(0) + ' %' ?? '0' + ' %'
    }
  },
  {
    title: "Avg Delay By",
    dataIndex: "avg_delay_by",
    key: "avg_delay_by",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.avg_delay_by - b.avg_delay_by,
  },
]

export const darkstoreQualityColumns = [
  {
    title: "Darkstore Name",
    dataIndex: "darkstore_name",
    key: "darkstore_name",
    align: "center",
  },
  {
    title: "Order Count",
    dataIndex: "darkstore_order_count",
    key: "darkstore_order_count",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.darkstore_order_count - b.darkstore_order_count,
  },
  {
    title: "Complaint Order Count",
    dataIndex: "complaint_order_count",
    key: "complaint_order_count",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.complaint_order_count - b.complaint_order_count,
  },
  {
    title: "Frequency Per Order",
    dataIndex: "freequency_per_order",
    key: "freequency_per_order",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.freequency_per_order - b.freequency_per_order,
  },
  {
    title: "Complaint Percentage",
    dataIndex: "",
    key: "",
    align: "center",
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.complaint_percentage - b.complaint_percentage,
    render: (record) => {
      return record.complaint_percentage?.toFixed(0) + ' %' ?? '0' + ' %'
    }
  },
]
