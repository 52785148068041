import { Http } from '../_modules/http'

export class OrderService {

  http = new Http()

  get_orders(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/orders/dashboard_order_list?page=' + payload.page +
      '&by_order_number=' + payload.by_order_number + '&by_status=' + payload.by_status +
      '&by_mobile_number=' + payload.by_mobile_number +
      '&by_darkstore=' + payload.by_darkstore + '&by_order_type=' + payload.by_order_type +
      '&by_delivery_date=' + payload.by_delivery_date + '&is_replacement=' + payload.is_replacement +
      '&is_society=' + payload.is_society + '&payment_filter=' + payload.payment_filter +
      '&payment_filter_value=' + payload.payment_filter_value + '&by_payment_mode=' + payload.by_payment_mode

    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_undelivered_orders(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/orders/undelivered_order_list?page=' + payload.page +
      '&by_order_number=' + payload.by_order_number + '&by_status=' + payload.by_status +
      '&by_mobile_number=' + payload.by_mobile_number +
      '&by_darkstore=' + payload.by_darkstore + '&by_order_type=' + payload.by_order_type +
      '&by_delivery_date=' + payload.by_delivery_date + '&is_replacement=' + payload.is_replacement +
      '&is_society=' + payload.is_society
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_customers() {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/orders/customers'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_order_types() {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/orders/order_types'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_payment_mode() {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/orders/static_filter_options'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_order_status() {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/orders/order_status'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_payment_status(id) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/payments/payment_status?id=' + id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_order_detail(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/orders/' + payload.id + '/order_details'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  create_refund(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/refunds/unfulfilled_items_refund'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  create_reviews(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/orders/add_review'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  create_partial_refund(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/refunds/initiate_refund'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  get_refund_reasons() {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/refunds/refund_reasons'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_cancel_reasons() {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/orders/cancellation_reasons'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_review_reasons() {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/orders/get_review_detail_reasons'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_review_params() {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/orders/get_review_parameters'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_issue_params() {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/orders/get_issue_type_parameter_list'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  cancel_order(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/orders/' + payload.id + '/cancel_order'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload.body, headers)
  }

  mark_delivered_order(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/orders/' + payload.id + '/mark_order_delivered'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  create_refund_credit(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/refunds/addon_refund'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  get_line_items(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/orders/' + payload.id + '/get_line_items'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_replacements(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/delivery_replacement/delivery_replacements_list?page=' + payload.page +
      '&by_order_number=' + payload.by_order_number + '&by_status=' + payload.by_status +
      '&by_mobile_number=' + payload.by_mobile_number + '&by_delivery_date=' + payload.by_delivery_date +
      '&by_darkstore=' + payload.by_darkstore
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_delivery_slots(payload) {
    const url = process.env.REACT_APP_API_URL +
      `admin_dashboard/v1/refunds/replacement_order_delivery_slots?delivery_id=` + payload.delivery_id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  create_order_replacement(payload) {
    const url = process.env.REACT_APP_API_URL + `admin_dashboard/v1/refunds/create_order_replacement`
    console.log("url", url)
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  get_status() {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/delivery_replacement/delivery_replacement_status'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  update_status(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/delivery_replacement/' + payload.id + '/update_delivery_replacement'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload.body, headers)
  }

  get_offline_orders(payload) {
    const url = process.env.REACT_APP_API4_URL +
      'offline_orders_dashboard/v1/customers/dashboard_order_list?page=' + payload.page +
      '&by_order_number=' + payload.by_order_number + '&by_status=' + payload.by_status +
      '&by_mobile_number=' + payload.by_mobile_number +
      '&by_darkstore=' + payload.by_darkstore + '&by_order_type=' + payload.by_order_type +
      '&by_delivery_date=' + payload.by_delivery_date + '&by_customer_exec=' + payload.by_customer_exec
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  check_offline_customer(payload, value) {
    const url = process.env.REACT_APP_API4_URL +
      'offline_orders_dashboard/v1/customers/customer_check?mobile_number=' + payload + '&is_whatsapp=' + value
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  generate_otp(payload) {
    const url = process.env.REACT_APP_API4_URL + 'offline_orders_dashboard/v1/customers/generate_otp'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  validate_otp(payload) {
    const url = process.env.REACT_APP_API4_URL + 'offline_orders_dashboard/v1/customers/verify_otp?mobile_number='
      + payload.mobile_number + '&otp=' + payload.otp
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, {}, headers)
  }

  validate_address(payload) {
    const url = process.env.REACT_APP_API4_URL + 'offline_orders_dashboard/v1/customers/check_serviceable'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  update_profile(payload, id) {
    const url = process.env.REACT_APP_API4_URL + 'offline_orders_dashboard/v1/customers/' + id + '/update_profile'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  create_profile(payload) {
    const url = process.env.REACT_APP_API4_URL + 'offline_orders_dashboard/v1/customers/create_profile'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  create_address(payload) {
    const url = process.env.REACT_APP_API4_URL + 'offline_orders_dashboard/v1/customers/create_address'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  update_address(payload, id) {
    const url = process.env.REACT_APP_API4_URL + 'offline_orders_dashboard/v1/customers/' + id + '/update_address'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  customer_address(id) {
    const url = process.env.REACT_APP_API4_URL + 'offline_orders_dashboard/v1/customers/' + id + '/customer_address'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_admins() {
    const url = process.env.REACT_APP_API4_URL + 'offline_orders_dashboard/v1/offline_orders/admins?role_type=customer_support_executive'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_customer_listing(payload) {
    const url = process.env.REACT_APP_API4_URL + 'offline_orders_dashboard/v1/customers/customer_listing?by_customer_exec=' +
      payload.by_customer_exec + '&by_mobile_number=' + payload.by_mobile_number + '&page=' + payload.page
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  delivery_slots(id) {
    const url = process.env.REACT_APP_API4_URL + 'offline_orders_dashboard/v1/customers/' + id + '/delivery_slots'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_product_catalogue(id) {
    const url = process.env.REACT_APP_API4_URL + 'offline_orders_dashboard/v1/products/' + id + '/products_catalogue'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_active_carts(id) {
    const url = process.env.REACT_APP_API4_URL + 'offline_orders_dashboard/v1/customers/' + id + '/active_carts'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  update_cart(payload) {
    const url = process.env.REACT_APP_API4_URL + 'offline_orders_dashboard/v1/customers/active_carts'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  update_cart_item(payload) {
    const url = process.env.REACT_APP_API4_URL + 'offline_orders_dashboard/v1/customers/update_cart_item'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  apply_coupon(payload) {
    const url = process.env.REACT_APP_API4_URL + 'offline_orders_dashboard/v1/customers/apply_discount'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  create_order(payload) {
    const url = process.env.REACT_APP_API4_URL + 'offline_orders_dashboard/v1/customers/payments'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  get_search(payload, id) {
    const url = process.env.REACT_APP_API4_URL + 'offline_orders_dashboard/v1/products/' + id + '/products?starts_with=' + payload.value
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_nearby_darkstores(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/nearby_available_darkstore?darkstore_id=' + payload.id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  update_nearby_darkstore(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/update_darkstore_id'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  reshedule_delivery(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/orders/reshedule_delivery'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  delivery_now(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/orders/deliver_now'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  get_customer_address(id) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/orders/customer_addresses?customer_id=' + id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  change_address(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/orders/reorder_on_request'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  get_partners() {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/orders/get_partner_types'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  escalate_refund(id) {
    const url = process.env.REACT_APP_API_URL + `admin_dashboard/v1/refunds/refund_requests/${id}/escalate_refund_request`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.patch(url, {}, headers)
  }

  update_priority(payload){
    const url = process.env.REACT_APP_API_URL + `admin_dashboard/v1/delivery_admin/update_delivery_priority`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  update_allow_cr(payload){
    const url = process.env.REACT_APP_API_URL + `admin_dashboard/v1/delivery_admin/create_order_action`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  get_fraud_logs_filter(payload) {
    const url = process.env.REACT_APP_API_URL +
    'admin_dashboard/v1/orders/get_all_logs?page=' + payload.page + '&fraud_reason_code=' + payload.fraud_reason_code + '&by_darkstore=' + payload.by_darkstore +
    '&by_mobile_number=' + payload.by_mobile_number+ '&by_order_number=' + payload.by_order_number +'&by_delivery_date=' + payload.by_delivery_date +
    '&by_status=' +payload.by_status + '&by_order_type=' + payload.by_order_type
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }
}
