import React, {useState} from 'react'
import { Spin, Modal, Button, Row, Col, Tabs, notification, 
    Select, Input, InputNumber, Radio} from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import LocationSearch from './location_search';
const { Option } = Select;

function Search(props){
    const [showError, setShowError] = useState(false)

    function showErrorDetail(e){
        e.stopPropagation();
        setShowError(true)
        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }
    
    return(
        <div>
            {props.searchData ?
                <div className="frz-p-relative">
                    {props.searchData.products.length > 0 ?
                    <div className="frz-search-box">
                    {props.searchData.products.map(data =>{
                        return(
                        <div className="frz-search-layer" key={data.id}>
                            <Row>
                                <Col xs={2}>
                                    <img className="order-detail-img" src={data.image_url} alt="item"/>
                                </Col>
                                <Col xs={11}>
                                    <div className="vertical-center" style={{paddingLeft: 8}}>
                                        <div className="line-item-name">{data.name}</div>
                                        <div className="line-item-moq">
                                        {data.pack_size}
                                        {!data.in_stock ? 
                                            <span className="out-stock">(Out of Stock)</span>
                                        : null}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={4}>
                                    <div className="line-item-name vertical-center-metric">
                                        ₹ {data.moq_sp}
                                    </div>
                                </Col>
                                <Col xs={7}>
                                    <div className="line-item-name vertical-center-metric">
                                        {props.currentCart.find(x => x.id === data.id) ? 
                                        <>
                                            <button className="frz-product-minus"
                                                    onClick={() => props.addCart('min', data.id)}>
                                                    -   
                                            </button>
                                            {props.currentCart.find(x => x.id === data.id).qty}
                                            {props.currentCart.find(x => x.id === data.id).qty < data.max_qty ?
                                                <button className="frz-product-plus"
                                                        onClick={() => props.addCart('max', data.id)}>
                                                        +   
                                                </button>
                                                : 
                                                <span className="frz-Qty">
                                                    <button className="frz-product-plus"
                                                        onClick={(e) => showErrorDetail(e)}>
                                                        +   
                                                    </button>
                                                    {showError ? 
                                                        <div className="tooltiptext">
                                                            Max Qty {data.max_qty}
                                                        </div>
                                                    : null}
                                                </span>
                                            }
                                        </>
                                        : 
                                        <>
                                            {data.in_stock ? 
                                                <button className="frz-add-cart"
                                                        onClick={() => props.addCart('max', data.id)}>
                                                            ADD  
                                                </button>
                                            : null}
                                        </>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        )
                    })}
                    </div>
                    :
                    <div className="frz-search-box-noresult">
                        <div className="frz-position-center frz-fw-500">
                            No Results Found
                        </div>
                    </div>
                    }
                </div>
            : null}
        </div>
    )
}

export default Search