import React from 'react'
import { Button } from 'antd'
import { EditOutlined  } from '@ant-design/icons';

export const dsPropertiesColumns = (onEdit) => {
  return [
    {
      title: 'Darkstore Name',
      dataIndex: 'darkstore_name',
      key: 'darkstore_name',
      align: 'center',
    },
    {
      title: 'Max Batch Size',
      dataIndex: '',
      key: '',
      align: 'center',
      sorter: (a, b) => a.max_batch_size - b.max_batch_size,
      render: (record) => {
        return record.max_batch_size
      }
    },
    {
      title: 'Batching Threshold',
      dataIndex: '',
      key: '',
      align: 'center',
      sorter: (a, b) => a.batching_threshold - b.batching_threshold,
      render: (record) => {
        return record.batching_threshold
      }
    },
    {
      title: 'Max Retries',
      dataIndex: '',
      key: '',
      align: 'center',
      sorter: (a, b) => a.max_retries - b.max_retries,
      render: (record) => {
        return record.max_retries
      }
    },
    {
      title: 'Masking Enabled',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return record?.masking_enabled?.toString()
      }
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => <div>
        <Button onClick={() => onEdit(record)} icon={<EditOutlined />}></Button>
      </div>
    }
  ]
}