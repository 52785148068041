import { Http } from '../_modules/http'

export class SpecialDiscountService {

  http = new Http()

  get_special_discount(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/marketing/list_special_discount?page=' + `${payload.page}` 
      + '&by_valid_from=' + payload.by_valid_from + '&by_valid_to=' + payload.by_valid_to
      + '&by_first_order_flag=' + payload.by_first_order_flag + '&starts_with=' + payload.starts_with
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  update_special_discount(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/marketing/update_special_discount?by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  create_special_discount(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/marketing/create_special_discount?by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  get_special_discount_sequence(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/marketing/get_special_discount_sequence?by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  update_special_discount_sequence(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/marketing/update_special_discount_sequence?by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  get_special_discount_activation(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/marketing/show_special_discount?city_id=' + payload.city + '&id=' + payload.id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  update_special_discount_activation(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/marketing/activation_special_discount'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }
}
