import React, {useEffect, useState} from 'react';
import {Card , Radio, Spin, Select, DatePicker} from 'antd';
import { Line } from 'react-chartjs-2';
import {StatisticsService} from '../../_services/statistics';
import moment from 'moment';
import Search from '../antd/search';
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;

const stats_service =  new StatisticsService()

function DeliveryChart(){
    const [statisticalData, setStatisticalData] = useState(false)
    const [renderData, setRenderData] = useState(false)
    const [renderDataGraph, setRenderDataGraph] = useState(false)
    const [state, setState] = useState({type: 'daily', start_date: '', end_date: '', showDateValue: false,
                                        valueByDarkstore: 'all'});
    const presetData = {
        labels: [],
        datasets: [{
            data: [],
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data1: []
        }]
    } 
    const [graphData, setGraphData] = useState({
        labels: [],
        datasets: [{
            data: [],
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data1: []
        }]
    })
    const { type, start_date, end_date, showDateValue, valueByDarkstore } = state

    useEffect(() => {
        if(!renderData){
          setRenderData(true)
          getDeliveryChart()
        }
      })

    function getDeliveryChart(){
        const payload = {
          type: type === undefined ? '' : type,
          start_date: start_date,
          end_date: end_date,
          by_darkstore: valueByDarkstore === 'all' ? '' : valueByDarkstore,
        }
        stats_service.get_delivery_chart(payload).subscribe((r) => {
          setStatsData(r.response.data)
          setStatisticalData(r.response.total_deliveries)
        },
        (err)=>{
          console.log(err)
        })
    }

    function onChangeRangePicker(value, dateString){
        setState(prevState =>({...prevState, showDateValue: true, start_date :dateString[0], end_date:dateString[1], type: undefined}))
        setRenderDataGraph(false)
        setRenderData(false)
        setGraphData(presetData)
    }

    function onChange(value, type) {
        if(type === 'type'){
            setState(prevState =>({...prevState, [type]: value, start_date: '', end_date: '', showDateValue: false}))
        }else{
            setState(prevState =>({...prevState, [type]: value}))
        }
        setRenderDataGraph(false)
        setRenderData(false)
        setGraphData(presetData)
     }

    function setStatsData(data) {
        data.map(val => {
            graphData.labels.push(val.x_axis)
            graphData.datasets[0].data.push(val.delivery_count)
        })
        // console.log(graphData)
        setGraphData(graphData)
        setRenderDataGraph(true)
    }

    function disabledDate(current){
        // Can select days before today and today
        return current && current > moment().add(0, "day");
      }

    return(
        <div>
            <Card>
                <h2 style={{margin: 10}}>Daily Order Count: </h2>
                <div className="layer">
                    <Search placeholder="Darkstore Name" value={valueByDarkstore}
                        onChange={(e) => onChange(e, 'valueByDarkstore')} type="valueByDarkstoreStats"/>
                </div>
                <div style={{margin: 10, borderBottom: '1px solid rgb(240,240,240', paddingBottom: 20}}>
                    <Select style={{ width: 150}} value={type}
                            placeholder="Select Type" onChange={(e) => onChange(e, 'type')}>
                        <Option value='daily'>Yesterday</Option>
                        <Option value='weekly'>Weekly</Option>
                        <Option value='this_month'>This Month</Option>
                        <Option value='last_month'>Last Month</Option>
                        <Option value='quarterly'>Quarterly</Option>
                        {/* <Option value='half_yearly'>Half Yearly</Option> */}
                    </Select>
                    <RangePicker onChange={onChangeRangePicker} style={{float:'right'}}
                            value={showDateValue ? [moment(start_date, dateFormat),
                            moment(end_date, dateFormat)] : '' } format={dateFormat}
                            allowClear={false} disabledDate={disabledDate}/>
                </div>
                <div style={{margin: 10, borderBottom: '1px solid rgb(240,240,240', 
                             paddingBottom: 10, display: 'flex', justifyContent: 'space-between'}}>
                    <div><b>Total Orders:</b> {statisticalData}</div>
                </div>
                {renderDataGraph ? 
                   <div>
                        <Line 
                            data={graphData}
                            options={{
                                    tooltips: {
                                        titleFontSize:13,
                                        bodyFontSize:15,
                                        enabled: true,
                                        displayColors:false,
                                        callbacks: {
                                            title: (tooltipItem, data) => {
                                                return (type !== 'daily' ? 
                                                new Date(data['labels'][tooltipItem[0]['index']]).toDateString()
                                                :   
                                                data['labels'][tooltipItem[0]['index']])
                                            }, 
                                            label: (tooltipItem, data) => {
                                                let tooltipData = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                                return `Order Count : ${tooltipData.toLocaleString('en-IN')}`;
                                            },
                                        }
                                    },
                                    scales: type !== 'daily' ? 
                                        {xAxes : [{
                                          type: 'time',
                                           unitStepSize: 1,
                                           time: {
                                             displayFormats: {
                                               'month': 'MMM YYYY'
                                             },
                                             unit: 'month',
                                           },
                                        }],
                                        yAxes : [{
                                            ticks : {
                                              min : 0
                                            }
                                        }]}
                                    : {yAxes : [{
                                        ticks : {
                                          min : 0
                                        }
                                      }]}
                            }}
                            legend={{"display": false}}
                            width={50} height={35} redraw={true}
                        />
                   </div>
                : 
                  <div className="spin-center">
                    <Spin tip="...Loading"/>
                  </div>
                }
            </Card>
        </div>
    )
}

export default DeliveryChart