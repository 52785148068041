import { Http } from '../_modules/http'

export class BannersService {

  http = new Http()

  get_banners(payload){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/banners?page=' + payload.page
    +'&valid='+payload?.date+'&by_banner_name='+payload.banner_name
    + "&by_segment=" + payload.by_segment
    + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }
  get_pages(){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/promotional_pages?no_paginate=true'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_segment(){
    const url = process.env.REACT_APP_SEGMENT_URL + 'admin/api/v1/segments?pagination=off'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  update_banner(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/admin/banners/' + payload.id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload.body, headers)
  }

  create_banner(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/admin/banners'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload.body, headers)
  }

  get_product_list(){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/products/get_product_list'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_cluster_list(){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/clusters?no_paginate=true'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_cluster_items(id){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/promotional_pages/'+id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_statusAndTemplate(){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/promotional_pages/static_data'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_darkstore_banners(payload){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/' + payload.id + '/darkstore_banners?'
                + 'by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  update_darkstore_banners(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/admin/activate_banner'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers)
  }

  get_banner_type(){
      const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/banners/static_data'
      let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
      return this.http.get(url, headers)
    }
}
