import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification } from 'antd';
import {SocietyService} from '../../_services/society';
import MainTable from '../../components/antd/table';
import DSTargetModal from '../../components/society/ds_target_modal';
import {dsTargetColumns} from '../../components/antd/columns/society';

const society_service = new SocietyService()

function DarkstoreTargets(){
  const modalRef = useRef(null);
  const [listingData, setListingData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])    
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [state, setState] = useState({});
  const [modalState, setModalState] = useState({society_target: undefined, currentActive: undefined});
  const { society_target, currentActive } = modalState

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getListing()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getListing(){
    setSpinEnable(true)
    setListingData([])
    society_service.get_virtual_ds().subscribe((r) => {
      setSpinEnable(false)
      setListingData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    dsTargetColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (
          <Button type="primary" onClick={() => showModal(record, 'Edit DS Targets')}>Edit</Button>
        )
      }
    });
    setColumns(columns)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  function showModal(data, type) {
    if(type === 'Edit DS Targets'){
      setModalState({society_target: data.society_target, currentActive: data.id})
    }
    modalRef.current.showModal(type, data)
  }

  function onChangeModal(value, type) {
    if(type === 'valueByRadio'){
      setModalState(prevState =>({...prevState, [type]: value}))
    }else{
      setModalState(prevState =>({...prevState, [type]: value === "" ? undefined : value}))
    }
  }

  function onCancelModal() {
    setModalState({society_target: undefined, currentActive: undefined})
  }

  function modalSubmit() {
    if(!society_target){
         openNotificationWithIcon('error', "Please fill society target")
         modalRef.current.stopLoading()
    }else{
      const payload = {
        id: currentActive,
        society_target: society_target
      }
      society_service.update_store_targets(payload).subscribe((r) => {
        openNotificationWithIcon('success','Edited Successfully')
        getListing()
        modalRef.current.handleCancel()
      },
      (err)=>{
        console.log(err)
        modalRef.current.stopLoading()
        openNotificationWithIcon('error', err.response.errors[0])
      })
    }
  }

  return(
    <div className="layer-nofilter">
      {listingData ?
        <div>
          {listingData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={listingData} columns={columns}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <DSTargetModal modalSubmit={modalSubmit} ref={modalRef}
                         onChangeModal={onChangeModal}
                         onCancelModal={onCancelModal}
                         society_target={society_target}/>
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default DarkstoreTargets
