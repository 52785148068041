import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button, Select, Input, InputNumber,
         Radio} from 'antd';
import {SocietyService} from '../../_services/society';

const society_service = new SocietyService()
const { Option } = Select;

const SocietyModal = forwardRef((props, ref) => {
  const [societyLogicPosData, setSocietyLogicPosData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      getLogicPos(value, data)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function getLogicPos(value, data){
    society_service.get_logic_pos().subscribe((r) => {
      setVisible(true)
      setData(data)
      setTitle(value)
      setSocietyLogicPosData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function handleOk() {
    setLoading(true)
    props.modalSubmit()
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.onCancelModal();
  }

  return (
    <div>
      {data && societyLogicPosData ?
        <Modal
          visible={visible}
          title={title}
          width='50%'
          onOk={handleOk}
          onCancel={handleCancelModal}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              Submit
            </Button>,
          ]}
        >
          <div className="block" style={{marginLeft: 18}}>Society Name :
            <span style={{paddingLeft:10}}>
              <Input placeholder="Society Name"
                value={props.society_name} style={{width:200}}
                onChange={(e) => props.onChangeModal(e.target.value, 'society_name')}/>
            </span>
          </div>
          <div className="block" style={{marginLeft: 50}}>Latitude :
            <span style={{paddingLeft:10}}>
              <Input placeholder="Latitude"
                value={props.latitude} style={{width:200}}
                onChange={(e) => props.onChangeModal(e.target.value, 'latitude')}/>
            </span>
          </div>
          <div className="block" style={{marginLeft: 40}}>Longitude :
            <span style={{paddingLeft:10}}>
              <Input placeholder="Longitude"
                value={props.longitude} style={{width:200}}
                onChange={(e) => props.onChangeModal(e.target.value, 'longitude')}/>
            </span>
          </div>
          <div className="block">LatLong Address :
            <span style={{paddingLeft:10}}>
              <Input placeholder="LatLong Address"
                value={props.latlong_address} style={{width:200}}
                onChange={(e) => props.onChangeModal(e.target.value, 'latlong_address')}/>
            </span>
          </div>
          <div className="block" style={{marginLeft: 52}}>Address :
            <span style={{paddingLeft:10}}>
              <Input placeholder="Current Address"
                value={props.current_address} style={{width:200}}
                onChange={(e) => props.onChangeModal(e.target.value, 'current_address')}/>
            </span>
          </div>
          <div className="block"style={{marginLeft: 35}}>Area Name :
            <span style={{paddingLeft:10}}>
              <Input placeholder="Area Name"
                value={props.area_name} style={{width:200}}
                onChange={(e) => props.onChangeModal(e.target.value, 'area_name')}/>
            </span>
          </div>
          <div className="block" style={{marginLeft: 52}}>Pincode :
            <span style={{paddingLeft:10}}>
              <Input placeholder="Pincode"
                value={props.pincode} style={{width:200}}
                onChange={(e) => props.onChangeModal(e.target.value, 'pincode')}/>
            </span>
          </div>
          <div className="block" style={{marginLeft:77}}>City :
            <span style={{paddingLeft:10}}>
            <Select style={{ width: 200}} value={props.currentcity}
                    placeholder="Select City" onChange={(e) => props.onChangeModal(e, 'currentcity')}>
                <Option key='Mumbai' value='Mumbai'>Mumbai</Option>
                <Option key='Pune' value='Pune'>Pune</Option>
            </Select>
            </span>
          </div>
          <div className="block" style={{marginLeft:40}}>Is Verified :
            <span style={{paddingLeft:10}}>
              <Radio.Group
                     value={props.valueByRadio}
                     onChange={(e) => props.onChangeModal(e.target.value, 'valueByRadio')}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </span>
          </div>
          <div className="block">Logic Pos Account:
          <span style={{paddingLeft:10}}>
          <Select style={{ width: 400}} value={props.mainLogicPos}
                  showSearch
                  optionFilterProp="children"
                  placeholder="Select Logic Pos Account" onChange={(e) => props.onChangeModal(e, 'mainLogicPos')}
                  filterOption={(input, option) =>
                      option.props.logic.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {societyLogicPosData.map((data, index) => {
              return(
                <Option key={data.logic_pos_code} value={data.logic_pos_code} logic={data.name}>
                  {data.name} - {data.logic_pos_user_id}
                </Option>
              )
            })}
          </Select>
          </span>
        </div>
        </Modal>
      : null }
    </div>
  )
})

export default SocietyModal
