import React, { useEffect, useState } from "react";
import { Input, Button, Spin, InputNumber } from "antd";
import { ProductService } from "../../../_services/product";
import { ErrorNotification, SuccessNotification } from "./notification";

function ProductDetails(props) {
  const productService = new ProductService();
  const [productDetails, setProductDetails] = useState(undefined);
  const [buttonLoading, setButtonLoading] = useState(false);

  const inputStyle = {
    marginRight: 100,
    textAlign: "right",
  };

  useEffect(() => {
    getProductDetails();
  }, []);

  function getProductDetails() {
    productService.getProductDetails(props.productId).subscribe(
      (r) => {
        setProductDetails(r.response.data);
      },
      (err) => {
        ErrorNotification("Failed to get details", (err = err));
      }
    );
  }

  function saveProductDetails() {
    // if (!productDetails.hsn_code) {
    //   ErrorNotification("Please add HSN Code");
    //   return
    // }
    setButtonLoading(true);
    productService
      .updateProductDetails(props.productId, productDetails)
      .subscribe(
        (r) => {
          SuccessNotification("Details updated successfully");
          setButtonLoading(false);
        },
        (err) => {
          ErrorNotification("Failed to update details", (err = err));
          setButtonLoading(false);
        }
      );
  }

  function onInputChange(key, value) {
    productDetails[key] = value;
    if (value === "") {
      productDetails[key] = null;
    }
  }

  const FZInputText = ({ inputName, keyName }) => {
    return (
      <div className="block" style={inputStyle}>
        <span style={{ verticalAlign: "top" }}>
          <b>{inputName} :</b>
        </span>
        <span style={{ paddingLeft: 10 }}>
          <Input.TextArea
            placeholder={inputName}
            style={{ width: 400 }}
            onChange={(e) => onInputChange(keyName, e.target.value)}
            rows={4}
            cols={10}
            defaultValue={productDetails[keyName] ?? undefined}
          />
        </span>
      </div>
    );
  };

  const FZInput = ({ inputName, keyName }) => {
    return (
      <div className="block" style={inputStyle}>
        <span>
          <b>{inputName} :</b>
        </span>
        <span style={{ paddingLeft: 10 }}>
          <Input
            placeholder={inputName}
            style={{ width: 400 }}
            onChange={(e) => onInputChange(keyName, e.target.value)}
            defaultValue={productDetails[keyName] ?? undefined}
          />
        </span>
      </div>
    );
  };

  const FZInputNumber = ({ inputName, keyName }) => {
    return (
      <div className="block" style={inputStyle}>
        <span>
          <b>{inputName} :</b>
        </span>
        <span style={{ paddingLeft: 10 }}>
          <InputNumber
            placeholder={inputName}
            style={{ width: 400 }}
            onChange={(e) => onInputChange(keyName, e)}
            defaultValue={productDetails[keyName] ?? undefined}
          />
        </span>
      </div>
    );
  };

  return (
    <div style={{ margin: "20px auto" }}>
      {productDetails ? (
        <div style={{ textAlign: "center" }}>
          <FZInput inputName="HSN Code" keyName="hsn_code" />
          <FZInput inputName="EAN Code" keyName="ean_no" />
          <FZInputNumber inputName="OPC Sequence" keyName="opc_sequence" />
          <FZInputText inputName="Description" keyName="description" />
          <FZInputText inputName="Info" keyName="info" />
          <FZInputText inputName="Benefits" keyName="benefits" />

          <Button
            type="primary"
            style={{ marginTop: 20, width: "100px" }}
            onClick={saveProductDetails}
            loading={buttonLoading}
          >
            Save
          </Button>
        </div>
      ) : (
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      )}
    </div>
  );
}

export default ProductDetails;
