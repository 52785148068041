import { Http } from "../_modules/http";

export class StoreService {
  http = new Http();

  get_stock(payload) {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/products/out_of_stock_skus?page=" +
      payload.page +
      "&starts_with=" + payload.by_name +
      "&category=" + payload.category + '&by_city=' + `${localStorage.getItem('city') ?? ''}`

    let headers = { ...JSON.parse(localStorage.getItem("auth")) };
    return this.http.get(url, headers);
  }

  get_ds_stock(payload) {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/products/darkstore_wise_oos?page=" +
      payload.page +
      "&darkstore_id=" + payload.darkstore_id +
      "&category=" + payload.category + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = { ...JSON.parse(localStorage.getItem("auth")) };
    return this.http.get(url, headers);
  }

  get_sto(payload) {
    const url =
      process.env.REACT_APP_AWS_URL +
      "admin_dashboard/sto?page=" +
      payload.page +
      "&by_source=" +
      payload.by_source +
      "&by_destination=" +
      payload.by_destination +
      "&by_transfer_date=" +
      payload.by_transfer_date +
      "&by_status=" +
      payload.by_status;
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

  get_grn(payload) {
    const url =
      process.env.REACT_APP_AWS_URL +
      "admin_dashboard/grns?page=" +
      payload.page +
      "&node_code=" +
      payload.by_node_id +
      "&by_po_number=" +
      payload.by_po_number +
      "&by_delivery_date=" +
      payload.by_delivery_date;
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

  get_line_items(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/' + payload.id + "/items";
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

  download_reports(payload) {
    let url={};
    if(payload.report_type){
      url =
      process.env.REACT_APP_AWS_V2_URL +
      "admin_dashboard_v2/stock_losses/export_" +
      payload.url +
      "?start_date=" +
      payload.start_date +
      "&end_date=" +
      payload.end_date +
      "&node_code=" +
      payload.node_id + '&report_type=' + payload.report_type
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    }
    else{
    url =
      process.env.REACT_APP_AWS_URL +
      "admin_dashboard/" +
      payload.url +
      "?start_date=" +
      payload.start_date +
      "&end_date=" +
      payload.end_date +
      "&node_code=" +
      payload.node_id
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
      + `${payload.operations ? '&operations=true' : ''}`
      + `${payload.by_category_code ? '&by_category_code=' + payload.by_category_code : ''}`
      + `${payload.by_sub_category_code ? '&by_sub_category_code=' + payload.by_sub_category_code : ''}`
}
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

  download_reports_job(id) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/get_job_details?id=" + id;
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

  darkstore_margins(payload) {
    const url =
      process.env.REACT_APP_AWS2_URL +
      "darkstore-sales/fetch-daily-margins?date=" +
      payload.date +
      "&dk_id=" +
      payload.darkstore_id;
    let headers = {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  darkstore_rankings(payload) {
    const url =
      process.env.REACT_APP_AWS_URL + "admin_dashboard/display_store_rankings?dk_id=" + payload.darkstore_id + `&date=${payload.date}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

  get_forecasting() {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/forecasting_factors";
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

  update_forecasting(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/forecasting_factors";
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers);
  }

  get_store_approval_list(payload) {
    const url = process.env.REACT_APP_AWS_V2_URL + "admin_v2/stock_loss/requests?created_by=" + payload.created_by + "&page=" + payload.page;
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

  show_sku_list(payload) {
    const url = process.env.REACT_APP_AWS_V2_URL + "admin_v2/stock_loss/requests/" + payload.id;
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

  sku_row_approval(payload) {
    const url = process.env.REACT_APP_AWS_V2_URL + "admin_v2/approvals"
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload, headers);
  }

  sku_bulk_approval(payload) {
    const url = process.env.REACT_APP_AWS_V2_URL + "admin_v2/approvals/bulk_create"
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload, headers);
  }

  get_node_listing(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'admin/list_node?by_node_type=' + payload.nodeType
      + '&show_all=' + `${payload?.show_all ?? ''}`
      + '&by_city=' + `${payload?.no_city ?? localStorage.getItem('city') ?? ''}`
      + '&admin_id=' + `${payload?.no_admin ? '' : JSON.parse(localStorage.getItem('auth')).id}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_pending_approval_status(payload) {
    const pathUrl = process.env.REACT_APP_AWS_V2_URL + `approval_dashboard/requests/statuses?dashboard=admin`;
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(pathUrl, headers);
  }


  get_pending_approval_detail(payload) {
    const pathUrl = process.env.REACT_APP_AWS_V2_URL + `approval_dashboard/requests?by_city=${localStorage.getItem('city') ?? ''}&by_requester=${payload.by_requester}&by_status=${payload.status}`
      + '&by_node=' + payload.by_node + "&page=" + payload.page
      + "&node_type=DS";
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(pathUrl, headers);
  }

}
