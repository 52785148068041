import React, {useState, useEffect} from 'react';
import loginRequired from "../_wrappers/login";
import { Route, Switch } from 'react-router-dom';
import CustomRoutes from './routes';

function Routes () {
  const [customRoutes, setCustomRoutes] = useState(false)
  const [renderData, setRenderData] = useState(false)

  useEffect(() => {
    if(localStorage.getItem('roles') !== null){
      if(!renderData){
        if(localStorage.getItem('roles').includes('offline_partner')){
          setRenderData(true)
          getAccessControlPartner(CustomRoutes)
        }else if(localStorage.getItem('roles').includes('catalog_admin_third_party')){
          setRenderData(true)
          getAccessControlCatalog(CustomRoutes)
        }else if(//localStorage.getItem('roles').includes('procurement') ||
                //  localStorage.getItem('roles').includes('warehouse_admin') ||
                //  localStorage.getItem('roles').includes('warehouse_staff') ||
                //  localStorage.getItem('roles').includes('procurement_admin') ||
                //  localStorage.getItem('roles').includes('category') ||
                //  localStorage.getItem('roles').includes('category_admin') ||
                //  localStorage.getItem('roles').includes('cc_admin') ||
                 localStorage.getItem('roles').includes('coldex')){
                  if(JSON.parse(localStorage.getItem('roles')).indexOf('admin') > -1 || 
                     JSON.parse(localStorage.getItem('roles')).indexOf('accounts') > -1){
                    setRenderData(true)
                    getAccessControl(CustomRoutes)
                  }else{
                    setRenderData(true)
                    getAccessControlOperations(CustomRoutes)
                  }
        }else{
          setRenderData(true)
          getAccessControl(CustomRoutes)
        }
      }
    }
  })

  function getAccessControl(routes){
    let accessControl = []
    accessControl.push(routes.dsstats, routes.capacity_plan, routes.society, routes.orders, 
                       routes.valet, routes.customers, routes.store,
                       routes.operations, routes.pricing, routes.deliveryapp, routes.distribution, routes.master, 
                       routes.updown, routes.dunzo, routes.logs, routes.segments,
                       routes.marketing, routes.discounts, routes.sdui, routes.developers)
    setCustomRoutes(accessControl)
  }

  function getAccessControlPartner(routes){
    let accessControl = []
    let partnerRoute = {
      name: "Orders",
      children: [routes.orders.children[2]]
    }
    accessControl.push(partnerRoute)
    setCustomRoutes(accessControl)
  }

  function getAccessControlCatalog(routes){
    console.log(routes)
    let accessControl = []
    let partnerRoute = {
      name: "Master",
      children: [routes.master.children[0]]
    }
    accessControl.push(partnerRoute)
    setCustomRoutes(accessControl)
  }

  function getAccessControlOperations(routes){
    let accessControl = []
    accessControl.push(routes.operations, routes.master)
    setCustomRoutes(accessControl)
  }
  
  return (
    <>
    {customRoutes ?
      <Switch>
        {customRoutes.map((data, index) => (
          (data.children ?
            (data.children.map((val, index) => (
              (val.children ? 
                (val.children.map(({path, component}, index) => (
                  <Route path={path} exact key={index} component={component} />
                ))) 
              : <Route path={val.path} exact key={index} component={val.component} />
              )
            ))) 
          : <Route path={data.path} exact key={index} component={data.component} />
          )
        ))}
      </Switch>
    : null}
    </>
  )
}

const Wrapped = loginRequired(Routes)
export default Wrapped
