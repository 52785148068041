import React, { useState } from 'react';
import { Row, Col, Form, Radio, Select, InputNumber, Rate } from 'antd';
import FRZSelect from '../../../_controls/FRZSelect';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import FRZCustomRadioButon from '../../../_controls/FRZCustomRadioButon';
import rupee from '../../../static/images/rupee.svg';
import { MinusCircleOutlined } from '@ant-design/icons';

function ImsH(props) {
  const [period] = useState([{ period: 'DAILY', name: 'Daily', id: 1 },
  { period: 'WEEKLY', name: 'Weekly', id: 2 }, { period: 'MONTHLY', name: 'Monthly', id: 3 }])

  const onFormChange = () => {
    props.handleOnFormChange(true)
  }

  return (
    <div className='frz-ims-box'>
      <Row>
        <Col span={12}>
          <div className='frz-ims-text'>
            Rating Incentive (H)
          </div>
        </Col>
        <Col span={12}>
          <Form
            form={props.form}
            layout="horizontal"
            name="form"
            onChange={() => onFormChange()}>
            <div className='frz-dis-flex frz-justify-end'>
              <Form.Item name={['H', 'rating_threshold']}>
                <Rate count={5} allowHalf />
              </Form.Item>
              <div className='frz-lp-10'></div>
              <div className='frz-p-relative frz-ims-rs-custom frz-ims-placeholder'>
                <div className='frz-rupee-ims'><img src={rupee} /> </div>
                <FRZInputNumber minValue={0} name={['H', 'rate']} isRequired={true}
                  label="" placeholder="rate" width={150} errorMsg="Please enter rate" />
              </div>
            </div>
          </Form>
        </Col>
      </Row>

      {/* 2ND FORM i.e I */}
      <Row>
        <Col span={12}>
          <div className='frz-ims-text'>
            Earning Based Incentive (I)
          </div>
        </Col>
        <Col span={12}>
          <Form
            form={props.form}
            layout="horizontal"
            name="formH"
            onChange={() => onFormChange()}>
            <div className='frz-dis-flex frz-justify-end'>
              {/* <FRZSelect DropDownData={period} name={['I', 'period']} isRequired={true}
                label="" placeholder="Period" option="name" isShowSearch={true}
                width={150} value="period" setOnChange={onFormChange} />
              <div className='frz-lp-10'></div> */}

              {/* <div className='frz-p-relative frz-ims-rs-custom frz-ims-placeholder'>
                <div className='frz-rupee-ims'><img src={rupee} /> </div>
                <FRZInputNumber minValue={0} name={['I', 'rate']} isRequired={true}
                  label="" placeholder="rate" width={150} errorMsg="Please enter target earning" />
              </div> */}

              <div className='frz-p-relative frz-ims-rs-custom frz-ims-placeholder'>
                {/* <div className='frz-rupee-ims'><img src={rupee} /> </div> */}
                <Form.List name="I"
                // initialValue={[{}]}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name }, index) => (
                        <div key={key} className='frz-dis-flex frz-justify-end frz-ims-placeholder'>
                          {index == "0" ?
                            <Form.Item
                              name={[name, 'period']}
                              label={''}
                              rules={[{ required: true, message: 'Please select period' }]}
                            >
                              <Select
                                className="frz-w-150px" placeholder="Select Period" onChange={onFormChange}>
                                {period.map(item => (
                                  <Select.Option disabled={period.filter((x) => x.id > props.billingValue).map(a => a.id).includes(item['id'])} key={item.period} value={item.period}>
                                    {item.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                            : null}
                          <div className='frz-lp-10'></div>
                          <Form.Item
                            name={[name, 'earning_threshold']}
                            label={''}
                            rules={[{ required: true, message: 'Please enter target earning' }]}
                          >
                            <InputNumber placeholder="Target earning" width={200} className='frz-w-150px' />
                          </Form.Item>
                          <div className='frz-lp-10'></div>
                          <Form.Item
                            name={[name, 'rate']}
                            label={''}
                            rules={[{ required: true, message: 'Please enter rate' }]}
                          >
                            <InputNumber placeholder="Rate" width={200} className='frz-w-150px' />
                          </Form.Item>
                          {/* <FRZInputNumber onChange={onFormChange} minValue={0} name={['I', 'rating_threshold']} isRequired={true}
                            label="" placeholder="Target earning" width={150} errorMsg="Please enter target earning" />
                          <div className='frz-lp-10'></div> */}
                          {/* <FRZInputNumber onChange={onFormChange} minValue={0} name={['I', 'rate']} isRequired={true}
                            label="" placeholder="rate" width={150} errorMsg="Please enter rate" /> */}
                          <div className='frz-tp-5'>
                            {/* {index != 0 && */}
                            <>
                              <MinusCircleOutlined className='frz-lp-10' onClick={() => remove(name)} />
                            </>
                            {/* } */}
                          </div>
                        </div>
                      ))}
                      <div className='frz-ims-add' onClick={() => add()}>
                        + Add Field
                      </div>
                    </>
                  )}
                </Form.List>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default ImsH