import React, {useState, useEffect} from 'react';
import {Table, Spin, Card, Icon, Tooltip, Button} from 'antd';
import {societyCampaignDiscountColumns} from '../antd/columns/society';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

function NestedDiscountTable(props) {
  const [columns, setColumns] = useState([])

  useEffect(() => {
    getColumns()
  }, [])

  function getColumns(){
    societyCampaignDiscountColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: 'Edit/Delete',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (
          <div>
             <Button.Group>
               <Tooltip placement="topLeft" title="Edit">
                  <Button disabled={props.data.society_campaign_status_details.name !== 'draft'}
                          icon={<EditOutlined />}
                          onClick={() => props.showModalCampaign(record, props.data.campaign_name, 'Edit Campaign Discount')}>
                  </Button>
               </Tooltip>
               <Tooltip placement="topLeft" title="Delete">
                  <Button disabled={props.data.society_campaign_status_details.name !== 'draft'}
                          icon={<DeleteOutlined />}
                          onClick={() => props.deleteDiscount(record)}>
                  </Button>
               </Tooltip>
            </Button.Group>
          </div>
        )
      }
    })
    setColumns(columns)
  }

  return(
    <Card className="nested-table">
      {props.data.society_campaign_discount_details.length > 0 ?
        <Table columns={columns} pagination={false} rowKey="id"
               dataSource={props.data.society_campaign_discount_details}/>
       :
        <div className="no-data-nested">No Discounts Added</div>
      }
    </Card>
  )
}

export default NestedDiscountTable;
