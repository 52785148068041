import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Button } from 'antd';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

const isObject = val => {
  return typeof val === 'object' && val !== null;
};

function LocationSearch(props){
    const [address, setAddress] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [state, setState] = useState({});

    useEffect(() => {
        setAddress(props.tempAddressSearch)
    }, [])

    function handleChange(address){
        setAddress(address)
    }

    function handleSelect(address){
        setAddress(address)
        geocodeByAddress(address)
            // .then(results => getLatLng(results[0]))
            .then(results => {props.searchAddress(results[0].geometry.location.lat(), results[0].geometry.location.lng(), address, results[0].address_components)})
            .catch(error => console.error('Error', error));
    }

    function classnames(...args){

        const classes = [];
        args.forEach(arg => {
          if (typeof arg === 'string') {
            classes.push(arg);
          } else if (isObject(arg)) {
            Object.keys(arg).forEach(key => {
              if (arg[key]) {
                classes.push(key);
              }
            });
          } else {
            throw new Error(
              '`classnames` only accepts string or object as arguments'
            );
          }
        });
    
        return classes.join(' ');
    };

    function handleCloseClick(){
        setAddress(address)
        setState({latitude: null, longitude: null})
    };

    function handleError (status, clearSuggestions) {
        // console.log('Error from Google Maps API', status); // eslint-disable-line no-console
        setErrorMessage(status)
        clearSuggestions()
      };

    return(
        <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
            onError={handleError}
            shouldFetchSuggestions={address.length > 3}
            searchOptions={{
            componentRestrictions: {
            country: ['in'],
            }
            }}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                return (
                    <div className={`${"Demo__search-bar-container"} ${props.showEdit ? "block" : false}`}>
                    <div className="Demo__search-input-container" style={{display: 'inline-flex', width: '100%'}}>
                        <input {...getInputProps({
                                placeholder: 'Search for locality...',
                                className: 'frz-search-location Demo__search-input',
                            })} disabled={props.addressLock} value={address} style={!props.showEdit ? {width: '100%'} : {}} />
                        {props.addressLock && props.showEdit  ? 
                            <Button type="primary" style={{marginLeft: 20}} ghost onClick={props.editAddress}>
                                Edit
                            </Button> 
                            : null
                        }
                    </div>
                    {props.addressLock && !props.isServiceable && props.showEdit ? 
                      <div style={{color: 'red', paddingTop: 10}}>
                        Location Not Serviceable
                      </div>
                    : null}
                    {props.addressLock && props.isServiceable && props.showEdit ? 
                      <div style={{color: 'green', paddingTop: 10}}>
                        Location Serviceable
                      </div>
                    : null}  
                    {suggestions.length > 0 && (
                        <div className="Demo__autocomplete-container frz-search-container">
                        {suggestions.map(suggestion => {
                            const className = classnames('Demo__suggestion-item', {
                            'Demo__suggestion-item--active': suggestion.active,
                            });

                            return (
                            /* eslint-disable react/jsx-key */
                            <div 
                                {...getSuggestionItemProps(suggestion,
                                { className: 'frz-suggestion' }
                                )}
                            >
                                <div>
                                    <div>
                                        {suggestion.formattedSuggestion.mainText}
                                    </div>
                                    <div>
                                        {suggestion.formattedSuggestion.secondaryText}
                                    </div>
                                </div>
                            </div>
                            );
                            /* eslint-enable react/jsx-key */
                        })}
                        </div>
                    )}
                    </div>
                );
            }}
        </PlacesAutocomplete>
    )
}

export default LocationSearch
