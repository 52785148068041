import React, {useState} from 'react';
import { Card, Row, Col, Select, Button, Spin, notification, DatePicker } from 'antd';
import {DeliveryApp} from '../../_services/delivery_app';
import { useSelector } from "react-redux";
import moment from 'moment';
import Search from '../antd/search';

const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const delivery_service = new DeliveryApp()

const { Option } = Select;

function DeliveryAppDownloads(props){
    const [state, setState] = useState({start_date: '', end_date: '', showDateValue: false, valueByDarkstore: 'all'});
    const [spinEnable, setSpinEnable] = useState(false)
    const [downloadOption, setDownloadOption] = useState(undefined)

    const {start_date, end_date, showDateValue, valueByDarkstore} = state

    function onChange(val) {
        setDownloadOption(val)
    }

    function onChangeDarkstore(value){
        // console.log(values)
        setState(prevState =>({...prevState, valueByDarkstore: value}))
    }

    function getDownloadList(){
        setSpinEnable(true)
        const payload = {
            start_date: start_date,
            end_date: end_date,
            store: valueByDarkstore === 'all' ? '' : valueByDarkstore ,
            url: downloadOption
        }
        delivery_service.download_reports_delivery(payload).subscribe((r) => {
            const linkSource = `data:application/octet-stream;base64,${r.response.csv_data}`;
            const downloadLink = document.createElement("a");
            let fileName
            if(downloadOption === 'admin/bills/download?store_code='){
                fileName = `${'Report'}_${(new Date).toDateString().replace(/ /g,'_')}.xlsx`;
            }else{
                fileName = `${'Report'}_${(new Date).toDateString().replace(/ /g,'_')}.csv`;
            }
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            setSpinEnable(false)
            openNotificationWithIcon('success','Downloaded Successfully')
            // if(downloadOption === 'sto_report'){
            //     window.open(r.response.response, '_blank')
            //     openNotificationWithIcon('success','Downloaded Successfully')
            //     setSpinEnable(false)
            // }else{
            //     var myVar = setInterval(() => {
            //         store_service.download_reports_job(r.response.data).subscribe(res => {
            //             if(res.response.response.status === 'success'){
            //                 clearInterval(myVar)
            //                 window.open(res.response.response.url, '_blank')
            //                 openNotificationWithIcon('success','Downloaded Successfully')
            //                 setSpinEnable(false)
            //             }
            //         }, (err) => {
            //             setSpinEnable(false)
            //             clearInterval(myVar)
            //             openNotificationWithIcon('error','Download Failed')
            //         })
            //     }, 6000);
            // }
        }, (err) => {
            setSpinEnable(false)
            openNotificationWithIcon('error','Download Failed')
        })
    }

    function openNotificationWithIcon(type, msg) {
        notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
    };

    function onChangeRangePicker(value, dateString){
        setState(prevState =>({...prevState, showDateValue: true, start_date :dateString[0], end_date:dateString[1]}))
    }

    function disabledDate(current){
        // Can select days before today and today
        // return current && current > moment().add(0, "day");
        //Can select days before today and past 3 months
        return current && (current < moment().subtract(91, "day") || current > moment().add(0, "day"));
    }

    return(
        <div className="layer" style={{paddingTop: 20}}>
            {!spinEnable ?
                <Row>
                <Card>
                    <Col span={24} style={{marginLeft: 10}}>
                     <h3 style={{marginTop: 10, marginBottom: 0}}>
                        Fraazo Delivery Task Report | Delivery App Rider Bills
                     </h3>   
                    <RangePicker onChange={onChangeRangePicker}
                                 value={showDateValue ? [moment(start_date, dateFormat),
                                 moment(end_date, dateFormat)] : '' } format={dateFormat}
                                 allowClear={false} disabledDate={disabledDate}/>
                    <Search placeholder="Darkstore Name" value={valueByDarkstore}
                        onChange={(e) => onChangeDarkstore(e, 'valueByDarkstore')} type="valueByDarkstoreStatsCode"/>
                    <Select style={{ width: 300, marginRight:10}}
                      value={downloadOption}
                      placeholder="Select Download Item" onChange={(e) => onChange(e)}
                      filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        <Option key="task/csv-download?store=" value="task/csv-download?store=">
                            Fraazo Delivery Task
                        </Option>
                        <Option key="admin/bills/download?store_code=" value="admin/bills/download?store_code=">
                            Delivery App Rider Bills
                        </Option>

                    </Select>
                    <Button disabled={!downloadOption || start_date === ''} onClick={getDownloadList} style={{width: 100, margin:10}}
                            type="primary">Download</Button>
                    </Col>
                </Card>
                </Row>
                :
                <div className="spin-center">
                    <Spin tip="...Download in Progress"/>
                </div>
            }
         </div>
    )
}

export default DeliveryAppDownloads