import { Http } from '../_modules/http'

export class WastageService {

  http = new Http()
  get_wastage_list(payload){
    const url = process.env.REACT_APP_AWS_URL + 'admin/stock_loss?node_code=' + `${payload.nodeCode}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  revert_wastage(payload){
    const pathUrl = process.env.REACT_APP_AWS_URL + "admin/stock_loss";
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(pathUrl, payload, headers);
  }

  get_wastage_product_list(payload){
    const url = process.env.REACT_APP_AWS_URL + 'admin/loss_items?id=' + `${payload.nodeId}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  update_rtv_wastage(payload) {
    const pathUrl = process.env.REACT_APP_AWS_URL + "admin/update_loss_unit_price";
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(pathUrl, payload, headers);
  }

  create_wastage(payload) {
    const pathUrl = process.env.REACT_APP_AWS_URL + "admin/create_daily_wastage";
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(pathUrl, payload, headers);
  }

  wastage_get_all_products(payload){
    const url = process.env.REACT_APP_AWS_URL + "admin/get_all_products?node_code=" + payload.nodeCode
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

}
