import React from 'react'
import { Spin, Modal, Button, Row, Col, Tabs, notification, 
    Select, Input, InputNumber, Radio} from 'antd';
import { EditOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import LocationSearch from './location_search';
const { Option } = Select;

function PaymentMode(props){
    return(
        <div style={{marginTop: 50}}>
            <div className="order-header">
                <b>#Payment Mode</b>
            </div>
            {props.updateCart.cart_items ?
                <div className="order-detail-padding">
                    <span>
                        <Radio.Group value={props.valueByRadio}
                                    onChange={(e) => props.onChangeModal(e.target.value, 'valueByRadio')}>
                            {props.updateCart.nett_amount > 0 ?
                                <> 
                                    <Radio value='cod'>COD</Radio>
                                    <Radio value='razorpay'>Razorpay</Radio>
                                </>
                            :
                                <Radio value='credits'>Credits</Radio>
                            }
                        </Radio.Group>
                    </span>
                </div>
            : null}
        </div>
    )
}

export default PaymentMode