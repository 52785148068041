import React, { useEffect, useState } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber, Select,
         Pagination, DatePicker, Popconfirm, notification, Tag } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {ValetService} from '../../_services/valet';
import MainTable from '../../components/antd/table';
import Search from '../../components/antd/search';
import {valetCustomerColumns} from '../../components/antd/columns/valet';

const valet_service = new ValetService()

function CustomersValet(){
  const [listingData, setListingData] = useState(false)
  const [valetPlan, setValetPlan] = useState(false)
  const [valetStatus, setValetStatus] = useState(false)
  const [valetCount, setValetCount] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [state, setState] = useState({valueByValet: undefined, valueByMobno: undefined, valueByValetStatus: undefined});
  const { valueByValet, valueByMobno, valueByValetStatus } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getListing()
    }
  })

  useEffect(() => {
    getColumns()
    getValetPlans()
    getValetStatus()
  }, [])

  function getListing(){
    setSpinEnable(true)
    setListingData([])
    const payload = {
      page: current,
      valet_product: !valueByValet ? '' : valueByValet,
      by_mobile_number: !valueByMobno ? '' : valueByMobno,
      by_status: !valueByValetStatus ? '' : valueByValetStatus
    }
    valet_service.customers_valet(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.valet_customers.meta.total_pages * 50}))
      setSpinEnable(false)
      setListingData(r.response.valet_customers.data)
      setValetCount(r.response.valet_customers_count)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getValetPlans(){
    valet_service.valet_plans().subscribe((r) => {
        setValetPlan(r.response.data)
    },
    (err)=>{
        console.log(err)
    })
  }

  function getValetStatus(){
    valet_service.valet_status().subscribe((r) => {
        setValetStatus(r.response.data)
    },
    (err)=>{
        console.log(err)
    })
  }

  function getColumns(){
    valetCustomerColumns.map(data => {
      columns.push(data)
    })
    setColumns(columns)
  }

  function pagination(page) {
    setCurrent(page)
    setListingData([])
    setRenderData(false)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function reset() {
    setState({valueByValet: undefined, valueByMobno: undefined, valueByValetStatus: undefined})
    setDisable(true)
    setCurrent(1)
    setListingData([])
    setRenderData(false)
  }

  return(
    <div className="layer">
      {listingData && valetPlan && valetStatus ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Valet Plan" value={valueByValet}
                        onChange={onChange} type="valueByValet"
                        data={valetPlan}/>
                <Search placeholder="Valet Status" value={valueByValetStatus}
                        onChange={onChange} type="valueByValetStatus"
                        data={valetStatus}/>
                <InputNumber placeholder="Mobile No"
                             onChange={(e) => onChange(e, 'valueByMobno')}
                             value={valueByMobno} style={{width: 200, margin: 10}}/>
                <Button disabled={disable} onClick={getListing} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
                <span style={{float: 'right', padding: 15}}><b>Valet Count: </b> {valetCount}</span>
              </Col>
            </Card>
          </Row>
          {listingData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={listingData} columns={columns}/>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default CustomersValet
