import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification, Table } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {DarkstoreService} from '../../_services/darkstore';
import {captializestr} from '../../_modules/captialize';
import moment from 'moment';

const darkstore_service = new DarkstoreService()

function CancelledDashboard(){
  const [tableData, setTableData] = useState(false)
  const [statsData, setStatsData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState(false)
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [state, setState] = useState({valueByDeliveryDate: '', date: ''});
  const { valueByDeliveryDate, date } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getTableData()
    }
  })

  useEffect(() => {
    tableData && getColumns()
  }, [tableData])

  function reset() {
    setState({valueByDeliveryDate: '', date: ''})
    setDisable(true)
    setTableData([])
    setRenderData(false)
  }

  function getTableData(){
    setSpinEnable(true)
    setTableData([])
    const payload = {
      date: !valueByDeliveryDate ? '' : valueByDeliveryDate,
    }
    darkstore_service.get_cancelled_ds(payload).subscribe((r) => {
      setSpinEnable(false)
      setStatsData(r.response.cumulative_data)
      setTableData(r.response.cancellation_data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    let newColm = []
    tableData?.length>0 && 
    Object.entries(tableData[0]).map(([key]) => {
      newColm.push({
        title: captializestr(key.split('_').join(' ')),
        dataIndex: key,
        key: key,
        align: "center",
        defaultSortOrder: 'descend',
        sorter: (a, b) => a[key] - b[key]
      })
    })
    setColumns(newColm)
  }

  function disabledDate(current){
    // Can select days before today and today
    return current && current > moment().add(0, "day");
  }

  function onChangeDate(date, dateString) {
    setState(prevState =>({...prevState, date: date, valueByDeliveryDate: dateString}))
    setDisable(false)
  }

  return(
    <div className="layer">
      {tableData && columns ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <DatePicker value={date} onChange={onChangeDate} placeholder="Date"
                  style={{ width: 200, margin: 10 }} disabledDate={disabledDate} />
                <Button disabled={disable} onClick={getTableData} style={{ width: 100, margin: 10 }}
                  type="primary">Search</Button>
                <Button onClick={reset} style={{ margin: 10 }}
                  type="primary" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                {Object.entries(statsData).map(([key, value]) => {
                    return(
                      key.includes("amount") ?
                      <span key={key} style={{padding: 15, lineHeight: 3,display: 'inline-block', paddingBottom:0, paddingTop:0}}><b>{key.replace("total_","").replace("amount", "Amt").split('_').join(' ').charAt(0).toUpperCase() +  key.replace("total_","").replace("amount", "amt").split('_').join(' ').slice(1)}: </b>₹{value}</span>: 
                      <span key={key} style={{padding: 15, lineHeight: 3, display: 'inline-block',paddingBottom:0,paddingTop:0}}><b>{key.replace("total_","").split('_').join(' ').charAt(0).toUpperCase() + key.replace("total_","").split('_').join(' ').slice(1)}: </b>{value}</span> 
                    )
                })}
              </Col> 
            </Card>
          </Row>
          {tableData.length > 0 ?
            <Row>
              <Col span={24}>
                {/* <MainTable dataSource={tableData} columns={columns}/> */}
                <Card style={{ paddingTop: 5, marginTop: 20 }}>
                    <Row>
                        <Col span={24}>
                            <Table
                              columns={columns}
                              dataSource={tableData}
                              pagination={false}
                              scroll={{ x: 240 }}
                              rowKey="darkstore_name"
                            />
                        </Col>
                    </Row>
                </Card>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default CancelledDashboard
