import React, { useEffect, useState } from 'react';
import { Row, Col, Form, InputNumber, Select } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import rupee from '../../../static/images/rupee.svg';
import { tailLayout } from '../../../_modules/controllayout'

const { Option } = Select;

function ImsC(props) {

  // useEffect(() => {
  //   props.form.setFieldsValue({distance_config:[{distance: 10, rate: 4}, {distance: 45, rate: 33}]})
  // }, [])

  const onFormChange = () => {
    props.handleOnFormChange(true)
  }

  useEffect(() => {
    if (props.billingValue && props.billingChange) {
      let formData = props.form.getFieldValue()
      if (formData["C"]) {
        if (!formData["C"]?.isArray) {
          formData["C"].map((itm) => {
            Object.assign(itm, { ...itm, period: undefined })
          })
        }
      }
    }
  }, [props.billingValue])

  return (
    <div>
      <Form
        form={props.form}
        layout="horizontal"
        name="form"
        onChange={() => onFormChange()}>
        <Row>
          <Col span={12}>
            <div className='frz-ims-incentive-subtext frz-fuel-km-padding'>
              Number Of Orders <br />
              Greater Than (C)
            </div>
          </Col>
          <Col span={12}>
            <div className='frz-fuel-km-padding'>
              <Form.List name="C">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name }, index) => (
                      <div key={key} className='frz-dis-flex frz-justify-end frz-ims-placeholder'>
                        {index == "0" ?
                          <Form.Item
                            name={[name, 'period']}
                            label={''}
                            rules={[{ required: true, message: 'Please select period' }]}
                          >
                            <Select className="frz-w-150px" placeholder="Select Period" onChange={onFormChange}>
                              {props.period.map(item => (
                                <Option key={item.period} value={item.period}
                                  disabled={props.period.filter((x) => x.id > props.billingValue).map(a => a.id).includes(item["id"])}>
                                  {item.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          : null}
                        <div className='frz-lp-10'></div>
                        <Form.Item
                          name={[name, 'order_threshold']}
                          label={''}
                          rules={[{ required: true, message: 'Please enter orders' }]}
                        >
                          <InputNumber placeholder="orders count" width={200} className='frz-w-150px' />
                        </Form.Item>
                        <div className='frz-lp-10'></div>
                        <div className='frz-p-relative frz-ims-rs-custom frz-ims-placeholder'>
                          <div className='frz-rupee-ims'><img src={rupee} /></div>
                          <Form.Item
                            name={[name, 'rate']}
                            label={''}
                            rules={[{ required: true, message: 'Please enter rate' }]}
                          >
                            <InputNumber placeholder="rate" className='frz-w-150px' />
                          </Form.Item>
                        </div>
                        <div className='frz-lp-10'></div>
                        <div className='frz-tp-5'>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </div>
                      </div>
                    ))}
                    <div className='frz-ims-add' onClick={() => add()}>
                      + Add Field
                    </div>
                  </>
                )}
              </Form.List>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default ImsC