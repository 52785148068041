import React from 'react'
import { Spin, Modal, Button, Row, Col, Tabs, notification, 
    Select, Input, InputNumber, Radio, Checkbox} from 'antd';
import { EditOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import LocationSearch from './location_search';
const { Option } = Select;

function CheckoutDetails(props){
    return(
        <div style={{marginTop: 50}}>
            <div className="order-header">
                <b>#Checkout Details</b>
            </div>
            {props.updateCart.cart_items ?
                <div>
                    <div className="frz-dis-tbl">
                        <div>
                            {props.updateCart.cart_items.length} x Total Item Price
                        </div>
                        <div>
                            ₹ {props.updateCart.amount}
                        </div>
                    </div>
                    {props.updateCart.discount_amount ?
                    <div className="frz-dis-tbl">
                        <div className="frz-green">
                            {props.updateCart.discount_details ? props.updateCart.discount_details.discount_title : 'Coupon'}
                        </div>
                        <div className="frz-green">
                            - ₹ {props.updateCart.discount_amount}
                        </div>
                    </div>
                    : null}
                    {props.updateCart.product_savings > 0 ?
                    <div className="frz-dis-tbl">
                        <div className="frz-green">
                            Price Savings
                        </div>
                        <div className="frz-green">
                            - ₹ {props.updateCart.product_savings}
                        </div>
                    </div>
                    : null}
                    {props.updateCart.credit_applied > 0 ?
                    <div className="frz-dis-tbl">
                        <div className="frz-green">
                            Fraazo Credits
                        </div>
                        <div className="frz-green">
                            - ₹ {props.updateCart.credit_applied}
                        </div>
                    </div>
                    : null}
                    <div className="frz-dis-tbl">
                        <div>
                            Delivery Charges
                        </div>
                        <div>
                            ₹ {props.updateCart.delivery_charge}
                        </div>
                    </div>
                    <div className="frz-bill-border"></div>
                    <div className="frz-dis-tbl">
                        <div className="frz-dis-inflex">
                        To Pay {props.updateCart.discount_amount ?
                            <div className="frz-orange frz-dis-inflex frz-lp-5"> 
                                (Saved ₹ {props.updateCart.discount_amount})
                            </div> : null}
                        </div>
                        <div className="frz-orange frz-dis-inflex">
                            ₹ {props.updateCart.nett_amount}
                        </div>
                    </div>
                    <div>
                        {props.updateCart.cashback_data?.applicable_cashback_detail?.is_available? 
                            <div className="frz-cashback frz-tm-10 text-center">
                                <div className="frz-dis-inflex frz-p-relative">
                                    <div className="frz-rp-12 frz-999">
                                        {props.updateCart.cashback_data.applicable_cashback_detail.title}
                                    </div> 
                                    <div>
                                        ₹ {props.updateCart.cashback_data.applicable_cashback_detail.cashback_value}
                                    </div>
                                </div>
                            </div>
                        : null}
                    </div>
                    <div>
                        {props.updateCart.discount_details?.discount_type === 'CustomerCashback' ? 
                            <div className="frz-cashback frz-tm-10 text-center">
                                <div className="frz-dis-inflex frz-p-relative">
                                    <div className="frz-rp-12 frz-999">
                                        Coupon Cashback
                                    </div> 
                                    <div>
                                        {props.updateCart.discount_details.discount_percentage_or_value_type === 'value' ? '₹ ' : null} 
                                        {props.updateCart.discount_details.discount_percentage_or_value}
                                        {props.updateCart.discount_details.discount_percentage_or_value_type !== 'value' ? ' %' : null}
                                    </div>
                                </div>
                            </div>
                        : null}
                    </div>
                    <div>    
                        <p className="frz-tm-10 frz-bm-20">
                            <Checkbox
                                onChange={(e)=>props.onChangeModal(e.target.checked, "freeDelivery")}>
                                Free Delivery
                            </Checkbox>
                        </p>
                    </div>
                </div>
            : null}
        </div>
    )
}

export default CheckoutDetails