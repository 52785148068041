import React, {useState, useEffect} from 'react';
import { Button, Popconfirm, Table} from 'antd';
import {refundColumns, paymentColumns} from '../antd/columns/orders';
import {handleDateFormat} from '../../_modules/date';
import {getStaticTime} from '../../_modules/time';
import {OrderService} from '../../_services/orders';
import {openNotificationWithIcon} from '../../_modules/notification';

function PaymentDetails(props){
  const [columns, setColumns] = useState([])
  const [paymentCols, setPaymentCols] = useState([])
  const [data, setData] = useState(false)

  const order_service = new OrderService()

  useEffect(() => {
    getColumns()
  }, [])

  useEffect(() => {
    setData(props.orderDetail.all_payments)
  }, [props.orderDetail.all_payments])

  useEffect(() => {
    getPaymentColumns()
  }, [data])

  function getPaymentColumns(){
    let payCols = []
    paymentColumns.map(data => {
      payCols.push(data)
    })
    payCols.push({
      title: "Fetch Status",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <Button type="primary" disabled={record.mode_of_payment === 'cod'} 
                  onClick={() => fetchStatus(record.id)}>Fetch Status</Button>
        );
      },
    })
    setPaymentCols(payCols)
  }

  function fetchStatus (id) {
    let tempData = data
    order_service.get_payment_status(id).subscribe((r) => {
      openNotificationWithIcon('success', 'Latest Status Fetched')
      tempData.find(x => x.id === id).status = r.response.data.status
      setData([...tempData])
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    refundColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: "Created On",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <>
            <div>{handleDateFormat(record.created_at)}</div>
            <div>{getStaticTime(record.created_at)}</div>
          </>
        );
      },
    })
    setColumns(columns)
  }
  
  return(
    <div className="p-relative">
      {props.orderDetail ?
        <div>
          <div>
            <b>Amount :</b>
            <span style={{paddingLeft:10}}>
              Rs. {props.orderDetail.amount}
            </span>
        </div>
          <div className="order-detail-padding">
            <b>Nett Amount :</b>
            <span style={{paddingLeft:10}}>
              Rs. {props.orderDetail.nett_amount}
            </span>
          </div>
          <div className="order-detail-padding">
            <b>Payment Status :</b>
            {props.orderDetail?.payment_details ? 
              <span style={{paddingLeft:10}}>
                {props.orderDetail.payment_details.status === 'success' ?
                  <span style={{color: 'green'}}>Paid</span>
                :
                  <span style={{color: 'red'}}>Unpaid</span>
                }
              </span>
            : null}
          </div>
          <div className="order-detail-padding">
            <b>Payment Method :</b>
            <span style={{paddingLeft:10}}>
              {props.orderDetail?.payment_details?.mode_of_payment}
            </span>
          </div>
          {props.orderDetail.amount_to_pay ?
            <div className="order-detail-padding">
              <b>Amount To Pay :</b>
              <span style={{paddingLeft:10}}>
                Rs. {props.orderDetail.amount_to_pay}
              </span>
            </div>
          : null}
          {props.orderDetail.refund_amount ?
            <div className="order-detail-padding">
              <b>Refunded Amount :</b>
              <span style={{paddingLeft:10}}>
                Rs. {props.orderDetail.refund_amount}
              </span>
            </div>
          : null}
          {props.orderDetail.addon_refund ?
            <div className="order-detail-padding">
              <b>Extra Credit Given :</b>
              <span style={{paddingLeft:10}}>
                Rs. {props.orderDetail.addon_refund.refund_amount}
              </span>
            </div>
          : null}
          {props.orderDetail.order_attributes.length > 0 ?
            <div>
            <div className="order-detail-padding"
                 style={{paddingTop: 20, color: '#262624'}}>
              <b>#Order Attributes</b>
            </div>
              {props.orderDetail.order_attributes.map((data, index) => {
                return(
                  <div className="order-detail-padding" key={index}>
                    <b>{data.name}:</b>
                    {data.attr_txn_type === 'discount' || data.attr_txn_type === 'cashback' ?
                      <span style={{paddingLeft:10, color: 'green'}}>
                        {data.attr_txn_type === 'discount' ? '- ' : null}Rs. {data.amount}
                      </span>
                      :
                      <span style={{paddingLeft:10}}>
                        Rs. {data.amount}
                      </span>
                    }
                  </div>
                )
              })}
            </div>
          : null}
          <div className="refund-button">
            <Popconfirm
              title="Are you sure you want to credit extra ₹ 50 to customer?"
              onConfirm={props.createRefundCredit}
              okText="Yes"
              cancelText="No"
              placement="leftTop"
              disabled={props.orderDetail.order_status !== 'delivered' || !props.operations}
            >
              <Button type="primary" disabled={props.orderDetail.order_status !== 'delivered' || !props.operations} style={{float: 'right'}}>
                Add ₹ 50 Extra Credit
              </Button>
            </Popconfirm>
            <br/>
            <Button style={{width: 130, marginTop: 10, float:'right'}} type="primary"
                    onClick={props.createTicket}
                    disabled={((props.orderDetail.order_type_detail !== 'express' ||
                              props.orderDetail.order_type_detail !== 'scheduled') &&
                              props.orderDetail.delivery_partner_details?.partner_type !== 'dunzo') || !props.operations}>
              Create Ticket
            </Button>
            <br/>
            <Button style={{width: 130, marginTop: 10, float:'right'}} type="primary"
                    ghost disabled={props.orderDetail.invoice_url === null}
                    href={props.orderDetail.invoice_url} target="_blank">
              Print Invoice
            </Button>
            <br />
            <Button style={{width: 180, marginTop: 10, float:'right'}} type="primary"
                    onClick={props.createPartialRefund}
                    disabled={(props.orderDetail.order_status !== 'delivered' && props.orderDetail.order_status !== 'cancelled') || !props.operations}>
              Make Partial Refund
            </Button>
          </div>
          {props.orderDetail.refund_payment?.length > 0 ?
            <div style={{paddingTop: 15, borderTop: '1px solid rgb(240,240,240)', marginTop: 20}}>
              <h3 style={{paddingBottom: 5, textAlign: 'center'}}><b>Refund Details</b></h3>
              <Table columns={columns} dataSource={props.orderDetail.refund_payment}
                    pagination={false} scroll={{ x: 240 }}
                    rowKey="id"/>
            </div>
          : null}
          {data && data.length > 0 ?
            <div style={{paddingTop: 15, borderTop: '1px solid rgb(240,240,240)', marginTop: 20}}>
              <h3 style={{paddingBottom: 5, textAlign: 'center'}}><b>All Payments</b></h3>
              <Table columns={paymentCols} dataSource={data}
                    pagination={false} scroll={{ x: 240 }}
                    rowKey="id"/>
            </div>
          : null}
        </div>
      : null}

    </div>
  )
}

export default PaymentDetails
