import React, { useEffect, useState, useRef } from "react";
import { ReloadOutlined } from "@ant-design/icons";
import { Row, Card, Col, Button, Table, Pagination, Spin, Input, DatePicker } from "antd";
// import CreateLanding from "./create_landing";
import { bulkDiscountColumns } from "../../components/ads/bulk_discount/bulkDiscountColmns";
import { AdsService } from "../../_services/ads";
import BulkDiscountModal from "../../components/ads/bulk_discount/bulk_discount_modal";
import { openNotificationWithIcon } from "../../_modules/notification";
import Search from '../../components/antd/search';

const ads_service = new AdsService()

const initialFilters = {
  page: 1,
  isSearch: true,
  valueByCouponCode: undefined,
  valueByCampaignName: undefined,
  valueByStatus: undefined,
  valueBySeg: undefined,
  date:'',
  valueByDate:undefined,
}

const initialState = {
  btnDisable: true,
  spinner: false,
  err: false
}

function BulkDiscount() {
  const modalRef = useRef(null);
  const [tableData, setTableData] = useState(false)
  const [filters, setFilters] = useState(initialFilters)
  const [state, setState] = useState(initialState)
  const [disable, setDisable] = useState(true)
  const [bankDetails, setBankDetails] = useState(false)
  const [paymentType, setPaymentType] = useState(false)
  const [operations, setOperations] = useState(false)
  const [segmentData, setSegmentData] = useState([])
  const [segmentDataOther, setSegmentDataOther] = useState([])
  const [status] = useState([{ id: true, name: 'Active' }, { id: false, name: 'Inactive' }])

  useEffect(() => {
    filters.isSearch && getTableList()
  }, [filters])

  useEffect(() => {
    getBankDetails()
    getPaymentTypes()
    getSegment()
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if(['developer_admin','super_admin','marketing_admin'].includes(data)){
        setOperations(true)
      }
    })
  }, [])

  function getTableList() {
    setState(prevState => ({ ...prevState, spinner: true }))
    let urlParam = `?page=${filters.page}&by_campaign_name=${filters.valueByCampaignName === undefined ? '' : filters.valueByCampaignName
    }&by_campaign_code=${filters.valueByCouponCode === undefined ? '' : filters.valueByCouponCode}&by_status=${filters.valueByStatus === undefined ? '' : filters.valueByStatus}&valid=${filters.valueByDate === undefined ? '' : filters.valueByDate}&by_segment=${filters.valueBySeg === undefined ? '' : filters.valueBySeg}`
    
    ads_service.get_bulk_discount(urlParam).subscribe((r) => {
      setState(prevState => ({ ...prevState, spinner: false, total: r.response.meta.total_pages * 50 }))
      setTableData(r.response.data)
    }, (err) => {
      setState(prevState => ({ ...prevState, spinner: false, err: true }))
      setTableData([])
    })
  }

  function getBankDetails(){
    ads_service.get_bank_details().subscribe((r) => {
      setBankDetails(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getSegment(){
    ads_service.get_segment().subscribe((r) => {
      let city = r?.response?.segments.filter(item => item.category === 'city')
      let other = r?.response?.segments.filter(item => item.category === 'other')
      setSegmentData(city)
      setSegmentDataOther(other)
    },
    (err)=>{
      // setSegmentData(segments)
      console.log(err)
    })
  }

  function getPaymentTypes(){
    ads_service.get_payment_type().subscribe((r) => {
      setPaymentType(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function modalSubmit(payloadData, id) {

    delete payloadData.applicable_count;
    delete payloadData?.payment_flag;
    delete payloadData?.bank_detail_id;
    if (id) {
      const payload = {
        id : id,
        body:{
          description: payloadData.description,
          success_message: payloadData.success_message,
          is_active: payloadData.is_active,
          segments_attributes_prefix_city: payloadData.segments_attributes_prefix_city,
          segments_attributes_prefix_other: payloadData.segments_attributes_prefix_other
        }
      }
      ads_service.update_bulk_discount(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Edited Successfully')
        getTableList()
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err?.response?.errors?.[0] ?? 'API Error')
        })
    } 
    else {
      const payload = {
        id: id,
        body: {
          ...payloadData,
          applicable_count: 1
        }
      }
      ads_service.create_bulk_discount(payload.body).subscribe((r) => {
        openNotificationWithIcon('success', 'Created Successfully')
        if(r.response.data){
          getTableList();
        }
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors?.[0] ?? 'API Error')
        })
    }
  }

  
  function onChangeFilters(value, type, searchNow) {
    setFilters(prevState => ({ ...prevState, [type]: value, isSearch: searchNow }))
    setState(prevState => ({ ...prevState, btnDisable: false,  }))
    setDisable(false)
  }

  function onChangeDate(date, dateString) {
    setFilters(prevState =>({...prevState, date: date, valueByDate: dateString, isSearch:false}))
    setState(prevState => ({ ...prevState, btnDisable: false,  }))
    setDisable(false)
  }

  function reset() {
    setFilters({ valueByCampaignName: undefined, valueByCouponCode:undefined,
      valueByStatus:undefined, valueBySeg: undefined, valueByDate: undefined, date: '', page: 1, isSearch: true})
    setState(prevState => ({ ...prevState, btnDisable: false, spinner:false }))
    setDisable(true)
  }

  return (
    <div className="layer-nofilter">
      {tableData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Input className="frz-filter" placeholder="Coupon Code"
                  onChange={(e) => onChangeFilters(e.target.value, 'valueByCouponCode')}
                  value={filters.valueByCouponCode} />
                <Input className="frz-filter" placeholder="Campaign Name"
                  onChange={(e) => onChangeFilters(e.target.value, 'valueByCampaignName')}
                  value={filters.valueByCampaignName}/>
                <Search placeholder="Select Status" value={filters.valueByStatus} data={status}
                        onChange={(e) => onChangeFilters(e, 'valueByStatus')}  type="valueByCategory" />
                <DatePicker value={filters.date} onChange={onChangeDate} placeholder="Date"
                           className="frz-filter"/>
                <Search className="frz-filter" placeholder="Segment" value={filters.valueBySeg}
                  onChange={(e) => onChangeFilters(e, 'valueBySeg')} type="valueBySeg" data={[...segmentData, ...segmentDataOther]} />
                <Button disabled={disable} onClick={getTableList} className="frz-w-100px frz-m-10"
                  type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} className="frz-m-10"
                  type="primary" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          <Row>
            <Col span={24}>
              <Card>
                <Button onClick={() => modalRef.current.showModal(true, 'Create Bulk Coupon')} className="frz-dwn"
                  type="primary" disabled={!operations}>Create Bulk Coupon</Button>                 
              </Card>
            </Col>
          </Row>
          {tableData.length > 0 && !state.err ?
            <Spin spinning={state.spinner}>
              <Row>
                <Col span={24}>
                  <Card className="frz-mt-5">
                    <Table dataSource={tableData} pagination={false} scroll={{ y: 'calc(100vh - 278px)' }} rowKey={'id'}
                      columns={bulkDiscountColumns((rowdata) => modalRef.current.showModal(rowdata, 'Edit Bulk Coupon'), setState, operations)}
                       />
                  </Card>
                  <Pagination current={filters.page} pageSize={50} total={state.total} className="frz-mt-5"
                    onChange={(e) => onChangeFilters(e, 'page', true)} showSizeChanger={false} />
                </Col>
              </Row>
            </Spin>
            :
            <div className="no-data">{state.err ? 'Backend API System Down' : 'No Data Available'}</div>
          }
          <BulkDiscountModal ref={modalRef} modalSubmit={modalSubmit} paymentType={paymentType}
          bankDetails={bankDetails} segmentData={segmentData} segmentDataOther={segmentDataOther} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default BulkDiscount