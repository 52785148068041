import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Radio, Table, Row, Col, InputNumber } from 'antd';
import { handleDateFormat } from "../../_modules/date";
import {
  GoogleMap,
  Marker,
  InfoBox,
  DistanceMatrixService,
  Circle,
  InfoWindow,
} from "@react-google-maps/api";
import DIcon from "../../static/images/delivered_icon.png";
import CIcon from "../../static/images/customer-add-icon.png";
import FIcon from "../../static/images/flat-add-icon.png";
import {
  svgTabOne,
  svgTabTwo,
  svgHome,
  svgFalseAlarm,
} from "../../static/images/orders_customerlocation";
import { openNotificationWithIcon } from '../../_modules/notification';
import { OrderLocationService } from "../../_services/order_location";
import { PreviousOrdersModal } from "../../components/orders/modal_previous_orders";
import mapStyle from '../../static/json/map.json'
import green from '../../static/images/green_nc.svg'
import red from '../../static/images/red_nc.svg'
import orange from '../../static/images/orange.svg'
import redWarning from '../../static/images/red_warning.svg'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

// const optionsInfoBox = { closeBoxURL: "", enableEventPropagation: true };
const options = {
  strokeColor: "#84E0BE",
  strokeOpacity: 0.1,
  strokeWeight: 0,
  fillColor: "#84E0BE",
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 200,
  zIndex: 1,
};

const orderLocationService = new OrderLocationService();

const ModalModify = ({ showModal, setShowModal, rowData, getAllOrders }) => {

  const [isPOModalVisible, setIsPOModalVisible] = useState(false);
  const [flatAddress, setFlatAddress] = useState(false);
  const [flatAddressLat, setFlatAddressLat] = useState(undefined)
  const [flatAddressLong, setFlatAddressLong] = useState(undefined)
  const [radioValue, setRadioValue] = useState(null);
  const [selectedAddressData, setSelectedAddressData] = useState(false);
  const [searchMarkerData, setSearchMarkerData] = useState(false);
  const [deliveryToCustDist, setDeliveryToCustDist] = useState(false);
  const [deliveryToFlateDist, setDeliveryToFlatDist] = useState(false)
  const [prevOrdersModalData, setPrevOrderModalData] = useState([]);
  const [deliveryAddCoordinates, setDeliveryAddCoordinates] = useState(false)
  const [userClipboard, setUserClipboard] = useState(false)
  const [collaspeOn, setCollaspeOn] = useState(false)

  useEffect(() => {
    getPreviousOrders()
    getDeliveryAddCoordinates()
  }, [])

  // useEffect(() => {
  //   readClipboard()
  // },[])

  useEffect(() => {
    if (
      flatAddress.latitude !== undefined &&
      flatAddress.longitude !== undefined
    ) {
      if (flatAddress.latitude && flatAddress.longitude) {
        setDeliveryToCustDist(false);

        setSearchMarkerData({
          latitude: +flatAddress.latitude,
          longitude: +flatAddress.longitude,
        });

        // handleRadioSelect(false, "flat_address");
      }
    }
  }, [flatAddress]);

  useEffect(() => {
    if (flatAddressLong && flatAddressLat) {
      setFlatAddress(false)
    }
  }, [flatAddressLat, flatAddressLong])

  const showPOModal = () => {
    setIsPOModalVisible(true);
  };

  const getDeliveryAddCoordinates = () => {
    const address = {
      lat: +rowData?.delivered_address?.latitude,
      long: +rowData?.delivered_address?.longitude,
    }
    orderLocationService.get_addtess_by_coordinates(address).subscribe((r) => {
      if (r.response.status == "ZERO_RESULTS") {
        openNotificationWithIcon("error", "Invalid coordinates of delivered address")
        setShowModal(false)
        return
      }
      setDeliveryAddCoordinates(r.response.results[0].formatted_address)
      // console.log("response of address", r.response.results[0].geometry.location)
    }, ((err) => {
      console.log("err", err)
    }))
  }

  const readClipboard = async () => {
    const text = await navigator.clipboard.readText();
    if (text.includes(",") && text.split(",").length === 2
      && !isNaN(text.split(",")[0]) && !isNaN(text.split(",")[1])) {
      setFlatAddressLat(text.split(",")[0])
      setFlatAddressLong((text.split(",")[1]))
      setFlatAddress({ latitude: text.split(",")[0], longitude: text.split(",")[1] })
    } else {
      openNotificationWithIcon("error", "Please copy valid coordinates")
    }
    setUserClipboard(text)
  }

  const radioOnChange = (e) => {
    if (e.target.value === "CA") {
      handleRadioSelect(true, "ca");
    } else if (e.target.value === "DA") {
      handleRadioSelect(false, "delivered_address");
    } else if (e.target.value === "FA") {
      handleRadioSelect(false, "flat_address");
    }
    setRadioValue(e.target.value);
  };

  const handleRadioSelect = (falseAlarm, correctedBy) => {
    let lat = correctedBy == "ca" ? +rowData?.customer_address?.latitude :
      correctedBy == "delivered_address" ? rowData?.delivered_address?.latitude :
        correctedBy == "flat_address" ? flatAddress?.latitude : false;

    let long = correctedBy == "ca" ? +rowData?.customer_address?.longitude :
      correctedBy == "delivered_address" ? rowData?.delivered_address?.longitude :
        correctedBy == "flat_address" ? flatAddress?.longitude : false;

    let data = {
      false_alarm: falseAlarm,
      corrected_by: correctedBy,
      latitude: lat,
      longitude: long,
    };
    setSelectedAddressData(data);
  }

  const handleOnSaveLatLong = () => {
    const data = {
      latitude: flatAddressLat,
      longitude: flatAddressLong
    }
    openNotificationWithIcon("success", "lat long updated")
    setFlatAddress(data)
  }

  const handlePOBack = () => {
    setIsPOModalVisible(false);
  };

  const handleUseThisAddress = (allData) => {
    const payload = {
      order_id: allData?.id,
      body: { ...selectedAddressData },
    };
    // console.log("payload", payload)
    //--
    if (payload.body['corrected_by'] == "ca") {
      delete payload.body['corrected_by']
    }
    orderLocationService.update_customer_address(payload).subscribe(
      (r) => {
        openNotificationWithIcon("success", "Location Rectified")
        getAllOrders()
        setShowModal()
      },
      (err) => {
        console.log("err", err);
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      }
    );
    // handleCancel()
    // getAllOrders(1);
  };

  function getPreviousOrders() {
    setPrevOrderModalData([]);
    const payload = {
      customer_id: rowData.customer_id
    };
    orderLocationService.get_prev_orders(payload).subscribe(
      (r) => {
        let data = r.response.data;
        data = data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setPrevOrderModalData(data);
      },
      (err) => {
        console.log(err);
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      }
    );
  }

  return (
    <Modal
      visible={showModal}
      title=""
      footer={null}
      okText="Create"
      cancelText="Cancel"
      width="80%"
      onCancel={() => setShowModal(false)}>
      <div>
        <div>
          <Row>
            <Col span={14}>
              <div className="frz-fw-800">
                Customer Details
              </div>
            </Col>
            <Col span={5}>
              <div className="frz-fw-800">
                Rider Details
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={3}>Date</Col>
            <Col span={3}>Name</Col>
            <Col span={3}>Mobile No.</Col>
            <Col span={5}>Order No.</Col>
            <Col span={3}>
              Name
            </Col>
            <Col span={3}>
              Mobile No.
            </Col>
          </Row>
          <Row>
            <Col span={3}>{handleDateFormat(rowData.created_at)}</Col>
            <Col span={3}>{rowData.customer_name}</Col>
            <Col span={3}>{rowData.mobile_number}</Col>
            <Col span={5}>{rowData.order_number}</Col>
            <Col span={3}>
              {rowData?.rider_info?.name}
            </Col>
            <Col span={3}>
              {rowData?.rider_info?.phone_number}
            </Col>
          </Row>
        </div>
        <div className="frz-previous-order">
          <button className="btn-prev-order" onClick={showPOModal}>
            Previous Order
          </button>
        </div>

        <div style={{ marginTop: 40 }}>
          <Radio.Group
            onChange={radioOnChange}
            value={radioValue}
            className="radio-group"
          >
            <Row>
              <Col span={12}>
                <Row className="customer-add-row">
                  <Col span={10}>
                    <div>
                      <Radio value={"CA"}>
                        <span style={{ fontWeight: "700" }}>Customer Address</span>
                      </Radio>
                      <div className="red-coordinates margin-left-15 margin-top-10">
                        <div>
                          Lat: {rowData?.customer_address?.latitude} <span>
                            <img src={redWarning} className="frz-warning-icon frz-lm-10" />
                          </span>
                        </div>
                        <div>
                          Lng: {rowData?.customer_address?.longitude}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={15}>
                    <div className="modify-flat-address-div">
                      <Radio value={"FA"}>
                        <span style={{ fontWeight: "700" }}>
                          Flat Address
                        </span>
                      </Radio>
                      {/* g-o */}
                      {/* g-r */}
                      <div className="frz-lm-24">
                        <div className="flat-address-input-div">
                          <div>
                            <label htmlFor="input-latitude">Lat: </label>
                            <InputNumber style={{ width: "200px" }} value={flatAddressLat} onChange={(e) => setFlatAddressLat(e)} />
                          </div>

                          <div>
                            <label htmlFor="input-longitude">Lng:</label>
                            <InputNumber style={{ width: "200px" }} value={flatAddressLong} onChange={(e) => setFlatAddressLong(e)} />
                          </div>
                          <div className="frz-flat-address-wrapper" onClick={() => {
                            navigator.clipboard.writeText(rowData.flat_address)
                            openNotificationWithIcon("success", "Flat address copied to clipboard")
                          }}>
                            <div className="frz-font-15">
                              {rowData.flat_address}
                            </div>
                            <div className="frz-label">
                              Click here to copy address
                            </div>
                          </div>
                          <span className="frz-auto-fill" onClick={() => readClipboard()}>Auto fill lat long</span>
                          <div className="frz-tm-10 frz-dis-flex">
                            {/* <div className="frz-copy-long frz-rm-10" onClick={() => {
                              navigator.clipboard.writeText(rowData.flat_address)
                              openNotificationWithIcon("success", "Flat address copied to clipboard")
                            }}>
                              Copy flat address
                            </div> */}
                            <div className={(flatAddressLat && flatAddressLong) ?
                              "frz-save-lat-long" : "frz-save-lat-long-dis"}
                              onClick={() => (flatAddressLat && flatAddressLong) ? handleOnSaveLatLong() : null}>
                              Save Lat:Lng
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Radio value={"DA"}></Radio>
                <div className="map-div">
                  <div className={!collaspeOn ? "map-delivered-location-div-off" : "map-delivered-location-div"}>
                    <div onClick={() => setCollaspeOn(!collaspeOn)} className="frz-icon-collaspse">
                      {!collaspeOn ? <CaretDownOutlined />
                        : <CaretUpOutlined />}
                    </div>
                    <Row>
                      <Col span={8}>
                        <h3>Delivered Location</h3>
                      </Col>
                      <Col span={16}>
                        <div className="delivered-location-flex">
                          <div className="green-coordinates">
                            <div className="text-align-left">
                              Lat: {rowData?.delivered_address?.latitude}
                            </div>
                            <div className="text-align-left">
                              Lng: {rowData?.delivered_address?.longitude}
                            </div>
                          </div>
                          <div className="svgHome">{svgHome()}</div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <div className="only-one-line" >{deliveryAddCoordinates}</div>
                      </Col>
                    </Row>
                  </div>
                  <div className="google-map-div">
                    <GoogleMap
                      zoom={16}
                      styles={mapStyle}
                      center={{
                        lat: +rowData?.delivered_address?.latitude,
                        lng: +rowData?.delivered_address?.longitude,
                      }}
                      options={{
                        scaleControl: true,
                        zoomControl: true,
                        mapTypeControl: false,
                        streetViewControl: false,
                        rotateControl: true,
                        fullscreenControl: false,
                        styles: mapStyle
                      }}
                      mapContainerClassName="map-container"
                    >
                      <Marker
                        className="customer-address-marker"
                        position={{
                          lat: +rowData?.customer_address?.latitude,
                          lng: +rowData?.customer_address?.longitude,
                        }}
                        icon={red}
                      />

                      {flatAddress ?
                        <Marker
                          position={{
                            lat: +searchMarkerData?.latitude,
                            lng: +searchMarkerData?.longitude,
                          }}
                          icon={orange}
                        />
                        : null}

                      <Marker
                        position={{
                          lat: +rowData?.delivered_address?.latitude,
                          lng: +rowData?.delivered_address?.longitude,
                        }}
                        icon={green}
                      ></Marker>

                      <Circle
                        center={{
                          lat: +rowData?.delivered_address?.latitude,
                          lng: +rowData?.delivered_address?.longitude,
                        }}
                        options={options}
                      />

                      <DistanceMatrixService
                        options={{
                          destinations: [
                            {
                              lat: +rowData?.delivered_address?.latitude,
                              lng: +rowData?.delivered_address?.longitude,
                            },
                          ],
                          origins: [
                            {
                              lat: +rowData?.customer_address?.latitude,
                              lng: +rowData?.customer_address?.longitude,
                            },
                          ],
                          travelMode: "DRIVING",
                        }}
                        callback={(response) => {
                          if (!deliveryToCustDist) {
                            setDeliveryToCustDist(
                              response?.rows[0]?.elements[0]?.distance?.text
                            );
                          }
                        }}
                      />

                      <DistanceMatrixService
                        options={{
                          destinations: [
                            {
                              lat: +rowData?.delivered_address?.latitude,
                              lng: +rowData?.delivered_address?.longitude,
                            },
                          ],
                          origins: [
                            {
                              lat: +flatAddress?.latitude,
                              lng: +flatAddress?.longitude,
                            },
                          ],
                          travelMode: "DRIVING",
                        }}
                        callback={(response) => {
                          if (flatAddress) {
                            setDeliveryToFlatDist(
                              response?.rows[0]?.elements[0]?.distance?.text
                            );
                          }
                        }}
                      />

                      <InfoWindow
                        options={{
                          pixelOffset: ({ width: 0, height: -20 })
                        }}
                        // options={optionsInfoBox}
                        position={{
                          lat: +rowData?.customer_address?.latitude,
                          lng: +rowData?.customer_address?.longitude,
                        }}
                      >
                        <div style={{ paddingLeft: 0, background: "#fff", borderRadius: 4, textAlign: "center" }}>
                          <div
                            style={{
                              fontSize: 16,
                              fontColor: `#000`,
                              fontWeight: "800",
                            }}
                          >
                            {deliveryToCustDist}
                          </div>
                        </div>
                      </InfoWindow>
                      {flatAddress ?
                        <InfoWindow
                          options={{
                            pixelOffset: ({ width: 0, height: -32 })
                          }}
                          position={{
                            lat: +flatAddress?.latitude,
                            lng: +flatAddress?.longitude,
                          }}
                        >
                          <div style={{ paddingLeft: 0, background: "#fff", borderRadius: 4, textAlign: "center" }}>
                            <div
                              style={{
                                fontSize: 16,
                                fontColor: `#000`,
                                fontWeight: "800",
                              }}
                            >
                              {deliveryToFlateDist}
                            </div>
                          </div>
                        </InfoWindow>
                        : null}

                    </GoogleMap>
                  </div>
                </div>
              </Col>
            </Row>
          </Radio.Group>
        </div>

        <div className="modify-footer-div">
          <button
            className={selectedAddressData ? selectedAddressData.corrected_by === "flat_address" &&
              (flatAddressLat && flatAddressLong && flatAddress) ?
              "btn-use-add" : selectedAddressData.corrected_by === "ca" ? "btn-use-add" :
                selectedAddressData.corrected_by == "delivered_address" ? "btn-use-add" : "btn-use-add-dis" : "btn-use-add-dis"}
            onClick={selectedAddressData ? selectedAddressData.corrected_by === "flat_address" &&
              (flatAddressLat && flatAddressLong && flatAddress) ?
              () => handleUseThisAddress(rowData) : selectedAddressData.corrected_by === "ca" ? () => handleUseThisAddress(rowData) :
                selectedAddressData.corrected_by == "delivered_address" ? () => handleUseThisAddress(rowData)
                  : null : null}>
            Use this Address
          </button>
        </div>
        {isPOModalVisible ?
          <PreviousOrdersModal
            isPOModalVisible={isPOModalVisible}
            handlePOBack={handlePOBack}
            prevOrdersModalData={prevOrdersModalData}
            handleDateFormat={handleDateFormat}
          />
          : null}
      </div>
    </Modal>
  )
}

export default ModalModify