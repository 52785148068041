import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button, Select, Input, InputNumber,
         Radio} from 'antd';
const { Option } = Select;

const ForecastingModal = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOk() {
    setLoading(true)
    props.modalSubmit()
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.onCancelModal();
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onOk={handleOk}
          onCancel={handleCancelModal}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              Submit
            </Button>,
          ]}
        >
          <div className="block" style={{marginLeft: 44}}>Tp Increment % :
            <span style={{paddingLeft:10}}>
                <InputNumber placeholder="Tp Increment %"
                    value={props.tp_increment_percentage} style={{width:200}}
                    onChange={(e) => props.onChangeModal(e, 'tp_increment_percentage')}/>
            </span>
          </div>
          <div className="block" style={{marginLeft: 66}}>Tp Available :
            <span style={{paddingLeft:10}}>
              <Radio.Group
                     value={props.tp_available_flag}
                     onChange={(e) => props.onChangeModal(e.target.value, 'tp_available_flag')}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </span>
          </div>
          <div className="block" style={{marginLeft: 30}}>Organic Growth % :
            <span style={{paddingLeft:10}}>
                <InputNumber placeholder="Organic Growth %"
                    value={props.organic_growth_percentage} style={{width:200}}
                    onChange={(e) => props.onChangeModal(e, 'organic_growth_percentage')}/>
            </span>
          </div>
          <div className="block" style={{marginLeft:44}}>Organic Growth :
            <span style={{paddingLeft:10}}>
              <Radio.Group
                     value={props.organic_growth_flag}
                     onChange={(e) => props.onChangeModal(e.target.value, 'organic_growth_flag')}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </span>
          </div>
          <div className="block">Marketing Increment % :
            <span style={{paddingLeft:10}}>
                <InputNumber placeholder="Marketing Increment %"
                    value={props.marketing_increment} style={{width:200}}
                    onChange={(e) => props.onChangeModal(e, 'marketing_increment')}/>
            </span>
          </div>
        </Modal>
      : null }
    </div>
  )
})

export default ForecastingModal
