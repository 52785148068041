import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Button, Select, Input, InputNumber, DatePicker,
         Radio, Popconfirm} from 'antd';
import { useSelector } from "react-redux";
import Search from '../antd/search';
const { Option } = Select;
const { TextArea } = Input;

const DiscountModal = forwardRef((props, ref) => {
  const darkstore = useSelector(state => state.auth.darkstore);
  const customerTypeData = useSelector(state => state.auth.customer_type);
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [segmentType, setSegmentType] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
      setSegmentType((data?.segments_attributes_prefix_city?.length || data?.segments_attributes_prefix_other?.length) > 0 ? 'select_segment' : 'all_users')
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

 useEffect(() => {
     if(props.multiple_count){
         updateIsMultipleCount()
        }
      if(props.multiple_count === null){
        props.onChangeModal(false, 'isMultipleCount')
      }
 }, [props.multiple_count]);

  function handleOk() {
    setLoading(true)
    props.modalSubmit(segmentType)
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.onCancelModal();
  }

  //if raw data has value of multiple count then set ismultiple to true
  function updateIsMultipleCount(){
    if(props.multiple_count!==undefined){
        props.onChangeModal(true, 'isMultipleCount')
    }
}  

  return (
    <div>
      {data && customerTypeData?
        <Modal
          visible={visible}
          title={title}
          onOk={handleOk}
          width='50%'
          onCancel={handleCancelModal}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            (title === 'Create Customer Discount' ? 
              <Popconfirm
              title="Are you sure you want to create this coupon, once created you wont be able to cancel or edit ?"
              onConfirm={handleOk}
              okText="Yes, Create Coupon"
              cancelText="Cancel"
              placement="leftTop"
            >
              <Button type="primary" loading={loading} key="submit">
                Submit
              </Button>
            </Popconfirm>
            :
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              Submit
            </Button>
            ),
          ]}
        > 
        {title === 'Create Product Discount' || title === 'Edit Product Discount' ?
          <div className="block" style={{marginLeft:57}}>Variant <span style={{color: 'red'}}>* &nbsp;</span> :
            <span style={{paddingLeft:10}}>
              <Radio.Group disabled={title === 'Edit Product Discount'}
                      value={props.variant}
                      onChange={(e) => props.onChangeModal(e.target.value, 'variant')}>
                  <Radio value='bulk_discount'>Bulk discount </Radio>
                  <Radio value='non_bulk_discount'>Non bulk discount </Radio>
              </Radio.Group>
            </span>
          </div>
        : null}
        {title === 'Create Product Discount' || title === 'Edit Product Discount' ?
          <div className="block" style={{marginLeft:42}}>Is Coupon <span style={{color: 'red'}}>* &nbsp;</span> :
            <span style={{paddingLeft:10}}>
              <Radio.Group defaultValue={false}
                  disabled={props.variant === 'non_bulk_discount'} 
                  value={props.is_coupon}
                  onChange={(e) => props.onChangeModal(e.target.value, 'is_coupon')}>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
              </Radio.Group>
            </span>
          </div>
        : null}
        {title !== 'Create Customer Discount' && props.is_coupon ? 
          <div className="block" style={{marginLeft:24}}>Coupon Code<span style={{color: 'red'}}>* &nbsp;</span> :
              <span style={{paddingLeft:10}}>
                  <Input placeholder="Coupon Code"
                      value={props.valueByCoupon} style={{width:200}}
                      onChange={(e) => props.onChangeModal(e.target.value, 'valueByCoupon')}/>
              </span>
          </div>
        : null}
        {/* {props?.variant !== 'non_bulk_discount' ? 
          title === 'Create Cashback' || title === 'Create Product Discount' || title === 'Create Discount' ? 
            <div className="block" style={{marginLeft:15}}>Customer Type<span style={{color: 'red'}}>* &nbsp;</span> :
              <span style={{paddingLeft:10}}>
                <Select style={{ width: 430}} value={props.customer_type} showSearch
                        placeholder="Customer Type" onChange={(e) => props.onChangeModal(e, 'customer_type')}>
                  <Option key='none' value='none'>None</Option>
                  {customerTypeData.map(data => {
                    return(
                      <Option key={data.id} value={data.id}>
                        {data.description}
                      </Option>
                    )
                  })}
                </Select>
              </span>
            </div>
          : null
        : null} */}
        {title === 'Edit Cashback' || title === 'Edit Product Discount' || title === 'Edit Discount' ? 
          <div className="block" style={{marginLeft:18}}>Customer Type &nbsp; :
            <span style={{paddingLeft:10}}>{data.customer_tag_description}</span>
          </div>
        : null}
        {title === 'Edit Product Discount' || title === 'Create Product Discount' ? 
          <div className="block" style={{marginLeft:54}}>Product <span style={{color: 'red'}}>* &nbsp;</span> :
            <span style={{paddingLeft:10}}>
            <Select style={{ width: 400}} value={props.valueByProduct} 
                    showSearch
                    placeholder="Select Product" onChange={(e) => props.onChangeModal(e, 'valueByProduct')}
                    filterOption={(input, option) =>
                        option.props.skuName.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
              {props.productData.map(data => {
                return(
                  <Option key={data.id} value={data.id} skuName={data.name +' - ('+data.sku+')'}>{data.name} - ({data.sku})</Option>
                )
              })}
            </Select>
            </span>
          </div>
        : null}
        {/* {props?.variant !== 'non_bulk_discount' ?  */}
        <div className="block" style={{marginLeft:74}}>Title <span style={{color: 'red'}}>* &nbsp;</span> :
            <span style={{paddingLeft:10}}>
                <Input placeholder="Title"
                    value={props.title} style={{width:200}}
                    onChange={(e) => props.onChangeModal(e.target.value, 'title')}/>
            </span>
        </div>
        {/* : null} */}
        <div className="block" style={{marginLeft:30}}>Description <span style={{color: 'red'}}>* &nbsp;</span> :
            <span style={{paddingLeft:10}}>
                <Input placeholder="Description"
                    value={props.description} style={{width:200}}
                    onChange={(e) => props.onChangeModal(e.target.value, 'description')}/>
            </span>
        </div>
        {props?.variant !== 'non_bulk_discount' ? 
          title !== 'Create Customer Discount' ?
            <div className="block" style={{marginLeft:25}}>Success Msg <span style={{color: 'red'}}>* &nbsp;</span> :
                <span style={{paddingLeft:10}}>
                    <Input placeholder="Success Msg"
                        value={props.success_message} style={{width:200}}
                        onChange={(e) => props.onChangeModal(e.target.value, 'success_message')}/>
                </span>
            </div>
          : null 
        : null}
        <div className="block" style={{marginLeft:10}}>Discount Value <span style={{color: 'red'}}>* &nbsp;</span> :
            <span style={{paddingLeft:10}}>
                <InputNumber placeholder="Discount Value"
                    value={props.discountValue} style={{width:200}}
                    onChange={(e) => props.onChangeModal(e, 'discountValue')}/>
            </span>
        </div>
        <div className="block" style={{marginLeft:16}}>Discount Type <span style={{color: 'red'}}>* &nbsp;</span> :
            <span style={{paddingLeft:10}}>
            <Select style={{ width: 200}} showSearch 
                    disabled={props.variant === 'non_bulk_discount'} 
                    value={props.discountType}
                    placeholder="Discount Type" onChange={(e) => props.onChangeModal(e, 'discountType')}>
                <Option value='percentage'>Percentage</Option>
                <Option value='value'>Value</Option>
            </Select>
            </span>
        </div>   
        {props.is_coupon ?  
          <div className="block" style={{marginLeft:6}}>Min Order Value <span style={{color: 'red'}}>* &nbsp;</span> :
              <span style={{paddingLeft:10}}>
                  <InputNumber placeholder="Min Order Value"
                      value={props.min_order} style={{width:200}}
                      onChange={(e) => props.onChangeModal(e, 'min_order')}/>
              </span>
          </div>
        : null}
        {title === 'Edit Product Discount' || title === 'Create Product Discount' ? 
          <div>
            <div className="block" style={{marginLeft:24}}>Discount Qty <span style={{color: 'red'}}>* &nbsp;</span> :
              <span style={{paddingLeft:10}}>
                  <InputNumber placeholder="Discount Qty"
                      value={props.discount_qty} style={{width:200}}
                      onChange={(e) => props.onChangeModal(e, 'discount_qty')}/>
              </span>
            </div>
            {props.is_coupon ?
              <div className="block">Product Discount <span style={{color: 'red'}}>* &nbsp;</span> :
                <span style={{paddingLeft:10}}>
                <Select style={{ width: 200}} value={props.productDiscountType} showSearch
                        placeholder="Product Discount Type" onChange={(e) => props.onChangeModal(e, 'productDiscountType')}>
                    <Option value='one_plus_one'>One + One</Option>
                    <Option value='free_product'>Free Product</Option>
                    <Option value='flat_discount'>Flat Discount</Option>
                </Select>
                </span>
              </div>
            : null}
          </div>  
          : 
          <div className="block" style={{marginLeft:16}}>Max Order Value :
            <span style={{paddingLeft:10}}>
                <InputNumber placeholder="Max Order Value"
                    value={props.max_order} style={{width:200}}
                    onChange={(e) => props.onChangeModal(e, 'max_order')}/>
            </span>
          </div>
        }
        {title !== 'Create Customer Discount' ?
          <div className="block" style={{marginLeft:52}}>Is Active <span style={{color: 'red'}}>* &nbsp;</span> :
            <span style={{paddingLeft:10}}>
              <Radio.Group
                      value={props.valueByRadioActive}
                      onChange={(e) => props.onChangeModal(e.target.value, 'valueByRadioActive')}>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
              </Radio.Group>
            </span>
          </div>
        : null} 
        {title !== 'Edit Discount' && title !== 'Create Discount' && title !== 'Create Customer Discount' && props.is_coupon ?
          <div className="block" style={{marginLeft:14}}>Is Auto Applied <span style={{color: 'red'}}>* &nbsp;</span> :
            <span style={{paddingLeft:10}}>
              <Radio.Group
                      value={props.valueByRadioAuto}
                      onChange={(e) => props.onChangeModal(e.target.value, 'valueByRadioAuto')}>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
              </Radio.Group>
            </span>
          </div>
        : null}
        {title !== 'Create Customer Discount' && props.is_coupon ? 
          <div className="block">Applicable Count <span style={{color: 'red'}}>* &nbsp;</span> :
              <span style={{paddingLeft:10}}>
                  <InputNumber placeholder="Applicable Count"
                      value={props.applicableCount} style={{width:200}}
                      onChange={(e) => props.onChangeModal(e, 'applicableCount')}/>
              </span>
          </div>
        : null}
        <div className="block" style={{marginLeft:38}}>Valid From <span style={{color: 'red'}}>* &nbsp;</span> :
            <span style={{paddingLeft:10}}>
                <DatePicker value={props.valid_from_date} onChange={(e, f) => props.onChangeDate(e, f, 'valid_from_date', 'valid_from')} 
                            placeholder="Valid From Date"
                            style={{width: 200}}/>
            </span>
        </div>
        <div className="block" style={{marginLeft:56}}>Valid To <span style={{color: 'red'}}>* &nbsp;</span> :
            <span style={{paddingLeft:10}}>
                <DatePicker value={props.valid_to_date} onChange={(e, f) => props.onChangeDate(e, f, 'valid_to_date', 'valid_to')} 
                            placeholder="Valid To Date"
                            style={{width: 200}}/>
            </span>
        </div>
        {title !== 'Create Customer Discount' ? 
        <div>
          {props.is_coupon ?          
            <div className="block" style={{marginLeft:52}}>Is Visible <span style={{color: 'red'}}>* &nbsp;</span> :
                <span style={{paddingLeft:10}}>
                <Radio.Group
                        value={props.valueByRadio}
                        onChange={(e) => props.onChangeModal(e.target.value, 'valueByRadio')}>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                </Radio.Group>
                </span>
            </div> 
          : null}
          {/* {props.is_coupon ?
            <div className="block" style={{marginLeft: 25}}>Is First Order <span style={{color: 'red'}}>* &nbsp;</span> :
                <span style={{paddingLeft:10}}>
                <Radio.Group
                        value={props.valueByRadioFirst}
                        onChange={(e) => props.onChangeModal(e.target.value, 'valueByRadioFirst')}>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                </Radio.Group>
                </span>
            </div>
          : null} */}
          {props.is_coupon ? 
            <div className="block" style={{marginLeft: 2}}>Society Discount <span style={{color: 'red'}}>* &nbsp;</span> :
              <span style={{paddingLeft:10}}>
                <Radio.Group
                        value={props.is_society_discount}
                        onChange={(e) => props.onChangeModal(e.target.value, 'is_society_discount')}>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                </Radio.Group>
              </span>
            </div>
          : null}
          {title === 'Create Cashback' || title === 'Edit Cashback' ?
            <div className="block" style={{marginLeft:15}}>Is Multiple Count :
              <span style={{paddingLeft:10}}>
                <Radio.Group  
                        value={props.isMultipleCount}
                        onChange={(e) => props.onChangeModal(e.target.value, 'isMultipleCount')}>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                </Radio.Group>
              </span>
            </div>
          : null}
          {props?.variant !== 'non_bulk_discount' ? 
            <div className="block" style={{marginLeft:30}}>Activation Day :
              <span style={{paddingLeft:10}}>
                <Select mode="multiple" style={{ width: 300}} showSearch
                        value={props.valueByActivation}
                        placeholder="Select Activation Day (Optional)" onChange={(e) => props.onChangeModal(e, 'valueByActivation')}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  <Option value="Monday" key="Monday">Monday</Option>
                  <Option value="Tuesday" key="Tuesday">Tuesday</Option>
                  <Option value="Wednesday" key="Wednesday">Wednesday</Option>
                  <Option value="Thursday" key="Thursday">Thursday</Option>
                  <Option value="Friday" key="Friday">Friday</Option>
                  <Option value="Saturday" key="Saturday">Saturday</Option>
                  <Option value="Sunday" key="Sunday">Sunday</Option>
                </Select>
              </span>
            </div>
          : null}
        </div>
        :null}
        {title === 'Create Cashback' || title === 'Edit Cashback' ? 
         
          props.isMultipleCount ? 
          <>
          <div className="block" style={{marginLeft: 28}}><span style={{color: 'red'}}>* &nbsp;</span>Multiple Count :
            <span style={{paddingLeft:10}}>
              <InputNumber placeholder="Multiple Count"
                  value={props.multiple_count} style={{width:200}}
                  onChange={(e) => props.onChangeModal(e, 'multiple_count')}/>
            </span>
          </div> 
            <div className="block" style={{marginLeft: 6}}><span style={{color: 'red'}}>* &nbsp;</span>Child Coupon Desc:
             <span style={{paddingLeft:10}}>
              <Input placeholder="Child Coupon Description"
                  value={props.child_coupon_desc} style={{width:200}}
                  onChange={(e) => props.onChangeModal(e.target.value, 'child_coupon_desc')}/>
             </span>
            </div> 
            </> : null                      
        : null}
          {props.is_coupon ? 
            <div className="block" style={{marginLeft:8}}>Banking Coupon <span style={{color: 'red'}}>* &nbsp;</span> :
                <span style={{paddingLeft:10}}>
                <Radio.Group
                        value={props.paymentFlag}
                        onChange={(e) => props.onChangeModal(e.target.value, 'paymentFlag')}>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                </Radio.Group>
                </span>
            </div>
          : null}
          {props.paymentFlag ? 
            <>
              <div className="layer" style={{marginLeft:46}}>Select Bank <span style={{color: 'red'}}>* &nbsp;</span> :
                <Search placeholder="Bank Details" value={props.bankDetailId} data={props.bankDetails}
                        onChange={(e) => props.onChangeModal(e, 'bankDetailId')} type="valueByStatus"/>
              </div>
              <div className="layer" style={{marginLeft:32}}>Payment Type <span style={{color: 'red'}}>* &nbsp;</span> :
                <Search placeholder="Payment Type" value={props.paymentTypeId} data={props.paymentType}
                        onChange={(e) => props.onChangeModal(e, 'paymentTypeId')} type="valueByStatus"/>
              </div>
            </>
          : null  
          }
          <div className="block" style={{ marginLeft: 34 }}>Segment Type :
            <span style={{ paddingLeft: 10 }}>
              <Radio.Group
                value={segmentType}
                onChange={(e) => setSegmentType(e.target.value)}>
                <Radio value={'all_users'}>All Users</Radio>
                <Radio value={'select_segment'}>Select Segment</Radio>
              </Radio.Group>
            </span>
          </div>
          {title !== 'Create Customer Discount' && segmentType === 'select_segment' ?
            <>
              <div className="block" style={{ marginLeft: 38 }}>Segment City :
                <span style={{ paddingLeft: 10 }}>
                  <Select mode="multiple" style={{ width: 400 }}
                    value={props.valueBySegment}
                    placeholder="Select Segment City" onChange={(e) => props.onChangeModal(e, 'valueBySegment')}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {props?.segmentData?.map((data, index) => {
                      return (
                        <Option value={data.slug} key={index}>{data.name}</Option>
                      )
                    })}
                  </Select>
                </span>
              </div>
              <div className='frz-segment-and' style={{textAlign: 'left', padding: '10px 300px'}}>AND</div>
              <div className="block" style={{ marginLeft: 28 }}>Segment Other :
                <span style={{ paddingLeft: 10 }}>
                  <Select mode="multiple" style={{ width: 400 }}
                    value={props.valueBySegmentOther}
                    placeholder="Select Segment Other" onChange={(e) => props.onChangeModal(e, 'valueBySegmentOther')}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {props?.segmentDataOther?.map((data, index) => {
                      return (
                        <Option value={data.slug} key={index}>{data.name}</Option>
                      )
                    })}
                  </Select>
                </span>
              </div>
            </>
            : null
          // : (
          //     <>
          //       <div className="block" style={{marginLeft:34}}>All Darkstores :
          //         <span style={{paddingLeft:10}}>
          //           <Radio.Group
          //                 value={props.valueByRadioDS}
          //                 onChange={(e) => props.onChangeModal(e.target.value, 'valueByRadioDS')}>
          //             <Radio value={true}>Yes</Radio>
          //             <Radio value={false}>No</Radio>
          //           </Radio.Group>
          //         </span>
          //       </div>
          //       {props.valueByRadioDS === false? 
          //           <div className="block" style={{marginLeft:52}}>Darkstores :
          //             <span style={{paddingLeft:10}}>
          //               {darkstore ?   
          //                 <Select mode="multiple" style={{ width: 400}}
          //                         value={props.valueByDarkstore}
          //                         placeholder="Select Darkstore" onChange={(e) => props.onChangeModal(e, 'valueByDarkstore')}
          //                         filterOption={(input, option) =>
          //                             option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
          //                     {darkstore.map(data => {
          //                         return(
          //                             <Option value={data.id} key={data.id}>{data.name}</Option>
          //                         )
          //                     })}
          //                 </Select>
          //               : null
          //               }
          //             </span>
          //           </div>
          //         : null}
          //       </>
          //     )
            }
        </Modal>
      : null }
    </div>
  )
})

export default DiscountModal
