import React, { useEffect, useState } from "react";
import { Table, Spin, Button, Row, Input, Col, Card } from "antd";
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { darkstorePriceColumns } from "../../../antd/columns/master/darkstore_price";

const DarkstorePriceUpdate = ({ showModal, cityWiseDs, activeCity, darkstoreSpinEnable }) => {
  const [darkstoreColumns, setdarkstoreColumns] = useState([]);
  const [dsList, setDsList] = useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);
  const handleChange = event => { setSearchTerm(event.target.value); };

  useEffect(() => {
    getColumns()
    getDs()
  }, [cityWiseDs])

  useEffect(() => {
    if (dsList) {
      const results = dsList.filter(data =>
        data.darkstore_detail.name.toLowerCase().includes(searchTerm)
      );
      setSearchResults(results);
    }
  }, [searchTerm]);

  const getDs = () => {
    if (cityWiseDs) {
      setSearchTerm("");
      setDsList(cityWiseDs)
      setSearchResults(cityWiseDs);
    } else {
      setDsList([])
    }
  }

  function getColumns() {
    let col = [];
    darkstorePriceColumns.map(data => {
      col.push(data)
    })
    col.push(
      {
        title: 'Edit',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (<Button title="Edit Details" icon={<EditOutlined />}
            onClick={(e) => {
              showModal(record, 'Edit Single DarkStore')
            }} >
          </Button>)
        }
      })
    setdarkstoreColumns(col);
  }

  return (
    <div>
      {cityWiseDs && !darkstoreSpinEnable?
        <>
        <Card>
          <Row>
            <Col className="frz-tm-5  frz-lp-10" span={7}>
              <h3>{activeCity}</h3>
            </Col>
            <Col>
              <Input name="search" value={searchTerm} onChange={handleChange}
                placeholder="Search Darkstore" prefix={<SearchOutlined />} style={{ width: 200 }} />
            </Col>
          </Row>
          {cityWiseDs.length > 0 ?
            <Table columns={darkstoreColumns}
              dataSource={searchResults ? searchResults : cityWiseDs} pagination={false}
              scroll={{ y: 'calc(100vh - 240px)' }} rowKey="id"
            /> :<div className="no-data">No Data Available</div>
          }
          </Card>
        </>
        :<div className="spin-center">
        <Spin tip="...Loading" />
      </div>
      }
    </div>
  )
};

export default DarkstorePriceUpdate;