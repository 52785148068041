import React, { useEffect, useState } from 'react'
import { Button, Modal, Form, } from 'antd';
import FRZInput from '../../../_controls/FRZInput';
import FRZSelect from '../../../_controls/FRZSelect';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZDatepicker from '../../../_controls/FRZDatepicker';
import FRZRadioButton from '../../../_controls/FRZRadioButton';
import moment from 'moment';

const ModalState = ({ showModal, handleModalShow, onSubmit, rowData, loading, currentTab, stateList }) => {
  const [form] = Form.useForm();

  const onCancel = () => {
    handleModalShow(false)
  }

  const onCreate = (value) => {
    onSubmit(value, currentTab)
  }
  return (
    <div>
      <Modal
        visible={showModal}
        title={rowData ? `Update ${currentTab == 1 ? "State" : "City"}` : `Create ${currentTab == 1 ? "State" : "City"}`}
        okText={rowData ? "Update" : "Create"}
        cancelText="Cancel"
        onCancel={onCancel}
        okButtonProps={{ loading: loading }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onCreate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          name="form"
          {...formItemLayout}
          layout="horizontal"
          initialValues={rowData ? { ...rowData, state_id: rowData?.state?.id } : null}>

          {currentTab == 1 ?
            <>
              <FRZInput name="name" isRequired={true}
                label="Name" placeholder="Name" width={200} />

              <FRZInput name="code" isRequired={true}
                label="Code" placeholder="Code" width={200} />

              <FRZInput name="gst_no" isRequired={true}
                label="GST No" placeholder="GST No" width={200} />

              <FRZInput isTextArea={true} name="address" isRequired={true}
                label="Address" placeholder="Address" width={200} />
            </>
            :
            <>

              <FRZInput name="name" isRequired={true}
                label="Name" placeholder="Name" width={200} />

              <FRZSelect DropDownData={stateList} name="state_id" isRequired={true}
                label="State" placeholder="Select State" value="id" option="name"
                isShowSearch={true} width={200} />


              <FRZInput name="code" isRequired={true}
                label="Code" placeholder="Code" width={200} />

              <FRZRadioButton name="is_active" label="Is active" isRequired={true} />

            </>
          }


        </Form>
      </Modal>
    </div>
  )
}

export default ModalState
