import React from 'react';
import { render } from 'react-dom';
import './static/index.css';
import { createStore } from 'redux';
import rootReducer from './_reducers';
import Root from './root';

const store = createStore(rootReducer)

render(<Root store={store} />, document.getElementById('root'))
