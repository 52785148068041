import React ,{useState,useEffect} from "react"
import { InputNumber, Select, Table, Tooltip } from "antd"
import { deliveryItemColumns } from '../antd/columns/orders';
import { InfoCircleOutlined } from '@ant-design/icons';
const { Option } = Select;
const RefundTable=({props,states,setStates})=>{
  const [refundColumns,setRefundColumns]= useState([]);
  const [updateCols,setUpdateCols]= useState(false);
  const [childCol,setChildCol] = useState([]);
  const [childUpdate,setChildUpdate] = useState(false)
  const [listData,setListData] = useState([])
  const [updateRender,setUpdateRender] = useState(false);
  const [showBad,setShowBad]=useState(false)


    useEffect(() => {
        getSorted()
        getColumns()
        setUpdateRender(true)
      }, [])
    function getSorted(){
      let combo_item=[];
      let single_item=[];
      let items=props?.orderDetails?.delivery_line_items;
      for(let i=0;i<items?.length;i++){
        if(items[i].combo_skus?.length>0){
          combo_item.push(items[i]);
        }else{
          single_item.push(items[i]);
        }
      }
      let both=[...single_item,...combo_item];
      setListData(both)
    }
    function getColumns() {
        let temp = []
        deliveryItemColumns.map(data => {
          temp.push(data)
        })
        temp.unshift({
            title: "Refund" + ' Qty',
            dataIndex: '',
            key: '',
            width: 150,
            align: 'center',
            render: (record) => {
              return (record.is_a_refundable_list ? 
                  <Select placeholder="Qty" value={states['qty' + record.id]}
                          disabled={!record.is_refundable}
                          onChange={(e) => onChange(e, 'refundQty', record.id)}
                          style={{ width: 70 }}>
                    {Array(record.max_refundable_units).fill().map((data, index) => {
                      return <Option key={index+1} value={index+1}>{index+1}</Option>
                    })}
                  </Select>
                :
                  <InputNumber placeholder="Qty"
                    value={states['qty' + record.id]}
                    disabled={!record.is_refundable}
                    onChange={(e) => onChange(e, 'refundQty', record.id)}
                    style={{ width: 50 }} />
              )
            }
          },
            {
              title: 'Refund' + ' Reason',
              dataIndex: '',
              key: '',
              width: 210,
              align: 'center',
              render: (record) => {
                return (
                  <>
                    <Select style={{ width: 180 }}
                      placeholder="Select Reason"
                      value={states['reason' + record.id]}
                      disabled={!record.is_refundable}
                      onChange={(e) => onChange(e, 'reason', record.id)}>
                      {props.refundReason.map(data => {
                        return (
                          <Option key={data.id} value={data.id}>
                            {data.name}
                          </Option>
                        )
                      })}
                      <Option key='no_refund' value='no_refund'>No Refund</Option>
                    </Select>
                  </>
                )
              }
            })
          setRefundColumns(temp)
          setChildCol(temp)
      }
    
    function onChange(value, type, id,typeofcol) {
      if(value===2){
        setShowBad(true)
      }
        if (type === 'refundQty') {
          let stateTypeQty = 'qty' + id
          setStates(prevState => ({ ...prevState, [stateTypeQty]: value === "" ? undefined : value }))
        } else {
          let stateTypeReason = 'reason' + id
          let reviewReason = 'reviewreason' + id
          setStates(prevState => ({ ...prevState, [stateTypeReason]: value === "" ? undefined : value, [reviewReason]: undefined }))
        }
        if (type !== 'refundQty') {
          setUpdateCols({ ['reason' + id]: value })
          setChildUpdate({ ['reason' + id]: value })
        } else {
          setUpdateCols({ ['qty' + id]: value })
          setChildUpdate({ ['reason' + id]: value })
        }
      }

    function onChangeReviewReason(value, type, id,typeofcol) {
        let stateTypeReason = 'reviewreason' + id
        setStates(prevState => ({ ...prevState, [stateTypeReason]: value === "" ? undefined : value }))
      }

    useEffect(() =>{
      if(updateRender){
        let temp=[];
          refundColumns.map((data)=>{
            temp.push(data);
          })
          temp.splice(0, 1, {
            title: "Refund Qty",
            dataIndex: '',
            key: '',
            width: 150,
            align: 'center',
            render: (record) => {
              return (record.is_a_refundable_list ? 
                <Select placeholder="Qty" value={states['qty' + record.id]}
                        disabled={!record.is_refundable}
                        hidden={record?.combo_skus?.length>0}
                        onChange={(e) => onChange(e, 'refundQty', record.id,'parent')}
                        style={{ width: 70 }}>
                  {Array(record.max_refundable_units).fill().map((data, index) => {
                    return <Option key={index+1} value={index+1}>{index+1}</Option>
                  })}
                </Select>
              :
                <InputNumber placeholder="Qty"
                  value={states['qty' + record.id]}
                  disabled={!record.is_refundable}
                  onChange={(e) => onChange(e, 'refundQty', record.id,'parent')}
                  style={{ width: 50 }} />
              )
            }
          })
          temp.splice(1, 1, {
            title: "Refund Reason",
            dataIndex: '',
            key: '',
            width: 210,
            align: 'center',
            render: (record) => {
              return (
                <>
                  <Select style={{ width: 180 }}
                    placeholder="Select Reason"
                    value={states['reason' + record.id]}
                    disabled={!record.is_refundable}
                    hidden={record?.combo_skus?.length>0}
                    onChange={(e) => onChange(e, 'reason', record.id,'parent')}>
                    {props.refundReason.map(data => {
                      return (
                        <Option key={data.id} value={data.id}>
                          {data.name}
                        </Option>
                      )
                    })}
                    <Option key='no_refund' value='no_refund'>No Refund</Option>
                  </Select>
                </>
              )
            }
          })
          setRefundColumns(temp)
        }
    },[updateRender])
    useEffect(() => {
        if (updateCols) {
          let temp=[];
          refundColumns.map((data)=>{
            temp.push(data);
          })
          temp.splice(0, 1, {
            title: "Refund Qty",
            dataIndex: '',
            key: '',
            width: 150,
            align: 'center',
            render: (record) => {
              return (record.is_a_refundable_list ? 
                <Select placeholder="Qty" value={states['qty' + record.id]}
                        disabled={!record.is_refundable}
                        hidden={record?.combo_skus?.length>0}
                        onChange={(e) => onChange(e, 'refundQty', record.id,'parent')}
                        style={{ width: 70 }}>
                  {Array(record.max_refundable_units).fill().map((data, index) => {
                    return <Option key={index+1} value={index+1}>{index+1}</Option>
                  })}
                </Select>
              :
                <InputNumber placeholder="Qty"
                  value={states['qty' + record.id]}
                  disabled={!record.is_refundable}
                  onChange={(e) => onChange(e, 'refundQty', record.id,'parent')}
                  style={{ width: 50 }} />
              )
            }
          })
          temp.splice(1, 1, {
            title: "Refund Reason",
            dataIndex: '',
            key: '',
            width: 210,
            align: 'center',
            render: (record) => {
              return (
                <>
                  <Select style={{ width: 180 }}
                    placeholder="Select Reason"
                    value={states['reason' + record.id]}
                    disabled={!record.is_refundable}
                    hidden={record?.combo_skus?.length>0}
                    onChange={(e) => onChange(e, 'reason', record.id,'parent')}>
                    {props.refundReason.map(data => {
                      return (
                        <Option key={data.id} value={data.id}>
                          {data.name}
                        </Option>
                      )
                    })}
                    <Option key='no_refund' value='no_refund'>No Refund</Option>
                  </Select>
                </>
              )
            }
          })
          showBad && temp.splice(2, 1, {
            title: 'Bad Quality Reason',
            dataIndex: '',
            key: '',
            width: 210,
            align: 'center',
            render: (record) => {
              return (
                <div>
                  {props.orderDetails.delivery_line_items.map(data => {
                    return (
                      states['reason' + record.id] === 2 && data.id === record.id ?
                        <Select style={{ width: 180 }} key={record.id}
                          placeholder="Select Reason"
                          disabled={!record.is_refundable}
                          value={states['reviewreason' + record.id]}
                          hidden={record?.combo_skus?.length>0}
                          onChange={(e) => onChangeReviewReason(e, 'reviewreason', data.id,'parent')}>
                          {props.reviewReason.map(data => {
                            return (
                              <Option key={data.id} value={data.id}>
                                {data.display_name}
                              </Option>
                            )
                          })}
                        </Select>
                        : null
                    )
                  })}
                </div>
              )
            }
          },deliveryItemColumns[0])
          let distinct={};
          let unique=[]
          for(let i=0;i<temp.length;i++){
            if(!distinct[temp[i].title]){
              distinct[temp[i].title]=1;
                unique.push(temp[i])
            }
          }
          setRefundColumns(unique)
        }
      }, [updateCols])
      
    useEffect(() => {
      if(childUpdate){
        let temp=[];
        childCol.map((item)=>{
          temp.push(item)
        })
        temp.splice(0, 1, {
          title: "Refund Qty",
          dataIndex: '',
          key: '',
          width: 150,
          align: 'center',
          render: (record) => {
            return (record.is_a_refundable_list ? 
              <Select placeholder="Qty" value={states['qty' + record.id]}
                      disabled={!record.is_refundable}
                      hidden={record?.combo_skus?.length>0}
                      onChange={(e) => onChange(e, 'refundQty', record.id,'child')}
                      style={{ width: 70 }}>
                {Array(record.max_refundable_units).fill().map((data, index) => {
                  return <Option key={index+1} value={index+1}>{index+1}</Option>
                })}
              </Select>
            :
              <InputNumber placeholder="Qty"
                value={states['qty' + record.id]}
                hidden={record?.combo_skus?.length>0}
                disabled={!record.is_refundable}
                onChange={(e) => onChange(e, 'refundQty', record.id,'child')}
                style={{ width: 50 }} />
            )
          }
        })
        temp.splice(1, 1, {
          title: "Refund Reason",
          dataIndex: '',
          key: '',
          align: 'center',
          width: 210,
          render: (record) => {
            return (
              <>
                <Select style={{ width: 180 }}
                  placeholder="Select Reason"
                  value={states['reason' + record.id]}
                  disabled={!record.is_refundable}
                  hidden={record?.combo_skus?.length>0}
                  onChange={(e) => onChange(e, 'reason', record.id,'child')}>
                  {props.refundReason.map(data => {
                    return (
                      <Option key={data.id} value={data.id}>
                        {data.name}
                      </Option>
                    )
                  })}
                  <Option key='no_refund' value='no_refund'>No Refund</Option>
                </Select>
              </>
            )
          }
        })
        showBad && temp.splice(2, 1, {
          title: 'Bad Quality Reason',
          dataIndex: '',
          key: '',
          width: 210,
          align: 'center',
          render: (record) => {
            return (
              <div>
                {props.orderDetails.refundable_line_items.map(data => {
                  return (
                    states['reason' + record.id] === 2 && data.id === record.id?
                      <Select style={{ width: 180 }} key={record.id}
                        placeholder="Select Reason"
                        disabled={!record.is_refundable}
                        value={states['reviewreason' + record.id]}
                        onChange={(e) => onChangeReviewReason(e, 'reviewreason', data.id,'child')}>
                        {props.reviewReason.map(data => {
                          return (
                            <Option key={data.id} value={data.id}>
                              {data.display_name} 
                              {/* herere */}
                            </Option>
                          )
                        })}
                      </Select>
                      : null
                  )
                })}
              </div>
            )
          }
        },deliveryItemColumns[0])
        let distinct={};
          let unique=[]
          for(let i=0;i<temp.length;i++){
            if(!distinct[temp[i].title]){
              distinct[temp[i].title]=1;
                unique.push(temp[i])
            }
          }
        setChildCol(unique)
      } 
    },[childUpdate])

    const expandedRow=(record)=>{
        let items=props.orderDetails.refundable_line_items;
        let arrayItems=[]
        for(let i=0;i<items.length;i++){
            if(items[i].delivery_line_item_id===record.id){
                arrayItems.push(items[i])
            }
        }
        return <Table columns={childCol}  showHeader={false}  pagination={false} dataSource={arrayItems} scroll={{ x: 240 }} rowKey={(e)=>e.id}></Table>
    }
    return(
        <div className='child-table-container'>
            <Table rowKey={(e)=>e.id}  pagination={false}
            dataSource={props.orderDetails.delivery_line_items?listData:null} columns={refundColumns}  expandRowByClick={true}
            expandable={{
                  expandedRowRender: (record)=>expandedRow(record),
                  rowExpandable: record => record?.combo_skus?.length>0 ? true :false
                }}
              scroll={{ x: 240 }} >
            </Table>
        </div>
    )
}
export default RefundTable