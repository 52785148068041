import { Button } from "antd"
import React from 'react'
import { EditOutlined } from '@ant-design/icons';
export const poColumns = [
  {
    title: 'Order No',
    dataIndex: 'order_number',
    key: 'order_number',
    align: 'center'
  },
  {
    title: 'Supplier Name',
    dataIndex: 'source_name',
    key: 'source_name',
    align: 'center',
    width: 130
  },
  {
    title: 'Collection Center',
    dataIndex: 'collection_center',
    key: 'collection_center',
    align: 'center',
    width: 130
  },
  {
    title: 'Order Placed At',
    dataIndex: 'ordered_at',
    key: 'ordered_at',
    align: 'center',
    width: 130
  },
  {
    title: 'Delivery Date',
    dataIndex: 'delivery_date',
    key: 'delivery_date',
    align: 'center',
    width: 130
  },
  {
    title: "Is Advance",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_advance.toString()
    }
  },
  {
    title: 'Total Amount',
    dataIndex: 'total_amount',
    key: 'total_amount',
    align: 'center',
    width: 130
  },
]

export const productColumns = [
  {
    title: "Product",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    align: "center",
  },
  {
    title: "Qty",
    dataIndex: "qty",
    key: "qty",
    align: "center",
  },
  {
    title: "Unit Price",
    dataIndex: "unit_price",
    key: "unit_price",
    align: "center",
  },
]

export const productGrnColumns = [
  {
    title: "Product",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "PO Qty",
    dataIndex: "po_qty",
    key: "po_qty",
    align: "center",
  },
  {
    title: "Grading Qty",
    dataIndex: "graded_quantity",
    key: "graded_quantity",
    align: "center",
  },
  {
    title: "Unit Price",
    dataIndex: "unit_price",
    key: "unit_price",
    align: "center",
  },
  {
    title: "Grading Loss",
    dataIndex: "grading_loss",
    key: "grading_loss",
    align: "center",
  },
  {
    title: "Total Amt",
    dataIndex: "product_total_amount",
    key: "product_total_amount",
    align: "center",
  },
]

export const paymentColumns = [
  {
    title: 'Order No',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.po_detail.order_number
    }
  },
  {
    title: 'Order Date',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.po_detail.order_date
    }
  },
  {
    title: 'Delivery Date',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.po_detail.delivery_date
    }
  },
  {
    title: 'Supplier Name',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.po_detail.name
    }
  },
  {
    title: 'Collection Center',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.po_detail.cc_name
    }
  },
  {
    title: 'Total Amt',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.po_detail.total_amount
    }
  },
  {
    title: 'Due Date',
    dataIndex: 'due_date',
    key: 'due_date',
    align: 'center'
  },
  {
    title: 'Payment Date',
    dataIndex: 'payment_date',
    key: 'payment_date',
    align: 'center'
  },
  {
    title: 'Payment Method',
    dataIndex: 'mode_of_payment',
    key: 'mode_of_payment',
    align: 'center'
  },
  {
    title: 'Final Amt',
    dataIndex: 'final_amount',
    key: 'final_amount',
    align: 'center'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center'
  },
  {
    title: 'Rejection Reason',
    dataIndex: 'rejection_reason',
    key: 'rejection_reason',
    align: 'center'
  },
  {
    title: 'Txn Detail',
    dataIndex: 'transaction_detail',
    key: 'transaction_detail',
    align: 'center'
  },
]

export const nodeListingColumns = [
  {
    title: "Center Name",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "fraazo_sku",
    align: "center",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    align: "center",
  },
  {
    title: "Node Type",
    dataIndex: "node_type",
    key: "node_type",
    align: "center",
  },
  {
    title: "Mobile Number",
    dataIndex: "POC_contact",
    key: "POC_contact",
    align: "center",
  },
  {
    title: "Is Hub",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_hub.toString()
    }
  },
  {
    title: "Is Active",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_active.toString()
    }
  }
]

export const CCWHIncentoryColumns = [
  {
    title: "Node Name",
    dataIndex: "node_name",
    key: "node_name",
    align: "center",
  },
  {
    title: "Product Name",
    dataIndex: "product_name",
    key: "product_name",
    align: "center",
  },
  {
    title: "Fraazo Sku",
    dataIndex: "fraazo_sku",
    key: "fraazo_sku",
    align: "center",
  },
  {
    title: "Current Stock",
    dataIndex: "current_stock",
    key: "current_stock",
    align: "center",
  },
]

export const poPaymentDetailColumn = [
  {
    title: 'Due Date',
    dataIndex: 'due_date',
    key: 'due_date',
    align: 'center'
  },
  {
    title: 'Payment Date',
    dataIndex: 'payment_date',
    key: 'payment_date',
    align: 'center'
  },
  {
    title: 'Payment Method',
    dataIndex: 'mode_of_payment',
    key: 'mode_of_payment',
    align: 'center'
  },
  {
    title: 'Final Amt',
    dataIndex: 'final_amount',
    key: 'final_amount',
    align: 'center'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center'
  },
  {
    title: 'Rejection Reason',
    dataIndex: 'rejection_reason',
    key: 'rejection_reason',
    align: 'center'
  },
  {
    title: 'Txn Detail',
    dataIndex: 'transaction_detail',
    key: 'transaction_detail',
    align: 'center'
  },
] 
export const globalPropertiesColumn=(onEdit)=>{
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center'
    },
    {
      title: 'Visible',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return record?.is_visible?.toString()
      }
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      align: 'center'
    },
    {
      title: 'Updated By',
      dataIndex: 'updated_by',
      key: 'updated_by',
      align: 'center'
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => <div>
        <Button onClick={() => onEdit(record)} icon={<EditOutlined />}></Button>
      </div>
    },
  ]
}