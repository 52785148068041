import React, {useState} from 'react';
import { Card, Row, Col, Select, Button, Spin, notification, DatePicker } from 'antd';
import moment from 'moment';
import Search from '../antd/search';
import {DownloadService} from '../../_services/downloads';
import {StoreService} from '../../_services/store';

const download_service = new DownloadService()
const store_service = new StoreService()

const { Option } = Select;

function SingularDate(props){
    const [state, setState] = useState({date: '', dateValue: '', valueByDarkstore: 'all',
                                        valueByCategory: [], valueBySubCategory: []});
    const [spinEnable, setSpinEnable] = useState(false)
    const [downloadOption, setDownloadOption] = useState(undefined)
    const [subCatData, setSubCatData] = useState(props.productSubCategories)

    const {date, dateValue, valueByDarkstore, valueByCategory, valueBySubCategory} = state

    function onChange(val) {
        setDownloadOption(val)
    }

    function onChangeDropdown(value, type){
        // console.log(values)
        setState(prevState =>({...prevState, [type]: value}))
        if(type === 'valueByCategory'){
            let newSubCat = []

            if(value.length > 0){
                props.productSubCategories.map(data => {
                    if(value.find(x => x === data.parent_code)){
                        newSubCat.push(data)
                    }
                })
            }else{
                newSubCat = props.productSubCategories
            }

            setSubCatData(newSubCat)
            setState(prevState =>({...prevState, valueBySubCategory: []}))
        }
    }

    function getDownloadList(){
        setSpinEnable(true)
        const payload = {
            date: dateValue,
            darkstore: valueByDarkstore === 'all' ? '' : valueByDarkstore,
            url: downloadOption,
            by_category_code: `[${valueByCategory}]`,
            by_sub_category_code: `[${valueBySubCategory}]`,
        }
        if(props.operations){
            payload.operations=true
        }
        download_service.download_singular_date_report(payload).subscribe((r) => {
            // const linkSource = `data:application/octet-stream;base64,${r.response.csv_data}`;
            // const downloadLink = document.createElement("a");
            // const fileName = `${downloadOption}_${(new Date).toDateString().replace(/ /g,'_')}.csv`;
            // downloadLink.href = linkSource;
            // downloadLink.download = fileName;
            // downloadLink.click();
            if(downloadOption === 'sto_report'){
                window.open(r.response.response, '_blank')
                openNotificationWithIcon('success','Downloaded Successfully')
                setSpinEnable(false)
            }else{
                var myVar = setInterval(() => {
                    store_service.download_reports_job(r.response.data).subscribe(res => {
                        if(res.response.response.status === 'success'){
                            clearInterval(myVar)
                            window.open(res.response.response.url, '_blank')
                            openNotificationWithIcon('success','Downloaded Successfully')
                            setSpinEnable(false)
                        }
                    }, (err) => {
                        setSpinEnable(false)
                        clearInterval(myVar)
                        openNotificationWithIcon('error','Download Failed')
                    })
                }, 6000);
            }
        }, (err) => {
            setSpinEnable(false)
            openNotificationWithIcon('error','Download Failed')
        })
    }

    function openNotificationWithIcon(type, msg) {
        notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
    };

    function disabledDate(current){
        // Can select days before today and today
        // return current && current > moment().add(0, "day");
        //Can select days before today and past 3 months
        return current && (current < moment().subtract(91, "day") || current > moment().add(0, "day"));
    }

    function onChangeDate(date, dateString) {
        setState(prevState =>({...prevState, date: date, dateValue: dateString}))
      }

    return(
        <div className="layer" style={{paddingTop: 20}}>
            {!spinEnable ?
                <Row>
                <Card>
                    <Col span={24} style={{marginLeft: 10}}>
                    <h3 style={{marginTop: 10, marginBottom: 0}}>
                        Physical SKU Qty Report | STO Challan Report
                    </h3>   
                    <DatePicker value={date} onChange={onChangeDate} placeholder="Date"
                                style={{ width: 200}} disabledDate={disabledDate} />
                    {props.nodes ? 
                        <Search placeholder="Node Name" value={valueByDarkstore} data={props.nodes} width='medium'
                            onChange={(e) => onChangeDropdown(e, 'valueByDarkstore')} type="valueByZapperCode"/>
                    :     
                        <Search placeholder="Darkstore Name" value={valueByDarkstore}
                            onChange={(e) => onChangeDropdown(e, 'valueByDarkstore')} type="valueByDarkstoreStatsCode"/>
                    }
                    <Select mode="multiple" style={{ width: 300, marginRight: 10}}
                            value={valueByCategory}
                            placeholder="Select Category (Optional)" onChange={(e) => onChangeDropdown(e, 'valueByCategory')}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {props.productCategories.map(data => {
                            return(
                                <Option value={data.code} key={data.code}>{data.name}</Option>
                            )
                        })}
                    </Select>
                    <Select mode="multiple" style={{ width: 300, marginRight: 10}}
                            value={valueBySubCategory}
                            placeholder="Select Sub-Category (Optional)" onChange={(e) => onChangeDropdown(e, 'valueBySubCategory')}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {subCatData.map(data => {
                            return(
                                <Option value={data.code} key={data.code}>{data.name}</Option>
                            )
                        })}
                    </Select>
                    <Select style={{ width: 300, marginRight:10}}
                      value={downloadOption}
                      placeholder="Select Download Item" onChange={(e) => onChange(e)}
                      filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        <Option key="phy_sku_qty_report" value="phy_sku_qty_report">
                            Physical SKU Qty Report
                        </Option>
                        <Option key="sto_challan_report" value="sto_challan_report">
                            STO Challan Report
                        </Option>
                    </Select>
                    <Button disabled={!downloadOption || dateValue === ''} onClick={getDownloadList} style={{width: 100, margin:10}}
                            type="primary">Download</Button>
                    </Col>
                </Card>
                </Row>
                :
                <div className="spin-center">
                    <Spin tip="...Download in Progress"/>
                </div>
            }
         </div>
    )
}

export default SingularDate