import React, {useEffect, useState} from 'react';
import { Select, Spin } from 'antd';
import Store from '../../../components/downloads/store';
import SingularDate from  '../../../components/downloads/singulardate';
import { OperationsService } from '../../../_services/operations';

const ops_service = new OperationsService()

const { Option } = Select;

function DownloadListOperations(){
  
  const [nodes, setNodes] = useState(false)

  useEffect(() => {
    getNodes()
  }, [])

  function getNodes() {
    const payload = {
      nodeType: `["CC","WH"]`,
      city_id: localStorage.getItem('city') ?? ''
    }
    ops_service.get_node_listing(payload).subscribe((r) => {
      setNodes(r.response.data)
    },
    (err) => {
      console.log(err)
    })
  }

  return(
    <div className="layer">
      {nodes ?
        <div> 
          <Store nodes={nodes} operations={true}/>
          <SingularDate nodes={nodes} operations={true}/>
        </div>
      : 
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default DownloadListOperations
