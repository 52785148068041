import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber, Select,
         Pagination, DatePicker, Popconfirm, notification, Tag, Table } from 'antd';
import {CustomerService} from '../../_services/customer';
import MainTable from '../../components/antd/table';
import Search from '../../components/antd/search';
import { ReloadOutlined } from '@ant-design/icons';
import {reviewColumns} from '../../components/antd/columns/reviews';
import ReviewModal from '../../components/customers/review_detail';
import AddReviewModal from '../../components/customers/add_review_modal';

const customer_service = new CustomerService()
const { Option } = Select;

function ReviewList(){
  const modalRef = useRef(null);
  const reviewRef = useRef(null);
  const [reviewData, setReviewData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [issueData, setIssueData] = useState(false)
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [ratingData, setRatingData] = useState([{id: 1, name: 'Good'}, {id: 1, name: 'Bad'}])
  const [state, setState] = useState({
                            valueByDarkstore: undefined, valueByDeliveryDate: undefined,
                            date: '', valueByRating: undefined, valueByIssuetype: undefined,
                            valueByOrderNo: undefined});
  const [modalState, setModalState] = useState({valueByReviews: [], orderId: undefined});
  const { valueByDarkstore, valueByDeliveryDate, date, valueByRating, valueByIssuetype, valueByOrderNo } = state
  const { valueByReviews, orderId } = modalState

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getReviews()
    }
  })

  useEffect(() => {
    getColumns()
    getIssues()
  }, [])

  function getReviews(){
    setSpinEnable(true)
    setReviewData([])
    const payload = {
      page: current,
      by_darkstore: !valueByDarkstore ? '' : valueByDarkstore,
      by_delivery_date: !valueByDeliveryDate ? '' : valueByDeliveryDate,
      review_rating_type: !valueByRating ? '' : valueByRating,
      issue_type: !valueByIssuetype ? '' : valueByIssuetype,
      order_no: !valueByOrderNo ? '' : valueByOrderNo
    }
    customer_service.get_reviews(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setSpinEnable(false)
      setReviewData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getIssues(){
    customer_service.get_issues().subscribe((r) => {
      setIssueData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    reviewColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: 'Customer Liked/Disliked',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record, row) => {
        let selectedTag = [];
        Object.keys(row.order_review).map(key => {
            if(key === 'app' || key === 'other' || key === 'packaging' || key === 'pricing'
               || key === 'quality' || key === 'quantity' || key === 'time' || key === 'payment'
               || key === 'customer_support' || key === 'delivery_partner'){
              const value = row.order_review[key];
              selectedTag.push(value)
            }
        })
        return(
          <div>
              { selectedTag.map((data, index) => {return data ? (
                <Tag key={index} color={record.order_review.ratings > 3 ? 'green' : 'red'}>{data}</Tag>
              ):null}
              )}
          </div>
        )
      }
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      align: 'right',
      width: 200,
      render: (record) => {
        return (
          <div>
            <Button type="primary" onClick={() => showModal(record, 'Review Details')}>
              View Details
            </Button>
            {record.order_review.ratings <= 2 ? 
              <Button type="primary" style={{marginTop: 10}} onClick={() => showModalReview(record, 'Review Tags')}>
                Add Review Tags
              </Button>
            : null}
          </div>
        )
      }
    })
    setColumns(columns)
  }

  function showModal(data, type) {
    modalRef.current.showModal(type, data)
  }

  function showModalReview(data, type) {
    setModalState(prevState =>({...prevState, orderId: data.order_details.id}))
    reviewRef.current.showModal(type, data)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function onChangeDate(date, dateString) {
    setState(prevState =>({...prevState, date: date, valueByDeliveryDate: dateString}))
    setDisable(false)
    setCurrent(1)
  }

  function reset() {
    setState({
      valueByDarkstore: undefined,
      valueByDeliveryDate: undefined,
      date: '', valueByRating: undefined,
      valueByIssuetype: undefined,
      valueByOrderNo: undefined})
    setDisable(true)
    setCurrent(1)
    setReviewData([])
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setReviewData([])
    setRenderData(false)
  }

  function onChangeReview(values) {
    setModalState(prevState =>({...prevState, valueByReviews: values}))
  }

  function onCancelModal() {
    setModalState({valueByReviews: [], orderId: undefined})
  }

  function modalSubmit() {
    if(valueByReviews.length <= 0){
         openNotificationWithIcon('error', "Please select review tag")
         reviewRef.current.stopLoading()
    }else{
      const payload = {
        order_id: orderId,
        issue_type: valueByReviews
      }
      customer_service.update_review(payload).subscribe((r) => {
        openNotificationWithIcon('success','Updated Successfully')
        getReviews()
        reviewRef.current.handleCancel()
      },
      (err)=>{
        console.log(err)
        reviewRef.current.stopLoading()
        openNotificationWithIcon('error', err.response.errors[0])
      })
    }
  }

  return(
    <div className="layer">
      {reviewData && issueData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Darkstore Name" value={valueByDarkstore}
                        onChange={onChange} type="valueByDarkstore"/>
                <DatePicker value={date} onChange={onChangeDate} placeholder="Delivery Date"
                            style={{width: 200, marginLeft: 10, marginRight: 10}}/>
                <Search placeholder="Rating" value={valueByRating}
                        onChange={(e) => onChange(e, 'valueByRating')} data={ratingData}/>
                <Select style={{ width: 200, marginLeft: 10}} value={valueByIssuetype}
                        placeholder="Select Issue" onChange={(e) => onChange(e, 'valueByIssuetype')}>
                  {issueData.map((data, index) => {
                      return(
                          <Option value={data} key={index}>{data}</Option>
                      )
                  })}
                </Select>
                <InputNumber placeholder="Order No"
                             onChange={(e) => onChange(e, 'valueByOrderNo')}
                             value={valueByOrderNo} style={{width: 200, margin: 10}}/>
                <Button disabled={disable} onClick={getReviews} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
              </Col>
            </Card>
          </Row>
          {reviewData.length > 0 ?
            <Row>
              <Col span={24}>
                <Card style={{ paddingTop: 5, marginTop: 20 }}>
                  <Row>
                    <Col span={24}>
                      <Table
                        columns={columns}
                        dataSource={reviewData}
                        pagination={false}
                        scroll={{ x: 240 }}
                        rowKey={record => record.order_review.id}
                      />
                    </Col>
                  </Row>
                </Card>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <ReviewModal ref={modalRef}/>
          <AddReviewModal ref={reviewRef} onChangeReview={onChangeReview}
                          onCancelModal={onCancelModal} modalSubmit={modalSubmit}
                          issueData={issueData} valueByReviews={valueByReviews}/>
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default ReviewList
