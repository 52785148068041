import React from 'react'
import { Form, Input } from 'antd'
import { tailLayout } from '../_modules/controllayout'

const FRZInput = ({ name, label, isRequired, placeholder, width, ofType, setOnChange, isDisabled, isTextArea, customClass }) => {

  const onChange = (e) => {
    if (setOnChange) {
      setOnChange(e)
    }
  }

  return (
    <Form.Item
      {...tailLayout}
      name={name}
      label={label}
      rules={[
        isRequired ? {
          required: true,
          message: `Please input the value of ${label}!`,
        } : {},
        {
          whitespace: true,
        },
        {
          type: ofType ? ofType : "string",
          message: "Please Enter An Valid Email"
        }
      ]}>
      {isTextArea ?
        <Input.TextArea className={customClass ? customClass : ""} onChange={onChange} style={{ width: width }} placeholder={placeholder ? placeholder : label}
          disabled={isDisabled} /> :
        <Input className={customClass ? customClass : ""} onChange={onChange} style={{ width: width }} placeholder={placeholder ? placeholder : label}
          disabled={isDisabled} />}
    </Form.Item>
  )
}

export default FRZInput
