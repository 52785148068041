import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button, Select, Input, InputNumber,
         Radio} from 'antd';
const { Option } = Select;

const TestAccountModal = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOk() {
    setLoading(true)
    props.modalSubmit()
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.onCancelModal();
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onOk={handleOk}
          onCancel={handleCancelModal}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              Submit
            </Button>,
          ]}
        >
            {title === 'Create Test Account' ? 
                <div className="block">Mobile No :
                    <span style={{paddingLeft:10}}>
                        <InputNumber placeholder="Mobile No"
                        value={props.mobile_no} style={{width:200}}
                        onChange={(e) => props.onChangeModal(e, 'mobile_no')}/>
                    </span>
                </div>
            : 
                <div className="block">Reset Type :
                    <span style={{paddingLeft:10}}>
                        <Select style={{ width: 200}} value={props.reset_type}
                                placeholder="Reset Type" 
                                onChange={(e) => props.onChangeModal(e, 'reset_type')}>
                            <Option key='all' value='all'>All</Option>
                            <Option key='orders' value='orders'>Orders</Option>
                            <Option key='fraazo_valet' value='fraazo_valet'>Fraazo Valet</Option>
                        </Select>
                    </span>
                </div>
            }
        </Modal>
      : null }
    </div>
  )
})

export default TestAccountModal
