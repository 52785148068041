import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Spin, Button, Card, Row, Col, Table, Tabs } from 'antd';
import { CategoryService } from '../../_services/category';
import MainTable from '../../components/antd/table';
import { categoryColumns } from '../../components/antd/columns/master/category';
import CategoryModal from '../../components/master/category/modal';
import { openNotificationWithIcon } from '../../_modules/notification'
import update from 'immutability-helper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, useDrag, useDrop, createDndContext } from 'react-dnd';
import ModalActivaition from '../../components/master/category/modalactivaition';
import LogModal from '../../components/logs/logmodal';
import FrzDynamicDownload from '../../_controls/FRZDynamicDownload';
import SubCategoryTable from '../../components/master/category/SubCategoryTable';

const category_service = new CategoryService()
const RNDContext = createDndContext(HTML5Backend);
const type = 'DragableBodyRow';

const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

const { TabPane } = Tabs;


function Category() {
  const modalRef = useRef(null);
  const [listingData, setListingData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [imgLoading, setImgLoading] = useState(false)
  const [mode, setMode] = useState(undefined)
  const [currentTab, setCurrentTab] = useState(1);
  const [updateData, setUpdateData] = useState(false)
  const [activationModal, setActivationModal] = useState(false)
  const [catId, setCatId] = useState(false)
  const [rowData, setRowData] = useState(false)

  const handleOnActivationModal = (value, record) => {
    if (record) {
      setRowData(record)
      setCatId(record.id)
    } else {
      setRowData(false)
      setCatId(false)
    }
    setActivationModal(value)
  }

  const [showLogModal, setShowLogModal] = useState(false)
  const [itemId, setItemId] = useState(false)
  const [nestedCol, setNestedCol] = useState(false)

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const handleLogModal = (value, id) => {
    setShowLogModal(value)
    setItemId(id)
  }

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = listingData[dragIndex];
      setListingData(
        update(listingData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
      setUpdateData(true)
    },
    [listingData],
  );

  const manager = useRef(RNDContext);

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getListing(currentTab)
    }
  })

  useEffect(() => {
    getColumns()
  }, [currentTab])

  useEffect(() => {
    if (updateData) {
      setUpdateData(false)
      updateListingSequence()
    }
  })

  function updateListingSequence() {
    let tempData = []
    listingData.map((data, index) => {
      tempData.push({ id: data.id, sequence: index + 1 })
    })
    const payload = {
      category_products: tempData
    }
    category_service.update_category_sequence(payload).subscribe((r) => {
      openNotificationWithIcon("success", "Sequence Updated Successfully");
      getListing(currentTab)
    },
      (err) => {
        console.log(err)
      })
  }

  function getListing(key) {
    setSpinEnable(true)
    // setListingData([])
    if (key == 1) {
      category_service.get_category().subscribe((r) => {
        //   setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
        setSpinEnable(false)
        setListingData(r.response.data)
      },
        (err) => {
          console.log(err)
        })
    } else {
      if (localStorage.getItem('city') !== '') {
        category_service.get_category_sequence().subscribe((r) => {
          //   setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
          setSpinEnable(false)
          let tempdata = r.response.data.sort(function (a, b) { return a.sequence - b.sequence });
          setListingData(tempdata)
        },
          (err) => {
            console.log(err)
          })
      } else {
        setSpinEnable(false)
        setListingData([{}])
      }
    }
  }

  function getColumns() {
    let tempCol = []
    let tempNestedCol = []
    categoryColumns.map(data => {
      tempCol.push(data)
    })
    tempCol.push(
      {
        title: "Old App Mobile Img",
        dataIndex: "",
        key: "",
        align: "center",
        width: 150,
        render: (record) => {
          return (
            <img src={record.image_url} height={60} />
          );
        },
      },
      {
        title: "New App Mobile Img",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
          return (
            <img src={record.mobile_image_url} height={60} />
          );
        },
      },
      {
        title: "New App Mobile Grid Img",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
          return (
            <img src={record.icon_image_url} height={60} />
          );
        },
      },
      {
        title: "SVG Img Icon",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
          return (
            <img src={record.sketch_image_url} height={60} />
          );
        },
      },
      {
        title: "Web Image",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
          return (
            <img src={record.web_image_url} height={60} />
          );
        },
      },
    );
    if (currentTab == "2") {
      tempCol.push({
        title: 'Sequence',
        dataIndex: 'sequence',
        key: 'sequence',
        align: 'center'
      })
    }
    if (currentTab == 1) {
      tempCol.push({
        title: 'Edit',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (
            <div className="frz-dis-flex">
              <Button type="primary" onClick={() => showModal(record, 'Edit')}>Edit</Button>
              <Button className="frz-lm-10" type="primary" onClick={() => handleOnActivationModal(true, record)}>Active / Deactive</Button>
            </div>
          )
        }
      },
        {
          title: 'Logs',
          dataIndex: '',
          key: '',
          align: 'center',
          render: (record) => {
            return (
              <Button type="primary" onClick={() => handleLogModal(true, record.id)}>View Logs</Button>
            )
          }
        })
    }
    setColumns(tempCol)
    tempNestedCol = [...tempCol]
    // let tempIndex = tempNestedCol.indexOf(tempNestedCol.find((e) => e.title === "Logs"))
    // tempNestedCol.splice(tempIndex)
    setNestedCol(tempNestedCol)
  }

  function changeTab(key) {
    setCurrentTab(key);
    setListingData([]);
    setDisable(true);
    setRenderData(false);
  }

  function expandedRow(record) {
    return (
      <SubCategoryTable nestedCol={nestedCol} nestedTableData={record} />
    )
  }

  function showModal(data, type) {
    setMode(type)
    modalRef.current.showModal(type, data)
  }

  function modalSubmit(payloadData, id) {
    if ((mode !== 'Edit' && payloadData.is_parent) &&
      (!payloadData.mobile_image || !payloadData.icon_image || !payloadData.web_image)) {
      openNotificationWithIcon('error', "Please add images for mobile and web")
      modalRef.current.stopLoading()
      return
    }
    if (!payloadData.is_parent && !payloadData.parent_id) {
      openNotificationWithIcon('error', "Please select parent category")
      modalRef.current.stopLoading()
      return
    }

    const payload = {
      id: id ? id : undefined,
      body: {
        ...payloadData
      }
    }
    if (mode === 'Edit') {
      category_service.update_category(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Edited Successfully')
        getListing(currentTab)
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors?.[0])
        })
    } else {
      category_service.create_category(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Created Successfully')
        getListing(currentTab)
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors?.[0])
        })
    }
  }

  return (
    <div className="layer-nofilter">
      <div className="ost">
        <Tabs defaultActiveKey="1" onChange={changeTab}>
          <TabPane tab="Create Category / Sub-Category" key="1"></TabPane>
          <TabPane tab="Category Sequencing" key="2"></TabPane>
        </Tabs>
      </div>
      {listingData ?
        <div>
          <Row>
            {currentTab == 1 ?
              <Card>
                <Col span={24}>
                  <Button onClick={() => showModal(true, 'Create Category / Sub-Category')} className="frz-w-250p frz-m-10"
                    type="primary">Create Category / Sub-Category</Button>
                  <span className="fl-right">
                    <FrzDynamicDownload name={'Category Master'} fileName={'Category Mater'} url={'admin_dashboard/v1/downloads/category_master_report'}
                      payload={'?'} type={'job'} reportFunc={'fraazo_report'} jobReportFunc={'download_reports_job_fraazo'}
                      base={'fraazo'} />
                  </span>
                </Col>
              </Card>
              : null}
          </Row>
          {listingData.length > 0 ?
            <Row>
              <Col span={24}>
                {currentTab == 1 ?
                  <MainTable dataSource={listingData} columns={columns} expandedRow={expandedRow} />
                  :
                  <>
                    {localStorage.getItem('city') === '' ?
                      <div className="no-data">Please select city</div>
                      :
                      <DndProvider manager={manager.current.dragDropManager}>
                        <MainTable dataSource={listingData} columns={columns}
                          components={components}
                          onRow={(record, index) => ({
                            index,
                            moveRow,
                          })} />
                      </DndProvider>
                    }
                  </>
                }
                {/* <Pagination style={{ marginTop: 10, marginBottom: 10 }} current={current}
                            pageSize={50} total={state.total} onChange={pagination} showSizeChanger={false}/> */}
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <CategoryModal modalSubmit={modalSubmit} ref={modalRef}
            imgLoading={imgLoading}
            listingData={listingData} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
      {activationModal ?
        <ModalActivaition handleOnActivationModal={handleOnActivationModal}
          activationModal={activationModal} catId={catId} rowData={rowData} /> : null}
      {/* type, showLogModal, handleLogModal, itemId  */}
      {showLogModal ? <LogModal showLogModal={showLogModal} handleLogModal={handleLogModal}
        type={"product_category"} itemId={itemId} /> : null}
    </div>
  )
}

export default Category
