import { Button, Card, Col, Input, Pagination, Row, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { ReloadOutlined } from "@ant-design/icons";
import { globalPropertiesColumn } from '../../components/antd/columns/operations/category';
import GlobalPropertyModal from '../../components/developers/global_properties';
import { GlobalPropertiesService } from '../../_services/global_properties';
const initialState={
    data:null,
    err:false,
    spinner:false,
    btnDisable:false,
    total:null,
    showModal:false,
    modalType:null,
    editColumnDetails:null,
};
const initialFilters={
    page:1,
}

const global_properties_service= new GlobalPropertiesService();
const GlobalProperties = ()=>{
    const [state,setState]=useState(initialState);
    const [filters, setFilters] = useState(initialFilters)
    const [searchValue, setSearchValue] = useState('')
    const [reset,setReset]=useState(false)
    useEffect(()=>{
        getListing();
    },[filters])
    useEffect(()=>{
      if(reset){
        getListing();
      }
    },[reset])
    const getListing=()=>{
        setState((prev)=>({...prev,spinner:true}));
        const payload={
            page:filters.page?filters.page:'',
            by_name:searchValue??''
        }
        global_properties_service.get_properties(payload).subscribe((r) => {
            setState((prev)=>({...prev,data:r.response.data,total: r.response.meta.total_pages * 10,spinner:false,err:false}));
          },
        (err)=>{
            setState((prev)=>({...prev,data:false,spinner:false,err:true}));
            console.log(err)
        })
    }
    function onChangeFilters(value, type, searchNow) {
        setFilters(prevState => ({ ...prevState, [type]: value }))
        setState(prevState => ({ ...prevState, btnDisable: false }))
      }
    const onEditGp =(value)=>{
        setState(prevState => ({ ...prevState,showModal: true,modalType:'Edit',editColumnDetails:value}))
    }
    const handleSearch=(e)=>{
        setReset(false)
        setSearchValue(e.target.value)
    }
    const resetFields=()=>{
      setSearchValue('');
      setReset(true)
    }
    const handleSubmit=()=>{
        getListing()
    }
    return(
      <div className='layer'>
        {!state?.spinner ?
          <>
            {!state.err ? 
              <div>
                <Row>
                    <Card>
                        <Col span={24}>
                            <div className="frz-dis-flex frz-justify-sb">
                            <div>
                              <Input value={searchValue} className="frz-w-200" onChange={handleSearch} placeholder={'search property'}/>
                              <Button disabled={searchValue.length>0?false:true} type='primary' className="frz-lm-5" onClick={handleSubmit}>Search</Button>
                              <Button disabled={searchValue.length>0?false:true} icon={<ReloadOutlined />} className='frz-border-radius-50 frz-lm-5' type="primary" ghost onClick={resetFields}></Button>
                            </div>
                            <Button disabled={state.btnDisable} onClick={()=>setState(prevState =>({...prevState,showModal: true,modalType:'Create'}))} type='primary'>Add Global Property</Button>
                            </div>
                        </Col>
                    </Card>
                </Row>
              </div>
            : null}
            {state?.data?.length > 0 ? 
              <div className='frz-mt-10'>
                <Table rowKey="id" dataSource={state.data} scroll={{ y: 'calc(100vh - 228px)' }}
                  columns={globalPropertiesColumn((rowdata) => onEditGp(rowdata))} pagination={false}/>
                <Pagination current={filters.page} pageSize={10} total={state.total} className="frz-mt-10"
                    onChange={(e) => onChangeFilters(e, 'page', true)} showSizeChanger={false} />
              </div>
            :
              <div className="no-data">{state.err ? 'Backend API System Down' : 'No Data Available'}</div>
            }
          </>
          :
          <div className="spin-center">
            <Spin tip="...Loading" />
          </div>
        }
        {state.showModal?
          <GlobalPropertyModal editDetails={state.editColumnDetails} getProperties={getListing} title={state.modalType} 
            show={state.showModal} hide={(value)=>setState((prev)=>({...prev,showModal:value}))} />
        : null}
      </div>
    )
}
export default GlobalProperties