import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input,
         Pagination, notification } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {SocietyService} from '../../_services/society';
import Search from '../../components/antd/search';
import MainTable from '../../components/antd/table';
import SocietyModal from '../../components/society/modal';
import MergeSociety from '../../components/society/merge_society';
import SocietyLogicPos from '../../components/society/society_logic';
import {societyColumns} from '../../components/antd/columns/society';

const society_service = new SocietyService()

function SocietyList() {
  const modalRef = useRef(null);
  const mergeModalRef = useRef(null);
  const logicModalRef = useRef(null);
  const [societyData, setSocietyData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [mode, setMode] = useState(undefined)
  const [city] = useState([{name: 'Mumbai'}, {name: 'Pune'}])
  const [mergingSociety, setMergingSociety] = useState([])
  const [state, setState] = useState({
                            valueByDarkstore: undefined, valueByCity: undefined,
                            valueByName: undefined});
  const [modalState, setModalState] = useState({
                            society_name: undefined, latitude: undefined,
                            longitude: undefined, latlong_address: undefined,
                            current_address: undefined, area_name: undefined,
                            pincode: undefined, currentcity: undefined,
                            valueByRadio: undefined, currentActive: undefined,
                            mainSociety: undefined, mainLogicPos: undefined});
  const { society_name, latitude, longitude, latlong_address,
          current_address, area_name, pincode, currentcity,
          valueByRadio, currentActive, mainSociety, mainLogicPos } = modalState
  const { valueByName, valueByDarkstore, valueByCity } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getSociety()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getSociety(){
    setSpinEnable(true)
    setSocietyData([])
    const payload = {
      page: current,
      by_name: valueByName === undefined ? '' : valueByName,
      by_darkstore: valueByDarkstore === undefined ? '' : valueByDarkstore,
      by_city: valueByCity === undefined ? '' : valueByCity,
    }
    society_service.get_society(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setSpinEnable(false)
      setSocietyData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    societyColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (
          <Button type="primary" onClick={() => showModal(record, 'Edit Society')}>Edit</Button>
        )
      }
    })
    setColumns(columns)
  }

  function showModal(data, type) {
    setMode(type)
    if(type === 'Edit Society'){
      setModalState({
        society_name: data.name, latitude: data.latitude,
        longitude: data.longitude, latlong_address: data.latlong_address,
        current_address: data.address, area_name: data.area_name,
        pincode: data.pincode, currentcity: data.city_name,
        valueByRadio: data.is_verified, currentActive: data.id,
        mainLogicPos: data.logic_pos_account_details ? data.logic_pos_account_details.logic_pos_code : undefined})
    }
    modalRef.current.showModal(type, data)
  }

  function showModalMerge(data, type) {
    mergeModalRef.current.showModal(type, data)
  }

  function showModalLogic(data, type) {
    logicModalRef.current.showModal(type, data)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function onChangeModal(value, type) {
    if(type === 'valueByRadio'){
      setModalState(prevState =>({...prevState, [type]: value}))
    }else{
      setModalState(prevState =>({...prevState, [type]: value === "" ? undefined : value}))
    }
  }

  function societyMerge(values) {
    setMergingSociety(values)
  }

  function reset() {
    setState({
      valueByDarkstore: undefined,
      valueByCity: undefined,
      valueByName: undefined})
    setDisable(true)
    setCurrent(1)
    setSocietyData([])
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setSocietyData([])
    setRenderData(false)
  }

  function onCancelModal() {
    setModalState({society_name: undefined, latitude: undefined,
    longitude: undefined, latlong_address: undefined,
    current_address: undefined, area_name: undefined,
    pincode: undefined, currentcity: undefined,
    valueByRadio: undefined, currentActive: undefined,
    mainSociety: undefined, mainLogicPos: undefined})
    setMergingSociety([])
  }

  function modalSubmit() {
    if(!society_name || !latitude || !longitude || !latlong_address ||
       !current_address || !area_name || !pincode || !currentcity ||
       valueByRadio === undefined || !mainLogicPos){
         openNotificationWithIcon('error', "Please fill all fields")
         modalRef.current.stopLoading()
    }else{
      const payload = {
        id: mode === 'Edit Society' ? currentActive : undefined,
        body: {
          name: society_name,
          latitude: latitude,
          longitude: longitude,
          latlong_address: latlong_address,
          city: currentcity,
          pincode: pincode,
          address: current_address,
          is_verified: valueByRadio,
          area_name: area_name,
          logic_pos_account_id: mainLogicPos
        }
      }
      if(mode === 'Edit Society'){
        society_service.update_society(payload).subscribe((r) => {
          openNotificationWithIcon('success','Edited Successfully')
          getSociety()
          modalRef.current.handleCancel()
        },
        (err)=>{
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors[0])
        })
      }else{
        society_service.create_society(payload).subscribe((r) => {
          openNotificationWithIcon('success','Created Successfully')
          getSociety()
          modalRef.current.handleCancel()
        },
        (err)=>{
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors[0])
        })
      }
    }
  }
 
  function modalSubmitMergeSociety(){
    if(mergingSociety.length === 0 || !mainSociety){
      openNotificationWithIcon('error', "Please fill all fields")
      mergeModalRef.current.stopLoading()
    }else{
      const payload = {
        society_request_ids: mergingSociety,
        to_society_id: mainSociety
      }
      society_service.merge_societies(payload).subscribe((r) => {
        openNotificationWithIcon('success','Merged Successfully')
        getSociety()
        mergeModalRef.current.handleCancel()
      },
      (err)=>{
        console.log(err)
        mergeModalRef.current.stopLoading()
        openNotificationWithIcon('error', err.response.errors)
      })
    }
  }

  function modalSubmitLogic(){
    if(!mainSociety || !mainLogicPos){
      openNotificationWithIcon('error', "Please fill all fields")
      logicModalRef.current.stopLoading()
    }else{
      const payload = {
        id: mainSociety,
        logic_pos_account_id: mainLogicPos
      }
      society_service.update_society_logic_pos(payload).subscribe((r) => {
        openNotificationWithIcon('success','Updated Successfully')
        getSociety()
        logicModalRef.current.handleCancel()
      },
      (err)=>{
        console.log(err)
        logicModalRef.current.stopLoading()
        openNotificationWithIcon('error', err.response.errors)
      })
    }
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  return (
    <div className="layer">
      {societyData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Darkstore Name" value={valueByDarkstore}
                        onChange={onChange} type="valueByDarkstore"/>
                <Search placeholder="City" value={valueByCity}
                        onChange={onChange} type="valueByCity"
                        data={city}/>
                <Input placeholder="Society Name" onChange={(e) => onChange(e.target.value, 'valueByName')}
                        value={valueByName} style={{width: 200, margin: 10}}/>
                <Button disabled={disable} onClick={getSociety} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                {/* <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/> */}
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Add Society')} style={{width: 100, margin:10}}
                        type="primary">Add Society</Button>
                <Button onClick={() => showModalMerge(true, 'Merge Society')} style={{width: 120, margin:10}}
                        type="primary">Merge Society</Button>
                {/* <Button onClick={() => showModalLogic(true, 'Map Society To Logic Pos')} style={{width: 200, margin:10}}
                        type="primary">Map Society To Logic Pos</Button> */}
              </Col>
            </Card>
          </Row>
          {societyData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={societyData} columns={columns}/>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <SocietyModal modalSubmit={modalSubmit} ref={modalRef}
                        onChangeModal={onChangeModal}
                        onCancelModal={onCancelModal}
                        society_name={society_name} latitude={latitude}
                        longitude={longitude} latlong_address={latlong_address}
                        current_address={current_address} area_name={area_name}
                        pincode={pincode} currentcity={currentcity}
                        valueByRadio={valueByRadio}
                        mainLogicPos={mainLogicPos}
                       />
          <MergeSociety modalSubmit={modalSubmitMergeSociety} ref={mergeModalRef}
                        onChangeModal={onChangeModal}
                        onCancelModal={onCancelModal}
                        societyMerge={societyMerge}
                        mergingSociety={mergingSociety}
                        mainSociety={mainSociety}/>    
          <SocietyLogicPos modalSubmit={modalSubmitLogic} ref={logicModalRef}
                           onChangeModal={onChangeModal}
                           onCancelModal={onCancelModal}
                           mainLogicPos={mainLogicPos}
                           mainSociety={mainSociety}/>
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  );
}

export default SocietyList
