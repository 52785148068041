import SocietyList from '../pages/society/list';
import SocietyCampaign from '../pages/society/campaign';
import PickupPoints from '../pages/society/pickup_points';
import OrderList from '../pages/orders/list';
import CustomerList from '../pages/customers/list';
import ProductList from '../pages/master/product_list';
import BannerList from '../pages/master/banner';
import DarkstoresList from '../pages/master/darkstore';
import DarkstoreSales from '../pages/master/darkstore_sales';
import DarkstoreUndelivered from '../pages/darkstorestats/darkstore_undelivered';
import ReviewList from '../pages/customers/reviews';
import DownloadList from '../pages/downloads/list';
import UploadList from '../pages/uploads/list';
import DunzoTickets from '../pages/dunzo/tickets';
import DeliverySlot from '../pages/logs/delivery_slot';
import Serviceable from '../pages/logs/is_serviceable';
import StockLog from '../pages/logs/stock_log';
import NotificationLogs from '../pages/logs/notifications';
import AdminList from '../pages/master/admin';
import DeliverySlotList from '../pages/master/delivery_slot';
import ProductSequence from '../pages/master/product_sequence';
import AdCampaign from '../pages/ads/campaign';
import AppVersions from '../pages/developers/app_versions';
import StatisticsList from '../pages/statistics/statistics';
import LogicPos from '../pages/master/logic_pos';
import DemandForecasting from '../pages/master/demand_forecasting';
import CustomerSegment from '../pages/ads/segment';
import DeliveryReplacement from '../pages/orders/delivery_replacement';
import DiscountPerformance from '../pages/ads/discount_performance';
import DiscountModule from '../pages/ads/discount_module';
import Cashback from '../pages/ads/cashback';
import ReferralDiscountCustomer from '../pages/ads/customer_referral';
import ProductDiscount from '../pages/ads/product_discount';
import ServiceableLocation from '../pages/master/serviceable_location';
import CustomerSource from '../pages/ads/customer_source';
import OfflineOrders from '../pages/orders/offline_orders';
import SpecialDeals from '../pages/ads/special_deals';
import OrdersValet from '../pages/valet/orders';
import CustomersValet from '../pages/valet/customers';
import TestAccounts from '../pages/developers/test_accounts';
import OutOfStock from '../pages/store/out_of_stock';
import STO from '../pages/store/sto';
import GRN from '../pages/store/grn';
import DarkstoreMargins from '../pages/store/margins';
import ForecastingFactors from '../pages/master/forecasting_factors';
import DarkstoreDeliveryTime from '../pages/darkstorestats/darkstore_deliverytime';
import DarkstoreQuality from '../pages/darkstorestats/darkstore_quality';
import DarkstoreTargets from '../pages/society/darkstore_targets';
import ProductTag from '../pages/master/product_tag';
import Category from '../pages/master/category';
import DarkstoreDeliverySlot from '../pages/darkstorestats/darkstore_deliveryslot';
import DarkstoreQualityIssues from '../pages/darkstorestats/darkstore_qualityissues';
import ReferralReward from '../pages/customers/referralReward';
import DarkstoreRefundStats from '../pages/darkstorestats/darkstore_refundstats';
import ProductTaxSlab from '../pages/master/product_tax_slab';
import ProcurementList from '../pages/operations/procurement/list';
import PoList from '../pages/operations/category/create_po';
import GrnList from '../pages/operations/grn/grnlist';
import PaymentList from '../pages/operations/grn/paymentlist';
import CCWH from '../pages/operations/category/cc_wh';
import Suppliers from '../pages/operations/procurement/suppliers';
import Wastage from '../pages/operations/wastage/wastage';
import STOList from '../pages/operations/sto/stolist';
import CCWHInventory from '../pages/operations/category/cc_wh_inventory';
import Billing from '../pages/deliveryapp/billing';
import DownloadListOperations from '../pages/operations/downloads/list';
import MonthlyDownloads from '../pages/downloads/monthly';
import Distribution from '../pages/master/distribution';
import FraudulentRefunds from '../pages/master/fraudulent_refunds';
import WebAppCampaign from '../pages/ads/webapp_campaign';
import Buying from '../pages/pricing/buying';
import Selling from '../pages/pricing/selling';
import ReferralDiscount from '../pages/ads/referral_discount';
import CompanyBrand from '../pages/master/company_brand';
import SpecialDiscount from '../pages/master/specialdiscount';
import State from '../pages/master/state';
import UndeliveredOrderList from '../pages/orders/undelivered_list';
import ProductCessSlab from '../pages/master/product_cess_slab';
import IMS from '../pages/deliveryapp/ims';
import Payments from '../pages/master/payments';
import Capicity from '../pages/master/capacity';
import PromotionPage from '../pages/ads/promotion_page';
import Freebies from '../pages/ads/freebies';
import ProductCluster from '../pages/master/product_cluster';
import PackerDistributionComp from '../pages/distribution/packer_distribution';
import StoreApprovals from '../pages/store/storeapprovals';
import CustomerLocation from '../pages/orders/customerlocation';
import RolesandShifts from '../pages/master/roles_and_shifts';
import DarkstoreFullfillment from '../pages/darkstorestats/darkstore_fulfillment';
import ProductUnfullfillment from '../pages/darkstorestats/product_unfulfillment';
import CancelledDashboard from '../pages/darkstorestats/cancelled_dash';
import AttendanceReport from '../pages/darkstorestats/attendance_report';
import FraudLogs from '../pages/orders/fraud_logs';
import SegmentCreation from '../pages/segments/segment_creation';
import BulkDiscount from '../pages/ads/bulk_discount';
import GlobalProperties from '../pages/master/global_properties';
import GrowthAndPlanning from '../pages/distribution/growthAndPlanningTab';
import Overage from '../pages/master/overage';
import LandingClusters from '../pages/sdui/landing_clusters';
import SDUI from '../pages/sdui/sdui';
import OPCSequence from '../pages/master/opc_sequence';
import DarkstoreProperties from '../pages/master/darkstore_properties';

const routes = {
  dsstats: {
    name: 'Darkstore Stats',
    children: [
      {
        name: 'Sales',
        path: '/dssales',
        key: 'DarkstoreSales',
        component: DarkstoreSales
      },
      {
        name: 'Overage',
        path: '/overage',
        key: 'Overage',
        component: Overage
      },
      {
        name: 'Attendance',
        path: '/attendancereport',
        key: 'attendancereport',
        component: AttendanceReport
      },
      {
        name: 'Undelivered Stats',
        path: '/darkstoreundelivered',
        key: 'darkstoreundelivered',
        component: DarkstoreUndelivered
      },
      {
        name: 'Cancelled Dashboard',
        path: '/cancelled_dashboard',
        key: 'CancelledDashboard',
        component: CancelledDashboard
      },
      {
        name: 'Delivery Time',
        path: '/',
        key: 'DarkstoreDeliveryTime',
        component: DarkstoreDeliveryTime
      },
      {
        name: 'Quality Issue',
        path: '/darkstorequality',
        key: 'DarkstoreQuality',
        component: DarkstoreQuality
      },
      {
        name: 'Delivery Slot',
        path: '/darkstoredeliveryslot',
        key: 'DarkstoreDeliverySlot',
        component: DarkstoreDeliverySlot
      },
      {
        name: 'Refund Stats',
        path: '/darkstorerefundstats',
        key: 'DarkstoreRefundStats',
        component: DarkstoreRefundStats
      },
      {
        name: 'Quality Issues Category',
        path: '/darkstorequalityissues',
        key: 'DarkstoreQualityIssues',
        component: DarkstoreQualityIssues
      },
      {
        name: 'Fulfillment/Unfulfillment',
        path: '/fullfillment_unfulfillment',
        key: 'Fullfillment/Unfulfillment',
        component: DarkstoreFullfillment
      },
      {
        name: 'Dashboard',
        path: '/dashboard',
        key: 'StatisticsList',
        component: StatisticsList
      },
      {
        name: 'Out Of Stock',
        path: '/stock',
        key: 'OutOfStock',
        component: OutOfStock
      },
      {
        name: 'Darkstore Margins',
        path: '/margins',
        key: 'DarkstoreMargins',
        component: DarkstoreMargins
      },
      {
        name: 'Demand Forecasting',
        path: '/demand_forecasting',
        key: 'DemandForecasting',
        component: DemandForecasting
      }
    ]
  },
  capacity_plan: {
    name: 'Capacity Planning',
    children: [
      {
        name: 'Slot capacity',
        path: '/capacity',
        key: 'capacity',
        component: Capicity
      },
    ]
  },
  society: {
    name: 'Society',
    children: [
      {
        path: '/society/list',
        name: 'List',
        key: 'SocietyList',
        component: SocietyList
      },
      {
        path: '/society/campaign',
        name: 'Campaign',
        key: 'SocietyCampaign',
        component: SocietyCampaign
      },
      {
        path: '/society/pickup',
        name: 'Pickup Points',
        key: 'PickupPoints',
        component: PickupPoints
      },
      {
        path: '/society/ds_targets',
        name: 'Darkstore Targets',
        key: 'DarkstoreTargets',
        component: DarkstoreTargets
      },
    ]
  },
  orders: {
    name: 'Orders',
    children: [
      {
        path: '/orders/customer_location',
        name: 'Customer location',
        key: 'CustomerLocation',
        component: CustomerLocation
      },
      {
        path: '/orders/list',
        name: 'List',
        key: 'OrderList',
        component: OrderList
      },
      {
        path: '/orders/replacement',
        name: 'Delivery Replacement',
        key: 'DeliveryReplacement',
        component: DeliveryReplacement
      },
      {
        path: '/orders/offline_orders',
        name: 'Offline Orders',
        key: 'OfflineOrders',
        component: OfflineOrders
      },
      {
        path: '/orders/undelivered_orders',
        name: 'Undelivered Orders',
        key: 'UndeliveredOrderList',
        component: UndeliveredOrderList
      },
      {
        path: '/orders/fraud_logs',
        name: 'Fraud Logs',
        key: 'FraudLogs',
        component: FraudLogs
      }
    ]
  },
  valet: {
    name: 'Valet',
    children: [
      {
        path: '/valet/orders',
        name: 'Orders',
        key: 'OrdersValet',
        component: OrdersValet
      },
      {
        path: '/valet/customers',
        name: 'Customers',
        key: 'CustomersValet',
        component: CustomersValet
      },
    ]
  },
  customers: {
    name: 'Customers',
    children: [
      {
        path: '/customers/list',
        name: 'List',
        key: 'CustomerList',
        component: CustomerList
      },
      {
        path: '/customers/reviews',
        name: 'Reviews',
        key: 'ReviewList',
        component: ReviewList
      },
      {
        path: '/customers/referralreward',
        name: 'Referral Reward',
        key: 'ReferralReward',
        component: ReferralReward
      },
    ]
  },
  store: {
    name: 'Store Operations',
    children: [
      {
        path: '/store/sto',
        name: 'STO',
        key: 'STO',
        component: STO
      },
      {
        path: '/store/grn',
        name: 'GRN',
        key: 'GRN',
        component: GRN
      },
      {
        path: '/store/storeapprovals',
        name: 'Store Approvals',
        key: 'StoreApprovals',
        component: StoreApprovals
      },
    ]
  },
  deliveryapp: {
    name: 'Delivery App',
    children: [
      {
        path: '/delivery_app/billing',
        name: 'Billing',
        key: 'Billing',
        component: Billing
      },
      {
        path: '/delivery_app/ims',
        name: 'IMS',
        key: 'IMS',
        component: IMS
      },
    ]
  },
  distribution: {
    name: 'Distribution',
    children: [
      {
        path: '/distribution/manpower_distribution',
        name: 'Manpower Distribution',
        key: 'PackerDistribution',
        component: PackerDistributionComp
      },
      {
        path: '/distribution/darkstore_planning',
        name: 'Darkstore Planning',
        key: 'darkstore_planning',
        component: GrowthAndPlanning
      },
    ]
  },
  operations: {
    name: 'Operations',
    children: [
      {
        path: '/operations/payments',
        name: 'PO - GRN Payments',
        key: 'PaymentList',
        component: PaymentList
      },
    ]
  },
  pricing: {
    name: 'Pricing',
    children: [
      {
        path: '/pricing/buying',
        name: 'Buying',
        key: 'Buying',
        component: Buying
      },
      {
        path: '/pricing/selling',
        name: 'Selling',
        key: 'Selling',
        component: Selling
      },
    ]
  },
  master: {
    name: 'Master',
    children: [
      {
        path: '/master/product',
        name: 'Products',
        key: 'ProductList',
        component: ProductList
      },
      {
        path: '/master/product_tag',
        name: 'Product Tag',
        key: 'ProductTag',
        component: ProductTag
      },
      {
        path: '/master/product_tax_slab',
        name: 'Product Tax Slab',
        key: 'ProductTaxSlab',
        component: ProductTaxSlab
      },
      {
        path: '/master/category',
        name: 'Category',
        key: 'Category',
        component: Category
      },
      {
        path: '/master/darkstores',
        name: 'Darkstores',
        key: 'DarkstoresList',
        component: DarkstoresList
      },
      {
        path: '/master/company_brand',
        name: 'Company / Brand',
        key: 'CompanyBrand',
        component: CompanyBrand
      },
      {
        path: '/master/state',
        name: 'State / City',
        key: 'State',
        component: State
      },
      {
        path: '/master/product_cess_slab',
        name: 'Product Cess Slab',
        key: 'ProductCessSlab',
        component: ProductCessSlab
      },
      {
        path: '/master/forecasting',
        name: 'Forecasting Factors',
        key: 'ForecastingFactors',
        component: ForecastingFactors
      },
      {
        path: '/master/serviceable_location',
        name: 'Serviceable Location',
        key: 'ServiceableLocation',
        component: ServiceableLocation
      },
      {
        path: '/master/deliveryslot',
        name: 'Delivery Slot',
        key: 'DeliverySlotList',
        component: DeliverySlotList
      },
      {
        path: '/master/logic_pos',
        name: 'Logic Pos',
        key: 'LogicPos',
        component: LogicPos
      },
      {
        path: '/master/fraudulent_refunds',
        name: 'Fraudulent Refunds',
        key: 'FraudulentRefunds',
        component: FraudulentRefunds
      },
      {
        path: '/master/payments',
        name: 'Payments',
        key: 'Payments',
        component: Payments
      },
      {
        path: '/master/roles_and_shifts',
        name: 'Roles and Shifts',
        key: 'RolesandShifts',
        component: RolesandShifts
      },
      {
        path: '/master/opc_sequence',
        name: 'OPC Sequence',
        key: 'opc_sequence',
        component: OPCSequence
      },
      {
        path: '/master/ds_properties',
        name: 'Darkstore Properties',
        key: 'darkstore_properties',
        component: DarkstoreProperties
      }
    ]
  },
  marketing: {
    name: 'Marketing',
    children: [
      {
        path: '/marketing/ads',
        name: 'Send Notifications',
        key: 'AdCampaign',
        component: AdCampaign
      },
      {
        path: '/marketing/webapp_campaign',
        name: 'Web / App Campaign',
        key: 'WebAppCampaign',
        component: WebAppCampaign
      },
      {
        path: '/marketing/segment',
        name: 'Customer Segment',
        key: 'CustomerSegment',
        component: CustomerSegment
      },
      {
        path: '/marketing/product_sequence',
        name: 'Product Sequence',
        key: 'ProductSequence',
        component: ProductSequence
      },
      {
        path: '/marketing/banners',
        name: 'Banners',
        key: 'BannerList',
        component: BannerList
      },
      // {
      //   path: '/marketing/special_deals',
      //   name: 'Special Deals',
      //   key: 'SpecialDeals',
      //   component: SpecialDeals
      // },
      {
        path: '/marketing/customer_source',
        name: 'Customer Source',
        key: 'CustomerSource',
        component: CustomerSource
      },
      {
        path: '/marketing/referral_discount',
        name: 'Referral Discount',
        key: 'ReferralDiscount',
        component: ReferralDiscount
      },
      {
        path: '/discounts/special_discount',
        name: 'Special Discount',
        key: 'SpecialDiscount',
        component: SpecialDiscount
      },
      {
        path: '/marketing/freebies',
        name: 'Freebies',
        key: 'Freebies',
        component: Freebies
      },
      // {
      //   name: 'Clusters & Pages',
      //   children: [
      //     {
      //       path: '/marketing/product_cluster',
      //       name: 'Product Clusters',
      //       key: 'CreateProductClusters',
      //       component: ProductCluster
      //     },
      //     {
      //       path: '/marketing/landing_page',
      //       name: 'Landing Pages',
      //       key: 'PromotionPage',
      //       component: PromotionPage
      //     },
      //   ]
      // }
    ]
  },
  segments: {
    name: 'Segments',
    children: [
      {
        path: '/segment/segment_creation',
        name: 'Segment Creation',
        key: 'SegmentCreation',
        component: SegmentCreation
      }
    ]
  },
  discounts: {
    name: 'Discounts',
    children: [
      {
        path: '/discounts/cashback',
        name: 'Cashback',
        key: 'Cashback',
        component: Cashback
      },
      {
        path: '/discounts/discount',
        name: 'Discount',
        key: 'DiscountModule',
        component: DiscountModule
      },
      {
        path: '/discounts/product_discount',
        name: 'Product Discount',
        key: 'ProductDiscount',
        component: ProductDiscount
      },
      {
        path: '/discounts/bulk_coupons',
        name: 'Bulk Coupons',
        key: 'BulkCoupons',
        component: BulkDiscount
      },
      {
        path: '/discounts/customer_referral',
        name: 'Customer Referral',
        key: 'ReferralDiscountCustomer',
        component: ReferralDiscountCustomer
      },
      {
        path: '/discounts/dicount_performance',
        name: 'Discount Performance',
        key: 'DiscountPerformance',
        component: DiscountPerformance
      },
    ]
  },
  updown: {
    name: 'Upload/Downloads',
    children: [
      {
        path: '/uploads',
        name: 'Uploads',
        key: 'UploadList',
        component: UploadList
      },
      {
        path: '/downloads',
        name: 'Downloads',
        key: 'DownloadList',
        component: DownloadList
      },
      {
        path: '/downloads_monthly',
        name: 'Monthly Downloads',
        key: 'MonthlyDownloads',
        component: MonthlyDownloads
      }
    ]
  },
  dunzo: {
    name: 'Dunzo Support',
    children: [
      {
        path: '/dunzo/tickets',
        name: 'Tickets',
        key: 'DunzoTickets',
        component: DunzoTickets
      },
    ]
  },
  logs: {
    name: 'Logs',
    children: [
      {
        path: '/darkstore/delivery_slot',
        name: 'Delivery Slot',
        key: 'DeliverySlot',
        component: DeliverySlot
      },
      {
        path: '/darkstore/is_serviceable',
        name: 'Is Serviceable',
        key: 'Serviceable',
        component: Serviceable
      },
      {
        path: '/darkstore/stock',
        name: 'Products Stock',
        key: 'StockLog',
        component: StockLog
      },
      {
        path: '/darkstore/notification',
        name: 'Notifications',
        key: 'NotificationLogs',
        component: NotificationLogs
      },
    ]
  },
  sdui: {
    name: 'SDUI',
    children: [
      {
        path: '/sdui/landing_clusters',
        name: 'Landing Pg / Clusters',
        key: 'landing_clusters',
        component: LandingClusters
      },
      {
        path: '/sdui/sdui',
        name: 'SDUI',
        key: 'sdui',
        component: SDUI
      }
    ]
  },
  developers: {
    name: 'Developers',
    children: [
      {
        path: '/developers/versions',
        name: 'App Versions',
        key: 'AppVersions',
        component: AppVersions
      },
      {
        path: '/developers/test_accounts',
        name: 'Testing Accounts',
        key: 'TestAccounts',
        component: TestAccounts
      },
      {
        path: '/developers/distribution',
        name: 'Cust / Del - Distribution',
        key: 'Distribution',
        component: Distribution
      },
      {
        path: '/developers/admins',
        name: 'User Admins',
        key: 'AdminList',
        component: AdminList
      },
      {
        path: '/developers/globalproperties',
        name: 'Global Properties',
        key: 'GlobalProperties',
        component: GlobalProperties
      },

    ]
  },
}

export default routes