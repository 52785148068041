import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification, Select } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {AdsService} from '../../_services/ads';
import {BannersService} from '../../_services/banners';
import MainTable from '../../components/antd/table';
import Search from '../../components/antd/search';
import {discountColumns} from '../../components/antd/columns/ads';
import DiscountModal from '../../components/ads/discount_modal';
import {handleDateFormat} from '../../_modules/date';
import {getStaticTime} from '../../_modules/time';
import FrzDynamicDownload from '../../_controls/FRZDynamicDownload';

import moment from 'moment';
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const ads_service = new AdsService()
const banner_service = new BannersService()
const {Option} =  Select
function ProductDiscount(){
  const modalRef = useRef(null);
  const [discountData, setDiscountData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [productData, setProductData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [bankDetails, setBankDetails] = useState(false)
  const [paymentType, setPaymentType] = useState(false)
  const [mode, setMode] = useState(undefined)
  const [current, setCurrent] = useState(1)
  const [state, setState] = useState({
    valueByCouponCode: undefined, valueByVariant: 'non_bulk_discount',
    valueBySku: undefined, valueByDiscount: undefined, valueBySegment: undefined, valueByActivate: undefined,
    productName: undefined, valueByActiveDate: undefined, showDate: undefined, valueBySegmentOther: undefined
  });
  const [segmentData, setSegmentData] = useState(false)
  const [segmentDataOther, setSegmentDataOther] = useState(false)
  const { valueByCouponCode, valueByVariant, valueBySku, valueByDiscount, valueBySeg, showDate, valueByActiveDate, valueByActivate, productName } = state
  const [variantData] = useState([{name: 'bulk_discount'}, {name: 'non_bulk_discount'}])
  const [modalState, setModalState] = useState({valueByCoupon: undefined, title: undefined, description: undefined,
                                                discountValue: undefined, discountType: undefined, min_order: undefined, 
                                                discount_qty: undefined, valid_from_date: '', valid_from: undefined, 
                                                valid_to_date: '', valid_to: undefined, applicableCount: undefined,
                                                valueByRadio: undefined, currentActive: undefined, valueByProduct: undefined,
                                                valueByRadioAuto: undefined, productDiscountType: undefined, success_message: undefined,
                                                valueByRadioActive: undefined, valueByRadioFirst: undefined, customer_type: undefined,
                                                is_society_discount: undefined, valueByActivation: [], paymentFlag: undefined, 
                                                paymentTypeId: undefined, bankDetailId: undefined, valueBySegment: [], oldSegment: [],
                                                valueByRadioDS: undefined, valueByDarkstore: [], is_coupon: undefined, variant: undefined,
                                                valueBySegmentOther: [], oldSegmentOther: []});
  const {valueByCoupon, title, description, discountValue, discountType, min_order, valueByActivation,
         discount_qty, valid_from_date, valid_from, valid_to_date, valid_to, applicableCount, 
         valueByRadio, currentActive, valueByProduct, valueByRadioAuto, productDiscountType,
         valueByRadioActive, valueByRadioFirst, customer_type, success_message, is_society_discount,
         paymentFlag, bankDetailId, paymentTypeId, valueByDarkstore, valueByRadioDS, is_coupon, variant,
         valueBySegment, oldSegment, valueBySegmentOther, oldSegmentOther} = modalState
         const [productList, setProductList] = useState(false);
  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getDiscount()
    }
  })

  useEffect(() => {
    getColumns()
    getProductList()
    getBankDetails()
    getPaymentTypes()
    getSegment()
  }, [])

  function onChange(value, type) {
    setState(prevState =>({...prevState, [type]: value}))
    setDisable(false)
    setCurrent(1)
 }

  function getProductList(){
    banner_service.get_product_list().subscribe((r) => {
    const obj =[];
    r.response.data.forEach(function (elem) {
      obj.push({
        id: elem.id,
        name: elem.name + ' - ' + elem.sku
      });
    });
    setProductList(obj)
      setProductData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getDiscount(){
    setSpinEnable(true)
    setDiscountData([])
    const product_name = productName?.split('-');
    const payload = {
      page: current,
      valueByCouponCode: valueByCouponCode === undefined ? '' : valueByCouponCode.toUpperCase(),
      variant: valueByVariant ?? '',
      by_sku: product_name?.[1].trim() ?? valueBySku ?? '',
      by_active_date:valueByActiveDate ?? '',
      by_segment:valueBySeg?valueBySeg :'',
      by_discount_qty:valueByDiscount??'',
      activated:valueByActivate??'',
      by_product:product_name?.[0] ?? '',
    }
    ads_service.get_product_discount(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setSpinEnable(false)
      setDiscountData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  // console.log('segmentData',segmentData)
  function getSegment(){
    ads_service.get_segment().subscribe((r) => {
      let city = r?.response?.segments.filter(item => item.category === 'city')
      let other = r?.response?.segments.filter(item => item.category === 'other')
      setSegmentData(city)
      setSegmentDataOther(other)
    },
    (err)=>{
      // setSegmentData(segments)
      console.log(err)
    })
  }

  function getBankDetails(){
    ads_service.get_bank_details().subscribe((r) => {
      setBankDetails(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getPaymentTypes(){
    ads_service.get_payment_type().subscribe((r) => {
      setPaymentType(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }
  function getColumns(){
    discountColumns.map(data => {
      columns.push(data)
    })
    columns.unshift({
        title: "Product",
        dataIndex: "product",
        key: "product",
        align: "center",
        width: 200
    })
    columns.push(
    {
      title: "Created On",
      dataIndex: "",
      key: "",
      align: "center",
      width: 150,
      render: (record) => {
        return (
          <>
            <div>{handleDateFormat(record.created_at)}</div>
            <div>{getStaticTime(record.created_at)}</div>
          </>
        );
      },
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (
          <Button type="primary" onClick={() => showModal(record, 'Edit Product Discount')}>Edit</Button>
        )
      }
    })
    setColumns(filterUnique(columns))
  }

  const filterUnique=(data)=>{
    let distinct={};
    let temp=[];
    let dataa=[...data]
    for(let i=0;i<dataa.length;i++){
      if(!distinct[dataa[i].title]){
        temp.push(dataa[i])
        distinct[dataa[i].title]=1;
      }
    }
    return temp;
  }
  function pagination(page) {
    setCurrent(page)
    setDiscountData([])
    setRenderData(false)
  }
  useEffect(() =>{
    if(valueByVariant === 'non_bulk_discount'){
      let temp=[...columns];
      let newTemp=[];
      for(let i=0;i<temp.length;i++){
          if(temp[i].title==="Coupon Code"||temp[i].title==="Is Visible"){
            continue;
          }else{
            newTemp.push(temp[i])
          }
      }
      setColumns(newTemp)
    }
    if(valueByVariant === 'bulk_discount'){
      // setColumns([])
      getColumns()
    }
  },[state])
  function reset() {
    setDisable(true)
    setCurrent(1)
    setState({
      valueByMobno: undefined,
      valueByVariant: 'non_bulk_discount',
      valueBySku:undefined,
      valueByDiscount:undefined,
      valueBySegment:undefined,
      valueBySegmentOther: undefined,
      valueByActivate:undefined,
      productName:undefined,
      valueByActiveDate:undefined,
      showDate:undefined
    })
    setDiscountData([])
    setRenderData(false)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };
  function showModal(data, type) {
    setMode(type)
    if(type === 'Edit Product Discount'){
        var newSegment = [], newSegmentOther = []
        data?.segments_attributes_prefix_city?.length > 0 && data.segments_attributes_prefix_city.map(data =>{
          newSegment.push(data.slug)
        })
        data?.segments_attributes_prefix_other?.length > 0 && data.segments_attributes_prefix_other.map(data =>{
          newSegmentOther.push(data.slug)
        })
        // console.log(newSegment)
        setModalState({valueByCoupon: data.coupon_code, title: data.title,
                       description: data.description, discountValue: data.discount_percentage_or_value,
                       valueByRadio: data.is_visible, currentActive: data.id,
                       discountType: data.discount_type, min_order: data.min_order_value, 
                       discount_qty: data.discount_qty, applicableCount: data.applicable_count,
                       valid_from_date: moment(data.valid_from),
                       valid_from: data.valid_from,
                       valid_to_date: moment(data.valid_to),
                       valid_to: data.valid_to,
                       valueByProduct: data.product_id,
                       valueByRadioAuto: data.is_auto_applied,
                       productDiscountType: data.product_discount_type,
                       valueByRadioActive: data.is_active,
                       valueByRadioFirst: data.first_order_flag,
                       success_message: data.success_message,
                       is_society_discount: data.is_society_discount,
                       valueByActivation: data.activation_day,
                       paymentFlag: data.payment_flag,
                       paymentTypeId: data.payment_type_id,
                       bankDetailId: data.bank_detail_id,
                       valueByRadioDS: data.all_darkstores,
                       valueByDarkstore: data.active_darkstores,
                       valueBySegment: newSegment,
                       oldSegment: data.segments_attributes_prefix_city,
                       valueBySegmentOther: newSegmentOther,
                       oldSegmentOther: data.segments_attributes_prefix_other,
                       is_coupon: data.is_coupon,
                       variant: data.variant
                       })
      
    }else{
      setModalState({is_coupon: false, variant: 'non_bulk_discount', discountType: 'value'})
    }
    modalRef.current.showModal(type, data)
  }
  function onChangeModal(value, type) {
    if(type === 'valueByRadio' || type === 'valueByRadioAuto' || type === 'valueByRadioActive' 
       || type === 'valueByRadioFirst' || type === 'is_society_discount' || type === 'paymentFlag'
       || type === 'valueByRadioDS' || type === 'is_coupon' || type=== 'variant'){
      setModalState(prevState =>({...prevState, [type]: value}))
      if(type === 'variant' && value === 'non_bulk_discount'){
        setModalState(prevState =>({...prevState, is_coupon: false, discountType: 'value'}))
      }
    }else{
      setModalState(prevState =>({...prevState, [type]: value === "" || value === null ? undefined : value}))
    }
  }

  function onChangeDate(date, dateString, type, valueDate) {
    setModalState(prevState =>({...prevState, [type]: date, [valueDate]: dateString}))
  }

  function onCancelModal() {
    setModalState({valueByCoupon: undefined, title: undefined, description: undefined,
                   discountValue: undefined, discountType: undefined, min_order: undefined, 
                   discount_qty: undefined, valid_from_date: '', valid_from: undefined, 
                   valid_to_date: '', valid_to: undefined, applicableCount: undefined,
                   valueByRadio: undefined, currentActive: undefined, valueByRadioAuto: undefined,
                   valueByProduct: undefined, productDiscountType: undefined, valueByRadioActive: undefined,
                   valueByRadioFirst: undefined, customer_type: undefined, success_message: undefined,
                   is_society_discount: undefined, valueByActivation: [], paymentFlag: undefined, 
                   paymentTypeId: undefined, bankDetailId: undefined, variant: 'non_bulk_discount',
                   valueByRadioDS: undefined, valueByDarkstore: [], is_coupon: undefined, valueBySegment:[],
                   oldSegment: [], valueBySegmentOther: [], oldSegmentOther: []})
  }

  function getSegmentForEdit(segmentType, oldSeg, newSeg) {
    var newSegment = []
    if (segmentType === 'all_users') {
      if (oldSeg?.length > 0) {
        oldSeg.map(data => {
          newSegment.push({ id: data.id, _destroy: true })
        })
      }
    } else {
      if (oldSeg.length > 0) {
        for (var i = 0; i < oldSeg.length; i++) {
          var newList = newSeg.find(x => x === oldSeg[i].slug)
          if (!newList) {
            newSegment.push({ id: oldSeg[i].id, _destroy: true })
          }
        }
      }
      newSeg?.length > 0 && newSeg.map(data => {
        if (!oldSeg.find(x => x?.slug === data)) {
          newSegment.push({ slug: data })
        }
      })
    }
    return newSegment;
  }

  function modalSubmit(segmentType) {
    if(is_coupon === undefined){
      openNotificationWithIcon('error', "Please fill all required fields")
      modalRef.current.stopLoading()
      return
    }
    if(is_coupon){
      if(!valueByCoupon || !description || discountValue === undefined || !discountType ||
        min_order === undefined || !valid_from || !valid_to || applicableCount === undefined ||
        valueByRadio === undefined || !valueByProduct || valueByRadioAuto === undefined || !productDiscountType ||
        discount_qty === undefined || valueByRadioActive == undefined || is_society_discount === undefined || paymentFlag === undefined){
           openNotificationWithIcon('error', "Please fill all required fields")
           modalRef.current.stopLoading()
           return
      }
      if(paymentFlag){
        if(!paymentTypeId || !bankDetailId){
          openNotificationWithIcon('error', "Please fill all required fields")
          modalRef.current.stopLoading()
          return
        }
      }
    }else{
      if(!description || discountValue === undefined || !discountType ||
         !valid_from || !valid_to || !valueByProduct ||
         discount_qty === undefined || valueByRadioActive == undefined){
           openNotificationWithIcon('error', "Please fill all required fields")
           modalRef.current.stopLoading()
           return
     }
    }
    if(segmentType === 'select_segment' && !valueBySegment?.length && !valueBySegmentOther?.length){
      openNotificationWithIcon('error', "Please select a segment")
      modalRef.current.stopLoading()
      return
    }
    // if(valueBySegment.length === 0){
    //   openNotificationWithIcon('error', "Please Select Segment")
    //   modalRef.current.stopLoading()
    //   return
    // }
    // if(variant === 'bulk_discount' && (valueByRadioDS === undefined || (!valueByRadioDS && valueByDarkstore.length === 0))){
    //   openNotificationWithIcon('error', "Please set darkstore")
    //   modalRef.current.stopLoading()
    //   return
    // }
    const payload = {
        product_discount: {
            coupon_code: valueByCoupon,
            title: title,
            description: description,
            product_id: valueByProduct,
            discount_percentage_or_value: discountValue,
            discount_type: discountType,
            min_order_value: min_order,
            valid_from: valid_from,
            valid_to: valid_to,
            applicable_count: applicableCount,
            discount_qty: discount_qty,
            product_discount_type: productDiscountType,
            is_auto_applied: valueByRadioAuto,
            is_active: valueByRadioActive,
            // first_order_flag: valueByRadioFirst,
            success_message: success_message,
            payment_flag: paymentFlag,
            is_coupon: is_coupon,
            variant: variant,
        },
        // active_darkstores: valueByRadioDS || variant === 'non_bulk_discount' ? [] : valueByDarkstore,
        is_society_discount: is_society_discount,
        activation_day: valueByActivation,
        is_visible: valueByRadio,
        by_city: localStorage.getItem('city') ?? ''
    }
    if(paymentFlag){
      payload.product_discount.payment_type_id = paymentTypeId
      payload.product_discount.bank_detail_id = bankDetailId
    }
    if (mode === 'Edit Product Discount') {

        payload.product_discount.segments_attributes_prefix_city =  getSegmentForEdit(segmentType, oldSegment, valueBySegment)
        payload.product_discount.segments_attributes_prefix_other =  getSegmentForEdit(segmentType, oldSegmentOther, valueBySegmentOther)
        payload.product_discount.id = currentActive
        ads_service.update_product_discount(payload).subscribe((r) => {
            openNotificationWithIcon('success','Edited Successfully')
            getDiscount()
            modalRef.current.handleCancel()
        },
        (err)=>{
            console.log(err)
            openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
            modalRef.current.stopLoading()
        })
    }else{
      // if(variant === 'bulk_discount' && !customer_type){
      //   openNotificationWithIcon('error', "Please fill all required fields")
      //   modalRef.current.stopLoading()
      //   return
      // }
      payload.product_discount.segments_attributes_prefix_city = valueBySegment?.map(data => { return { slug: data }})
      payload.product_discount.segments_attributes_prefix_other = valueBySegmentOther?.map(data => { return { slug: data }})
      // if(variant === 'bulk_discount'){
      //   payload.product_discount.customer_tagging_parameter_id = customer_type === 'none' ? null : customer_type
      // }else{
      //   payload.product_discount.customer_tagging_parameter_id = null
      // }
      ads_service.create_product_discount(payload).subscribe((r) => {
          openNotificationWithIcon('success','Created Successfully')
          getDiscount()
          modalRef.current.handleCancel()
      },
      (err)=>{
          console.log(err)
          openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
          modalRef.current.stopLoading()
      })
    }  
  }
  const handleDatePicker=(value,dateString)=>{
    setState((prev)=>({...prev,showDate:true,valueByActiveDate:dateString?.replaceAll('/','-')}))
  }

  return(
    <div className="layer">
      {discountData && productData && bankDetails && paymentType && segmentData && segmentDataOther && productList?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                {/* <Input placeholder="Product Name" value={productName} className="frz-filter" onChange={(e)=>onChange(e.target.value, 'productName')}/>
                 */}
                 <Select className='frz-w-300 frz-m-10' value={productName} 
                    showSearch
                    placeholder="Select Product" onChange={(e) => onChange(e, 'productName')}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
              {productList.map(data => {
                return(
                  <Option key={data.id} value={data.name}>{data.name}</Option>
                )
              })}
            </Select>
                <Input placeholder="SKU Code" value={valueBySku} className="frz-filter" onChange={(e) => onChange(e.target.value, 'valueBySku')} />
                <Input placeholder="Coupon Code"
                  onChange={(e) => onChange(e.target.value, 'valueByCouponCode')}
                  value={valueByCouponCode} className="frz-filter" />
                <Search className="frz-filter" placeholder="Variant" value={valueByVariant}
                        onChange={(e) => onChange(e, 'valueByVariant')} type="valueByVariant" data={variantData}/>
                <Search className="frz-filter" placeholder="Segment" value={valueBySeg}
                        onChange={(e) => onChange(e, 'valueBySeg')} type="valueBySeg" data={[...segmentData, ...segmentDataOther]}/>
                <DatePicker value={showDate?moment(valueByActiveDate,'YYYY-MM-DD'):''}  placeholder='Active Date' className="frz-filter" onChange={handleDatePicker}/>
                <Input value={valueByDiscount} placeholder="Discount Qty" className="frz-filter" onChange={(e)=>onChange(e.target.value, 'valueByDiscount')}/>
                <Select value={valueByActivate} className="frz-filter" placeholder='activated' onChange={(e) => onChange(e, 'valueByActivate')}>
                    <Option value='true'>True</Option>
                    <Option value='false'>False</Option>
                </Select>
                <Button disabled={disable} onClick={getDiscount} className="frz-dwn"
                  type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} className="frz-m-5"
                  type="primary" ghost shape="circle" icon={<ReloadOutlined />} />
                <span className="fl-right">
                  <FrzDynamicDownload name={'Non Bulk Report'} fileName={'Non Bulk Report'} url={'admin_dashboard/v1/downloads/non_bulk_product_discount_list'}
                    payload={'?'} type={'job'} reportFunc={'fraazo_report'} jobReportFunc={'download_reports_job_fraazo'}
                    base={'fraazo'} />
                </span>
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Create Product Discount')} style={{width: 200, margin:10}}
                        type="primary">Create Product Discount</Button>
              </Col>
            </Card>
          </Row>
          {discountData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={discountData} columns={columns}/>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <DiscountModal modalSubmit={modalSubmit} ref={modalRef}
                         onChangeModal={onChangeModal}
                         onCancelModal={onCancelModal}
                         onChangeDate={onChangeDate}
                         segmentData={segmentData}
                         segmentDataOther={segmentDataOther}
                         customer_type={customer_type}
                         valueByCoupon={valueByCoupon} 
                         title={title} bankDetails={bankDetails}
                         productData={productData}
                         description={description} paymentType={paymentType}
                         discountValue={discountValue} 
                         discountType={discountType} bankDetailId={bankDetailId}
                         min_order={min_order} paymentTypeId={paymentTypeId}
                         discount_qty={discount_qty} paymentFlag={paymentFlag}
                         valid_from_date={valid_from_date}
                         valid_to_date={valid_to_date}
                         applicableCount={applicableCount}
                         valueByProduct={valueByProduct}
                         valueByRadio={valueByRadio} success_message={success_message}
                         productDiscountType={productDiscountType}
                         valueByRadioAuto={valueByRadioAuto}
                         valueByRadioActive={valueByRadioActive}
                         valueByRadioFirst={valueByRadioFirst}
                         valueByActivation={valueByActivation}
                         is_society_discount={is_society_discount}
                         valueBySegment={valueBySegment}
                         valueBySegmentOther={valueBySegmentOther}
                         valueByRadioDS={valueByRadioDS} is_coupon={is_coupon} variant={variant}
                         valueByDarkstore={valueByDarkstore}/>
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default ProductDiscount
