import React from 'react'
import { Menu, Checkbox } from 'antd';

const CheckboxGroup = Checkbox.Group;

const CityList = ({ allOptions, onChange, checkedList, cloneCheckedlist, cityWiseDs, activeCityId, onCityChange, isChanged, resetCheckList }) => {

  const handleClicks = (e) => {
    e.stopPropagation();
  }
  return (
    cityWiseDs.map((data, index) => {
      return (
        <div key={index} className="frz-bottom-border">
          <div className={`${"frz-side-panle-list frz-dis-flex frz-justify-sb"} ${activeCityId === data.id ? "frz-side-panle-list-active" : ""}`}
            onClick={() => activeCityId === data.id ? "" : isChanged ? resetCheckList(data.id) : onCityChange(data.id)}>
            <div>
              {data.name}
            </div>
            <div>
              <Checkbox
                className={cloneCheckedlist.includes(data.id) ? "frz-custom-checkbox-test" : "frz-custom-checkbox-disable-test"}
                checked={cloneCheckedlist.includes(data.id)}
                defaultChecked={checkedList.includes(data.id)}
                disabled={activeCityId !== data.id}
                onChange={(e) => onChange(e.target.checked, data.id)}>
                <div onClick={(e) => handleClicks(e)} className={cloneCheckedlist.includes(data.id) ? "frz-custom-checkbox-text" : "frz-custom-checkbox-text-disable"}>
                  Active
                </div>
              </Checkbox>
            </div>
          </div>
        </div>
      )
    })
    // <Menu
    //   // onClick={this.handleClick}
    //   style={{ width: 256 }}
    //   defaultSelectedKeys={[`${activeCityId}`]}
    //   // defaultOpenKeys={['sub1']}
    //   mode="inline"
    // >
    //   {cityWiseDs.map((data, index) => {
    //     return (
    //       <Menu.Item key={data.id} onClick={onCityChange}>
    //         <div className="frz-dis-flex frz-justify-sb">
    //           <div>
    //             {data.name}
    //           </div>
    //           <div>
    //             <Checkbox defaultChecked={checkedList.includes(data.id)} onChange={(e) => onChange(e, data.id)} />
    //           </div>
    //         </div>
    //       </Menu.Item>
    //     )
    //   })}

    // </Menu>
  )
}

export default CityList
