import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber, Pagination, DatePicker, Popconfirm, notification, Table } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { captializestr } from '../../_modules/captialize';
import { DarkstoreService } from '../../_services/darkstore';

const darkstore_service = new DarkstoreService()

function DarkstoreUndelivered() {
  const [darkstoreData, setDarkstoreData] = useState(false)
  const [statsData, setStatsData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [state, setState] = useState({ valueByDeliveryDate: '', date: '' });
  const { valueByDeliveryDate, date } = state

  let temp = []
  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getDarkstores()
    }
  })

  useEffect(() => {
    if (darkstoreData.length) {
      getColumns()
    }
  }, [darkstoreData])

  function reset() {
    setState({ valueByDeliveryDate: '', date: '' })
    setDisable(true)
    setDarkstoreData([])
    setRenderData(false)
  }

  function getDarkstores() {
    setSpinEnable(true)
    setDarkstoreData([])
    const payload = {
      date: !valueByDeliveryDate ? '' : valueByDeliveryDate,
    }
    darkstore_service.get_darkstores_undelivered(payload).subscribe((r) => {
      setSpinEnable(false)
      setStatsData(r.response.undelivered_darkstore_stats_wise_data)
      setDarkstoreData(r.response.cumulative_data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getColumns() {
    const test = darkstoreData[0]
    Object.entries(test).map(([key, value]) => {
      temp.push({
        title: captializestr(key),
        dataIndex: key,
        key: key,
        align: "center",
        defaultSortOrder: 'descend',
        sorter: (a, b) => a[key] - b[key],
        render: (record) => {
          return (
            <>
              {
                key === 'poor_rating_count' ?
                  <div className="color" style={{ color: 'red' }}> {record}</div> :
                  <div> {record} </div>
              }
            </>
          );
        }
      })
    })
    setColumns(temp)
  }

  return (
    <div className="layer">
      {darkstoreData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                {statsData && Object.entries(statsData).map(([key, value]) => {
                  return (
                    key.includes("amount") ?
                      <span key={key} className="first_row" style={{ padding: 15, lineHeight: 3, display: 'inline-block', paddingBottom: 0, paddingTop: 0 }}><b>{key.replace("total_", "").replace("amount", "Amt").split('_').join(' ').charAt(0).toUpperCase() + key.replace("total_", "").replace("amount", "amt").split('_').join(' ').slice(1)}: </b>₹{value}</span> :
                      <span key={key} className="first_row" style={{ padding: 15, lineHeight: 3, display: 'inline-block', paddingBottom: 0, paddingTop: 0 }}><b>{key.replace("total_", "").split('_').join(' ').charAt(0).toUpperCase() + key.replace("total_", "").split('_').join(' ').slice(1)}: </b>{value}</span>
                  )
                })}
                <Button onClick={reset} style={{ margin: 10 }}
                  type="primary" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          {darkstoreData.length > 0 ?
            <Row>
              <Col span={24}>
                {/* <MainTable dataSource={darkstoreData} columns={columns}/> */}
                <Card style={{ paddingTop: 5, marginTop: 20 }}>
                  <Row>
                    <Col span={24}>
                      <Table
                        columns={columns}
                        dataSource={darkstoreData}
                        pagination={false}
                        scroll={{ x: 240 }}
                        rowKey="darkstore_name"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default DarkstoreUndelivered
