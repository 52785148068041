import React from 'react';
import { handleDateFormat } from '../../../_modules/date';
import {getStaticTime} from '../../../_modules/time';

export const billingColumns = [
    {
      title: 'Rider Name',
      dataIndex: 'rider_name',
      key: 'rider_name',
      align: 'center',
      width: 120,
    },
    {
      title: 'Partner Name',
      dataIndex: 'partner_name',
      key: 'partner_name',
      align: 'center',
      width: 120,
    },
    {
      title: 'From Date',
      dataIndex: '',
      key: '',
      align: 'center',
      width: 120,
      render: (record) => {
        return <>
                <div>{handleDateFormat(record.start_date)}</div>
                <div>{getStaticTime(record.start_date)}</div>
               </>
      }
    },
    {
      title: 'To Date',
      dataIndex: '',
      key: '',
      align: 'center',
      width: 120,
      render: (record) => {
        return <>
                <div>{handleDateFormat(record.end_date)}</div>
                <div>{getStaticTime(record.end_date)}</div>
               </>
      }
    },
    {
      title: 'Store Name',
      dataIndex: 'store_name',
      key: 'store_name',
      align: 'center',
      width: 150,
    },
    {
      title: 'Distance',
      dataIndex: 'total_distance',
      key: 'total_distance',
      align: 'center',
      width: 100
    },
    {
      title: 'Fixed Cost',
      dataIndex: 'total_fixed_cost',
      key: 'total_fixed_cost',
      align: 'center',
      width: 100
    },
    {
      title: 'Orders',
      dataIndex: 'total_orders',
      key: 'total_orders',
      align: 'center',
      width: 100
    },
    {
      title: 'Distance Earning',
      dataIndex: 'total_distance_earning',
      key: 'total_distance_earning',
      align: 'center',
      width: 100
    },
    {
      title: 'In Time Delivery Incentive',
      dataIndex: 'total_incentive',
      key: 'total_incentive',
      align: 'center',
      width: 100
    },
    {
      title: 'MG Bonus',
      dataIndex: 'total_mg_bonus',
      key: 'total_mg_bonus',
      align: 'center',
      width: 100
    },
    {
      title: 'Weekly Order Bonus',
      dataIndex: 'weekly_bonus_order_level',
      key: 'weekly_bonus_order_level',
      align: 'center',
      width: 100
    },
    {
      title: 'LoggedIn Incentive',
      dataIndex: 'loggedin_incentive',
      key: 'loggedin_incentive',
      align: 'center',
      width: 100
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
      key: 'total_amount',
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
    },
    {
      title: 'Created By',
      dataIndex: 'updated_by',
      key: 'updated_by',
      align: 'center',
      width: 200,
    },
  ]
  