import { Http } from '../_modules/http'

export class FraudulentService {

  http = new Http()

  get_refund_list(payload){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/refunds/fraudulent_refund_tracking?page=' 
                + payload.page + '&priority=' + payload.priority + '&status=' + payload.status 
                + '&darkstore_id=' + payload.darkstore_id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  update_refund(id){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/refunds/update_fraudulent_refund_status?id='
                + id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, headers)
  }

}
