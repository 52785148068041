import React from 'react'
import { Button } from 'antd'
import { EditOutlined } from '@ant-design/icons';

export const clusterColumns = (onEdit) => [
  {
    title: 'Cluster Title',
    dataIndex: 'title',
    key: 'title',
    align: "center",
  },
  {
    title: 'Cluster Items',
    dataIndex: 'clutser_items',
    key: 'clutser_items',
    align: "center",
  },
  {
    title: 'Edit',
    dataIndex: '',
    key: '',
    align: "center",
    render: (record) => <div>
      <Button onClick={() => onEdit(record)} icon={<EditOutlined />}></Button>
    </div>
  }
]

export const bannerColumns = (onEdit) => [
  {
    title: 'Cluster Title',
    dataIndex: 'title',
    key: 'title',
    align: "center",
  },
  {
    title: 'Cluster Items',
    dataIndex: 'clutser_items',
    key: 'clutser_items',
    align: "center",
  },
  {
    title: 'Edit',
    dataIndex: '',
    key: '',
    align: "center",
    render: (record) => <div>
      <Button onClick={() => onEdit(record)} icon={<EditOutlined />}></Button>
    </div>
  }
]

export const landingColumns = (onEdit) => {
  return [
    {
      title: 'Landing Title',
      dataIndex: 'title',
      key: 'title',
      align: "center",
    },
    {
      title: 'Created By',
      dataIndex: 'created_by_name',
      key: 'created_by_name',
      align: "center",
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: "center",
      render: (record) => <div>
        <Button onClick={() => onEdit(record)} icon={<EditOutlined />}></Button>
      </div>
    }
  ]
}