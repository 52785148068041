import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification, Table } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {DarkstoreService} from '../../_services/darkstore';
import {darkstoreQualityColumns} from '../../components/antd/columns/master/darkstores';
import moment from 'moment';

const darkstore_service = new DarkstoreService()

function DarkstoreQuality(){
  const [darkstoreData, setDarkstoreData] = useState(false)
  const [statsData, setStatsData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [mode, setMode] = useState(undefined)
  const [state, setState] = useState({valueByDeliveryDate: '', date: ''});
  const {valueByDeliveryDate, date } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getDarkstores()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function reset() {
    setState({valueByDeliveryDate: '', date: ''})
    setDisable(true)
    setDarkstoreData([])
    setRenderData(false)
  }


  function getDarkstores(){
    setSpinEnable(true)
    setDarkstoreData([])
    const payload = {
      date: !valueByDeliveryDate ? '' : valueByDeliveryDate,
    }
    darkstore_service.get_darkstores_quality(payload).subscribe((r) => {
      setSpinEnable(false)
      setStatsData(r.response.cumulative_data)
      setDarkstoreData(r.response.darkstore_wise_data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function disabledDate(current){
    // Can select days before today and today
    return current && current > moment().add(0, "day");
  }

  function onChangeDate(date, dateString) {
    setState(prevState =>({...prevState, date: date, valueByDeliveryDate: dateString}))
    setDisable(false)
    setCurrent(1)
  }

  function getColumns(){
    darkstoreQualityColumns.map(data => {
      columns.push(data)
    })
    setColumns(columns)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  return(
    <div className="layer">
      {darkstoreData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <DatePicker value={date} onChange={onChangeDate} placeholder="Date"
                  style={{ width: 200, margin: 10 }} disabledDate={disabledDate} />
                <Button disabled={disable} onClick={getDarkstores} style={{ width: 100, margin: 10 }}
                  type="primary">Search</Button>
                <Button onClick={reset} style={{margin:10}}
                  type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>  
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <span style={{padding: 15, lineHeight: 3}}><b>Today's Orders: </b>{statsData.total_order_count}</span>
                <span style={{padding: 15}}><b>Bad Quality Complaint: </b>{statsData.total_bad_quality_complaint_count}</span>
                <span style={{padding: 15}}><b>Complaint Percentage: </b>{statsData.total_complaint_percentage?.toFixed(0)??0} %</span>
                <span style={{padding: 15}}><b>Frequency Per Order: </b>{statsData.total_freequency_per_order}</span>
              </Col>
            </Card>
          </Row>
          {darkstoreData.length > 0 ?
            <Row>
              <Col span={24}>
                {/* <MainTable dataSource={darkstoreData} columns={columns}/> */}
                <Card style={{ paddingTop: 5, marginTop: 20 }}>
                    <Row>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                dataSource={darkstoreData}
                                pagination={false}
                                scroll={{ x: 240 }}
                                rowKey="darkstore_name"
                            />
                        </Col>
                    </Row>
                </Card>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default DarkstoreQuality
