import React, { useEffect, useState, useRef } from "react";
import { Spin, Button, Card, Row, Col, Pagination, } from "antd";
import CreateProductCluster from "../../components/master/product_cluster/modal.js";
import { ClusterService } from '../../_services/clusters.js';
import { clusterColumns } from "../../components/antd/columns/product_cluster.js";
import { openNotificationWithIcon } from '../../_modules/notification'
import { BannersService } from '../../_services/banners';
import { EditOutlined } from '@ant-design/icons';
import ClusterProductTable from "../../components/master/product_cluster/clusterProductTable.js";
import { clusterNestedColumns } from "../../components/antd/columns/product_cluster_nested.js";
import ClusterTable from "../../components/master/product_cluster/clusterTable.js";
import ModalActivaition from "../../components/master/category/modalactivaition.js";

const cluster_service = new ClusterService();
const banner_service = new BannersService();

function ProductCluster() {
  const modalRef = useRef(null);
  const [updateData, setUpdateData] = useState(false);
  const [renderData, setRenderData] = useState(false);
  const [current, setCurrent] = useState(1);
  const [columns, setColumns] = useState([]);
  const [spinEnable, setSpinEnable] = useState(false);
  const [productData, setProductData] = useState(false);
  const [total, setTotal] = useState(false)
  const [status, setStatus] = useState(false);
  const [typeOfCluster, setTypeOfCluster] = useState(false);
  const [nestedCol, setNestedCol] = useState(false);
  const [fetchColm, setFetchColm] = useState(false);
  const [rowData, setRowData] = useState(false)
  const [clusterId, setClusterId] = useState(false)
  const [activationModal, setActivationModal] = useState(false)
  const [updateNestedData, setUpdateNestedData] = useState(false)

  useEffect(() => {
    if (!updateData) {
      setUpdateData(true);
      getProductList()
      getClusters();
      getStatus();
    }
  });

  useEffect(() => {
    if (fetchColm) {
      setFetchColm(false);
      getColumns()
    }
  });

  useEffect(() => {
    getColumns();
  }, []);

  const handleOnActivationModal = (value, record) => {
    if (record) {
      setRowData(record)
      setClusterId(record.id)
    } else {
      setRowData(false)
      setClusterId(false)
    }
    setActivationModal(value)
  }


  function getStatus() {
    cluster_service.getClustersStaticData().subscribe((r) => {
      setStatus(r.response.data.statuses)
      setTypeOfCluster(r.response.data.type_of_clusters)
    },
      (err) => {
        console.log(err)
      })
  }

  function getClusters(data) {
    const payload = {
      page: current,
    };
    setSpinEnable(true);
    cluster_service.getClusters(payload).subscribe(
      (r) => {
        setSpinEnable(false)
        setTotal(r.response.meta.total_pages * 50);
        setRenderData(r.response.data);
      },
      (err) => {
        console.log(err);
      })
  }

  function getColumns() {
    let temp = []
    clusterColumns.map((data) => {
      temp.push(data);
    });
    temp.push(
      {
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (
            <div className="frz-dis-flex">
              <Button name="Edit" icon={<EditOutlined />} onClick={() => showModal(record, 'Edit Product Cluster')}></Button>
              <Button className="frz-lm-10" type="primary" onClick={() => handleOnActivationModal(true, record)}>Active / Deactive</Button>
            </div>
          )
        },
      });
    setColumns(temp);
    setNestedCol(clusterNestedColumns);
  }

  function getProductList() {
    banner_service.get_product_list().subscribe((r) => {
      setProductData(r.response.data.map(x => ({ ...x, component_id: x.id, component_name: x.name })));
    },
      (err) => {
        console.log(err)
      })
  }

  function showModal(data, type) {
    modalRef.current.showModal(type, data)
  }

  function modalSubmit(params, id) {
    const obj = []
    params.product_component.forEach(function (elem) {
      obj.push({
        component_id: elem,
        component_type: typeOfCluster.find(x => x.cluster_type === params.type_of_cluster).component_type
      });
    });
    const payload = {
      id: id,
      body: {
        ...params, cluster_details_attributes: obj
      }
    }
    if (id) {
      cluster_service.updateClusters(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Edited Product Cluster Successfully')
        renderData[renderData.findIndex(x => x.id === r.response.data.id)] = r.response.data
        setRenderData([...renderData])
        setUpdateNestedData(r.response.data.id)
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err?.response?.errors?.[0] ?? 'API Error');
        })
    }
    else {
      cluster_service.createClusters(payload.body).subscribe(
        (r) => {
          openNotificationWithIcon("success", "Created Product Cluster Successfully");
          modalRef.current.handleCancel();
        },
        (err) => {
          console.log(err);
          modalRef.current.stopLoading();
          openNotificationWithIcon("error", err?.response?.errors?.[0] ?? 'API Error');
        }
      );
    }
  }

  function expandedRow(record) {
    return (
      <ClusterProductTable nestedTableData={record} renderData={renderData}
        nestedCol={nestedCol} updateNestedData={updateNestedData} setUpdateNestedData={setUpdateNestedData}/>
    )
  }

  function pagination(page) {
    setCurrent(page);
    setRenderData([]);
    setUpdateData(false);
  }

  return (
    <div className="layer-nofilter">
      {renderData && productData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Create Product Cluster')}
                  className="frz-w-200 frz-m-10" type="primary">Create Product Clusters</Button>
              </Col>
            </Card>
          </Row>
          {renderData.length > 0 ? (
            <Row >
              <Col span={24} >
                <ClusterTable columns={columns} expandedRow={expandedRow} dataSource={renderData} />
              </Col>
              <Pagination className="frz-tm-10" current={current} pageSize={50}
                total={total} onChange={pagination} showSizeChanger={false} />
            </Row>
          ) : spinEnable ? (
            <div className="spin-center">
              <Spin tip="...Loading" />
            </div>
          ) : (
            <div className="no-data">No Data Available</div>
          )}
          <CreateProductCluster modalSubmit={modalSubmit} ref={modalRef} data={renderData}
            productData={productData} status={status} typeOfCluster={typeOfCluster} />
        </div>
        : (<div className="spin-center">
          <Spin tip="...Loading" />
        </div>
        )}
      {activationModal ?
        <ModalActivaition handleOnActivationModal={handleOnActivationModal}
          activationModal={activationModal} clusterId={clusterId} rowData={rowData} /> : null}
    </div>
  );
}

export default ProductCluster;