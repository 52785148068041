import React, { useEffect, useState, useRef } from 'react';
import {
  Spin, Button, Card, Row, Col, Input, InputNumber,
  Pagination, DatePicker, Popconfirm, notification
} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { AdsService } from '../../_services/ads';
import MainTable from '../../components/antd/table';
import Search from '../../components/antd/search';
import { discountColumns } from '../../components/antd/columns/ads';
import DiscountModal from '../../components/ads/discount_modal';
import { handleDateFormat } from '../../_modules/date';
import { getStaticTime } from '../../_modules/time';
import moment from 'moment';
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const ads_service = new AdsService()

function DiscountModule() {
  const modalRef = useRef(null);
  const [discountData, setDiscountData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [renderColumns, setRenderColumns] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [operations, setOperations] = useState(false)
  const [createCoupons, setCreateCoupons] = useState(false)
  const [bankDetails, setBankDetails] = useState(false)
  const [paymentType, setPaymentType] = useState(false)
  const [disable, setDisable] = useState(true)
  const [mode, setMode] = useState(undefined)
  const [current, setCurrent] = useState(1)
  const [segmentData, setSegmentData] = useState(false)
  const [segmentDataOther, setSegmentDataOther] = useState([])
  const [state, setState] = useState({ valueByCouponCode: undefined, valueBySeg: undefined });
  const { valueByCouponCode, valueBySeg } = state
  const [modalState, setModalState] = useState({
    valueByCoupon: undefined, title: undefined, description: undefined,
    discountValue: undefined, discountType: undefined, min_order: undefined,
    max_order: undefined, valid_from_date: '', valid_from: undefined,
    valid_to_date: '', valid_to: undefined, applicableCount: undefined,
    valueByRadio: undefined, currentActive: undefined, valueByRadioFirst: undefined,
    valueByRadioActive: undefined, customer_type: undefined, success_message: undefined,
    is_society_discount: undefined, valueByActivation: [], paymentFlag: undefined,
    paymentTypeId: undefined, bankDetailId: undefined, valueByRadioDS: undefined,
    valueByDarkstore: [], valueBySegment: [], oldSegment: [], valueBySegmentOther: [], oldSegmentOther: []
  });
  const { valueByCoupon, title, description, discountValue, discountType, min_order, success_message,
    max_order, valid_from_date, valid_from, valid_to_date, valid_to, applicableCount, valueByActivation,
    valueByRadio, currentActive, valueByRadioFirst, valueByRadioActive, customer_type, is_society_discount,
    paymentFlag, bankDetailId, paymentTypeId, valueByDarkstore, valueByRadioDS, valueBySegment, oldSegment, 
    valueBySegmentOther, oldSegmentOther } = modalState

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getDiscount()
    }
  })

  useEffect(() => {
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['developer', 'super_admin', 'marketing_admin'].includes(data)) {
        setOperations(true)
      }
      if (['developer', 'super_admin', 'marketing', 'marketing_admin'].includes(data)) {
        setCreateCoupons(true)
      }
    })
    setRenderColumns(true)
    getBankDetails()
    getPaymentTypes()
    getSegment()
  }, [])

  function getSegment() {
    ads_service.get_segment().subscribe((r) => {
      let city = r?.response?.segments.filter(item => item.category === 'city')
      let other = r?.response?.segments.filter(item => item.category === 'other')
      setSegmentData(city)
      setSegmentDataOther(other)
    },
      (err) => {
        setSegmentData([])
        // setSegmentData(segments)
        console.log(err)
      })
  }

  useEffect(() => {
    if (renderColumns) {
      setRenderColumns(false)
      getColumns()
    }
  })

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }

  function getDiscount() {
    setSpinEnable(true)
    setDiscountData([])
    const payload = {
      page: current,
      valueByCouponCode: valueByCouponCode === undefined ? '' : valueByCouponCode.toUpperCase(),
      by_segment: valueBySeg === undefined ? '' : valueBySeg
    }
    ads_service.get_discount(payload).subscribe((r) => {
      setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
      setSpinEnable(false)
      setDiscountData(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getBankDetails() {
    ads_service.get_bank_details().subscribe((r) => {
      setBankDetails(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getPaymentTypes() {
    ads_service.get_payment_type().subscribe((r) => {
      setPaymentType(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getColumns() {
    discountColumns.map(data => {
      columns.push(data)
    })
    columns.push(
      {
        title: "Created On",
        dataIndex: "",
        key: "",
        align: "center",
        width: 150,
        render: (record) => {
          return (
            <>
              <div>{handleDateFormat(record.created_at)}</div>
              <div>{getStaticTime(record.created_at)}</div>
            </>
          );
        },
      },
      {
        title: 'Edit',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (
            <Button type="primary" onClick={() => showModal(record, 'Edit Discount')} disabled={!operations}>Edit</Button>
          )
        }
      })
    setColumns(columns)
  }

  function pagination(page) {
    setCurrent(page)
    setDiscountData([])
    setRenderData(false)
  }

  function reset() {
    setState({
      valueByMobno: undefined
    })
    setDisable(true)
    setCurrent(1)
    setDiscountData([])
    setRenderData(false)
  }

  function openNotificationWithIcon(type, msg) {
    notification[type]({ message: type.toUpperCase(), duration: 3, description: msg });
  };

  function showModal(data, type) {
    setMode(type)
    if (type === 'Edit Discount') {
      var newSegment = [], newSegmentOther = []
      data?.segments_attributes_prefix_city?.length > 0 && data.segments_attributes_prefix_city.map(data => {
        newSegment.push(data.slug)
      })
      data?.segments_attributes_prefix_other?.length > 0 && data.segments_attributes_prefix_other.map(data => {
        newSegmentOther.push(data.slug)
      })
      setModalState({
        valueByCoupon: data.coupon_code, title: data.title,
        description: data.description, discountValue: data.discount_percentage_or_value,
        valueByRadio: data.is_visible, currentActive: data.id,
        discountType: data.discount_type, min_order: data.min_order_value,
        max_order: data.max_value, applicableCount: data.applicable_count,
        valid_from_date: moment(data.valid_from),
        valid_from: data.valid_from,
        valid_to_date: moment(data.valid_to),
        valid_to: data.valid_to,
        valueByRadioFirst: data.first_order_flag,
        valueByRadioActive: data.is_active,
        success_message: data.success_message,
        is_society_discount: data.is_society_discount,
        valueByActivation: data.activation_day,
        paymentFlag: data.payment_flag,
        paymentTypeId: data.payment_type_id,
        bankDetailId: data.bank_detail_id,
        valueByRadioDS: data.all_darkstores,
        valueByDarkstore: data.active_darkstores,
        valueBySegment: newSegment,
        oldSegment: data.segments_attributes_prefix_city,
        valueBySegmentOther: newSegmentOther,
        oldSegmentOther: data.segments_attributes_prefix_other
      })

    }
    modalRef.current.showModal(type, data)
  }

  function onChangeModal(value, type) {
    if (type === 'valueByRadio' || type === 'valueByRadioFirst' || type === 'valueByRadioActive'
      || type === 'is_society_discount' || type === 'paymentFlag' || type === 'valueByRadioDS') {
      setModalState(prevState => ({ ...prevState, [type]: value }))
    } else {
      setModalState(prevState => ({ ...prevState, [type]: value === "" || value === null ? undefined : value }))
    }
  }

  function onChangeDate(date, dateString, type, valueDate) {
    setModalState(prevState => ({ ...prevState, [type]: date, [valueDate]: dateString }))
  }

  function onCancelModal() {
    setModalState({
      valueByCoupon: undefined, title: undefined, description: undefined,
      discountValue: undefined, discountType: undefined, min_order: undefined,
      max_order: undefined, valid_from_date: '', valid_from: undefined,
      valid_to_date: '', valid_to: undefined, applicableCount: undefined,
      valueByRadio: undefined, currentActive: undefined, valueByRadioFirst: undefined,
      valueByRadioActive: undefined, customer_type: undefined, success_message: undefined,
      is_society_discount: undefined, valueByActivation: [],
      paymentFlag: undefined, paymentTypeId: undefined, bankDetailId: undefined,
      valueByRadioDS: undefined, valueByDarkstore: [], valueBySegment: [],
      oldSegment: [], valueBySegmentOther: [], oldSegmentOther: []
    })
  }

  function getSegmentForEdit(segmentType, oldSeg, newSeg) {
    var newSegment = []
    if (segmentType === 'all_users') {
      if (oldSeg?.length > 0) {
        oldSeg.map(data => {
          newSegment.push({ id: data.id, _destroy: true })
        })
      }
    } else {
      if (oldSeg.length > 0) {
        for (var i = 0; i < oldSeg.length; i++) {
          var newList = newSeg.find(x => x === oldSeg[i].slug)
          if (!newList) {
            newSegment.push({ id: oldSeg[i].id, _destroy: true })
          }
        }
      }
      newSeg?.length > 0 && newSeg.map(data => {
        if (!oldSeg.find(x => x?.slug === data)) {
          newSegment.push({ slug: data })
        }
      })
    }
    return newSegment;
  }

  function modalSubmit(segmentType) {
    if (!valueByCoupon || !title || !description || discountValue === undefined || !discountType ||
      min_order === undefined || !valid_from || !valid_to || applicableCount === undefined ||
      valueByRadio === undefined || valueByRadioActive === undefined ||
      !success_message || is_society_discount === undefined || paymentFlag === undefined) {
      openNotificationWithIcon('error', "Please fill all required fields")
      modalRef.current.stopLoading()
      return
    }if(segmentType === 'select_segment' && !valueBySegment?.length && !valueBySegmentOther?.length){
      openNotificationWithIcon('error', "Please select a segment")
      modalRef.current.stopLoading()
    } else {
      if (paymentFlag) {
        if (!paymentTypeId || !bankDetailId) {
          openNotificationWithIcon('error', "Please fill all required fields")
          modalRef.current.stopLoading()
          return
        }
      }
      // if(valueByRadioDS === undefined || (!valueByRadioDS && valueByDarkstore.length === 0)){
      //   openNotificationWithIcon('error', "Please set darkstore")
      //   modalRef.current.stopLoading()
      //   return
      // }
      // if(valueBySegment.length === 0){
      //   openNotificationWithIcon('error', "Please Select Segment")
      //   modalRef.current.stopLoading()
      //   return
      // }
      const payload = {
        coupon_discount: {
          coupon_code: valueByCoupon,
          title: title,
          description: description,
          discount_percentage_or_value: discountValue,
          discount_type: discountType,
          min_order_value: min_order,
          valid_from: valid_from,
          valid_to: valid_to,
          applicable_count: applicableCount,
          max_value: max_order === undefined ? null : max_order,
          // first_order_flag: valueByRadioFirst,
          is_active: valueByRadioActive,
          success_message: success_message,
          payment_flag: paymentFlag,
        },
        // active_darkstores: valueByRadioDS ? [] : valueByDarkstore,
        is_society_discount: is_society_discount,
        activation_day: valueByActivation,
        is_visible: valueByRadio,
        by_city: localStorage.getItem('city') ?? ''
      }
      if (paymentFlag) {
        payload.coupon_discount.payment_type_id = paymentTypeId
        payload.coupon_discount.bank_detail_id = bankDetailId
      }
      if (mode === 'Edit Discount') {
        payload.coupon_discount.segments_attributes_prefix_city = getSegmentForEdit(segmentType, oldSegment, valueBySegment)
        payload.coupon_discount.segments_attributes_prefix_other = getSegmentForEdit(segmentType, oldSegmentOther, valueBySegmentOther)
        payload.coupon_discount.id = currentActive
        ads_service.update_discount(payload).subscribe((r) => {
          openNotificationWithIcon('success', 'Edited Successfully')
          getDiscount()
          modalRef.current.handleCancel()
        },
          (err) => {
            console.log(err)
            openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
            modalRef.current.stopLoading()
          })
      } else {
        // if(!customer_type){
        //   openNotificationWithIcon('error', "Please fill all required fields")
        //   modalRef.current.stopLoading()
        //   return
        // }
        payload.coupon_discount.segments_attributes_prefix_city = valueBySegment.map(data => { return { slug: data }})
        payload.coupon_discount.segments_attributes_prefix_other = valueBySegmentOther.map(data => { return { slug: data }})
        // payload.coupon_discount.customer_tagging_parameter_id = customer_type === 'none' ? null : customer_type
        ads_service.create_discount(payload).subscribe((r) => {
          openNotificationWithIcon('success', 'Created Successfully')
          getDiscount()
          modalRef.current.handleCancel()
        },
          (err) => {
            console.log(err)
            openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
            modalRef.current.stopLoading()
          })
      }
    }
  }

  return (
    <div className="layer">
      {discountData && bankDetails && paymentType && segmentData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Input placeholder="Coupon Code"
                  onChange={(e) => onChange(e.target.value, 'valueByCouponCode')}
                  value={valueByCouponCode} style={{ width: 200, margin: 10 }} />
                <Search className="frz-filter" placeholder="Segment" value={valueBySeg}
                  onChange={(e) => onChange(e, 'valueBySeg')} type="valueBySeg" data={[...segmentData, ...segmentDataOther]} />
                <Button disabled={disable} onClick={getDiscount} style={{ width: 100, margin: 10 }}
                  type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{ margin: 10 }}
                  type="primary" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Create Discount')} style={{ width: 150, margin: 10 }}
                  type="primary" disabled={!operations && !createCoupons}>Create Discount</Button>
              </Col>
            </Card>
          </Row>
          {discountData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={discountData} columns={columns} />
                <Pagination style={{ marginTop: 10, marginBottom: 10 }} current={current}
                  pageSize={50} total={state.total} onChange={pagination}
                  showSizeChanger={false} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <DiscountModal modalSubmit={modalSubmit} ref={modalRef}
            onChangeModal={onChangeModal}
            onCancelModal={onCancelModal}
            onChangeDate={onChangeDate}
            segmentData={segmentData}
            segmentDataOther={segmentDataOther}
            valueBySegment={valueBySegment}
            valueBySegmentOther={valueBySegmentOther}
            valueByCoupon={valueByCoupon}
            title={title} paymentType={paymentType}
            customer_type={customer_type}
            description={description}
            discountValue={discountValue}
            discountType={discountType} bankDetails={bankDetails}
            min_order={min_order} bankDetailId={bankDetailId}
            max_order={max_order} paymentTypeId={paymentTypeId}
            valid_from_date={valid_from_date}
            valid_to_date={valid_to_date} paymentFlag={paymentFlag}
            applicableCount={applicableCount}
            valueByRadio={valueByRadio}
            success_message={success_message}
            valueByRadioActive={valueByRadioActive}
            valueByRadioFirst={valueByRadioFirst}
            valueByActivation={valueByActivation}
            is_society_discount={is_society_discount}
            valueByRadioDS={valueByRadioDS} is_coupon={true}
            valueByDarkstore={valueByDarkstore} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default DiscountModule
