import { Http } from '../../_modules/http';
import FRAAZO from '../../_modules/http_headers';

export class LandingClusterService {

  http = new Http()

  getClusterList(urlParam) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/clusters?' + urlParam
    return this.http.get(url, FRAAZO())
  }

  getBannerList(urlParam) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/banners?' + urlParam
    return this.http.get(url, FRAAZO())
  }

  getProductSearch(urlParam) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/products/get_product_list'
    return this.http.get(url, FRAAZO())
  }

  getLandingList(urlParam) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/promotional_pages?' + urlParam
    return this.http.get(url, FRAAZO())
  }

  showLandingPageData(urlParam) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/promotional_pages/' + urlParam
    return this.http.get(url, FRAAZO())
  }

  updateLandingPageData(payload, urlParam) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/promotional_pages/' + urlParam
    return this.http.put(url, payload, FRAAZO())
  }

  createLanding(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/promotional_pages'
    return this.http.post(url, payload, FRAAZO())
  }

  getProductList(urlParam) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/clusters/' + urlParam
    return this.http.get(url, FRAAZO())
  }

  updateCluster(payload, urlParam) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/clusters/' + urlParam
    return this.http.put(url, payload, FRAAZO())
  }


  createCluster(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/clusters'
    return this.http.post(url, payload, FRAAZO())
  }

  createCluster(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/clusters'
    return this.http.post(url, payload, FRAAZO())
  }

  getUnitIndex(urlParam) {
    const url = process.env.REACT_APP_CUST_BFF_URL + 'admin_engine/api/v1/backend_driven/backend_driven_page_units?' + urlParam
    return this.http.get(url, FRAAZO())
  }

  getUnitData(urlParam) {
    const url = process.env.REACT_APP_CUST_BFF_URL + 'admin_engine/api/v1/backend_driven/units?' + urlParam
    return this.http.get(url, FRAAZO())
  }

  getStaticData() {
    const url = process.env.REACT_APP_CUST_BFF_URL + 'admin_engine/api/v1/backend_driven/backend_driven_page_units/static_data'
    return this.http.get(url, FRAAZO())
  }

  createUnit(payload) {
    const url = process.env.REACT_APP_CUST_BFF_URL + 'admin_engine/api/v1/backend_driven/backend_driven_page_units'
    return this.http.post(url, payload, FRAAZO())
  }

  updateUnit(payload, urlParam) {
    const url = process.env.REACT_APP_CUST_BFF_URL + 'admin_engine/api/v1/backend_driven/backend_driven_page_units/' + urlParam
    return this.http.put(url, payload, FRAAZO())
  }

  showUnitDetail(urlParam) {
    const url = process.env.REACT_APP_CUST_BFF_URL + 'admin_engine/api/v1/backend_driven/backend_driven_page_units/' + urlParam
    return this.http.get(url, FRAAZO())
  }

  updateSequence(payload) {
    const url = process.env.REACT_APP_CUST_BFF_URL + 'admin_engine/api/v1/backend_driven/backend_driven_page_units/update_sequence'
    return this.http.post(url, payload, FRAAZO())
  }

  getLandingStaticData() {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/promotional_pages/static_data'
    return this.http.get(url, FRAAZO())
  }
  // /admin_engine/api/v1/backend_driven/backend_driven_page_units/static_data
  // {{base_url}}/admin_engine/api/v1/backend_driven/units?by_date=2022-08-01
  // /admin_engine/api/v1/backend_driven/backend_driven_page_units?backend_driven_page_id=100&by_valid_today=2022-08-01&by_city=jaipur
}
