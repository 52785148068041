import React, {useState, useEffect, useRef} from 'react';
import {Table, Spin, Card, Button} from 'antd';
import {wastageProductColumns} from '../../antd/columns/wastage/wastage';
import {WastageService} from '../../../_services/wastage';
import { openNotificationWithIcon } from '../../../_modules/notification';

const wastage_service = new WastageService()

function NestedTable(props) {
  const modalRef = useRef(null);
  const [nestedOrder, setNestedOrder] = useState(false)
  const [loading, setLoading] = useState(false)
  const [columns, setColumns] = useState([])
  const [modalState, setModalState] = useState({sequence: undefined, is_active: undefined, currentActive: undefined, 
                                                product_id: undefined});
  const { sequence, is_active, currentActive, product_id} = modalState

  useEffect(() => {
    getItems()
    getColumns()
  }, [])
  
  function getItems(){
    const payload = {
      nodeId: props.data,
    }
    wastage_service.get_wastage_product_list(payload).subscribe((r) => {
      setNestedOrder(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  const handleOnRevert = (data)=>{
    setLoading(true)
    const payload ={
      loss_type: "wastage_reverted",
      node_code: props.nodeCode,
      items:[{
        current_wastage_qty:data.wastage_qty,
        product_id: data.id,
        qty: data.wastage_qty,
        stock_loss_item_id: data.stock_loss_item_id
      }]
    }
    wastage_service.revert_wastage(payload).subscribe((r) => {
        getItems()
        setLoading(false)
        openNotificationWithIcon('success', 'Wastage Reverted For' + data.name)
    },
      (err) => {
        setLoading(false)
        openNotificationWithIcon('error', 'Cannot Revert Wastage')
        console.log(err)
      })
  }

  function getColumns(){
    wastageProductColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: 'Revert Wastage',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return <Button title="Revert Wastage" disabled={record.is_reverted || record.loss_type === 'rtv'}
          onClick={() => handleOnRevert(record)}>
            Revert Wastage
        </Button>
      }
    })
    setColumns(columns)
  }

  function showModal(data, type) {
    setModalState({sequence: data.sequence, is_active: data.is_active, currentActive: data.id, product_id: data.product_id})
    modalRef.current.showModal(type, data)
  }

  return(
    <Card className="nested-table">
      {nestedOrder && !loading ?
        <div>
          {nestedOrder.length > 0 ?
            <Table columns={columns} pagination={false} rowKey="id"
                   dataSource={nestedOrder}/>
           :
            <div className="no-data-nested">No Items Found</div>
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </Card>
  )
}

export default NestedTable;
