import React, { useEffect, useState, useCallback } from 'react';
import { Table, Card, Spin } from 'antd';
import { ClusterService } from '../../../_services/clusters.js';
import { openNotificationWithIcon } from '../../../_modules/notification.js';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';

const cluster_service = new ClusterService();
const type = 'DraggableBodyRow';

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

const ClusterProductTable = ({ nestedCol, nestedTableData, updateNestedData, setUpdateNestedData }) => {
  const [productsColm, setProductsColm] = useState(false);
  const [updateNestedRow, setUpdateNestedRow] = useState(false);
  const [spinEnable, setSpinEnable] = useState(false);
  const [updateSeq, setUpdateSeq] = useState(false);
  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = productsColm[dragIndex];
      setProductsColm(
        update(productsColm, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
      setUpdateSeq(true)
    },
    [productsColm],
  );

  useEffect(() => {
    if (updateSeq) {
      setUpdateSeq(false)
      updateClusterSequence(productsColm)
    }
  }, [updateSeq])

  useEffect(() => {
    if (!updateNestedRow || updateNestedData === nestedTableData.id) {
      setUpdateNestedData(false)
      setUpdateNestedRow(false)
      showProductCluster(nestedTableData.id);
    }
  }, [updateNestedRow, updateNestedData]);

  function updateClusterSequence(props) {
    const payload = {
      sequence: props.map(a => a.id),
    }
    
    cluster_service.update_clusters_sequence(nestedTableData.id, payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Updated Successfully')
    },
      (err) => {
        console.log(err);
        openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
      }
    );
  }

  function showProductCluster(id) {
    setSpinEnable(true)
    setUpdateNestedRow(true);
    cluster_service.showClusters(id).subscribe((r) => {
      setSpinEnable(false);
      setProductsColm(r.response.data.cluster_details);
    },
      (err) => {
        console.log(err);
        openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
      }
    );
  }

  return (
    <div>
      {productsColm.length > 0 ?
        (<Card className="nested-table">
            <DndProvider backend={HTML5Backend}>
              <Table columns={nestedCol} components={components}
                pagination={false} rowKey="id"
                scroll={{ x: 240, y: 'calc(100vh - 254px)'}} dataSource={productsColm}
                onRow={(record, index) => ({
                  index, moveRow,
                })}
              />
            </DndProvider>
          </Card>
        )
        : (spinEnable ?
          <div className="spin-center"><Spin tip="...Loading" /></div>
          :
          <div className="no-data">No Data Available</div>
        )}
    </div>
  )
}

export default ClusterProductTable;
