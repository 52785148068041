import { maskingNumber } from '../../../_modules/masking';

export const deliverySlotColumns = [
  {
    title: 'Darkstore',
    dataIndex: 'darkstore_name',
    key: 'darkstore_name',
    align: 'center',
    width: 200
  },
  {
    title: "Today's Slot",
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.changed_current_active ? record.changed_current_active.toString() : null
    }
  },
  {
    title: "Tomm's Slot",
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.changed_next_active ? record.changed_next_active.toString() : null
    }
  },
  {
    title: "Day After Tomm's Slot",
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.changed_overmorrow_active ? record.changed_overmorrow_active.toString() : null
    }
  },
  {
    title: 'Admin Name',
    dataIndex: 'admin_name',
    key: 'admin_name',
    align: 'center'
  },
  {
    title: 'Delivery Slot',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.delivery_slot_details.from_time + ' to ' +
             record.delivery_slot_details.to_time + ' ' +
             record.delivery_slot_details.am_pm
    }
  }
]

export const serviceableColumns = [
  {
    title: 'Darkstore Name',
    dataIndex: 'darkstore_name',
    key: 'darkstore_name',
    align: 'center'
  },
  {
    title: 'Admin Name',
    dataIndex: 'admin_name',
    key: 'admin_name',
    align: 'center'
  },
  {
    title: 'Changed To',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.changed_is_active ? record.changed_is_active.toString() : null
    }
  },
]

export const stockColumns = [
  {
    title: 'Darkstore Name',
    dataIndex: 'darkstore',
    key: 'darkstore',
    align: 'center'
  },
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
    align: 'center'
  },
  {
    title: 'Stock Status',
    dataIndex: 'stock_status',
    key: 'stock_status',
    align: 'center'
  },
  {
    title: 'Status Changed By',
    dataIndex: 'status_changed_by',
    key: 'status_changed_by',
    align: 'center'
  }
]

export const notificationColumns = [
  {
    title: 'Customer Name',
    dataIndex: 'customer_name',
    key: 'customer_name',
    align: 'center'
  },
  {
    title: 'Mobile No',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.mobile_number ? maskingNumber(record.mobile_number) : ''
    }
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
    width: 300,
    align: 'center'
  },
  {
    title: 'Message Heading',
    dataIndex: 'message_heading',
    key: 'message_heading',
    width: 200,
    align: 'center'
  },
  {
    title: 'Message Id',
    dataIndex: 'message_id',
    key: 'message_id',
    align: 'center'
  },
  {
    title: 'Message Type',
    dataIndex: 'message_type',
    key: 'message_type',
    align: 'center'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center'
  },
]
