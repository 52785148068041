import React from "react";

export const darkstorePriceColumns = [
  {
    title: 'DarkStore',
    dataIndex: "",
    key: "name",
    width: 100,
    align: "center",
    render: (record) => {
      return record.darkstore_detail?.name ?? ''
  }
},
{
  title: "MRP",
  dataIndex: "",
  key: "moq_mrp",
  align: "center",
  render: (record) => {
    return record.pricing_details?.moq_mrp ?? ''
}
},
{
  title: "SP",
  dataIndex: "",
  key: "moq_sp",
  align: "center",
  render: (record) => {
    return record.pricing_details?.moq_sp ?? ''
}
},
{
  title: "Max Qty",
  dataIndex: "",
  key: "moq_qty",
  align: "center",
  render: (record) => {
    return record.pricing_details?.max_qty ?? ''
}
},
];