import React, {useState} from 'react';
import { Card, Row, Col, Button } from 'antd';
import PricingUpload from '../../components/pricing/pricingupload';
import FrzDynamicDownload from '../../_controls/FRZDynamicDownload';

function Selling (){

  const [uploadDataSelling] = useState([{name: 'Upload Selling Prices', url: 'price/local/market/csv/selling'}])

  return(
    <div>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card>
            <FrzDynamicDownload name={'Selling Price'} fileName={'Selling Price'} url={'download/csv/format/selling'}
                                      payload={`?city_code=${localStorage.getItem('city')}`} type={'pricing'} reportFunc={'pricing_report'}
                                      base={'pricing'}/>  
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <PricingUpload uploadData={uploadDataSelling} />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Selling