import { Http } from '../_modules/http'

export class StateService {

  http = new Http()
  get_city() {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/admin/all_cities'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  create_city(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/admin/create_city'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  update_city(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/admin/update_city?id=' + payload.id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  get_state() {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/admin/get_states'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  create_state(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/admin/create_state'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  update_state(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/admin/update_state?id=' + payload.id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

}
