import { Http } from '../../_modules/http';
import FRAAZO from '../../_modules/http_headers';

export class SegmentCreationService {

  http = new Http()

  get_segments(urlParam) {
    const url = process.env.REACT_APP_SEGMENT_URL + 'admin/api/v1/segments' + urlParam
    return this.http.get(url, FRAAZO())
  }

  get_segment_metrics() {
    const url = process.env.REACT_APP_SEGMENT_URL + 'admin/api/v1/customer_metrics/fields'
    return this.http.get(url, FRAAZO())
  }

  create_segments(payload) {
    const url = process.env.REACT_APP_SEGMENT_URL + 'admin/api/v1/segments'
    return this.http.post(url, payload, FRAAZO())
  }

  update_segments(payload, urlParam) {
    const url = process.env.REACT_APP_SEGMENT_URL + 'admin/api/v1/segments/' + urlParam
    return this.http.put(url, payload, FRAAZO())
  }

  // update_admin(payload){
  //   const url = process.env.REACT_APP_API_URL +
  //               'admin_dashboard/v1/delivery_admin/' + payload.id + '/update_admin'
  //   let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
  //   return this.http.put(url, payload.body, headers)
  // }

  // create_admin(payload){
  //   const url = process.env.REACT_APP_API_URL +
  //               'admin_dashboard/v1/delivery_admin/admin_registration'
  //   let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
  //   return this.http.post(url, payload, headers)
  // }

  // get_roles(){
  //   const url = process.env.REACT_APP_API_URL +
  //               'admin_dashboard/v1/admin/get_all_roles'
  //   let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
  //   return this.http.get(url, headers)
  // }

}
