import React, { useEffect, useState, useRef } from "react";
import { Spin, Button, Card, Row, Col, Tabs, Table } from "antd";
import { AdsService } from "../../_services/ads";
import { referralColumns, referralCityColumns } from '../../components/antd/columns/ads';
import ReferralModal from '../../components/ads/referral_modal';
import { openNotificationWithIcon } from '../../_modules/notification';

const ads_service = new AdsService()
const { TabPane } = Tabs;

function ReferralDiscount() {
  const modalRef = useRef(null);
  const [referralData, setReferralData] = useState(false);
  const [discountData, setDiscountData] = useState(false);
  const [renderData, setRenderData] = useState(false);
  const [columns, setColumns] = useState([]);
  const [cityColumns, setCityColumns] = useState([]);
  const [spinEnable, setSpinEnable] = useState(false);
  const [disable, setDisable] = useState(true);
  const [mode, setMode] = useState(undefined)
  const [current, setCurrent] = useState(1);
  const [state, setState] = useState({});
  const [currentTab, setCurrentTab] = useState(1);

  useEffect(() => {
    if (!renderData) {
      setRenderData(true);
      getReferralData(currentTab);
    }
  });

  useEffect(() => {
    getColumns()
  }, [])

  function getReferralData(key) {
    setSpinEnable(true);
    setReferralData([]);
    if (key == 1) {
        ads_service.get_referral_discounts().subscribe(
        (r) => {
          setSpinEnable(false);
          setReferralData(r.response.data);
          let newData = r.response.data.map(x => ({...x, referral_discount_id: x.id, 
                          option: `${x.discount_percentage_or_value}` + `${x.discount_type === 'value' ? ' Rs' : ' %'}`}))
          setDiscountData(newData)
        },

        (err) => {
          console.log(err);
        }
      );
    } else {
      ads_service.get_referral_discounts_city().subscribe(
        (r) => {
          setSpinEnable(false);
          let newData = r.response.data.map(x => ({...x, city_name: x.city_details?.name ?? ''}))
          setReferralData(newData);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  function getColumns() {
    setColumns(referralColumns);
    referralCityColumns.map(data => {
      cityColumns.push(data)
    })
    cityColumns.push({
      title: "Actions",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <Button type="primary" onClick={() => showModal(record, 'Edit Discounts')}>Edit</Button>
        );
      },
    })
    setCityColumns(cityColumns);
  }

  function showModal(data, type) {
    setMode(type)
    modalRef.current.showModal(type, data)
  }

  function changeTab(key) {
    setCurrentTab(key);
    setReferralData([]);
    setCurrent(1);
    setDisable(true);
    setRenderData(false);
  }

  function modalSubmit(payloadData) {
    if (mode === 'Edit Discounts') {
      ads_service.update_referral_discounts_city(payloadData).subscribe((r) => {
        openNotificationWithIcon('success', 'Edited Successfully')
        getReferralData(currentTab)
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response?.errors?.[0])
        })
    } else {
      ads_service.create_referral_discounts(payloadData).subscribe((r) => {
        openNotificationWithIcon('success', 'Created Successfully')
        getReferralData(currentTab)
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response?.errors?.[0])
        })
    }
  }

  return (
    <div className="layer">
      <div className="ost">
        <Tabs defaultActiveKey="1" onChange={changeTab}>
          <TabPane tab="Create Referral Discounts" key="1"></TabPane>
          <TabPane tab="City Wise Referral Discounts" key="2"></TabPane>
        </Tabs>
      </div>
      {referralData && discountData ? (
        <div>
          {currentTab == 1 ?
            <Row>
              <Card style={{ marginBottom: 20 }}>
                <Col span={24}>
                  <Button onClick={() => showModal(true, 'Create Discounts')} className="frz-w-140px frz-m-10"
                    type="primary">Create Discounts</Button>
                </Col>
              </Card>
            </Row>
          : null}
          {referralData.length > 0 ? (
            <Row>
              <Col span={24}>
                <Card style={{ paddingTop: 5}}>
                  <Row>
                    <Col span={24}>
                      {currentTab == 1 ? (
                        <Table
                          columns={columns}
                          dataSource={referralData}
                          pagination={false}
                          scroll={{ x: 240 }}
                          rowKey="id"
                        />
                      ) : (
                        <Table
                          columns={cityColumns}
                          dataSource={referralData}
                          pagination={false}
                          scroll={{ x: 240 }}
                          rowKey="city_id"
                        />
                      )}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          ) : disable || spinEnable ? (
            spinEnable ? (
              <div className="spin-center">
                <Spin tip="...Loading" />
              </div>
            ) : (
              <div className="no-data">No Data Available</div>
            )
          ) : (
            <div className="no-data">No Data Available On Selected Filters</div>
          )}
          <ReferralModal modalSubmit={modalSubmit} ref={modalRef} discountData={discountData} referralData={referralData}/>
        </div>
      ) : (
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      )}
    </div>
  );
}

export default ReferralDiscount;
