import React, {useState} from 'react';
import { Card, Row, Col, Select, Button, Spin, notification, DatePicker } from 'antd';
import {DownloadService} from '../../_services/downloads';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';

const download_service = new DownloadService()

const { Option } = Select;

function MonthlyDownloads(props){
    const [state, setState] = useState({date: '', dateValue: '', valueByDarkstore: 'all'});
    const [spinEnable, setSpinEnable] = useState(false)
    const [downloadOption, setDownloadOption] = useState(undefined)

    const {date, dateValue, valueByDarkstore} = state

    function onChange(val) {
        setDownloadOption(val)
    }

    function onChangeDarkstore(value){
        // console.log(values)
        setState(prevState =>({...prevState, valueByDarkstore: value}))
    }

    function getDownloadList(type){
        setSpinEnable(true)
        const payload = {
            date: dateValue+"-01",
            store: valueByDarkstore === 'all' ? '' : valueByDarkstore,
            url: downloadOption
        }
        download_service.download_reports_monthly(payload).subscribe((r) => {
            if(type === 'regenerate'){
                const payload = {
                    id: r.response.dateValue.id
                }
                download_service.download_reports_regenerate(payload).subscribe((r) => {
                    setSpinEnable(false)
                    openNotificationWithIcon('success','Regeneration successfully in progress, You will receive email shortly with latest reports')
                }, (err) => {
                    setSpinEnable(false)
                    openNotificationWithIcon('error','Regeneration Failed')
                })
            }else{
                const linkSource = `data:application/octet-stream;base64,${r.response.csv_data}`;
                const downloadLink = document.createElement("a");
                const fileName = `${'Report'}_${(new Date).toDateString().replace(/ /g,'_')}.csv`;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                setSpinEnable(false)
                openNotificationWithIcon('success','Downloaded Successfully')
            }
        }, (err) => {
            setSpinEnable(false)
            openNotificationWithIcon('error','Download Failed')
        })
    }

    function openNotificationWithIcon(type, msg) {
        notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
    };

    function disabledDate(current){
        // Can select days before today and today
        // return current && current > moment().add(0, "day");
        //Can select days before today and past 3 months
        return current && (current < moment().subtract(6, "month") || current > moment().add(0, "month"));
    }

    function onChangeDate(date, dateString) {
        console.log(date, dateString)
        setState(prevState =>({...prevState, date: date, dateValue: dateString}))
    }

    return(
        <div className="layer" style={{paddingTop: 20}}>
            {!spinEnable ?
                <Row>
                <Card>
                    <Col span={24} style={{marginLeft: 10}}>
                     <h3 style={{marginTop: 10, marginBottom: 0}}>
                        Monthly Reports - SKU Level Sales Report | Order Level Sales Report
                     </h3>
                    <DatePicker value={date} onChange={onChangeDate} placeholder="Month" picker="month"
                                style={{ width: 200, marginRight:10}} disabledDate={disabledDate}/>
                    <Select style={{ width: 300, marginRight:10}}
                      value={downloadOption}
                      placeholder="Select Download Item" onChange={(e) => onChange(e)}
                      filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        <Option key="sku_level_sales_report" value="sku_level_sales_report">
                            SKU Level Sales Report
                        </Option>
                        <Option key="order_level_sales_report" value="order_level_sales_report">
                            Order Level Sales Report
                        </Option>
                    </Select>
                    <Button disabled={!downloadOption || date === ''} onClick={getDownloadList} style={{width: 100, margin:10}}
                            type="primary">Download</Button>
                    <Button disabled={!downloadOption || date === ''} onClick={() => getDownloadList('regenerate')} style={{width: 200}}
                            type="primary">Regenerate Report</Button>
                    </Col>
                </Card>
                </Row>
                :
                <div className="spin-center">
                    <Spin tip="...Download in Progress"/>
                </div>
            }
         </div>
    )
}

export default MonthlyDownloads