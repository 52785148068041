import React, {useState} from 'react';
import { Upload, Row, Col, Card, Button, Spin, notification } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {UploadService} from '../../_services/uploads';

const upload_service = new UploadService()

const { Dragger } = Upload;

function PricingUpload(props){
  const [uploadFile, setUploadFile] = useState(false)
  const [current, setCurrent] = useState(false)
  const [currentSpin, setCurrentSpin] = useState(false)
  const [spinEnable, setSpinEnable] = useState(false)
  
  const [fileList, setFileList] = useState([])

  function beforeUploadFile(file, url) {
    setCurrent(url)
    setUploadFile(false)
    setFileList([])
    const formData = new FormData();
    formData.append('file', file)
    setFileList([file])
    setUploadFile(formData)
  }

  function afterUpload(url) {
    setSpinEnable(true)
    setCurrentSpin(url)
    upload_service.upload_pricing_reports(uploadFile, url).subscribe((r) => {
      setUploadFile(false)
      setSpinEnable(false)
      setFileList([])
      setCurrentSpin(false)
      setCurrent(false)
      openNotificationWithIcon('success','Upload File Succesfully')
    }, (err) => {
      setSpinEnable(false)
      openNotificationWithIcon('error','Upload Failed Please Check Internal File Format')
    })
  }

  function handleClick({ file, onSuccess }){
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  function handleRemove(url){
    setUploadFile(false)
    setFileList([])
    setCurrent(false)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  return(
    <div className="layer">
      {props.uploadData.map((data, index) => {
        return(
          <>
            <Dragger
              name='file'
              fileList={current === data.url ? fileList : []}
              showUploadList={true}
              customRequest={handleClick}
              beforeUpload={(e) => beforeUploadFile(e, data.url)}
              accept=".csv"
              onRemove={handleRemove}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{data.name}</p>
                <p className="ant-upload-hint">
                  Format :- CSV File
                </p>
            </Dragger>
            <div style={{textAlign: 'center'}}>
              {!spinEnable ?
                <Button disabled={current !== data.url} onClick={(e) => afterUpload(data.url)} style={{width: 100, margin:10, marginTop: 16}}
                        type="primary">Upload</Button>
              :
                <div>
                  {currentSpin == data.url ? 
                    <div style={{margin:10, marginTop: 16}}>
                      <Spin tip="...Upload in Progress"/>
                    </div>
                  : <Button disabled={true} onClick={(e) => afterUpload(data.url)} style={{width: 100, margin:10, marginTop: 16}} type="primary">Upload</Button>}
                </div>
              }
            </div>
          </>
        )
      })}
    </div>
  )
}

export default PricingUpload
