import React, { useEffect, useState } from 'react';
import MainTable from '../../antd/table';
import { Row, Col, Table} from 'antd';
import {comboItemColumns} from '../../antd/columns/master/product';

function ComboDetails(props){
  const [columns, setColumns] = useState([])

  useEffect(() => {
    getColumns()
  }, [])

  function getColumns(){
    let newColm = []
    comboItemColumns.map(data => {
      newColm.push(data)
    })
    setColumns(newColm)
  }

  return(
    <div>
      <Row>
        <Col span={24}>
          {props.product.length > 0 ?
            <Table columns={columns} dataSource={props.product}
                   pagination={false} scroll={{ x: 240 }}
                   rowKey="id"/>
          :
            <h3 style={{textAlign: 'center', paddingTop: 24}}>No Combo Products Created</h3>
          }
        </Col>
      </Row>
    </div>
  )
}

export default ComboDetails
