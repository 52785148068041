import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification } from 'antd';
import {SocietyService} from '../../_services/society';
import MainTable from '../../components/antd/table';
import LogicPosModal from '../../components/master/logic_pos/modal';
import {logicPosColumns} from '../../components/antd/columns/master/logic_pos';

const society_service = new SocietyService()

function LogicPos(){
  const modalRef = useRef(null);
  const [logicPosData, setLogicPosData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])    
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [state, setState] = useState({});
  const [modalState, setModalState] = useState({name: undefined, description: undefined,
                                      valueByRadio: undefined, currentActive: undefined});
  const { name, description, valueByRadio, currentActive } = modalState

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getLogicPos()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getLogicPos(){
    setSpinEnable(true)
    setLogicPosData([])
    society_service.get_logic_pos().subscribe((r) => {
      setSpinEnable(false)
      setLogicPosData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    logicPosColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (
          <Button type="primary" onClick={() => showModal(record, 'Edit Logic Pos')}>Edit</Button>
        )
      }
    });
    setColumns(columns)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  function showModal(data, type) {
    if(type === 'Edit Logic Pos'){
      setModalState({name: data.name, description: data.description,
                     valueByRadio: data.is_active, currentActive: data.logic_pos_code})
    }
    modalRef.current.showModal(type, data)
  }

  function onChangeModal(value, type) {
    if(type === 'valueByRadio'){
      setModalState(prevState =>({...prevState, [type]: value}))
    }else{
      setModalState(prevState =>({...prevState, [type]: value === "" ? undefined : value}))
    }
  }

  function onCancelModal() {
    setModalState({name: undefined, description: undefined,
                   valueByRadio: undefined, currentActive: undefined})
  }

  function modalSubmit() {
    if(!name || !description || valueByRadio === undefined){
         openNotificationWithIcon('error', "Please fill all fields")
         modalRef.current.stopLoading()
    }else{
      const payload = {
        id: currentActive,
        body: {
          name: name,
          description: description,
          is_active: valueByRadio,
        }
      }
      society_service.update_logic_pos(payload).subscribe((r) => {
        openNotificationWithIcon('success','Edited Successfully')
        getLogicPos()
        modalRef.current.handleCancel()
      },
      (err)=>{
        console.log(err)
        modalRef.current.stopLoading()
        openNotificationWithIcon('error', err.response.errors[0])
      })
    }
  }

  return(
    <div className="layer-nofilter">
      {logicPosData ?
        <div>
          {logicPosData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={logicPosData} columns={columns}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <LogicPosModal modalSubmit={modalSubmit} ref={modalRef}
                          onChangeModal={onChangeModal}
                          onCancelModal={onCancelModal}
                          name={name} description={description}
                          valueByRadio={valueByRadio}/>
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default LogicPos
