import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Form } from 'antd';
import FRZSelect from '../../../_controls/FRZSelect';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZRadioButton from '../../../_controls/FRZRadioButton';
import FRZDatepicker from '../../../_controls/FRZDatepicker';
import moment from 'moment';

const PoModal = forwardRef((props, ref) => {
  const { modalSubmit } = props

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState(false)
  const [data, setData] = useState(false)
  const [isClose, setIsClose] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setData(false)
      form.resetFields()
      setVisible(false)
      setLoading(false)
      setIsClose(true)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleCancelModal() {
    setData(false)
    form.resetFields()
    setVisible(false)
    setLoading(false)
    setIsClose(true)
  }

  const handleOnSubmit = (values) => {
    const payload = { ...values, delivery_date: moment(values.delivery_date).format("YYYY-MM-DD") }
    setLoading(true)
    modalSubmit(payload)
  }

  function disabledDate(current){
    return current && (current < moment().subtract(90, "day") || current > moment().add(10, "day"));
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            <FRZSelect DropDownData={props.nodes} name="node_id" isRequired={true}
              label="CC Name" placeholder="Select CC Name" value="id" option="name"
              isShowSearch={true} width={"100%"} />
            
            <FRZSelect DropDownData={props.suppliers} name="supplier_id" isRequired={true}
              label="Supplier Name" placeholder="Select Supplier Name" value="id" option="name"
              isShowSearch={true} width={"100%"} />

            <FRZRadioButton name="is_advance" label="Is Advance" isRequired={true} />

            <FRZDatepicker placeholder="Select Delivery Date" label="Delivery Date" isRequired={true}
              name="delivery_date" isRequired={true} isDisabled={false} disabledDate={disabledDate}/>

          </Form>
        </Modal>
        :   
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})

export default PoModal
