import { Http } from '../_modules/http'

export class OperationsService {

  http = new Http()
 
  get_supplier(payload){
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/procurement/suppliers?by_name=' + `${payload.valueByName}` + "&page=" + `${payload.page}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  update_supplier(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/suppliers";
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers);
  }

  get_map_supplier(){
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/procurement/map_suppliers'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_node_listing(payload){
    const url = process.env.REACT_APP_AWS_URL + 'admin/list_node?by_node_type=' + payload.nodeType 
                + '&admin_id=' + `${payload?.admin_id ?? ''}` + '&show_all=' + `${payload?.show_all ?? ''}`
                + '&by_city=' + `${payload?.city_id ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  add_node_listing(payload){
    const url = process.env.REACT_APP_AWS_URL + 'admin/nodes'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload, headers);
  }

  edit_node_listing(payload){
    const url = process.env.REACT_APP_AWS_URL + 'admin/nodes'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers);
  }

  get_admin_cc(){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/get_cc_admins'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_product_list(){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/products/get_product_list?is_active=true'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_forcast_listing(payload) {
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/procurement/procurement_forecasting?page=' 
                + payload.page + '&date=' + payload.date + '&by_name=' + payload.valueByName;
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  create_forcast_listing(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/procurement_forecasting";
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload, headers);
  }

  update_forcast_listing(payload) {
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/procurement_forecasting";
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers);
  }

  get_po_list(payload){
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/purchase_orders?page=" + payload.page 
                + '&by_node=' + payload.by_node + '&by_supplier=' + payload.by_supplier + '&start_date=' + payload.start_date
                + '&end_date=' + payload.end_date + '&by_order_number=' + payload.by_order_number 
                + '&by_status=' + payload.by_status + '&admin_id=' + payload.admin_id + '&by_product=' + payload.by_product
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

  get_suppliers(){
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/get_all_suppliers"
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

  create_po(payload){
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/purchase_orders"
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload, headers);
  }

  cancel_po(id){
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/cancel_purchase_order?id=" + id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, {}, headers);
  }

  po_products(payload){
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/create_po_line_item"
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload, headers);
  }

  get_grn_list(payload){
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/grns?page=" + payload.page 
                + '&by_node=' + payload.by_node + '&by_supplier=' + payload.by_supplier + '&start_date=' + payload.start_date
                + '&end_date=' + payload.end_date + '&by_order_number=' + payload.by_order_number 
                + '&by_status=' + payload.by_status + '&admin_id=' + payload.admin_id + '&by_product=' + payload.by_product
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

  allow_edit_grn(payload){
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/update_edit"
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers);
  }

  grn_products(payload){
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/grns"
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers);
  }

  create_payments(payload){
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/po_payments"
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload, headers);
  }

  get_payment_list(payload){
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/po_payments?page=" + payload.page 
                + '&by_node=' + payload.by_node + '&by_supplier=' + payload.by_supplier + '&start_date=' + payload.start_date
                + '&end_date=' + payload.end_date + '&by_order_number=' + payload.by_order_number 
                + '&by_status=' + payload.by_status
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

  update_payments(payload){
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/po_payments"
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers);
  }

  get_sto_list(payload){
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/stos?page=" + payload.page 
                + '&transfer_date=' + payload.transfer_date + '&by_source=' + payload.by_source
                + '&by_destination=' + payload.by_destination + '&by_status=' + payload.by_status
                + '&admin_id=' + payload.admin_id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

  create_sto(payload){
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/stos"
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload, headers);
  }

  update_sto(payload){
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/procurement/stos'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers);
  }

  cancel_sto(payload){
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/procurement/cancel_sto'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers);
  }

  update_inward(payload){
    const url = process.env.REACT_APP_AWS_URL + 'admin_dashboard/procurement/inward_receiving'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers);
  }

  get_cc_wh_inventory(payload){
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/list_current_stock?node_id=" + payload.nodeId + "&by_name=" + payload.valueByName + "&page=" + payload.page
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }
  
  upload_bills(payload){
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/upload_procurement_bill"
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers);
  }

  get_po_payment_list(payload){
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/order_po_payment?id=" + payload.id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

  get_city(){
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/get_all_city"
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

  get_sto_skus(payload){
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/list_sto_products?node_id=" + payload.id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers);
  }

  sto_products(payload){
    const url = process.env.REACT_APP_AWS_URL + "admin_dashboard/procurement/add_sto_product"
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers);
  }

}
