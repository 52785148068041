import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button, Select, Input, InputNumber,
         Radio, DatePicker, TimePicker, Row, Col, ConfigProvider} from 'antd';
const { Option } = Select;

const customizeRenderEmpty = () => (
  <div style={{ textAlign: 'center' }}>
    <p>No society found on above pincode</p>
  </div>
);

const SocietyModal = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOk() {
    setLoading(true)
    props.modalSubmit()
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.onCancelModal();
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onOk={handleOk}
          width={title === 'Campaign Details' || title === 'Publish Campaign' ||
                 title === 'Create Campaign' ? 1000 : 520}
          onCancel={handleCancelModal}
          footer={[
            (title === 'Campaign Details' ?
            <Button key="back" onClick={handleCancelModal}>
              Close
            </Button>
            :
            <>
              <Button key="back" onClick={handleCancelModal}>
                Cancel
              </Button>
              <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                {title === 'Create Campaign'? 'Create Campaign'
                : title === 'Publish Campaign' ? 'Publish Campaign' : 'Submit'}
              </Button>
            </>),
          ]}
        >
          <Row>
            <Col span={title === 'Campaign Details' || title === 'Publish Campaign'
                       || title === 'Create Campaign' ? 10 : 24}
                 style={title === 'Campaign Details' || title === 'Publish Campaign' ||
                        title === 'Create Campaign' ?
                 {borderRight: '1px solid #f0f0f0'} : null}>
              {title === 'Create Campaign' || title === 'Publish Campaign'
                || title === 'Campaign Details'?
                <div>
                  <div className="block" style={{marginLeft: 20}}>Campaign Name :
                    <span style={{paddingLeft:10}}>
                      <Input placeholder="Campaign Name"
                        disabled={title === 'Campaign Details' ? true : false}
                        value={props.campaign_name}
                        style={props.campaignError && props.campaign_name === undefined ?
                               {width:200, borderColor: 'red'} : {width:200}}
                        onChange={(e) => props.onChangeModal(e.target.value, 'campaign_name')}/>
                    </span>
                  </div>
                  <div className="block">Campaign End Date :
                    <span style={{paddingLeft:10}}>
                      <DatePicker placeholder="Select Date"
                                  value={props.to_date}
                                  disabled={title === 'Campaign Details' ? true : false}
                                  onChange={props.onChangeDateModal}
                                  style={props.campaignError && props.to_date === '' ?
                                         {width:200, borderColor: 'red'} : {width:200}}/>
                    </span>
                  </div>
                  <div className="block">Campaign End Time :
                    <span style={{paddingLeft:10}}>
                      <TimePicker style={props.campaignError && props.to_time === '' ?
                                        {width:200, borderColor: 'red'} : {width:200}}
                                  placeholder="Select Time"
                                  disabled={title === 'Campaign Details' ? true : false}
                                  use24Hours format='HH:mm'
                                  value={props.to_time}
                                  onChange={props.onChangeTimeModal} />
                    </span>
                  </div>
                  <div className="block" style={{marginLeft: 37}}>Delivery Date :
                    <span style={{paddingLeft:10}}>
                      <DatePicker placeholder="Select Date"
                                  disabled={title === 'Campaign Details' ? true : false}
                                  value={props.delivery_date}
                                  onChange={props.onChangeDateModalDelivery}
                                  style={props.campaignError && props.delivery_date === '' ?
                                         {width:200, borderColor: 'red'} : {width:200}}/>
                    </span>
                  </div>
                  <div className="block"style={{marginLeft: 23}}>Society Pincode :
                    <span style={{paddingLeft:10}}>
                      <Input
                        disabled={title === 'Campaign Details' ? true : false}
                        placeholder="Society Pincode" maxLength="6"
                        value={props.pincode_modal}
                        style={props.campaignError && props.pincode_modal.length !== 6 ?
                               {width:200, borderColor: 'red'} : {width:200}}
                        onChange={(e) => props.onChangeModal(e.target.value, 'pincode_modal')}/>
                    </span>
                  </div>
                  {props.societyData && props.pincode_modal.length === 6 ?
                  <div className="block" style={{marginLeft: 37}}>Society Name :
                    <span style={{paddingLeft:10}}>
                      <ConfigProvider renderEmpty={customizeRenderEmpty}>
                        <Select value={props.society_id}
                                className={`${props.campaignError && props.society_id === undefined ?
                                              "select-error" : false}`}
                                style={{width:200}}
                                disabled={title === 'Campaign Details' ? true : false}
                                placeholder="Select Society"
                                onChange={(e) => props.onChangeModal(e, 'society_id')}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                            {props.societyData.length > 0 ?
                            (props.societyData.map(data => {
                              return (
                                <Option key={data.id} value={data.id}>{data.name}</Option>
                              )
                            }))
                            : null}
                        </Select>
                      </ConfigProvider>
                    </span>
                  </div>
                  : null}
                  <div className="block" style={{marginLeft: 64}}>Time Slot :
                    <span style={{paddingLeft:10}}>
                    <Select value={props.campaignSlot}
                            className={`${props.campaignError && props.campaignSlot === undefined ?
                                          "select-error" : false}`}
                            style={{width:200}}
                            disabled={title === 'Campaign Details' ? true : false}
                            placeholder="Select Slot" onChange={(e) => props.onChangeModal(e, 'campaignSlot')}>
                        {props.campaignSlotData.map(data => {
                          return (
                            <Option key={data.id} value={data.id}>
                              {data?.slot_string ?? data.from_time + ' to ' + data.to_time + ' ' + data.am_pm}
                            </Option>
                          )
                        })}
                    </Select>
                    </span>
                  </div>
                  <div className="block"style={{marginLeft: 44}}>Society Type :
                    <span style={{paddingLeft:10}}>
                      <Input
                        disabled={title === 'Campaign Details' ? true : false}
                        placeholder="Small, Medium, Large"
                        value={props.societyType}
                        style={props.campaignError && props.societyType === undefined ?
                               {width:200, borderColor: 'red'} : {width:200}}
                        onChange={(e) => props.onChangeModal(e.target.value, 'societyType')}/>
                    </span>
                  </div>
                  <div className="block"style={{marginLeft: 23}}>Min Order Value :
                    <span style={{paddingLeft:10}}>
                      <InputNumber
                        disabled={title === 'Campaign Details' ? true : false}
                        placeholder="Min Order Value"
                        value={props.min_order}
                        style={props.campaignError && props.min_order === undefined ?
                               {width:200, borderColor: 'red'} : {width:200}}
                        onChange={(e) => props.onChangeModal(e, 'min_order')}/>
                    </span>
                  </div>
                  <div className="block"style={{marginLeft: 23}}>Min Order Count :
                    <span style={{paddingLeft:10}}>
                      <InputNumber
                        disabled={title === 'Campaign Details' ? true : false}
                        placeholder="Min Order Count"
                        value={props.min_order_count}
                        style={props.campaignError && props.min_order_count === undefined ?
                               {width:200, borderColor: 'red'} : {width:200}}
                        onChange={(e) => props.onChangeModal(e, 'min_order_count')}/>
                    </span>
                  </div>
                  <div className="block" style={{marginLeft: 29}}>Targeted Order :
                    <span style={{paddingLeft:10}}>
                      <InputNumber
                        disabled={title === 'Campaign Details' ? true : false}
                        placeholder="Target Order Count"
                        value={props.target_order}
                        style={props.campaignError && props.target_order === undefined ?
                               {width:200, borderColor: 'red'} : {width:200}}
                        onChange={(e) => props.onChangeModal(e, 'target_order')}/>
                    </span>
                  </div>
                </div>
              :
                <div>
                  <div className="block" style={{marginLeft: 5}}>Campaign Name :
                    <span style={{paddingLeft:10}}>
                      {props.campaign_name}
                    </span>
                  </div>
                  <div className="block" style={{marginLeft: 45}}>Min Order :
                    <span style={{paddingLeft:10}}>
                      <InputNumber
                        disabled={title === 'Campaign Details' ? true : false}
                        placeholder="Minimum no of Orders"
                        value={props.discount_min} style={{width:200}}
                        onChange={(e) => props.onChangeModal(e, 'discount_min')}/>
                    </span>
                  </div>
                  <div className="block">Offer Percentage :
                    <span style={{paddingLeft:10}}>
                      <InputNumber
                        disabled={title === 'Campaign Details' ? true : false}
                        placeholder="Offer Percentage"
                        value={props.discount_percent} style={{width:200}}
                        onChange={(e) => props.onChangeModal(e, 'discount_percent')}/>
                    </span>
                  </div>
                </div>
              }
            </Col>
            {title === 'Campaign Details' || title === 'Publish Campaign' ?
              (data.society_campaign_discount_details.length > 0 ?
                <Col span={14}>
                  <Row>
                    {data.society_campaign_discount_details.map((data, index) => {
                      return(
                        <Col key={data.id} span={12} style={{paddingLeft: 40, marginBottom: 20}}>
                          <div className="block-detail">
                            <b>Campaign Discount #{index+1}</b>
                          </div>
                          <div className="block-detail">Min Order :
                            <span style={{paddingLeft:10}}>
                              {data.min_order}
                            </span>
                          </div>
                          <div className="block-detail">Offer Percentage :
                            <span style={{paddingLeft:10}}>
                              {data.offer_percentage}
                            </span>
                          </div>
                        </Col>
                      )
                    })}
                  </Row>
                </Col>
              :
                <Col span={14}>
                  <div className="no-data-nested">No Discounts Added</div>
                </Col>
              )
            : null}
            {title === 'Create Campaign' ?
              <Col span={14}>
                <Row>
                  <Col span={12} style={{paddingLeft: 40, marginBottom: 20}}>
                    <div className="block-detail">
                      <b>Campaign Discount #1</b>
                    </div>
                    <div className="block-detail">Min Order :
                      <span>
                        <InputNumber
                          placeholder="Minimum no of Orders"
                          value={props.discount_min}
                          style={props.discount1Error && props.discount_min === undefined ?
                                 {width:200, borderColor: 'red'} : {width:200}}
                          onChange={(e) => props.onChangeModal(e, 'discount_min')}/>
                      </span>
                    </div>
                    <div className="block-detail">Offer Percentage :
                      <span>
                        <InputNumber
                          placeholder="Offer Percentage"
                          value={props.discount_percent}
                          style={props.discount1Error && props.discount_percent === undefined ?
                                 {width:200, borderColor: 'red'} : {width:200}}
                          onChange={(e) => props.onChangeModal(e, 'discount_percent')}/>
                      </span>
                    </div>
                  </Col>
                  <Col span={12} style={{paddingLeft: 40, marginBottom: 20}}>
                    <div className="block-detail">
                      <b>Campaign Discount #2</b>
                    </div>
                    <div className="block-detail">Min Order :
                      <span>
                        <InputNumber
                          placeholder="Minimum no of Orders"
                          value={props.discount_min1}
                          style={props.discount2Error && props.discount_min1 === undefined ?
                                 {width:200, borderColor: 'red'} : {width:200}}
                          onChange={(e) => props.onChangeModal(e, 'discount_min1')}/>
                      </span>
                    </div>
                    <div className="block-detail">Offer Percentage :
                      <span>
                        <InputNumber
                          placeholder="Offer Percentage"
                          value={props.discount_percent1}
                          style={props.discount2Error && props.discount_percent1 === undefined ?
                                 {width:200, borderColor: 'red'} : {width:200}}
                          onChange={(e) => props.onChangeModal(e, 'discount_percent1')}/>
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
            : null}
          </Row>
        </Modal>
      : null }
    </div>
  )
})

export default SocietyModal
