import React, { useEffect, useState, useRef } from 'react';
import {
  Spin, Button, Card, Row, Col, Input, InputNumber,
  Pagination, DatePicker, Popconfirm, notification, Select
} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { OrderService } from '../../_services/orders';
import Search from '../../components/antd/search';
import MainTable from '../../components/antd/table';
import OrderModal from '../../components/orders/modal';
import { orderColumns } from '../../components/antd/columns/orders';
import OrderCancel from '../../components/orders/order_cancel';
import moment from 'moment';
import ModalMarkDeliver from '../../components/orders/modal_mark_deliver';
import ModalChangeAddress from '../../components/orders/modal_change_address';

const order_service = new OrderService()
const { Option } = Select

function UndeliveredOrderList() {
  const modalRef = useRef(null);
  const modalRef2 = useRef(null);
  const [orderData, setOrderData] = useState(false)
  const [orderStatus, setOrderStatus] = useState(false)
  const [refundReason, setRefundReason] = useState(false)
  const [reviewReason, setReviewReason] = useState(false)
  const [cancelReason, setCancelReason] = useState(false)
  const [partners, setPartners] = useState(false)
  const [reviewParams, setReviewParams] = useState(false)
  const [issueParams, setIssueParams] = useState(false)
  const [customerName, setCustomerName] = useState(false)
  const [operations, setOperations] = useState(false)
  const [cancelOperations, setCancelOperations] = useState(false)
  const [markOperations, setMarkOperations] = useState(false)
  const [renderOperations, setRenderOperations] = useState(false)
  const [orderType, setOrderType] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [rerenderData, setReRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [loading, setLoading] = useState(false)
  const [markDeliverModal, setMarkDeliverModal] = useState(false)
  const [recordId, setRecordId] = useState(false)
  const [deliverNow, setDeliverNow] = useState(false)
  const [changeAddress, setChangeAddress] = useState(false)
  const [state, setState] = useState({
    valueByDarkstore: undefined, valueByOrderNo: undefined,
    valueByOrderStatus: undefined, valueByCustomer: undefined,
    valueByMobno: undefined, valueByDeliveryDate: undefined, cancellationReason: undefined,
    valueByOrderType: undefined, date: '', replacementOrder: undefined,
    valueBySociety: undefined
  });
  const { valueByOrderNo, valueByDarkstore, valueByOrderStatus, valueByCustomer, cancellationReason,
    valueByMobno, valueByDeliveryDate, valueByOrderType, date, replacementOrder, valueBySociety } = state

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getOrders()
    }
    if (rerenderData) {
      setReRenderData(false)
      const index = orderData.findIndex(e => e.id === rerenderData.id)
      orderData[index].order_status = rerenderData.order_status
      setOrderData(orderData)
    }
  })

  useEffect(() => {
    if (renderOperations) {
      setRenderOperations(false)
      getColumns()
    }
  })

  useEffect(() => {
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['developer_admin', 'customer_support_executive', 'customer_support_tl', 'super_admin'].includes(data)) {
        setOperations(true)
      }
    })
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['developer_admin', 'customer_support_tl', 'super_admin'].includes(data)) {
        setCancelOperations(true)
        setMarkOperations(true)
      }
    })
    if (JSON.parse(localStorage.getItem('auth')).uid === 'sourabh.dalvi@freshvnf.com' ||
      JSON.parse(localStorage.getItem('auth')).uid === 'dipak.girase@freshvnf.com' ||
      JSON.parse(localStorage.getItem('auth')).uid === 'anuj.chauhan@fraazo.com' ||
      JSON.parse(localStorage.getItem('auth')).uid === 'vishwa.rawat@fraazo.com' ||
      JSON.parse(localStorage.getItem('auth')).uid === 'hanumesh.konanavar@freshvnf.com' ||
      JSON.parse(localStorage.getItem('auth')).uid === 'bharath.nayaka@freshvnf.com') {
      setMarkOperations(true)
    }
    if(process.env.REACT_APP_AWS_TOKEN === '12345'){
      setCancelOperations(true)
      setMarkOperations(true)
    }
    setRenderOperations(true)
    getOrderStatus()
    // getCustomerNames()
    getOrderTypes()
    getCancelReasons()
    getRefundReasons()
    getReviewReasons()
    getReviewParams()
    getPartners()
    // getIssueParams()
  }, [])

  function getOrders() {
    setSpinEnable(true)
    setOrderData([])
    const payload = {
      page: current,
      by_order_number: !valueByOrderNo ? '' : valueByOrderNo,
      by_darkstore: !valueByDarkstore ? '' : valueByDarkstore,
      by_status: !valueByOrderStatus ? '' : valueByOrderStatus,
      by_customer: !valueByCustomer ? '' : valueByCustomer,
      by_mobile_number: !valueByMobno ? '' : valueByMobno,
      by_delivery_date: !valueByDeliveryDate ? '' : valueByDeliveryDate,
      by_order_type: !valueByOrderType ? '' : valueByOrderType,
      is_replacement: !replacementOrder ? '' : replacementOrder,
      is_society: valueBySociety ?? ''
    }
    order_service.get_undelivered_orders(payload).subscribe((r) => {
      setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
      setSpinEnable(false)
      setOrderData(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getColumns() {
    orderColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: '',
      dataIndex: '',
      key: '',
      align: 'right',
      render: (record) => {
        return (
          <div>
            <div>
              <Button type="primary" onClick={() => showModal(record, 'Order Details')}>
                View Details
              </Button>
            </div>
            {cancelOperations && record.order_status !== 'draft' && record.order_status !== 'offline_draft' &&
                record.order_status !== 'cancelled' ?
              <Popconfirm
                title="Are you sure you want to cancel this order?"
                onConfirm={() => cancelOrder(record.id)}
                okText="Yes"
                cancelText="No"
                placement="leftTop"
              >
                <Button type="primary" ghost style={{ marginTop: 10 }}>
                  Cancel Order
                </Button>
              </Popconfirm>
            : null}
            
            {markOperations && record.order_status !== 'draft' && record.order_status !== 'offline_draft' &&
              record.order_status !== 'cancelled' && record.order_status !== 'delivered' ?
              <Popconfirm
                title="Are you sure you want to mark this order as delivered?"
                onConfirm={() => handleMarkDeliver(record, record.id)}
                okText="Yes"
                cancelText="No"
                placement="leftTop"
              >
                <Button type="primary" ghost style={{ marginTop: 10, marginLeft: 10 }}>
                  Mark Delivered
                </Button>
              </Popconfirm>
            : null}
            

            {(record.order_status === 'dispatched' || record.order_status === "confirmed" ||
              record.order_status === "choosing_best") && record.delivery_id !== null ?
              <Popconfirm
                title="Are you sure you want to deliver now?"
                onConfirm={() => deliverNowDirect(record.delivery_id)}
                okText="Yes"
                cancelText="No"
                placement="leftTop"
              >
                {cancelOperations ?
                  <Button type="primary" ghost style={{ marginTop: 10 }}>
                    Deliver Now
                  </Button>
                  : null}
              </Popconfirm> 
              : null}

              {(record.order_status === 'dispatched' || record.order_status === "confirmed" ||
              record.order_status === "choosing_best") && record.customer_id !== null ?
                <Popconfirm
                  title="Are you sure you want to change address?"
                  onConfirm={() => handleChangeAddress(record)}
                  okText="Yes"
                  cancelText="No"
                  placement="leftTop"
                >
                  {cancelOperations ?
                    <Button type="primary" ghost style={{ marginTop: 10, marginLeft: 10 }}>
                      Change Address
                    </Button>
                  : null}
                </Popconfirm> 
              : null}
          </div>
        )
      }
    })
    setColumns(columns)
  }

  function cancelOrder(id) {
    modalRef2.current.showModal(id, true)
  }

  const handleMarkDeliver = (value, id, delivernow) => {
    if (delivernow) {
      setDeliverNow(delivernow)
    } else {
      setDeliverNow(false)
    }
    setRecordId(id)
    setMarkDeliverModal(value)
  }

  const handleChangeAddress = (value) =>{
    setChangeAddress(value)
  }

  const deliverNowDirect = (id) => {
    const payload = {
      id: id
    }
    order_service.delivery_now(payload).subscribe((r) => {
      openNotificationWithIcon('success', "Delivering Now")
      setRenderData(false)
    },
    (err) => {
      openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      console.log(err)
    })
  }

  function deliveredOrder(data) {
    setLoading(true)
    const payload = {
      id: recordId,
      ...data
    }
    order_service.mark_delivered_order(payload).subscribe((r) => {
      setLoading(false)
      setMarkDeliverModal(false)
      openNotificationWithIcon('success', "Marked Delivered")
      setReRenderData(r.response.data)
    },
    (err) => {
      openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      setLoading(false)
      console.log(err)
    })  
  }

  function showModal(data, type) {
    modalRef.current.showModal(type, data)
  }

  function getCancelReasons() {
    order_service.get_cancel_reasons().subscribe((r) => {
      setCancelReason(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getRefundReasons() {
    order_service.get_refund_reasons().subscribe((r) => {
      setRefundReason(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getReviewReasons() {
    order_service.get_review_reasons().subscribe((r) => {
      setReviewReason(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getPartners() {
    order_service.get_partners().subscribe((r) => {
      setPartners(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getReviewParams() {
    order_service.get_review_params().subscribe((r) => {
      setReviewParams(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  // function getIssueParams() {
  //   order_service.get_issue_params().subscribe((r) => {
  //     setIssueParams(r.response.data)
  //   },
  //   (err)=>{
  //     console.log(err)
  //   })
  // }

  function getOrderStatus() {
    order_service.get_order_status().subscribe((r) => {
      setOrderStatus(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getCustomerNames() {
    order_service.get_customers().subscribe((r) => {
      setCustomerName(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getOrderTypes() {
    order_service.get_order_types().subscribe((r) => {
      setOrderType(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }

  function onChangeDate(date, dateString) {
    // console.log(date)
    setState(prevState => ({ ...prevState, date: date, valueByDeliveryDate: dateString }))
    setDisable(false)
    setCurrent(1)
  }

  function reset() {
    setState({
      valueByDarkstore: undefined,
      valueByOrderNo: undefined,
      valueByOrderStatus: undefined,
      valueByCustomer: undefined,
      valueByMobno: undefined,
      valueByDeliveryDate: undefined,
      valueByOrderType: undefined,
      replacementOrder: undefined,
      valueBySociety: undefined,
      date: ''
    })
    setDisable(true)
    setCurrent(1)
    setOrderData([])
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setOrderData([])
    setRenderData(false)
  }

  function openNotificationWithIcon(type, msg) {
    notification[type]({ message: type.toUpperCase(), duration: 3, description: msg });
  };

  function onChangeValue(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
  }

  function onCancelModal() {
    setState(prevState => ({ ...prevState, cancellationReason: undefined }))
  }

  function modalSubmit(data) {
    setLoading(true)
    if (cancellationReason && data.rider_payment !== undefined) {
      const payload = {
        id: data.id,
        body: {
          cancel_reason_id: cancellationReason,
          rider_payment: data.rider_payment
        },
      }
      order_service.cancel_order(payload).subscribe((r) => {
        setLoading(false)
        setReRenderData(r.response.data)
        modalRef2.current.handleCancel()
      },
        (err) => {
          setLoading(false)
          openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
          modalRef2.current.stopLoading()
          console.log(err)
        })
    } else {
      setLoading(false)
      openNotificationWithIcon('error', 'Please fill all details')
    }
  }

  function onSubmitChangeAdd(val, id){
    setLoading(true)
    const payload = {...val, order_id: id}
    order_service.change_address(payload).subscribe((r) => {
      setLoading(false)
      setChangeAddress(false)
      setRenderData(false)
      openNotificationWithIcon('success', "Address Changed")
    },
    (err) => {
      setLoading(false)
      openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      console.log(err)
    })
  }

  return (
    <div className="layer">
      {orderData && orderStatus && orderType && partners ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Darkstore Name" value={valueByDarkstore}
                  onChange={onChange} type="valueByDarkstore" />
                <Search placeholder="Order Status" value={valueByOrderStatus}
                  onChange={onChange} type="valueByOrderStatus"
                  data={orderStatus} />
                {/*<Search placeholder="Customer" value={valueByCustomer}
                        onChange={onChange} type="valueByCustomer"
                        data={customerName}/>*/}
                <Search placeholder="Order Type" value={valueByOrderType}
                  onChange={onChange} type="valueByOrderType"
                  data={orderType} />
                <DatePicker value={date} onChange={onChangeDate} placeholder="Delivery Date"
                  style={{ width: 200, marginLeft: 10, marginRight: 10 }} />
                <InputNumber placeholder="Order No"
                  onChange={(e) => onChange(e, 'valueByOrderNo')}
                  value={valueByOrderNo} style={{ width: 200, margin: 10 }} />
                <InputNumber placeholder="Mobile No"
                  onChange={(e) => onChange(e, 'valueByMobno')}
                  value={valueByMobno} style={{ width: 200, margin: 10 }} />
                <Select style={{ width: 200, margin: 10 }} value={replacementOrder}
                  placeholder="Replacement Order" onChange={(e) => onChange(e, 'replacementOrder')}>
                  <Option value={true}>Is Replacement Order</Option>
                </Select>
                <Select style={{ width: 200, margin: 10 }} value={valueBySociety}
                  placeholder="Is Society" onChange={(e) => onChange(e, 'valueBySociety')}>
                  <Option value={true}>Is Society</Option>
                </Select>
                <Button disabled={disable} onClick={getOrders} style={{ width: 100, margin: 10 }}
                  type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{ margin: 10 }}
                  type="primary" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          {orderData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={orderData} columns={columns} />
                <Pagination style={{ marginTop: 10, marginBottom: 10 }} current={current}
                  pageSize={50} total={state.total} onChange={pagination}
                  showSizeChanger={false} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <OrderModal ref={modalRef} refundReason={refundReason} reviewReason={reviewReason}
            operations={operations} cancelOperations={cancelOperations} reviewParams={reviewParams} issueParams={reviewParams} getOrders={getOrders} />
          <OrderCancel ref={modalRef2} cancelReason={cancelReason}
            cancellationReason={cancellationReason} onChangeValue={onChangeValue}
            onCancelModal={onCancelModal} modalSubmit={modalSubmit} loading={loading} />
          {markDeliverModal ?
            <ModalMarkDeliver markDeliverModal={markDeliverModal} deliveredOrder={deliveredOrder} partners={partners}
              handleMarkDeliver={handleMarkDeliver} loading={loading} deliverNow={deliverNow} recordId={recordId} />
            : null}
          {changeAddress ? 
            <ModalChangeAddress changeAddress={changeAddress} onSubmitChangeAdd={onSubmitChangeAdd} loading={loading}
              handleChangeAddress={handleChangeAddress} />
          : null  
          }  

        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default UndeliveredOrderList
