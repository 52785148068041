import React, { useEffect, useState, useRef } from 'react';
import {
  Spin, Button, Card, Row, Col, Input, InputNumber, Tabs,
  Pagination, DatePicker, Popconfirm, notification, Switch
} from 'antd';
import { DarkstoreService } from '../../_services/darkstore';
import MainTable from '../../components/antd/table';
import DarkstoreModal from '../../components/master/darkstore/modal';
import { darkstoreColumns, checklistColumns } from '../../components/antd/columns/master/darkstores';
import { ReloadOutlined } from '@ant-design/icons';
import ModalCheckList from '../../components/master/darkstore/modalCheckList';

const darkstore_service = new DarkstoreService()

const { TabPane } = Tabs;

function DarkstoresList() {
  const modalRef = useRef(null);
  const [currentTab, setCurrentTab] = useState(1);
  const [darkstoreData, setDarkstoreData] = useState(false)
  const [darkstoreNVData, setNonVirtualDarkstoreData] = useState(false)
  const [flashSale, setFlashSale] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [mode, setMode] = useState(undefined)
  const [checklistModal, setChecklistModal] = useState(false)
  const [listingChecklist, setListingChecklist] = useState(false)
  const [rowData, setRowData] = useState(false)
  const [state, setState] = useState({ valueByDarkStore: undefined });
  const [checklistId, setChecklistId] = useState(false)
  const { valueByDarkStore } = state
  const [modalClosed, setModalClosed] = useState(false)
  const [modalState, setModalState] = useState({
    name: undefined, latitude: undefined,
    longitude: undefined, address: undefined,
    valueByRadio: undefined, currentActive: undefined,
    city_id: undefined, pincode: undefined,
    mobile_number: undefined, code: undefined,
    erp_code: undefined, is_virtual: undefined,
    parent_id: undefined, is_superstore: undefined,
    is_fraazo: undefined, is_night_serviceable: undefined,
    serviceable_radius: undefined, opening_time: undefined,
    closing_time: undefined, valueByopening_time: undefined,
    valueByclosing_time: undefined, image_attendance: undefined,
    otp_attendance: undefined, static_ip: undefined
  });
  const { name, latitude, longitude, address, city_id, pincode, parent_id, delivery_limit, is_fraazo, serviceable_radius,
    mobile_number, code, erp_code, valueByRadio, currentActive, is_virtual, is_superstore, is_night_serviceable, is_serviceable,
    opening_time, closing_time, valueByopening_time, valueByclosing_time, otp_attendance, image_attendance, static_ip } = modalState

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getListing(currentTab)
    }
  })

  useEffect(() => {
    getColumns()
    if (currentTab == 1) {
      getNonVirtualDS()
      getAllCheckList()
    }
  }, [currentTab])

  useEffect(() => {
    if (modalClosed === "remove") {
      setModalClosed(false)
    }
  }, [modalClosed])

  const getAllCheckList = () => {
    darkstore_service.get_checklist_listing_no_pg().subscribe((r) => {
      let tempListing = r.response.data.filter((e) => e.is_active === true)
      setListingChecklist(tempListing)
    }, ((err) => {
      console.log("err", err)
    }))
  }

  const handleCheckListModal = (value, rowdata) => {
    if (rowdata) {
      setChecklistId(rowdata.id)
      setRowData(rowdata)
    }
    setChecklistModal(value)
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }

  function getListing(key) {
    setSpinEnable(true)
    if (key == 1) {
      setDarkstoreData([])
      const payload = {
        valueByDarkStore: valueByDarkStore === undefined ? '' : valueByDarkStore.toUpperCase()
      }
      darkstore_service.get_darkstores(payload).subscribe((r) => {
        setSpinEnable(false)
        setDarkstoreData(r.response.data)
        setFlashSale(r.response.flash_sale_active)
      },
        (err) => {
          console.log(err)
        })
    } else {
      setDarkstoreData([])
      const payload = {
        page: current
      }
      darkstore_service.get_checklist_listing(payload).subscribe((r) => {
        setState(prevState => ({ ...prevState, total: r.response.data.meta.total_pages * 50 }))
        setSpinEnable(false)
        setDarkstoreData(r.response.data.data)
      },
        (err) => {
          console.log(err)
        })
    }
  }

  function getNonVirtualDS() {
    darkstore_service.get_non_virtual_darkstores().subscribe((r) => {
      setNonVirtualDarkstoreData(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getColumns() {
    let tempCol = []
    if (currentTab == 1) {
      darkstoreColumns.map(data => {
        tempCol.push(data)
      })
      tempCol.push({
        title: 'Delivery Limit',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (
            <div>
              {record.delivery_limit}
            </div>
          )
        }
      });
      tempCol.push({
        title: 'Edit',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (
            <Button type="primary" onClick={() => showModal(record, 'Edit Darkstore')}>Edit</Button>
          )
        }
      });
    } else {
      checklistColumns.map(data => {
        tempCol.push(data)
      })
      tempCol.push({
        title: 'Edit',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (
            <Button type="primary" onClick={() => handleCheckListModal(true, record)}>Edit</Button>
          )
        }
      });
    }
    setColumns(tempCol)
  }

  function openNotificationWithIcon(type, msg) {
    notification[type]({ message: type.toUpperCase(), duration: 3, description: msg });
  };

  function showModal(data, type) {
    setMode(type)
    if (type === 'Edit Darkstore') {
      setModalState({
        name: data.name, latitude: data.latitude,
        longitude: data.longitude, address: data.address,
        valueByRadio: data.is_serviceable, currentActive: data.id,
        city_id: data.city_id, delivery_limit: data.delivery_limit, mobile_number: data.mobile_number,
        pincode: data.pincode, code: data.code, erp_code: data.erp_code,
        is_virtual: data.is_virtual, parent_id: data.parent_id,
        is_superstore: data.is_superstore, is_fraazo: data.has_fraazo_partner,
        image_attendance: data.image_attendance,
        otp_attendance: data.otp_attendance,
        is_night_serviceable: data.is_night_serviceable,
        is_serviceable: data.is_serviceable,
        serviceable_radius: data.serviceable_radius,
        opening_time: data.opening_timing,
        static_ip: data.static_ip,
        closing_time: data.closing_timing, valueByopening_time: data.opening_timing,
        valueByclosing_time: data.closing_timing
      })
    }
    modalRef.current.showModal(type, data)
  }

  const modalDOM = (value) => {
    setModalClosed(value)
  }

  function onChangeModal(value, type) {
    if (type === 'valueByRadio' || type === 'is_virtual' || type === 'is_superstore' || type === 'is_fraazo'
      || type === 'is_night_serviceable' || type === 'is_serviceable' || type === 'image_attendance'
      || type === 'otp_attendance') {
      setModalState(prevState => ({ ...prevState, [type]: value }))
    } else {
      setModalState(prevState => ({ ...prevState, [type]: value === "" ? undefined : value }))
    }
  }

  function onCancelModal() {
    setModalState({
      name: undefined, latitude: undefined,
      longitude: undefined, address: undefined,
      valueByRadio: undefined, currentActive: undefined,
      city_id: undefined, pincode: undefined,
      delivery_limit: undefined, is_superstore: undefined,
      image_attendance: undefined, otp_attendance: undefined,
      mobile_number: undefined, code: undefined, is_fraazo: undefined,
      erp_code: undefined, is_virtual: undefined, parent_id: undefined,
      is_night_serviceable: undefined, serviceable_radius: undefined,
      is_serviceable: undefined, opening_time: undefined,
      closing_time: undefined, valueByopening_time: undefined,
      valueByclosing_time: undefined, static_ip: undefined
    })
  }

  function modalSubmit() {
    if (!name || !latitude || !longitude || !address || !city_id ||
      !pincode || !mobile_number || !code || delivery_limit === undefined || is_fraazo === undefined
      || is_night_serviceable === undefined || !serviceable_radius || image_attendance === undefined 
      || otp_attendance === undefined) {
      openNotificationWithIcon('error', "Please fill darkstore form")
      modalRef.current.stopLoading()
    } else {
      if (mode === 'Add Darkstore') {
        if (is_virtual === undefined || valueByRadio === undefined || is_superstore === undefined) {
          openNotificationWithIcon('error', "Please fill darkstore form")
          modalRef.current.stopLoading()
          return
        }
      }
      if (is_virtual && parent_id === undefined) {
        openNotificationWithIcon('error', "Please select parent darkstore")
        modalRef.current.stopLoading()
        return
      }
      const payload = {
        id: mode === 'Edit Darkstore' ? currentActive : undefined,
        body: {
          name: name,
          latitude: latitude,
          longitude: longitude,
          address: address,
          city_id: city_id,
          delivery_limit: delivery_limit,
          pincode: pincode,
          city_id: city_id,
          mobile_number: mobile_number,
          code: code,
          erp_code: erp_code === undefined ? '' : erp_code,
          parent_id: parent_id,
          is_superstore: is_superstore,
          image_attendance: image_attendance,
          otp_attendance: otp_attendance,
          has_fraazo_partner: is_fraazo,
          is_night_serviceable: is_night_serviceable,
          is_serviceable: is_serviceable,
          serviceable_radius: serviceable_radius,
          static_ip: static_ip,
          opening_timing: valueByopening_time ?? null,
          closing_timing: valueByclosing_time ?? null
        }
      }
      if (mode === 'Edit Darkstore') {
        darkstore_service.update_darkstores(payload).subscribe((r) => {
          openNotificationWithIcon('success', 'Edited Successfully')
          getListing(currentTab)
          modalRef.current.handleCancel()
          setModalClosed("removed")
        },
          (err) => {
            console.log(err)
            modalRef.current.stopLoading()
            openNotificationWithIcon('error', err.response.errors[0])
          })
      } else {
        payload.body.is_virtual = is_virtual
        payload.body.is_serviceable = valueByRadio
        darkstore_service.create_darkstores(payload).subscribe((r) => {
          openNotificationWithIcon('success', 'Created Successfully')
          getListing(currentTab)
          modalRef.current.handleCancel()
          setModalClosed("removed")
        },
          (err) => {
            console.log(err)
            modalRef.current.stopLoading()
            openNotificationWithIcon('error', err.response.errors[0])
          })
      }
    }
  }

  function onChangeFlashSale(e) {
    const payload = {
      is_active: e
    }
    darkstore_service.update_flash_sale(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Flash Sale Updated Successfully')
      setFlashSale(e)
    },
      (err) => {
        console.log(err)
        openNotificationWithIcon('error', err.response.errors[0])
      })
  }

  function reset() {
    setDisable(true)
    setCurrent(1)
    setState({
      valueByMobno: undefined
    })
    setDarkstoreData([])
    setRenderData(false)
  }

  function changeTab(key) {
    setCurrentTab(key);
    setDarkstoreData([]);
    setDisable(true);
    setRenderData(false);
  }

  const submitChecklist = (values) => {
    const payload = {
      ...values,
    }
    if (checklistId) {
      payload.id = checklistId
    }
    if (checklistId) {
      darkstore_service.update_checklist(payload).subscribe((r) => {
        getListing(currentTab)
        setChecklistModal(false)
        setRowData(false)
        openNotificationWithIcon('success', 'Update Successfully')
      }, ((err) => {
        console.log("err", err)
      }))
    } else {
      darkstore_service.post_checklist(payload).subscribe((r) => {
        getListing(currentTab)
        setChecklistModal(false)
        openNotificationWithIcon('success', 'Created Successfully')
      }, ((err) => {
        console.log("err", err)
      }))
    }
    // darkstore_service.post_checklist(payload).subscribe((r) => {
    //   getListing(currentTab)
    //   setChecklistModal(false)
    // }, ((err) => {
    //   console.log("err", err)
    // }))
  }

  function pagination(page) {
    setCurrent(page)
    setDarkstoreData([])
    setRenderData(false)
  }

  const onEditCheckList = (data) => {
    const payload = {
      "darkstore_id": currentActive,
      "darkstore_checklist": data
    }
    // console.log("payload", payload)
    darkstore_service.edit_checklist(payload).subscribe((r) => {
      modalSubmit()
    }, ((err) => {
      console.log("err", err)
    }))
  }

  function onChangeModalTime(time, timeString, type) {
    // console.log(time, timeString, type)
    if(type === 'opening_time'){
      setModalState(prevState =>({...prevState, [type]: time, valueByopening_time: parseInt(timeString)}))
    }else{
      setModalState(prevState =>({...prevState, [type]: time, valueByclosing_time: parseInt(timeString)}))
    }
  }

  return (
    <div className="layer">
      <div className="ost">
        <Tabs defaultActiveKey="1" onChange={changeTab}>
          <TabPane tab="Darkstores" key="1"></TabPane>
          <TabPane tab="Checklist" key="2"></TabPane>
        </Tabs>
      </div>
      {darkstoreData && darkstoreNVData ?
        <div>
          {currentTab == "1" ?
            <div>
              <Row>
                <Card>
                  <Col span={24}>
                    <Input placeholder="Dark Store Name"
                      onChange={(e) => onChange(e.target.value, 'valueByDarkStore')}
                      value={valueByDarkStore} style={{ width: 200, margin: 10 }} />
                    <Button disabled={disable} onClick={() => getListing(currentTab)} style={{ width: 100, margin: 10 }}
                      type="primary">Search</Button>
                    <Button disabled={disable} onClick={reset} style={{ margin: 10 }}
                      type="primary" ghost shape="circle" icon={<ReloadOutlined />} />
                  </Col>
                </Card>
              </Row>
              <Row>
                <Card>
                  <Col span={24}>
                    <Button onClick={() => showModal(true, 'Add Darkstore')} style={{ width: 150, margin: 10 }}
                      type="primary">Add Darkstore</Button>
                    <span className="fl-right flash-sale"><b>Flash Sale : </b>
                      <Switch checked={flashSale} onChange={onChangeFlashSale} style={{ marginLeft: 10 }} />
                    </span>
                  </Col>
                </Card>
              </Row>
            </div>
            : <Row>
              <Card>
                <Col span={24}>
                  <Button onClick={() => handleCheckListModal(true)} style={{ width: 150, margin: 10 }} type="primary">Add Checklist</Button>
                </Col>
              </Card>
            </Row>}

          {darkstoreData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={darkstoreData} columns={columns} />
                {currentTab == 2 ?
                  <Pagination style={{ marginTop: 10, marginBottom: 10 }} current={current}
                    pageSize={50} total={state.total} onChange={pagination}
                    showSizeChanger={false} />
                  : null}
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }

          {modalClosed === "remove" ? null :
            <DarkstoreModal modalSubmit={modalSubmit} ref={modalRef}
              onChangeModal={onChangeModal}
              onChangeModalTime={onChangeModalTime}
              onCancelModal={onCancelModal}
              opening_time={opening_time}
              closing_time={closing_time}
              name={name} latitude={latitude}
              longitude={longitude} address={address}
              city_id={city_id} pincode={pincode}
              mobile_number={mobile_number} code={code}
              delivery_limit={delivery_limit}
              is_virtual={is_virtual} static_ip={static_ip}
              is_superstore={is_superstore}
              image_attendance={image_attendance}
              otp_attendance={otp_attendance}
              parent_id={parent_id} is_fraazo={is_fraazo}
              darkstoreNVData={darkstoreNVData}
              is_night_serviceable={is_night_serviceable}
              is_serviceable={is_serviceable}
              serviceable_radius={serviceable_radius}
              erp_code={erp_code} valueByRadio={valueByRadio}
              listingChecklist={listingChecklist} onEditCheckList={onEditCheckList}
              modalDOM={modalDOM} />
          }


          {checklistModal ?
            <ModalCheckList checklistModal={checklistModal}
              handleCheckListModal={handleCheckListModal}
              submitChecklist={submitChecklist} rowData={rowData} />
            : null}
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default DarkstoresList
