import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form } from 'antd';
import FRZSelect from '../../../_controls/FRZSelect';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZInputNumber from '../../../_controls/FRZInputNumber';

const procUnit = [
  { value: "po_unit", option: "Po Unit" },
  { value: "packets", option: "Packets" },
  { value: "crates", option: "Crates" },
]

const AddProductModalSTO = forwardRef((props, ref) => {
  const { modalSubmit, roles } = props

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState(false)
  const [data, setData] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setData(false)
      form.resetFields()
      setVisible(false)
      setLoading(false)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleCancelModal() {
    setData(false)
    form.resetFields()
    setVisible(false)
    setLoading(false)
  }

  const handleOnSubmit = (values) => {
    setLoading(true)
    modalSubmit(values, data.id)
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form2">

                <FRZSelect DropDownData={props.products} name="sku" isRequired={true}
                  label="SKU Name" placeholder="Select SKU Name" value="sku" option="name"
                  isShowSearch={true} width={"100%"} selectDisabled={title === 'Edit Product'}/>
                
                <FRZInputNumber name="transfer_unit_qty" isRequired={true}
                  label="Transfer Quantity" placeholder="Transfer Quantity" width={200} />

                <FRZSelect DropDownData={procUnit} name="transfer_unit_type" isRequired={true}
                  label="Transefer Unit Type" placeholder="Transefer Unit Type" value="value" option="option"
                  isShowSearch={true} width={200} />

          </Form>
        </Modal>
      :   
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form2">
        </Form>
      }
    </div>
  )
})

export default AddProductModalSTO
