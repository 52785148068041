import React from "react";

export const svgTabTwo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="26px"
      viewBox="0 0 26.619 27.502"
    >
      <g
        id="Group_135"
        data-name="Group 135"
        transform="translate(1168.619 -3621.276)"
      >
        <g
          id="Group_18"
          data-name="Group 18"
          transform="translate(-1691.369 3385.276)"
        >
          <path
            id="Path_3"
            data-name="Path 3"
            d="M542.369,246.06c0,5.28-9.56,16.665-9.56,16.665s-9.56-11.385-9.56-16.665a9.56,9.56,0,1,1,19.119,0Z"
            transform="translate(0 0)"
            fill="none"
            stroke="#2e4765"
            strokeWidth="1"
          />
        </g>
        <g
          id="Circle_BG"
          data-name="Circle BG"
          transform="translate(-1152 3625)"
          fill="#0d9112"
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <circle cx="4" cy="4" r="4" stroke="none" />
          <circle cx="4" cy="4" r="5" fill="none" />
        </g>
      </g>
    </svg>
  );
};

export const svgTabOne = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="26px"
      viewBox="0 0 24.619 27.502"
    >
      <g
        id="Group_135"
        data-name="Group 135"
        transform="translate(1168.619 -3621.276)"
      >
        <g
          id="Group_18"
          data-name="Group 18"
          transform="translate(-1691.369 3385.276)"
        >
          <path
            id="Path_3"
            data-name="Path 3"
            d="M542.369,246.06c0,5.28-9.56,16.665-9.56,16.665s-9.56-11.385-9.56-16.665a9.56,9.56,0,1,1,19.119,0Z"
            transform="translate(0 0)"
            fill="none"
            stroke="#2e4765"
            strokeWidth="1"
          />
          <circle
            id="Ellipse_1"
            data-name="Ellipse 1"
            cx="4.166"
            cy="4.166"
            r="4.166"
            transform="translate(528.643 241.7)"
            fill="none"
            stroke="#2e4765"
            strokeWidth="1"
          />
        </g>
        <circle
          id="Circle_BG"
          data-name="Circle BG"
          cx="4"
          cy="4"
          r="4"
          transform="translate(-1152 3625)"
          fill="#e3344f"
        />
      </g>
    </svg>
  );
};

export const svgHome = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g id="Group_84" data-name="Group 84" transform="translate(-1390 -390)">
        <rect
          id="Rectangle_34"
          data-name="Rectangle 34"
          width="22"
          height="22"
          rx="5"
          transform="translate(1390 390)"
          fill="#716c89"
        />
        <g id="home" transform="translate(1395.182 394.208)">
          <path
            id="Shape"
            d="M8.132,13a.381.381,0,0,1-.381-.381V9.559a.64.64,0,0,0-.639-.639H5.888a.64.64,0,0,0-.639.639v3.06A.381.381,0,0,1,4.868,13H2.793a1.4,1.4,0,0,1-1.4-1.4V7.7H1.181l-.037,0a1.2,1.2,0,0,1-.8-2.039l.005-.005,5.3-5.3a1.2,1.2,0,0,1,1.693,0l5.3,5.3h0A1.2,1.2,0,0,1,11.8,7.7h-.2v3.9a1.4,1.4,0,0,1-1.4,1.4Z"
            transform="translate(-0.182 -0.208)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export const svgFalseAlarm = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="26"
      viewBox="0 0 11.344 15.789"
    >
      <g id="ic_location_on_48px" transform="translate(0)">
        <path
          id="Shape"
          d="M0,5.526A5.6,5.6,0,0,1,5.671,0a5.6,5.6,0,0,1,5.672,5.526c0,4.144-5.672,10.263-5.672,10.263S0,9.671,0,5.526Z"
          transform="translate(0)"
          fill="#e3344f"
        />
      </g>
    </svg>
  );
};
