import React from "react";
import { Button, Switch } from 'antd';

function ImsSubmit(props){

  return(
    <div className="frz-text-center frz-justify-end frz-dis-flex frz-bm-20 frz-rm-40">
      <div className="frz-rm-10 frz-tm-5">
          <Switch label="Deduct COD form Bills" defaultChecked={props.isDeductCOD} onChange={(e)=>{
            props.setState(prevState => ({ ...prevState, deduct_cod: e, }))
          }}/> <span>Deduct COD from Bills</span>
      </div>
      <Button onClick={() => {
            props.form
              .validateFields()
              .then(values => {
                props.handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }} type="primary" className="frz-w-150px">
        Submit
      </Button>
    </div>
  )
}

export default ImsSubmit