import React from "react";

export const overageNestedColumns = (onEdit, showModalOverage) => {

  return [
    {
      title: 'Sr. No.',
      dataIndex: 'id',
      key: 'id',
      align: "center",
      width: 65
    },
    {
      title: 'Order Id',
      dataIndex: 'order_id',
      key: 'order_id',
      align: "center",
      width: 110,
      render: record => <div className="underline"><a onClick={() => showModalOverage(record, 'Show')}>{record}</a></div>,
      sorter: (a, b) => a.order_id - b.order_id
    },
    {
      title: 'Customer Name',
      dataIndex: 'customer_name',
      key: 'customer_name',
      align: "center",
      width: 150,
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name)
    },
    {
      title: 'Packer',
      dataIndex: 'packer',
      key: 'packer',
      align: "center",
      width: 100,
      sorter: (a, b) => {
        a = a.packer || '';
        b = b.packer || '';
        return a.localeCompare(b);
    }
    },
    {
      title: 'Rider',
      dataIndex: 'rider',
      key: 'rider',
      align: "center",
      width: 100,
      sorter: (a, b) => {
        a = a.rider || '';
        b = b.rider || '';
        return a.localeCompare(b);
    }
    },
    {
      title: 'Shift Incharge',
      dataIndex: 'shift_incharge',
      key: 'shift_incharge',
      align: "center",
      sorter: (a, b) => a.shift_incharge.localeCompare(b.shift_incharge)
    },
    {
      title: 'Overage Weight',
      dataIndex: 'overage_weight',
      key: 'overage_weight',
      align: "center",
      sorter: (a, b) => a.overage_weight - b.overage_weight
    },
    {
      title: 'Total Weight',
      dataIndex: 'total_weight',
      key: 'total_weight',
      align: "center",
      sorter: (a, b) => a.total_weight - b.total_weight
    }
  ]
}

export const skuColumns = () => {
  return [
    {
      title: 'SKU Code',
      dataIndex: 'sku_code',
      key: 'sku_code',
      align: "center",
      sorter: (a, b) => a.sku_code.localeCompare(b.sku_code)
    },
    {
      title: 'SKU Name',
      dataIndex: 'sku_name',
      key: 'sku_name',
      align: "center",
      sorter: (a, b) => a.sku_name.localeCompare(b.sku_name)
    },
    {
      title: 'Unit of Measure',
      dataIndex: 'unit_of_measure',
      key: 'unit_of_measure',
      align: "center",
      sorter: (a, b) => a.unit_of_measure.localeCompare(b.unit_of_measure)
    },
    {
      title: 'Procurement Unit',
      dataIndex: 'procurement_unit',
      key: 'procurement_unit',
      align: "center",
      sorter: (a, b) => {
        a = a.procurement_unit || '';
        b = b.procurement_unit || '';
        return a.localeCompare(b);
    }
    },
    {
      title: 'Conversion Factor',
      dataIndex: 'conversion_factor',
      key: 'conversion_factor',
      align: "center",
      sorter: (a, b) => a.conversion_factor - b.conversion_factor
    },
    {
      title: 'Buying Price',
      dataIndex: 'buying_price',
      key: 'buying_price',
      align: "center",
      sorter: (a, b) => a.buying_price - b.buying_price
    },
    {
      title: 'Selling Price',
      dataIndex: 'selling_price',
      key: 'selling_price',
      align: "center",
      sorter: (a, b) => a.selling_price - b.selling_price
    },
    {
      title: 'Mean Weight',
      dataIndex: 'mean_weight',
      key: 'mean_weight',
      align: "center",
      sorter: (a, b) => a.mean_weight - b.mean_weight
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
      align: "center",
      sorter: (a, b) => a.count - b.count
    },
    {
      title: 'Lower Weight Tolerance',
      dataIndex: 'lower_weight_tolerance',
      key: 'lower_weight_tolerance',
      align: "center",
      sorter: (a, b) => a.lower_weight_tolerance - b.lower_weight_tolerance
    },
    {
      title: 'Upper Weight Tolerance',
      dataIndex: 'higher_weight_tolerance',
      key: 'higher_weight_tolerance',
      align: "center",
      sorter: (a, b) => a.higher_weight_tolerance - b.higher_weight_tolerance
    },
    {
      title: 'Overage Weight',
      dataIndex: 'overage',
      key: 'overage',
      align: "center",
      sorter: (a, b) => a.overage - b.overage
    },
    {
      title: 'Actual Weight',
      dataIndex: 'actual_weight',
      key: 'actual_weight',
      align: "center",
      sorter: (a, b) => a.actual_weight - b.actual_weight
    }
  ]
}