import { Http } from "../_modules/http";

export class ProductService {
  http = new Http();

  getPayloadPath(params) {
    let payloadPath = "";
    Object.keys(params).forEach(function (key) {
      let value = params[key];
      if (value != undefined) {
        payloadPath += `&${key}=${value}`;
      }
    });
    // console.log(payloadPath);
    return payloadPath;
  }

  getProducts(params) {
    const pathUrl = process.env.REACT_APP_API_URL + "admin_dashboard/v1/products/products?" + this.getPayloadPath(params);
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  addProduct(payload) {
    const pathUrl = process.env.REACT_APP_API_URL + "admin_dashboard/v1/products/products";
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(pathUrl, payload, headers);
  }
  updateProduct(payload) {
    const pathUrl = process.env.REACT_APP_API_URL + "admin_dashboard/v1/products/products/" + payload.id;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(pathUrl, payload, headers);
  }

  getProductCategories() {
    const pathUrl = process.env.REACT_APP_API_URL + "admin_dashboard/v1/products/product_category_list";
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }
  getProductSubCategories() {
    const pathUrl = process.env.REACT_APP_API_URL + "admin_dashboard/v1/products/product_sub_categories";
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  getProductBrands() {
    const pathUrl = process.env.REACT_APP_API_URL + "admin_dashboard/v1/products/product_brands";
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  getProductCompanys() {
    const pathUrl = process.env.REACT_APP_API_URL + "admin_dashboard/v1/products/product_company_list";
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  get_dynamic_brands(id) {
    const pathUrl = process.env.REACT_APP_API_URL + "admin_dashboard/v1/products/get_brand_list?company_id=" + id;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  getProductDetails(productId) {
    const pathUrl = process.env.REACT_APP_API_URL + `admin_dashboard/v1/products/${productId}/product_details`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }
  updateProductDetails(productId, payload) {
    const pathUrl = process.env.REACT_APP_API_URL + `admin_dashboard/v1/products/${productId}/update_descriptions`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(pathUrl, payload, headers);
  }

  getProductPricesDarkstoreWise(productId) {
    const pathUrl = process.env.REACT_APP_API_URL + `admin_dashboard/v1/products/${productId}/darkstore_products?` + 'by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }
  updateProductPricesDarkstoreWise(payload) {
    const pathUrl = process.env.REACT_APP_API_URL + `admin_dashboard/v1/products/update_price`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(pathUrl, payload, headers);
  }

  get_product_sequence(payload) {
    const pathUrl = process.env.REACT_APP_API_URL + `admin_dashboard/v1/products/product_sub_category_sequence/${payload.valueBySubCat}`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }

  update_product_sequence(payload) {
    const pathUrl = process.env.REACT_APP_API_URL + `admin_dashboard/v1/products/product_sequence_update`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(pathUrl, payload, headers);
  }

  get_product_list() {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/products/get_list_for_combo'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_product_requests() {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/products/get_product_requests'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  darkstore_flash_sales(id) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/products/product_flash_sales?product_id=' + id + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  update_flash_sales(payload) {
    const url = process.env.REACT_APP_API_URL + `admin_dashboard/v1/products/product_flash_sales`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  get_product_tax_slab(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/product_tax_slabs/product_tax_slabs?' + "page=" + `${payload.page}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_product_tax_slab_dropdown() {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/product_tax_slabs/get_product_tax_slabs'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  add_product_tax_slab(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/product_tax_slabs/product_tax_slabs';
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  update_product_tax_slab(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/product_tax_slabs/product_tax_slabs/' + `${payload.id}`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  get_local_names(id) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/products/' + id + '/product_local_names'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  update_local_names(payload) {
    const url = process.env.REACT_APP_API_URL + `admin_dashboard/v1/products/update_product_local_names`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  getProductDS(id) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/products/get_active_darkstores?id=' + id
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  updateProductDS(payload) {
    const url = process.env.REACT_APP_API_URL + `admin_dashboard/v1/products/darkstore_wise_activation`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  getProductInstock(id) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/products/get_instock_darkstores?id=' + id
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  updateProductInstock(payload) {
    const url = process.env.REACT_APP_API_URL + `admin_dashboard/v1/products/darkstore_wise_stock`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  updateProductDSInventory(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/products/' + payload.id + '/update_inventory_management';
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload.inventory_management, headers);
  }

  getProductActivation(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/products/get_darkstore_wise_activation_v2?product_id=' + payload.id + '&city_id=' + payload.city;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }

  updateProductActivation(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/products/darkstore_wise_activation_v2';
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  get_product_cess_slab(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/product_tax_slabs/product_cess_slabs?' + "page=" + `${payload.page}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_product_cess_slab_dropdown() {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/product_tax_slabs/get_product_cess_slabs'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  add_product_cess_slab(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/product_tax_slabs/product_cess_slabs';
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  update_product_cess_slab(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/product_tax_slabs/product_cess_slabs/' + `${payload.id}`;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }

  update_product_image_sequence(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/products/update_image_sequence';
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.patch(url, payload, headers);
  }

  get_multiple_image(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/images/images/?imageable_type=' + payload.type + `&imageable_id=${payload.id}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }
  // admin_dashboard/v1/images/images/220
  update_single_image(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/images/images/' + payload.id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.patch(url, payload, headers);
  }

  add_multiple_images(payload, id) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/images/products/' + id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.patch(url, payload, headers);
  }

  add_combo(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/products/' + payload.product_id +'/add_combo_products'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers);
  }

  add_multiple_images_to_existing(payload, id) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/products/products/' + id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.patch(url, payload, headers);
  }

  get_city_level_price(id) {
    const pathUrl = process.env.REACT_APP_API_URL + `/admin_dashboard/v1/products/${id}/city_wise_darkstores`    
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(pathUrl, headers);
  }
  get_darkstores_price(product_id, code) {
    const url = process.env.REACT_APP_API_URL + `/admin_dashboard/v1/products/${product_id}/city_wise_darkstore_products?by_city=` + code
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers);
  }
 
  update_single_darkstore_price(payload) {
    const url = process.env.REACT_APP_API_URL + '/admin_dashboard/v1/products/update_product_price';
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }
  update_all_darkstores_price(payload) {
    const url = process.env.REACT_APP_API_URL + '/admin_dashboard/v1/products/update_product_price';
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers);
  }
}
