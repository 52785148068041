export const reviewColumns = [
  {
    title: 'Customer Name',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.order_details.customer_details.name
    }
  },
  {
    title: 'Order No',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.order_details.order_number
    }
  },
  {
    title: 'Order Type',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.order_details.order_type_detail
    }
  },
  {
    title: 'Delivery At',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.order_details.delivered_at
    }
  },
  {
    title: 'Rating',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.order_review.ratings
    }
  },
]

export const reviewItemColumns = [
  {
    title: 'SKU Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: 'Pack Size',
    dataIndex: 'pack_size',
    key: 'pack_size',
    align: 'center',
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    key: 'qty',
    align: 'center',
  },
  {
    title: 'Review',
    dataIndex: 'display_name',
    key: 'display_name',
    align: 'center',
  },
  {
    title: 'Reason',
    dataIndex: 'review_detail_reason_name',
    key: 'review_detail_reason_name',
    align: 'center',
  },
]
