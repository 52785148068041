import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Select } from 'antd';
import { OperationsService } from "../../../_services/operations";
import MainTable from '../../../components/antd/table';
import { nodeListingColumns } from "../../../components/antd/columns/operations/category.js";
import { openNotificationWithIcon } from '../../../_modules/notification'
import CCWHModal from '../../../components/operations/category/cc_wh_modal'; 
import { ReloadOutlined } from '@ant-design/icons';
import FrzDynamicDownload from '../../../_controls/FRZDynamicDownload';

const operation_service = new OperationsService();

const CCWH = () => {
  const modalRef = useRef(null);
  const [listingData, setListingData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [mode, setMode] = useState(undefined)
  const [managedBy, setManagedBy] = useState(false)
  const [city, setCity] = useState(false)
  const [state, setState] = useState({ nodeType: undefined });
  const { nodeType } = state

  const { Option } = Select;

  const nodeTypeData = [{ value: "CC", option: "CC" }, { value: "WH", option: "WH" }]

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getListing()
      getAdminCC()
      getCity()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getListing() {
    setSpinEnable(true)
    setListingData([])
    const payload = { nodeType: nodeType === undefined ? `["CC","WH"]` : nodeType,
                      show_all: true }
    operation_service.get_node_listing(payload).subscribe((r) => {
      //   setTotal(r.response.meta.total_pages * 50);
      setSpinEnable(false);
      setListingData(r.response.data);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  function getAdminCC() {
    operation_service.get_admin_cc().subscribe((r) => {
      setManagedBy(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getCity() {
    operation_service.get_city().subscribe((r) => {
      setCity(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getColumns() {
    nodeListingColumns.map(data => {
      columns.push(data)
    })
    // columns.push({
    //   title: "Managed By",
    //   dataIndex: "cc_admin",
    //   key: "cc_admin",
    //   align: "center",
    //   render: (record) => {
    //     return (record && record[0] && record[0].name)
    //   },
    // })
    columns.push({
      title: "Actions",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <Button type="primary" onClick={() => showModal(record, 'Edit')}>Edit</Button>
        );
      },
    });
    setColumns(columns)
  }

  function showModal(data, type) {
    setMode(type)
    modalRef.current.showModal(type, data)
  }

  function reset() {
    setState({ nodeType: undefined })
    setDisable(true)
    setListingData([])
    setRenderData(false)
  }


  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
  }

  function modalSubmit(payloadData, id) {
    const payload = {
      ...payloadData, id: id ? id : undefined, is_virtual: false
    }
    if (mode === 'Edit') {
      operation_service.edit_node_listing(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Edited Successfully')
        getListing()
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors?.[0])
        })
    } else {
      operation_service.add_node_listing(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Created Successfully')
        getListing()
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors?.[0])
        })
    }
  }

  return (
    <div className="layer">
      {listingData && managedBy ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Select style={{ width: 200, margin: 10 }} value={nodeType} showSearch
                  onChange={(e) => onChange(e, 'nodeType')} placeholder="Node Type">
                  {nodeTypeData.map(data => {
                    return (
                      <Option key={data.value} value={data.value}>{data.option}</Option>
                    )
                  })}
                </Select>
                <Button disabled={disable} onClick={getListing}
                  className="frz-w-100px frz-m-10" type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Create Collection Center')} className="frz-w-250p frz-m-10"
                  type="primary">Add Collection Center</Button>
                <span className="fl-right">   
                  <FrzDynamicDownload name={'Node Master'} fileName={'Node Master'} url={`admin_dashboard/procurement/node_master_report`}
                                      payload={'?'} type={'job'}
                                      reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'} />                    
                </span>
              </Col>
            </Card>
          </Row>
          {listingData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={listingData} columns={columns} />
              </Col>
            </Row>
          : (disable || spinEnable ?
            (spinEnable ?
              <div className="spin-center"><Spin tip="...Loading" /></div>
              :
              <div className="no-data">No Data Available</div>)
            :
            <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <CCWHModal modalSubmit={modalSubmit} ref={modalRef} managedBy={managedBy} nodeTypeData={nodeTypeData} 
                     city={city}/>
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default CCWH
