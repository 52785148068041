import React, { useEffect, useState, useRef } from "react";
import { Spin, Button, Card, Row, Col, Tabs, Table, Pagination } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { DarkstoreService } from "../../_services/darkstore";

const darkstore_service = new DarkstoreService();
const { TabPane } = Tabs;

function DarkstoreQualityIssues() {
  const [stockData, setStockData] = useState(false);
  const [renderData, setRenderData] = useState(false);
  const [DarkConsolidatedStats, setDarkConsolidatedStats] = useState(false);
  const [columns, setColumns] = useState([]);
  const [skuColumns, setSkuColumns] = useState([]);
  const [spinEnable, setSpinEnable] = useState(false);
  const [disable, setDisable] = useState(true);
  const [current, setCurrent] = useState(1);
  const [state, setState] = useState({});
  const [currentTab, setCurrentTab] = useState(1);

  useEffect(() => {
    if (!renderData) {
      setRenderData(true);
      getStock(currentTab);
    }
  });

  function getStock(key) {
    setSpinEnable(true);
    setStockData([]);
    if (key == 1) {
      darkstore_service.get_darkstores_quality_category().subscribe(
        (r) => {
          setSpinEnable(false);
          if (columns.length == 0) {
            getColumns(r.response.data.column_attributes);
          }
          setStockData(r.response.data.stats_data);
          setDarkConsolidatedStats(r.response.data.consolidated_stats);
        },

        (err) => {
          console.log(err);
        }
      );
    } else {
      const payload = {
        page: current,
      };
      darkstore_service.get_sku_quality_category(payload).subscribe(
        (r) => {
          setState((prevState) => ({
            ...prevState,
            total: r.response.meta.total_pages * 50,
          }));
          setSpinEnable(false);
          if (skuColumns.length == 0) {
            getSkuColumns(r.response.data.column_attributes);
          }
          setStockData(r.response.data.stats_data);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  function getColumns(data) {
    data.map((data) => {
      columns.push({
        title: data.replace(/_/g, " "),
        dataIndex: "",
        key: data,
        align: "center",
        sorter: data === "darkstore_name" ? false : (a, b) => a[data] - b[data],
        render: (record) => {
          return <div>{record[data]}</div>;
        },
      });
    });
    setColumns(columns);
  }

  function getSkuColumns(data) {
    data.map((data) => {
      skuColumns.push({
        title: data.replace(/_/g, " "),
        dataIndex: "",
        key: data,
        align: "center",
        sorter:
          data === "product_name" || data === "sku_code"
            ? false
            : (a, b) => a[data] - b[data],
        render: (record) => {
          return <div>{record[data]}</div>;
        },
      });
    });
    setSkuColumns(skuColumns);
  }

  function changeTab(key) {
    setCurrentTab(key);
    setStockData([]);
    setCurrent(1);
    setDisable(true);
    setRenderData(false);
  }

  function pagination(page) {
    setCurrent(page);
    setStockData([]);
    setRenderData(false);
  }

  return (
    <div className="layer">
      <div className="ost">
        <Tabs defaultActiveKey="1" onChange={changeTab}>
          <TabPane tab="Darkstore Wise" key="1"></TabPane>
          <TabPane tab="SKU Wise" key="2"></TabPane>
        </Tabs>
      </div>
      {stockData ? (
        <div>
          <Row>
            <Card>
              <Col span={24}>
                {currentTab == 2 ? null : 
                <> 
                <span style={{ padding: 15 }}>
                  <b>Cc qc: </b>
                  {DarkConsolidatedStats.cc_qc}
                </span>
                <span style={{ padding: 15 }}>
                  <b>Dispatch qc: </b>
                  {DarkConsolidatedStats.dispatch_qc}
                </span>
                <span style={{ padding: 15 }}>
                  <b>Process Qc: </b>
                  {DarkConsolidatedStats.process_qc}
                </span>
                <span style={{ padding: 15 }}>
                  <b>Last Mile: </b>
                  {DarkConsolidatedStats.last_mile}
                </span>
                <span style={{ padding: 15 }}>
                  <b>Others: </b>
                  {DarkConsolidatedStats.others}
                </span>
             
                <span style={{ padding: 15 }}>
                  <b>Total Issues: </b>
                  {DarkConsolidatedStats.total_issues}
                </span>
                </>}
               

                <Button
                  onClick={(e) => getStock(currentTab)}
                  style={{ margin: 10 }}
                  type="primary"
                  ghost
                  shape="circle"
                  icon={<ReloadOutlined />}
                />
              </Col>
            </Card>
          </Row>
          {stockData.length > 0 ? (
            <Row>
              <Col span={24}>
                <Card style={{ paddingTop: 5, marginTop: 20 }}>
                  <Row>
                    <Col span={24}>
                      {currentTab == 1 ? (
                        <Table
                          columns={columns}
                          dataSource={stockData}
                          pagination={false}
                          scroll={{ x: 240 }}
                          rowKey="darkstore_name"
                        />
                      ) : (
                        <Table
                          columns={skuColumns}
                          dataSource={stockData}
                          pagination={false}
                          scroll={{ x: 240 }}
                          rowKey="product_name"
                        />
                      )}
                    </Col>
                  </Row>
                </Card>
                {currentTab == 2 ? (
                  <Pagination
                    style={{ marginTop: 10, marginBottom: 10 }}
                    current={current}
                    pageSize={50}
                    total={state.total}
                    onChange={pagination}
                    showSizeChanger={false}
                  />
                ) : null}
              </Col>
            </Row>
          ) : disable || spinEnable ? (
            spinEnable ? (
              <div className="spin-center">
                <Spin tip="...Loading" />
              </div>
            ) : (
              <div className="no-data">No Data Available</div>
            )
          ) : (
            <div className="no-data">No Data Available On Selected Filters</div>
          )}
        </div>
      ) : (
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      )}
    </div>
  );
}

export default DarkstoreQualityIssues;
