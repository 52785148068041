import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Radio, Button, InputNumber } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import FRZSelect from '../../../_controls/FRZSelect';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import FRZCustomRadioButon from '../../../_controls/FRZCustomRadioButon';
import rupee from '../../../static/images/rupee.svg';
import { tailLayout } from '../../../_modules/controllayout'

const fuelType = [{ value: 'PER_ORDER', option: 'Per Order' }, { value: 'PER_KM', option: 'Per KM' }]

function ImsB(props) {
  const [period] = useState([{ period: 'DAILY', name: 'Daily' },
  { period: 'WEEKLY', name: 'Weekly' }, { period: 'MONTHLY', name: 'Monthly' }])
  const [fuelRType, setFuelRType] = useState(props.radioType ? props.radioType.type : null)

  function onChangeType(e) {
    setFuelRType(e)
    props.handleOnFormChange(true)
  }

  const onFormChange = () => {
    props.handleOnFormChange(true)
  }

  // useEffect(() => {
  //   if (props.radioType && props.radioType) {
  //     console.log("first")
  //     setFuelRType(props.radioType.type)
  //   }
  // }, [props.radioType])

  // useEffect(() => {
  //   props.form.setFieldsValue({distance_config:[{distance: 10, rate: 4}, {distance: 45, rate: 33}]})
  // }, [])

  return (
    <div className='frz-ims-box frz-fuel-radio'>
      <div className='frz-ims-text'>
        Fuel Reimbursement (B)
      </div>
      <Form
        form={props.form}
        layout="horizontal"
        name="form"
        onChange={() => onFormChange()}>
        <Row>
          <Col span={12}>
            <FRZCustomRadioButon name={['B', 'type']} label="" setOnChange={onChangeType}
              optionData={fuelType} option="option" value="value" />
          </Col>
          <Col span={12}>
            {fuelRType === 'PER_ORDER' ?
              <div className='frz-dis-flex frz-justify-end frz-tp-10'>
                <div className='frz-p-relative frz-ims-rs-custom frz-ims-placeholder'>
                  <div className='frz-rupee-ims'><img src={rupee} /></div>
                  <FRZInputNumber minValue={0} name={['B', 'order_rate']} label="" placeholder="per order rate" width={150} errorMsg="Please enter rate" />
                </div>
              </div>
              :
              (fuelRType === 'PER_KM' ?
                <div className='frz-fuel-km-padding'>
                  <Form.List name={['B', 'distance_config']}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name }) => (
                          <div key={key} className='frz-dis-flex frz-justify-end frz-ims-placeholder'>
                            <Form.Item
                              name={[name, 'distance']}
                              label={''}
                              rules={[{ required: true, message: 'Please enter distance' }]}
                            >
                              <InputNumber min={0} placeholder="enter KM" width={200} className='frz-w-150px' />
                            </Form.Item>
                            <div className='frz-lp-10'></div>
                            <div className='frz-p-relative frz-ims-rs-custom frz-ims-placeholder'>
                              <div className='frz-rupee-ims'><img src={rupee} /></div>
                              <Form.Item
                                name={[name, 'rate']}
                                label={''}
                                rules={[{ required: true, message: 'Please enter rate' }]}
                              >
                                <InputNumber min={0} placeholder="rate" className='frz-w-150px' />
                              </Form.Item>
                            </div>
                            <div className='frz-lp-10'></div>
                            <div className='frz-tp-5'>
                              <MinusCircleOutlined onClick={() => remove(name)} />
                            </div>
                          </div>
                        ))}
                        <div className='frz-ims-add' onClick={() => add()}>
                          + Add Field
                        </div>
                      </>
                    )}
                  </Form.List>
                </div> : null)
            }
          </Col>
        </Row>
      </Form>
    </div >
  )
}

export default ImsB