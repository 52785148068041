import React from "react";
import { Table, Row, Col, Card } from "antd";

function FreebieTable(props) {
  return (
    <div>
      <Card className="freebieCard">
        <Row>
          <Col span={24}>
            <Table
              columns={props.columns}
              expandedRowRender={props.expandedRow}
              dataSource={props.dataSource}
              pagination={false}
              components={props.components}
              scroll={{ x: 240, y: 'calc(100vh - 254px)'}}
              loading={props.loading}
              onRow={props.onRow}
              rowKey="id"
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default FreebieTable;
