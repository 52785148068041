export const slotColumns = [
  {
    title: "Time",
    dataIndex: "slot_string",
    key: "slot_string",
    align: "center"
  },
  {
    title: "Is Active",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_active !== null ? record.is_active.toString() : null
    }
  },
  {
    title: "Is Pickup",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_pickup !== null ? record.is_pickup.toString() : null
    }
  },
  {
    title: "Is Scheduled",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_scheduled !== null ? record.is_scheduled.toString() : null
    }
  },
  {
    title: "Is Society",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_society !== null ? record.is_society.toString() : null
    }
  },
]
