import React, { useEffect, useState, useRef } from "react";
import {
  Spin,
  Button,
  Card,
  Row,
  Col,
  Input,
  Pagination,
  notification,
} from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import Search from "../../components/antd/search";
import MainTable from "../../components/antd/table";
import { productColumns } from "../../components/antd/columns/master/product";
import { ProductService } from "../../_services/product";
import ProductAddFormModal from "../../components/master/product/add_form";
import ProductDetailModal from "../../components/master/product/details_modal";
import FrzDynamicDownload from '../../_controls/FRZDynamicDownload';

const productService = new ProductService();

function ProductList() {
  const modalRef = useRef(null);
  const detailsModalRef = useRef(null);
  const [productData, setProductData] = useState(false);
  const [productCategories, setProductCategories] = useState(false);
  const [productSubCategories, setProductSubCategories] = useState(false);
  const [productList, setProductList] = useState(false);
  const [productRequest, setProductRequest] = useState(false);
  const [productBrandsList, setProductBrandsList] = useState(false);
  const [companyList, setCompanyList] = useState(false);
  const [productTaxSlab, setProductTaxSlab] = useState([])
  const [productCessSlab, setProductCessSlab] = useState([])
  const [renderData, setRenderData] = useState(false);
  const [current, setCurrent] = useState(1);
  const [columns, setColumns] = useState([]);
  const [spinEnable, setSpinEnable] = useState(false);
  const [disable, setDisable] = useState(true);
  const [mode, setMode] = useState(undefined);
  const [imageUrl, setImageUrl] = useState(undefined)
  const [imgLoading, setImgLoading] = useState(false)
  const [showModalFunc, setShowModalFunc] = useState(false)
  const [fetchColm, setFetchColm] = useState(false)
  const [imageSizeAcceptable, setImageSizeAcceptable] = useState(false)
  const [imageErrMsg, setImageErrMsg] = useState(false)
  const [catalogRole, setCatalogRole] = useState(false)
  // const [detailsModal, setDetailsModal] = useState(undefined);
  const values = {
    valueByCategory: undefined,
    valueBySubCategory: undefined,
    valueByName: undefined,
    valueBySKU: undefined,
    valueByCompany: undefined,
    valueByBrand: undefined
  };
  const [state, setState] = useState({
    values,
  });
  const [modalState, setModalState] = useState({
    /*  name: undefined, sku: undefined,
         variant_name: undefined, description: undefined,
         image_url: undefined, uom: undefined, */
  });
  const { } = modalState;
  const {
    valueByName,
    valueByCategory,
    valueBySubCategory,
    valueBySKU,
    valueByCompany,
    valueByBrand
  } = state;

  useEffect(() => {
    if (!renderData) {
      setRenderData(true);
      getProducts();
    }
  });

  useEffect(() => {
    getProductCategories();
    getProductSubCategories();
    getProductBrands();
    getProductList();
    getProductRequest()
    setFetchColm(true)
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['catalog_team_lead'].includes(data)) {
        setCatalogRole(true)
      }
    })
  }, []);

  useEffect(() => {
    if (fetchColm) {
      setFetchColm(false)
      getColumns()
    }
  })

  function getProducts() {
    setSpinEnable(true);
    setProductData([]);
    const payload = {
      page: current,
      starts_with: valueByName ?? "",
      by_sku: valueBySKU ? valueBySKU.toUpperCase() : '',
      by_category: valueByCategory,
      by_sub_category: valueBySubCategory,
      by_company: valueByCompany ?? '',
      by_brand: valueByBrand ?? ''
    };
    productService.getProducts(payload).subscribe(
      (r) => {
        setState((prevState) => ({
          ...prevState,
          total: r.response.meta.total_pages * 50,
        }));
        setSpinEnable(false);
        setProductData(r.response.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  function getProductList() {
    productService.get_product_list().subscribe(
      (r) => {
        const obj =[];
        r.response.data.forEach(function (elem) {
          obj.push({
            id: elem.id,
            name: elem.name + ' - ' + elem.sku
          });
        });
        setProductList(obj)
        //setProductList(r.response.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  function getProductRequest() {
    productService.get_product_requests().subscribe(
      (r) => {
        setProductRequest(r.response.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  const handleImageUrl = (value) => {
    setImageUrl(value)
  }

  function getProductCategories() {
    setProductCategories([]);
    productService.getProductCategories().subscribe(
      (r) => {
        setState((prevState) => ({ ...prevState }));
        setProductCategories(r.response.data.sort((a, b) => a.name > b.name ? 1 : -1));
      },
      (err) => {
        console.log(err);
      }
    );
  }

  function getProductSubCategories() {
    setProductSubCategories([]);
    productService.getProductSubCategories().subscribe(
      (r) => {
        setState((prevState) => ({ ...prevState }));
        setProductSubCategories(r.response.data.sort((a, b) => a.name > b.name ? 1 : -1));
      },
      (err) => {
        console.log(err);
      }
    );
  }

  function getProductBrands() {
    productService.getProductCompanys().subscribe(
      (r) => {
        setCompanyList(r.response.data);
      },
      (err) => {
        console.log(err);
      }
    );
    productService.getProductBrands().subscribe(
      (r) => {
        setProductBrandsList(r.response.data);
      },
      (err) => {
        console.log(err);
      }
    );
    productService.get_product_tax_slab_dropdown().subscribe((r) => {
      setProductTaxSlab(r.response)
    }, (err) => {
      console.log(err)
    })
    productService.get_product_cess_slab_dropdown().subscribe((r) => {
      setProductCessSlab(r.response)
    }, (err) => {
      console.log(err)
    })
  }

  function getColumns() {
    productColumns.map((data) => {
      columns.push(data);
    });
    columns.push({
      title: "Actions",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <Button
            type="primary"
            onClick={() =>
              setShowModalFunc(record)
            }
          >
            View/Edit
          </Button>
        );
      },
    });

    setColumns(columns);
  }

  useEffect(() => {
    if (showModalFunc) {
      detailsModalRef.current.showModal("Product Details", {
        ...showModalFunc,
      })
    }
  }, [showModalFunc]);

  function onChange(value, type) {
    resetAndSetCategorySelection(type);
    setState((prevState) => ({ ...prevState, [type]: value }));
    setDisable(false);
    setCurrent(1);
  }

  function resetAndSetCategorySelection(type) {
    if (type === "valueByCategory") {
      setState({
        valueBySubCategory: undefined,
      });
    } else if (type === "valueBySubCategory") {
      setState({
        valueByCategory: undefined,
      });
    }
  }

  function reset() {
    setState({
      values,
    });
    setDisable(true);
    setCurrent(1);
    setProductData([]);
    setRenderData(false);
  }

  function pagination(page) {
    setCurrent(page);
    setProductData([]);
    setRenderData(false);
  }

  function showModal(data, type) {
    setMode(type);
    modalRef.current.showModal(type, data);
  }

  function onChangeModal(value, type) {
    if (type === "valueByRadio") {
      setModalState((prevState) => ({ ...prevState, [type]: value }));
    } else {
      setModalState((prevState) => ({
        ...prevState,
        [type]: value === "" ? undefined : value,
      }));
    }
  }
  function onCancelModal() {
    setImageUrl(undefined)
    setModalState({
      /*  society_name: undefined, latitude: undefined,
             longitude: undefined, latlong_address: undefined,
             current_address: undefined, area_name: undefined,
             pincode: undefined, currentcity: undefined,
             valueByRadio: undefined, currentActive: undefined */
    });
  }

  function onCancelDetailsModal(isReloadData) {
    setImageUrl(undefined)
    setShowModalFunc(false)
    // setDetailsModal(undefined);
    // detailsModalRef.current.handleCancel();
    getProducts();
  }

  function modalSubmit(modalsProps, sku) {
   
    modalsProps.product_detail_attributes = {
      info: modalsProps?.info ?? '',
      benefits: modalsProps?.benefits ?? '',
      opc_sequence: modalsProps?.opc_sequence ?? null,
      hsn_code: modalsProps?.hsn_code ?? '',
      ean_no: modalsProps?.ean_no ?? ''
    }

    delete modalsProps.info
    delete modalsProps.benefits
    delete modalsProps.opc_sequence
    delete modalsProps.hsn_code
    delete modalsProps.ean_no

    if (mode === 'Add Combo Product') {
      //---------------Now image validation are being done on component level only---------------
      // if (!imageUrl) {
      //   openNotificationWithIcon("error", 'Please Upload Image');
      //   modalRef.current.stopLoading();
      //   return
      // }
      //---------------///////////////////////////////////////////////////////////---------------

      modalsProps.combos_attributes = sku
      modalsProps.is_combo = true
    }
    if (imageUrl) {
      modalsProps.product_image = imageUrl
    }
    if (mode === 'Create Product') {
      //---------------Now image validation are being done on component level only---------------
      // if (!imageUrl) {
      //   openNotificationWithIcon("error", 'Please Upload Image');
      //   modalRef.current.stopLoading();
      //   return
      // }
      //---------------///////////////////////////////////////////////////////////---------------

      modalsProps.is_combo = false
    }
    productService.addProduct(modalsProps).subscribe(
      (r) => {
        openNotificationWithIcon("success", "Added Successfully");
        getProducts();
        modalRef.current.handleCancel();
      },
      (err) => {
        console.log(err);
        modalRef.current.stopLoading();
        openNotificationWithIcon("error", err?.response?.errors?.[0] ?? 'API Error');
      }
    );
  }

  function openNotificationWithIcon(type, msg) {
    notification[type]({
      message: type.toUpperCase(),
      duration: 3,
      description: msg,
    });
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
    let img1 = new Image()
    img1.src = window.URL.createObjectURL(img)
    img1.onload = () => {
      if (img1.width <= 512 && img1.height <= 512) {
        if (img1.height === img1.width) {
          setImageSizeAcceptable(true)
        } else {
          setImageSizeAcceptable(false)
          setImageErrMsg(`Uploaded image dimension ${img1.width} x ${img1.height} is not equal,
           please upload equal dimension's image.`)
        }
        // upload logic here
      } else {
        setImageSizeAcceptable(false)
        setImageErrMsg(`Uploaded image dimension ${img1.width} x ${img1.height} is too large,
         please upload standard 512 x 512 dimension image.`)
      }
    }
  }

  function handleChange(info) {
    if (info.file.status === 'uploading') {
      setImgLoading(true)
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        setImageUrl(imageUrl),
        setImgLoading(false)
      )
    }
  };

  return (
    <div className="layer">
      {productData && productList && productRequest && companyList && productBrandsList ? (
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Input
                  placeholder="Product Name"
                  onChange={(e) => onChange(e.target.value, "valueByName")}
                  value={valueByName}
                  style={{ width: 200, margin: 10 }}
                />
                <Input
                  placeholder="SKU Code"
                  onChange={(e) => onChange(e.target.value, "valueBySKU")}
                  value={valueBySKU}
                  style={{ width: 120, margin: 10, textTransform: "uppercase" }}
                />
                <Search
                  placeholder="Category Name"
                  value={valueByCategory}
                  onChange={onChange}
                  type="valueByCategory"
                  data={productCategories}
                />
                <Search
                  placeholder="Sub-Category Name"
                  value={valueBySubCategory}
                  onChange={onChange}
                  type="valueBySubCategory"
                  data={productSubCategories}
                />
                <Search
                  placeholder="Company Name"
                  value={valueByCompany}
                  onChange={onChange}
                  type="valueByCompany"
                  data={companyList}
                />
                <Search
                  placeholder="Brand Name"
                  value={valueByBrand}
                  onChange={onChange}
                  type="valueByBrand"
                  data={productBrandsList}
                />
                <Button
                  disabled={disable}
                  onClick={getProducts}
                  style={{ width: 100, margin: 10 }}
                  type="primary"
                >
                  Search
                </Button>
                <Button
                  disabled={disable}
                  onClick={reset}
                  style={{ margin: 10 }}
                  type="primary"
                  ghost
                  shape="circle"
                  icon={<ReloadOutlined />}
                />
              </Col>
            </Card>
          </Row>
          <Card>
            <Col span={24}>
              <Button
                onClick={() => showModal(true, "Add Combo Product")}
                style={{ width: 150, margin: 10 }}
                type="primary" disabled={true}
              >
                Add Combo Product
              </Button>
              <Button
                onClick={() => showModal(true, "Create Product")}
                style={{ width: 150, margin: 10 }}
                type="primary" disabled={!catalogRole}
              >
                Create Product
              </Button>
              <span className="fl-right">
                <FrzDynamicDownload name={'SKU'} fileName={'SKU'} url={'admin_dashboard/v1/downloads/skus_report'}
                  payload={'?'} type={'job'} reportFunc={'fraazo_report'} jobReportFunc={'download_reports_job_fraazo'}
                  base={'fraazo'} />
                <FrzDynamicDownload name={'Citywise Active SKU'} fileName={'SKU'} url={'admin_dashboard/v1/downloads/citywise_active_sku_report'}
                  payload={'?'} type={'job'} reportFunc={'fraazo_report'} jobReportFunc={'download_reports_job_fraazo'} base={'fraazo'} />
              </span>
            </Col>
          </Card>

          {productData.length > 0 ? (
            <Row>
              <Col span={24}>
                <MainTable dataSource={productData} columns={columns} />
                <Pagination
                  style={{ marginTop: 10, marginBottom: 10 }}
                  current={current}
                  pageSize={50}
                  total={state.total}
                  onChange={pagination}
                  showSizeChanger={false}
                />
              </Col>
            </Row>
          ) : disable || spinEnable ? (
            spinEnable ? (
              <div className="spin-center">
                <Spin tip="...Loading" />
              </div>
            ) : (
              <div className="no-data">No Data Available</div>
            )
          ) : (
            <div className="no-data">No Data Available On Selected Filters</div>
          )}
          <ProductAddFormModal
            productCategories={productCategories}
            modalSubmit={modalSubmit}
            ref={modalRef}
            onChangeModal={onChangeModal}
            onCancelModal={onCancelModal}
            product={modalState}
            subCategoryList={productSubCategories}
            productBrandsList={productBrandsList}
            productTaxSlab={productTaxSlab}
            productCessSlab={productCessSlab}
            imageUrl={imageUrl}
            imgLoading={imgLoading}
            handleChange={handleChange}
            productRequest={productRequest}
            productList={productList}
            companyList={companyList}
            imageSizeAcceptable={imageSizeAcceptable}
            imageErrMsg={imageErrMsg}
          />
          {showModalFunc ?
            <ProductDetailModal
              ref={detailsModalRef}
              // data={detailsModal}
              modalSubmit={modalSubmit}
              onChangeModal={onChangeModal}
              subCategoryList={productSubCategories}
              productCategories={productCategories}
              productBrandsList={productBrandsList}
              productTaxSlab={productTaxSlab}
              productCessSlab={productCessSlab}
              onCancelModal={onCancelDetailsModal}
              imageUrl={imageUrl}
              handleImageUrl={handleImageUrl}
              imgLoading={imgLoading}
              handleChange={handleChange}
              companyList={companyList}
              productList={productList}
              imageSizeAcceptable={imageSizeAcceptable}
              imageErrMsg={imageErrMsg}
            />
            : null}
        </div>
      ) : (
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      )}
    </div>
  );
}

export default ProductList;
