import React, { useEffect, useState } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification } from 'antd';
import {DunzoService} from '../../_services/dunzo';
import MainTable from '../../components/antd/table';
import {dunzoColumns} from '../../components/antd/columns/dunzo';
import {handleDateFormat} from '../../_modules/date';
import {getStaticTime} from '../../_modules/time';

const dunzo_service = new DunzoService()

function DunzoTickets(){
  const [dunzoData, setDunzoData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getDunzoTickets()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getDunzoTickets(){
    setSpinEnable(true)
    setDunzoData([])
    const payload = {
      page: current,
    }
    dunzo_service.get_dunzo_tickets(payload).subscribe((r) => {
      setSpinEnable(false)
      setDunzoData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    dunzoColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: "Created On",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <>
            <div>{handleDateFormat(record.created_at)}</div>
            <div>{getStaticTime(record.created_at)}</div>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <Popconfirm
            title="Are you sure you want to close this ticket?"
            onConfirm={() => closeTicket(record.id)}
            okText="Yes"
            cancelText="No"
            placement="leftTop"
          >
            <Button type="primary">
              Close Ticket
            </Button>
          </Popconfirm>
        );
      },
    })
    setColumns(columns)
  }

  function closeTicket(id){
    dunzo_service.close_ticket(id).subscribe((r) => {
      getDunzoTickets()
      openNotificationWithIcon('success', 'Ticket Closed Successfully')
    },
    (err)=>{
      console.log(err)
      openNotificationWithIcon('error', err.response.errors[0])
    })
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  return(
    <div className="layer-nofilter">
      {dunzoData ?
        <div>
          {dunzoData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={dunzoData} columns={columns}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Tickets Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default DunzoTickets
