import React, { useEffect, useState } from 'react';
import MainTable from '../antd/table';
import { Row, Col, Table} from 'antd';
import {pickupOrderColumns} from '../antd/columns/society';
import NestedOrderTable from '../customers/nested_order';

function PickupDetail(props){
  const [columns, setColumns] = useState([])

  useEffect(() => {
    getColumns()
  }, [])

  function getColumns(){
    pickupOrderColumns.map(data => {
      columns.push(data)
    })
    setColumns(columns)
  }

  function expandedRow(record){
    return <NestedOrderTable data={record.order_number}/>
  }

  return(
    <div>
      <Row>
        <Col span={24}>
          {props.pickupDetail.length > 0 ?
            <Table columns={columns} dataSource={props.pickupDetail}
                   pagination={false} scroll={{ x: 240 }} expandedRowRender={expandedRow}
                   rowKey="id"/>
          :
            <h3 style={{textAlign: 'center', paddingTop: 24}}>No Orders Found</h3>
          }
        </Col>
      </Row>
    </div>
  )
}

export default PickupDetail
