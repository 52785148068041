import { Http } from '../_modules/http'

export class ClusterService {

  http = new Http();

  getClusters(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/clusters?page=' + payload.page
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  createClusters(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/clusters'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  updateClusters(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/clusters/' + payload.id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload.body, headers)
  }

  getClustersStaticData(payload) {
    const url = process.env.REACT_APP_API_URL + '/admin_dashboard/v1/marketing/clusters/static_data'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  update_clusters_sequence(id, payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/marketing/clusters/' + id + '/update_cluster_detail_sequence'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.patch(url, payload, headers)
  }

  showClusters(id) {
    const url = process.env.REACT_APP_API_URL + '/admin_dashboard/v1/marketing/clusters/' + id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  bannerSearch(e){
    const url = process.env.REACT_APP_API_URL + '/admin_dashboard/v1/admin/banners?by_banner_name=' + e + '&page=1'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

}