import React from 'react'
import { Row, Col, Pagination, Affix } from 'antd'
import LogCard from './logcard'
const LogData = ({ logData, activeCardId, handleActiveCard, total, pagination, current }) => {
  return (
    <div>
      {logData.logs.map((data, index) => {
        return (
          <div className={`${index === 0 ? "frz-tm-15" : "frz-tm-10"}`} key={index}>
            <LogCard data={data} activeCardId={activeCardId} handleActiveCard={handleActiveCard} />
          </div>
        )
      })}
      <div>
      </div>
    </div>
  )
}

export default LogData
