import React from 'react'
import { Spin, Modal, Button, Row, Col, Tabs, notification, 
    Select, Input, InputNumber, Radio} from 'antd';
import { EditOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';

function CustomerProfile(props){
    return(
        <div>
            <div className="order-header">
                <b>#Customer Profile</b>
                {props.modalSpinner ? 
                        <Spin style={{float: 'right'}}/>
                    :
                        <>    
                            {props.modalProfile ? 
                                <Button type="primary" ghost icon={<EditOutlined />} 
                                        size="small" style={{float: 'right', paddingTop: 1}}
                                        shape="round"
                                        onClick={() => props.editOrder('profile')}/>
                            :
                                <span>
                                <Button type="primary" ghost shape="round" icon={<CheckOutlined />} 
                                        size="small" style={{float: 'right', marginLeft: 16, paddingTop: 1}}
                                        onClick={() => props.saveOrder('profile')}/>
                                {/* <Button type="primary" ghost shape="round" icon={<CloseOutlined />} 
                                        size="small" style={{float: 'right', paddingTop: 1}} danger
                                        onClick={() => props.cancelEdit('profile')}/> */}
                                </span>
                            }
                        </>
                }
            </div>
            <div className="order-detail-padding">First Name : 
                <span style={{paddingLeft:10}}>
                    <Input
                        placeholder="Enter First Name"
                        value={props.modalFirstName}
                        style={{width:190}}
                        disabled={props.modalProfile}
                        onChange={(e) => props.onChangeModal(e.target.value, 'modalFirstName')}/>
                </span>
            </div>
            <div className="order-detail-padding">Last Name : 
                <span style={{paddingLeft:10}}>
                    <Input
                        placeholder="Enter Last Name"
                        value={props.modalLastName}
                        style={{width:190}}
                        disabled={props.modalProfile}
                        onChange={(e) => props.onChangeModal(e.target.value, 'modalLastName')}/>
                </span>
            </div>
            <div className="order-detail-padding" style={{marginLeft: 31}}>Email : 
                <span style={{paddingLeft:10}}>
                    <Input
                        placeholder="Enter Email (Optional)"
                        value={props.modalEmail}
                        style={{width:190}}
                        disabled={props.modalProfile}
                        onChange={(e) => props.onChangeModal(e.target.value, 'modalEmail')}/>
                </span>
            </div>
            <div className="order-detail-padding" style={{marginLeft: 38}}>Title : 
                <span style={{paddingLeft:10}}>
                    <Radio.Group
                            value={props.modalTitle}
                            disabled={props.modalProfile}
                            onChange={(e) => props.onChangeModal(e.target.value, 'modalTitle')}>
                        <Radio value='Mr'>Mr</Radio>
                        <Radio value='Mrs'>Mrs</Radio>
                        <Radio value='Ms'>Ms</Radio>
                    </Radio.Group>
                </span>
            </div>
        </div>
    )
}

export default CustomerProfile