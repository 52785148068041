import React from 'react'
import { Spin, Modal, Button, Row, Col, Tabs, notification, 
    Select, Input, InputNumber, Radio} from 'antd';
import { EditOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import LocationSearch from './location_search';
const { Option } = Select;

function DeliverySlot(props){
    return(
        <div style={{marginTop: 50}}>
            <div className="order-header">
                <b>#Delivery Slot</b>
            </div>
            {props.slotData && props.updateCart.cart_items ?
                <div> 
                    <div className="order-detail-padding">Date : 
                        <span style={{paddingLeft:10}}>
                            <Select style={{ width: 190}} value={props.modalDate}
                                    placeholder="Select Date"
                                    onChange={(e) => props.onChangeModal(e, 'modalDate')}>
                                {props.slotData.map((data, index) => {
                                    return (
                                        (data.time_slot.length > 0 ?
                                            <Option key={index} value={data.date}>{data.date}</Option>
                                        : null)
                                    )
                                })}
                            </Select>
                        </span>
                    </div>
                    <div className="order-detail-padding" style={{marginLeft: 5}}>Slot : 
                        <span style={{paddingLeft:10}}>
                            <Select style={{ width: 190}} value={props.modalSlot}
                                    placeholder="Select Time"
                                    disabled={props.timeData.length === 0}
                                    onChange={(e) => props.onChangeModal(e, 'modalSlot')}>
                                {props.timeData.length > 0 ?
                                    (props.timeData.map(data => {
                                        return(
                                            <Option key={data.id} value={data.id}
                                                    disabled={!data.is_active || data.is_full}>
                                                {data?.slot_string ?? data.from_time + ' to ' + data.to_time + ' ' + data.am_pm}
                                            </Option>
                                        )
                                    }))
                                : null}
                            </Select>
                        </span>
                    </div>
                </div>
            : null}
        </div>
    )
}

export default DeliverySlot