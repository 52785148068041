export const monthlyColumns = [
    {
        title: 'Report Name',
        dataIndex: 'report_name',
        key: 'report_name',
        align: 'center'
    },
    {
        title: 'City',
        dataIndex: 'name',
        key: 'name',
        align: 'center'
    },
    {
        title: 'Start Date',
        dataIndex: 'start_date',
        key: 'start_date',
        align: 'center'
    },
    {
        title: 'End Date',
        dataIndex: 'end_date',
        key: 'end_date',
        align: 'center'
    },
]