import React from 'react'
export const specialDiscountColumns = [
  {
    title: 'Product Name',
    dataIndex: 'product_details',
    key: 'product_details',
    align: 'center',
    render: (e) =>
      <div>
        {e.name}
      </div>
  },
  // {
  //   title: 'Is Active',
  //   dataIndex: 'is_active',
  //   key: 'is_active',
  //   align: 'center',
  //   render: (e) =>
  //     <div>
  //       {e ? "Yes" : "No"}
  //     </div>
  // },
  {
    title: 'Valid From',
    dataIndex: 'valid_from',
    key: 'valid_from',
    align: 'center'
  },
  {
    title: 'Valid To',
    dataIndex: 'valid_to',
    key: 'valid_to',
    align: 'center'
  },
  {
    title: 'Is For First Order',
    dataIndex: 'first_order_flag',
    key: 'first_order_flag',
    align: 'center',
    render: (e) =>
      <div>
        {e ? "Yes" : "No"}
      </div>
  }
]

export const specialDiscountSeqColumns = [
  {
    title: 'Product Name',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (e) =>
      <div>
        {e.product_details.name}
      </div>
  },
  {
    title: 'Image',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (e) =>
      <div>
        <img style={{ width: 40 }} src={e.product_details.image_url} />
      </div>
  },
  {
    title: 'Sequence',
    dataIndex: 'sequence',
    key: 'sequence',
    align: 'center'
  },
]