import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { Modal, Form, Select } from 'antd';
import FRZInput from '../../_controls/FRZInput';
import FRZRadioButton from '../../_controls/FRZRadioButton';
import { formItemLayout, tailLayout } from '../../_modules/controllayout';
import FRZTimePicker from '../../_controls/FRZTimePicker';
import moment from 'moment';
const { Option } = Select;

const RolesandShiftsModal = forwardRef((props, ref) => {
  const { modalSubmit } = props

  const [form] = Form.useForm();
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (data && title == "Edit CityWise Shift") {
      form.setFieldsValue({
        ...data, shift_hrs: moment(data.shift_hrs, 'HH'),
        starting_time: moment(data.starting_time, 'HH:mm'), ending_time: moment(data.ending_time, 'HH:mm')
      })
    }
  }, [data, title])

  useImperativeHandle(ref, () => ({
    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      form.resetFields()
      setData(false)
      setVisible(false)
      setLoading(false)
    },

    stopLoading() {
      setLoading(false)
    }
  }));

  function handleCancelModal() {
    form.resetFields()
    setData(false)
    setVisible(false)
    setLoading(false)
  }

  const handleOnSubmit = (values) => {
    setLoading(true)
    modalSubmit(values, data?.id)
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">
            <Form.Item
              {...tailLayout}
              name="roles"
              label="Roles"
              rules={[
                {
                  required: true,
                  message: `Please input the value of roles!`,
                },
              ]}
            >
              <Select disabled={title !== "Create CityWise Shift"} placeholder="Roles" style={{ width: 200 }}>
                {props.roles.map((item, index) => {
                  return (
                    <Option key={index} value={item}>{item}</Option>
                  )
                })}
              </Select>
            </Form.Item>
            {/* <FRZInput name="roles" isRequired={true}
              label="Roles" placeholder="Roles" width={200} /> */}

            <FRZTimePicker name="starting_time" isRequired={true}
              label="Start time" placeholder="Start time" width={200}  />

            <FRZTimePicker name="ending_time" isRequired={true}
              label="End time" placeholder="End time" width={200}  />

            <FRZRadioButton label="Is Active" isRequired={true} name="is_active" />

          </Form>
        </Modal>
        : null}
    </div>

  )
})

export default RolesandShiftsModal
