import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification } from 'antd';
import {BannersService} from '../../_services/banners';
import {ProductService} from '../../_services/product';
import MainTable from '../../components/antd/table';
import BannerModal from '../../components/master/banners/modal';
import BannerActivateModal from '../../components/master/banners/activatemodal';
import {bannerColumns} from '../../components/antd/columns/master/banners';
import moment from 'moment';
import { useSelector } from 'react-redux';

import FRZDatepicker from '../../_controls/FRZDatepicker';
import FRZInput from '../../_controls/FRZInput';
import { ReloadOutlined } from '@ant-design/icons';
import Search from '../../components/antd/search';
const banner_service = new BannersService()
const product_service = new ProductService()
 
function BannerList(){
  const modalRef = useRef(null);
  const activateModalRef = useRef(null);
  const [bannerData, setBannerData] = useState(false)
  const [productData, setProductData] = useState(false)
  const [productSubCatData, setProductSubCatData] = useState(false)
  const [pagesData, setPagesData] = useState(false)
  const [catData, setCatData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [mode, setMode] = useState(undefined)
  const [state, setState] = useState({});
  const [webimageUrl, setWebImageUrl] = useState(undefined)
  const [segmentData, setSegmentData] = useState(false)
  const [segmentOtherData, setSegmentOtherData] = useState(false)
  const [webimgLoading, setWebImgLoading] = useState(false)
  const [mobimageUrl, setMobImageUrl] = useState(undefined)
  const [newMobImageUrl, setNewMobImageUrl] = useState(undefined)
  const [mobimgLoading, setMobImgLoading] = useState(false)
  const [actionimageUrl, setActionImageUrl] = useState(undefined)
  const [actionimgLoading, setActionImgLoading] = useState(false)
  const [bannerTypeData, setBannerTypeData] = useState(false)
  const [modalState, setModalState] = useState({banner_name: undefined, web_url: undefined,
                                      mobile_url: undefined, description: undefined, valueByRadioDS: undefined,
                                      valueByRadio: undefined, currentActive: undefined, type_of_data: undefined,
                                      currentProductCat: undefined, sequence: undefined,
                                      currentProductSubCat: undefined, currentProductAction: undefined,
                                      new_banner_image_url: undefined, valueByDarkstore: [],
                                      currentProduct: undefined, valueByProduct: undefined,
                                      action_url: undefined, valid_from_date: '', valid_from: undefined, 
                                      valid_to_date: '', valid_to: undefined, valueByActivation: [],
                                      is_first_order: undefined, valueBySegment: [], oldSegment: [], valueBySegmentOther: [],
                                    oldSegmentOther: []});
  const { banner_name, web_url, mobile_url, description, new_banner_image_url, valueByRadioDS, type_of_data,
          valueByRadio, currentActive, currentProduct, action_url, valueByDarkstore, currentProductAction,
          valueByProduct, currentProductCat, sequence, valid_from_date, currentProductSubCat,
          valid_from, valid_to_date, valid_to, valueByActivation, is_first_order, valueBySegment, oldSegment, valueBySegmentOther, oldSegmentOther} = modalState
  const [disableFilter,setDisableFilter]=useState(true)
  const [payload,setPayload]=useState({inputValue:undefined,dateOfTill:undefined,showDate:undefined,valueBySeg:undefined})
  let {inputValue,dateOfTill,showDate,valueBySeg}=payload;

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getBanners()
      getBannerType()
    }
  })

  useEffect(() => {
    getColumns()
    getProductList()
    getProductSubcat()
    getProductCat()
    getPages()
    getSegment()
  }, [])

  function getBanners(dateOfTill,inputValue,valueSeg){
    setSpinEnable(true)
    setBannerData([])
    const payload = {
      page: current,
      date:dateOfTill?dateOfTill:'',
      banner_name:inputValue?inputValue:'',
      by_segment: valueSeg?valueSeg:''
    }
    banner_service.get_banners(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setSpinEnable(false)
      setBannerData(r.response.data)
    },
    (err)=>{
      console.log(err)
      setSpinEnable(false)
    })
  }

  function getSegment(){
    banner_service.get_segment().subscribe((r) => {
      let city = r?.response?.segments.filter(item => item.category === 'city')
      let other = r?.response?.segments.filter(item => item.category === 'other')
      setSegmentData(city)
      setSegmentOtherData(other)
    },
    (err)=>{
      // setSegmentData(segments)
      console.log(err)
    })
  }

  function getPages(){
    banner_service.get_pages().subscribe((r) => {
      setPagesData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getBannerType(){
      banner_service.get_banner_type().subscribe((r) => {
        setBannerTypeData(r.response.data)
      },
      (err)=>{
        console.log(err)
       })
     }
    

  function getColumns(){
    bannerColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: "Web Banner",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <img src={record.web_url} height={80} />
        );
      },
    },
    {
      title: "Old App Mobile Banner",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <img src={record.mobile_url} height={80} />
        );
      },
    },
    {
      title: "New App Mobile Banner",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <img src={record.new_banner_image_url} height={80} />
        );
      },
    },
    {
      title: "Action Img",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <img src={record.action_url} height={80} />
        );
      },
    },
    // {
    //   title: 'Actions',
    //   dataIndex: '',
    //   key: '',
    //   align: 'center',
    //   render: (record) => {
    //     return (
    //       <Button type="primary" onClick={() => showModalActivate(record, 'Activate/Deactivate Banners')}>Activate/Deactivate</Button>
    //     )
    //   }
    // },
    {
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (
          <Button type="primary" onClick={() => showModal(record, 'Edit Banners')}>Edit</Button>
        )
      }
    });
    setColumns(columns)
  }

  function getProductList(){
    banner_service.get_product_list().subscribe((r) => {
      const obj =[];
      r.response.data.forEach(function (elem) {
        obj.push({
          id: elem.id,
          name: elem.name + ' - ' + elem.sku
        });
      });
      setProductData(obj)
     // setProductData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getProductSubcat(){
    product_service.getProductSubCategories().subscribe((r) => {
      setProductSubCatData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getProductCat(){
    product_service.getProductCategories().subscribe((r) => {
      setCatData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function pagination(page) {
    setCurrent(page)
    setBannerData([])
    setRenderData(false)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  function showModalActivate(data, type) {
    activateModalRef.current.showModal(type, data)
  }

  function showModal(data, type) {
    setMode(type)
    if(type === 'Edit Banners'){
      var newSegment = [], newSegmentOther = []
      data?.segments_attributes_prefix_city?.length > 0 && data.segments_attributes_prefix_city.map(data =>{
        newSegment.push(data.slug)
      })
      data?.segments_attributes_prefix_other?.length > 0 && data.segments_attributes_prefix_other.map(data =>{
        newSegmentOther.push(data.slug)
      })
      if(data.action_reference_id){
        setModalState({banner_name: data.banner_name, web_url: data.web_url, 
                       mobile_url: data.mobile_url, description: data.description,
                       new_banner_image_url: data.new_banner_image_url,
                       valueByRadio: data.is_active, currentActive: data.id, sequence: data.sequence,
                       valid_from_date: data.valid_from ? moment(data.valid_from) : undefined,
                       valid_from: data.valid_from,
                       valid_to_date: data.valid_to ? moment(data.valid_to) : undefined,
                       valid_to: data.valid_to,
                       valueByRadioDS: data.all_ds,
                       is_first_order: data?.first_order_flag,
                       valueByDarkstore: data.active_darkstores,
                       valueByActivation: data.activation_day, type_of_data: data.type_of_data,
                       currentProductSubCat: data.action_type === 'product_category' ? data.action_reference_id : undefined,
                       currentProductCat: data.action_type === 'category' ? data.action_reference_id : undefined,
                       currentProduct: data.action_type === 'product' ? data.action_reference_id : undefined, 
                       currentProductAction: data.action_type === 'promotional_page' ? data.action_reference_id : undefined,
                       valueByProduct: data.action_type, valueBySegment: newSegment,
                       oldSegment: data.segments_attributes_prefix_city, valueBySegmentOther: newSegmentOther, oldSegmentOther: data.segments_attributes_prefix_other})
      }else{
        setModalState({banner_name: data.banner_name, web_url: data.web_url,
                       mobile_url: data.mobile_url, description: data.description,
                       new_banner_image_url: data.new_banner_image_url,
                       valid_from_date: data.valid_from ? moment(data.valid_from) : undefined,
                       valid_from: data.valid_from,
                       valueByRadioDS: data.all_ds,
                       valueByDarkstore: data.active_darkstores,
                       valid_to_date: data.valid_to ? moment(data.valid_to) : undefined,
                       valid_to: data.valid_to,
                       valueByActivation: data.activation_day,
                       is_first_order: data?.first_order_flag, type_of_data: data.type_of_data,
                       valueByRadio: data.is_active, currentActive: data.id, sequence: data.sequence,
                       currentProduct: undefined, valueByProduct: data.action_type === 'offer' ? 'offer' : 'noproduct', 
                       currentProductCat: undefined, currentProductAction: undefined,
                       currentProductSubCat: undefined,
                       valueBySegment: newSegment,
                       oldSegment: data.segments_attributes_prefix_city, valueBySegmentOther: newSegmentOther, oldSegmentOther: data.segments_attributes_prefix_other})
      }
    }
    modalRef.current.showModal(type, data)
  }

  function onChangeModal(value, type) {
    if(type === 'valueByRadio' ||  type === 'valueByRadioDS' || type === 'is_first_order'){
      setModalState(prevState =>({...prevState, [type]: value}))
    }else{
      setModalState(prevState =>({...prevState, [type]: value === "" ? undefined : value}))
    }
  }

  function onCancelModal() {
    setModalState({banner_name: undefined, web_url: undefined,
                   mobile_url: undefined, description: undefined,
                   new_banner_image_url: undefined, valueByRadioDS: undefined,
                   valueByRadio: undefined, currentActive: undefined,
                   is_first_order: undefined, currentProductSubCat: undefined, type_of_data:undefined,
                   currentProductCat: undefined, sequence: undefined, currentProductAction: undefined,
                   currentProduct: undefined, valueByProduct: undefined,
                   valid_from_date: '', valid_from: undefined, valueByDarkstore: [],
                   valid_to_date: '', valid_to: undefined, valueByActivation: [],
                   valueBySegment:[], oldSegment: [], valueBySegmentOther: [], oldSegmentOther: []})
    setWebImageUrl(undefined)
    setMobImageUrl(undefined)
    setActionImageUrl(undefined)
    setNewMobImageUrl(undefined)
  }

  function getSegmentForEdit(segmentType, oldSeg, valueSeg) {
    var newSegment = []
    if (segmentType === 'all_users') {
      if (oldSeg?.length > 0) {
        oldSeg.map(data => {
          newSegment.push({ id: data.id, _destroy: true })
        })
      }
    } else {
      if (oldSeg?.length > 0) {
        for (var i = 0; i < oldSeg.length; i++) {
          var newList = valueSeg.find(x => x === oldSeg[i].slug)
          if (!newList) {
            newSegment.push({ id: oldSeg[i].id, _destroy: true })
          }
        }
      }
      valueSeg?.length > 0 && valueSeg.map(data => {
        if (!oldSeg?.find(x => x?.slug === data)) {
          newSegment.push({ slug: data })
        }
      })
    }
    return newSegment;
  }

  function getSegmentForCreate(valueSeg) {
    var newSegment = []
    valueSeg?.length > 0 && valueSeg.map(data => {
      newSegment.push({slug: data})
    })
    return newSegment
  }

  function modalSubmit(segmentType) {
    if(valueByProduct === 'product'){
      if(currentProduct === undefined){
        openNotificationWithIcon('error', "Please Select Product")
        modalRef.current.stopLoading()
        return
      }
    }
    if(valueByProduct === 'category'){
      if(currentProductCat === undefined){
        openNotificationWithIcon('error', "Please Select Category")
        modalRef.current.stopLoading()
        return
      }
    }
    if(valueByProduct === 'product_category'){
      if(currentProductSubCat === undefined){
        openNotificationWithIcon('error', "Please Select Sub Category")
        modalRef.current.stopLoading()
        return
      }
    }
    if(valueByProduct === 'promotional_page'){
      if(currentProductAction === undefined){
        openNotificationWithIcon('error', "Please Select Pages")
        modalRef.current.stopLoading()
        return
      }
    }
    if(mode === 'Add Banner'){
      if(valueByProduct === 'offer' && !actionimageUrl){
        if(currentProductCat === undefined){
          openNotificationWithIcon('error', "Please upload action image")
          modalRef.current.stopLoading()
          return
        }
      }
    }
    // if(valueByRadioDS === undefined || (!valueByRadioDS && valueByDarkstore.length === 0)){
    //   openNotificationWithIcon('error', "Please set darkstore")
    //   modalRef.current.stopLoading()
    //   return
    // }
    if(!banner_name || !description || !type_of_data ||
       valueByRadio === undefined || valueByProduct === undefined){
         openNotificationWithIcon('error', "Please fill all fields")
         modalRef.current.stopLoading()
         return
    }
    if(segmentType === 'select_segment' && !valueBySegment?.length && !valueBySegmentOther?.length){
      openNotificationWithIcon('error', "Please select a segment")
      modalRef.current.stopLoading()
    }else{
      if(mode === 'Add Banner'){
        if(!webimageUrl || !mobimageUrl || !newMobImageUrl){
          openNotificationWithIcon('error', "Please upload banners")
          modalRef.current.stopLoading()
          return
        }
      }
      if(!valid_from || !valid_to){
        openNotificationWithIcon('error', "Please enter validity dates")
        modalRef.current.stopLoading()
        return
      }
        // if(valueBySegment.length === 0){
        //   openNotificationWithIcon('error', "Please Select Segment")
        //   modalRef.current.stopLoading()
        //   return
        // }
      const payload = {
        id: mode === 'Edit Banners' ? currentActive : undefined,
        body: {
          banner_name: banner_name,
          action_reference_id: valueByProduct === 'product' ? currentProduct : 
                               valueByProduct === 'category' ? currentProductCat : 
                               valueByProduct === 'product_category' ? currentProductSubCat : 
                               valueByProduct === 'promotional_page' ? currentProductAction : null,
          action_category: valueByProduct,
          action_type: valueByProduct,
          description: description,
          is_active: valueByRadio,
          // first_order_flag: is_first_order,
          sequence: !sequence ? 0 : sequence,
          valid_from: valid_from,
          valid_to: valid_to,
          type_of_data: type_of_data,
          activation_day: valueByActivation,
          // active_darkstores: valueByRadioDS ? [] : valueByDarkstore,
          // by_city: localStorage.getItem('city') ?? ''
        }
      }
      if(webimageUrl){
        payload.body.web_image = webimageUrl
      }
      if(mobimageUrl){
        payload.body.mobile_image = mobimageUrl
      }
      if(actionimageUrl){
        payload.body.action_image = actionimageUrl
      }
      if(newMobImageUrl){
        payload.body.new_banner_image = newMobImageUrl
      }
      if(mode === 'Edit Banners'){ 
        payload.body.segments_attributes_prefix_city = getSegmentForEdit(segmentType, oldSegment, valueBySegment)
        payload.body.segments_attributes_prefix_other = getSegmentForEdit(segmentType, oldSegmentOther, valueBySegmentOther)
        banner_service.update_banner(payload).subscribe((r) => {
          openNotificationWithIcon('success','Edited Successfully')
          getBanners()
          modalRef.current.handleCancel()
        },
        (err)=>{
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err?.response?.errors[0])
        })
      }else{
        payload.body.segments_attributes_prefix_city = getSegmentForCreate(valueBySegment)
        payload.body.segments_attributes_prefix_other = getSegmentForCreate(valueBySegmentOther)
        banner_service.create_banner(payload).subscribe((r) => {
          openNotificationWithIcon('success','Created Successfully')
          getBanners()
          modalRef.current.handleCancel()
        },
        (err)=>{
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err?.response?.errors[0])
        })
      }
    }
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function handleChangeWeb(info){ 
    if (info.file.status === 'uploading') {
      setWebImgLoading(true)
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, webimageUrl =>
        setWebImageUrl(webimageUrl),
        setWebImgLoading(false),
      )
    }
  };

  function handleChangeMob(info){ 
    if (info.file.status === 'uploading') {
      setMobImgLoading(true)
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, mobimageUrl =>
        setMobImageUrl(mobimageUrl),
        setMobImgLoading(false)
      )
    }
  };

  function handleChangeNewMob(info){ 
    if (info.file.status === 'uploading') {
      setMobImgLoading(true)
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, newMobImageUrl =>
        setNewMobImageUrl(newMobImageUrl),
        setMobImgLoading(false)
      )
    }
  };

  function handleChangeAction(info){ 
    if (info.file.status === 'uploading') {
      setActionImgLoading(true)
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, actionimageUrl =>
        setActionImageUrl(actionimageUrl),
        setActionImgLoading(false)
      )
    }
  };

  function onChangeDate(date, dateString, type, valueDate) {
    setModalState(prevState =>({...prevState, [type]: date, [valueDate]: dateString}))
  }
  const handleSearch=(e)=>{
    setDisableFilter(false)
    let value=e?.target?.value
    setPayload((prev)=>({...prev, inputValue:value}))
  }
  function onChangeDatePicker(value, dateString) {
    setDisableFilter(false)
    setPayload((prev)=>({...prev,showDate:true,dateOfTill:dateString.replaceAll('/','-')}))
  }
  function onChange(e) {
    setDisableFilter(false)
    setPayload((prev)=>({...prev, valueBySeg:e}))
  }
  const resetFields=()=>{
    setDisableFilter(true)
    setPayload((prev)=>({...prev,showDate:false,inputValue:undefined,dateOfTill:undefined,valueBySeg:undefined}))
    getBanners(null,null, null)
  }
  return(
    <div className="layer">
      {bannerData && pagesData && segmentData && segmentOtherData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <DatePicker style={{ width: '150px' }} value={showDate ? moment(dateOfTill, 'YYYY-MM-DD') : undefined} allowClear={false} placeholder="Valid Date" onChange={onChangeDatePicker}
                  className="frz-lm-5" />
                <Input value={inputValue} placeholder="Search Banners" style={{ width: '150px', marginLeft: '10px' }} onChange={handleSearch} />
                <Search className="frz-filter" placeholder="Segment" value={valueBySeg}
                  onChange={(e) => onChange(e)} type="valueBySeg" data={[...segmentData, ...segmentOtherData]} />
                <Button disabled={disableFilter} type='primary' onClick={() => getBanners(dateOfTill, inputValue, valueBySeg)}>Search</Button>
                <Button icon={<ReloadOutlined />} onClick={resetFields} disabled={disableFilter} type="primary" ghost shape="circle" className="frz-lm-5" />
                <span className="right">
                  <Button onClick={() => showModal(true, 'Add Banner')} style={{ width: 120, margin: 10 }}
                    type="primary">Add Banners</Button>
                </span>
              </Col>
            </Card>
          </Row>
          {bannerData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={bannerData} columns={columns}/>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <BannerModal modalSubmit={modalSubmit} ref={modalRef}
                       onChangeModal={onChangeModal}
                       onCancelModal={onCancelModal}
                       catData={catData}
                       segmentData={segmentData}
                       segmentOtherData={segmentOtherData}
                       valueBySegment={valueBySegment}
                       valueBySegmentOther={valueBySegmentOther}
                       banner_name={banner_name} valueByProduct={valueByProduct}
                       web_url={web_url} description={description}
                       productData={productData} currentProduct={currentProduct}
                       mobile_url={mobile_url} valueByRadio={valueByRadio}
                       is_first_order={is_first_order}
                       handleChangeMob={handleChangeMob} handleChangeWeb={handleChangeWeb}
                       webimageUrl={webimageUrl} mobimageUrl={mobimageUrl} webimgLoading={webimgLoading}
                       mobimgLoading={mobimgLoading} sequence={sequence} newMobImageUrl={newMobImageUrl}
                       currentProductCat={currentProductCat} valueByRadioDS={valueByRadioDS}
                       productSubCatData={productSubCatData} currentProductSubCat={currentProductSubCat}
                       actionimageUrl={actionimageUrl} valueByDarkstore={valueByDarkstore}
                       actionimgLoading={actionimgLoading} currentProductAction={currentProductAction}
                       handleChangeAction={handleChangeAction}
                       onChangeDate={onChangeDate} bannerTypeData={bannerTypeData}
                       handleChangeNewMob={handleChangeNewMob}
                       valueByActivation={valueByActivation}
                       valid_from_date={valid_from_date} type_of_data={type_of_data}
                       valid_to_date={valid_to_date} pagesData={pagesData}/>
          <BannerActivateModal ref={activateModalRef}/>
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default BannerList
