import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button, Select, Input, InputNumber,
         Radio} from 'antd';
const { Option } = Select;

const AppModal = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOk() {
    setLoading(true)
    props.modalSubmit()
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.onCancelModal();
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onOk={handleOk}
          onCancel={handleCancelModal}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              Submit
            </Button>,
          ]}
        >
            <div className="block" style={{marginLeft:48}}>Android Forced Version :
              <span style={{paddingLeft:10}}>
                <InputNumber placeholder="Android Forced Version"
                  value={props.android_forced_version} style={{width:200}}
                  onChange={(e) => props.onChangeModal(e, 'android_forced_version')}/>
              </span>
            </div>
            <div className="block">Android Recommended Version :
              <span style={{paddingLeft:10}}>
                <InputNumber placeholder="Android Recommended Version"
                  value={props.android_recommended_version} style={{width:200}}
                  onChange={(e) => props.onChangeModal(e, 'android_recommended_version')}/>
              </span>
            </div>
            <div className="block" style={{marginLeft:80}}>Ios Forced Version :
              <span style={{paddingLeft:10}}>
                <InputNumber placeholder="Ios Forced Version"
                  value={props.ios_forced_version} style={{width:200}}
                  onChange={(e) => props.onChangeModal(e, 'ios_forced_version')}/>
              </span>
            </div>
            <div className="block" style={{marginLeft:30}}>Ios Recommended Version :
              <span style={{paddingLeft:10}}>
                <InputNumber placeholder="Ios Recommended Version"
                  value={props.ios_recommended_version} style={{width:200}}
                  onChange={(e) => props.onChangeModal(e, 'ios_recommended_version')}/>
              </span>
            </div>
            <div className="block" style={{marginLeft:136}}>Features :
            <span style={{paddingLeft:10}}>
              <Input placeholder="Features"
                value={props.features} style={{width:200}}
                onChange={(e) => props.onChangeModal(e.target.value, 'features')}/>
            </span>
          </div>
        </Modal>
      : null }
    </div>
  )
})

export default AppModal
