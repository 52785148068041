import React, { useEffect, useState, useRef } from 'react';
import {
  Spin, Button, Card, Row, Col, Input, InputNumber,
  Pagination, DatePicker
} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { DeliveryApp } from '../../_services/delivery_app';
import Search from '../../components/antd/search';
import MainTable from '../../components/antd/table';
import BillingModal from '../../components/deliveryapp/billing_modal';
import { billingColumns } from '../../components/antd/columns/deliveryapp';
import { openNotificationWithIcon } from '../../_modules/notification';
import moment from 'moment';

const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const billing_service = new DeliveryApp()

function Billing() {
  const modalRef = useRef(null);
  const [billData, setBillData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [partnerData, setPartnerData] = useState(undefined)
  const [status] = useState([{ name: 'BILLED' }, { name: 'NOT_BILLED' }, { name: 'APPROVED' }, { name: 'PAID' }, { name: 'REJECTED' }])
  const [state, setState] = useState({ valueByRider: undefined, valueByPartner: undefined, valueByStatus: undefined, 
                                       from_date: '', to_date: '', valueByStore: undefined, showDateValue: false});
  const { valueByRider, valueByPartner, valueByStatus, valueByStore, from_date, to_date, showDateValue } = state

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getBills()
    }
  })

  useEffect(() => {
    getColumns()
    getPartners()
  }, [])

  function getPartners() {
    billing_service.delivery_partner().subscribe((r) => {
      setPartnerData(r.response.data)
    },
    (err) => {
      console.log(err)
    })
  }

  function getBills() {
    setSpinEnable(true)
    setBillData([])
    const payload = {
      page: current,
      rider_id: valueByRider ?? '',
      partner_id: valueByPartner ?? '',
      status: valueByStatus ?? '',
      store_code: valueByStore ?? '',
      from_date: from_date,
      to_date: to_date,
    }

    billing_service.get_bills(payload).subscribe((r) => {
      setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
      setSpinEnable(false)
      setBillData(r?.response?.data ?? [])
    },
    (err) => {
      console.log(err)
    })
  }

  function getColumns() {
    billingColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (
          <Button type="primary" onClick={() => showModal(record, 'Edit')} 
                  disabled={record.status === 'PAID' || record.status === 'REJECTED'}>
            Edit
          </Button>
        )
      }
    })
    setColumns(columns)
  }

  function showModal(data, type) {
    modalRef.current.showModal(type, data)
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }

  function reset() {
    setState({valueByRider: undefined, valueByPartner: undefined, valueByStatus: undefined, 
             from_date: '', to_date: '', valueByStore: undefined, showDateValue: false})
    setDisable(true)
    setCurrent(1)
    setBillData([])
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setBillData(false)
    setRenderData(false)
  }

  function onChangeRangePicker(value, dateString){
    setState(prevState =>({...prevState, showDateValue: true, from_date :dateString[0], to_date:dateString[1]}))
    setDisable(false)
    setCurrent(1)
  }

  function disabledDate(current){
    return current && (current < moment().subtract(91, "day") || current > moment().add(0, "day"));
  }

  function modalSubmit(payloadData, id) {
    const payload = { ...payloadData, bill_id: id, created_by: JSON.parse(localStorage.getItem('auth')).uid }
    billing_service.update_bills(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Udpated Successfully')
      getBills()
      modalRef.current.handleCancel()
    },
    (err) => {
      console.log(err)
      modalRef.current.stopLoading()
      openNotificationWithIcon('error', err.response.message)
    })
  }

  return (
    <div className="layer">
      {billData && partnerData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <RangePicker onChange={onChangeRangePicker} className="frz-m-10"
                             value={showDateValue ? [moment(from_date, dateFormat),
                             moment(to_date, dateFormat)] : '' } format={dateFormat}
                             allowClear={false} disabledDate={disabledDate}/>
                <Search placeholder="Status" value={valueByStatus}
                  onChange={(e) => onChange(e, 'valueByStatus')} data={status} />
                <Search placeholder="Partner" value={valueByPartner}
                  onChange={(e) => onChange(e, 'valueByPartner')} type="valueByCategory" data={partnerData} />
                <Search placeholder="Store" value={valueByStore} width="medium"
                  onChange={(e) => onChange(e, 'valueByStore')} type="valueByDSCode" />
                <Button disabled={disable} onClick={getBills}
                  className="frz-w-100px frz-m-10" type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          {billData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={billData} columns={columns} />
                <Pagination className="frz-tm-10 frz-bm-10" current={current}
                  pageSize={50} total={state.total} onChange={pagination}
                  showSizeChanger={false} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <BillingModal modalSubmit={modalSubmit} ref={modalRef} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  );
}

export default Billing