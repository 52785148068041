import React, { useEffect, useState } from 'react';
import MainTable from '../antd/table';
import { Row, Col, Table} from 'antd';
import {customerReferralColumns} from '../antd/columns/customers';

function CustomerReferrals(props){
  const [columns, setColumns] = useState([])

  useEffect(() => {
    getColumns()
  }, [])

  function getColumns(){
    let temCol = []
    customerReferralColumns.map(data => {
      temCol.push(data)
    })
    setColumns(temCol)
  }

  return(
    <div>
      <Row>
        <Col span={24}>
          {props.customerDetail.length > 0 ?
            <Table columns={columns} dataSource={props.customerDetail}
                   pagination={false} scroll={{ x: 240 }}
                   rowKey="id"/>
          :
            <h3 style={{textAlign: 'center', paddingTop: 24}}>No Referrals Found</h3>
          }
        </Col>
      </Row>
    </div>
  )
}

export default CustomerReferrals
