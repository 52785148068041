import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { Modal, Form, Select, Row, Col, Button, InputNumber, DatePicker, Radio } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { formItemLayout, tailLayout } from '../../_modules/controllayout';
import { useSelector } from "react-redux";
import moment from 'moment';
import FRZInput from '../../_controls/FRZInput';
import FRZRadioButton from '../../_controls/FRZRadioButton';
import {openNotificationWithIcon} from '../../_modules/notification';

const { Option } = Select;

const initialModal = {
  data: false,
  title: false,
  loading: false,
  visible: false
}

const CreateSegments = forwardRef((props, ref) => {
  const darkstore = useSelector(state => state.auth.darkstore);
  const city = useSelector(state => state.auth.city);
  const [form] = Form.useForm();
  const [modalState, setModalState] = useState(initialModal)
  const [isSelected, setIsSelected] = useState([])

  useEffect(() => {
    if (modalState.data && modalState.title === 'Edit Segments') {
      // if(modalState.data.conditions.find(x => x.variable.includes('date'))){
      //   modalState.data.conditions.map(data => {
      //     data.variable.includes('date') && (data.value = moment(data.value))
      //   }) 
      // }
      // if(modalState.data.conditions.find(x => x.variable === 'city_id')){
      //   modalState.data.conditions.find(x => x.variable === 'city_id').value = modalState.data.conditions.find(x => x.variable === 'city_id').value?.split(',')
      // }
      // if(modalState.data.conditions.find(x => x.variable === 'dark_store_id')){
      //   modalState.data.conditions.find(x => x.variable === 'dark_store_id').value = modalState.data.conditions.find(x => x.variable === 'dark_store_id').value?.split(',')
      // }

      //Not A Correct approach to set data based on substring 
      // Backend shld store radiobutton type to solve this if edit form opens up
      for(var i=0; i<modalState.data.conditions.length; i++){
        if(modalState.data.conditions[i].variable?.includes('date')){
          modalState.data.conditions[i].Radiovalue = modalState.data.conditions[i].type
          if(modalState.data.conditions[i].type === 'Date'){
            modalState.data.conditions[i].value = moment(modalState.data.conditions[i].value)
          }
        }
      }
      form.setFieldsValue({ ...modalState.data })
      handleChange()
    }
  }, [modalState.data])

  useEffect(() => {
    return () => {
      form.resetFields()
      setIsSelected([])
      setModalState(initialModal)
    };
  },[]);

  useImperativeHandle(ref, () => ({
    handleCancel: handleCancelModal,
    showModal(data, title) {
      setModalState(prevState => ({ ...prevState, data: data, title: title, visible: true }))
    },
    stopLoading() {
      setModalState(prevState => ({ ...prevState, loading: false }))
    }
  }));

  function handleCancelModal() {
    form.resetFields()
    setIsSelected([])
    setModalState(initialModal)
  }

  const handleChange = () => {
    setIsSelected(form.getFieldValue("conditions"))
  };

  const handleOnSubmit = (values) => {
    if(isSelected.length === 0){
      openNotificationWithIcon('error', 'Please add a metric')
      return
    }
    setModalState(prevState => ({ ...prevState, loading: true }))
    if(values.conditions.find(x => moment.isMoment(x.value))){
      values.conditions.map(data => {
        moment.isMoment(data.value) && (data.value = moment(data.value).format("YYYY-MM-DD"))
      }) 
    }

    if(values.conditions.find(x => x.variable === 'city_id')){
      values.conditions.find(x => x.variable === 'city_id').value = values.conditions.find(x => x.variable === 'city_id').value.toString()
    }
    if(values.conditions.find(x => x.variable === 'dark_store_id')){
      values.conditions.find(x => x.variable === 'dark_store_id').value = values.conditions.find(x => x.variable === 'dark_store_id').value.toString()
    }

    values.conditions.map((v) => { delete v.Radiovalue });
    if(modalState.title === 'Create Segments'){
      createSegments(values)
    }else{
      updateSegments(values)
    }
  }

  const createSegments = (values) => {
    props.segment_service.create_segments(values).subscribe((r) => {
      props.getTableList()
      openNotificationWithIcon('success','Created Successfully')
      handleCancelModal()
    }, (err) => {
      openNotificationWithIcon('error', err?.response?.errors?.[0] ?? 'API Error')
      setModalState(prevState => ({ ...prevState, loading: false }))
    })
  }

  const updateSegments = (values) => {
    props.segment_service.update_segments(values, modalState.data.id).subscribe((r) => {
      props.getTableList()
      openNotificationWithIcon('success','Updated Successfully')
      handleCancelModal()
    }, (err) => {
      openNotificationWithIcon('error', err?.response?.errors?.[0] ?? 'API Error')
      setModalState(prevState => ({ ...prevState, loading: false }))
    })
  }

  const SelectHandler = (data) => {
    return <Form.Item
              className='frz-hrz-center'
              {...data.restField}
              name={[data.name, 'value']}
              rules={[{ required: true, message: 'Missing Value' }]}
           >
            <Select className="frz-w-300" mode={data.typeKey ? 'multiple' : false}
              placeholder="Select Value" showSearch optionFilterProp="children"
              disabled={modalState.title === 'Edit Segments'}
            >
              {data.data?.map((item, index) => (
                <Option key={index} value={data.typeValue ? item[data.typeValue] : item}>
                  {data.typeKey ? item[data.typeKey] : typeof(item) === 'boolean' ? item?.toString() : item}
                </Option>
              ))}
            </Select>
           </Form.Item>
  }

  const IntegerHandler = (data) => {
    return <Form.Item
            className='frz-hrz-center'
            {...data.restField}
            name={[data.name, 'value']}
            rules={[{ required: true, message: 'Missing Value' }]}
           >
            <InputNumber placeholder='Enter Value' className='frz-width-220p'
                         disabled={modalState.title === 'Edit Segments'}/>
           </Form.Item>
  }

  const DatepickerHandler = (data) => {
    return <Form.Item
              className='frz-hrz-center'
              {...data.restField}
              name={[data.name, 'value']}
              rules={[{ required: true, message: 'Missing Value', type: 'object' }]}
           >
            <DatePicker placeholder='Select Date' className='frz-width-220p' 
              disabled={modalState.title === 'Edit Segments'}/>
           </Form.Item>
  }

  const RadioHandler = (data) => {
    return <Form.Item
              className='frz-hrz-center'
              {...data.restField}
              name={[data.name, 'Radiovalue']}
              rules={[{ required: true, message: 'Missing Value' }]}
           >
            <Radio.Group onChange={() => handleRadioChange(data)} disabled={modalState.title === 'Edit Segments'}>
                <Radio value='Integer'>Integer</Radio>
                <Radio value='Date'>Datepicker</Radio>
            </Radio.Group>
           </Form.Item>
  }

  function handleRadioChange(data){
    form.setFieldsValue(form.getFieldsValue().conditions[data.name].value = undefined)
    handleChange()
  }

  return (
    <div>
      {modalState.data ?
        <Modal
          visible={modalState.visible}
          title={modalState.title}
          width={'70%'}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            modalState.title !== 'Edit Segments' ? 
              <Button key="submit" type="primary" loading={modalState.loading} onClick={() => {
                form
                  .validateFields()
                  .then(values => {
                    handleOnSubmit(values)
                  })
                  .catch(info => {
                    console.log('Validate Failed:', info);
                  });
              }}>
                Submit
              </Button> 
            : null,
          ]}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

              <FRZInput name="name" isRequired={true} label="Segment Name" placeholder="Segment Name" width={200} 
                        isDisabled={modalState.title === 'Edit Segments'}/>

              <FRZRadioButton name="is_active" label="Is Active" isRequired={true} disabled={modalState.title === 'Edit Segments'}/>

              <Row className="frz-bm-20 text-center frz-segment-border">
                <Col span={8}><b>Metric</b></Col>
                <Col span={4}><b>Operator</b></Col>
                <Col span={10}><b>Value</b></Col>
              </Row>
              <Form.List name="conditions">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <div key={name}>
                        <div className='frz-segment-and'>{name !== 0 ? 'AND' : ''}</div>
                        <Row key={name}>
                          <Col span={8}>
                            <Form.Item
                              className='frz-hrz-center'
                              {...restField}
                              name={[name, 'variable']}
                              rules={[{ required: true, message: 'Missing Variable' }]}
                            >
                              <Select className="frz-width-220p" onChange={handleChange}
                                placeholder="Select Metric" showSearch optionFilterProp="children"
                                disabled={modalState.title === 'Edit Segments'}
                              >
                                {props.metrics.map(item => (
                                  <Option key={item.key} value={item.value}>
                                    {item.key}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          {isSelected?.[name]?.variable ? 
                            <>
                              <Col span={4}>
                                <Form.Item
                                  className='frz-hrz-center'
                                  {...restField}
                                  name={[name, 'condition']}
                                  rules={[{ required: true, message: 'Missing Operators' }]}
                                >
                                  <Select className="frz-width-110p" onChange={handleChange}
                                    placeholder="Select Operator" showSearch optionFilterProp="children"
                                    disabled={modalState.title === 'Edit Segments'}
                                  >
                                    {props.metrics.find(x => x.value === isSelected?.[name]?.variable)?.operators.map(item => (
                                      <Option key={item} value={item}>
                                        {item}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={10}>
                                {props.metrics.find(x => x.value === isSelected?.[name]?.variable)?.options === 'existing API' ? 
                                  <SelectHandler data={isSelected?.[key]?.variable === 'dark_store_id' ? darkstore : city}
                                                  typeKey="name" typeValue="id" key={key} name={name} restField={restField}/> : null
                                }
                                {Array.isArray(props.metrics.find(x => x.value === isSelected?.[name]?.variable)?.options) ? 
                                  <SelectHandler data={props.metrics.find(x => x.value === isSelected?.[name]?.variable)?.options}
                                    key={key} name={name} restField={restField} /> : null
                                }
                                {props.metrics.find(x => x.value === isSelected?.[name]?.variable)?.options === 'NA' && 
                                  props.metrics.find(x => x.value === isSelected?.[name]?.variable)?.type === 'Integer' ? 
                                  <IntegerHandler key={key} name={name} restField={restField}/> : null
                                }
                                {props.metrics.find(x => x.value === isSelected?.[name]?.variable)?.options === 'NA' && 
                                  props.metrics.find(x => x.value === isSelected?.[name]?.variable)?.type === 'Date' ? 
                                  <DatepickerHandler key={key} name={name} restField={restField}/> : null
                                }
                                {Array.isArray(props.metrics.find(x => x.value === isSelected?.[name]?.variable)?.type) ? 
                                  <>
                                    <RadioHandler key={key+'radio'} name={name} restField={restField}/>
                                    {props.metrics.find(x => x.value === isSelected?.[name]?.variable) && isSelected?.[name]?.Radiovalue === 'Integer' ?
                                      <IntegerHandler key={key} name={name} restField={restField}/> : null
                                    }
                                    {props.metrics.find(x => x.value === isSelected?.[name]?.variable) && isSelected?.[name]?.Radiovalue === 'Date' ? 
                                      <DatepickerHandler key={key} name={name} restField={restField}/> : null
                                    }
                                  </> 
                                  : null 
                                }
                              </Col>
                            </>
                          : null}
                          <Col span={2}>
                            {modalState.title !== 'Edit Segments' ?
                              <div className='frz-tm-7'>
                                {key !== 0 ? (
                                  <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    onClick={() => {remove(name); handleChange()}}
                                  />
                                ) : null}
                              </div>
                            : null}
                          </Col>
                        </Row>
                      </div>
                    ))}
                    {modalState.title !== 'Edit Segments' ?
                      <Form.Item className='frz-hrz-center text-center'>
                        <Button type="primary" onClick={() => add()} icon={<PlusOutlined />}>
                          Add Metrics
                        </Button>
                      </Form.Item> 
                    : null}
                  </>
                )}
              </Form.List>
          </Form>
        </Modal>
      : null}
    </div >

  )
})

export default CreateSegments
