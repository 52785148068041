import React, { useEffect, useState } from 'react';
import { Table, Checkbox, Button } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { EditOutlined } from '@ant-design/icons';

function ParentPayments({parentData, onChange, moveRow, components, getPayments, activeParent, showModal}){
  const [columns, setColumns] = useState([])

  useEffect(() => {
    getColumns()
  }, [parentData])

  function getColumns(){
    let colm = []
    colm.push({
      title: 'Image',
      dataIndex: '',
      key: '',
      width: 80,
      height: 80,
      render: (record) => {
        return (record?.image?.image_url ? 
          <img src={record?.image?.image_url} alt={record.name} className='frz-w-100'/>
        : null)  
      }
    },
    {
      title: 'Payment Mode',
      dataIndex: 'name',
      key: 'name',
      width: 190,
      align: 'center'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      align: 'center'
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'right',
      width: 60,
      render: (record) => {
        return <Button title="Edit" icon={<EditOutlined />} disabled={record.status === 'disabled'}
                       onClick={(e) => showModal(e, record, 'Edit Payment Methods')}>
               </Button>
      }
    })
    setColumns(colm)
  }

  return(
    <div className='frz-payment-box'>
      <DndProvider backend={HTML5Backend}>
        <Table dataSource={parentData} columns={columns} 
               pagination={false} components={components}
               scroll={{ y: 'calc(100vh - 240px)' }}
               rowClassName={(record) => {
                 return activeParent?.payment_mode_id === record.payment_mode_id ? 'frz-payment-highlight' : ''
               }}
               rowKey={'id'}
               onRow={(record, index) => ({
                index,
                moveRow,
                onClick:(e) => getPayments(record)
              })}/>
      </DndProvider>
    </div>
  )
}

export default ParentPayments