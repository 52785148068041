import React, { useEffect, useState } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber, Select,
         Pagination, DatePicker, Popconfirm, notification, Tag } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {StoreService} from '../../_services/store';
import MainTable from '../../components/antd/table';
import Search from '../../components/antd/search';
import {stoColumns} from '../../components/antd/columns/store';

const store_service = new StoreService()

function STO(){
  const [listingData, setListingData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [state, setState] = useState({valueBySource: undefined, valueByDestination: undefined, valueByStatus: undefined,
                                      valueByDeliveryDate: undefined, date: ''});
  const { valueBySource, valueByStatus, valueByDestination, valueByDeliveryDate, date } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getListing()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getListing(){
    setSpinEnable(true)
    setListingData([])
    const payload = {
      page: current,
      by_source: !valueBySource ? '' : valueBySource,
      by_destination: !valueByDestination ? '' : valueByDestination,
      by_transfer_date: !valueByDeliveryDate ? '' : valueByDeliveryDate,
      by_status: !valueByStatus ? '' : valueByStatus
    }
    store_service.get_sto(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setSpinEnable(false)
      setListingData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    stoColumns.map(data => {
      columns.push(data)
    })
    setColumns(columns)
  }

  function pagination(page) {
    setCurrent(page)
    setListingData([])
    setRenderData(false)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function onChangeDate(date, dateString) {
    setState(prevState =>({...prevState, date: date, valueByDeliveryDate: dateString}))
    setDisable(false)
    setCurrent(1)
  }

  function reset() {
    setState({valueBySource: undefined, valueByDestination: undefined, valueByStatus: undefined,
              valueByDeliveryDate: undefined, date: ''})
    setDisable(true)
    setCurrent(1)
    setListingData([])
    setRenderData(false)
  }

  return(
    <div className="layer">
      {listingData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Source DS" value={valueBySource}
                        onChange={(e) => onChange(e, 'valueBySource')} type="valueByDarkstore"/>
                <Search placeholder="Destination DS" value={valueByDestination}
                        onChange={(e) => onChange(e, 'valueByDestination')} type="valueByDarkstore2"/>
                <DatePicker value={date} onChange={onChangeDate} placeholder="Transfer Date"
                            style={{width: 200, margin: 10}}/>
                <Search placeholder="Status" value={valueByStatus}
                        onChange={(e) => onChange(e, 'valueByStatus')} data={[{name: 'dispatched'},{name: 'delivered'}]}/>
                <Button disabled={disable} onClick={getListing} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
              </Col>
            </Card>
          </Row>
          {listingData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={listingData} columns={columns}/>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default STO
