import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification, Table } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {DarkstoreService} from '../../_services/darkstore';
import {darkstoreSalesColumns} from '../../components/antd/columns/master/darkstores';
import moment from 'moment';

const darkstore_service = new DarkstoreService()

function DarkstoreSales(){
  const [darkstoreData, setDarkstoreData] = useState(false)
  const [statsData, setStatsData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [mode, setMode] = useState(undefined)
  const [state, setState] = useState({valueByDeliveryDate: '', date: ''});
  const { valueByDeliveryDate, date } = state
  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getDarkstores()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function reset() {
    setState({valueByDeliveryDate: '', date: ''})
    setDisable(true)
    setDarkstoreData([])
    setRenderData(false)
  }

  function getDarkstores(){
    setSpinEnable(true)
    setDarkstoreData([])
    const payload = {
      date: !valueByDeliveryDate ? '' : valueByDeliveryDate,
    }
    darkstore_service.get_darkstores_sales(payload).subscribe((r) => {
      setSpinEnable(false)
      setStatsData(r.response.darkstore_wise_data)
      setDarkstoreData(r.response.cumulative_data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    darkstoreSalesColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: "Poor Rating Count",
      dataIndex: "",
      key: "",
      align: "center",
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.poor_rating_count - b.poor_rating_count,
      render: (record) => {
        return(
          <div style={{color: 'red'}}>{record.poor_rating_count}</div>
        )
      }
    },
    {
      title: "Final Sales",
      dataIndex: "final_sales",
      key: "final_sales",
      align: "center",
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.final_sales - b.final_sales,
    },
    {
      title: "NPS",
      dataIndex: "nps",
      key: "nps",
      align: "center",
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.nps - b.nps,
    },)
    setColumns(columns)
  }

  function disabledDate(current){
    // Can select days before today and today
    return current && current > moment().add(0, "day");
  }

  function onChangeDate(date, dateString) {
    setState(prevState =>({...prevState, date: date, valueByDeliveryDate: dateString}))
    setDisable(false)
    setCurrent(1)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  return(
    <div className="layer">
      {darkstoreData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <DatePicker value={date} onChange={onChangeDate} placeholder="Date"
                  style={{ width: 200, margin: 10 }} disabledDate={disabledDate} />
                <Button disabled={disable} onClick={getDarkstores} style={{ width: 100, margin: 10 }}
                  type="primary">Search</Button>
                <Button onClick={reset} style={{ margin: 10 }}
                  type="primary" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                {Object.entries(statsData).map(([key, value]) => {
                    return(
                      key.includes("amount") ?
                      <span key={key} style={{padding: 15, lineHeight: 3,display: 'inline-block', paddingBottom:0, paddingTop:0}}><b>{key.replace("total_","").replace("amount", "Amt").split('_').join(' ').charAt(0).toUpperCase() +  key.replace("total_","").replace("amount", "amt").split('_').join(' ').slice(1)}: </b>₹{value}</span>: 
                      <span key={key} style={{padding: 15, lineHeight: 3, display: 'inline-block',paddingBottom:0,paddingTop:0}}><b>{key.replace("total_","").split('_').join(' ').charAt(0).toUpperCase() + key.replace("total_","").split('_').join(' ').slice(1)}: </b>{value}</span> 
                    )
                })}
                {/* <span style={{padding: 15}}><b>Society Orders: </b>{statsData.total_society_order_count}</span>
                <span style={{padding: 15}}><b>Society Ordered Amt: </b>₹ {statsData.total_society_order_amount}</span>
                <span style={{padding: 15}}><b>Ordered Amt: </b>₹ {statsData.total_ordered_amount}</span>
                <span style={{padding: 15}}><b>Sales Amt: </b>₹ {statsData.total_sales_amount}</span>
                <span style={{padding: 15}}><b>Sales Loss: </b>₹ {statsData.total_sales_loss}</span>
                <span style={{padding: 15}}><b>Reviews: </b>{statsData.total_review_count}</span>
                <span style={{padding: 15, display: 'inline-block'}}><b>Valet Order Count: </b>{statsData.total_valet_order_count}</span>
                <span style={{padding: 15, display: 'inline-block'}}><b>Valet Purchase: </b>{statsData.total_valet_purchase_count}</span> */}
              </Col> 
            </Card>
          </Row>
          {darkstoreData.length > 0 ?
            <Row>
              <Col span={24}>
                {/* <MainTable dataSource={darkstoreData} columns={columns}/> */}
                <Card style={{ paddingTop: 5, marginTop: 20 }}>
                    <Row>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                dataSource={darkstoreData}
                                pagination={false}
                                scroll={{ x: 240 }}
                                rowKey="darkstore_name"
                            />
                        </Col>
                    </Row>
                </Card>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default DarkstoreSales
