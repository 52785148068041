import React, { useEffect, useState } from "react";
import { Input, Button, Spin, InputNumber, Modal, Table, Radio, Checkbox } from "antd";
import { ProductService } from "../../../_services/product";
import { ErrorNotification, SuccessNotification } from "./notification";
import { darkStoreWisePricesColumns } from "../../../components/antd/columns/master/product";

function DarkStoreWisePrices(props) {
  const productService = new ProductService();
  const [darkStoreWisePrices, setDarkStoreWisePrices] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [operations, setOperations] = useState(false)
  const [columns, setColumns] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalRecord, setModalRecord] = useState(false);
  const commonInputWidth = 250;
  const editedRecordValues = {};

  const inputStyle = {
    marginRight: 100,
    textAlign: "right",
  };

  const inputStyle2 = {
    marginRight: 100,
    textAlign: "right",
    display: 'flex'
  };

  const inputStyle3 = {
    marginRight: 50,
    textAlign: "right",
  };

  useEffect(() => {
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if(['admin','developer','super_admin'].includes(data)){
        setOperations(true)
      }
    })
    getColumns();
    getDarkStoreWisePrices();
  }, []);

  function getColumns() {
    darkStoreWisePricesColumns.map((data) => {
      columns.push(data);
    });

    columns.push({
      title: "Actions",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, record) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              setModalRecord(record);
              setModalVisible(true);
            }}
          >
            Edit
          </Button>
        );
      },
    });
    setColumns(columns);
  }

  function handleCancel() {
    setModalVisible(false);
  }

  function getDarkStoreWisePrices() {
    setTableLoading(true);
    productService.getProductPricesDarkstoreWise(props.productId).subscribe(
      (r) => {
        setDarkStoreWisePrices(r.response.data);
      },
      (err) => {
        ErrorNotification("Failed to get details", (err = err));
      },
      () => {
        setTableLoading(false);
      }
    );
  }

  function saveProductDetails() {
    // if (!checkValidation()) {
    //   ErrorNotification("Please fill all details");
    //   return;
    // }
    setButtonLoading(true);
    const payload = {
      ...editedRecordValues,
      darkstore_id: modalRecord.darkstore_detail.id,
      product_id: modalRecord.product_detail.id,
    };
    // console.log(payload);

    productService.updateProductPricesDarkstoreWise(payload).subscribe(
      (r) => {
        getDarkStoreWisePrices();
        SuccessNotification("Details updated successfully");
        setButtonLoading(false);
        setModalVisible(false);
      },
      (err) => {
        ErrorNotification("Failed to update details");
        setButtonLoading(false);
      }
    );
  }

  function checkValidation() {
    Object.keys(editedRecordValues).forEach((key) => {
      if (editedRecordValues[key] == undefined) {
        return false;
      }
    });
    return true;
  }

  function onInputChange(key, value) {
    editedRecordValues[key] = value;
    if (value === "") {
      editedRecordValues[key] = undefined;
    }
  }

  const FZInputNumber = ({ keyName }) => {
    editedRecordValues[keyName] = modalRecord[keyName];
    return (
      <div className="block" style={inputStyle}>
        <span style={{ verticalAlign: "top" }}>{keyName.toUpperCase()}*</span>
        <span style={{ paddingLeft: 10 }}>
          <InputNumber
            placeholder={keyName}
            style={{ width: 200 }}
            min={0}
            onChange={(v) => onInputChange(keyName, v)}
            defaultValue={modalRecord[keyName] ?? undefined}
          />
        </span>
      </div>
    );
  };

  const FZInputRadioSelector = ({ keyName }) => {
    editedRecordValues[keyName] = modalRecord[keyName];
    return (
      <div className="block" style={keyName === 'is_special_discount' ? inputStyle2 : inputStyle3}>
        <span style={{ verticalAlign: "top" }}>{keyName.toUpperCase()}*</span>
        <span style={{ paddingLeft: 10 }}>
          <Radio.Group
            style={{ width: commonInputWidth, textAlign: "left" }}
            defaultValue={modalRecord[keyName] ?? undefined}
            disabled={!operations && keyName === 'is_active'}
            onChange={(e) => onInputChange(keyName, e.target.value)}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </span>
      </div>
    );
  };

  const FZCheckBox = ({ inputName, keyName }) => {
    editedRecordValues[keyName] = false;
    return (
      <div className="block" style={inputStyle2}>
        <span style={{ verticalAlign: "top" }}>{inputName} :</span>
        <span style={{ paddingLeft: 10 }}>
          <Checkbox
            placeholder={inputName}
            onChange={(e) => onInputChange(keyName, e.target.checked)}
            rows={4}
            cols={10}
          />
        </span>
      </div>
    );
  };

  return (
    <div>
      {darkStoreWisePrices ? (
        <div>
          <Table
            columns={columns}
            dataSource={darkStoreWisePrices}
            pagination={false}
            scroll={{ x: 240 }}
            loading={tableLoading}
            rowKey="id"
          />
          <Modal
            title={modalRecord?.darkstore_detail?.name}
            visible={modalVisible}
            onOk={saveProductDetails}
            confirmLoading={buttonLoading}
            onCancel={handleCancel}
          >
            <FZInputNumber keyName="moq_mrp" />
            <FZInputNumber keyName="moq_sp" />
            {/* <FZInputNumber keyName="qty" /> */}
            <FZInputNumber keyName="max_qty" />
            <FZInputRadioSelector keyName="is_special_discount" />
            <FZInputRadioSelector keyName="is_active" />
            <FZCheckBox inputName={`${"Update prices for all darkstores of "} ${modalRecord?.darkstore_detail?.city_name}`} 
                        keyName="all_darkstore_flag" />
          </Modal>
        </div>
      ) : (
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      )}
    </div>
  );
}

export default DarkStoreWisePrices;
