import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification } from 'antd';
import {StoreService} from '../../_services/store';
import MainTable from '../../components/antd/table';
import ForecastingModal from '../../components/master/forecasting/modal';
import {forecastingColumns} from '../../components/antd/columns/master/forecasting';
import {handleDateFormat} from '../../_modules/date';
import {getStaticTime} from '../../_modules/time';

const store_service = new StoreService()

function ForecastingFactors(){
  const modalRef = useRef(null);
  const [forecasting, setForecasting] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])    
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [state, setState] = useState({});
  const [modalState, setModalState] = useState({node_code: undefined, tp_available_flag: undefined, 
                                                tp_increment_percentage: undefined, marketing_increment: undefined,
                                                organic_growth_percentage: undefined, organic_growth_flag: undefined});
  const { node_code, tp_available_flag, tp_increment_percentage, marketing_increment,
          organic_growth_percentage, organic_growth_flag } = modalState

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getForecasting()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getForecasting(){
    setSpinEnable(true)
    setForecasting([])
    store_service.get_forecasting().subscribe((r) => {
      setSpinEnable(false)
      setForecasting(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    forecastingColumns.map(data => {
      columns.push(data)
    })
    columns.push(
    {
        title: "Updated On",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
          return (
            <>
              <div>{handleDateFormat(record.updated_at)}</div>
              <div>{getStaticTime(record.updated_at)}</div>
            </>
          );
        },
    },
    {
        title: 'Edit',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
            return (
            <Button type="primary" onClick={() => showModal(record, 'Edit Forecasting')}>Edit</Button>
            )
        }
    });
    setColumns(columns)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  function showModal(data, type) {
    if(type === 'Edit Forecasting'){
      setModalState({node_code: data.node_code, tp_available_flag: data.tp_available_flag,
                     tp_increment_percentage: data.tp_increment_percentage, marketing_increment: data.marketing_increment,
                     organic_growth_percentage: data.organic_growth_percentage, organic_growth_flag: data.organic_growth_flag})
    }
    modalRef.current.showModal(type, data)
  }

  function onChangeModal(value, type) {
    if(type === 'tp_available_flag' || type === 'organic_growth_flag'){
      setModalState(prevState =>({...prevState, [type]: value}))
    }else{
      setModalState(prevState =>({...prevState, [type]: value === "" ? undefined : value}))
    }
  }

  function onCancelModal() {
    setModalState({node_code: undefined, tp_available_flag: undefined, tp_increment_percentage: undefined, 
                   marketing_increment: undefined, organic_growth_percentage: undefined, organic_growth_flag: undefined})
  }

  function modalSubmit() {
    if(!node_code || !tp_increment_percentage || tp_available_flag === undefined || !marketing_increment || 
       !organic_growth_percentage || organic_growth_flag === undefined){
         openNotificationWithIcon('error', "Please fill all fields")
         modalRef.current.stopLoading()
    }else{
      const payload = {
          node_code: node_code,
          tp_increment_percentage: tp_increment_percentage,
          tp_available_flag: tp_available_flag,
          marketing_increment: marketing_increment,
          organic_growth_percentage: organic_growth_percentage,
          organic_growth_flag: organic_growth_flag
      }
      store_service.update_forecasting(payload).subscribe((r) => {
        openNotificationWithIcon('success','Edited Successfully')
        getForecasting()
        modalRef.current.handleCancel()
      },
      (err)=>{
        console.log(err)
        modalRef.current.stopLoading()
        openNotificationWithIcon('error', err.response.errors[0])
      })
    }
  }

  return(
    <div className="layer-nofilter">
      {forecasting ?
        <div>
          {forecasting.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={forecasting} columns={columns}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <ForecastingModal modalSubmit={modalSubmit} ref={modalRef}
                            onChangeModal={onChangeModal}
                            onCancelModal={onCancelModal}
                            tp_available_flag={tp_available_flag} 
                            tp_increment_percentage={tp_increment_percentage} 
                            marketing_increment={marketing_increment}
                            organic_growth_percentage={organic_growth_percentage} 
                            organic_growth_flag={organic_growth_flag}/>
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default ForecastingFactors
