import React, { useEffect, useState } from 'react';
import { Spin, Button, Form, Slider, Popconfirm, Col } from 'antd';
import { DistributionService } from "../../../_services/distribution";
import { openNotificationWithIcon } from '../../../_modules/notification'
import { useSelector } from "react-redux";
import FRZSelect from '../../../_controls/FRZSelect';
import { formItemLayout } from '../../../_modules/controllayout';
import { humanize } from '../../../_modules/humanize';

const distribution_service = new DistributionService();

const PackerDistribution = () => {

  const [form] = Form.useForm();
  const [data, setData] = useState(false)
  const [fromDs, setFromDs] = useState(false)
  const [toDs, setToDs] = useState(false)
  const [packersData, setPackersData] = useState([]);
  const [buttonLoad, setButtonLoad] = useState(false)
  const darkstore = useSelector(state => state.auth.darkstore);

  useEffect(() => {
    if(packersData.length > 0){
        setFormValues();
    }
  }, [packersData])

  function getListing(id) {
    const payload = { 
      by_darkstore: id
    }
    distribution_service.get_packer_list(payload).subscribe((r) => {
      let newData = r.response.map(v => ({...v, name: v.first_name + ' ' + v.last_name + ' (' + v.mobile_number + ')'}))
      setData(newData);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  function handlePackersSelected(e, arr){
        var newVal = []
        arr.map(val => {
          var newData =[]
          data.map(x=> x.name === val.children ?
            newData.push({id: x.id, name: val.children, city_wise_shift_id: x.city_wise_shift_id, role: x.roles, shifts: x.allowed_shifts}) : null
          )
          newVal.push(newData);
        })
        setPackersData(newVal)
     }

  function onChange(value, type) {
    if(type === 'fromDs'){
      getListing(value)
      setFromDs(value)
      form.setFieldsValue({ packers_list: undefined })
    }
    else{
      setToDs(value)
    }
  }

  function onFinish(values){
    var obj = [];
    for (const [key, value] of Object.entries(values)) {
      if(key.includes("shift")){
        obj.push({
          id:parseInt(key.split("_")[1]),
          city_wise_shift_id: value
        })
      }}
    const payload ={
      to_darkstore: values.to_darkstore,
      admin_shift_details: obj
    }
    setButtonLoad(true)
    delete values.from_darkstore
    
    distribution_service.update_packer(payload).subscribe((r) => {
      setButtonLoad(false);
      form.resetFields()
      setFromDs(false)
      setToDs(false)
      setData(false)
      setPackersData([])
      openNotificationWithIcon('success', 'Packer Transfer Success')
    },
      (err) => {
        console.log(err);
        setButtonLoad(false);
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      }
    );
  }

  const setFormValues =(e) => {
     let formValues = setValues();
    form.setFieldsValue(formValues)
  }

  const setValues = () => {
    let temp = packersData.map(
      x => Object.entries(x).map(([key, value]) => {
        return Object.assign({}, 
          {
            ["shifts"+"_"+value.id]:value.city_wise_shift_id
          },
          )
      }))
       let obj ={};
        for (var i = 0; i < temp.length; i++) {
          obj = Object.assign({}, ...temp[i])
        }
      return obj;
  }

  return (
    <div>
      <h2>Manpower Distribution</h2>
      <div className="frz-mt-40">
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
          <FRZSelect DropDownData={darkstore} name="from_darkstore" isRequired={true}
            label="From Darkstore" placeholder="From Darkstore" value="id" option="name"
            isShowSearch={true} width={300} setOnChange={(e) => onChange(e, 'fromDs')} isDisabledVal={[toDs]}
            optionDisabledKey='id'/>
          <FRZSelect DropDownData={darkstore} name="to_darkstore" isRequired={true}
            label="To Darkstore" placeholder="To Darkstore" value="id" option="name"
            isShowSearch={true} width={300} selectDisabled={!data} isDisabledVal={[fromDs]}
            optionDisabledKey='id'
            setOnChange={(e) => onChange(e, 'toDs')}/>
          {data ? 
            <FRZSelect mode="multiple" DropDownData={data} name="packers_list" isRequired={true}
                label="Names" placeholder="Names" value="id" option="name" setOnChange={handlePackersSelected}
                isShowSearch={true} width={300} isDisabledVal={[fromDs]} optionDisabledKey='id'/> : null}
          {packersData ? <Col span={24}>
            {packersData.map(
              x => Object.entries(x).map(([key, value]) => {
                return (
                  <div key ={value.id}>
                    <span className='packers-data' key={value.id}><b>{humanize(value.name)}</b> ({ value.role.map((x, index) => 
                    index !== value.role.length -1 ? x.split('_').join(' ').charAt(0).toUpperCase() + x.split('_').join(' ').slice(1) + ", " 
                    : x.split('_').join(' ').charAt(0).toUpperCase() + x.split('_').join(' ').slice(1))})</span>
                  <span className='packers-data' key= {value.city_wise_shift_id}><FRZSelect name= {"shifts"+"_"+value.id} 
                   placeholder = "Select shift" width={300} value="id" option="shift_string" DropDownData={value.shifts}/></span> 
                  </div>
                );
              })
            )}
          </Col>
           :null}
          <Form.Item wrapperCol={{ offset: 10, span: 24 }}>
            <Popconfirm
              title="Are you sure you want to transfer these packers ?"
              onConfirm={() => {
                form
                  .validateFields()
                  .then(values => {
                    onFinish(values)
                  })
                  .catch(info => {
                  });
              }}
              okText="Yes"
              cancelText="No"
              placement="leftTop"
              disabled={!data}
            >
              <Button type="primary" disabled={!data} loading={buttonLoad}>
                Transfer
              </Button>
            </Popconfirm>
          </Form.Item>

        </Form>
      </div>
    </div>
  )
}

export default PackerDistribution
