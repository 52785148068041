import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, notification, DatePicker } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {OrderService} from '../../_services/orders';
import Search from '../../components/antd/search';
import MainTable from '../../components/antd/table';
import ReplacementModal from '../../components/orders/replacement_modal';
import {replacementColumns} from '../../components/antd/columns/orders';
import NestedReplacementTable from '../../components/orders/nested_replacement_table';

const order_service = new OrderService()

function DeliveryReplacement() {
  const modalRef = useRef(null);
  const [replacementData, setReplacementData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [operations, setOperations] = useState(false)
  const [renderOperations, setRenderOperations] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [status, setStatus] = useState(false)
  const [state, setState] = useState({valueByOrderNo: undefined, valueByOStatus: undefined, valueByDate: undefined, valueByNo: undefined,
                                      valueByDarkstore: undefined, date: ''});
  const [modalState, setModalState] = useState({currentActive: undefined, valueByStatus: undefined, current_status: undefined});
  const { currentActive, valueByStatus, current_status} = modalState
  const { valueByOrderNo, valueByOStatus, valueByDate, valueByNo, valueByDarkstore, date } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getReplacement()
    }
  })

  useEffect(() => {
    if(renderOperations){
      setRenderOperations(false)
      getColumns()
    }
  })

  useEffect(() => {
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if(['admin','developer','customer_support_executive','super_admin'].includes(data)){
        setOperations(true)
      }
    })
    setRenderOperations(true)
    getStatus()
  }, [])

  function getStatus(){
    order_service.get_status().subscribe((r) => {
        setStatus(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getReplacement(){
    setSpinEnable(true)
    setReplacementData([])
    const payload = {
      page: current,
      by_order_number: valueByOrderNo === undefined ? '' : valueByOrderNo, 
      by_status: valueByOStatus === undefined ? '' : valueByOStatus, 
      by_mobile_number: valueByNo === undefined ? '' : valueByNo, 
      by_delivery_date: valueByDate === undefined ? '' : valueByDate, 
      by_darkstore: valueByDarkstore === undefined ? '' : valueByDarkstore
    }
    order_service.get_replacements(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setSpinEnable(false)
      setReplacementData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    replacementColumns.map(data => {
      columns.push(data)
    })
    if(operations){
      columns.push({
        title: 'Edit',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (
            <Button type="primary" onClick={() => showModal(record, 'Edit Status')}>Edit</Button>
          )
        }
      })
    }
    setColumns(columns)
  }

  function showModal(data, type) {
    if(type === 'Edit Status'){
      setModalState({
        current_status: data.status,
        currentActive: data.id})
    }
    modalRef.current.showModal(type, data)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function onChangeDate(date, dateString) {
    setState(prevState =>({...prevState, date: date, valueByDate: dateString}))
    setDisable(false)
    setCurrent(1)
  }

  function onChangeModal(value, type) {
    setModalState(prevState =>({...prevState, [type]: value === "" ? undefined : value}))
    
  }

  function reset() {
    setState({valueByOrderNo: undefined, valueByOStatus: undefined, date: '',
              valueByDate: undefined, valueByNo: undefined, valueByDarkstore: undefined})
    setDisable(true)
    setCurrent(1)
    setReplacementData([])
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setReplacementData([])
    setRenderData(false)
  }

  function onCancelModal() {
    setModalState({current_status: undefined, valueByStatus: undefined,
                   currentActive: undefined})
  }

  function handleRoles(values){
    setModalState(prevState =>({...prevState, new_roles: values}))
  }

  function expandedRow(record){
    return <NestedReplacementTable data={record}/>
  }

  function modalSubmit() {
    if(!valueByStatus){
        openNotificationWithIcon('error', "Please select a status")
        modalRef.current.stopLoading()
    }else{
        const payload = {
            id: currentActive,
            body: {
                id: currentActive,
                delivery_replacement_status_id: valueByStatus
            }
        }
        order_service.update_status(payload).subscribe((r) => {
            openNotificationWithIcon('success','Updated Successfully')
            getReplacement()
            modalRef.current.handleCancel()
        },
        (err)=>{
            console.log(err)
            modalRef.current.stopLoading()
            openNotificationWithIcon('error', err.response.errors[0])
        })
    }
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  return (
    <div className="layer">
      {replacementData && status ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Darkstore Name" value={valueByDarkstore}
                        onChange={onChange} type="valueByDarkstore"/>
                <Search placeholder="Status" value={valueByOStatus}
                        onChange={onChange} type="valueByOStatus" data={status}/>
                <DatePicker value={date} onChange={onChangeDate} placeholder="Delivery Date"
                            style={{width: 200, marginLeft: 10, marginRight: 10}}/>
                <InputNumber
                  placeholder="Order No"
                  onChange={(e) => onChange(e, "valueByOrderNo")}
                  value={valueByOrderNo}
                  style={{ width: 200, margin: 10 }}
                />
                <InputNumber
                  placeholder="Mobile No"
                  onChange={(e) => onChange(e, "valueByNo")}
                  value={valueByNo}
                  style={{ width: 200, margin: 10 }}
                />
                <Button disabled={disable} onClick={getReplacement} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
              </Col>
            </Card>
          </Row>
          {replacementData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={replacementData} columns={columns} expandedRow={expandedRow}/>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <ReplacementModal modalSubmit={modalSubmit} ref={modalRef}
                      onChangeModal={onChangeModal}
                      onCancelModal={onCancelModal}
                      valueByStatus={valueByStatus}
                      status={status} current_status={current_status}
                     />
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  );
}

export default DeliveryReplacement
