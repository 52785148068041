import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, notification } from 'antd';
import { RolesAndShiftsService } from '../../_services/roles_and_shifts';
import MainTable from '../../components/antd/table';
import { shiftsColumns } from '../../components/antd/columns/master/darkstores';
import { openNotificationWithIcon } from '../../_modules/notification';
import RolesandShiftsModal from '../../components/store/RolesAndShiftsModal';
import { AdminService } from '../../_services/admin';
import {handleDateFormat} from '../../_modules/date';
import {getStaticTime} from '../../_modules/time';

const roles_and_shifts_service = new RolesAndShiftsService()

const admin_service = new AdminService()

function RolesandShifts() {
  const [shiftData, setShiftData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [payloadId, setPayloadId] = useState(false);
  const [roles, setRoles] = useState(false)
  const [mode, setMode] = useState(undefined)
  const modalRef = useRef(null);

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getRolesAndShifts()
      getRoles()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])


  function getRoles() {
    admin_service.get_roles().subscribe((r) => {
      setRoles(r.response)
    },
      (err) => {
        console.log(err)
      })
  }

  function getRolesAndShifts() {
    let id = localStorage.getItem('cityid')
    setSpinEnable(true)
    setShiftData([])
    roles_and_shifts_service.get_roles_and_shifts(id).subscribe((r) => {
      setSpinEnable(false)
      setShiftData(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getColumns() {
    shiftsColumns.map(data => {
      columns.push(data)
    })
    columns.push(
      {
        title: "Created At",
        dataIndex: "",
        key: "",
        align: "center",
        width: 150,
        render: (record) => {
          return (
            <>
              <div>{handleDateFormat(record.created_at)}</div>
              <div>{getStaticTime(record.created_at)}</div>
            </>
          );
        },
      },
      {
        title: "Updated At",
        dataIndex: "",
        key: "",
        align: "center",
        width: 150,
        render: (record) => {
          return (
            <>
              <div>{handleDateFormat(record.updated_at)}</div>
              <div>{getStaticTime(record.updated_at)}</div>
            </>
          );
        },
      },
      {
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (
          <Button type="primary" onClick={() => showModal(record, 'Edit CityWise Shift')}>Edit</Button>
        )
      }
    });
    setColumns(columns)
  }

  function showModal(data, type) {
    setPayloadId(data.id);
    setMode(type)
    modalRef.current.showModal(type, data)
  }

  function modalSubmit(payloadData, id) {
    const payload = {
      id: id,
      city_wise_shift: {
        city_id: localStorage.getItem('cityid'),
        starting_time: (payloadData.starting_time).format('HH:mm').toString(),
        ending_time: (payloadData.ending_time).format('HH:mm').toString(),
        is_active: payloadData.is_active,
        roles: payloadData.roles
      }
    }

    if (id) {
      roles_and_shifts_service.update_roles_and_shifts(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Edited Successfully')
        getRolesAndShifts()
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors)
        })
    } else {
      roles_and_shifts_service.create_roles_and_shifts(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Created Successfully')
        getRolesAndShifts()
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
        })
    }
  }

  return (
    <div className="layer">
      {localStorage.getItem('cityid') !== "" ?
        <div>
          {shiftData ?
            <div>
              <Row>
                <Card>
                  <Col span={24}>
                    <Button onClick={() => showModal(true, 'Create CityWise Shift')} className="frz-w-200 frz-m-10"
                      type="primary">Create CityWise Shift</Button>
                  </Col>
                </Card>
              </Row>
              {shiftData.length > 0 ?
                <Row>
                  <Col span={24}>
                    <MainTable dataSource={shiftData} columns={columns} />
                  </Col>
                </Row>
                : (spinEnable ?
                  <div className="spin-center"><Spin tip="...Loading" /></div>
                  :
                  <div className="no-data">No Data Available</div>)
              }
              <RolesandShiftsModal roles={roles} modalSubmit={modalSubmit} ref={modalRef} showModal={showModal}></RolesandShiftsModal>
            </div>
            :
            <div className="spin-center">
              <Spin tip="...Loading" />
            </div>
          }
        </div>
        :
        <div className="no-data">Please select city</div>
      }
    </div>
  )
}

export default RolesandShifts
