import React, { useEffect, useState } from 'react';
import {Row,Col, Card} from 'antd';
import Sales from '../../components/statistics/sales';
import Payment from '../../components/statistics/payment';
import Refund from '../../components/statistics/refund';
import StockOut from '../../components/statistics/stock_out';
import ExpressStockOut from '../../components/statistics/express_stock_out';
import ExpressStockSku from '../../components/statistics/express_stock_out_sku';
import QualityComplain from '../../components/statistics/quality_complain';
import Review from '../../components/statistics/review';
import CancelledOrders from '../../components/statistics/cancelled_orders';
import TopSkus from '../../components/statistics/top_skus';
import TopCustomers from '../../components/statistics/top_customers';
import Dea from '../../components/statistics/dea';
import TopSkusValue from '../../components/statistics/top_skus_value';
import RevenueChart from '../../components/statistics/revenue_chart';
import CustomerSignup from '../../components/statistics/customer_signup';
import DeliveryChart from '../../components/statistics/delivery_chart';
import FirstCustomerOrders from '../../components/statistics/first_customer_orders';
import FortnightlyActive from '../../components/statistics/fortnight_customers';
import ReactivatedCustomers from '../../components/statistics/reactivated_customers';
import CustomerOrderOnce from '../../components/statistics/customer_order_once';
import NewInstall from '../../components/statistics/new_install'; 
import Discount from '../../components/statistics/discount';
import ValetSales from '../../components/statistics/valet_sales';
import AllDeliveryChart from '../../components/statistics/all_delivery_chart'
import {BannersService} from '../../_services/banners';

const banner_service = new BannersService()

function StatisticsList(){
    const [productData, setProductData] = useState(false)

    useEffect(() => {
        getProductList()
    }, [])

    function getProductList(){
        banner_service.get_product_list().subscribe((r) => {
          setProductData(r.response.data)
        },
        (err)=>{
          console.log(err)
        })
    }

    return(
        <div>       
            <Card className="stats-card-padding">
                <div className="stats-card">Sales</div>
            </Card>
            <Row gutter={20} type="flex">
                <Col md={12} xs={24}>
                    <Sales/>
                </Col>
                <Col md={12} xs={24}>
                    <RevenueChart/>
                </Col>
            </Row>
            <Row gutter={20} type="flex">
                <Col md={12} xs={24}>
                    <TopSkus productData={productData}/>
                </Col>
                <Col md={12} xs={24}>
                    <TopSkusValue productData={productData}/>
                </Col>
            </Row>
            <Row gutter={20} type="flex">
                <Col md={12} xs={24}>
                    <ValetSales />
                </Col>
            </Row>
            <Card className="stats-card-padding card-mt">
                <div className="stats-card">App Analytics</div>
            </Card>
            <Row gutter={20} type="flex">
                <Col md={12} xs={24}>
                    <DeliveryChart/>
                </Col>
                <Col md={12} xs={24}>
                    <NewInstall/>
                </Col>
            </Row>
            <Row gutter={20} type="flex">
                <Col md={12} xs={24}>
                    <CustomerOrderOnce/>
                </Col>
                <Col md={12} xs={24}>
                    <FirstCustomerOrders />
                </Col>
            </Row>
            <Row gutter={20} type="flex">
                <Col md={12} xs={24}>
                    <FortnightlyActive/>
                </Col>
                <Col md={12} xs={24}>
                    <ReactivatedCustomers/>
                </Col>
            </Row>
            <Row gutter={20} type="flex">
                <Col md={12} xs={24}>
                    <AllDeliveryChart/>
                </Col>
            </Row>
            <Card className="stats-card-padding card-mt">
                <div className="stats-card">OPS Analytics</div>
            </Card>
            <Row gutter={20} type="flex">
                <Col md={12} xs={24}>
                    <Review/>
                </Col>
                <Col md={12} xs={24}>
                    <Dea/>
                </Col>
            </Row>
            <Row gutter={20} type="flex">
                <Col md={12} xs={24}>
                    <ExpressStockOut/>
                </Col>
                <Col md={12} xs={24}>
                    <QualityComplain/>
                </Col>
            </Row>
            <Row gutter={20} type="flex">
                <Col md={12} xs={24}>
                    <StockOut/>
                </Col>
                <Col md={12} xs={24}>
                    <ExpressStockSku productData={productData}/>
                </Col>
            </Row>
            <Card className="stats-card-padding card-mt">
                <div className="stats-card">Miscellaneous</div>
            </Card>
            <Row gutter={20} type="flex">
                <Col md={12} xs={24}>
                    <CancelledOrders/>
                </Col>
                <Col md={12} xs={24}>
                    <Payment/>
                </Col>
            </Row>
        </div>
    )
}

export default StatisticsList