import React, { forwardRef, useEffect, useState, useRef, useImperativeHandle } from "react";
import { Modal, Form, Checkbox, Row, Col } from "antd";
import FRZInputNumber from "../../../../_controls/FRZInputNumber";
import FRZRadioButton from "../../../../_controls/FRZRadioButton";
import { formItemLayout } from '../../../../_modules/controllayout';

const PriceUpdateModal = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [type, setType] = useState(false);
  const [isCheckbox, setIsCheckbox] = useState(false)
  const [state, setState] = useState({ moq_mrp_cb: false, moq_sp_cb: false, max_qty_cb: false, 
                                       is_next_day_delivery_cb: false, is_replenished_cb: false });
  const { moq_mrp_cb, moq_sp_cb, max_qty_cb, is_next_day_delivery_cb, is_replenished_cb } = state
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      if (type === 'Edit Single DarkStore')
        form.setFieldsValue({
          ...data, moq_mrp: data.pricing_details.moq_mrp,
          moq_sp: data.pricing_details.moq_sp, max_qty: data.pricing_details.max_qty,
          is_next_day_delivery: data.pricing_details.is_next_day_delivery,
          is_replenished: data.pricing_details.is_replenished
        })
      else {
        setState(prevState => ({
          ...prevState, moq_mrp_cb: data.pricing_details.moq_mrp || data.pricing_details.moq_mrp == 0 ? true : false,
          moq_sp_cb: data.pricing_details.moq_sp || data.pricing_details.moq_sp == 0 ? true : false,
          max_qty_cb: data.pricing_details.moq_qty || data.pricing_details.moq_qty == 0 ? true : false,
          is_next_day_delivery_cb: data.pricing_details.is_next_day_delivery,
          is_replenished_cb: data.pricing_details.is_replenished
        }))
        form.setFieldsValue({
          ...data, moq_mrp: data.pricing_details.moq_mrp,
          moq_sp: data.pricing_details.moq_sp, max_qty: data.pricing_details.moq_qty,
          is_next_day_delivery: data.pricing_details.is_next_day_delivery,
          is_replenished: data.pricing_details.is_replenished
        })
      }
    }
  }, [data])

  useImperativeHandle(ref, () => ({

    showModal(data, value, type) {
      setIsCheckbox(type)
      setVisible(true)
      setData(data)
      setType(value);
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      setData(false)
      form.resetFields()
    },

    stopLoading() {
      setLoading(false)
    }

  }));
  function handleCancel() {
    setVisible(false);
    setLoading(false)
    setData(false)
    form.resetFields()
  }

  function handleOnSubmit(values) {
    if (type === 'Edit Single DarkStore') {
      const payload = {
        ...values, darkstore_id: data.darkstore_detail.id,
        city_name: data.darkstore_detail.city_name, all_darkstore_flag: false
      }
      setLoading(true)
      props.modalSubmit(payload, type)
    }
    else {
      const payload = { ...values, city_name: data.name, all_darkstore_flag: true }
      if (!moq_sp_cb) {
        delete payload["moq_sp"];
      }
      if (!moq_mrp_cb) {
        delete payload["moq_mrp"];
      }
      if (!max_qty_cb) {
        delete payload["max_qty"];
      }
      if (!is_next_day_delivery_cb) {
        delete payload["is_next_day_delivery"];
      }
      if (!is_replenished_cb) {
        delete payload["is_replenished"];
      }
      setLoading(true)
      props.modalSubmit(payload, type)
    }
  }

  const onChange = (value, type) => {
    setState(prevState => ({ ...prevState, [type]: value }))
  }

  const handleOnChangeInput = (value, type) => {
    // if (type == "moq_mrp") {
    //   if (value !== "" || value !== null) {
    //     setState(prevState => ({
    //       ...prevState, moq_mrp_cb: true,
    //     }))
    //   } else {
    //     setState(prevState => ({
    //       ...prevState, moq_mrp_cb: false,
    //     }))
    //   }
    // } else if (type == "moq_sp") {
    //   if (value !== "" || value !== null) {
    //     setState(prevState => ({
    //       ...prevState,
    //       moq_sp_cb: true
    //     }))
    //   } else {
    //     setState(prevState => ({
    //       ...prevState,
    //       moq_sp_cb: false
    //     }))
    //   }
    // } else if (type == "max_qty") {
    //   if (value !== "" || value !== null) {
    //     setState(prevState => ({
    //       ...prevState,
    //       max_qty_cb: true
    //     }))
    //   } else {
    //     setState(prevState => ({
    //       ...prevState,
    //       max_qty_cb: false
    //     }))
    //   }
    // }
    // console.log("value", value, type)
  }

  function handleIsReplenished(e){
    if(e){
      form.setFieldsValue({is_replenished: false})
    }
  }

  function handleIsNextDay(e){
    if(e){
      form.setFieldsValue({is_next_day_delivery: false})
    }
  }


  return (
    <div>
      {data ?
        <Modal
          title={isCheckbox ? ("Edit Details - " + props.activeCity) : ("Edit Details - " + data.darkstore_detail.name)}
          visible={visible}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
              });
          }}
          width="50%"
          onCancel={handleCancel}
        >
          {isCheckbox ?
            <Form
              form={form}
              {...formItemLayout}
              layout="horizontal"
              name="form">
              <Row>
                <Col span={20}>
                  <FRZInputNumber isDisabled={!moq_mrp_cb} isRequired={moq_mrp_cb} name="moq_mrp" label="MRP" width={140} setOnChange={(e) => handleOnChangeInput(e, "moq_mrp")} />
                </Col>
                <Col span={4} className="frz-mt-8">
                  <Checkbox checked={moq_mrp_cb} onChange={(e) => onChange(e.target.checked, 'moq_mrp_cb')} />
                </Col>

                <Col span={20}>
                  <FRZInputNumber isDisabled={!moq_sp_cb} isRequired={moq_sp_cb} name="moq_sp" label="SP" width={140} />
                </Col>
                <Col span={4} className="frz-mt-8">
                  <Checkbox checked={moq_sp_cb} onChange={(e) => onChange(e.target.checked, 'moq_sp_cb')} />
                </Col>

                <Col span={20}>
                  <FRZInputNumber isDisabled={!max_qty_cb} isRequired={max_qty_cb} name="max_qty" label="Max Qty" width={140} />
                </Col>
                <Col span={4} className="frz-mt-8">
                  <Checkbox checked={max_qty_cb} onChange={(e) => onChange(e.target.checked, 'max_qty_cb')} />
                </Col>

                <Col span={20}>
                  <FRZRadioButton name="is_next_day_delivery" label="Is Next Day Delivery" 
                    isRequired={is_next_day_delivery_cb} disabled={!is_next_day_delivery_cb}
                    setOnChange={handleIsReplenished}/>
                </Col>
                <Col span={4} className="frz-mt-8">
                  <Checkbox checked={is_next_day_delivery_cb} onChange={(e) => onChange(e.target.checked, 'is_next_day_delivery_cb')} />
                </Col>

                <Col span={20}>
                  <FRZRadioButton name="is_replenished" label="Is Replenished" 
                    isRequired={is_replenished_cb} disabled={!is_replenished_cb}
                    setOnChange={handleIsNextDay}/>
                </Col>
                <Col span={4} className="frz-mt-8">
                  <Checkbox checked={is_replenished_cb} onChange={(e) => onChange(e.target.checked, 'is_replenished_cb')} />
                </Col>

              </Row>
            </Form>
            :
            <Form
              form={form}
              {...formItemLayout}
              layout="horizontal"
              name="form">
              <FRZInputNumber name="moq_mrp" label="MRP" width={200} />
              <FRZInputNumber name="moq_sp" label="SP" width={200} />
              <FRZInputNumber name="max_qty" label="Max Qty" width={200} />
              <FRZRadioButton name="is_next_day_delivery" label="Is Next Day Delivery" setOnChange={handleIsReplenished}/>
              <FRZRadioButton name="is_replenished" label="Is Replenished" setOnChange={handleIsNextDay}/>
            </Form>
          }

        </Modal>

        : <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})
export default PriceUpdateModal;