export const categoryColumns = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        align: "center",
    },
    {
        title: "Is Active",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
          return record.is_active.toString()
        }
    },
]