import React, { useEffect } from 'react';
import { useCountdown } from '../../_modules/usecountdown';
import { ShowCounter } from './showcounter';

const CountdownTimer = ({ targetDate, handleTimerUp, id, setEndTimeNull }) => {
  // useEffect(() => {
  //   setTimeout(() => {
  //     setEndTimeNull(156)
  //   }, 5000);
  // }, [])

  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  if (days + hours + minutes + seconds <= 0) {
    if (targetDate != null) {
      setEndTimeNull(id)
    }
    return <div className="expired-notice">
      <span>Expired!!!</span>
    </div>
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer