import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, notification, Select, Tabs, Table } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import Search from '../../components/antd/search';
import MainTable from '../../components/antd/table';
import SpecialDealsModal from '../../components/ads/special_deals_modal';
import {dealsColumns, dealsSequenceColumns} from '../../components/antd/columns/ads';
import {AdsService} from '../../_services/ads';
import { BannersService } from '../../_services/banners';
import { DndProvider, useDrag, useDrop, createDndContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

const ads_service = new AdsService()
const banner_service = new BannersService()
const { Option } = Select;
const { TabPane } = Tabs;

const RNDContext = createDndContext(HTML5Backend);

const type = 'DragableBodyRow';

const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = React.useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: monitor => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
        };
      },
      drop: item => {
        moveRow(item.index, index);
      },
    });
    const [, drag] = useDrag({
      item: { type, index },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    });
    drop(drag(ref));
    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ''}`}
        style={{ cursor: 'move', ...style }}
        {...restProps}
      />
    );
};

function SpecialDeals() {
  const modalRef = useRef(null);
  const [dealsData, setDealsData] = useState(false)
  const [productData, setProductData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [updateData, setUpdateData] = useState(false)
  const [currentTab, setCurrentTab] = useState(1);
  const [state, setState] = useState({valueByDarkstore: undefined, currentProduct: undefined});
  const [modalState, setModalState] = useState({darkstore_list: [], product_list: [], valueByRadio: undefined,
                                                valueByRadioActive: undefined});
  const { darkstore_list, product_list, valueByRadio, valueByRadioActive} = modalState
  const { currentProduct, valueByDarkstore } = state

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = dealsData[dragIndex];
      setDealsData(
        update(dealsData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
      setUpdateData(true)
    },
    [dealsData],
  );

  const manager = useRef(RNDContext);

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getDeals()
    }
  })

  useEffect(() => {
    if(updateData){
      setUpdateData(false)
      updateProductSequence()
    }
  })

  useEffect(() => {
    getColumns()
    getProductList()
  }, [])

  function getDeals(){
    setSpinEnable(true)
    setDealsData([])
    const payload = {
      page: current,
      by_product: !currentProduct ? '' : currentProduct,
      by_darkstore: !valueByDarkstore ? '' : valueByDarkstore,
    }
    ads_service.get_special_deals(payload).subscribe((r) => {
      // setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setSpinEnable(false)
      setDealsData(r.response.data.sort((a, b) => a.deals_sequence - b.deals_sequence))
    },
    (err)=>{
      console.log(err)
      setSpinEnable(false)
      setDealsData([])
    })
  }

  function getProductList(){
    banner_service.get_product_list().subscribe((r) => {
      setProductData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    dealsColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: 'Darkstores',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return(record.darkstore_list.length > 0 ?
                <div>
                    {record.darkstore_list.map(data => {
                    return (
                        <span key={data.id}>{data.name.split('-').slice(-1).pop()}, </span>
                    )
                    })}
                </div>
                :
                <div>All Darkstores</div>
        )
      }
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (
          <Button type="primary" onClick={() => showModal(record, 'Edit Deals')}>Edit</Button>
        )
      }
    })
    setColumns(columns)
  }

  function showModal(data, type) {
    if(type === 'Edit Deals'){
      let darkstores = []
      data.darkstore_list.map(val => {
        darkstores.push(val.id)
      })
      setModalState({
        darkstore_list: darkstores, product_list: [data.product_id],
        valueByRadio: darkstores.length > 0 ? false : true, valueByRadioActive: true})
    }
    modalRef.current.showModal(type, data)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function onChangeModal(value, type) {
    if(type === 'valueByRadio' || type === 'valueByRadioActive'){
      setModalState(prevState =>({...prevState, [type]: value}))
    }else{
      setModalState(prevState =>({...prevState, [type]: value === "" || value === null ? undefined : value}))
    }
  }

  function reset() {
    setState({currentProduct: undefined, valueByDarkstore: undefined})
    setDisable(true)
    setCurrent(1)
    setDealsData([])
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setDealsData([])
    setRenderData(false)
  }

  function onCancelModal() {
    setModalState({darkstore_list: [], product_list: [], valueByRadio: undefined, valueByRadioActive: undefined})
  }

  function onChangeTags(values, type){
    setModalState(prevState =>({...prevState, [type]: values}))
}

  function modalSubmit() {
      if(product_list.lrngth === 0 || valueByRadio === undefined || valueByRadioActive === undefined){
        openNotificationWithIcon('error', "Please fill all fields")
        modalRef.current.stopLoading()
      }else{
          if(valueByRadio === false && darkstore_list.length === 0){
            openNotificationWithIcon('error', "Please add darkstores")
            modalRef.current.stopLoading()
            return
          }
          const payload = {
            darkstore_list: valueByRadio ? [] : darkstore_list,
            product_list: product_list,
            active: valueByRadioActive,
            by_city: localStorage.getItem('city') ?? ''
          }
          ads_service.update_special_deals(payload).subscribe((r) => {
            openNotificationWithIcon('success','Success')
            getDeals()
            modalRef.current.handleCancel()
          },
          (err)=>{
            console.log(err)
            modalRef.current.stopLoading()
            openNotificationWithIcon('error', err.response.errors[0])
          })
      }
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  function changeTab(key) {
    setState({ valueByDarkstore: undefined, currentProduct: undefined});
    setCurrentTab(key);
    setDealsData([]);
    setCurrent(1);
    setDisable(true);
    setRenderData(false);
  }

  function updateProductSequence(){
    let dealsProduct = []
    dealsData.map((data, index) => {
      dealsProduct.push({id: data.product_id, sequence: index+1})
    })
    const payload = {
      spl_deals_products: dealsProduct,
      by_city: localStorage.getItem('city') ?? ''
    }
    ads_service.update_deals_sequence(payload).subscribe((r) => {
        openNotificationWithIcon("success", "Sequence Updated Successfully");
        getDeals()
    },
    (err)=>{
        console.log(err)
    })
  }

  return (
    <div className="layer">
      <div className="ost">
        <Tabs defaultActiveKey="1" onChange={changeTab}>
          <TabPane tab="Add/Edit Deals" key="1"></TabPane>
          <TabPane tab="Deals Sequence" key="2"></TabPane>
        </Tabs>
      </div>
      {dealsData && productData ?
        <div>
          {currentTab == 1 ?
            <>
              <Row>
                <Card>
                  <Col span={24}>
                    <Search placeholder="Darkstore Name" value={valueByDarkstore}
                            onChange={(e) => onChange(e, 'valueByDarkstore')} type="valueByDarkstoreStats"/>
                    <Select style={{ width: 200, margin: 10}} value={currentProduct} showSearch
                            placeholder="Select Product" onChange={(e) => onChange(e, 'currentProduct')}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                            {productData.map(data => {
                                return(
                                    <Option key={data.id} value={data.id}>{data.name}</Option>
                                )
                            })}
                    </Select>
                    <Button disabled={disable} onClick={getDeals} style={{width: 100, margin:10}}
                            type="primary">Search</Button>
                    <Button disabled={disable} onClick={reset} style={{margin:10}}
                            type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
                  </Col>
                </Card>
              </Row>
              <Row>
                <Card>
                  <Col span={24}>
                    <Button onClick={() => showModal(true, 'Add Deals')} style={{width: 150, margin:10}}
                            type="primary" disabled={localStorage.getItem('city') === ''}>Add Special Deals</Button>
                  </Col>
                </Card>
              </Row>
            </>
          : null}
          {dealsData.length > 0 ?
            <Row>
              <Col span={24}>
                {currentTab == 1 ?
                  <MainTable dataSource={dealsData} columns={columns}/>
                : 
                  <DndProvider manager={manager.current.dragDropManager}>
                    <Card>
                        <Row>
                            <Col span={24}>
                                <Table
                                    columns={dealsSequenceColumns}
                                    dataSource={dealsData}
                                    components={components}
                                    pagination={false}
                                    scroll={{ x: 240 }}
                                    onRow={(record, index) => ({
                                        index,
                                        moveRow,
                                    })}
                                    rowKey="product_id"
                                />
                            </Col>
                        </Row>
                    </Card>
                  </DndProvider>}
                {/* <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/> */}
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available Please Select A City</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <SpecialDealsModal modalSubmit={modalSubmit} ref={modalRef}
                             onChangeModal={onChangeModal}
                             onCancelModal={onCancelModal}
                             onChangeTags={onChangeTags}
                             productData={productData}
                             darkstore_list={darkstore_list}
                             product_list={product_list}
                             valueByRadio={valueByRadio}
                             valueByRadioActive={valueByRadioActive}
                             />
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  );
}

export default SpecialDeals
