import React, { useEffect, useState } from 'react'
import { Row, Col, Affix, Input, Divider, Checkbox, Button, Spin } from 'antd'
import { SearchOutlined } from '@ant-design/icons';

const CityDetail = ({ activeCityId, cityWiseDs, activeCityData, onSubmit, activeDsList, cloneActiveDsList,
  onChangeDs, handleDsId, handleAllDsActive, isAllDs, isChanged, loading, cityDetailLoader, isDistable }) => {

  const [dsList, setDsList] = useState(false)
  const [filterValue, setFilterValue] = useState(undefined)
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);
  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (dsList) {
      const results = dsList.filter(data =>
        data.name.toLowerCase().includes(searchTerm)
      );
      setSearchResults(results);
    }
  }, [searchTerm]);

  useEffect(() => {
    getDsById()
  }, [activeCityId, isAllDs])

  const getDsById = () => {
    let cityData = cityWiseDs.filter((e) => e.id === activeCityId)[0].ds
    if (cityData) {
      setDsList(cityData)
      setSearchResults(cityData);
      let tempDsIds = []
      cityData.map((data) => {
        tempDsIds.push(data.id)
      })
      handleDsId(tempDsIds)
    } else {
      setDsList([])
    }
  }

  return (
    !cityDetailLoader ?
      <div>
        <div>
          {dsList.length > 0 ?
            <Affix>
              <div className="frz-dis-flex frz-tp-10 frz-justify-sb">
                <div className="frz-dis-flex">
                  <div className="frz-city-heading frz-rp-12">
                    {cityWiseDs.find((e) => e.id == activeCityId).name}
                  </div>
                  <div>
                    <Input placeholder="Search Darkstore"
                      value={searchTerm}
                      onChange={handleChange} />
                  </div>
                </div>

                <div className="frz-dis-flex">
                  <div className="frz-rm-10 frz-dis-flex frz-vertical-center">
                    All Darkstores
                  </div>
                  <div className=" frz-dis-flex">
                    <Checkbox checked={isAllDs} className={isAllDs ? "frz-custom-checkbox-test" : "frz-custom-checkbox-disable-test"} onChange={handleAllDsActive}>
                      <div className={isAllDs ? "frz-custom-checkbox-text" : "frz-custom-checkbox-text-disable"}>
                        Active
                      </div>
                    </Checkbox>
                  </div>
                </div>
              </div>
            </Affix>
            : null}

          {/* <Divider /> */}

          <div className="frz-products-ds-list">
            {searchResults.length > 0 ?
              searchResults.map((dsData, index) => {
                return (
                  <div key={index} className="frz-ds-lisitng">
                    <div className="frz-dis-flex frz-justify-sb">
                      <div>
                        {dsData.name}
                      </div>
                      <div>
                        <Checkbox
                          className={cloneActiveDsList.includes(dsData.id) ? "frz-custom-checkbox-test" : "frz-custom-checkbox-disable-test"}
                          checked={cloneActiveDsList.includes(dsData.id)}
                          defaultChecked={activeDsList.includes(dsData.id)}
                          onChange={(e) => onChangeDs(e, dsData.id)} >
                          <div className={cloneActiveDsList.includes(dsData.id) ? "frz-custom-checkbox-text" : "frz-custom-checkbox-text-disable"}>
                            Active
                          </div>
                        </Checkbox>
                        {/* <Checkbox /> */}
                      </div>
                    </div>
                  </div>
                )
              })
              :
              <h3 className="frz-tp-24 frz-text-center">No Darkstore Found</h3>
            }
          </div>
          {dsList.length > 0 || isChanged ?
            <div className="frz-ds-list-bottom">
              <Button type="primary" onClick={onSubmit} loading={loading} disabled={isDistable}>
                Save
              </Button>
            </div>
            : null}
        </div >

      </div>
      :

      <div className="spin-center">
        <Spin tip="...Loading" />
      </div>
  )
}

export default CityDetail
