import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form } from 'antd';
import FRZSelect from '../../../_controls/FRZSelect';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZInputNumber from '../../../_controls/FRZInputNumber';


const AddProductModal = forwardRef((props, ref) => {
  const { modalSubmit, roles } = props

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState(false)
  const [data, setData] = useState(false)
  const [id, setId] = useState(false)
  const [id2, setId2] = useState(false)
  const [isClose, setIsClose] = useState(false)

  useEffect(() => {
    if(data && title === 'Edit Product'){
      setId(data.po_id)
    }
    if(data && title === 'Add Product'){
      setId(data.id)
    }
    if(data && title === 'Edit Graded Qty'){
      setId(data.po_id)
      setId2(data.id)
    }
    if ((data && title == "Edit Product") || isClose) {
      form.setFieldsValue({ ...data})
    }
    if (isClose) {
      setIsClose(false)
    }
  }, [data])

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setData(false)
      form.resetFields()
      setVisible(false)
      setLoading(false)
      setIsClose(true)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleCancelModal() {
    setData(false)
    form.resetFields()
    setVisible(false)
    setLoading(false)
    setIsClose(true)
  }

  const handleOnSubmit = (values) => {
    setLoading(true)
    modalSubmit(values, id, id2)
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form2">

            {title === 'Edit Graded Qty' ? 
              <FRZInputNumber name="graded_quantity" isRequired={true}
                label="Graded Qty" placeholder="Graded Qty" width={200} />
              :
              <>
                <FRZSelect DropDownData={props.products} name="sku" isRequired={true}
                  label="SKU Name" placeholder="Select SKU Name" value="sku" option="name"
                  isShowSearch={true} width={"100%"} selectDisabled={title === 'Edit Product'}/>

                <FRZInputNumber name="unit_price" isRequired={false}
                  label="Unit Price" placeholder="Unit Price" width={200} />
                
                <FRZInputNumber name="qty" isRequired={true}
                  label="Quantity" placeholder="Quantity" width={200} />
              </>
            }
          </Form>
        </Modal>
      :   
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form2">
        </Form>
      }
    </div>
  )
})

export default AddProductModal
