import React, {useState, useEffect, useRef} from 'react';
import {Table, Spin, Card, Button, notification} from 'antd';
import {nestedItemsColumns} from '../../antd/columns/master/product';
import {ProductTagService} from '../../../_services/product_tag';
import { EditOutlined } from '@ant-design/icons';
import TagModal from './tag_modal';
const tag_service = new ProductTagService()

function NestedTable(props) {
  const modalRef = useRef(null);
  const [nestedOrder, setNestedOrder] = useState(false)
  const [columns, setColumns] = useState([])
  const [modalState, setModalState] = useState({sequence: undefined, is_active: undefined, currentActive: undefined, 
                                                product_id: undefined});
  const { sequence, is_active, currentActive, product_id} = modalState

  useEffect(() => {
    getItems()
    getColumns()
  }, [])

  function getItems(){
    const payload = {
      id: props.data,
    }
    tag_service.get_line_items(payload).subscribe((r) => {
      setNestedOrder(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    nestedItemsColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (
          <Button type="primary" onClick={() => showModal(record, 'Edit Product Tag Mapping')} ghost icon={<EditOutlined />}></Button>
        )
      }
    })
    setColumns(columns)
  }

  function showModal(data, type) {
    setModalState({sequence: data.sequence, is_active: data.is_active, currentActive: data.id, product_id: data.product_id})
    modalRef.current.showModal(type, data)
  }

  function onChangeModal(value, type) {
    if(type === 'is_active'){
      setModalState(prevState =>({...prevState, [type]: value}))
    }else{
      setModalState(prevState =>({...prevState, [type]: value === "" ? undefined : value}))
    }
  }

  function onCancelModal() {
    setModalState({sequence: undefined, is_active: undefined, currentActive: undefined, product_id: undefined})
  }

  function modalSubmit(payloadData, id) {
    if(payloadData.is_active === undefined || payloadData.sequence === undefined){
          openNotificationWithIcon('error', "Please fill all fields")
          modalRef.current.stopLoading()
    }else{
      const payload = {
          id: currentActive,
          body: {
              product_id: product_id,
              product_tag_id: props.data,
              sequence: payloadData.sequence,
              is_active: payloadData.is_active,
          }
      }
      tag_service.update_product_tag_mapping(payload).subscribe((r) => {
        openNotificationWithIcon('success','Edited Successfully')
        getItems()
        modalRef.current.handleCancel()
      },
      (err)=>{
        console.log(err)
        modalRef.current.stopLoading()
        openNotificationWithIcon('error', err.response.errors[0])
      })
    }
  }

  function openNotificationWithIcon(type, msg) {
    notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  return(
    <Card className="nested-table">
      {nestedOrder ?
        <div>
          {nestedOrder.length > 0 ?
            <Table columns={columns} pagination={false} rowKey="id"
                   dataSource={nestedOrder}/>
           :
            <div className="no-data-nested">No Items Found</div>
          }
          <TagModal modalSubmit={modalSubmit} ref={modalRef}
                    onChangeModal={onChangeModal}
                    onCancelModal={onCancelModal}
                    modalState={modalState}/>
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </Card>
  )
}

export default NestedTable;
