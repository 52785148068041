import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification, Switch } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {AppService} from '../../_services/app';
import MainTable from '../../components/antd/table';
import {testAccountColumns} from '../../components/antd/columns/developers';
import TestAccountModal from '../../components/developers/test_account_modal';

const app_service = new AppService()

function TestAccounts(){
  const modalRef = useRef(null);
  const [listingData, setListingData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(undefined)
  const [modalState, setModalState] = useState({mobile_no: undefined, reset_type: undefined});
  const {mobile_no, reset_type} = modalState

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getTestAccount()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getTestAccount(){
    setSpinEnable(true)
    app_service.get_test_account().subscribe((r) => {
      setSpinEnable(false)
      setListingData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    testAccountColumns.map(data => {
      columns.push(data)
    })
    columns.push({
        title: 'Reset',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (
            <div>
              <Button type="primary"
                      onClick={() => showModal(record, 'Reset Account')}>Reset Account</Button>
            </div>
          )
        }
    })
    setColumns(columns)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  function showModal(data, type) {
    setCurrent(type)
    if(type === 'Create Test Account'){
        modalRef.current.showModal(type, data)
    }else{
        setModalState(prevState =>({...prevState, mobile_no: data.mobile_number}))
        modalRef.current.showModal(type, data)
    }
  }

  function onChangeModal(value, type) {
    if(type === 'valueByRadio'){
      setModalState(prevState =>({...prevState, [type]: value}))
    }else{
      setModalState(prevState =>({...prevState, [type]: value === "" ? undefined : value}))
    }
  }

  function onCancelModal() {
    setModalState({mobile_no: undefined, reset_type: undefined})
    setCurrent(undefined)
  }


  function modalSubmit(){ 
    if(current === 'Create Test Account'){
        if(!mobile_no){
            openNotificationWithIcon('error', "Please enter mobile no")
            modalRef.current.stopLoading()
            return
        }else{
            const payload = {
                mobile_number: mobile_no 
            }
            app_service.create_test_account(payload).subscribe((r) => {
                openNotificationWithIcon('success','Created Successfully')
                getTestAccount()
                modalRef.current.handleCancel()
            },
            (err)=>{
                console.log(err)
                modalRef.current.stopLoading()
                openNotificationWithIcon('error', err.response.errors[0])
            })
        }
    }else{
        if(!reset_type){
            openNotificationWithIcon('error', "Please select reset type")
            modalRef.current.stopLoading()
            return
        }else{
            const payload = {
                mobile_number: mobile_no,
                reset_type: reset_type
            }
            app_service.update_test_account(payload).subscribe((r) => {
                openNotificationWithIcon('success','Reset Successfull')
                getTestAccount()
                modalRef.current.handleCancel()
            },
            (err)=>{
                console.log(err)
                modalRef.current.stopLoading()
                openNotificationWithIcon('error', err.response.errors[0])
            })
        }
    }
  }

  return(
    <div className="layer-nofilter">
      {listingData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Create Test Account')} style={{width: 150, margin:10}}
                        type="primary">Create Test Account</Button>
                <span style={{paddingLeft: 20}}><b>OTP : </b> 654321</span>
              </Col>
            </Card>
          </Row>
          {listingData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={listingData} columns={columns}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Accounts Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <TestAccountModal modalSubmit={modalSubmit} ref={modalRef}
                            onChangeModal={onChangeModal}
                            onCancelModal={onCancelModal}
                            mobile_no={mobile_no}
                            reset_type={reset_type}/>
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default TestAccounts
