import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber, Table,
         Pagination, DatePicker, Popconfirm, notification } from 'antd';
import {ProductService} from '../../_services/product';
import Search from '../../components/antd/search';
import { DndProvider, useDrag, useDrop, createDndContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import {sequenceColumns} from '../../components/antd/columns/master/sequence';

const product_service = new ProductService()

const RNDContext = createDndContext(HTML5Backend);

const type = 'DragableBodyRow';

const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = React.useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: monitor => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
        };
      },
      drop: item => {
        moveRow(item.index, index);
      },
    });
    const [, drag] = useDrag({
      item: { type, index },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    });
    drop(drag(ref));
    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ''}`}
        style={{ cursor: 'move', ...style }}
        {...restProps}
      />
    );
};

function ProductSequence(){
  const [productData, setProductData] = useState(false)
  const [subCatData, setSubCatData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [updateData, setUpdateData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(false)
  const [state, setState] = useState({valueBySubCategory: 11});
  const { valueBySubCategory } = state

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = productData[dragIndex];
      setProductData(
        update(productData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
      setUpdateData(true)
    },
    [productData],
  );

  const manager = useRef(RNDContext);

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getProductSequence()
    }
  })

  useEffect(() => {
    if(updateData){
      setUpdateData(false)
      updateProductSequence()
    }
  })

  useEffect(() => {
    getColumns()
    getProductSubCatList()
  }, [])

  function getProductSequence(){
    setSpinEnable(true)
    // setProductData([])
    const payload = {
        valueBySubCat: valueBySubCategory,
    }
    product_service.get_product_sequence(payload).subscribe((r) => {
      setSpinEnable(false)
      setProductData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getProductSubCatList(){
    product_service.getProductSubCategories().subscribe((r) => {
        setSubCatData(r.response.data)
    },
    (err)=>{
        console.log(err)
    })
  }

  function updateProductSequence(){
    let subCat = []
    productData.map((data, index) => {
        subCat.push({id: data.id, sequence: index+1})
    })
    const payload = {
        sub_category_products: subCat
    }
    product_service.update_product_sequence(payload).subscribe((r) => {
        openNotificationWithIcon("success", "Sequence Updated Successfully");
        getProductSequence()
    },
    (err)=>{
        console.log(err)
    })
  }

  function getColumns(){
    sequenceColumns.map(data => {
      columns.push(data)
    })
    setColumns(columns)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  return(
    <div className="layer">
      {productData && subCatData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Subcategory" value={valueBySubCategory}
                        onChange={onChange} type="valueBySubCategory" data={subCatData}/>
                <Button disabled={disable} onClick={getProductSequence} style={{width: 100, margin:10}}
                        type="primary">Search</Button>  
              </Col>
            </Card>
          </Row>
          {productData.length > 0 ?
            <Row>
              <Col span={24}>
                <DndProvider manager={manager.current.dragDropManager}>
                    <Card style={{ paddingTop: 5, marginTop: 20 }}>
                        <Row>
                            <Col span={24}>
                                <Table
                                    columns={columns}
                                    dataSource={productData}
                                    components={components}
                                    pagination={false}
                                    scroll={{ x: 240 }}
                                    onRow={(record, index) => ({
                                        index,
                                        moveRow,
                                    })}
                                    rowKey="id"
                                />
                            </Col>
                        </Row>
                    </Card>
                </DndProvider>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default ProductSequence
