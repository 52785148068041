import React, { useState } from 'react';
import { Button } from 'antd'
import {DownloadService} from '../_services/downloads';
import {openNotificationWithIcon} from '../_modules/notification';

const download_service = new DownloadService()

function FrzDynamicDownload({name, fileName, url, payload, type, reportFunc, jobReportFunc, base }) {

    const [loading, setLoading] = useState(false)

    function getDownloadList(){
        setLoading(true)
        download_service[reportFunc](url, payload).subscribe((r) => {
            if(type === 'job'){
                var myVar = setInterval(() => {
                    download_service[jobReportFunc](base === 'fraazo' ? r.response.data.id : r.response.data).subscribe(res => {
                        if(base === 'fraazo'){
                            if(res.response.csv_data.status === 'success'){
                                clearInterval(myVar)
                                window.open(res.response.csv_data.url, '_blank')
                                openNotificationWithIcon('success','Downloaded Successfully')
                                setLoading(false)
                            }
                        }else if(base === 'zapper'){
                            if(res.response.response.status === 'success'){
                                clearInterval(myVar)
                                window.open(res.response.response.url, '_blank')
                                openNotificationWithIcon('success','Downloaded Successfully')
                                setLoading(false)
                            }
                        }else if(base === 'customer_segments'){
                            if(res.response.data.status === 'success'){
                                clearInterval(myVar)
                                const linkSource = `data:application/octet-stream;base64,${res.response.data.url}`;
                                const downloadLink = document.createElement("a");
                                const downloadFileName = `${fileName}_${(new Date).toDateString().replace(/ /g,'_')}.csv`;
                                downloadLink.href = linkSource;
                                downloadLink.download = downloadFileName;
                                downloadLink.click();
                                openNotificationWithIcon('success','Downloaded Successfully')
                                setLoading(false)
                            }
                        }
                    }, (err) => {
                        setLoading(false)
                        clearInterval(myVar)
                        openNotificationWithIcon('error','Download Failed')
                    })
                }, 6000);
            }else{
                const linkSource = `data:application/octet-stream;base64,${r.response.csv_data}`;
                const downloadLink = document.createElement("a");
                const downloadFileName = `${fileName}_${(new Date).toDateString().replace(/ /g,'_')}.csv`;
                downloadLink.href = linkSource;
                downloadLink.download = downloadFileName;
                downloadLink.click();
                openNotificationWithIcon('success','Downloaded Successfully')
                setLoading(false)
            }
        }, (err) => {
            setLoading(false)
            openNotificationWithIcon('error','Download Failed')
        })
    }

    return (
        <Button loading={loading} onClick={getDownloadList} className="frz-dwn"
                type="primary">Download {name}</Button>
    );
}

export default FrzDynamicDownload;
