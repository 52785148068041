import React, { useState, useEffect, useRef } from 'react'
import FRZSelect from '../../../_controls/FRZSelect'
import { LandingClusterService } from '../../../_services/sdui/landing_cluster'
import { Row, Col, Button, Select, Card, Image, Spin, Modal, Popover, Input, Form } from 'antd'
import { DeleteOutlined, HolderOutlined, BorderInnerOutlined, CloseOutlined } from '@ant-design/icons'
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import { openNotificationWithIcon } from '../../../_modules/notification'
import FRAAZO from '../../../_modules/http_headers';
import DebounceApp from '../../../_controls/FRZDebounceSelect'
import CreateCluster from '../landing_clusters/create_clusters'
import { tailLayout } from '../../../_modules/controllayout'

const landing_cluster_service = new LandingClusterService()
const { Option } = Select;

const ProductControl = ({ form, itemsChanged, setItemsChanged, staticData, setDataSourceType, dataSourceType,
  setItems, setClusterData, items, clusterData, spin, setSpin, getitemsById, clusterId }) => {
  const modalRef = useRef(null);

  const [title, setTitle] = useState(false)
  const [isAddProduct, setIsAddProduct] = useState(false)
  const [gridDisble, setGridDisble] = useState(false)
  const [currentSKU, setCurrentSKU] = useState(undefined)
  const [selectedProduct, setSelectedProduct] = useState(false)
  const [dragNdrop, setDragNdrop] = useState(false)
  const [clusterTitle, setClusterTitle] = useState(undefined)
  const [cloneModal, setCloneModal] = useState(false)

  useEffect(() => {
    if (dragNdrop) {
      setTitle(dragNdrop.title)
      getitemsById(dragNdrop.id)
    }
  }, [dragNdrop])

  useEffect(() => {
    if (Object.keys(clusterData)?.length) {
      if (!clusterId) {
        setTitle(clusterData?.title)
      } else {
        form.setFieldsValue({ productlist: [{ value: clusterData.id, label: clusterData?.cluster_name ? clusterData?.cluster_name : clusterData?.title }], })
        // console.log("data", clusterData)
        setTitle(clusterData?.cluster_name ? clusterData?.cluster_name : clusterData?.title)
      }
    }
  }, [clusterData, clusterId])

  // useEffect(() => {
  //   if (clusterId) {
  //     console.log("clusterData 2", clusterData)
  //     setTitle(clusterData.cluster_name)
  //   }
  // }, [clusterId])

  useEffect(() => {
    if (selectedProduct) {
      handleOnAddProduct(selectedProduct.id)
    }
  }, [selectedProduct])

  // const handleOnChangeCluster = (value) => {
  //   setTitle(clusterList.find((x) => x.id == value).title)
  //   getitemsById(value)
  // }

  // const getitemsById = (value) => {
  //   setSpin(true)
  //   setItemsChanged(false)
  //   landing_cluster_service.getProductList(value).subscribe((r) => {
  //     setSpin(false)
  //     setClusterData(r.response.data)
  //     setItems(r.response.data.cluster_details)
  //   }, ((err) => {
  //     setSpin(false)
  //     console.error({ err })
  //   }))
  // }

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    if (sourceIndex == items.length) {
      return
    }
    const nextState = swap(items, sourceIndex, targetIndex);
    setItems(nextState);
    setItemsChanged(true)
  }

  const handleOnClone = () => {
    setGridDisble(true)
    let tempCluster = clusterData
    tempCluster.cluster_details.forEach(function (v) { delete v.id });
    const payload = {
      title: clusterTitle,
      type_of_cluster: "products",
      cluster_details_attributes: [
        ...items
      ]
    }
    landing_cluster_service.createCluster(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Cluster clonned Successfully')
      // getClusterList()
      // setItems(false)
      setDragNdrop(r.response.data)
      getitemsById(r.response.data.id)

      setCloneModal(false);
      setItemsChanged(false)
      setClusterTitle(undefined)
      setGridDisble(false)
    }, ((err) => {
      openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
      setGridDisble(false)
      console.error("err", err)
    }))
  }

  const removeSelectedCluster = () => {
    // let data = form.getFieldValue()    
    // console.log("data", data)
    form.setFieldsValue({ productlist: undefined })
    setItems(false)
    setSpin(null)
  }

  const onDeleteItm = (id) => {
    if (items.length == 1) {
      openNotificationWithIcon("error", "At least one item is required")
      return
    }
    let temp = items;
    let index = temp.indexOf(temp.find((x) => x.component_id == id))
    if (index > -1) {
      temp.splice(index, 1);
    }
    setItems([...temp])
    setItemsChanged(true)
  }

  const handleOnAddProduct = (value) => {
    setItemsChanged(true)
    setCurrentSKU(value)
  }

  const onSaveCluster = () => {
    setGridDisble(true)
    const payload = {
      cluster: {
        cluster_details_attributes: [
          ...items
        ]
      }
    }
    const urlParam = items[0].cluster_id
    landing_cluster_service.updateCluster(payload, urlParam).subscribe((r) => {
      openNotificationWithIcon('success', 'Updated Successfully')
      setGridDisble(false)
      setItemsChanged(false)
      setCurrentSKU(undefined)
    }, ((err) => {
      setGridDisble(false)
      openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
      console.error("err", err)
    }))
  }

  const OnAddProduct = () => {
    // let productData = productList.find((x) => x.id == currentSKU)
    //check if item id already exist in data
    for (let i = 0; i < items.length; i++) {
      if (items[i].component_id === selectedProduct.id) {
        openNotificationWithIcon('error', 'selected item already exist, please select another item.')
        return
      }
    }
    const itemObj = {
      // constant data
      cluster_id: items[0].cluster_id, status: items[0].status,
      component_type: items[0].component_type, component_type: "Product",
      // id: items[0].id,
      component_id: selectedProduct.id,
      //dynamic data
      component_sku: selectedProduct.sku,
      component_image_url: selectedProduct.image_url, component_name: selectedProduct.name
    }
    let temp = items
    temp.push(itemObj)
    setItems([...temp])
    setSelectedProduct(false)
    form.setFieldsValue({ prodnamne: undefined })
    setIsAddProduct(false)
    setItemsChanged(true)
  }

  const handleVisibleChange = (newVisible) => {
    setCloneModal(newVisible);
    setClusterTitle(undefined)
  };

  const onTypeDataSrcChange = (value) => {
    setDataSourceType(value)
    setItems(false)
    form.setFieldsValue({ productlist: undefined })
    setSelectedProduct(undefined)
    setIsAddProduct(false)
    setSpin(null)
  }


  return (
    <>
      <FRZSelect DropDownData={staticData.type_of_data_sources} name="type_of_data_source" isRequired={true}
        label="Select data sources type"
        isShowSearch={true} width={200}
        setOnChange={(value) => onTypeDataSrcChange(value)} />

      {dataSourceType === "manual_cluster" ?
        <>
          <DebounceApp isRequired={true} name="productlist" placeholder="Select Product Cluster" label="Select Product cluster" method="get"
            headers={FRAAZO()} labelTerm="title" valueTerm="id"
            url={process.env.REACT_APP_API_URL + '/admin_dashboard/v1/marketing/clusters?by_type_of_cluster=products&by_cluster_title='}
            dragNdrop={dragNdrop}
            setDragNdrop={(value) => setDragNdrop(value)} />
          <div className="frz-bm-20">
            <Row>
              <Col span={4}>
              </Col>
              <Col>
                <Button onClick={() => modalRef.current.showModal(true, 'Create Clusters')} className="frz-btn" type="primary">
                  Create new cluster
                </Button>
              </Col>
            </Row>
          </div>
        </>
        : dataSourceType &&
        <FRZSelect DropDownData={staticData.data_sources}
          name="data_source" isRequired={true}
          label="Select data sources"
          isShowSearch={true} width={200} />
      }
      {/* <FRZSelect DropDownData={clusterList} name="cluster" isRequired={true}
        label="Select Cluster" value="id" option="title"
        isShowSearch={true} width={200} setOnChange={handleOnChangeCluster} /> */}
      {
        !spin && items ?
          <Row>
            <Col span={7}>
            </Col>
            <Col span={17}>
              <Spin tip="Loading..." spinning={gridDisble}>
                <div className="frz-dis-flex frz-bm-20">
                  <div className="frz-selected-title frz-vt-center frz-dis-flex frz-justify-sb">
                    {title}
                    <div className="frz-lp-10 frz-font-10 frz-cursor-pointer" onClick={removeSelectedCluster}>
                      <CloseOutlined />
                    </div>
                  </div>
                  <div className="frz-lp-10 frz-dis-flex frz-vt-center">
                    <div className="frz-rp-12">
                      <Button type="primary" disabled={!itemsChanged} onClick={onSaveCluster} loading={gridDisble}>
                        update cluster
                      </Button>
                    </div>
                    {!itemsChanged ?
                      <Button type="ghost" onClick={() => setCloneModal(true)}
                        disabled={!itemsChanged}
                      // onClick={handleOnClone}
                      >
                        Clone & create new cluster
                      </Button> :
                      <Popover
                        // style={{ zIndex: !itemsChanged ? "-1" : "3" }}
                        // zIndex={!itemsChanged ? "-1" : "3"}
                        content={<div>
                          <Input value={clusterTitle} onChange={(e) => setClusterTitle(e.target.value)}
                            style={{ width: 120 }} placeholder='Enter title' />
                          <Button disabled={!clusterTitle || spin} className='frz-lm-10' onClick={() => handleOnClone()}>
                            Clone
                          </Button>
                        </div>}
                        title="Enter title for new cluster"
                        trigger="click"
                        visible={cloneModal}
                        onVisibleChange={handleVisibleChange}
                      >
                        <Button type="ghost" onClick={() => setCloneModal(true)}
                          disabled={!itemsChanged}
                        // onClick={handleOnClone}
                        >
                          Clone & create new cluster
                        </Button>
                      </Popover>
                    }
                  </div>
                </div>
                <GridContextProvider onChange={onChange}>
                  <GridDropZone
                    disableDrag={gridDisble}
                    disableDrop={gridDisble}
                    id="items"
                    boxesPerRow={4}
                    rowHeight={105}
                    style={{ height: items.length <= 4 ? "115px" : `calc(55px * ${items.length/2})`, overflow: "scroll" }}>
                    {items.map(item => (
                      <GridItem key={item.component_id}>
                        <div className={`frz-prod-contol-card frz-pos-realtive  ${gridDisble ? 'frz-cursor-disable' : 'frz-cursor-dragg'}`}>
                          <div className="frz-p-8 frz-dis-flex frz-hrz-center">
                            <div className="frz-pos-abs frz-r-5 frz-t-5 frz-font-10 frz-cursor-pointer" onClick={() => onDeleteItm(item.component_id)}>
                              <CloseOutlined />
                            </div>
                            <div>
                              <div className="frz-dis-flex frz-hrz-center">
                                <img draggable={false} src={item.component_image_url} className="frz-prod-contol-img" />
                              </div>
                              <div className="frz-text-center frz-seq-grid-txt">
                                {item.component_name}
                              </div>
                            </div>
                          </div>
                        </div>
                      </GridItem>
                    ))}
                    <GridItem>
                      {isAddProduct ?
                        <Modal title="Add Product" visible={isAddProduct} footer={false} onCancel={() => setIsAddProduct(false)}>
                          <DebounceApp isRequired={true} name="prodnamne" placeholder="Select product" label="Select product" method="get"
                            headers={FRAAZO()} labelTerm="name" valueTerm="id"
                            url={process.env.REACT_APP_API_URL + '/admin_dashboard/v1/products/products?starts_with='}
                            dragNdrop={selectedProduct}
                            setDragNdrop={(value) => setSelectedProduct(value)} />

                          <div className="frz-dis-flex frz-justify-end frz-tm-15">
                            <Button type="primary" onClick={() => OnAddProduct()} disabled={!currentSKU}>
                              Add
                            </Button>
                            <Button className="frz-lm-10" type="ghost" onClick={() => setIsAddProduct(false)}>
                              Cancel
                            </Button>
                          </div>
                        </Modal>
                        :
                        <div className="frz-prod-contol-card frz-pos-realtive">
                          <div className="frz-p-8 frz-dis-flex frz-hrz-center" onClick={() => {
                            setIsAddProduct(true)
                            setCurrentSKU(undefined)
                          }}>
                            Add product
                          </div>
                        </div>}
                    </GridItem>

                  </GridDropZone>
                </GridContextProvider>
              </Spin>
            </Col>
          </Row>
          :
          spin === null ? "" : <div className="spin-center">
            <Spin tip="...Loading" />
          </div>
      }
      <CreateCluster ref={modalRef} />
    </>
  )
}

export default ProductControl