import React, { useEffect, useState, Fragment } from "react";
import { Input, Modal, Divider, Spin } from "antd";
import { GrowthAndPlanningService } from '../../_services/growthAndPlanning';

const growth_and_planning = new GrowthAndPlanningService();

const LogsModal = (props) => {
  const { tab, setState, logsModal, data } = props;
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState(false)

  useEffect(() => {
    logsModal && getLogs()
  }, [logsModal])

  const getLogs = () => {
    const minUrl = tab === 'growth' ? 'capacity_change_logs' : 'pnp_change_logs'
    growth_and_planning.get_logs(minUrl).subscribe((res) => {
      setState('logsData', res.response.data)
      setFilteredData(res.response.data)
    },
      (err) => {
        console.log(err?.response)
      })
  }

  const onLogsSearch = () => {
    console.log(search, 'TEST')
    if (search === '') {
      setFilteredData(data)
    } else {
      let temp = []
      temp = data.filter(item => item?.message?.toLowerCase().includes(search.toLowerCase())) 
      console.log(temp, 'temp')
      setFilteredData(temp)
    }
  }

  return (
    <Modal
      visible={logsModal}
      onCancel={() => setState('logsModal', false)}
      className="modal-new"
      footer={false}
      closable={false}
      bodyStyle={{height: 818}}
      style={{
        top: 0,
      }}
    >
      <div className="search-padding">
        <Input.Search placeholder="Search logs" onChange={(e) => setSearch(e.target.value)} 
        onPressEnter={onLogsSearch} onSearch={onLogsSearch} enterButton />
      </div>
      <Divider />
      {filteredData ?
        filteredData?.length ? <div className="logs-modal-body">
          {filteredData.map((item) => {
            return (
              <Fragment key={item.id}>
                <div className="logs-modal">
                  <div>
                    <span className="initials">{item.initials}</span>
                    <Divider type="vertical" className="divider" />
                  </div>
                  <div className="logs-modal-content">
                    <div className="date-and-time">{item.date} | {item.time}</div>
                    <div className="frz-font-16">{item.message}</div>
                  </div>
                </div>
              </Fragment>
            )
          })}
        </div> : <div className="no-data">No Data Available</div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>}
    </Modal>
  )
}

export default LogsModal;