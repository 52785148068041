import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification, Table } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {DarkstoreService} from '../../_services/darkstore';
import {darkstoreDeliveryTimeColumns} from '../../components/antd/columns/master/darkstores';
import moment from 'moment';
import FrzDynamicDownload from '../../_controls/FRZDynamicDownload';

const darkstore_service = new DarkstoreService()

function DarkstoreDeliveryTime(){
  const [darkstoreData, setDarkstoreData] = useState(false)
  const [statsData, setStatsData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [mode, setMode] = useState(undefined)
  const [state, setState] = useState({valueByDeliveryDate: '', date: ''});
  const {valueByDeliveryDate, date } = state


  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getDarkstores()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function reset() {
    setState({valueByDeliveryDate: '', date: ''})
    setDisable(true)
    setDarkstoreData([])
    setRenderData(false)
  }

  function getDarkstores(){
    setSpinEnable(true)
    setDarkstoreData([])
    const payload = {
      date: !valueByDeliveryDate ? '' : valueByDeliveryDate,
    }
    darkstore_service.get_darkstores_deliverytime(payload).subscribe((r) => {
      setSpinEnable(false)
      setStatsData(r.response.cumulative_data)
      setDarkstoreData(r.response.darkstore_wise_data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function disabledDate(current){
    // Can select days before today and today
    return current && current > moment().add(0, "day");
  }

  function onChangeDate(date, dateString) {
    setState(prevState =>({...prevState, date: date, valueByDeliveryDate: dateString}))
    setDisable(false)
    setCurrent(1)
  }

  function getColumns(){
    darkstoreDeliveryTimeColumns.map(data => {
      columns.push(data)
    })
    setColumns(columns)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  return(
    <div className="layer">
      {darkstoreData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <DatePicker value={date} onChange={onChangeDate} placeholder="Date"
                  style={{ width: 200, margin: 10 }} disabledDate={disabledDate} />
                <Button disabled={disable} onClick={getDarkstores} style={{ width: 100, margin: 10 }}
                  type="primary">Search</Button>
                <Button onClick={reset} style={{margin:10}}
                  type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
                <span className="fl-right">
                  <FrzDynamicDownload name={'Booked Slots'} fileName={'Booked Slots'} url={'admin_dashboard/v1/downloads/darkstore_wise_booked_slots'}
                    payload={`?date=${valueByDeliveryDate}`} type={'job'} reportFunc={'fraazo_report'} jobReportFunc={'download_reports_job_fraazo'}
                    base={'fraazo'} />
                </span>
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <div className='frz-m-10'>
                  <span style={{paddingRight: 20, lineHeight: 3}}><b>Today's Orders: </b>{statsData.order_count}</span>
                  <span style={{paddingRight: 20, display: 'inline-block'}}><b>Delays: </b>{statsData.delays}</span>
                  <span style={{paddingRight: 20, display: 'inline-block'}}><b>Avg New Time: </b>{statsData.avg_new_time}</span>
                  <span style={{paddingRight: 20, display: 'inline-block'}}><b>Avg Packaging Time: </b>{statsData.avg_packaging_time}</span>
                  <span style={{paddingRight: 20, display: 'inline-block'}}><b>Avg Ready To Dispatch Time: </b>{statsData.avg_ready_to_dispatch_time}</span>
                  <span style={{paddingRight: 20, display: 'inline-block'}}><b>Avg Delivery Time: </b>{statsData.cumulative_avg_delivery_time}</span>
                  <span style={{paddingRight: 20, display: 'inline-block'}}><b>On Time Percentage: </b>{statsData.on_time_percentage} %</span>
                  <span style={{paddingRight: 20, display: 'inline-block'}}><b>Avg Delay By: </b>{statsData.avg_delay_by}</span>
                </div>
              </Col>
            </Card>
          </Row>
          {darkstoreData.length > 0 ?
            <Row>
              <Col span={24}>
                {/* <MainTable dataSource={darkstoreData} columns={columns}/> */}
                <Card style={{ paddingTop: 5, marginTop: 20 }}>
                    <Row>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                dataSource={darkstoreData}
                                pagination={false}
                                scroll={{ x: 240 }}
                                rowKey="name"
                            />
                        </Col>
                    </Row>
                </Card>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default DarkstoreDeliveryTime
