import React, { useEffect, useState } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {LogsService} from '../../_services/logs';
import MainTable from '../../components/antd/table';
import Search from '../../components/antd/search';
import {stockColumns} from '../../components/antd/columns/logs';
import {handleDateFormat} from '../../_modules/date';
import {getStaticTime} from '../../_modules/time';
import FrzDownload from '../../_controls/FRZDownload';
import moment from 'moment';
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const log_service = new LogsService()

function StockLog(){
  const [logData, setLogData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [spinEnableDownload, setSpinEnableDownload] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [stockType, setStockType] = useState([{name: 'All Stock'}, {name: 'Express Stock'}])
  const [state, setState] = useState({valueByDarkstore: undefined, start_date: '', end_date: '', showDateValue: false,
                                      valueByStock: 'All Stock', by_sku: undefined});
  const { valueByDarkstore, start_date, end_date, by_sku, showDateValue, valueByStock } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getStockLogs()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getStockLogs(){
    setSpinEnable(true)
    setLogData([])
    const payload = {
      page: current,
      by_darkstore: valueByDarkstore === undefined ? '' : valueByDarkstore,
      start_date: start_date,
      end_date: end_date,
      by_sku: by_sku?.toLowerCase() ?? '',
      // type: valueByStock === 'All Stock' ? '' : 'express'
    }
    log_service.get_stock_logs(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setSpinEnable(false)
      setLogData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    stockColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: "Created On",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <>
            <div>{handleDateFormat(record.created_at)}</div>
            <div>{getStaticTime(record.created_at)}</div>
          </>
        );
      },
    },
    {
      title: "Extended Time",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (record.extended_stock_time ? 
          <>
            <div>{handleDateFormat(record.extended_stock_time)}</div>
            <div>{getStaticTime(record.extended_stock_time)}</div>
          </> : null
        );
      },
    })
    setColumns(columns)
  }

  function pagination(page) {
    setCurrent(page)
    setLogData([])
    setRenderData(false)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function reset() {
    setState({valueByDarkstore: undefined, start_date: '', by_sku: undefined,
              end_date: '', showDateValue: false, valueByStock: 'All Stock'})
    setDisable(true)
    setCurrent(1)
    setLogData([])
    setRenderData(false)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  function onChangeRangePicker(value, dateString){
    setState(prevState =>({...prevState, showDateValue: true, start_date :dateString[0], end_date:dateString[1]}))
    setDisable(false)
    setCurrent(1)
  }

  function disabledDate(current){
    // Can select days before today and today
    // return current && current > moment().add(0, "day");
    //Can select days before today and past 3 months
    return current && (current < moment().subtract(91, "day") || current > moment().add(0, "day"));
  }

  function getDownloadStock(){
    setSpinEnableDownload(true)
    const payload = {
        by_darkstore: valueByDarkstore === undefined ? '' : valueByDarkstore,
        start_date: start_date,
        end_date: end_date,
        by_sku: by_sku?.toLowerCase() ?? '',
        type: valueByStock === 'All Stock' ? '' : 'express'
    }
    log_service.download_stock_sheet(payload).subscribe((r) => {
      const linkSource = `data:application/octet-stream;base64,${r.response.csv_data}`;
      const downloadLink = document.createElement("a");
      const fileName = `Products_Stock_Log_Sheet_${(new Date).toDateString().replace(/ /g,'_')}.csv`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      openNotificationWithIcon('success','Downloaded Successfully')
      setSpinEnableDownload(false)
    }, (err) => {
      setSpinEnableDownload(false)
      openNotificationWithIcon('error', err.response.errors[0])
    })
  }

  return(
    <div className="layer">
      {logData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Darkstore Name" value={valueByDarkstore}
                        onChange={onChange} type="valueByDarkstore"/>
                <RangePicker onChange={onChangeRangePicker} style={{margin:10}}
                             value={showDateValue ? [moment(start_date, dateFormat),
                             moment(end_date, dateFormat)] : '' } format={dateFormat}
                             allowClear={false} disabledDate={disabledDate}/>
                <Input placeholder="SKU Name"
                       onChange={(e) => onChange(e.target.value, 'by_sku')}
                       value={by_sku} style={{width: 200, margin: 10}}/>
                {/* <Search placeholder="Select Type" value={valueByStock} data={stockType}
                        onChange={(e) => onChange(e, 'valueByStock')} type="valueByStock"/> */}
                <Button disabled={disable} onClick={getStockLogs} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
                {!spinEnableDownload ?
                    <Button onClick={getDownloadStock} style={{width: 200, margin: 10}}
                            type="primary">Download Stock Log</Button>
                    :
                    <span style={{margin: 10, float: 'right'}}>
                        <Spin tip="...Download in Progress"/>
                    </span>
                }
                <FrzDownload 
                  setSpinEnable={setSpinEnable} 
                  url={'darkstore_product_stock_report'}
                  startDate={start_date}
                  endDate={end_date}
                  name={'Stock Report'}
                 />
              </Col>
            </Card>
          </Row>
          {logData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={logData} columns={columns}/>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Logs Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default StockLog
