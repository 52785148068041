export const attendanceColumns = [
  {
      title: 'Darkstore Name',
      dataIndex: 'node_name',
      key: 'node_name',
      align: "center",
  },
  {
      title: 'Manpower Planned',
      dataIndex: 'manpower_count',
      key: 'manpower_count',
      align: 'center',
      sorter: (a, b) => a.manpower_count - b.manpower_count 
  },
  {
      title: 'Actual Arrived',
      dataIndex: 'actual_arrived',
      key: 'actual_arrived',
      align: 'center',
      sorter: (a, b) => a.actual_arrived.localeCompare(b.actual_arrived)
  },
  {
    title: 'Arrived Late Percentage',
    dataIndex: 'arrived_late',
    key: 'arrived_late',
    align: 'center',
    sorter: (a, b) => a.arrived_late.localeCompare(b.arrived_late)
},
{
    title: 'Logged Out Count',
    dataIndex: 'logged_out',
    key: 'logged_out',
    align: 'center',
    sorter: (a, b) => a.logged_out.localeCompare(b.logged_out)
},
{
    title: 'Completed Shift Hours',
    dataIndex: 'completed_shift_hours',
    key: 'completed_shift_hours',
    align: 'center',
    sorter: (a, b) => a.completed_shift_hours.localeCompare(b.completed_shift_hours)
},
{
    title: 'Completed Valid Shift Hours',
    dataIndex: 'completed_valid_shift_hours',
    key: 'completed_valid_shift_hours',
    align: 'center',
    sorter: (a, b) => a.completed_valid_shift_hours.localeCompare(b.completed_valid_shift_hours)
},
]

export const cityWiseAttendanceColumns = [
  {
      title: 'Date',
      dataIndex: 'display_date',
      key: 'display_date',
      align: "center",
      width: 90
  },
    {
      title: 'Day',
      dataIndex: 'day',
      key: 'day',
      align: "center",
      width: 90
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    align: "center",
    width: 90
},
 {
      title: 'Manpower Planned',
      dataIndex: 'manpower_count',
      key: 'manpower_count',
      align: 'center',
      width: 90,
      sorter: (a, b) => a.manpower_count - b.manpower_count
  },
  {
      title: 'Actual Arrived',
      dataIndex: 'actual_arrived',
      key: 'actual_arrived',
      align: 'center',
      sorter: (a, b) => a.actual_arrived.localeCompare(b.actual_arrived),
      width: 90
  },
  {
    title: 'Arrived Late Percentage',
    dataIndex: 'arrived_late',
    key: 'arrived_late',
    align: 'center',
    sorter: (a, b) => a.arrived_late.localeCompare(b.arrived_late),
    width: 90
},
{
    title: 'Logged Out Count',
    dataIndex: 'logged_out',
    key: 'logged_out',
    align: 'center',
    sorter: (a, b) => a.logged_out.localeCompare(b.logged_out),
    width: 90
},
{
    title: 'Completed Shift Hours',
    dataIndex: 'completed_shift_hours',
    key: 'completed_shift_hours',
    align: 'center',
    sorter: (a, b) => a.completed_shift_hours.localeCompare(b.completed_shift_hours)
    ,width: 90
},
{
    title: 'Completed Valid Shift Hours',
    dataIndex: 'completed_valid_shift_hours',
    key: 'completed_valid_shift_hours',
    align: 'center',
    sorter: (a, b) => a.completed_valid_shift_hours.localeCompare(b.completed_valid_shift_hours),
    width: 90
},
]

export const cityWiseNestedCol = [
  {
      dataIndex: '',
      key: '',
      align: "center",
      width: 45
  },
  {
      dataIndex: 'display_date',
      key: 'display_date',
      align: "center",
      width: 90
  },
  {
      dataIndex: 'day',
      key: 'day',
      align: "center",
      width: 100
  },
  {
    dataIndex: 'city',
    key: 'city',
    align: "center",
    width: 90
  },
  {
      dataIndex: 'manpower_count',
      key: 'manpower_count',
      align: 'center',
      width: 100
  },
  {
      dataIndex: 'actual_arrived',
      key: 'actual_arrived',
      align: 'center',
      width: 100
  },
  {
      dataIndex: 'arrived_late',
      key: 'arrived_late',
      align: 'center',
      width: 90
  },
  {
      dataIndex: 'logged_out',
      key: 'logged_out',
      align: 'center',
      width: 90
  },
  {
      dataIndex: 'completed_shift_hours',
      key: 'completed_shift_hours',
      align: 'center',
      width: 90
  },
  {
      dataIndex: 'completed_valid_shift_hours',
      key: 'completed_valid_shift_hours',
      align: 'center',
      width: 90
  },
]
