import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Spin } from 'antd'
import UnitListing from '../../components/sdui/unitlisting'
import DetailPanel from '../../components/sdui/detailpanel'
import { LandingClusterService } from '../../_services/sdui/landing_cluster';
import { AdsService } from '../../_services/ads';
import moment from 'moment';
import { useSelector } from 'react-redux';

const landing_cluster_service = new LandingClusterService()

const ads_service = new AdsService()

const SDUI = () => {
  const [listingData, setListingData] = useState(false)
  const [activeUnitId, setActiveUnitId] = useState(false)
  const [addInProgress, setAddInProgress] = useState(false)
  const [unitData, setUnitData] = useState(false)
  const [staticData, setStaticData] = useState(false)
  const [segmentData, setSegmentData] = useState(false)
  const [updateId, setUpdateId] = useState(false)
  const [unitListSpin, setUnitListSpin] = useState(false)
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"))
  const [segmentValue, setSegmentValue] = useState(false)
  const [isSDUISuperAdmin, setIsSDUISuperAdmin] = useState(false)

  // by city backend driven not in unit listing
  // date picker only date no time
  // clone popover

  useEffect(() => {
    getListingData()
    getUnitData()
  }, [date, segmentValue])

  useEffect(() => {
    if (addInProgress) {
      getUnitData()
    }
  }, [addInProgress])

  useEffect(() => {
    getStaticData()
    getSegment()
    if (localStorage.getItem('roles').includes('sdui_super_admin')) {
      setIsSDUISuperAdmin(true)
    }
  }, [])

  const getSegment = () => {
    ads_service.get_segment().subscribe((r) => {
      setSegmentData(r.response.segments)
    },
      (err) => {
        console.log(err)
      })
  }

  const getStaticData = () => {
    landing_cluster_service.getStaticData().subscribe((r) => {
      setStaticData(r.response.data)
    }, ((err) => {
      console.error("err", err)
    }))
  }

  const getListingData = () => {
    setUnitListSpin(true)
    const param = `backend_driven_page_id=${100}&by_valid_today=${date ? date : ''}&by_city=${segmentValue ? segmentValue : ''}`
    landing_cluster_service.getUnitIndex(param).subscribe((r) => {
      setUnitListSpin(false)
      setListingData(r.response.data)
    }, ((err) => {
      setUnitListSpin(false)
      console.error("err", err)
    }))
  }

  const getUnitData = () => {
    const param = `by_date=${date ? date : ''}&by_city=${segmentValue ? segmentValue : ''}`
    landing_cluster_service.getUnitData(param).subscribe((r) => {
      setUnitData(r.response.data)
    }, ((err) => {
      console.error("err", err)
    }))
  }

  return (
    <div>
      {
        listingData && staticData && segmentData ?
          <Row gutter={[5, 5]}>
            <Col span={7}>
              <UnitListing activeUnitId={activeUnitId} setActiveUnitId={(value) => setActiveUnitId(value)}
                listingData={listingData} setListingData={(value) => setListingData(value)}
                addInProgress={addInProgress} setAddInProgress={(value) => setAddInProgress(value)}
                setDate={(value) => setDate(value)} supdateId={updateId}
                setUpdateId={(value) => setUpdateId(value)} segmentData={segmentData}
                unitListSpin={unitListSpin} setSegmentValue={(value) => setSegmentValue(value)} />
            </Col>

            <Col span={17}>
              <DetailPanel isSDUISuperAdmin={isSDUISuperAdmin} activeUnitId={activeUnitId} addInProgress={addInProgress}
                getListingData={getListingData}
                listingData={listingData} setActiveUnitId={(value) => setActiveUnitId(value)}
                setListingData={(value) => setListingData(value)}
                setAddInProgress={(value) => setAddInProgress(value)}
                unitData={unitData} staticData={staticData} segmentData={segmentData}
                updateId={updateId} date={date}
                setUpdateId={(value) => setUpdateId(value)}
              />
            </Col>
          </Row>
          : <div className="spin-center">
            <Spin tip="...Loading" />
          </div>}
    </div>
  )
}

export default SDUI