import React, { forwardRef, useState, useImperativeHandle, useRef, useEffect } from 'react'
import { Spin, Modal, Button, Row, Col, Tabs, notification, Select } from 'antd';
import { OrderService } from '../../_services/orders'
import {
  CreditCardOutlined, ShoppingCartOutlined,
  FieldTimeOutlined, SwapOutlined, RetweetOutlined, CheckOutlined
} from '@ant-design/icons';
import PaymentDetails from './payment_details';
import DeliveryItems from './delivery_items';
import DeliveryStatus from './delivery_status';
import CreateRefund from './create_refund';
import RefundItems from './refund_items';
import CreateTicket from './create_ticket';
import PartialRefund from './partial_refund';
import { DunzoService } from '../../_services/dunzo';
import ModalReshedule from './modal_reshedule';

const { TabPane } = Tabs;

const order_service = new OrderService()
const dunzo_service = new DunzoService()

const { Option } = Select;

const OrderModal = forwardRef((props, ref) => {
  const refundRef = useRef(null);
  const ticketRef = useRef(null);
  const partialRefundRef = useRef(null);
  const closeRefundRef = useRef(null);
  const [orderDetail, setOrderDetail] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [nearbyStores, setNearbyStores] = useState([])
  const [updatedNearbyDs, setUpdatedNearbyDs] = useState(false)
  const [submitDisble, setSubmitDisble] = useState(true)
  const [priority, setPriority] = useState(false)
  const [currentDsId, setCurrentDsId] = useState(false)
  const [resheduleModal, setResheduleModal] = useState(false)
  const [deliveryId, setDeliveryId] = useState(false)

  const [modalState, setModalState] = useState({
    refundMode: undefined, ticketReason: undefined, reason: undefined,
    partialRefundAmt: undefined
  });

  const { refundMode, ticketReason, reason, partialRefundAmt } = modalState

  useImperativeHandle(ref, () => ({
    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
      // console.log("data", data)
      getOrderDetail(data.id)
      setCurrentDsId(data.darkstore_id)
      setUpdatedNearbyDs(data.darkstore_id)
      getAllDsList(data.darkstore_id)
    },
  }));

  // useEffect(() => {
  //   if (data) {
  //     console.log("running")
  //     setCurrentDsId(data.darkstore_id)
  //     setUpdatedNearbyDs(data.darkstore_id)
  //     // console.log("data", data)
  //     getAllDsList(data.darkstore_id)
  //   }

  // }, [data])

  const getAllDsList = (id) => {
    const payload = {
      id: id
    }
    order_service.get_nearby_darkstores(payload).subscribe((r) => {
      setNearbyStores(r.response.data)
    }, ((err) => {
      console.log("err", err)
    }))
  }

  function getOrderDetail(id) {
    const payload = {
      id: id
    }
    order_service.get_order_detail(payload).subscribe((r) => {
      setOrderDetail(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function handleCancelModal() {
    closeRefundRef.current.cancelRefundClose()
    setVisible(false)
    setLoading(false)
    setCurrentDsId(false)
    setUpdatedNearbyDs(false)
    setOrderDetail(false)
    setPriority(false)
  }

  function createRefund() {
    refundRef.current.showModal('Create Refund', true)
  }

  function createTicket() {
    ticketRef.current.showModal('Create Dunzo Ticket', true)
  }

  function createPartialRefund() {
    partialRefundRef.current.showModal('Create Partial Refund', true)
  }

  function onCancelModal() {
    setModalState({
      refundMode: undefined, ticketReason: undefined, reason: undefined,
      partialRefundAmt: undefined
    })
  }

  //SUBMIT REFUND

  function modalSubmit(list, checked, refundType) {
    const payload = {
      delivery_id: orderDetail.delivery.id,
      raised_from: 'customer',
      mode_of_refund: refundType,
      refund_details: list,
      addon_refund: checked
    }
    order_service.create_refund(payload).subscribe((r) => {
      refundRef.current.handleCancel()
      openNotificationWithIcon('success', 'Refund Created Successfully')
      setOrderDetail(false)
      getOrderDetail(data.id)
    },
      (err) => {
        // getOrderDetail(data.id)
        // refundRef.current.stopLoading()
        openNotificationWithIcon('error', err.response.errors[0])
        console.log(err)
      })
  }

  //SUBMIT REPLACEMENT
  // function modalSubmitReplacement(list) {
  //   const payload = {
  //     delivery_id: orderDetail.delivery.id,
  //     refund_details:list,
  //     delivery_date: deliveryDate,
  //     delivery_slot_id: modalSlot
  //   }
  //   order_service.create_order_replacement(payload).subscribe((r) => {
  //     // refundRef.current.handleCancel()
  //     openNotificationWithIcon('success', 'Replacement Created Successfully')
  //     setOrderDetail(false)
  //     getOrderDetail(data.id)
  //   },
  //   (err)=>{
  //     // getOrderDetail(data.id)
  //     // refundRef.current.stopLoading()
  //     openNotificationWithIcon('error', err.response.errors[0])
  //     console.log(err)
  //   })
  // }


  //SUBMIT REVIEW

  function handleChange(value) {
    setUpdatedNearbyDs(value)
    // console.log("value", value)
    // console.log("updatedNearbyDs", updatedNearbyDs)
    // console.log("currentDsId", currentDsId)
    // if (value === updatedNearbyDs) {
    //   setCurrentDsId(value)
    // }
    setSubmitDisble(false)
  }

  function modalSubmitReviews(list, feedback, issues) {
    const payload = {
      review: {
        delivery_id: orderDetail.delivery.id,
        ratings: 0,
        feedback: feedback,
      },
      line_item_reviews: list
    }
    issues.map(data => {
      payload.review[data] = true
    })
    order_service.create_reviews(payload).subscribe((r) => {
      refundRef.current.handleCancel()
      openNotificationWithIcon('success', 'Review Created Successfully')
      setOrderDetail(false)
      getOrderDetail(data.id)
    },
      (err) => {
        // getOrderDetail(data.id)
        // refundRef.current.stopLoading()
        openNotificationWithIcon('error', err.response.errors[0])
        console.log(err)
      })
  }

  function modalSubmitTicket() {
    if (!ticketReason) {
      ticketRef.current.stopLoading()
      openNotificationWithIcon('error', 'Please enter a reason')
    } else {
      const payload = {
        delivery_id: orderDetail.delivery.id,
        issue_description: ticketReason
      }
      dunzo_service.create_ticket(payload).subscribe((r) => {
        ticketRef.current.handleCancel()
        openNotificationWithIcon('success', 'Ticket Created Successfully')
      },
        (err) => {
          ticketRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors[0])
          console.log(err)
        })
    }
  }

  function modalSubmitRefund() {
    if (!refundMode || !reason || !partialRefundAmt) {
      partialRefundRef.current.stopLoading()
      openNotificationWithIcon('error', 'Please fill all fields')
    } else {
      const payload = {
        delivery_id: orderDetail.delivery.id,
        refund_type: 'partial',
        amount: partialRefundAmt,
        refund_reason_id: reason,
        raised_from: 'customer',
        mode_of_refund: refundMode
      }
      order_service.create_partial_refund(payload).subscribe((r) => {
        partialRefundRef.current.handleCancel()
        // setOrderDetail(false)
        getOrderDetail(data.id)
        openNotificationWithIcon('success', 'Refund Created Successfully')
      },
        (err) => {
          partialRefundRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors[0])
          console.log(err)
        })
    }
  }

  function createRefundCredit() {
    const payload = {
      delivery_id: orderDetail.delivery.id,
    }
    order_service.create_refund_credit(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Extra Credit Added Successfully')
      setOrderDetail(false)
      getOrderDetail(data.id)
    },
      (err) => {
        // getOrderDetail(data.id)
        // refundRef.current.stopLoading()
        openNotificationWithIcon('error', err.response.errors[0])
        console.log(err)
      })
  }


  function onChangeModal(value, type) {
    setModalState(prevState => ({ ...prevState, [type]: value === "" ? undefined : value }))
  }

  function openNotificationWithIcon(type, msg) {
    notification[type]({ message: type.toUpperCase(), duration: 3, description: msg });
  };

  const handleNearbyDsUpdate = () => {
    setSubmitDisble(true)
    const payload = {
      order_id: data.id,
      darkstore_id: updatedNearbyDs
    }
    order_service.update_nearby_darkstore(payload).subscribe((r) => {
      setCurrentDsId(updatedNearbyDs)
      setSubmitDisble(true)
      props.getOrders()
      openNotificationWithIcon("success", "Darkstore Updated Successfully")
    }, ((err) => {
      console.log("err", err)
      setSubmitDisble(false)
    }))
  }



  const handlePriority = () => {
    const payload = {
      id: data.id,
      priority: true
    }
    order_service.update_priority(payload).subscribe((r) => {
      setPriority(true)
      props.getOrders()
      openNotificationWithIcon("success", "Priority Updated Successfully")
    }, ((err) => {
      console.log("err", err)
      openNotificationWithIcon("error", err?.response?.errors[0] ?? "API Error")
    }))
  }

  const handleResheduleModal = (value, id) => {
    setDeliveryId(id)
    setResheduleModal(value)
  }

  const onsubmit = (payloadData) => {
    setLoading(true)
    const payload = {
      ...payloadData,
      delivery_id: deliveryId
    }
    order_service.reshedule_delivery(payload).subscribe((r) => {
      setLoading(false)
      setResheduleModal(false)
      // handleCancelModal()
      props.getOrders()
      getOrderDetail(data.id)
      openNotificationWithIcon("success", "Delivery Reshedule Updated Successfully")
    }, ((err) => {
      setLoading(false)
      openNotificationWithIcon('error', err?.response?.errors?.[0] ?? 'API Error')
      console.log("Err", err)
    }))
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onCancel={handleCancelModal}
          width='85%'
          style={{ float: 'right' }}
          className="order"
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Close
            </Button>,
          ]}
        >
          <div className="order-modal-container">
            {orderDetail ?
              <div>
                <Row>
                  <Col md={7}>
                    <div style={{
                      borderRight: '1px solid #f0f0f0', paddingRight: 24,
                      height: '100%'
                    }}>
                      <div className="frz-dis-flex frz-justify-sb">
                        <div>
                          <b>Order No :</b>
                          <span style={{ paddingLeft: 10 }}>
                            {orderDetail.order_number}
                          </span>
                        </div>
                        {data.order_status !== "draft" && data.order_status !== "cancelled" &&
                          data.order_status !== "offline_draft" && data.order_status !== 'on_hold' ?
                          <>
                            {!data.priority && !priority ?
                              <Button type="primary" ghost shape="round"
                                size="small" onClick={handlePriority}
                              >
                                Prioritize Order
                              </Button>
                              :
                              <div className='order-detail-priority'>Priority</div>
                            }
                          </>
                          : null}
                      </div>
                      <div className="order-detail-padding">
                        <b>Delivery Date :</b>
                        <span style={{ paddingLeft: 10 }}>
                          {orderDetail.date}
                        </span>
                      </div>
                      <div className="order-detail-padding">
                        <b>Confirmed At :</b>
                        <span style={{ paddingLeft: 10 }}>
                          {orderDetail.confirmed_at}
                        </span>
                      </div>
                      <div className="order-detail-padding">
                        <b>Order Type :</b>
                        <span style={{ paddingLeft: 10 }}>
                          {orderDetail.order_type_detail}
                        </span>
                      </div>
                      <div className="order-detail-padding">
                        <b>Is Replacement :</b>
                        <span style={{ paddingLeft: 10 }}>
                          {orderDetail.is_replacement ? "Yes" : "No"}
                        </span>
                      </div>
                      {orderDetail.delivery_slot_details ?
                        <div className="order-detail-padding">
                          <b>Delivery Slot Details :</b>
                          <span style={{ paddingLeft: 10 }}>
                            {orderDetail.delivery_slot_details?.slot_string ?? orderDetail.delivery_slot_details.from_time + ' to ' + orderDetail.delivery_slot_details.to_time + ' ' + orderDetail.delivery_slot_details.am_pm}
                          </span>
                        </div>
                        : null}
                      <div className="order-detail-padding">
                        <b>Order Status :</b>
                        <span style={{ paddingLeft: 10 }}>
                          {orderDetail.order_status}
                        </span>
                      </div>
                      {data.order_status !== 'on_hold' ?
                        <>
                          <div className="frz-tp-20">
                            <div className="frz-dis-flex frz-justify-sb">
                              <b># Darkstore</b>
                              <div>
                                <Button disabled={(data.order_status != "confirmed" && data.order_status != "pending") || updatedNearbyDs === currentDsId || submitDisble} type="primary" ghost shape="round" icon={<CheckOutlined />}
                                  size="small" style={{ float: 'right', marginLeft: 16, paddingTop: 1 }}
                                  onClick={handleNearbyDsUpdate}
                                />
                              </div>
                            </div>
                            <div className="frz-hrz-division" style={{ marginTop: 10 }} />
                          </div>
                          <div className="frz-tp-10">
                            <Select value={updatedNearbyDs} disabled={data.order_status != "confirmed" && data.order_status != "pending"}
                              style={{ width: '100%' }} onChange={handleChange}>
                              {nearbyStores.map((data) => {
                                return (
                                  <Option key={data.id} value={data.id}>{data.name}</Option>
                                )
                              })}
                            </Select>
                          </div>
                          {props.cancelOperations ?
                            <div className="frz-tp-20">
                              <div className="frz-dis-flex frz-justify-sb">
                                <b># Reshedule Delivery</b>
                                <div>
                                  <Button disabled={(data.order_status === "draft" || data.order_status === "cancelled" ||
                                    data.order_status === "offline_draft") ||
                                    !orderDetail?.delivery?.id || 
                                    data?.order_action_buttons?.buttons?.find(x => x?.action_type === 'reschedule' && x?.show !== 'active')}
                                    type="primary" ghost shape="round"
                                    size="small" style={{ float: 'right', marginLeft: 16, paddingTop: 1 }}
                                    onClick={() => handleResheduleModal(true, orderDetail.delivery.id)}
                                  >
                                    Reshedule
                                  </Button>
                                </div>
                              </div>
                              <div className="frz-hrz-division" style={{ marginTop: 10 }} />
                            </div>
                            : null}
                        </>
                        : null}
                      {orderDetail.order_instructions ?
                        <div className="order-detail-padding">
                          <b>Order Instructions :</b>
                          <span style={{ paddingLeft: 10 }}>
                            {orderDetail.order_instructions}
                          </span>
                        </div>
                        : null}
                      {orderDetail.delivery ?
                        <div>
                          <div className="order-detail-padding"
                            style={{ paddingTop: 20, color: '#262624' }}>
                            <b>#Address</b>
                          </div>
                          <div className="order-detail-padding">
                            <b>Flat :</b>
                            <span style={{ paddingLeft: 10 }}>
                              {orderDetail.delivery.delivery_address.flat_detail}
                            </span>
                          </div>
                          <div className="order-detail-padding">
                            <b>Landmark :</b>
                            <span style={{ paddingLeft: 10 }}>
                              {orderDetail.delivery.delivery_address.landmark}
                            </span>
                          </div>
                          <div className="order-detail-padding">
                            <b>City :</b>
                            <span style={{ paddingLeft: 10 }}>
                              {orderDetail.delivery?.delivery_address.city}
                            </span>
                          </div>
                          <div className="order-detail-padding">
                            <b>Pincode :</b>
                            <span style={{ paddingLeft: 10 }}>
                              {orderDetail.delivery.delivery_address.pincode}
                            </span>
                          </div>
                        </div>
                        : null}
                      {orderDetail.delivery_partner_details ?
                        <div>
                          <div className="order-detail-padding"
                            style={{ paddingTop: 20, color: '#262624' }}>
                            <b>#Delivery Partner</b>
                          </div>
                          <div className="order-detail-padding">
                            <b>Name :</b>
                            <span style={{ paddingLeft: 10 }}>
                              {orderDetail.delivery_partner_details.name}
                            </span>
                          </div>
                          <div className="order-detail-padding">
                            <b>Mobile No:</b>
                            <span style={{ paddingLeft: 10 }}>
                              {orderDetail.delivery_partner_details.mobile_number}
                            </span>
                          </div>
                          <div className="order-detail-padding">
                            <b>Partner Type:</b>
                            <span style={{ paddingLeft: 10 }}>
                              {orderDetail.delivery_partner_details.partner_type}
                            </span>
                          </div>
                        </div>
                        : null}
                      {orderDetail.delivery ?
                        <div>
                          {orderDetail.delivery.review ?
                            <div>
                              {orderDetail.delivery.review.order_review_details ?
                                <div>
                                  <div className="order-detail-padding"
                                    style={{ paddingTop: 20, color: '#262624' }}>
                                    <b>#Review</b>
                                  </div>
                                  <div className="order-detail-padding">
                                    <b>Rating :</b>
                                    <span style={{ paddingLeft: 10 }}>
                                      {orderDetail.delivery.review.order_review_details.ratings}
                                    </span>
                                  </div>
                                  <div className="order-detail-padding">
                                    {orderDetail.delivery.review.order_review_details.ratings > 3 ?
                                      <b>Customer Liked:</b>
                                      : <b>Customer Disliked:</b>}
                                    <span style={{ paddingLeft: 10 }}>
                                      {orderDetail.delivery.review.order_review_details.app ? (orderDetail.delivery.review.order_review_details.app + ', ') : ''}
                                      {orderDetail.delivery.review.order_review_details.packaging ? (orderDetail.delivery.review.order_review_details.packaging + ', ') : ''}
                                      {orderDetail.delivery.review.order_review_details.pricing ? (orderDetail.delivery.review.order_review_details.pricing + ', ') : ''}
                                      {orderDetail.delivery.review.order_review_details.payment ? (orderDetail.delivery.review.order_review_details.payment + ', ') : ''}
                                      {orderDetail.delivery.review.order_review_details.quality ? (orderDetail.delivery.review.order_review_details.quality + ', ') : ''}
                                      {orderDetail.delivery.review.order_review_details.quantity ? (orderDetail.delivery.review.order_review_details.quantity + ', ') : ''}
                                      {orderDetail.delivery.review.order_review_details.time ? (orderDetail.delivery.review.order_review_details.time + ', ') : ''}
                                      {orderDetail.delivery.review.order_review_details.customer_support ? (orderDetail.delivery.review.order_review_details.customer_support + ', ') : ''}
                                      {orderDetail.delivery.review.order_review_details.delivery_partner ? (orderDetail.delivery.review.order_review_details.delivery_partner + ', ') : ''}
                                      {orderDetail.delivery.review.order_review_details.other ? (orderDetail.delivery.review.order_review_details.other) : ''}
                                    </span>
                                  </div>
                                  <div className="order-detail-padding">
                                    <b>Feedback :</b>
                                    <span style={{ paddingLeft: 10 }}>
                                      {orderDetail.delivery.review.order_review_details.feedback}
                                    </span>
                                  </div>
                                </div>
                                : null}
                            </div>
                            : null}
                        </div>
                        : null}
                    </div>
                  </Col>
                  <Col md={17}>
                    <div style={{ paddingLeft: 24 }}>
                      <Tabs defaultActiveKey="2">
                        <TabPane
                          style={{ minHeight: '80vh' }}
                          tab={<span>
                            <CreditCardOutlined />
                            Payment Details
                          </span>}
                          key="1"
                        >
                          <PaymentDetails orderDetail={orderDetail} createRefund={createRefund}
                            createTicket={createTicket} createRefundCredit={createRefundCredit}
                            createPartialRefund={createPartialRefund}
                            operations={props.operations} />
                        </TabPane>
                        <TabPane
                          tab={<span>
                            <ShoppingCartOutlined />
                            Delivery Items
                          </span>}
                          key="2"
                        >
                          <DeliveryItems orderDetail={orderDetail.delivery_line_items} orderDetails={orderDetail}
                            refundReason={props.refundReason} modalSubmit={modalSubmit} ref={closeRefundRef}
                            operations={props.operations} reviewReason={props.reviewReason} modalSubmitReviews={modalSubmitReviews}
                            reviewParams={props.reviewParams} issueParams={props.issueParams} setOrderDetail={setOrderDetail}
                            getOrderDetail={getOrderDetail} data={data}
                          />
                        </TabPane>
                        <TabPane
                          tab={<span>
                            <FieldTimeOutlined />
                            Delivery Status Logs
                          </span>}
                          key="3"
                        >
                          <DeliveryStatus orderDetail={orderDetail.status_logs} />
                        </TabPane>
                        <TabPane
                          tab={<span>
                            <SwapOutlined />
                            Refund Items
                          </span>}
                          key="4"
                        >
                          <RefundItems title={"Refund"} orderDetail={orderDetail.line_item_refund_data} />
                        </TabPane>
                        <TabPane
                          tab={<span>
                            <RetweetOutlined />
                            Replacement Items
                          </span>}
                          key="5"
                        >
                          <RefundItems title={"Replacement"} orderDetail={orderDetail.order_replacement_data} />
                        </TabPane>
                      </Tabs>
                    </div>
                  </Col>
                </Row>
                <CreateRefund modalSubmit={modalSubmit} ref={refundRef}
                  onCancelModal={onCancelModal}
                  onChangeModal={onChangeModal}
                  refundMode={refundMode}
                  orderDetail={orderDetail.delivery_line_items}
                  refundReason={props.refundReason} />
                <CreateTicket modalSubmit={modalSubmitTicket} ref={ticketRef}
                  onCancelModal={onCancelModal}
                  onChangeModal={onChangeModal}
                  ticketReason={ticketReason} />
                <PartialRefund modalSubmit={modalSubmitRefund} ref={partialRefundRef}
                  onCancelModal={onCancelModal}
                  onChangeModal={onChangeModal}
                  refundMode={refundMode}
                  reason={reason}
                  partialRefundAmt={partialRefundAmt}
                  refundReason={props.refundReason} />
                {resheduleModal ?
                  <ModalReshedule handleResheduleModal={handleResheduleModal}
                    resheduleModal={resheduleModal} orderDetail={orderDetail} onsubmit={onsubmit}
                    loading={loading} />
                  : null}
              </div>
              :
              <div className="spin-center">
                <Spin tip="...Loading" />
              </div>
            }
          </div>
        </Modal>
        : null}
    </div >
  )
})

export default OrderModal
