import React, { useState, useEffect } from 'react'
import ColorPicker from 'rc-color-picker'
import { Form, Input } from 'antd'
import 'rc-color-picker/assets/index.css';
import { tailLayout } from '../_modules/controllayout';

const FRZColorPickerTemp = ({ changePickerColor, pickerColor, columnSpan, label, value, isRequired, noStyle, formItemClass }) => {

  const onchangeHandler = (color) => {
    changePickerColor(color.color)
  }

  return (
    <div
      style={noStyle ? {} : { textAlign: 'start', justifyContent: "center", alignContent: "center", marginTop: "1em", marginBottom: "1em" }}
    >
      <Form.Item
        className={formItemClass ?? ""}
        label={label}
        {...tailLayout}
        rules={isRequired ? [
          {
            required: true,
            message: `Please input the value of ${label}!`,
          },
        ] : []}
      >
        <div style={{ marginTop: 5 }}>
          <ColorPicker
            animation="slide-up"
            color={value === undefined ? pickerColor : value}
            onChange={onchangeHandler}
          />
        </div>
      </Form.Item>
    </div>
  )
}

export default FRZColorPickerTemp
