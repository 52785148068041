import React, { useEffect, useState, useRef } from "react";
import { Spin, Button, Card, Row, Col, Tabs, Table, Pagination } from "antd";
import { CompanyBrandService } from "../../_services/company_brand";
import { companyListColumns, brandListColumns } from '../../components/antd/columns/master/company_brand';
import CompanyBrandModal from '../../components/master/company_brand/modal';
import { openNotificationWithIcon } from '../../_modules/notification';
import LogModal from "../../components/logs/logmodal";
import FrzDynamicDownload from '../../_controls/FRZDynamicDownload'

const cb_service = new CompanyBrandService()
const { TabPane } = Tabs;

function CompanyBrand() {
  const modalRef = useRef(null);
  const [tableData, setTableData] = useState(false);
  const [companyData, setCompanyData] = useState(false);
  const [playerData, setPlayerData] = useState(false);
  const [renderData, setRenderData] = useState(false);
  const [companyColumns, setCompanyColumns] = useState([]);
  const [brandColumns, setBrandColumns] = useState([]);
  const [spinEnable, setSpinEnable] = useState(false);
  const [disable, setDisable] = useState(true);
  const [mode, setMode] = useState(undefined)
  const [current, setCurrent] = useState(1);
  const [state, setState] = useState({});
  const [currentTab, setCurrentTab] = useState(1);
  const [showLogModal, setShowLogModal] = useState(false)
  const [itemId, setItemId] = useState(false)


  useEffect(() => {
    if (!renderData) {
      setRenderData(true);
      getTableData(currentTab);
    }
  });

  useEffect(() => {
    getColumns()
    getAllCompany()
    getAllPlayer()
  }, [])

  useEffect(() => {
    if (currentTab === '2') {
      getAllCompany()
    }
  }, [currentTab])

  const handleLogModal = (value, id) => {
    setShowLogModal(value)
    setItemId(id)
  }

  function getTableData(key) {
    setSpinEnable(true);
    setTableData([]);
    if (key == 1) {
      const payload = {
        page: current
      }
      cb_service.get_company(payload).subscribe((r) => {
        setSpinEnable(false);
        setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
        setTableData(r.response.product_companies_data);
      },

        (err) => {
          console.log(err);
        });
    } else {
      const payload = {
        page: current
      }
      cb_service.get_brands(payload).subscribe((r) => {
        setSpinEnable(false);
        setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
        let newData = r.response.product_brands_data.map(x => ({ ...x, product_company_id: x.product_company?.id }))
        setTableData(newData);
      },
        (err) => {
          console.log(err);
        });
    }
  }

  function getAllCompany() {
    cb_service.get_all_company().subscribe((r) => {
      setCompanyData(r.response.data);
    },

      (err) => {
        console.log(err);
      });
  }

  function getAllPlayer() {
    cb_service.get_player_list().subscribe((r) => {
      setPlayerData(r.response.data.type_of_player);
    },

      (err) => {
        console.log(err);
      });
  }

  function getColumns() {
    companyListColumns.map(data => {
      companyColumns.push(data)
    })
    companyColumns.push({
      title: "Actions",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <div>
            <Button type="primary" onClick={() => showModal(record, 'Edit Company')}>Edit</Button>
            <Button className="frz-lm-10" type="primary" onClick={() => handleLogModal(true, record.id)}>View Logs</Button>
          </div>
        );
      },
    })
    setCompanyColumns(companyColumns);
    brandListColumns.map(data => {
      brandColumns.push(data)
    })
    brandColumns.push({
      title: "Actions",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <div>
            <Button type="primary" onClick={() => showModal(record, 'Edit Brand')}>Edit</Button>
            <Button className="frz-lm-10" type="primary" onClick={() => handleLogModal(true, record.id)}>View Logs</Button>
          </div>
        );
      },
    })
    setBrandColumns(brandColumns);
  }

  function showModal(data, type) {
    setMode(type)
    modalRef.current.showModal(type, data)
  }

  function changeTab(key) {
    setCurrentTab(key);
    setTableData([]);
    setCurrent(1);
    setDisable(true);
    setRenderData(false);
  }

  function pagination(page) {
    setCurrent(page)
    setTableData(false)
    setRenderData(false)
  }

  function modalSubmit(payloadData, id) {
    if (mode === 'Edit Company') {
      const payload = {
        id: id,
        body: payloadData
      }
      cb_service.update_company(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Edited Successfully')
        getTableData(currentTab)
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response?.errors?.[0] ?? 'API Error')
        })
    } else if (mode === 'Create Company') {
      cb_service.create_company(payloadData).subscribe((r) => {
        openNotificationWithIcon('success', 'Created Successfully')
        getTableData(currentTab)
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response?.errors?.[0] ?? 'API Error')
        })
    } else if (mode === 'Edit Brand') {
      const payload = {
        id: id,
        body: payloadData
      }
      cb_service.update_brands(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Edited Successfully')
        getTableData(currentTab)
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response?.errors?.[0] ?? 'API Error')
        })
    } else if (mode === 'Create Brand') {
      cb_service.create_brands(payloadData).subscribe((r) => {
        openNotificationWithIcon('success', 'Created Successfully')
        getTableData(currentTab)
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response?.errors?.[0] ?? 'API Error')
        })
    }
  }

  return (
    <div className="layer">
      <div className="ost">
        <Tabs defaultActiveKey="1" onChange={changeTab}>
          <TabPane tab="Company's" key="1"></TabPane>
          <TabPane tab="Brands" key="2"></TabPane>
        </Tabs>
      </div>
      {tableData && companyData && playerData ? (
        <div>
          <Row>
            <Card style={{ marginBottom: 20 }}>
              <Col span={24}>
                {currentTab == 1 ?
                  <>
                    <Button onClick={() => showModal(true, 'Create Company')} className="frz-w-140px frz-m-10"
                      type="primary">Create Company</Button>
                    <span className="fl-right">
                      <FrzDynamicDownload name={'Company Master'} fileName={'Company Master'} url={'admin_dashboard/v1/downloads/company_master_report'}
                        payload={'?'} type={'job'} reportFunc={'fraazo_report'} jobReportFunc={'download_reports_job_fraazo'}
                        base={'fraazo'} />
                    </span>
                  </>  
                  :
                  <>
                    <Button onClick={() => showModal(true, 'Create Brand')} className="frz-w-140px frz-m-10"
                      type="primary">Create Brand</Button>
                    <span className="fl-right">
                      <FrzDynamicDownload name={'Brand Master'} fileName={'Brand Master'} url={'admin_dashboard/v1/downloads/brand_master_report'}
                        payload={'?'} type={'job'} reportFunc={'fraazo_report'} jobReportFunc={'download_reports_job_fraazo'}
                        base={'fraazo'} />
                    </span>
                  </>
                }
              </Col>
            </Card>
          </Row>
          {tableData.length > 0 ? (
            <Row>
              <Col span={24}>
                <Card style={{ paddingTop: 5 }}>
                  <Row>
                    <Col span={24}>
                      {currentTab == 1 ? (
                        <Table
                          columns={companyColumns}
                          dataSource={tableData}
                          pagination={false}
                          scroll={{ x: 240 }}
                          rowKey="id"
                        />
                      ) : (
                        <Table
                          columns={brandColumns}
                          dataSource={tableData}
                          pagination={false}
                          scroll={{ x: 240 }}
                          rowKey="id"
                        />
                      )}
                    </Col>
                  </Row>
                </Card>
                <Pagination className="frz-tm-10 frz-bm-10" current={current}
                  pageSize={50} total={state.total} onChange={pagination}
                  showSizeChanger={false} />
              </Col>
            </Row>
          ) : disable || spinEnable ? (
            spinEnable ? (
              <div className="spin-center">
                <Spin tip="...Loading" />
              </div>
            ) : (
              <div className="no-data">No Data Available</div>
            )
          ) : (
            <div className="no-data">No Data Available On Selected Filters</div>
          )}
          <CompanyBrandModal modalSubmit={modalSubmit} ref={modalRef} playerData={playerData}
            companyData={companyData} tableData={tableData} />
        </div>
      ) : (
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      )}
      {/* type, showLogModal, handleLogModal, itemId  */}
      {showLogModal ? <LogModal showLogModal={showLogModal} handleLogModal={handleLogModal}
        type={currentTab != 1 ? "product_brand" : "product_company"} itemId={itemId} /> : null}
    </div>
  );
}

export default CompanyBrand;
