import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form } from 'antd';
import FRZInput from '../../../_controls/FRZInput';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZDatepicker from '../../../_controls/FRZDatepicker';
import moment from 'moment';
import FRZSelect from '../../../_controls/FRZSelect';

const ForcastListingModal = forwardRef((props, ref) => {
  const { modalSubmit } = props
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [id, setId] = useState(false)
  const [form] = Form.useForm();
  const [isClose, setIsClose] = useState(false)

  useEffect(() => {
    if ((data && title == "Edit")) {
      form.setFieldsValue({ ...data, delivery_date: data && moment(data.delivery_date), sku: data.fraazo_sku })
      setId(data.id)
    } else {
      setId(false)
    }
  }, [data])

  function handleOnSubmit(values) {
    const payload = { ...values, delivery_date: moment(values.delivery_date).format("YYYY-MM-DD") }
    setLoading(true)
    modalSubmit(payload, id)
  }

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      setIsClose(true)
      setData(false)
      form.resetFields()
    },

    stopLoading() {
      setLoading(false)
    }

  }));


  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    setIsClose(true)
    setData(false)
    form.resetFields()
  }
  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            <FRZSelect DropDownData={props.city} name="city_id" isRequired={true}
              label="City Name" placeholder="Select City Name" value="id" option="name"
              isShowSearch={true} width={200} selectDisabled={title == "Edit"} />

            <FRZSelect DropDownData={props.products} name="sku" isRequired={true}
              label="SKU Name" placeholder="Select SKU Name" value="sku" option="name"
              isShowSearch={true} width={"100%"} selectDisabled={title == "Edit"} />

            <FRZInputNumber name="qty" isRequired={true}
              label="Quantity" placeholder="Quantity" width={200} />

            <FRZInputNumber name="target_price" isRequired={false}
              label="Target Price" placeholder="Target Price" width={200} />

            <FRZDatepicker placeholder="Select Delivery Date" label="Delivery Date" isRequired={true}
              name="delivery_date" isRequired={true} isDisabled={title == "Edit"}/>

          </Form>
        </Modal>
        : <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})

export default ForcastListingModal
