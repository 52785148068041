import { Http } from '../_modules/http'

export class PaymentsService {

  http = new Http()

  get_payments(payload){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/payment_modes_cities?' 
                + `${localStorage.getItem('city') ? 'by_city='+ localStorage.getItem('city') : ''}` 
                + `${payload?.parent_id ? '&parent_id='+payload.parent_id : ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_upi_extensions(payload){
    const url = process.env.REACT_APP_API_URL + '/admin_dashboard/v1/admin/get_global_properties?name=' 
               +payload.name
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  create_payments(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/admin/payment_modes'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload.body, headers)
  }

  update_payments(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/admin/payment_modes_cities/' + payload.id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.patch(url, payload.body, headers)
  }

  update_upi_extension(payload){
    const url = process.env.REACT_APP_API_URL +
                '/admin_dashboard/v1/admin/update_global_properties'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers)
  }

  update_payments_sequence(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/admin/update_payment_mode_sequence'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.patch(url, payload, headers)
  }

  

}
