import { Http } from "../_modules/http";

const moduleApis = [
  "trigger_sales_estimates",
  "trigger_forecasting_factors",
  "trigger_order_without_allocations",
  "trigger_society_allocations",
  "trigger_cluster_level_orders",
  "trigger_warehouse_stock_data",
  "trigger_darkstore_level_allocation",
];
export class DemandForecastingService {
  http = new Http();

  get_demand_forecasting_modules() {
    const url = process.env.REACT_APP_API2_URL + "parent_modules";
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  update_demand_forecasting(index) {
    const url = process.env.REACT_APP_API2_URL + moduleApis[index];
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.put(url, headers);
  }

  put_display_eligible_skus() {
    const url = process.env.REACT_APP_API2_URL + "display_eligible_skus";
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.put(url, headers);
  }

  post_make_order_zero(selectedRows) {
    const url = process.env.REACT_APP_API2_URL + "make_order_zero";
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    let body = {
      skus: selectedRows,
    };
    return this.http.post(url, body, headers);
  }

  // Only call on PRODUCTION
  post_upload_zapper_bulk_orders(csvURL) {
    const url = "https://api.freshvnf.com/v1/admin/upload_zapper_bulk_orders";
    let body = {
      url: csvURL,
    };
    return this.http.put(url, body);
  }

  place_society_order(){
    const url = process.env.REACT_APP_API2_URL + "approve_society_orders";
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.put(url, headers);
  }

  cluster_allocation_sheet(){
    const url = process.env.REACT_APP_API2_URL + "generate_cluster_allocation_sheet";
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.put(url, headers);
  }

  get_submodule_status(id){
    const url = process.env.REACT_APP_API2_URL + "display_sub_modules/" + id;
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }
}
