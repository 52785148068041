import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {AdsService} from '../../_services/ads';
import MainTable from '../../components/antd/table';
import Search from '../../components/antd/search';
import {referralDiscountColumns} from '../../components/antd/columns/ads';
import {handleDateFormat} from '../../_modules/date';
import {getStaticTime} from '../../_modules/time';
import moment from 'moment';
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const ads_service = new AdsService()

function ReferralDiscountCustomer(){
  const modalRef = useRef(null);
  const [referralData, setReferralData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [state, setState] = useState({valueByReferer: undefined, valueByRecipient: undefined});
  const { valueByReferer, valueByRecipient} = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getReferralDiscount()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getReferralDiscount(){
    setSpinEnable(true)
    setReferralData([])
    const payload = {
      page: current,
      referent_mobile_number: valueByReferer === undefined ? '' : valueByReferer,
      recipient_mobile_number: valueByRecipient === undefined ? '' : valueByRecipient,
    }
    ads_service.get_referral_discount(payload).subscribe((r) => {
      if(r.response.meta){  
        setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      }
      setSpinEnable(false)
      setReferralData(r.response.discounts_data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    referralDiscountColumns.map(data => {
      columns.push(data)
    })
    setColumns(columns)
  }

  function pagination(page) {
    setCurrent(page)
    setReferralData([])
    setRenderData(false)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function reset() {
    setState({valueByReferer: undefined, valueByRecipient: undefined})
    setDisable(true)
    setCurrent(1)
    setReferralData([])
    setRenderData(false)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  return(
    <div className="layer">
      {referralData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <InputNumber placeholder="Referer Mobile"
                             value={valueByReferer} style={{width:200, margin: 10}}
                             onChange={(e) => onChange(e, 'valueByReferer')}/>
                <InputNumber placeholder="Recipient Mobile"
                             value={valueByRecipient} style={{width:200, margin: 10}}
                             onChange={(e) => onChange(e, 'valueByRecipient')}/>
                <Button disabled={disable} onClick={getReferralDiscount} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
              </Col>
            </Card>
          </Row>
          {referralData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={referralData} columns={columns}/>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default ReferralDiscountCustomer
