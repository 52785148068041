import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, DatePicker,
         Pagination, notification } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {SocietyService} from '../../_services/society';
import { useSelector } from "react-redux";
import Search from '../../components/antd/search';
import MainTable from '../../components/antd/table';
import CampaignModal from '../../components/society/campaignmodal';
import NestedDiscountTable from '../../components/society/nesteddiscounttable';
import {societyCampaignColumns} from '../../components/antd/columns/society';
import {handleDateFormat} from '../../_modules/date';
import {getStaticTime} from '../../_modules/time';
import moment from 'moment';

const society_service = new SocietyService()

function SocietyCampaign() {
  const modalRef = useRef(null);
  const [campaignData, setCampaignData] = useState(false)
  const [societyData, setSocietyData] = useState(false)
  const [societyDataPincode, setSocietyDataPincode] = useState(false)
  const [campaignSlotData, setCampaignSlotData] = useState(false)
  const [campaignStatus, setCampaignStatus] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [mode, setMode] = useState(undefined)
  const [campaignError, setCampaignError] = useState(false)
  const [discount1Error, setDiscount1Error] = useState(false)
  const [discount2Error, setDiscount2Error] = useState(false)
  const [state, setState] = useState({
                            valueByDarkstore: undefined, valueBySociety: undefined,
                            valueByDate: undefined, date: '', valueByStatus: undefined,
                            valueByPincode: undefined});
  const [modalState, setModalState] = useState({
                            campaign_name: undefined, to_date: '',
                            pincode_modal: '',
                            to_time: '', delivery_date: '',
                            society_id: undefined, campaign_status: undefined,
                            min_order: undefined, target_order: undefined,
                            min_order_count: undefined,
                            currentActive: undefined, valueByEndDate: undefined,
                            valueByDeliveryDate: undefined, valueByTime: undefined,
                            campaignSlot: undefined, societyType: undefined,
                            discount_min: undefined, discount_percent: undefined,
                            discount_min1: undefined, discount_percent1: undefined});
  const { campaign_name, to_time, to_date, delivery_date,
          society_id, campaign_status, min_order, target_order, min_order_count,
          currentActive, campaignSlot, societyType, valueByEndDate,
          valueByDeliveryDate, valueByTime, lastEndDate, pincode_modal,
          discount_min, discount_percent, discount_min1,
          discount_percent1} = modalState
  const { valueByDate, valueByDarkstore, valueBySociety, date,
          valueByStatus, valueByPincode } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getSocietyCampaign()
    }
  })

  useEffect(() => {
    getColumns()
    getSociety()
    getCampaignDeliverySLot()
    getCampaignStatus()
  }, [])

  function getSocietyCampaign(){
    setSpinEnable(true)
    setCampaignData([])
    const payload = {
      page: current,
      by_society: valueBySociety === undefined ? '' : valueBySociety,
      by_darkstore: valueByDarkstore === undefined ? '' : valueByDarkstore,
      by_delivery_date: valueByDate === undefined ? '' : valueByDate,
      by_status: valueByStatus === undefined ? '' : valueByStatus,
      by_pincode: valueByPincode === undefined ? '' : valueByPincode
    }
    society_service.get_society_campaign(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setSpinEnable(false)
      setCampaignData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getSociety() {
    const payload = {
      by_pincode: ''
    }
    society_service.get_society_list(payload).subscribe((r) => {
      setSocietyData(r.response.data.societies)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getSocietyByPincode(val) {
    const payload = {
      by_pincode: val
    }
    society_service.get_society_list(payload).subscribe((r) => {
      setSocietyDataPincode(r.response.data.societies)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getCampaignDeliverySLot() {
    society_service.get_campaign_delivery_slot().subscribe((r) => {
      setCampaignSlotData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getCampaignStatus() {
    society_service.get_society_campaign_status().subscribe((r) => {
      setCampaignStatus(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    societyCampaignColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: '',
      dataIndex: '',
      key: '',
      align: 'right',
      width: 220,
      render: (record) => {
        return (
          <div>
            {record.society_campaign_status_details.name !== 'draft' ?
            <Button type="dashed" onClick={() => showModal(record, 'Campaign Details')}>
              View Campaign
            </Button>
            :
            <div>
              <Button type="primary" onClick={() => showModal(record, 'Publish Campaign')}>
                Publish Campaign
              </Button>
              <Button onClick={() => showModalCampaign(true, record, 'Create Campaign Discount')} style={{marginTop: 10}}
                      type="primary" ghost>Add Campaign Discount</Button>
            </div>
            }
          </div>
        )
      }
    })
    setColumns(columns)
  }

  function showModal(data, type) {
    setMode(type)
    if(type === 'Publish Campaign' || type === 'Campaign Details'){
      getSocietyByPincode(data.society_details.pincode)
      setModalState({
        campaign_name: data.campaign_name, to_date: moment(data.to_time),
        to_time: moment(data.to_time), delivery_date: moment(data.delivery_date),
        valueByDeliveryDate: data.delivery_date,
        pincode_modal: data.society_details.pincode,
        valueByEndDate: handleDateFormat(data.to_time).split('-').reverse().join('-'),
        valueByTime: getStaticTime(data.to_time),
        society_id: data.society_details.id, campaign_status: 2,
        campaignSlot: data.delivery_slot_details.id,
        societyType: data.society_type,
        min_order: data.min_order_value !== null ? data.min_order_value : undefined,
        min_order_count: data.min_order_count !== null ? data.min_order_count : undefined,
        target_order: data.targeted_order_count !== null ? data.targeted_order_count : undefined,
        currentActive: data.id})
    }
    modalRef.current.showModal(type, data)
  }

  function showModalCampaign(data, value, type){
    setMode(type)
    if(type === 'Edit Campaign Discount'){
      setModalState({discount_min: data.min_order,
                     discount_percent: data.offer_percentage, campaign_name: value,
                     currentActive: data.id})
    }else{
      setModalState({ campaign_name: value.campaign_name,
                      currentActive: value.id})
    }
    modalRef.current.showModal(type, data)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function onChangeModal(value, type) {
    setDiscount1Error(false)
    setDiscount2Error(false)
    setModalState(prevState =>({...prevState, [type]: value === "" || value === null ? undefined : value}))
    if(type === 'pincode_modal'){
      setModalState(prevState =>({...prevState, [type]: value.split(' ').join(''),
                    society_id: undefined}))
      if(value.length === 6){
        getSocietyByPincode(value)
      }
    }
  }

  function reset() {
    setState({
      valueByDarkstore: undefined,
      valueBySociety: undefined,
      valueByDate: undefined,
      valueByStatus: undefined,
      valueByPincode: undefined,
      date:''})
    setDisable(true)
    setCurrent(1)
    setCampaignData([])
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setCampaignData([])
    setRenderData(false)
  }

  function onCancelModal() {
    setModalState({campaign_name: undefined, to_date: '',
    to_time: '', delivery_date: '',
    society_id: undefined, campaign_status: undefined,
    min_order: undefined, target_order: undefined,
    min_order_count: undefined,
    currentActive: undefined, valueByEndDate: undefined,
    valueByDeliveryDate: undefined, valueByTime: undefined,
    campaignSlot: undefined, societyType: undefined,
    discount_min: undefined,
    discount_percent: undefined, pincode_modal: '',
    discount_min1: undefined,
    discount_percent1: undefined})
    setCampaignError(false)
    setDiscount1Error(false)
    setDiscount2Error(false)
  }

  function modalSubmit() {
    if(mode === 'Publish Campaign' || mode === 'Create Campaign'){
      if(!campaign_name || !to_date || !to_time || !delivery_date ||
         !society_id || min_order === undefined || target_order === undefined ||
         min_order_count === undefined || !campaignSlot || !societyType ||
         pincode_modal.length !== 6){
           if(pincode_modal.length !== 6){
             openNotificationWithIcon('error', "Please enter 6 digit pincode")
           }
           setCampaignError(true)
           openNotificationWithIcon('error', "Please fill all campaign details")
           modalRef.current.stopLoading()
      }else{
        // console.log(valueByDeliveryDate)
        let payload = {
          id: mode === 'Publish Campaign' ? currentActive : undefined,
          body: {
            society_campaign: {
              campaign_name: campaign_name,
              to_time: valueByEndDate + ' ' + valueByTime,
              delivery_date: valueByDeliveryDate.split("-").reverse().join("-"),
              society_id: society_id,
              delivery_slot_id: campaignSlot,
              society_type: societyType,
              min_order_value: min_order,
              min_order_count: min_order_count,
              targeted_order_count: target_order,
            }
          }
        }
        if(mode === 'Publish Campaign'){
          payload.body.society_campaign.society_campaign_status_id = 2
          society_service.update_campaign(payload).subscribe((r) => {
            openNotificationWithIcon('success','Edited Successfully')
            getSocietyCampaign()
            modalRef.current.handleCancel()
          },
          (err)=>{
            console.log(err)
            openNotificationWithIcon('error', 'Missing Fields')
            modalRef.current.stopLoading()
          })
        }else{
          payload.body.society_campaign.society_campaign_discounts_attributes = []
          if(discount_min || discount_percent){
            if(discount_min === undefined
               || discount_percent === undefined){
              openNotificationWithIcon('error', 'Please fill all fields of campaign discount 1')
              setDiscount1Error(true)
              modalRef.current.stopLoading()
              return
            }else{
              payload.body.society_campaign.society_campaign_discounts_attributes.push({
                min_order: discount_min,
                offer_percentage: discount_percent,
              })
            }
          }
          if(discount_min1 || discount_percent1){
            if(discount_min1 === undefined || discount_percent1 === undefined){
              openNotificationWithIcon('error', 'Please fill all fields of campaign discount 2')
              setDiscount2Error(true)
              modalRef.current.stopLoading()
              return
            }else{
              payload.body.society_campaign.society_campaign_discounts_attributes.push({
                min_order: discount_min1,
                offer_percentage: discount_percent1,
              })
            }
          }
          society_service.create_campaign(payload).subscribe((r) => {
            openNotificationWithIcon('success','Created Successfully')
            getSocietyCampaign()
            modalRef.current.handleCancel()
          },
          (err)=>{
            console.log(err)
            openNotificationWithIcon('error', 'Invalid Values')
            modalRef.current.stopLoading()
          })
        }
      }
    }else{
      modalSubmitDiscount()
    }
  }

  function modalSubmitDiscount(){
    if(discount_min === undefined || discount_percent === undefined ){
      openNotificationWithIcon('error', "Please fill all fields")
      modalRef.current.stopLoading()
    }else{
      const payload = {
        id: currentActive,
        body: {
          society_campaign_discount : {
            min_order: discount_min,
            offer_percentage: discount_percent,
          }
        }
      }
      if(mode === 'Edit Campaign Discount'){
        society_service.update_campaign_discount(payload).subscribe((r) => {
          openNotificationWithIcon('success','Edited Successfully')
          getSocietyCampaign()
          modalRef.current.handleCancel()
        },
        (err)=>{
          openNotificationWithIcon('error', err.response.errors[0])
          modalRef.current.stopLoading()
        })
      }else{
        society_service.create_campaign_discount(payload).subscribe((r) => {
          openNotificationWithIcon('success','Created Successfully')
          getSocietyCampaign()
          modalRef.current.handleCancel()
        },
        (err)=>{
          openNotificationWithIcon('error', err.response.errors[0])
          modalRef.current.stopLoading()
        })
      }
    }
  }

  function expandedRow(record){
    return <NestedDiscountTable data={record}
                                showModalCampaign={showModalCampaign}
                                deleteDiscount={deleteDiscount}/>
  }

  function deleteDiscount(record){
    const payload = {
      id: record.id,
    }
    society_service.delete_campaign_discount(payload).subscribe((r) => {
      openNotificationWithIcon('success','Edited Successfully')
      getSocietyCampaign()
    },
    (err)=>{
      openNotificationWithIcon('error', err.response.errors[0])
    })
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  function onChangeDate(date, dateString) {
    // console.log(date)
    setState(prevState =>({...prevState, date: date, valueByDate: dateString}))
    setDisable(false)
    setCurrent(1)
  }

  function onChangeDateModal(date, dateString) {
    setModalState(prevState =>({...prevState, to_date: date, valueByEndDate: dateString}))
  }

  function onChangeDateModalDelivery(date, dateString) {
    setModalState(prevState =>({...prevState, delivery_date: date, valueByDeliveryDate: dateString}))
  }

  function onChangeTimeModal(time, timeString) {
    // console.log(time)
    setModalState(prevState =>({...prevState, to_time: time, valueByTime: timeString}))
  }

  return (
    <div className="layer">
      {campaignData && societyData && campaignStatus ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Darkstore Name" value={valueByDarkstore}
                        onChange={onChange} type="valueByDarkstore"/>
                <Search placeholder="Society Name" value={valueBySociety}
                        onChange={onChange} type="valueBySociety"
                        data={societyData}/>
                <Search placeholder="Status" value={valueByStatus}
                        onChange={onChange} type="valueByStatus"
                        data={campaignStatus}/>
                <Input placeholder="Pincode" onChange={(e) => onChange(e.target.value, 'valueByPincode')}
                       value={valueByPincode} style={{width: 200, margin: 10}}/>
                <DatePicker
                         value={date}
                         onChange={onChangeDate}
                         style={{width: 200, marginLeft: 10, marginRight: 10}}/>
                <Button disabled={disable} onClick={getSocietyCampaign} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Create Campaign')} style={{width: 150, margin:10}}
                        type="primary">Create Campaign</Button>
              </Col>
            </Card>
          </Row>
          {campaignData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={campaignData} columns={columns} expandedRow={expandedRow}/>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <CampaignModal modalSubmit={modalSubmit} ref={modalRef}
                         onChangeModal={onChangeModal}
                         onCancelModal={onCancelModal}
                         onChangeDateModal={onChangeDateModal}
                         onChangeDateModalDelivery={onChangeDateModalDelivery}
                         onChangeTimeModal={onChangeTimeModal}
                         campaignSlotData={campaignSlotData} pincode_modal={pincode_modal}
                         campaignSlot={campaignSlot} societyType={societyType}
                         campaign_name={campaign_name} to_date={to_date}
                         to_time={to_time} delivery_date={delivery_date}
                         society_id={society_id} campaign_status={campaign_status}
                         min_order={min_order} target_order={target_order}
                         societyData={societyDataPincode} min_order_count={min_order_count}
                         discount_min={discount_min} discount_percent={discount_percent}
                         discount_min1={discount_min1} discount_percent1={discount_percent1}
                         discount2Error={discount2Error} discount1Error={discount1Error}
                         campaignError={campaignError}
                         />
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  );
}

export default SocietyCampaign
