import { maskingNumber } from '../../../_modules/masking';

export const valetOrderColumns = [
    {
        title: 'Customer Name',
        dataIndex: 'customer_name',
        key: 'customer_name',
        align: 'center'
    },
    {
        title: 'Mobile No',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return record.mobile_number ? maskingNumber(record.mobile_number) : ''
        }
    },
    {
        title: 'Order No',
        dataIndex: 'order_no',
        key: 'order_no',
        align: 'center'
    },
    {
        title: 'Valet Amt',
        dataIndex: 'valet_amount',
        key: 'valet_amount',
        align: 'center'
    },
    {
        title: 'Valet Plan',
        dataIndex: 'valet_plan',
        key: 'valet_plan',
        align: 'center'
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center'
    },
]

export const valetCustomerColumns = [
    {
        title: 'Customer Name',
        dataIndex: 'customer_name',
        key: 'customer_name',
        align: 'center'
    },
    {
        title: 'Mobile No',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return record.mobile_number ? maskingNumber(record.mobile_number) : ''
        }
    },
    {
        title: 'Valet Amt',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
            return record.valet_product.amount
        }
    },
    {
        title: 'Valet Plan',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
            return record.valet_product.plan_cycle
        }
    },
    {
        title: 'Savings',
        dataIndex: 'savings',
        key: 'savings',
        align: 'center'
    },
    {
        title: 'Start Date',
        dataIndex: 'valet_start_date',
        key: 'valet_start_date',
        align: 'center'
    },
    {
        title: 'End Date',
        dataIndex: 'valet_end_date',
        key: 'valet_end_date',
        align: 'center'
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center'
    },
]