import { Http } from '../_modules/http'

export class DunzoService {

  http = new Http()

  get_dunzo_tickets(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/admin/dunzo_support_ticket'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  create_ticket(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/admin/dunzo_support_ticket'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload, headers)
  }

  close_ticket(id){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/admin/' + id + '/close_ticket'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, {}, headers)
  }

}
