import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification, Switch } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {AppService} from '../../_services/app';
import MainTable from '../../components/antd/table';
import Search from '../../components/antd/search';
import {appColumns} from '../../components/antd/columns/developers';
import {handleDateFormat} from '../../_modules/date';
import {getStaticTime} from '../../_modules/time';
import AppModal from '../../components/developers/app_modal';

const app_service = new AppService()

function AppVersions(){
  const modalRef = useRef(null);
  const [appData, setAppData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [state, setState] = useState({});
  const [modalState, setModalState] = useState({android_forced_version: undefined, android_recommended_version: undefined,
                                                ios_forced_version: undefined, ios_recommended_version: undefined,
                                                features: undefined });
  const {android_forced_version, android_recommended_version, ios_forced_version, 
         ios_recommended_version, features} = modalState

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getAppVersion()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getAppVersion(){
    setSpinEnable(true)
    const payload = {
        page: current
    }
    app_service.get_app_version(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setModalState(prevState =>({...prevState, android_forced_version: r.response.active.android_forced_version,
                                  android_recommended_version: r.response.active.android_recommended_version, 
                                  ios_forced_version: r.response.active.ios_forced_version,
                                  ios_recommended_version: r.response.active.ios_recommended_version}))
      setSpinEnable(false)
      setAppData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    appColumns.map(data => {
      columns.push(data)
    })
    columns.push({
        title: "Created On",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
          return (
            <>
              <div>{handleDateFormat(record.created_at)}</div>
              <div>{getStaticTime(record.created_at)}</div>
            </>
          );
        },
    },
    {
        title: "Updated On",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
          return (
            <>
              <div>{handleDateFormat(record.updated_at)}</div>
              <div>{getStaticTime(record.updated_at)}</div>
            </>
          );
        },
    },
    {
        title: "Activate Version",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
          return (
            !record.is_active ? <Switch checked={record.is_active} onChange={(e) => onChangeActive(e, record)} /> : null
          );
        },
    })
    setColumns(columns)
  }

  function onChangeActive(checked, record){
    const payload = {
        id: record.id
    }
    app_service.activate_version(payload).subscribe((r) => {
        openNotificationWithIcon('success','Activated Successfully')
        getAppVersion()
    },
    (err)=>{
        console.log(err)
        openNotificationWithIcon('error', err.response.errors[0])
    })
  }

  function pagination(page) {
    setCurrent(page)
    setAppData([])
    setRenderData(false)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  function showModal(data, type) {
    modalRef.current.showModal(type, data)
  }

  function onChangeModal(value, type) {
    if(type === 'valueByRadio'){
      setModalState(prevState =>({...prevState, [type]: value}))
    }else{
      setModalState(prevState =>({...prevState, [type]: value === "" ? undefined : value}))
    }
  }

  function onCancelModal() {
    setModalState({android_forced_version: undefined, android_recommended_version: undefined,
                   ios_forced_version: undefined, ios_recommended_version: undefined,
                   features: undefined})
  }


  function modalSubmit(){
    if(!android_forced_version || !android_recommended_version || !ios_forced_version || 
       !ios_recommended_version || !features){
        openNotificationWithIcon('error', "Please fill all fields")
        modalRef.current.stopLoading()
    }else{
        const payload = {
            android_forced_version: android_forced_version,
            android_recommended_version: android_recommended_version,
            ios_forced_version: ios_forced_version,
            ios_recommended_version: ios_recommended_version,
            features: features
        }
        app_service.create_app_version(payload).subscribe((r) => {
            openNotificationWithIcon('success','Created Successfully')
            getAppVersion()
            modalRef.current.handleCancel()
        },
        (err)=>{
            console.log(err)
            modalRef.current.stopLoading()
            openNotificationWithIcon('error', err.response.errors[0])
        })
    }
  }

  return(
    <div className="layer-nofilter">
      {appData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Create App Version')} style={{width: 150, margin:10}}
                        type="primary">Create App Version</Button>
              </Col>
            </Card>
          </Row>
          {appData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={appData} columns={columns}/>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Versions Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <AppModal modalSubmit={modalSubmit} ref={modalRef}
                    onChangeModal={onChangeModal}
                    onCancelModal={onCancelModal}
                    android_forced_version={android_forced_version} 
                    android_recommended_version={android_recommended_version}
                    ios_forced_version={ios_forced_version} 
                    ios_recommended_version={ios_recommended_version} 
                    features={features}/>
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default AppVersions
