import { Http } from '../_modules/http'

export class CustomerService {

  http = new Http()

  get_customer_list(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/orders/dashboard_customer_list?page=' + payload.page +
      '&by_mobile_number=' + payload.valueByMobno + '&by_id=' + payload.valueById
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_customer_reward_list(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/referral_rewards/referral_reward_listing?page='
      + payload.page
      + '&mobile_number=' + payload.valueByMobno;
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_customer_detail(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/orders/' + payload.id + '/customer_details'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_customer_segment(payload) {
    const url = process.env.REACT_APP_SEGMENT_URL +
      'admin/api/v1/customers/' + payload.id + '/segments'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_reviews(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/orders/orders_review?page=' + payload.page +
      '&by_darkstore_id=' + payload.by_darkstore + '&by_delivery_date=' + payload.by_delivery_date +
      '&by_review_rating_type=' + payload.review_rating_type + '&by_issue_type=' + payload.issue_type +
      '&by_order_number=' + payload.order_no
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_issues() {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/orders/review_issue_types'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  update_review(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/orders/add_review_tags'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  get_customer_coupons(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/marketing/customer_discount_listing?mobile_number=' + payload.mobile_number +
      '&page=' + payload.page
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  create_customer_discount(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/marketing/create_customer_discount'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  send_discount_sms(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/marketing/data'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  update_customer_activation(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/marketing/customers/' + payload.id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

}
