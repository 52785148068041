import React, { useEffect, useState } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {LogsService} from '../../_services/logs';
import MainTable from '../../components/antd/table';
import Search from '../../components/antd/search';
import {deliverySlotColumns} from '../../components/antd/columns/logs';
import {handleDateFormat} from '../../_modules/date';
import {getStaticTime} from '../../_modules/time';

const log_service = new LogsService()

function DeliverySlot(){
  const [logData, setLogData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [spinEnableDownload, setSpinEnableDownload] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [state, setState] = useState({valueByDarkstore: undefined});
  const { valueByDarkstore } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getDeliverySlotLogs()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getDeliverySlotLogs(){
    setSpinEnable(true)
    setLogData([])
    const payload = {
      page: current,
      by_darkstore: valueByDarkstore === undefined ? '' : valueByDarkstore,
    }
    log_service.get_delivery_slot_logs(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setSpinEnable(false)
      setLogData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    deliverySlotColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: "Updated On",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => {
        return (
          <>
            <div>{handleDateFormat(record.created_at)}</div>
            <div>{getStaticTime(record.created_at)}</div>
          </>
        );
      },
    })
    setColumns(columns)
  }

  function pagination(page) {
    setCurrent(page)
    setLogData([])
    setRenderData(false)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function reset() {
    setState({valueByDarkstore: undefined})
    setDisable(true)
    setCurrent(1)
    setLogData([])
    setRenderData(false)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  function getDownloadSlot(){
    setSpinEnableDownload(true)
    const payload = {
        by_darkstore: valueByDarkstore === undefined ? '' : valueByDarkstore
    }
    log_service.download_slot_sheet(payload).subscribe((r) => {
      const linkSource = `data:application/octet-stream;base64,${r.response.csv_data}`;
      const downloadLink = document.createElement("a");
      const fileName = `Delivery_Slot_Log_Sheet_${(new Date).toDateString().replace(/ /g,'_')}.csv`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      openNotificationWithIcon('success','Downloaded Successfully')
      setSpinEnableDownload(false)
    }, (err) => {
      setSpinEnableDownload(false)
      openNotificationWithIcon('error','Download Failed')
    })
  }

  return(
    <div className="layer">
      {logData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Darkstore Name" value={valueByDarkstore}
                        onChange={onChange} type="valueByDarkstore"/>
                <Button disabled={disable} onClick={getDeliverySlotLogs} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
                {!spinEnableDownload ?
                    <Button onClick={getDownloadSlot} style={{width: 200, float: 'right', margin: 10}}
                            type="primary">Download Slot Log</Button>
                    :
                    <span style={{margin: 10, float: 'right'}}>
                        <Spin tip="...Download in Progress"/>
                    </span>
                }       
              </Col>
            </Card>
          </Row>
          {logData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={logData} columns={columns}/>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Logs Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default DeliverySlot
