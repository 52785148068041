import { Http } from "../_modules/http";

export class DarkstoreService {
  http = new Http();

  get_darkstores(payload) {
    const url =
      process.env.REACT_APP_API_URL + "admin_dashboard/v1/admin/darkstores?by_name="
      + payload.valueByDarkStore + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_all_darkstores(payload) {
    const url =
      process.env.REACT_APP_API_URL + "admin_dashboard/v1/admin/darkstores"
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_non_virtual_darkstores() {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/admin/non_virtual_darkstores";
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_darkstores_sales(payload) {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/admin/darkstore_wise_summary?date=" + payload.date
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }
  get_darkstores_undelivered(payload) {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/admin/undelivered_darkstore_stats_wise_data?by_city="
      + `${localStorage.getItem('city') ?? ''}`
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_darkstores_deliverytime(payload) {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/admin/darkstores_delivery_time_data?date=" + payload.date
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_darkstores_quality(payload) {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/admin/darkstores_quality_issue?date=" + payload.date
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  update_darkstores(payload) {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/admin/darkstores/" +
      payload.id;
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.put(url, payload.body, headers);
  }

  create_darkstores(payload) {
    const url =
      process.env.REACT_APP_API_URL + "admin_dashboard/v1/admin/darkstores";
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.post(url, payload.body, headers);
  }

  get_location() {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/admin/serviceable_locations";
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  update_location(payload) {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/admin/serviceable_locations/" +
      payload.id;
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.put(url, payload.body, headers);
  }

  create_location(payload) {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/admin/serviceable_locations";
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.post(url, payload.body, headers);
  }

  update_flash_sale(payload) {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/products/all_products_flash_sale";
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.put(url, payload, headers);
  }

  get_darkstores_deliveryslot(payload) {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/stats/darkstore_delivery_slot_stats?date=" +
      payload.date + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_darkstores_refundstats(payload) {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/stats/refund_dashboard_stats?date=" + payload.date
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_darkstores_quality_category() {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/stats/bad_quality_category_stats?by_city=" + `${localStorage.getItem('city') ?? ''}`
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_sku_quality_category(payload) {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/stats/product_wise_bad_quality_stats?page=" +
      payload.page + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_checklist_listing(payload) {
    const url =
      process.env.REACT_APP_API_URL + "admin_dashboard/v1/admin/get_darkstore_checklists?page=" + payload.page
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_checklist_listing_no_pg(payload) {
    const url =
      process.env.REACT_APP_API_URL + "admin_dashboard/v1/admin/get_all_darkstore_checklists"
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  post_checklist(payload) {
    const url =
      process.env.REACT_APP_API_URL + "admin_dashboard/v1/admin/create_darkstore_checklist"
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.post(url, payload, headers);
  }

  update_checklist(payload) {
    const url =
      process.env.REACT_APP_API_URL + "admin_dashboard/v1/admin/update_darkstore_checklist"
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.put(url, payload, headers);
  }

  edit_checklist(payload) {
    const url =
      process.env.REACT_APP_API_URL + "admin_dashboard/v1/admin/darkstore_checklist"
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.put(url, payload, headers);
  }

  get_cancelled_ds(payload) {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/admin/darkstore_wise_cancellation_summary?date=" + payload.date
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_roles() {
    const url =
      process.env.REACT_APP_API_URL + "admin_dashboard/v1/admin/get_roles"
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }
  get_pnp_vendors() {
    const url =
      process.env.REACT_APP_AUTH_URL + "daily_attendance_reports/get_pnp_vendors"
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_darkstore_wise_attendance(payload) {
    const url =
      process.env.REACT_APP_AUTH_URL +
      "daily_attendance_reports/node_wise?date=" + payload.date + "&node_code=" + payload.node_code + "&platform=store_dashboard" + "&roles=" + payload.roles + "&download=false" + "&pnp_vender_id=" + payload.pnp_vender_id + "&by_city=" + `${localStorage.getItem('city') ?? ''}`
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_all_city_wise_attendance(payload) {
    const url =
      process.env.REACT_APP_AUTH_URL +
      "daily_attendance_reports/all_city?start_date=" + payload.start_date + "&end_date=" + payload.end_date + "&roles=" + payload.roles + "&pnp_vender_id=" + "&platform=store_dashboard" + payload.pnp_vender_id
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_city_wise_attendance(payload) {
    const url =
      process.env.REACT_APP_AUTH_URL +
      "daily_attendance_reports/city_wise?date=" + payload.date + "&roles=" + payload.roles + "&pnp_vender_id=" + "&platform=store_dashboard" + payload.pnp_vender_id
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_darkstore_level_view(payload) {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/admin/darkstore_level_view" + payload + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_order_level_view(pathParams) {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/admin/order_level_view?darkstore_id=" + pathParams
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }

  get_sku_level_view(id) {
    const url =
      process.env.REACT_APP_API_URL +
      "admin_dashboard/v1/admin/sku_level_view?order_id=" + id
    let headers = {
      ...JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "application/json",
    };
    return this.http.get(url, headers);
  }


}
