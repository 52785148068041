import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification, Table } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {DarkstoreService} from '../../_services/darkstore';
import {refundStatsColumns} from '../../components/antd/columns/master/darkstores';
import moment from 'moment';
import FrzDownload from '../../_controls/FRZDownload';

const darkstore_service = new DarkstoreService()

function DarkstoreRefundStats(){
  const [darkstoreData, setDarkstoreData] = useState(false)
  const [statsData, setStatsData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [mode, setMode] = useState(undefined)
  const [state, setState] = useState({valueByDeliveryDate: '', date: ''});
  const {valueByDeliveryDate, date } = state

  let url = "line_items_refund_report"

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getDarkstores()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function reset() {
    setState({valueByDeliveryDate: '', date: ''})
    setDisable(true)
    setDarkstoreData([])
    setRenderData(false)
  }

  function getDarkstores(){
    setSpinEnable(true)
    setDarkstoreData([])
    const payload = {
      date: !valueByDeliveryDate ? '' : valueByDeliveryDate,
    }
    darkstore_service.get_darkstores_refundstats(payload).subscribe((r) => {
      setSpinEnable(false)
      setStatsData(r.response.data.cumulative_data)
      setDarkstoreData(r.response.data.darkstore_wise_data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function disabledDate(current){
    // Can select days before today and today
    return current && current > moment().add(0, "day");
  }

  function onChangeDate(date, dateString) {
    setState(prevState =>({...prevState, date: date, valueByDeliveryDate: dateString}))
    setDisable(false)
    setCurrent(1)
  }

  function getColumns(){
    refundStatsColumns.map(data => {
      columns.push(data)
    })
    setColumns(columns)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  return(
    <div className="layer">
      {darkstoreData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <DatePicker value={date} onChange={onChangeDate} placeholder="Date"
                  style={{ width: 200, margin: 10 }} disabledDate={disabledDate} />
                <Button disabled={disable} onClick={getDarkstores} style={{ width: 100, margin: 10 }}
                  type="primary">Search</Button>
                  <Button onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
                <div className="vertical-center" style={{right:0}}>
                 <FrzDownload 
                 setSpinEnable={setSpinEnable} 
                 url={url}
                 startDate={date !== "" ? moment(date).format("YYYY-MM-DD"): date}
                 />
                </div>
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <span style={{padding: 15, lineHeight: 3}}><b>Customer Missing Refund Amt: </b>{statsData.customer_missing_refund_amount}</span>
                <span style={{padding: 15}}><b>Customer Quality Refund Amt: </b>{statsData.customer_quality_refund_amount}</span>
                <span style={{padding: 15}}><b>Missing Issue Count: </b>{statsData.missing_issue_count}</span>
                <span style={{padding: 15}}><b>Order Count: </b>{statsData.order_count}</span>
                <span style={{padding: 15}}><b>Quality Issue Count: </b>{statsData.quality_issue_count}<br/> </span>
                <span style={{padding: 15}}><b>Stock out Issue Count: </b>{statsData.stock_out_issue_count}</span>
                <span style={{padding: 15}}><b>Stock Out Refund Amt: </b>{statsData.stock_out_refund_amount}</span> 
                <span style={{padding: 15}}><b>Refund Sales Loss: </b>{statsData.total_refund_sales_loss_amount}</span> 
                <span style={{padding: 15}}><b>Stock Out Sales Loss: </b>{statsData.total_stock_out_sale_loss}</span> 
                <span style={{padding: 15}}><b>Missing Sales Loss: </b>{statsData.total_missing_sale_loss}</span> 
                <span style={{padding: 15}}><b>Quality Sales Loss: </b>{statsData.total_quality_sale_loss}</span> 
              </Col>
            </Card>
          </Row>
          {darkstoreData.length > 0 ?
            <Row>
              <Col span={24}>
                {/* <MainTable dataSource={darkstoreData} columns={columns}/> */}
                <Card style={{ paddingTop: 5, marginTop: 20 }}>
                    <Row>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                dataSource={darkstoreData}
                                pagination={false}
                                scroll={{ x: 240 }}
                                rowKey="name"
                            />
                        </Col>
                    </Row>
                </Card>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default DarkstoreRefundStats
