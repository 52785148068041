import React, { useDebugValue, useEffect, useState,  } from 'react';
import { Table, Card, Spin } from 'antd';
import { DarkstoreService } from '../../_services/darkstore';
import { openNotificationWithIcon } from '../../_modules/notification.js';
import { overageNestedColumns } from './overageNestedCol';
const darkstore_service = new DarkstoreService();

const initialState = {
  btnDisable: true,
  spinner: false,
  err: false
}

const OverageNestedTable = ({ nestedCol, nestedTableData, showModalOverage, filters  }) => {
  const [ordersColm, setOrdersColm] = useState(false);
  const [updateNestedRow, setUpdateNestedRow] = useState(false);
  const [state, setState] = useState(initialState)
  useEffect(() => {
    if (!updateNestedRow) {
      setUpdateNestedRow(false)
      getOrders(nestedTableData.darkstore_id);
    }

  }, [updateNestedRow]);


function getOrders(id) {
    setState(prevState => ({ ...prevState, spinner: true }))
    setUpdateNestedRow(true);
    let url = `${id}&start_date=${filters.start_date === '' ? '' : filters.start_date}&end_date=${filters.end_date === '' ? '' : filters.end_date}`
    darkstore_service.get_order_level_view(url).subscribe((r) => {
      setState(prevState => ({ ...prevState, spinner: false, }))
      let temp = r?.response?.data.map((item, index) => {
        item.id = index + 1
        return item
      })
      setOrdersColm(temp);
    },
      (err) => {
        setState(prevState => ({ ...prevState, spinner: false, err: true }))
        openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
      }
    );
  }

  return (
    <div>
      {ordersColm ?
      <div>
      {ordersColm.length > 0 && !state.err?
        (<Spin spinning={state.spinner}>
          <Card className="nested-table">
              <Table columns={overageNestedColumns((rowdata) => rowdata, showModalOverage)} 
                pagination={false} rowKey="order_id"
                scroll={{ x: 240, y: 'calc(100vh - 254px)'}} dataSource={ordersColm}
              />
          </Card>
          </Spin>
        )
        : <div className="no-data">{state.err ? 'Backend API System Down' : 'No Data Available'}</div>
        }
        </div>
         :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>}
    </div>
  )
}

export default OverageNestedTable;
