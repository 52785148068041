import React, { useState, useEffect } from 'react';
import { Popconfirm } from 'antd';

function ImsType(props) {
  const [typeData] = useState([{
    type: 'NORMAL', type_name: 'Regular Delivery Executive',
    shift: [
      {
        type: 'PART_TIME', type_name: 'Part Time',
        time: [{ type: '7AM-12PM', type_name: '07:00 AM to 12:00 PM' },
        { type: '8AM-1PM', type_name: '08:00 AM to 01:00 PM' }]
      },
      {
        type: 'FULL_TIME', type_name: 'Full Time',
        time: [{ type: '8AM-6PM', type_name: '08:00 AM to 06:00 PM' },
        { type: '2PM-12AM', type_name: '02:00 PM to 12:00 AM' }]
      }
    ]
  },
  {
    type: 'STAR', type_name: 'Fraazo STAR Delivery Executive',
    shift: [
      {
        type: 'PART_TIME', type_name: 'Part Time',
        time: [{ type: '7AM-12PM', type_name: '07:00 AM to 12:00 PM' },
        { type: '8AM-1PM', type_name: '08:00 AM to 01:00 PM' }]
      },
      {
        type: 'FULL_TIME', type_name: 'Full Time',
        time: [{ type: '8AM-6PM', type_name: '08:00 AM to 06:00 PM' },
        { type: '2PM-12AM', type_name: '02:00 PM to 12:00 AM' }]
      }
    ]
  }
  ])


  return (
    <div className='frz-ims-container-card'>
      <div className='frz-imstype'>
        <div className='frz-ims-text'>
          Regular Delivery Executive
        </div>
        {props.shiftTiming.map((item, index) => {
          if (item.shift.includes("FT")) {
            return (
              <div key={index}>
                {item.shift.includes("FT1") &&
                  <div className='frz-ims-shift'>
                    FULL-TIME
                  </div>}
                <Popconfirm
                  key={index}
                  zIndex={props.isFormChanged ? "1" : "-1"}
                  placement="topRight"
                  title={"Do you want to continue, you have unsaved changes?"}
                  onConfirm={() => props.selectTime(item.shift, "FULL_TIME", "NORMAL", item.id + "Regular")}
                  okText="Yes"
                  cancelText="No"
                >
                  <div key={index}
                    onClick={() => props.isFormChanged ? "" : props.selectTime(item.shift, "FULL_TIME", "NORMAL", item.id + "Regular")}
                    className={`${'frz-ims-time'} 
                          ${props.timeType === item.shift && props.shiftType === "FULL_TIME"
                        && props.type === "NORMAL" ? 'active' : ''}`}>
                    {item.shift_timing}
                  </div>
                </Popconfirm>
              </div>
            )
          } else {
            return (
              <div key={index}>
                {item.shift.includes("PT1") &&
                  <div className='frz-ims-shift'>
                    PART_TIME
                  </div>}
                <Popconfirm
                  key={index}
                  zIndex={props.isFormChanged ? "1" : "-1"}
                  placement="topRight"
                  title={"Do you want to continue, you have unsaved changes?"}
                  onConfirm={() => props.selectTime(item.shift, "PART_TIME", "NORMAL", item.id + "Regular")}
                  okText="Yes"
                  cancelText="No"
                >
                  <div key={index}
                    onClick={() => props.isFormChanged ? "" : props.selectTime(item.shift, "PART_TIME", "NORMAL", item.id + "Regular")}
                    className={`${'frz-ims-time'} 
                          ${props.timeType === item.shift && props.shiftType === "PART_TIME"
                        && props.type === "NORMAL" ? 'active' : ''}`}>
                    {item.shift_timing}
                  </div>
                </Popconfirm>
              </div>
            )
          }
        })}
      </div>
      <div className='frz-imstype'>
        <div className='frz-ims-text'>
          Fraazo STAR Delivery Executive
        </div>
        {props.shiftTiming.map((item, index) => {
          if (item.shift.includes("FT")) {
            return (
              <div key={index}>
                {item.shift.includes("FT1") &&
                  <div className='frz-ims-shift'>
                    FULL_TIME
                  </div>}
                <Popconfirm
                  key={index}
                  zIndex={props.isFormChanged ? "1" : "-1"}
                  placement="topRight"
                  title={"Do you want to continue, you have unsaved changes?"}
                  onConfirm={() => props.selectTime(item.shift, "FULL_TIME", "STAR", item.id + "star")}
                  okText="Yes"
                  cancelText="No"
                >
                  <div key={index}
                    onClick={() => props.isFormChanged ? "" : props.selectTime(item.shift, "FULL_TIME", "STAR", item.id + "star")}
                    className={`${'frz-ims-time'} 
                          ${props.timeType === item.shift && props.shiftType === "FULL_TIME"
                        && props.type === "STAR" ? 'active' : ''}`}>
                    {item.shift_timing}
                  </div>
                </Popconfirm>
              </div>
            )
          } else {
            return (
              <div key={index}>
                {item.shift.includes("PT1") &&
                  <div className='frz-ims-shift'>
                    PART-TIME
                  </div>}
                <Popconfirm
                  key={index}
                  zIndex={props.isFormChanged ? "1" : "-1"}
                  placement="topRight"
                  title={"Do you want to continue, you have unsaved changes?"}
                  onConfirm={() => props.selectTime(item.shift, "PART_TIME", "STAR", item.id + "star")}
                  okText="Yes"
                  cancelText="No"
                >
                  <div key={index}
                    onClick={() => props.isFormChanged ? "" : props.selectTime(item.shift, "PART_TIME", "STAR", item.id + "star")}
                    className={`${'frz-ims-time'} 
                          ${props.timeType === item.shift && props.shiftType === "PART_TIME"
                        && props.type === "STAR" ? 'active' : ''}`}>
                    {item.shift_timing}
                  </div>
                </Popconfirm>
              </div>
            )
          }
        })}
      </div>
      {/* {typeData.map((data, index) => {
        return (
          <div key={index} className='frz-imstype'>
            <div className='frz-ims-text'>{data.type_name}</div>
            {data.shift.map((shift, index) => {
              return (
                <div key={index}>
                  <div className='frz-ims-shift'>
                    {shift.type_name}
                  </div>
                  {shift.time.map((time, index) => {
                    return (
                      <Popconfirm
                        key={index}
                        zIndex={props.isFormChanged ? "1" : "-1"}
                        placement="topRight"
                        title={"Do you want to continue, you have unsaved changes?"}
                        onConfirm={() => props.selectTime(time.type, shift.type, data.type)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <div key={index}
                          onClick={() => props.isFormChanged ? "" : props.selectTime(time.type, shift.type, data.type)}
                          className={`${'frz-ims-time'} 
                          ${props.timeType === time.type && props.shiftType === shift.type
                              && props.type === data.type ? 'active' : ''}`}>
                          {time.type_name}
                        </div>
                      </Popconfirm>
                    )
                  })}
                </div>
              )
            })}
          </div>
        )
      })} */}
    </div>
  )
}

export default ImsType