import React from 'react'
import { Form, Select } from 'antd'
import { tailLayout } from '../_modules/controllayout';

const FRZSelect = ({
  name,
  label,
  isRequired,
  placeholder,
  DropDownData,
  option,
  value,
  mode,
  filterOption,
  isNone,
  isShowSearch,
  width,
  isDataKey,
  setOnChange,
  isDisabledVal,
  selectDisabled,
  optionDisabledKey,
  breakOption,
}) => {
  const { Option } = Select;

  const onChange = (e, type) => {
    if (setOnChange) {
      setOnChange(e, type)
    }
  }

  return (
    <Form.Item
      {...tailLayout}
      name={name}
      label={label}
      rules={isRequired ? [{
        required: true,
        message: `Please input the value of ${label ? label : value}!`,
      }
      ] : []}>
      <Select
        onChange={onChange}
        style={{ width: width }}
        showSearch={isShowSearch}
        disabled={selectDisabled ? selectDisabled : false}
        // filterOption={filterOption ? (input, option) =>
        //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        mode={mode === undefined ? false : mode}
        placeholder={placeholder ? placeholder : label}>
        {isNone ? <Option key='none' value='none'>None</Option> : null}
        {DropDownData?.map((data, index) => {
          return (
            <Option className={breakOption ? "frz-line-break" : ""} key={index} disabled={isDisabledVal ? isDisabledVal.includes(data[optionDisabledKey]) : false} value={value ? data[value] : data}>
              {value ? data[option] : data}
            </Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

export default FRZSelect
