import { Http } from '../_modules/http'

export class CompanyBrandService {

  http = new Http()

  get_company(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/products/company_list_page?page=' + payload.page
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_all_company(){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/products/product_company_list'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  create_company(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/products/create_product_company'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload, headers)
  }

  update_company(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/products/update_product_company?id=' + payload.id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload.body, headers)
  }

  get_player_list(){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/products/type_of_player'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_brands(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/products/brand_list_page?page=' + payload.page
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  create_brands(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/products/create_product_brand'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload, headers)
  }

  update_brands(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/products/update_product_brand?id=' + payload.id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload.body, headers)
  }

}
