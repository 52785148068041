import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Spin, Modal, Button, Row, Col, Tabs, notification, 
         Select, Input, InputNumber, Radio} from 'antd';
import { EditOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import CustomerProfile from './customer_profile';
import CustomerAddress from './customer_address';
import DeliverySlot from './delivery_slot';
import ActiveCart from './active_cart';
import ApplyCoupon from './apply_coupon';
import CheckoutDetails from './checkout_details';
import PaymentMode from './payment_mode';
const { Option } = Select;

const OfflineOrderModal = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOk() {
    setLoading(true)
    props.modalSubmit()
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.onCancelModal();
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={[title, <a href='https://fraazo.com/listing/vegetables' key={2} style={{paddingLeft: 20}} target="_blank">(View Products)</a>]}
          width='95%'
          style={{float: 'right'}}
          className="order"
          onOk={handleOk}
          onCancel={handleCancelModal}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                Submit
            </Button>
          ]}
        >
            <div className="order-modal-container" style={{paddingRight: 0, paddingTop: 0, paddingBottom: 0}}>
              {props.customerData ?
                <div>
                  <Row>
                    <Col md={6} style={{borderRight: '1px solid #f0f0f0', paddingRight: 24}} className="orderSheet-height">
                      <CustomerProfile onChangeModal={props.onChangeModal} editOrder={props.editOrder}
                                       cancelEdit={props.cancelEdit} saveOrder={props.saveOrder}
                                       modalFirstName={props.modalFirstName} modalLastName={props.modalLastName}
                                       modalEmail={props.modalEmail} modalProfile={props.modalProfile}
                                       modalTitle={props.modalTitle} modalSpinner={props.modalSpinner}/>
                      <CustomerAddress modalAddress={props.modalAddress} addressList={props.addressList}
                                       onChangeModalAddress={props.onChangeModalAddress}
                                       modalAddressType={props.modalAddressType}
                                       createNew={props.createNew}
                                       onChangeModal={props.onChangeModal} editOrder={props.editOrder}
                                       saveOrder={props.saveOrder} searchAddress={props.searchAddress}
                                       isServiceable={props.isServiceable} modalFlat={props.modalFlat}
                                       tempAddressSearch={props.tempAddressSearch} modalLandmark={props.modalLandmark}
                                       modalAddressDetail={props.modalAddressDetail} modalPincode={props.modalPincode}
                                       valueByRadioAddress={props.valueByRadioAddress} modalSpinner={props.modalSpinner}/>
                    </Col>
                    <Col md={12} style={{borderRight: '1px solid #f0f0f0', paddingRight: 24, paddingLeft: 24}} className="orderSheet-height">
                      <ActiveCart updateCart={props.updateCart} 
                                  onChangeModal={props.onChangeModal}
                                  productCatalogue={props.productCatalogue}
                                  productDrop={props.productDrop}
                                  addCart={props.addCart} currentCart={props.currentCart}
                                  customerData={props.customerData}
                                  spinEnableCart={props.spinEnableCart}/>
                    </Col>
                    <Col md={6} style={{paddingLeft: 24, paddingRight: 24}} className="orderSheet-height">
                      <ApplyCoupon onChangeModal={props.onChangeModal}
                                   modalCoupon={props.modalCoupon}
                                   updateCart={props.updateCart}
                                   applyCoupon={props.applyCoupon}
                                   modalCouponType={props.modalCouponType} />
                      <DeliverySlot onChangeModal={props.onChangeModal}
                                    modalDate={props.modalDate}
                                    modalSlot={props.modalSlot}
                                    slotData={props.slotData}
                                    updateCart={props.updateCart}
                                    timeData={props.timeData}/>
                      <CheckoutDetails updateCart={props.updateCart} onChangeModal={props.onChangeModal} />
                      <PaymentMode updateCart={props.updateCart}
                                   valueByRadio={props.valueByRadio}
                                   onChangeModal={props.onChangeModal} />
                    </Col>
                  </Row>  
                </div>
                : 
                <div className="spin-center">
                  <Spin tip="...Loading"/>
                </div>
              }    
            </div>
        </Modal>
      : null }
    </div>
  )
})

export default OfflineOrderModal
