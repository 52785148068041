import { Form, Modal } from "antd"
import React, { useEffect, useState } from 'react'
import FRZCustomRadioButon from "../../_controls/FRZCustomRadioButon";
import FRZInput from "../../_controls/FRZInput";
import { formItemLayout } from "../../_modules/controllayout";
import { openNotificationWithIcon } from "../../_modules/notification";
import { GlobalPropertiesService } from "../../_services/global_properties";
const optionData=[
    {
        id: 1,
        title:'True'
    },{
        id:0,
        title:'False'
    }
]

const global_properties_service= new GlobalPropertiesService();
const GlobalPropertyModal =({show,hide,title,editDetails,getProperties})=>{
    const [form] = Form.useForm();
    const [btnDisabled,setBtnDisabled]=useState(false)
    const [JsonFailed,setJsonFailed] =useState(false)
    const [jsonError,setJsonError]=useState(false);
    useEffect(()=>{
        if(title==='Edit'){
            form.setFieldsValue(
                {
                    "name": editDetails?.name,
                    "properties":JSONstringify(editDetails?.properties),
                    "status": editDetails?.status==='inactive'?0:1,
                    "is_visible": editDetails?.is_visible?1:0,
                    "is_editable": editDetails?.is_editable?1:0, 
                }
            )
        }
    },[])
    function JSONstringify(json) {
        if (typeof json != 'string') {
            json = JSON.stringify(json, undefined, '\t');
        }
        var arr = []
        json = json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
            return  match;
        });
        arr.unshift(json);
        return arr[0];
    }
    const validateJSON=(data)=>{
        try{
            JSON.parse(data)
            return true;
        }
        catch(err){
            setJsonError(err.message);
            setJsonFailed(true)
            return false;
        }
    }
    const handleOnSubmit=(values)=>{
        const payload={
            "name": values?.name,
            "properties": values?.properties,
            "status": values?.status?"active":"inactive",
            "is_visible": values?.is_visible?true:false,
            "is_editable": values?.is_editable?true:false,
        }
        if(validateJSON(payload.properties)){
            if(title==='Create'&& !JsonFailed && !jsonError){
                payload.properties=JSON.parse(payload.properties)
                global_properties_service.create_properties(payload).subscribe((r) => {
                  openNotificationWithIcon('success','successfully added the property')
                  hide(false)
                  getProperties();
                },
                (err)=>{
                    console.log(err)
                    if(err?.response?.is_editable){
                        openNotificationWithIcon('error',err?.response?.is_editable[0]+"   is_editable ")
                    }
                    else if(err?.response?.name){
                        openNotificationWithIcon('error',err?.response?.name[0])
                    }
                    else{
                        openNotificationWithIcon('error',err?.response+"Something went wrong")
                    }

                    setBtnDisabled(false)
                    
                })
            }else if(title==='Edit' && !JsonFailed && !jsonError){
                payload.id=editDetails.id;
                payload.properties=JSON.parse(payload.properties)
                global_properties_service.update_properties(payload).subscribe((r) => {
                    openNotificationWithIcon('success','successfully updated the property')
                    hide(false)
                    getProperties()
                  },
                  (err)=>{
                      console.log(err)
                      openNotificationWithIcon('error','something went wrong')
                      setBtnDisabled(false)
                    })
            }
        }else{
            openNotificationWithIcon('error','please make sure you have entered proper details')
            setBtnDisabled(false)
        }
    }
      const handleCancel = () => {
        hide(false);
      };
      const onChangeProperty=(value)=>{
        if(validateJSON(value)){
            setJsonFailed(false)
            setJsonError(false)
        }else{
            setJsonFailed(true)
        }
      }
    return(
        <>
        <Modal 
        title={`${title} Global Property Modal`}
        visible={show} 
        okText="Submit"
        okButtonProps={{ loading: btnDisabled }}
        width={'75%'}
        onOk={
            () => {
                setBtnDisabled(true)
                form
                  .validateFields()
                  .then(values => {
                    handleOnSubmit(values)
                  })
                  .catch(info => {
                    setBtnDisabled(false)
                    console.log('Validate Failed:', info);
                  });
              }
        } 
        onCancel={handleCancel}>
        <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">
            <div className='global-property-form-container'>
                <div>
                    <FRZInput name="name"  isRequired={true} label="Property Name" placeholder="Property Name" width={200} />
                    
                    <FRZCustomRadioButon  name="status"label="Status"
                    isRequired={true}
                    optionData={optionData}
                    value={'id'}  option={'title'}/>
                    
                    <FRZCustomRadioButon  name="is_editable"label="Is Editable"
                    isRequired={true}
                    optionData={optionData}
                    value={'id'}  option={'title'}/>
                    
                    <FRZCustomRadioButon name="is_visible"label="Is Visible"
                    isRequired={true}
                    optionData={optionData}
                    value={'id'}  option={'title'}/>
                </div>
                <div>
                    <FRZInput customClass={JsonFailed ? "wcl-input-error frz-h-400":'frz-h-400'} name="properties" isRequired={true} label="Properties" 
                    placeholder="Properties"
                    isTextArea={true} 
                    setOnChange={(e) => onChangeProperty(e.target.value)}/>
                    <span className='wcl-input-error-msg frz-ml-30p'>{jsonError}</span>
                </div>
                
            </div>
            </Form>
            
      </Modal>
        </>
    )
}
export default GlobalPropertyModal