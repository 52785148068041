import React, { useState } from 'react';
import { Button, notification } from 'antd'
//SERVICE
import {DownloadService} from '../_services/downloads';

const download_service = new DownloadService()

function FrzDownload({url, startDate, endDate, startsWith, name }) {

    const [loading, setLoading] = useState(false)

    function openNotificationWithIcon(type, msg) {
        notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
    };

    function getDownloadList(){
        let downloadFunc = endDate ? 'get_downloads_datewise' : startDate ? 'get_downloads_datewise_refund' : startsWith ? 'get_downloads_custom' : 'get_downloads'
        setLoading(true)
        const payload = {
            start_date: startDate,
            end_date: endDate,
            url: url
        }
        download_service[downloadFunc](payload, startsWith).subscribe((r) => {
            var myVar = setInterval(() => {
                download_service.download_reports_job_fraazo(r.response.data.id).subscribe(res => {
                    if(res.response.csv_data.status === 'success'){
                        clearInterval(myVar)
                        window.open(res.response.csv_data.url, '_blank')
                        openNotificationWithIcon('success','Downloaded Successfully')
                        setLoading(false)
                    }
                }, (err) => {
                    setLoading(false)
                    clearInterval(myVar)
                    openNotificationWithIcon('error','Download Failed')
                })
            }, 8000);
            // const linkSource = `data:application/octet-stream;base64,${r.response.csv_data}`;
            // const downloadLink = document.createElement("a");
            // const fileName = `${downloadOption}_${(new Date).toDateString().replace(/ /g,'_')}.csv`;
            // downloadLink.href = linkSource;
            // downloadLink.download = fileName;
            // downloadLink.click();
            // openNotificationWithIcon('success','Downloaded Successfully')
            // setSpinEnable(false)
        }, (err) => {
            setLoading(false)
            openNotificationWithIcon('error','Download Failed')
        })
    }

    return (
        <Button loading={loading} onClick={getDownloadList} style={{ width: 'fit-content', margin: 10 }}
            type="primary">Download {name}</Button>
    );
}

export default FrzDownload;
