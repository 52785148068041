import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import MainTable from '../antd/table';
import {
  Row, Col, Table, Button, InputNumber, Select, Checkbox, Tooltip,
  notification, Input, Spin, DatePicker
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { deliveryItemColumns } from '../antd/columns/orders';
import { OrderService } from '../../_services/orders'
import RefundTable from '../RefundChange/RefundTable';


const { TextArea } = Input;
const { Option } = Select;

const order_service = new OrderService()

const DeliveryItems = forwardRef((props, ref) => {
  const [columns, setColumns] = useState([])
  const [refundColumns, setRefundColumns] = useState([])
  const [valueByReviews, setValueByReviews] = useState(["quality"])
  const [refundMode, setRefundMode] = useState(true)
  const [reviewMode, setReviewMode] = useState(true)
  const [replacementMode, setReplacementMode] = useState(true)
  const [feedback, setFeedback] = useState(undefined)
  const [state, setState] = useState({})
  const [checked, setChecked] = useState(false)
  const [refundType, setRefundType] = useState(undefined)
  const [updateCols, setUpdateCols] = useState(false)
  const [manualTable, setManualTable] = useState(false)
  //states for delivery date and time slot
  const [deliveryDate, setDeliveryDate] = useState(undefined)
  const [deliverySlots, setDeliverySlots] = useState([])
  const [timeData, setTimeData] = useState([])
  const [modalSlot, setModalSlot] = useState(undefined)
  const [escalateLoading, setEscalateLoading] = useState(false)
  //
  const [refund,setRefund]=useState(false)
  // changing the column title as per the modal type
  let reasonColumnTitle = refundMode ? "Replacement Reason" : "Refund Reason";
  let qtyColumnTitle = refundMode ? "Replacement Qty" : "Refund Qty";


  useImperativeHandle(ref, () => ({
    cancelRefundClose() {
      cancelRefund()
    },
  }));

  useEffect(() => {
    setModalSlot(undefined)
    const getTimeSlot = () => {
      for (var i = 0; i < deliverySlots.length; i++) {
        if (deliverySlots[i].date === deliveryDate) {
          setTimeData(deliverySlots[i].time_slot)
          break
        }
      }
    }
    getTimeSlot();
  }, [deliveryDate])

  useEffect(() => {
    getColumns()
  }, [])


  function getColumns() {
    let temp = []
    deliveryItemColumns.map(data => {
      temp.push(data)
    })
    temp.splice(2, 0, {
      title: 'Refunded Qty',
      dataIndex: '',
      key: '',
      align: 'center',
      width: 120,
      render: (record) => {
        let refund_qty = 0

        record.refund_details.map(data => {
          refund_qty = data.refund_qty + refund_qty
        })
        return (
          <div>
            {record.combo_skus?.length > 0 ? 
              <div>{record.combo_refunded_units}</div>
            : 
              <div>
                {refund_qty} {record.refunded_unit_line_items_count ? ' (' + record.refunded_unit_line_items_count + ') ' : null}
                  {refund_qty > 0 ?
                    <Tooltip title="Refund details can be found in refund items tab">
                      <span style={{ paddingLeft: 5, fontSize: 10 }}><InfoCircleOutlined /></span>
                    </Tooltip>
                  : null}
              </div>
            }
          </div>
        )
      }
    })
    setColumns(temp)
  }

  function onChangeCredit(e) {
    setChecked(e.target.checked)
  }

  useEffect(() => {
    if (updateCols) {
      refundColumns.splice(0, 1, {
        title: qtyColumnTitle,
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (record.is_a_refundable_list ? 
            <Select placeholder="Qty" value={state['qty' + record.id]}
                    disabled={!record.is_refundable}
                    onChange={(e) => onChange(e, 'refundQty', record.id)}
                    style={{ width: 70 }}>
              {Array(record.max_refundable_units).fill().map((data, index) => {
                return <Option key={index+1} value={index+1}>{index+1}</Option>
              })}
            </Select>
          :
            <InputNumber placeholder="Qty"
              value={state['qty' + record.id]}
              disabled={!record.is_refundable}
              onChange={(e) => onChange(e, 'refundQty', record.id)}
              style={{ width: 50 }} />
          )
        }
      })
      refundColumns.splice(1, 1, {
        title: reasonColumnTitle,
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (
            <>
              <Select style={{ width: 180 }}
                placeholder="Select Reason"
                value={state['reason' + record.id]}
                disabled={!record.is_refundable}
                onChange={(e) => onChange(e, 'reason', record.id)}>
                {props.refundReason.map(data => {
                  return (
                    <Option key={data.id} value={data.id}>
                      {data.name}
                    </Option>
                  )
                })}
                <Option key='no_refund' value='no_refund'>No Refund</Option>
              </Select>
            </>
          )
        }
      })
      refundColumns.splice(2, 1, {
        title: 'Bad Quality Reason',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (
            <div>
              {props.orderDetail.map(data => {
                return (
                  state['reason' + record.id] === 2 && data.id === record.id ?
                    <Select style={{ width: 180 }} key={record.id}
                      placeholder="Select Reason"
                      disabled={!record.is_refundable}
                      value={state['reviewreason' + record.id]}
                      onChange={(e) => onChangeReviewReason(e, 'reviewreason', data.id)}>
                      {props.reviewReason.map(data => {
                        return (
                          <Option key={data.id} value={data.id}>
                            {data.display_name}
                          </Option>
                        )
                      })}
                    </Select>
                    : null
                )
              })}
            </div>
          )
        }
      })
      setRefundColumns(refundColumns)
      setManualTable(true)
    }
  }, [updateCols])


  // GET API FOR DELIVERY DATE AND TIME SLOT
  function getDeliverySlots() {
    const payload = {
      delivery_id: props.orderDetails.delivery.id,
    }
    order_service.get_delivery_slots(payload).subscribe((r) => {
      setDeliverySlots(r.response.data)
    },
      (err) => {
        openNotificationWithIcon('error', err.response.errors[0])
        console.log(err)
      })
  }
  //SUBMIT REPLACEMENT
  function modalSubmitReplacement(list) {
    const payload = {
      delivery_id: props.orderDetails.delivery.id,
      refund_details: list,
      delivery_date: deliveryDate,
      delivery_slot_id: modalSlot
    }
    order_service.create_order_replacement(payload).subscribe((r) => {
      // refundRef.current.handleCancel()
      openNotificationWithIcon('success', 'Replacement Created Successfully')
      props.setOrderDetail(false)
      props.getOrderDetail(props.data.id)
    },
      (err) => {
        openNotificationWithIcon('error', err.response.errors[0])
        console.log(err)
      })
  }


  function onChange(value, type, id) {
    if (type === 'refundQty') {
      let stateTypeQty = 'qty' + id
      setState(prevState => ({ ...prevState, [stateTypeQty]: value === "" ? undefined : value }))
    } else {
      let stateTypeReason = 'reason' + id
      let reviewReason = 'reviewreason' + id
      setState(prevState => ({ ...prevState, [stateTypeReason]: value === "" ? undefined : value, [reviewReason]: undefined }))
    }
    if (type !== 'refundQty') {
      setUpdateCols({ ['reason' + id]: value })
      setManualTable(false)
    } else {
      setUpdateCols({ ['qty' + id]: value })
      setManualTable(false)
    }
    setColumns(columns)
  }

  function onChangeReview(value, type, id) {
    if (type === 'review') {
      let stateTypeReview = 'review' + id
      setState(prevState => ({ ...prevState, [stateTypeReview]: value === "" ? undefined : value }))
    } else {
      let stateTypeReason = 'reason' + id
      setState(prevState => ({ ...prevState, [stateTypeReason]: value === "" ? undefined : value }))
    }
    setColumns(columns)
  }

  function onChangeReviewReason(value, type, id) {
    let stateTypeReason = 'reviewreason' + id
    setState(prevState => ({ ...prevState, [stateTypeReason]: value === "" ? undefined : value }))
    setColumns(columns)
  }

  function createRefund(type) {
    setRefund(true)
    if (type === "Replacement") {
      setRefund(false)
      getDeliverySlots()
    }

    props.orderDetail.map(data => {
      let stateTypeQty = 'qty' + data.id
      let stateTypeReason = 'reason' + data.id
      setState(prevState => ({ ...prevState, [stateTypeQty]: undefined, [stateTypeReason]: undefined }))
    })
    if (type === "Replacement") {
      setReplacementMode(false)
      setRefund(false)
    } else {
      setRefundMode(false)
    }
    setManualTable(true)
    refundColumns.push({
      title: type + ' Qty',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (record.is_a_refundable_list ? 
            <Select placeholder="Qty" value={state['qty' + record.id]}
                    disabled={!record.is_refundable}
                    onChange={(e) => onChange(e, 'refundQty', record.id)}
                    style={{ width: 70 }}>
              {Array(record.max_refundable_units).fill().map((data, index) => {
                return <Option key={index+1} value={index+1}>{index+1}</Option>
              })}
            </Select>
          :
            <InputNumber placeholder="Qty"
              value={state['qty' + record.id]}
              disabled={!record.is_refundable}
              onChange={(e) => onChange(e, 'refundQty', record.id)}
              style={{ width: 50 }} />
        )
      }
    },
      {
        title: type + ' Reason',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (
            <>
              <Select style={{ width: 180 }}
                placeholder="Select Reason"
                value={state['reason' + record.id]}
                disabled={!record.is_refundable}
                onChange={(e) => onChange(e, 'reason', record.id)}>
                {props.refundReason.map(data => {
                  return (
                    <Option key={data.id} value={data.id}>
                      {data.name}
                    </Option>
                  )
                })}
                <Option key='no_refund' value='no_refund'>No Refund</Option>
              </Select>
            </>
          )
        }
      })
    deliveryItemColumns.map(data => {
      refundColumns.push(data)
    })
    refundColumns.splice(2, 0, {
      title: type + ' Qty',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        let refund_qty = 0
        record.refund_details.map(data => {
          refund_qty = data.refund_qty + refund_qty
        })
        return (
          <div>
            {refund_qty}
            {refund_qty > 0 ?
              <Tooltip title="Refund details can be found in refund items tab">
                <span style={{ paddingLeft: 5, fontSize: 10 }}><InfoCircleOutlined /></span>
              </Tooltip>
              : null}
          </div>
        )
      }
    })
    setRefundColumns(refundColumns)
  }

  function escalate(id) {
    setEscalateLoading(true)
    order_service.escalate_refund(id).subscribe((r) => {
      props.getOrderDetail(props.data.id)
      setEscalateLoading(false)
      openNotificationWithIcon('success', 'Escalated Successfully')
    },
      (err) => {
        setEscalateLoading(false)
        openNotificationWithIcon('error', err?.response?.errors?.[0] ?? 'API Error')
        console.log(err)
      })
  }

  function createReviews() {
    setRefund(false)
    props.orderDetail.map(data => {
      let stateTypeReview = 'review' + data.id
      let stateTypeReason = 'reason' + data.id
      setState(prevState => ({ ...prevState, [stateTypeReview]: undefined, [stateTypeReason]: undefined }))
    })
    setReviewMode(false)
    setManualTable(true)
    refundColumns.push(
      {
        title: '',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (
            <Select style={{ width: 150, display: 'none' }}
              placeholder="Select Review"
              value={state['review' + record.id]}
              onChange={(e) => onChangeReview(e, 'review', record.id)}>
              {/* {props.reviewParams.map(data => {
                 return(
                   <Option key={data.id} value={data.id}>
                     {data.display_name}
                   </Option>
                 )
               })} */}
              <Option key={12} value={12}>Bad Quality</Option>
            </Select>
          )
        }
      },
      {
        title: 'Reason',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
          return (
            <Select style={{ width: 180 }}
              placeholder="Select Reason"
              value={state['reason' + record.id]}
              onChange={(e) => onChangeReview(e, 'reason', record.id)}>
              {props.reviewReason.map(data => {
                return (
                  <Option key={data.id} value={data.id}>
                    {data.display_name}
                  </Option>
                )
              })}
              <Option key='no_reason' value='no_reason'>No Reason</Option>
            </Select>
          )
        }
      })
    deliveryItemColumns.map(data => {
      refundColumns.push(data)
    })
    refundColumns.splice(2, 0, {
      title: 'Refunded Qty',
      dataIndex: '',
      key: '',
      align: 'center',
      width: 120,
      render: (record) => {
        let refund_qty = 0

        record.refund_details.map(data => {
          // refund_qty = data.refunded_qty_in_pieces ? data.refunded_qty_in_pieces + refund_qty : data.refund_qty + refund_qty
          refund_qty = data.refund_qty + refund_qty
        })
        return (
          <div>
            {record.combo_skus?.length > 0 ? 
              <div>{record.combo_refunded_units}</div>
            : 
              <div>
                {refund_qty} ({record.refunded_unit_line_items_count})
                {refund_qty > 0 ?
                  <Tooltip title="Refund details can be found in refund items tab">
                    <span style={{ paddingLeft: 5, fontSize: 10 }}><InfoCircleOutlined /></span>
                  </Tooltip>
                : null}
              </div>
            }
          </div>
        )
      }
    })
    setRefundColumns(refundColumns)
  }
  function checkSubLineItem(id){
    let items=props.orderDetails.delivery_line_items;
    let flag=true;
    for(let i=0;i<items.length;i++){
        if(items[i].id===id){
            flag=false
        }
    }
    return flag;
  }
  function submitRefund() {
    let refundList = []
    // console.log('props',props.orderDetails.refundable_line_items)
    for (var i = 0; i < props.orderDetails.refundable_line_items.length; i++) {
      if (state['qty' + props.orderDetails.refundable_line_items[i].id]) {
        if (state['reason' + props.orderDetails.refundable_line_items[i].id] !== 'no_refund') {
          if (state['reason' + props.orderDetails.refundable_line_items[i].id]) {
            refundList.push({
              id: props.orderDetails.refundable_line_items[i].id,
              refund_qty: state['qty' + props.orderDetails.refundable_line_items[i].id],
              refund_reason_id: state['reason' + props.orderDetails.refundable_line_items[i].id],
              refund_detail_reason_id: !state['reviewreason' + props.orderDetails.refundable_line_items[i].id] ? null : state['reviewreason' + props.orderDetails.refundable_line_items[i].id],
              refund_for:checkSubLineItem(props.orderDetails.refundable_line_items[i].id)?"DeliverySubLineItem":"DeliveryLineItem"
            })
          } else {
            openNotificationWithIcon('error', "Please Select Reason For Refund Item " + props.orderDetails.refundable_line_items[i].product.name)
            return
          }
        }
      }
    }
    // props.orderDetail.map(data =>{
    //   // console.log(state['qty'+data.id])
    //   if(state['qty'+data.id]){
    //     if(state['reason'+data.id] !== 'no_refund'){
    //       if(state['reason'+data.id]){
    //         refundList.push({
    //           id: data.id,
    //           refund_qty: state['qty'+data.id],
    //           refund_reason_id: state['reason'+data.id],
    //         })
    //       }else{
    //         openNotificationWithIcon('error', "Please Select Reason For Refund Item "+data.product.name)
    //         return
    //       }
    //     }
    //   }
    // })
    // console.log("refundList----",refundList)
    if (!refundType) {
      openNotificationWithIcon('error', "Please Select Refund Type")
      return
    }
    if (refundList.length > 0) {
      props.modalSubmit(refundList, checked, refundType)
    } else {
      openNotificationWithIcon('error', "Please Add Refund Details & Select Valid Reason")
    }
  }

  function submitReview() {
    let reviewList = []
    for (var i = 0; i < props.orderDetail.length; i++) {
      // if(state['review'+props.orderDetail[i].id]){
      if (state['reason' + props.orderDetail[i].id] !== 'no_reason') {
        if (state['reason' + props.orderDetail[i].id]) {
          reviewList.push({
            delivery_line_item_id: props.orderDetail[i].id,
            // review_parameter_id: state['review'+props.orderDetail[i].id],
            review_parameter_id: 12,
            review_detail_reason_id: state['reason' + props.orderDetail[i].id],
          })
        }
        // else{
        //   openNotificationWithIcon('error', "Please Select Reason For the Item " + props.orderDetail[i].product.name)
        //   return
        // }
      }
      // }
    }
    if (valueByReviews.length == 0) {
      openNotificationWithIcon('error', "Please Select 1 Issue")
      return
    }
    if (reviewList.length > 0) {
      props.modalSubmitReviews(reviewList, feedback, valueByReviews)
    } else {
      // console.log(reviewList)
      openNotificationWithIcon('error', "Please Add Valid Reason for atleast 1 item")
    }
  }

  function onChangeIssues(values) {
    setValueByReviews(values)
  }

  function cancelRefund() {
    setReplacementMode(true)
    setModalSlot(undefined)
    setDeliveryDate(undefined)
    setTimeData([])
    setRefundMode(true)
    setChecked(false)
    setRefundType(undefined)
    setUpdateCols(false)
    setManualTable(false)
    setRefundColumns([])
    setState({})
    setReviewMode(true)
    setValueByReviews(["quality"])
    setFeedback(undefined)
    setRefund(false)
  }
  function onChangeFeedback(value) {
    setFeedback(value)
  }

  function openNotificationWithIcon(type, msg) {
    notification[type]({ message: type.toUpperCase(), duration: 3, description: msg });
  };

  function onChangeType(e) {
    setRefundType(e)
  }

  function submitReplacement() {
    let replacementList = []
    for (var i = 0; i < props.orderDetail.length; i++) {
      if (state['qty' + props.orderDetail[i].id]) {
        if (state['reason' + props.orderDetail[i].id] !== 'no_refund') {
          if (state['reason' + props.orderDetail[i].id]) {
            replacementList.push({
              id: props.orderDetail[i].id,
              refund_qty: state['qty' + props.orderDetail[i].id],
              refund_reason_id: state['reason' + props.orderDetail[i].id],
              refund_detail_reason_id: !state['reviewreason' + props.orderDetail[i].id] ? null : state['reviewreason' + props.orderDetail[i].id]
            })
          } else {
            openNotificationWithIcon('error', "Please Select Reason For Replacement Item " + props.orderDetail[i].product.name)
            return
          }
        }
      }
    }
    if (replacementList.length > 0) {
      if (deliveryDate !== undefined && modalSlot !== undefined) {
        modalSubmitReplacement(replacementList)
      } else {
        openNotificationWithIcon('error', "Please Add Delivery Date & Time Slot")
      }
    } else {
      openNotificationWithIcon('error', "Please Add Replacement Details & Select Valid Reason")
    }
  }

  return (
    <div>
      <Row>
        <Col span={24}>
          {refundMode && reviewMode && replacementMode ?
            <div>
              <Button style={{ width: 130 }} type="primary"
                onClick={(e) => createRefund("Refund")}
                disabled={props.orderDetails.order_status !== 'delivered' || !props.operations || !props.orderDetails.can_refund}>
                Create Refund
              </Button>

              <Button style={{ width: 152, marginLeft: 20 }} type="primary"
                onClick={(e) => createRefund("Replacement")}
                disabled={props.orderDetails.order_status !== 'delivered' || !props.operations}>
                Create Replacement
              </Button>

              <Button style={{ width: 130, marginLeft: 20 }} type="primary"
                onClick={createReviews}
                disabled={props.orderDetails.order_status !== 'delivered' || !props.operations || props.orderDetails.delivery?.review?.order_review_details?.ratings > 0}>
                Add Reviews
              </Button>
              <Button style={{ width: 130, marginLeft: 20 }} type="primary" loading={escalateLoading}
                onClick={() => escalate(props.orderDetails.refund_request_id)}
                disabled={props.orderDetails.order_status !== 'delivered' || !props.operations
                  || !props.orderDetails.can_escalate || !props.orderDetails.refund_request_id}>
                {props.orderDetails?.escalate_label ?? 'Escalate'}
              </Button>
            </div>
            :
            <div>
              {/* REFUND MODE */}
              {!refundMode && refund?
                <div>
                  <Button style={{ width: 130 }} type="primary"
                    onClick={submitRefund}>
                    Save
                  </Button>
                  <Button style={{ width: 130, marginLeft: 20 }} type="primary"
                    onClick={cancelRefund} ghost>
                    Cancel
                  </Button>
                  <Select style={{ width: 200, marginLeft: 40 }}
                    placeholder="Select Refund Type"
                    onChange={(e) => onChangeType(e)}>
                    <Option value='credit'>Credit</Option>
                    <Option value='original_source'>Original Source</Option>
                  </Select>
                  <Checkbox
                    checked={checked}
                    onChange={onChangeCredit}
                    style={{ float: 'right', paddingTop: 7 }}
                  >
                    Add ₹ 50 Extra Credit
                  </Checkbox>
                  <div style={{ paddingTop: 20 }}><b> Note: </b>Razorpay amount will be credited into account in 5-7 working days</div>
                </div>
                : null}

              {/* REPLACEMENT MODE */}
              {!replacementMode ?
                <div>
                  {/* submit the replacement */}
                  <Button style={{ width: 130 }} type="primary"
                    onClick={submitReplacement}>
                    Save
                  </Button>

                  <Button style={{ width: 130, marginLeft: 20, marginRight: 10 }} type="primary"
                    onClick={cancelRefund} ghost>
                    Cancel
                  </Button>

                  {/* delivery date */}
                  <span style={{ marginRight: 10 }}>
                    <Select style={{ width: 190 }} value={deliveryDate}
                      placeholder="Select Date"
                      onChange={(e) => setDeliveryDate(e)}>
                      {deliverySlots.map((data, index) => {
                        return (
                          (data.time_slot.length > 0 ?
                            <Option key={index} value={data.date}>{data.date}</Option>
                            : null)
                        )
                      })}
                    </Select>
                  </span>

                  {/* time slot */}
                  <span>
                    <Select style={{ width: 190 }} value={modalSlot}
                      placeholder="Select Time"
                      disabled={!timeData.length}
                      onChange={(e) => setModalSlot(e)}>
                      {timeData.length > 0 ?
                        (timeData.filter(e => e.is_active).map(data => {
                          return (
                            <Option key={data.id} value={data.id}
                              disabled={!data.is_active || data.is_full}>
                              {data?.slot_string ?? data.from_time + ' to ' + data.to_time + ' ' + data.am_pm}
                            </Option>
                          )
                        }))
                        : null}
                    </Select>
                  </span>
                </div>
                : null}

              {/* REVIEW MODE */}
              {!reviewMode ?
                <div>
                  <Button style={{ width: 130 }} type="primary"
                    onClick={submitReview}>
                    Save
                  </Button>
                  <Button style={{ width: 130, marginLeft: 20 }} type="primary"
                    onClick={cancelRefund} ghost>
                    Cancel
                  </Button>
                  <div>
                    <span><b>Issues : </b>
                      <Select mode="multiple" style={{ width: 300, marginLeft: 10 }} value={["quality"]}
                        placeholder="Select Issues" onChange={onChangeIssues} disabled={true}>
                        {props.issueParams.map((data, index) => {
                          return (
                            <Option value={data.name} key={index}>{data.name}</Option>
                          )
                        })}
                      </Select>
                    </span>
                    <TextArea placeholder="Feedback (Optional)" autoSize={{ minRows: 2, maxRows: 3 }}
                      value={feedback} style={{ width: 290, marginLeft: 20 }}
                      onChange={(e) => onChangeFeedback(e.target.value)} />
                  </div>
                </div>
                : null}
            </div>
          }

          {/* RENDER TABLE AS PER THE FORM MODE */}
          <div style={{ marginTop: 20 }}>
            {!refundMode || !reviewMode || !replacementMode ?
              (props.orderDetail.length > 0 && !refund ?
                (manualTable &&!refund?
                  <Table columns={refundColumns} dataSource={props.orderDetail}
                    pagination={false} scroll={{ x: 240 }}
                    rowKey="id" />
                  : <div className="spin-center">
                    <Spin tip="...Loading" />
                  </div>)
                :
                null
              )
              : (props.orderDetail.length > 0 ?
                <Table columns={columns} dataSource={props.orderDetail}
                  pagination={false} scroll={{ x: 240 }}
                  rowKey="id" />
                :
                null
              )
            }
            {/* {props.orderDetail.length > 0 ?
              <Table columns={columns} dataSource={props.orderDetail}
                     pagination={false} scroll={{ x: 240 }}
                     rowKey="id"/>
            :
              null
            } */}
          </div>
          <div>
            {refund ? (
              <RefundTable  states={state} setStates={setState} props={props}  dataSource={props}/> 
            ):null}
          </div>
        </Col>
      </Row>
    </div>
  )
})

export default DeliveryItems