import React, {useState, useEffect} from 'react';
import {Table, Spin, Card, Icon, Tooltip, Button} from 'antd';
import {nestedOrderColumns} from '../antd/columns/customers';
import {OrderService} from '../../_services/orders';
const order_service = new OrderService()

function NestedOrderTable(props) {
  const [nestedOrder, setNestedOrder] = useState(false)
  const [columns, setColumns] = useState([])

  useEffect(() => {
    getOrderDetail()
    getColumns()
  }, [])

  function getOrderDetail(){
    const payload = {
      id: props.data,
    }
    order_service.get_line_items(payload).subscribe((r) => {
      setNestedOrder(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    nestedOrderColumns.map(data => {
      columns.push(data)
    })
    setColumns(columns)
  }

  return(
    <Card className="nested-table">
      {nestedOrder ?
        <div>
          {nestedOrder.length > 0 ?
            <Table columns={columns} pagination={false} rowKey="id"
                   dataSource={nestedOrder}/>
           :
            <div className="no-data-nested">No Order Detail Found</div>
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </Card>
  )
}

export default NestedOrderTable;
