import React from 'react'
import { humanize } from "../../../_modules/humanize"
import { handleDateFormat } from "../../../_modules/date"
import { getStaticTime } from "../../../_modules/time"

export const grnColumns = [
    {
        title: 'Supplier',
        dataIndex: 'supplier_name',
        key: 'supplier_name',
        align: 'center'
    },
    {
        title: 'PO Number',
        dataIndex: 'purchase_order_number',
        key: 'purchase_order_number',
        align: 'center'
    },
    {
        title: 'Vehicle No',
        dataIndex: 'vehicle_number',
        key: 'vehicle_number',
        align: 'center'
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        align: 'center'
    },
    {
        title: 'Delivery Date',
        dataIndex: 'delivery_date',
        key: 'delivery_date',
        align: 'center',
        width: 150
    },
]

export const stoColumns = [
    {
        title: 'Source DS',
        dataIndex: 'source_node_name',
        key: 'source_node_name',
        align: 'center'
    },
    {
        title: 'Destination DS',
        dataIndex: 'destination_node_name',
        key: 'destination_node_name',
        align: 'center'
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        align: 'center'
    },
    {
        title: 'Transfer Date',
        dataIndex: 'transfer_date',
        key: 'transfer_date',
        align: 'center',
        width: 150
    },
    {
        title: 'Vehicle No',
        dataIndex: 'vehicle_number',
        key: 'vehicle_number',
        align: 'center'
    },
    {
        title: 'Created By',
        dataIndex: 'created_by_name',
        key: 'created_by_name',
        align: 'center'
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center'
    },
]

export const pendingApprovalColumns = [
    {
        title: humanize('requested_on'),
        dataIndex: 'requested_on',
        key: 'requested_on',
        align: 'center',
        width: 120,
        render: (record) => <div>{handleDateFormat(record) + " " + getStaticTime(record)}</div>
    },
    {
        title: 'CC',
        dataIndex: 'node',
        key: 'node',
        align: 'center',
        width: 120
    },
    {
        title: humanize('approval_type'),
        dataIndex: 'approval_type',
        key: 'approval_type',
        align: 'center',
        width: 120
    },
    {
        title: humanize('request_by'),
        dataIndex: 'request_by',
        key: 'request_by',
        align: 'center',
        width: 120
    },
    // {
    //   title: humanize('has_child_requests'),
    //   dataIndex: 'has_child_requests',
    //   key: 'has_child_requests',
    //   align: 'center',
    //   render: (record) => <div>{record ? "True" : "False"}</div>
    // },
    {
        title: humanize('request_reason'),
        dataIndex: 'reason',
        key: 'reason',
        align: 'center',
        width: 300,
        render: (record, data) => <div>
            <div className="frz-align-left">
                {record}
            </div>
            <div>
                {data.request_approvals.map((item) => {
                    return (
                        <div className={`${item.action_type == "pending" ? "frz-clr-warning" :
                            item.action_type == "approved" ? "frz-clr-approved" :
                                item.action_type == "rejected" ? "frz-clr-rejected" :
                                    item.action_type == "discard" && "frz-clr-discard"} ${'frz-align-left'}`}>
                            Level - {item.level} | {humanize(item.action_type)} | {item.name}
                        </div>
                    )
                })}
            </div>
        </div >
    }
]

export const storeApprovalsColumn = [
    {
        title: humanize('approval_type'),
        dataIndex: 'approval_type',
        key: 'approval_type',
        align: 'center'
    },
    {
        title: humanize('dark_store'),
        dataIndex: 'dark_store',
        key: 'dark_store',
        align: 'center',
        width: "180px"
    },
    {
        title: humanize('requested_by'),
        dataIndex: 'requested_by',
        key: 'requested_by',
        align: 'center'
    },
    // {
    //     title: humanize('requested_on'),
    //     dataIndex: 'requested_on',
    //     key: 'requested_on',
    //     align: 'center'
    // },
    {
        title: humanize('reason'),
        dataIndex: 'reason',
        key: 'reason',
        align: 'center',
        width: "220px"
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: (record) => <div
            className={(record === "approved" || record === "partially_approved") ? "frz-status-approved" :
                record === "pending" ? "frz-status-pending" :
                    record === "expired" ? "frz-status-expired" :
                        record === "rejected" && "frz-status-rejected"} >
            {humanize(record)}
        </div >
    },
]

export const viewSKUColumn = [
    {
        title: 'Image',
        dataIndex: 'image_url',
        key: 'image_url',
        align: 'center',
        width: 100,
        fixed: 'left',
        render: (url) => <img src={url} style={{ width: 50, height: 50 }} />
    },
    {
        title: humanize('product_name'),
        dataIndex: 'product_name',
        key: 'product_name',
        align: 'center',
        width: 100,
        fixed: 'left',
    },
    {
        title: humanize('product_sku'),
        dataIndex: 'product_sku',
        key: 'product_sku',
        align: 'center'
    },
    {
        title: humanize('unit'),
        dataIndex: 'unit',
        key: 'unit',
        align: 'center'
    },
    {
        title: humanize('opening_quantity'),
        dataIndex: 'opening_quantity',
        key: 'opening_quantity',
        align: 'center'
    },
    {
        title: humanize('sto_in_quantity'),
        dataIndex: 'sto_in_quantity',
        key: 'sto_in_quantity',
        align: 'center'
    },
    {
        title: humanize('closing_quantity'),
        dataIndex: 'closing_quantity',
        key: 'closing_quantity',
        align: 'center',
        render: (record) => <div className="frz-fw-700 frz-font-16">{record}</div>
    },
    {
        title: humanize('wastage_quantity'),
        dataIndex: 'wastage_quantity',
        key: 'wastage_quantity',
        align: 'center'
    },
]


export const nestedItemsColumns = [
    {
        title: 'Product',
        dataIndex: 'product_name',
        key: 'product_name',
        align: 'center'
    },
    {
        title: 'Uom',
        dataIndex: 'uom',
        key: 'uom',
        align: 'center'
    },
    {
        title: 'Unit Price',
        dataIndex: 'unit_price',
        key: 'unit_price',
        align: 'center'
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
        align: 'center',
    },
    {
        title: 'Po Qty',
        dataIndex: 'po_qty',
        key: 'po_qty',
        align: 'center'
    },
    {
        title: 'Missing Qty',
        dataIndex: 'missing_qty',
        key: 'missing_qty',
        align: 'center'
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        align: 'center'
    },
]

export const stockColumns = [
    {
        title: 'Product',
        dataIndex: 'product_name',
        key: 'product_name',
        align: 'center',
        width: 150
    },
    {
        title: 'SKU Code',
        dataIndex: 'sku',
        key: 'sku',
        align: 'center',
        width: 100
    },
    {
        title: 'DS Count',
        dataIndex: 'darkstore_count',
        key: 'darkstore_count',
        align: 'center',
        width: 100
    },
]

export const stockDsColumns = [
    {
        title: 'Ds Name',
        dataIndex: 'darkstore_name',
        key: 'darkstore_name',
        align: 'center',
        width: 200
    },
    {
        title: 'Product Count',
        dataIndex: 'product_count',
        key: 'product_count',
        align: 'center',
        width: 150
    },
]

export const marginColumns = [
    {
        title: 'Ds Name',
        dataIndex: 'dk_name',
        key: 'dk_name',
        align: 'center',
    },
    {
        title: 'Margin',
        dataIndex: '',
        key: 'margin',
        align: 'center',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.margin - b.margin,
        render: (record) => {
            return record.margin.toFixed(2) + ' %'
        }
    },
    {
        title: 'Total Inventory',
        dataIndex: '',
        key: 'total_inventory',
        align: 'center',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.total_inventory - b.total_inventory,
        render: (record) => {
            return '₹ ' + record.total_inventory.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    },
    {
        title: 'Total Sales',
        dataIndex: '',
        key: 'total_sales',
        align: 'center',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.total_sales - b.total_sales,
        render: (record) => {
            return '₹ ' + record.total_sales.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    },
    {
        title: 'Total Profit',
        dataIndex: '',
        key: 'total_profit',
        align: 'center',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.total_profit - b.total_profit,
        render: (record) => {
            return '₹ ' + record.total_profit.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    },
    {
        title: 'Total Wastage',
        dataIndex: '',
        key: 'total_wastage',
        align: 'center',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.total_wastage - b.total_wastage,
        render: (record) => {
            return '₹ ' + record.total_wastage.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    },
    {
        title: 'Wastage %',
        dataIndex: '',
        key: 'waste_percentage',
        align: 'center',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.waste_percentage - b.waste_percentage,
        render: (record) => {
            return record.waste_percentage.toFixed(2) + ' %'
        }
    },
    {
        title: 'Daily Rank',
        dataIndex: '',
        key: 'dailyranking',
        align: 'center',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.daily_ranking - b.daily_ranking,
        render: (record) => {
            return record.daily_ranking
        }
    },
    {
        title: 'Monthly Rank',
        dataIndex: '',
        key: 'monthlyranking',
        align: 'center',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.monthly_ranking - b.monthly_ranking,
        render: (record) => {
            return record.monthly_ranking
        }
    },
]