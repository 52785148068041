import React, {useState, useEffect} from 'react';
import {Table, Spin, Card, Icon, Tooltip, Button} from 'antd';
import {nestedReplacementColumns} from '../antd/columns/orders';

function NestedReplacementTable(props) {
  const [columns, setColumns] = useState([])

  useEffect(() => {
    getColumns()
  }, [])

  function getColumns(){
    nestedReplacementColumns.map(data => {
      columns.push(data)
    })
    setColumns(columns)
  }

  return(
    <Card className="nested-table">
      {props.data.delivery_replacement_items.length > 0 ?
        <Table columns={columns} pagination={false} rowKey="id"
               dataSource={props.data.delivery_replacement_items}/>
       :
        <div className="no-data-nested">No Line Items</div>
      }
    </Card>
  )
}

export default NestedReplacementTable;
