import React, { useEffect, useState } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {AdsService} from '../../_services/ads';
import MainTable from '../../components/antd/table';
import Search from '../../components/antd/search';
import {customerSourceColumns} from '../../components/antd/columns/ads';
import {handleDateFormat} from '../../_modules/date';
import {getStaticTime} from '../../_modules/time';

const ads_service = new AdsService()

function CustomerSource(){
  const [sourceData, setSourceData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [sourceList, setSourceList] = useState(false)
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [spinEnableDownload, setSpinEnableDownload] = useState(false)
  const [state, setState] = useState({valueByStatus: undefined, valueByCode: undefined});
  const { valueByStatus, valueByCode } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getCustomerSource()
    }
  })

  useEffect(() => {
    getColumns()
    getSourceList()
  }, [])

  function getCustomerSource(){
    setSpinEnable(true)
    setSourceData([])
    const payload = {
      page: current,
      by_source: valueByStatus === undefined ? '' : valueByStatus,
      by_campaign: valueByCode === undefined ? '' : valueByCode.toUpperCase()
    }
    ads_service.get_customer_source(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setSpinEnable(false)
      setSourceData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getSourceList(){
    ads_service.get_source_list().subscribe((r) => {
        setSourceList(r.response.data)
    },
    (err)=>{
        console.log(err)
    })
  }

  function getColumns(){
    customerSourceColumns.map(data => {
      columns.push(data)
    })
    setColumns(columns)
  }

  function pagination(page) {
    setCurrent(page)
    setSourceData([])
    setRenderData(false)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function reset() {
    setState({valueByStatus: undefined, valueByCode: undefined})
    setDisable(true)
    setCurrent(1)
    setSourceData([])
    setRenderData(false)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  function getDownload(){
    setSpinEnableDownload(true)
    const payload = {
      by_source: valueByStatus === undefined ? '' : valueByStatus,
      by_campaign: valueByCode === undefined ? '' : valueByCode.toUpperCase()
    }
    ads_service.get_customer_source_download(payload).subscribe((r) => {
      const linkSource = `data:application/octet-stream;base64,${r.response.csv_data}`;
      const downloadLink = document.createElement("a");
      const fileName = `Customer_Source_Report_${(new Date).toDateString().replace(/ /g,'_')}.csv`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      openNotificationWithIcon('success','Downloaded Successfully')
      setSpinEnableDownload(false)
    }, (err) => {
      setSpinEnableDownload(false)
      openNotificationWithIcon('error', err.response.errors[0])
    })
  }

  return(
    <div className="layer">
      {sourceData && sourceList ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Select Source" value={valueByStatus}
                        onChange={onChange} type="valueByStatus" data={sourceList}/>
                <Input placeholder="Campaign Code"
                       value={valueByCode} style={{width:200, margin: 10}}
                       onChange={(e) => onChange(e.target.value, 'valueByCode')}/>
                <Button disabled={disable} onClick={getCustomerSource} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
                {!spinEnableDownload ?
                    <Button onClick={getDownload} style={{width: 150, float: 'right', margin: 10}}
                            type="primary">Download Report</Button>
                    :
                    <span style={{margin: 10, float: 'right'}}>
                        <Spin tip="...Download in Progress"/>
                    </span>
                }
              </Col>
            </Card>
          </Row>
          {sourceData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={sourceData} columns={columns}/>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default CustomerSource
