import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button, Select, Input, InputNumber,
         Radio} from 'antd';
const { Option } = Select;
const { TextArea } = Input;

const LogicPosModal = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOk() {
    setLoading(true)
    props.modalSubmit()
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.onCancelModal();
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onOk={handleOk}
          onCancel={handleCancelModal}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              Submit
            </Button>,
          ]}
        >
          <div className="block" style={{marginLeft: 35}}>Name :
            <span style={{paddingLeft:10}}>
              <TextArea placeholder="Name" autoSize={{ minRows: 2, maxRows: 3 }}
                        value={props.name} style={{width:230}}
                        onChange={(e) => props.onChangeModal(e.target.value, 'name')}/>
            </span>
          </div>
          <div className="block">Description :
            <span style={{paddingLeft:10}}>
              <TextArea placeholder="Description" autoSize={{ minRows: 2, maxRows: 6 }}
                        value={props.description} style={{width:230}}
                        onChange={(e) => props.onChangeModal(e.target.value, 'description')}/>
            </span>
          </div>
          <div className="block" style={{marginLeft:20}}>Is Active :
            <span style={{paddingLeft:10}}>
              <Radio.Group
                     value={props.valueByRadio}
                     onChange={(e) => props.onChangeModal(e.target.value, 'valueByRadio')}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </span>
          </div>
        </Modal>
      : null }
    </div>
  )
})

export default LogicPosModal
