import React, { useEffect, forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button, Select, InputNumber, Form, Row, Col } from 'antd';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { tailLayout } from '../../../_modules/controllayout';
import { openNotificationWithIcon } from '../../../_modules/notification';

const { Option } = Select;

const AddProductModal = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState([])
  const [skuList, setSkuList] = useState([])
  const [renderData, setRenderData] = useState(false)
  const [resetState, setResetState] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [state, setState] = useState({})

  const [isSelected, setIsSelected] = useState([]);
  const [form] = Form.useForm()

  const handleChange = (e) => {
    let res = form.getFieldValue("product_tag_mapping")
    setIsSelected(res)
  };

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      setResetState(true)
      setIsSelected([])
      form.resetFields()
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOnSubmit(values) {
    if (values.product_tag_mapping === undefined || values.product_tag_mapping.length === 0) {
      openNotificationWithIcon('error', "Please add at least one row")
      ref.stopLoading()
    } else {
      setLoading(true)

      let payloadValues = values.product_tag_mapping
      let finalpayload = []

      payloadValues.map((e) => {
        finalpayload.push(Object.assign(e, { product_tag_id: data.id, is_active: true }))
      })

      const payload = {
        product_tag_mapping: finalpayload
      }

      props.modalSubmit(payload)
    }
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    setResetState(true)
    setIsSelected([])
    form.resetFields()
  }

  useEffect(() => {
    if (renderData) {
      setRenderData(false)
      setCount(count)
      setSkuList(skuList)
      // console.log(count)
    }
    if (resetState) {
      setResetState(false)
      setCount([])
      setSkuList([])
      setState({})
    }
  })

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width='40%'
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form form={form} name="dynamic_form_nest_item" onFinish={handleOnSubmit} autoComplete="off">
            <Form.List name="product_tag_mapping" key={Math.random(1, 100)}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <div key={index}>
                      <Row gutter={41} key={index}>
                        <Col>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) =>
                              prevValues.area !== curValues.area || 
                              prevValues.sights !== curValues.sights}>
                            {() => (
                              <Form.Item
                                {...field}
                                {...tailLayout}
                                name={[field.name, 'product_id']}
                                fieldKey={[field.fieldKey, 'product_id']}
                                rules={[{ required: true, message: 'Missing sku' }]}>

                                <Select className="frz-width-220p" onChange={handleChange} 
                                  placeholder="Select SKU">
                                  {props.productList.map(item => (
                                    <Option disabled={isSelected?.map(e => e?.product_id).includes(item.id)} 
                                      key={item.id} value={item.id}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            )}
                          </Form.Item>
                        </Col>

                        <Col>
                          <Form.Item
                            {...field}
                            {...tailLayout}
                            name={[field.name, 'sequence']}
                            fieldKey={[field.fieldKey, 'sequence']}
                            rules={[{ required: true, message: 'Missing sequence' }]}>
                            <InputNumber placeholder="Sequence No" required={true} name="sequence" 
                              className="frz-width-110p" />
                          </Form.Item>
                        </Col>

                        <Col>
                          <Button className="frz-lm-20 frz-p-0 frz-width-36p frz-tp-2" type="primary" 
                            icon={<DeleteOutlined />}
                            shape="round" onClick={() => {
                              remove(field.name)
                              let response = form.getFieldValue("product_tag_mapping")
                              setIsSelected(response)
                            }}>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ))}

                  <Button className="frz-width-104p" type="primary" onClick={() => add()} block 
                    icon={<PlusCircleOutlined />}>
                    Add SKU
                  </Button>
                </>
              )}
            </Form.List>
          </Form>
        </Modal>
        : null}
    </div>
  )
})

export default AddProductModal
