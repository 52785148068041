import { Http } from '../../_modules/http';
import FRAAZO from '../../_modules/http_headers';

export class OPCService {

  http = new Http()

  get_opc_list() {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/products/get_products_by_opc_sequence'
    return this.http.get(url, FRAAZO())
  }

  opc_update_sequence(payload){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/products/update_opc_sequence'
    return this.http.put(url, payload, FRAAZO())
  }

}
