import { Http } from '../_modules/http'

export class GeneralService {

  http = new Http()

  get_darkstore(){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/admin/darkstores?by_city=' 
                + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_todays_order(){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/delivery_admin/todays_delivery_details?by_city=' 
                + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_customer_type(){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/customers_tags_list'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_city(){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/get_cities'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

}
