import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'

import { Modal, Form, Radio } from 'antd';
import { formItemLayout, tailLayout } from '../../../_modules/controllayout';

import FRZInput from '../../../_controls/FRZInput';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import FRZDatepicker from '../../../_controls/FRZDatepicker';
import FRZSelect from '../../../_controls/FRZSelect';
import FRZCustomRadioButon from '../../../_controls/FRZCustomRadioButon';

import moment from 'moment';
import { openNotificationWithIcon } from '../../../_modules/notification';


const FreebieModal = forwardRef((props, ref) => {
  const { modalSubmit } = props
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [segmentType, setSegmentType] = useState(false)
  const [form] = Form.useForm();
  const visibilityType = [
    { option: 'invoice', name: 'Invoice' }, { option: 'checkout', name: 'Checkout' },
    { option: 'checkout_and_invoice', name: 'Checkout Invoice' }, { option: 'nowhere', name: 'Nowhere' }]
  const statusType = [
    { value: "active", option: "Active" },
    { value: "inactive", option: "Inactive" },
  ]

  useEffect(() => {
    if ((data && title == "Edit")) {
      form.setFieldsValue({
        ...data, visible_from: moment(data.visible_from), visible_to: moment(data.visible_to),
        product_id: data.product.id, segments_attributes_prefix_city: data?.segments_attributes_prefix_city?.map(item => item.slug),
        segments_attributes_prefix_other: data?.segments_attributes_prefix_other?.map(item => item.slug)
      })
    }
  }, [data])

  function getSegmentForEdit(dataset, values){
    var newSegment = []
    if(segmentType === 'all_users'){
      dataset.map(item => { 
        newSegment.push({ id: item.id, _destroy: true })
      })
    }else{
      dataset.map(item => {
        if (values?.findIndex(val => val === item.slug) === -1){
          newSegment.push({ id: item.id, _destroy: true })
        }
      })
      values.map(item => {
        if (dataset?.findIndex(val => val.slug === item) === -1){
          newSegment.push({ slug: item })
        }
      })
    }
    return newSegment
  }

  function handleOnSubmit(values) {
    const payload = {
      ...values, visible_from: moment(values.visible_from).format("YYYY-MM-DD"),
      visible_to: moment(values.visible_to).format("YYYY-MM-DD")
    }
    if(title == "Edit"){
      payload.segments_attributes_prefix_city = getSegmentForEdit(data?.segments_attributes_prefix_city, values.segments_attributes_prefix_city)
      payload.segments_attributes_prefix_other = getSegmentForEdit(data?.segments_attributes_prefix_other, values.segments_attributes_prefix_other)
    }else {
      payload.segments_attributes_prefix_city = values.segments_attributes_prefix_city?.map(val => { return {slug: val}})
      payload.segments_attributes_prefix_other = values.segments_attributes_prefix_other?.map(val => {return {slug: val}})
    }
    setLoading(true)
    modalSubmit(payload, data.id)
  }

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
      setSegmentType((data?.segments_attributes_prefix_city?.length || data?.segments_attributes_prefix_other?.length) > 0 ? 'select_segment' : 'all_users')
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      setData(false)
      form.resetFields()
    },

    stopLoading() {
      setLoading(false)
    }
  }));

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    setData(false)
    form.resetFields()
  }

  function disabledDate(current) {
    return current && current < moment().add(-1, "day");
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                if (segmentType === 'all_users' || (segmentType === 'select_segment' && (values.segments_attributes_prefix_city.length || 
                  values.segments_attributes_prefix_other?.length))) {
                  handleOnSubmit(values)
                } else {
                  openNotificationWithIcon('error', 'Please Select Anyone Segments')
                }
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            initialValues={{ quantity: 1 }}
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            <FRZInput name="name" isRequired={true}
              label="Freebie Name" placeholder="Name" width={200} />

            <FRZSelect DropDownData={props.products} name="product_id" isRequired={true}
              label="Product Name" placeholder="Select Product Name" value="id" option="customname"
              isShowSearch={true} breakOption={true} width={300} selectDisabled={title == "Edit"} />

            <FRZInputNumber isDisabled={true} name="quantity" isRequired={true}
              label="Quantity" placeholder="1" width={200} />

            <FRZInputNumber isDisabled={title === 'Edit'} name="min_cart_amount" isRequired={true}
              label="Min Cart Amount" placeholder="" width={200} />

            <FRZSelect DropDownData={visibilityType} name="visibility" isRequired={true}
              label="Visibility" placeholder="Visibility" value="option" option="name"
              isShowSearch={true} width={200} />

            <FRZCustomRadioButon name="status" label="Status" isRequired={true}
              optionData={statusType} option="option" value="value" />

            <FRZDatepicker placeholder="Select Date" label="Visible From"
              name="visible_from" isRequired={true} disabledDate={disabledDate} />

            <FRZDatepicker placeholder="Select Date" label="Visible To"
              name="visible_to" isRequired={true} disabledDate={disabledDate} />

            <Form.Item
              {...tailLayout}
              label={"Segment Type"}>
              <Radio.Group
                value={segmentType}
                onChange={(e) => setSegmentType(e.target.value)}
              >
                <Radio value={'all_users'}>All Users</Radio>
                <Radio value={'select_segment'}>Select Segment</Radio>
              </Radio.Group>
            </Form.Item>

            {segmentType === 'select_segment' &&
              <>
                <FRZSelect DropDownData={props.segmentData} name="segments_attributes_prefix_city" isRequired={false}
                  label="Segment City" placeholder="Select Segment City" value="slug" option="name"
                  isShowSearch={false} width={'300px'} mode="multiple" />
                <div className='frz-segment-and' style={{padding: '0px 0px 20px'}}>AND</div>
                <FRZSelect DropDownData={props.segmentDataOther} name="segments_attributes_prefix_other" isRequired={false}
                  label="Segment Other" placeholder="Select Segment Others" value="slug" option="name"
                  isShowSearch={false} width={'300px'} mode="multiple" />
              </>}

          </Form>
        </Modal>
        : <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})

export default FreebieModal
