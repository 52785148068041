import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col } from 'antd';
import { EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { AdsService } from "../../_services/ads";
import FreebieTable from '../../components/ads/freebies/freebie_table';
import { freebieListingColumns } from '../../components/antd/columns/ads';
import { openNotificationWithIcon } from '../../_modules/notification'
import { BannersService } from '../../_services/banners';
import FreebieModal from '../../components/ads/freebies/freebie_modal';
import Search from '../../components/antd/search';

const ads_service = new AdsService();
const banner_service = new BannersService();

const Freebies = () => {
  const modalRef = useRef(null);
  const [listingData, setListingData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [products, setProducts] = useState(false)
  const [segmentData, setSegmentData] = useState([])
  const [segmentDataOther, setSegmentDataOther] = useState([])
  const [filters, setFilters] = useState({valueBySeg: undefined})
  const [disable, setDisable] = useState(true)

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getListing()
      getProducts()
      getSegment()
    }
  })

  useEffect(() => {
    getColumns()
  }, [listingData])

  function getListing() {
    setSpinEnable(true)
    setListingData([])
    const payload = {
      by_segment: filters.valueBySeg === undefined ? '' : filters.valueBySeg
    }
    ads_service.get_freebies(payload).subscribe((r) => {
      setSpinEnable(false);
      setListingData(r.response.data);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  function getProducts() {
    banner_service.get_product_list().subscribe((r) => {
      let newResponse = r.response.data.map(x => ({ ...x, customname: x.name + ' - ' + x.pack_size }))
      setProducts(newResponse)
    },
      (err) => {
        console.log(err)
      })
  }

  function getSegment(){
    ads_service.get_segment().subscribe((r) => {
      let city = r?.response?.segments.filter(item => item.category === 'city')
      let other = r?.response?.segments.filter(item => item.category === 'other')
      setSegmentData(city)
      setSegmentDataOther(other)
    },
    (err)=>{
      // setSegmentData(segments)
      console.log(err)
    })
  }

  function getColumns() {
    let temp=[]
    freebieListingColumns.map(data => {
      temp.push(data)
    })
    temp.push(
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
          return (
            <Button title="Edit" icon={<EditOutlined />}
              onClick={() => showModal(record, 'Edit')}>
            </Button>
          );
        },
      });
    setColumns(temp)
  }

  function showModal(data, type) {
    modalRef.current.showModal(type, data)
  }

  function modalSubmit(payloadData, id) {
    const payload = {
      id: id,
      body: payloadData
    }
    if (id) {
      ads_service.update_freebies(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Edited Successfully')
        if(r.response.data){
          listingData[listingData.findIndex(x => x.id === r.response.data.id)]=r.response.data
          setListingData([...listingData])
        }
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors?.[0] ?? 'API Error')
        })
    } else {
      ads_service.create_freebies(payload.body).subscribe((r) => {
        openNotificationWithIcon('success', 'Created Successfully')
        if(r.response.data){
          let newListingData=listingData
          newListingData.push(r.response.data)
          setListingData([...newListingData])
        }
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors?.[0] ?? 'API Error')
        })
    }
  }

  function onChangeFilters(value, type) {
    setFilters(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
  }

  function reset() {
    setFilters({valueBySeg: undefined})
    setDisable(true)
    setRenderData(false)
  }

  return (
    <div className="layer-nofilter">
      {listingData && products ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search className="frz-filter" placeholder="Segment" value={filters.valueBySeg}
                  onChange={(e) => onChangeFilters(e, 'valueBySeg')} type="valueBySeg" data={[...segmentData, ...segmentDataOther]} />
                <Button disabled={disable} onClick={getListing} className="frz-w-100px frz-m-10"
                  type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} className="frz-m-10"
                  type="primary" ghost shape="circle" icon={<ReloadOutlined />} />
                <span className="right">
                  <Button onClick={() => showModal(true, 'Create Freebie')} className="frz-dwn"
                    type="primary">Create Freebie</Button>
                </span>
              </Col>
            </Card>
          </Row>
          {listingData.length > 0 ?
            <>
              <Row>
                <Col span={24}>
                  <FreebieTable dataSource={listingData} columns={columns} />
                </Col>
              </Row>
            </>
            : (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
          }

          <FreebieModal modalSubmit={modalSubmit} ref={modalRef} products={products}
           segmentData={segmentData} segmentDataOther={segmentDataOther} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}


export default Freebies