export const dunzoColumns = [
  {
    title: 'Order No',
    dataIndex: 'order_number',
    key: 'order_number',
    align: 'center'
  },
  {
    title: 'Delivery Id',
    dataIndex: 'delivery_id',
    key: 'delivery_id',
    align: 'center'
  },
  {
    title: 'Dunzo Task Id',
    dataIndex: 'dunzo_task_id',
    key: 'dunzo_task_id',
    align: 'center'
  },
  {
    title: 'Contact No',
    dataIndex: 'contact_number',
    key: 'contact_number',
    align: 'center'
  },
  {
    title: 'Issue Description',
    dataIndex: 'issue_description',
    key: 'issue_description',
    align: 'center'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center'
  },
]
