import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button, Select, Input, InputNumber,
         Radio} from 'antd';

const DSTargetModal = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOk() {
    setLoading(true)
    props.modalSubmit()
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.onCancelModal();
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onOk={handleOk}
          onCancel={handleCancelModal}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              Submit
            </Button>,
          ]}
        >
          <div className="block">Society Target :
            <span style={{paddingLeft:10}}>
            <InputNumber placeholder="Society Target"
                         value={props.society_target} style={{width:200}}
                         onChange={(e) => props.onChangeModal(e, 'society_target')}/>
            </span>
          </div>
        </Modal>
      : null }
    </div>
  )
})

export default DSTargetModal
