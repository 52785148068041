import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Radio, Select, Spin } from 'antd';
import FRZRadioButton from '../../_controls/FRZRadioButton';
import FRZSelect from '../../_controls/FRZSelect';
import { formItemLayout } from '../../_modules/controllayout';
import { OrderService } from '../../_services/orders';
import { tailLayout } from '../../_modules/controllayout';

const order_service = new OrderService()
const { Option, OptGroup } = Select;

const ModalChangeAddress = ({ changeAddress, onSubmitChangeAdd, loading, handleChangeAddress }) => {
  const [form] = Form.useForm();
  const [addressData, setAddressData] = useState(false)

  useEffect(() => {
    order_service.get_customer_address(changeAddress.customer_id).subscribe((r) => {
        setAddressData(r.response.data)
        form.setFieldsValue({ customer_address_id: r.response.data.find(x => x.is_active === true).id})
    },
    (err) => {
        console.log(err)
    })
  }, [])
  
  return (
    <Modal
      visible={true}
      title="Mark Delivered"
      okText="Submit"
      cancelText="Cancel"
      width={'45%'}
      okButtonProps={{ loading: loading }}
      onCancel={() => handleChangeAddress(false)}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSubmitChangeAdd(values, changeAddress.id);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      {addressData ? 
        <Form
          form={form}
          layout="horizontal"
          name="form"
          {...formItemLayout}
        >
          <Form.Item
            {...tailLayout}
            label="Address"
            name="customer_address_id"
            rules={[{ required: true, message: 'Please select your address!' }]}
          >
            <Select value="id" style={{ width: 400 }} >
              {addressData.map(data => {
                return(
                  <OptGroup label={data.address_type}>
                    <Option value={data.id} className="frz-address-option">{data.flat_detail}, {data.latlong_address}</Option>
                  </OptGroup>
                )
              })}
            </Select>
          </Form.Item>
          <FRZRadioButton name="rider_payment" label="Rider Payment" isRequired={true} />
        </Form>
        : 
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </Modal>
  )
}

export default ModalChangeAddress
