import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button, Select, Input, InputNumber,
         Radio, Spin, Tabs, notification} from 'antd';
import {BannersService} from '../../../_services/banners';

const banner_service = new BannersService()
const { TabPane } = Tabs;
const { Option } = Select;

const BannerModal = forwardRef((props, ref) => {
  const [bannerDetail, setBannerDetail] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
      getBannerDetail(data.id)
    },

  }));

  function getBannerDetail(id){
    const payload = {
      id: id
    }
    banner_service.get_darkstore_banners(payload).subscribe((r) => {
      setBannerDetail(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
  }

  function onChange(val, id){
    const payload = {
      darkstore_id: id,
      banner_id: data.id,
      active: val
    }
    banner_service.update_darkstore_banners(payload).subscribe((r) => {
      openNotificationWithIcon('success','Updated Successfully')
      getBannerDetail(data.id)
    }, (err) => {
      openNotificationWithIcon('error','Failed To Update')
    })
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title + ' - ' + data.banner_name}
          onCancel={handleCancelModal}
          width='50%'
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Close
            </Button>,
          ]}
        >
          <div>
            {bannerDetail ?
              <div>
                <Tabs defaultActiveKey="1" tabPosition='left' style={{ height: 500 }}>
                  {bannerDetail.map(data => {
                    return(
                      <TabPane
                        tab={<span>
                              {data.darkstore_name}
                            </span>}
                        key={data.darkstore_id}
                      >
                        <div className="block">Is Active :
                          <span style={{paddingLeft:10}}>
                            <Radio.Group
                                   value={data.is_active}
                                   onChange={(e) => onChange(e.target.value, data.darkstore_id)}>
                              <Radio value={true}>True</Radio>
                              <Radio value={false}>False</Radio>
                            </Radio.Group>
                          </span>
                        </div>
                      </TabPane>
                    )
                  })}
                </Tabs>
              </div>
            :
              <div className="spin-center">
                <Spin tip="...Loading"/>
              </div>
            }
          </div>
        </Modal>
      : null }
    </div>
  )
})

export default BannerModal
