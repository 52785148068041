export const appColumns = [
    {
        title: 'Features',
        dataIndex: 'features',
        key: 'features',
        width: 300,
        align: 'center'
    },
    {
        title: 'Android Forced Version',
        dataIndex: 'android_forced_version',
        key: 'android_forced_version',
        width: 150,
        align: 'center'
    },
    {
        title: 'Android Version',
        dataIndex: 'android_recommended_version',
        key: 'android_recommended_version',
        width: 150,
        align: 'center'
    },
    {
        title: 'Ios Forced Version',
        dataIndex: 'ios_forced_version',
        key: 'ios_forced_version',
        width: 100,
        align: 'center'
    },
    {
        title: 'Ios Version',
        dataIndex: 'ios_recommended_version',
        key: 'ios_recommended_version',
        width: 100,
        align: 'center'
    },
    {
        title: 'Is Active',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
            return record.is_active.toString()
        }
    },
]

export const testAccountColumns = [
    {
        title: 'Developer Name',
        dataIndex: 'admin_name',
        key: 'admin_name',
        align: 'center'
    },
    {
        title: 'Mobile No',
        dataIndex: 'mobile_number',
        key: 'mobile_number',
        align: 'center'
    },
]