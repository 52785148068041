import React, { useState } from 'react';
import { Button, Upload } from 'antd'
import {UploadService} from '../_services/uploads';
import {openNotificationWithIcon} from '../_modules/notification';

const upload_service = new UploadService()

function FrzDynamicUpload({name, url, uploadFunc, callBack, disabled }) {

    const [loading, setLoading] = useState(false)
    const [uploadFile, setUploadFile] = useState(false)
    const [fileList, setFileList] = useState([])

    function afterUpload() {
        setLoading(true)
        upload_service[uploadFunc](uploadFile, url).subscribe((r) => {
            setUploadFile(false)
            setLoading(false)
            setFileList([])
            openNotificationWithIcon('success','Uploaded File Succesfully')
            if(callBack){
                callBack()
            }
        }, (err) => {
            setLoading(false)
            openNotificationWithIcon('error', err.response.errors ? err.response.errors[0] : 'Upload Failed Please Check Internal File Format')
        })
    }

    const props = {
        onRemove: file => {
            setUploadFile(false)
            setFileList([])
        },
        beforeUpload: file => {
            const formData = new FormData();
            formData.append('file', file)
            setFileList([file])
            setUploadFile(formData)
            return false
        },
        fileList,
    };

    return (
        <>
            <Upload {...props}>
                {fileList.length === 0 ?
                    <Button type="primary" className="frz-dwn" disabled={disabled}>Upload {name}</Button>
                : null}
            </Upload>
            {fileList.length > 0 ?
                <Button
                    type="primary"
                    onClick={afterUpload}
                    disabled={fileList.length === 0}
                    loading={loading}
                    className="frz-dwn"
                >
                    Start {name} Upload
                </Button>
            : null}
        </>
    );
}

export default FrzDynamicUpload;
