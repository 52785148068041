import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, notification } from 'antd';
import { DarkstoreService } from '../../_services/darkstore';
import MainTable from '../../components/antd/table';
import LocationModal from '../../components/master/darkstore/location_modal';
import { locationColumns } from '../../components/antd/columns/master/darkstores';
import { openNotificationWithIcon } from '../../_modules/notification';

const darkstore_service = new DarkstoreService()

function ServiceableLocation() {
  const [locationData, setLocationData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [mode, setMode] = useState(undefined)
  const modalRef = useRef(null);

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getLocation()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getLocation() {
    setSpinEnable(true)
    setLocationData([])
    darkstore_service.get_location().subscribe((r) => {
      setSpinEnable(false)
      setLocationData(r.response.data)
    },
      (err) => {
        console.log(err)
      })
  }

  function getColumns() {
    locationColumns.map(data => {
      columns.push(data)
    })
    columns.push({
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return (
          <Button type="primary" onClick={() => showModal(record, 'Edit Location')}>Edit</Button>
        )
      }
    });
    setColumns(columns)
  }

  function showModal(data, type) {
    setMode(type)
    modalRef.current.showModal(type, data)
  }

  // function onCancelModal(data) {
  //   showModal(data)
  // }

  function modalSubmit(payloadData, id) {
    const payload = {
      id: mode === 'Edit Location' ? id : undefined,
      body: { ...payloadData }
    }
    if (mode === 'Edit Location') {
      darkstore_service.update_location(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Edited Successfully')
        getLocation()
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors[0])
        })
    } else {
      darkstore_service.create_location(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Created Successfully')
        getLocation()
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          // openNotificationWithIcon('error', err.response.errors[0])
        })
    }
  }

  return (
    <div className="layer">
      {locationData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Add Location')} style={{ width: 150, margin: 10 }}
                  type="primary">Add Location</Button>
              </Col>
            </Card>
          </Row>
          {locationData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={locationData} columns={columns} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <LocationModal modalSubmit={modalSubmit} ref={modalRef} showModal={showModal}/>
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default ServiceableLocation
