import React from 'react';
import { Card, Row, Col } from 'antd';
import { DistributionService } from "../../_services/distribution";
import CustomerDistribution from '../../components/master/distribution/customer';
import DeliveryDistribution from '../../components/master/distribution/delivery';
import PackerDistribution from '../../components/master/distribution/packer';

const distribution_service = new DistributionService();

const Distribution = () => {

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card>    
            <CustomerDistribution />
          </Card>
        </Col>
        <Col span={12}>
          <Card>    
            <DeliveryDistribution />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Distribution
