import React, { useEffect, forwardRef, useState, useImperativeHandle } from "react";
import { Modal, Button, Row, Col, Tabs } from "antd";
import ProductDetails from "./product_details";
import DarkStoreWisePrices from "./darkstore_wise_prices";
import ProductLocalNames from "./local_names";
import ProductActivation from './product_activation';
import ProductInStockActivation from './product_instock';
import DarkStoreFlashSales from './darkstore_flash_sales';
import ProductEditForm from "./edit_form";
import ComboDetails from './combo_details';
import InventoryMgmnt from './inventory_mgmnt';
import LogModal from "../../logs/logmodal";
import CityLevelPriceUpdate from "./city_level_price/city_level_price_update";

const { TabPane } = Tabs;

const ProductDetailModal = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(false);
  const [ogData, setOgData] = useState(false);
  const [operations, setOperations] = useState(false)
  const [title, setTitle] = useState(false);
  const [currentTab, setCurrentTab] = useState(false)
  const [showLogModal, setShowLogModal] = useState(false)
  const [firstSeqImage, setFirstSeqImage] = useState(false)

  var isReloadData = false;

  useEffect(() => {
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if (['developer', 'super_admin', 'category', 'category_admin'].includes(data)) {
        setOperations(true)
      }
    })
  }, []);

  useImperativeHandle(ref, () => ({
    showModal(value, data) {
      setVisible(true);
      setData(data);
      setOgData(data);
      setTitle(value);
    },
  }));

  const handleFirstSeq = (value) => {
    setFirstSeqImage(value)
  }

  function onProductUpdate(product) {
    setData(false)
    setData(product);
    isReloadData = true;
  }

  function handleCancelModal() {
    setData(false);
    setVisible(false);
    setLoading(false);
    props.onCancelModal(isReloadData);
  }

  function capitalizeFirstLetterWithSpaces(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).replace("_", " ");
  }

  const NameValue = ({ keyName, label, value }) => {
    return (
      <div className="order-detail-padding">
        <b>{label ?? capitalizeFirstLetterWithSpaces(keyName)} :</b>
        <span style={{ paddingLeft: 10 }}>{value ?? data[keyName]}</span>
      </div>
    );
  };

  const onChangeTab = (e) => {
    setCurrentTab(e)
  }

  const handleLogModal = (value) => {
    setShowLogModal(value)
  }
  
  return (
    <Modal
      visible={visible}
      title={
        <div className="frz-dis-flex">
          <div className="frz-dis-flex frz-vertical-center">
            {title}
          </div>

          <div className="frz-show-logs-modal-btn">
            <Button type="primary" onClick={() => handleLogModal(true)}>
              Show Logs
            </Button>
          </div>
        </div>
      }
      onCancel={handleCancelModal}
      width="92%"
      style={{ float: "right" }}
      className="order"
      footer={[
        <Button key="back" onClick={handleCancelModal}>
          Close
        </Button>,
      ]}
    >
      {data ? (
        <div className="order-modal-container">
          <Row>
            <Col md={6}>
              <div
                style={{
                  borderRight: "1px solid #f0f0f0",
                  paddingRight: 24,
                  height: "100%",
                }}
              >
                <NameValue keyName="name" />
                <NameValue keyName="description" />
                <NameValue label="Category name" value={data?.category?.name} />
                <NameValue
                  label="Sub category name"
                  value={data?.sub_category?.name}
                />
                <NameValue keyName="sku" />
                <NameValue keyName="moq_mrp" />
                <NameValue keyName="moq_sp" />
                <NameValue keyName="mrp" />
                <NameValue keyName="sp" />
                <NameValue keyName="pack_size" />
                <NameValue keyName="pack_size_desc" />
                <NameValue keyName="max_qty" />
                <NameValue keyName="uom" />
                <NameValue keyName="multiple_quantity" />
                <NameValue
                  keyName="in_stock"
                  value={data.in_stock ? "Yes" : "No"}
                />
                <NameValue
                  keyName="is_active"
                  value={data.is_active ? "Yes" : "No"}
                />
                <NameValue
                  keyName="is_master"
                  value={data.is_master ? "Yes" : "No"}
                />
                <NameValue keyName="sequence" />
                <NameValue keyName="brand_name" />
                <NameValue keyName="variant_name" />
                <NameValue
                  keyName="local_names"
                  value={
                    Array.isArray(data.local_names)
                      ? data.local_names.join(",")
                      : ""
                  }
                />
                <NameValue keyName="conversion_factor" />
                <NameValue keyName="vnf_conversion_factor" />
                <NameValue keyName="cgst_percentage" />
                <NameValue keyName="sgst_percentage" />
                <NameValue keyName="gst_percentage_slab" />
                <div className="frz-tp-20">
                  <Row gutter={16}>
                    <Col xs={6}>
                      {/* change image url based on first image multiple image */}
                      <img src={!firstSeqImage ? data.image_url : firstSeqImage} className="frz-w-100 frz-grey" />
                    </Col>
                    <Col xs={18}>
                      <div className="frz-hc-product-name">{data.name}</div>
                      <div className="frz-hc-product-pk">{data.pack_size}</div>
                      <div className="frz-dis-flex frz-tm-5">
                        <div className="frz-font-16 frz-fw-700">
                          ₹{data.moq_sp}
                        </div>
                        {data.moq_mrp !== data.moq_sp ?
                          <div className="frz-price-slash">
                            ₹{data.moq_mrp}
                          </div>
                          : null}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md={18}>
              <div style={{ paddingLeft: 24 }}>
                <Tabs defaultActiveKey="1" onChange={(e) => onChangeTab(e)}>
                  <TabPane tab={<span>Product Details</span>} key="1">
                    <ProductEditForm
                      handleImageUrl={props.handleImageUrl}
                      product={data} companyList={props.companyList}
                      subCategoryList={props.subCategoryList}
                      productCategories={props.productCategories}
                      productBrandsList={props.productBrandsList}
                      productTaxSlab={props.productTaxSlab}
                      productCessSlab={props.productCessSlab}
                      onProductUpdate={onProductUpdate}
                      imageUrl={props.imageUrl}
                      imgLoading={props.imgLoading}
                      handleChange={props.handleChange}
                      isCombo={ogData.combo_sub_products?.length > 0}
                      handleFirstSeq={handleFirstSeq}
                      productList={props.productList}
                      modalSubmit = {props.modalSubmit}
                      handleCancelModal={handleCancelModal}
                    />
                  </TabPane>
                  {/* <TabPane tab={<span>CIty Level Price Update</span>} key="2">
                    <CityLevelPriceUpdate/>
                  </TabPane> */}
                  <TabPane tab={<span>Product DS Activation</span>} key="2" disabled={!operations}>
                    {currentTab == 2 ?
                      <ProductActivation productId={data.id} />
                      : null
                    }
                  </TabPane>
                  <TabPane tab={<span>City Level Price Update</span>} key="3">
                    <CityLevelPriceUpdate productId={data.id} />
                  </TabPane>
                  <TabPane tab={<span>Product In Stock</span>} key="4">
                    <ProductInStockActivation productId={data.id} />
                  </TabPane>
                  <TabPane tab={<span>Inventory Mgmnt</span>} key="5">
                    <InventoryMgmnt product={data} />
                  </TabPane>
                  <TabPane tab={<span>Product Local Names</span>} key="6">
                    <ProductLocalNames productId={data.id} />
                  </TabPane>
                  <TabPane tab={<span>Product Description</span>} key="7">
                    <ProductDetails productId={data.id} />
                  </TabPane>
                  <TabPane tab={<span>Dark Store Wise Prices</span>} key="8">
                    <DarkStoreWisePrices productId={data.id} />
                  </TabPane>
                  <TabPane tab={<span>Dark Store Wise Flash Sales</span>} key="9">
                    <DarkStoreFlashSales productId={data.id} />
                  </TabPane>
                  {ogData.combo_sub_products?.length > 0 ?
                    <TabPane tab={<span>Combo Details</span>} key="10">
                      <ComboDetails product={ogData.combo_sub_products} />
                    </TabPane>
                    : null
                  }
                </Tabs>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div></div>
      )}
      {showLogModal ?
        <LogModal type="product" showLogModal={showLogModal} handleLogModal={handleLogModal} itemId={data.id} />
        : null}
    </Modal>
  );
});

export default ProductDetailModal;
