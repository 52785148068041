import React, { useEffect, useState } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber, Select,
         Pagination, DatePicker, Popconfirm, notification, Tag } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {ValetService} from '../../_services/valet';
import MainTable from '../../components/antd/table';
import Search from '../../components/antd/search';
import {valetOrderColumns} from '../../components/antd/columns/valet';
import moment from 'moment';
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const valet_service = new ValetService()

function OrdersValet(){
  const [listingData, setListingData] = useState(false)
  const [valetPlan, setValetPlan] = useState(false)
  const [valetAmount, setValetAmount] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [state, setState] = useState({valueByValet: undefined, valueByMobno: undefined, 
                                      start_date: '', end_date: '', showDateValue: false});
  const { valueByValet, valueByMobno, start_date, end_date, showDateValue } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getListing()
    }
  })

  useEffect(() => {
    getColumns()
    getValetPlans()
  }, [])

  function getListing(){
    setSpinEnable(true)
    setListingData([])
    const payload = {
      page: current,
      valet_product: !valueByValet ? '' : valueByValet,
      by_mobile_number: !valueByMobno ? '' : valueByMobno,
      start_date: start_date,
      end_date: end_date,
    }
    valet_service.orders_valet(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.valet_orders.meta.total_pages * 50}))
      setSpinEnable(false)
      setListingData(r.response.valet_orders.data)
      setValetAmount(r.response.total_fraazo_valet_amount)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getValetPlans(){
    valet_service.valet_plans().subscribe((r) => {
        setValetPlan(r.response.data)
    },
    (err)=>{
        console.log(err)
    })
  }

  function getColumns(){
    valetOrderColumns.map(data => {
      columns.push(data)
    })
    setColumns(columns)
  }

  function pagination(page) {
    setCurrent(page)
    setListingData([])
    setRenderData(false)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function reset() {
    setState({valueByValet: undefined, valueByMobno: undefined, 
              start_date: '', end_date: '', showDateValue: false})
    setDisable(true)
    setCurrent(1)
    setListingData([])
    setRenderData(false)
  }

  function onChangeRangePicker(value, dateString){
    setState(prevState =>({...prevState, showDateValue: true, start_date :dateString[0], end_date:dateString[1]}))
    setDisable(false)
    setCurrent(1)
  }

  function disabledDate(current){
    // Can select days before today and today
    // return current && current > moment().add(0, "day");
    //Can select days before today and past 3 months
    return current && (current < moment().subtract(91, "day") || current > moment().add(0, "day"));
  }

  return(
    <div className="layer">
      {listingData && valetPlan ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Valet Plan" value={valueByValet}
                        onChange={onChange} type="valueByValet"
                        data={valetPlan}/>
                <InputNumber placeholder="Mobile No"
                             onChange={(e) => onChange(e, 'valueByMobno')}
                             value={valueByMobno} style={{width: 200, margin: 10}}/>
                <RangePicker onChange={onChangeRangePicker} style={{margin:10}}
                             value={showDateValue ? [moment(start_date, dateFormat),
                             moment(end_date, dateFormat)] : '' } format={dateFormat}
                             allowClear={false} disabledDate={disabledDate}/>
                <Button disabled={disable} onClick={getListing} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
                <span style={{float: 'right', padding: 15}}><b>Valet Amount:</b> ₹ {valetAmount}</span>
              </Col>
            </Card>
          </Row>
          {listingData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={listingData} columns={columns}/>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default OrdersValet
