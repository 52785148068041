import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button, Select, Input, InputNumber,
         Radio, Upload, message, Spin} from 'antd';
import Search from '../antd/search';
import { useSelector } from "react-redux";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {AdsService} from '../../_services/ads';
const { Option } = Select;
const { TextArea } = Input;
const ads_service = new AdsService()

const AdsModal = forwardRef((props, ref) => {
  const darkstore = useSelector(state => state.auth.darkstore);
  const [customerTypeData, setCustomerTypeData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      getCustomerType(value, data)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function getCustomerType(value, data){
    ads_service.get_customer_type().subscribe((r) => {
      setVisible(true)
      setData(data)
      setTitle(value)
      setCustomerTypeData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function handleOk() {
    setLoading(true)
    props.modalSubmit()
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.onCancelModal();
  }

  function handleClick({ file, onSuccess }){
    setTimeout(() => {
    onSuccess("ok");
    }, 0);
  }

  const uploadButton = (
    <div>
      {props.imgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <div>
      {data && customerTypeData ?
        <Modal
          visible={visible}
          title={title}
          onOk={handleOk}
          onCancel={handleCancelModal}
          width='55%'
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              Submit
            </Button>,
          ]}
        >
          <div className="block">Send Notification To <span style={{color: 'red'}}>* &nbsp;</span> :
            <span style={{paddingLeft:10}}>
            <Radio.Group
                    value={props.valueByRadio}
                    onChange={(e) => props.onChangeModalFilter(e.target.value, 'valueByRadio')}>
                <Radio value='all_darkstores'>All Darkstores Customers</Radio>
                <Radio value='individual_darkstores'>Individual Darkstore Customers</Radio>
            </Radio.Group>
            </span>
          </div>
          <div className="block" style={{marginLeft:60}}>Customers<span style={{color: 'red'}}>* &nbsp;</span> :
            <span style={{paddingLeft:10}}>
            <Radio.Group
                    value={props.is_serviceable}
                    onChange={(e) => props.onChangeModalFilter(e.target.value, 'is_serviceable')}>
                <Radio value={true}>Serviceable Customers</Radio>
                {/* <Radio value={false}>Non - Serviceable Customers</Radio> */}
                <Radio value={null}>All Customers</Radio>
            </Radio.Group>
            </span>
          </div>
          {props.valueByRadio === 'individual_darkstores' ? 
                  <div className="block" style={{marginLeft:55}}>Darkstores <span style={{color: 'red'}}>* &nbsp;</span> :
                    <span style={{paddingLeft:10}}>
                      <Select style={{ width: 430}} 
                              mode="tags"
                              value={props.valueByDarkstore}
                              placeholder="Darkstores" onChange={props.onChangeDarkstore}>
                        {darkstore.map(data => {
                          return(
                            <Option key={data.id} value={data.id}>
                              {data.name}
                            </Option>
                          )
                        })}
                      </Select>
                    </span>
                  </div>
          : null}
          {props.valueByRadio ?
            <div>
                <div className="block" style={{marginLeft:39}}>Customer Type &nbsp; :
                    <span style={{paddingLeft:10}}>
                    <Select style={{ width: 430}} value={props.customer_type}
                            placeholder="Customer Type" onChange={(e) => props.onChangeModalFilter(e, 'customer_type')}>
                      {customerTypeData.map(data => {
                        return(
                          <Option key={data.id} value={data.id}>
                            {data.description}
                          </Option>
                        )
                      })}
                    </Select>
                    </span>
                </div>
                <div className="block" style={{marginLeft:20}}>Exclude Customer &nbsp; :
                    <span style={{paddingLeft:10}}>
                    <Select style={{ width: 430}} 
                            mode="tags"
                            value={props.exclude_customer_type}
                            placeholder="Exclude Customer Type" onChange={props.onChangeModalExclude}>
                      {customerTypeData.map(data => {
                        return(
                          <Option key={data.id} value={data.id}>
                            {data.description}
                          </Option>
                        )
                      })}
                    </Select>
                    </span>
                </div>
                {props.valueByRadio === 'all_darkstores' ? 
                  <div className="block" style={{marginLeft:13}}>Exclude Darkstores &nbsp; :
                    <span style={{paddingLeft:10}}>
                      <Select style={{ width: 430}} 
                              mode="tags"
                              value={props.exclude_darkstore}
                              placeholder="Exclude Darkstores" onChange={props.onChangeModalExcludeDS}>
                        {darkstore.map(data => {
                          return(
                            <Option key={data.id} value={data.id}>
                              {data.name}
                            </Option>
                          )
                        })}
                      </Select>
                    </span>
                  </div>
                : null}
                {props.customerCount !== undefined ? 
                  <div className="block" style={{marginLeft:31}}>Customer Count &nbsp; : <b style={{paddingLeft: 8}}>{props.customerCount}</b></div>
                : 
                  <div className="block" style={{marginLeft:31}}>
                    Customer Count &nbsp; : &nbsp; <Spin style={{paddingLeft: 20}}/>
                  </div>
                }
                <div className="block" style={{marginLeft:15}}>Include Mobile No's &nbsp; :
                    <span style={{paddingLeft:10}}>
                        <Select mode="tags" style={{ width: 290 }} onChange={props.handleinclude} tokenSeparators={[',']}>
                            []
                        </Select>
                    </span>
                </div>
                <div className="block" style={{marginLeft:13}}>Exclude Mobile No's &nbsp; :
                    <span style={{paddingLeft:10}}>
                        <Select mode="tags" style={{ width: 290 }} onChange={props.handleexclude} tokenSeparators={[',']}>
                            []
                        </Select>
                    </span>
                </div>
                <div className="block" style={{marginLeft:21}}>Notification Type <span style={{color: 'red'}}>* &nbsp;</span> :
                    <span style={{paddingLeft:10}}>
                    <Select style={{ width: 290}} value={props.notification_type}
                            placeholder="Notification Type" onChange={(e) => props.onChangeModal(e, 'notification_type')}>
                        <Option value='push_notify'>Push Notification</Option>
                        <Option value='mobile_sms'>Mobile SMS</Option>
                    </Select>
                    </span>
                </div>
                {props.notification_type === 'push_notify' ?
                    <div> 
                        <div className="block" style={{marginLeft:100}}>Title <span style={{color: 'red'}}>* &nbsp;</span> :
                            <span style={{paddingLeft:10}}>
                            <Input placeholder="Title"
                                value={props.title} style={{width:290}}
                                onChange={(e) => props.onChangeModal(e.target.value, 'title')}/>
                            </span>
                        </div>
                        <div className="block" style={{marginLeft:29}}>Campaign Name <span style={{color: 'red'}}>* &nbsp;</span> :
                            <span style={{paddingLeft:10}}>
                            <Input placeholder="Campaign Name"
                                value={props.campaign_name} style={{width:290}}
                                onChange={(e) => props.onChangeModal(e.target.value, 'campaign_name')}/>
                            </span>
                        </div>
                        <div className="block" style={{display: 'flex', marginLeft: 58}}>Upload Image :
                            <span style={{paddingLeft:10}}>
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    customRequest={handleClick}
                                    onChange={props.handleChange}
                                >
                                    {props.imageUrl ? <img src={props.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </span>
                        </div>
                    </div>
                : null}
                <div className="block" style={{marginLeft:74}}>Message <span style={{color: 'red'}}>* &nbsp;</span> :
                    <span style={{paddingLeft:10}}>
                    <TextArea placeholder="Message" autoSize={{ minRows: 2, maxRows: 3 }}
                                value={props.message} style={{width:290}}
                                onChange={(e) => props.onChangeModal(e.target.value, 'message')}/>
                    </span>
                </div>
            </div>
          : null}
        </Modal>
      : null }
    </div>
  )
})

export default AdsModal
