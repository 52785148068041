import React, { useEffect, useState } from 'react';
import { Row, Col, Form, InputNumber, Select } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import FRZSelect from '../../../_controls/FRZSelect';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import rupee from '../../../static/images/rupee.svg';
import { tailLayout } from '../../../_modules/controllayout'

const { Option } = Select;

function ImsF(props) {

  // useEffect(() => {
  //   props.form.setFieldsValue({distance_config:[{distance: 10, rate: 4}, {distance: 45, rate: 33}]})
  // }, [])

  const [isFieldRequired, setIsFieldRequired] = useState(false);

  const onFormChange = () => {
    props.handleOnFormChange(true)
    let data = props.form.getFieldValue()
    // if (data.E.period || data.E.hour_count || data.E.rate) {
    //   setIsFieldRequired(true)
    // } else {
    //   props.form.validateFields()
    //   setIsFieldRequired(false)
    // }
  }

  return (
    <div>
      <Form
        form={props.form}
        layout="horizontal"
        name="form"
        onChange={() => onFormChange()}>
        <Row>
          <Col span={12}>
            <div className='frz-ims-incentive-subtext frz-fuel-km-padding'>
              <div className='frz-tp-8'>Fixed Per Order Cost (F)</div>
            </div>
          </Col>
          <Col span={12}>
            <div className='frz-fuel-km-padding'>
              <div className='frz-dis-flex frz-justify-end frz-ims-placeholder'>
                <div className='frz-p-relative frz-ims-rs-custom'>
                  <div className='frz-rupee-ims'><img src={rupee} /> </div>
                  <FRZInputNumber minValue={0} name={['F', 'rate']}
                    label="" placeholder="rate" width={150} errorMsg="Please enter rate" isRequired={false} valueOf={"rate"} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default ImsF