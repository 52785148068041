import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react'
import { Modal, Button, Select, Input, InputNumber,
         Radio, notification} from 'antd';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
const { Option } = Select;


const CreateRefund = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState([])
  const [skuList, setSkuList] = useState([])
  const [renderData, setRenderData] = useState(false)
  const [resetState, setResetState] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [state, setState] = useState({})

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      setResetState(true)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOk() {
    setLoading(true)
    let refundList = []
    if(count.length === 0){
      openNotificationWithIcon('error', "Please Add SKU's")
      setLoading(false)
      return
    }
    for(var i=0; i<count.length; i++){
      if(state['refundsku'+count[i]] === undefined){
        openNotificationWithIcon('error', "Please fill all fields")
        setLoading(false)
        return
      }else if(state['refundqty'+count[i]] === undefined){
        openNotificationWithIcon('error', "Please fill all fields")
        setLoading(false)
        return
      }else if(state['refundreason'+count[i]] === undefined){
        openNotificationWithIcon('error', "Please fill all fields")
        setLoading(false)
        return
      }else{
        refundList.push({
          id: state['refundsku'+count[i]],
          refund_qty: state['refundqty'+count[i]],
          refund_reason_id: state['refundreason'+count[i]],
        })
      }
    }
    if(props.refundMode === undefined){
      openNotificationWithIcon('error', "Please Select Refund Mode")
      setLoading(false)
    }else{
      // console.log(refundList)
      props.modalSubmit(refundList)
    }
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    setResetState(true)
    props.onCancelModal();
  }

  useEffect(() => {
    if(renderData){
      setRenderData(false)
      setCount(count)
      setSkuList(skuList)
      // console.log(count)
    }
    if(resetState){
      setResetState(false)
      setCount([])
      setSkuList([])
      setState({})
    }
  })

  function addDropdown() {
    if(count.length === 0){
      count.push(1)
    }else{
      count.push(count[count.length - 1] + 1)
    }
    setCount(count)
    setRenderData(true)
  }

  function deleteDropdown(val) {
    skuList.splice(count.findIndex(e => e === val),1)
    count.splice(count.findIndex(e => e === val),1)
    setCount(count)
    setSkuList(skuList)
    // console.log(skuList)
    setRenderData(true)
  }

  function blockDuplicates(id){
    // console.log(skuList)
    if(skuList.find(e => e === id)){
      return true
    }
  }

  function onChange(value, indexval, type, index) {
    if(type === 'sku'){
      let stateType = 'refundsku'+indexval
      // console.log(skuList)
      if(skuList[index] !== undefined){
        skuList[index] = value
      }else{
        skuList.push(value)
      }
      setSkuList(skuList)
      setState(prevState =>({...prevState, [stateType]: value === "" ? undefined : value}))
    }else if(type === 'qty'){
      let stateType = 'refundqty'+indexval
      setState(prevState =>({...prevState, [stateType]: value === "" || value === null ? undefined : value}))
    }else if(type === 'reason'){
      let stateType = 'refundreason'+indexval
      setState(prevState =>({...prevState, [stateType]: value === "" ? undefined : value}))
    }
    // console.log(skuList)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onOk={handleOk}
          onCancel={handleCancelModal}
          width='60%'
          style={{float: 'right'}}
          className="order"
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              Submit
            </Button>,
          ]}
        >
          <div className="order-modal-container">
            <div>Mode Of Refund :
              <span style={{paddingLeft:10}}>
              <Select style={{ width: 200}} value={props.refundMode}
                      placeholder="Refund Mode" onChange={(e) => props.onChangeModal(e, 'refundMode')}>
                  <Option key='original_source' value='original_source'>Original Source</Option>
                  <Option key='credit' value='credit'>Credit</Option>
              </Select>
              </span>
            </div>
            <div style={{borderTop: '1px solid #f0f0f0', marginTop: 24}}>
              <div className="refund-padding">
                {count.length > 0 ?
                  <div>
                    {count.map((val, index) => (
                      <div className="refund-inline" key={val}>
                        <Select style={{ width: 200}}
                                placeholder="Select SKU"
                                onChange={(e) => onChange(e, val, 'sku', index)}>
                            {props.orderDetail.map(data => {
                              return(
                                <Option key={data.id} value={data.id}
                                        disabled={blockDuplicates(data.id)}>
                                  {data.product.name}
                                </Option>
                              )
                            })}
                        </Select>
                        <InputNumber
                          placeholder="Qty"
                          style={{width:70}}
                          onChange={(e) => onChange(e, val, 'qty')}/>
                        <Select style={{ width: 200}}
                                placeholder="Select Reason"
                                onChange={(e) => onChange(e, val, 'reason')}>
                            {props.refundReason.map(data => {
                              return(
                                <Option key={data.id} value={data.id}>
                                  {data.name}
                                </Option>
                              )
                            })}
                        </Select>
                        <Button type="primary" icon={<DeleteOutlined />}
                                style={{ width: 36, padding: 0, paddingTop: 2 }}
                                shape="round" onClick={() => deleteDropdown(val)}>
                        </Button>
                      </div>
                    ))}
                  </div>
                :
                  null
                }
                <Button type="primary" icon={<PlusCircleOutlined />}
                        style={{ width: 104}} onClick={addDropdown}>
                  Add Sku
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      : null }
    </div>
  )
})

export default CreateRefund
