import React, { useState, useEffect } from 'react'
import { Card, Divider, Steps, Spin } from 'antd'
import CreateForm from './createform';
import { LandingClusterService } from '../../_services/sdui/landing_cluster'

const landing_cluster_service = new LandingClusterService()

const { Step } = Steps;

const DetailPanel = ({ activeUnitId, addInProgress, listingData,
  setAddInProgress, setActiveUnitId, setListingData, getListingData,
  unitData, staticData, segmentData, updateId, setUpdateId, isSDUISuperAdmin }) => {

  const [activeUnit, setActiveUnit] = useState(false)
  const [current, setCurrent] = useState(null);
  const [rowData, setRowData] = useState(false)
  const [detailSpin, setDetailSpin] = useState(false)

  useEffect(() => {
    setDetailSpin(true)
    if (activeUnitId) {
      setRowData(false)
      landing_cluster_service.showUnitDetail(activeUnitId).subscribe((r) => {
        setDetailSpin(false)
        setUpdateId(r.response.data.id)
        setRowData(r.response.data)
        setCurrent(1)
      }, (err) => {
        setDetailSpin(false)
        console.err(err)
      })
    } else {
      setDetailSpin(false)
      setCurrent(0)
    }
  }, [activeUnitId])

  useEffect(() => {
    if (!addInProgress) {
      setActiveUnit(false)
      setCurrent(0)
    }
  }, [addInProgress])

  const onDeleteItm = () => {
    let temp = listingData;
    setCurrent(0)
    temp.pop()
    setAddInProgress(false)
    setListingData([...temp])
  }

  const discardUpdate = (idViaUpdate) => {
    setUpdateId(false)
    setUpdateId(activeUnitId)
    setCurrent(1)
    if (idViaUpdate) {
      setActiveUnitId(false)
    }
  }

  return (
    <Card className="frz-h-100vh-unit unit-custom-card-padding">
      {!detailSpin ?
        (activeUnitId || addInProgress) && current !== null ?
          <div>
            <CreateForm listingData={listingData} activeUnitId={activeUnitId} addInProgress={addInProgress}
              setActiveUnitId={(value) => setActiveUnitId(value)}
              setAddInProgress={(value) => setAddInProgress(value)} onDiscardItm={onDeleteItm}
              setActiveUnit={(value) => setActiveUnit(value)} activeUnit={activeUnit}
              unitData={unitData} staticData={staticData} segmentData={segmentData} getListingData={getListingData}
              setUpdateId={(value) => setUpdateId(value)} updateId={updateId}
              setCurrent={(value) => setCurrent(value)} current={current} discardUpdate={discardUpdate} rowData={rowData}
              isSDUISuperAdmin={isSDUISuperAdmin} />
          </div>
          : <div className="frz-dis-flex frz-hrz-center frz-vt-center frz-h-100vh-unit">
            <h2>
              Please select a unit
            </h2>
          </div> :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </Card>
  )
}

export default DetailPanel