import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'antd';
import FRZSelect from '../../../_controls/FRZSelect';
import moment from 'moment'

function ImsBilling(props) {
  const [period] = useState([
    { period: '15_DAYS', name: '15 Days', id: 1 }, { period: 'WEEKLY', name: 'Weekly', id: 2 }, { period: 'MONTHLY', name: 'Monthly', id: 3 }])

  const onFormChange = (value) => {
    props.handleOnFormChange(true, period.find((e) => e.period == value).id)
  }

  useEffect(() => {
    if (props.imsData.billing_cycle == "") {
      props.form.resetFields()
    }
  }, [props.imsData])

  return (
    <div className='frz-ims-box'>
      <Row>
        <Col span={12}>
          <div className='frz-ims-text'>
            Billing Cycle
          </div>
        </Col>
        <Col span={12}>
          <Form
            form={props.form}
            layout="horizontal"
            name="form"
            initialValues={props.imsData.billing_cycle !== "" ?
              {
                billing_cycle: props.imsData.billing_cycle === "" ? undefined : props.imsData.billing_cycle,
                A: props.imsData['A'] ? { ...props.imsData['A'], login_time: moment(props.imsData['A'].login_time, 'HH:mm') } : {},
                B: props.imsData['B'] ? props.imsData['B'].type === "PER_ORDER" ?
                  { 'type': props.imsData['B'].type, 'order_rate': props.imsData['B'].order_rate }
                  : { 'type': props.imsData['B'].type, 'distance_config': props.imsData['B'].distance_config }
                  : [],
                C: props.imsData['C'] ? props.imsData['C'].order_incentive_config ? props.imsData['C'].order_incentive_config : [] : [],
                D: props.imsData['D'] ? props.imsData['D'] : [],
                E: props.imsData['E'] ? props.imsData['E'] : [], formula: props.imsData.formula,
                F: props.imsData['F'] ? props.imsData['F'] : [],
                G: props.imsData['G'] ? props.imsData['G'].type === "PER_ORDER" ?
                  { ...props.imsData['G'], 'G_time': [moment(props.imsData['G'].start_time, 'HH:mm'), moment(props.imsData['G'].end_time, 'HH:mm')] } :
                  { ...props.imsData['G'], 'G_time': [moment(props.imsData['G'].start_time, 'HH:mm'), moment(props.imsData['G'].end_time, 'HH:mm')] } :
                  {},
                H: props.imsData['H'] ? props.imsData['H'] : [],
                I: props.imsData['I'] ? props.imsData['I'].earning_based_incentive_config : [],
                J: props.imsData['J'] ? props.imsData['J'].rain_surge_incentive_config : [],
              } : null}
            onChange={() => onFormChange()}>
            <div className='frz-dis-flex frz-justify-end'>
              <FRZSelect DropDownData={period} name='billing_cycle' isRequired={true}
                label="" placeholder="Billing Cycle" option="name" isShowSearch={true}
                width={150} value="period" setOnChange={onFormChange} />
            </div>
          </Form>
        </Col>
      </Row>
    </div >
  )
}

export default ImsBilling