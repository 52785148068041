import { Http } from '../../_modules/http';
import FRAAZO from '../../_modules/http_headers';

export class DSPropertiesService {

  http = new Http()

  get_ds_properties(urlParam) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/darkstore_properties' + urlParam
    return this.http.get(url, FRAAZO())
  }

  update_ds_properties(payload){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/darkstore_properties/' + payload.id
    return this.http.put(url, payload.body, FRAAZO())
  }

}
