export const customerColumns = [
  {
    title: 'Customer Name',
    dataIndex: 'customer_name',
    key: 'customer_name',
    align: 'center'
  },
  {
    title: 'Mobile No',
    dataIndex: 'mobile_number',
    key: 'mobile_number',
    align: 'center',
  },
  {
    title: 'Address',
    dataIndex: '',
    key: '',
    width: 200,
    align: 'center',
    render: (record) => {
      return record.active_address ?
             (record.active_address.flat_detail ? record.active_address.flat_detail : '')
             + ' ' + (record.active_address.landmark ? record.active_address.landmark : '')
             : ''
    }
  },
  {
    title: 'Address Type',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.active_address ? record.active_address.address_type : null
    }
  },
  {
    title: 'Is Serviceable',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.active_address ? record.active_address.is_serviceable.toString() : null
    }
  },
]

export const referralRewardColumns = [
  {
    title: 'Customer Name',
    dataIndex: 'customer_name',
    key: 'customer_name',
    align: 'center'
  },
  {
    title: 'Received From',
    dataIndex: 'received_from',
    key: 'received_from',
    align: 'center'
  },
  {
    title: 'Last Purchased Date',
    dataIndex: 'last_purchased_date',
    key: 'last_purchased_date',
    align: 'center'
  },
  {
    title: 'Total Amount',
    dataIndex: 'total_amount',
    key: 'total_amount',
    align: 'center'
  },
  {
    title: 'Bonus Amount',
    dataIndex: 'bonus_amount',
    key: 'bonus_amount',
    align: 'center'
  }
]

export const customerWalletColumns = [
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    align: 'center'
  },
  {
    title: 'Ledger Type',
    dataIndex: 'credit_ledger_type',
    key: 'credit_ledger_type',
    align: 'center'
  },
]

export const customerAddressColumns = [
  {
    title: 'Address',
    dataIndex: '',
    key: '',
    width: 200,
    align: 'center',
    render: (record) => {
      return record.flat_detail + ' ' + record.landmark
    }
  },
  {
    title: 'Address Type',
    dataIndex: 'address_type',
    key: 'address_type',
    align: 'center'
  },
  {
    title: 'Is Active',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.is_active.toString()
    }
  },
  {
    title: 'Is Serviceable',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.is_serviceable.toString()
    }
  },
]

export const customerReferralColumns = [
  {
    title: 'Referral Code',
    dataIndex: 'coupon_code',
    key: 'coupon_code',
    align: 'center',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center'
  },
  {
    title: 'Is Used',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.is_used.toString()
    }
  },
  {
    title: 'Referral Name',
    dataIndex: 'referral_name',
    key: 'referral_name',
    align: 'center'
  },
  {
    title: 'Referral Mob',
    dataIndex: 'referral_mobile',
    key: 'referral_mobile',
    align: 'center',
  },
]

export const customerRefundsColumns1 = [
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center'
  },
  {
    title: 'Mode',
    dataIndex: 'mode_of_refund',
    key: 'mode_of_refund',
    align: 'center'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center'
  },
]

export const customerRefundsColumns2 = [
  {
    title: 'Product',
    dataIndex: 'product_name',
    key: 'product_name',
    align: 'center',
  },
  {
    title: 'Pack Size',
    dataIndex: 'pack_size',
    key: 'pack_size',
    align: 'center'
  },
  {
    title: 'Refund Qty',
    dataIndex: 'refund_qty',
    key: 'refund_qty',
    align: 'center'
  },
  {
    title: 'Refund Amt',
    dataIndex: 'refund_amount',
    key: 'refund_amount',
    align: 'center'
  },
  {
    title: 'Raised From',
    dataIndex: 'raised_from',
    key: 'raised_from',
    align: 'center'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center'
  },
]

export const customerHistoryColumns = [
  {
    title: 'Order No',
    dataIndex: 'order_number',
    key: 'order_number',
    align: 'center'
  },
  {
    title: 'Confirmed At',
    dataIndex: 'confirmed_at',
    key: 'confirmed_at',
    align: 'center'
  },
  {
    title: 'Delivered At',
    dataIndex: 'delivered_at',
    key: 'delivered_at',
    align: 'center'
  },
  {
    title: 'Nett Amt',
    dataIndex: 'nett_amount',
    key: 'nett_amount',
    align: 'center'
  },
  {
    title: 'Order Status',
    dataIndex: 'order_status',
    key: 'order_status',
    align: 'center'
  },
  {
    title: 'Order Type',
    dataIndex: 'order_type_detail',
    key: 'order_type_detail',
    align: 'center'
  },
  {
    title: 'Darkstore Name',
    dataIndex: 'darkstore_name',
    key: 'darkstore_name',
    align: 'center'
  },
]

export const nestedOrderColumns = [
  {
    title: 'Name',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.product.name
    }
  },
  {
    title: 'Pack Size',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.product.pack_size
    }
  },
  {
    title: 'Ordered Qty',
    dataIndex: 'ordered_qty',
    key: 'ordered_qty',
    align: 'center',
  },
  {
    title: 'Delivered Qty',
    dataIndex: 'delivered_qty',
    key: 'delivered_qty',
    align: 'center',
  },
  {
    title: 'Nett Amount',
    dataIndex: 'nett_amount',
    key: 'nett_amount',
    align: 'center',
  },
]

export const couponColumns = [
  {
    title: 'Coupon',
    dataIndex: 'coupon_code',
    key: 'coupon_code',
    align: 'center',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
  },
  {
    title: 'Discount Type',
    dataIndex: 'discount_type',
    key: 'discount_type',
    align: 'center',
  },
  {
    title: 'Discount Value',
    dataIndex: 'discount_percentage_or_value',
    key: 'discount_percentage_or_value',
    align: 'center',
  },
  {
    title: 'Valid From',
    dataIndex: 'valid_from',
    key: 'valid_from',
    align: 'center',
  },
  {
    title: 'Valid to',
    dataIndex: 'valid_to',
    key: 'valid_to',
    align: 'center',
  },
  {
    title: 'Created By',
    dataIndex: 'created_by',
    key: 'created_by',
    align: 'center',
  }
]
