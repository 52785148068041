import React, { useEffect, useState, useRef } from "react";
import { LandingClusterService } from '../../../_services/sdui/landing_cluster';
import { ReloadOutlined } from "@ant-design/icons";
import { Row, Card, Col, Button, Table, Pagination, Spin, Input } from "antd";
import { bannerColumns } from './columns';
import CreateBanners from "./create_banners";

const lc_service = new LandingClusterService()

const initialFilters = {
  page: 1,
  clusterSearch: null,
  isSearch: true
}

const initialState = {
  btnDisable: true,
  spinner: false,
  err: false
}

function Banner(props) {
  const modalRef = useRef(null);
  const [listingData, setListingData] = useState(false)
  const [filters, setFilters] = useState(initialFilters)
  const [state, setState] = useState(initialState)

  useEffect(() => {
    filters.isSearch && getListing()
  }, [filters])

  function getListing() {
    setState(prevState => ({ ...prevState, spinner: true }))
    let urlParam = `page=${filters.page}&by_cluster_title=${filters.clusterSearch ?? ''}&by_type_of_cluster=banners`
    lc_service.getClusterList(urlParam).subscribe((r) => {
      setState(prevState => ({ ...prevState, spinner: false, total: r.response.meta.total_pages * 50, err: false }))
      setListingData(r.response.data)
    }, (err) => {
      setState(prevState => ({ ...prevState, spinner: false, err: true }))
      setListingData([])
    })
  }

  function onChangeFilters(value, type, searchNow) {
    setFilters(prevState => ({ ...prevState, [type]: value, isSearch: searchNow }))
    setState(prevState => ({ ...prevState, btnDisable: false }))
  }

  return (
    <div className="layer">
      {listingData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Input placeholder="Search Banner" onChange={(e) => onChangeFilters(e.target.value, "clusterSearch", false)}
                  value={filters.clusterSearch} className="frz-w-200 frz-m-10" />
                <Button disabled={state.btnDisable} onClick={() => { onChangeFilters(1, 'page', true) }}
                  className="frz-btn" type="primary">Search</Button>
                <Button disabled={state.btnDisable} onClick={() => { setFilters(initialFilters); setState(initialState) }}
                  type="primary" className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
                <span className="fl-right">
                  <Button onClick={() => modalRef.current.showModal(true, 'Create Banners')} className="frz-btn"
                    type="primary">Create Banners</Button>
                </span>
              </Col>
            </Card>
          </Row>
          {listingData.length > 0 ?
            <Spin spinning={state.spinner}>
              <Row>
                <Col span={24}>
                  <Card className="frz-mt-5">
                    <Table dataSource={listingData} columns={bannerColumns((value) => modalRef.current.showModal(value, 'Edit Clusters'))} pagination={false}
                      scroll={{ y: 'calc(100vh - 331px)' }} rowKey={'id'} />
                  </Card>
                  <Pagination current={filters.page} pageSize={50} total={state.total} className="frz-mt-5"
                    onChange={(e) => onChangeFilters(e, 'page', true)} showSizeChanger={false} />
                </Col>
              </Row>
            </Spin>
            :
            <div className="no-data">{state.err ? 'Backend API System Down' : 'No Data Available'}</div>
          }
          <CreateBanners ref={modalRef} lc_service={lc_service} getListing={getListing} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default Banner