import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { Modal, Select, Card, Table, Spin } from 'antd';
import { DarkstoreService } from '../../_services/darkstore';
import { openNotificationWithIcon } from '../../_modules/notification.js';
const darkstore_service = new DarkstoreService();
const initialModal = {
  data: false,
  title: false,
  loading: false,
  visible: false,
  spinner: false,
  err: false
}

const OverageModal = forwardRef((props, ref) => {
  const [modalState, setModalState] = useState(initialModal)
  const [ordersColm, setOrdersColm] = useState(false);

  useEffect(() => {
    if (modalState.data) {
      getSKU(props.id);
    }
  }, [modalState.data])

  useImperativeHandle(ref, () => ({
    handleCancel: handleCancelModal,
    showModal(data, title) {
      setModalState(prevState => ({ ...prevState, data: data, title: title, visible: true }))
    },
    stopLoading() {
      setModalState(prevState => ({ ...prevState, loading: false }))
    }
  }));

  function handleCancelModal() {
    setModalState(initialModal)
  }

  function getSKU(id) {
    setModalState(prevState => ({ ...prevState, spinner: true }))
    setOrdersColm(false)
    darkstore_service.get_sku_level_view(id).subscribe((r) => {
      setModalState(prevState => ({ ...prevState, spinner: false, }))
      setOrdersColm(r.response.data);
    },
      (err) => {
        setModalState(prevState => ({ ...prevState, spinner: false, err: true }))
        openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
      }
    );
  }

  return (
    <div>
      {modalState.data ?
        <Modal
          visible={modalState.visible}
          title={"Order ID - " + modalState.title}
          width={'98%'}
          onCancel={handleCancelModal}
          footer={null}
          >
          {ordersColm ?
            <>
              {ordersColm.length > 0 && !modalState.err ?
                (<Spin spinning={modalState.spinner}>
                    <Table columns={props.skuCol}
                      pagination={false} rowKey="sku_code"
                      scroll={{ x: 240, y: 'calc(100vh - 254px)' }} dataSource={ordersColm}
                    />
                </Spin>
                )
                : <div className="no-data">{modalState.err ? 'Backend API System Down' : 'No Data Available'}</div>
              }
            </>
            :
            <div className="spin-center">
              <Spin tip="...Loading" />
            </div>}
        </Modal>
        : null}
    </div >

  )
})

export default OverageModal;