import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Pagination, Table } from 'antd';
import { AdsService } from "../../_services/ads";
import { promotionListingColumns } from "../../components/antd/columns/ads";
import { openNotificationWithIcon } from '../../_modules/notification'
import { BannersService } from '../../_services/banners';
import { EditOutlined } from '@ant-design/icons';
import PromotionPageModal from '../../components/ads/promotions/promotionPage_modal';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ClusterProductTable from '../../components/ads/promotions/clusterTable';

const ads_service = new AdsService();
const banner_service = new BannersService();


const PromotionPage = () => {
  const modalRef = useRef(null);
  const [listingData, setListingData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [total, setTotal] = useState(false)
  const [current, setCurrent] = useState(1);
  const [status, setStatus] = useState(false)
  const [templates, setTemplates] = useState(false)
  const [nestedCol, setNestedCol] = useState(false)
  const [products, setProducts] = useState(false)
  const [clutserTags, setClutserTags] = useState(false)
  const [updateData, setUpdateData] = useState(false);
  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getListing()
      getStatusAndTemplates()
      getProducts()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getListing() {
    setSpinEnable(true)
    setListingData([])
    const payload = {
      page: current,
    }
    ads_service.get_pages(payload.page).subscribe((r) => {
      setSpinEnable(false);
      setTotal(r.response.meta.total_pages * 50);
      setListingData(r.response.data);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  function getProducts() {
    banner_service.get_cluster_list().subscribe((r) => {
      let newResponse = r.response.data
      setProducts(newResponse)
      setSpinEnable(false);
    },
      (err) => {
        console.log(err)
      })
  }

  function getStatusAndTemplates() {
    banner_service.get_statusAndTemplate().subscribe((r) => {
      let statusResponse = r.response.data.statuses
      let templatesResponse = r.response.data.templates
      let clutserTagsResponses = r.response.data.clutser_tags
      setStatus(statusResponse)
      setTemplates(templatesResponse)
      setClutserTags(clutserTagsResponses)
    },
      (err) => {
        console.log(err)
      })
  }

  function getColumns() {

    let tempNestedCol = []
    tempNestedCol.push(
      {
        title: "Cluster Tag",
        dataIndex: "cluster_tag",
        key: "cluster_tag",
        align: "center",
      },
      {
        title: 'Created By',
        dataIndex: 'created_by_name',
        key: 'created_by_name',
        align: 'center'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center'
      },

    );
    promotionListingColumns.map(data => {
      columns.push(data)
    })
    columns.push(
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
          return (
            <Button title="Edit" icon={<EditOutlined />}
              onClick={() => showModal(record, 'Edit')}>
            </Button>
          );
        },
      });
    setColumns(columns)
    setNestedCol(tempNestedCol)
  }

  function showModal(data, type) {
    modalRef.current.showModal(type, data)
  }

  function pagination(page) {
    setCurrent(page);
    setListingData([]);
    setRenderData(false);
  }

  function modalSubmit(payloadData, id) {
    const payload = {
      id: id,
      body: {
        promotional_page: {
          ...payloadData
        }
      }
    }
    if (id) {
      ads_service.update_pages(payload).subscribe((r) => {
        openNotificationWithIcon('success', 'Edited Successfully')
        if(r.response.data){
          listingData[listingData.findIndex(x => x.id === r.response.data.id)]=r.response.data
          setListingData([...listingData])
          setUpdateData(r.response.data);
        }
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors?.[0] ?? 'API Error')
        })
    } else {
      ads_service.create_pages(payload.body).subscribe((r) => {
        openNotificationWithIcon('success', 'Created Successfully')
        if(r.response.data){
          let newListingData=listingData
          newListingData.push(r.response.data)
          setListingData([...newListingData])
        }
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors?.[0] ?? 'API Error')
        })
    }
  }

  function expandedRow(record) {
    return <Card className="nested-table">
      <ClusterProductTable nestedTableData={record}
        nestedCol={nestedCol} updateData={updateData} setUpdateData={setUpdateData}
        listingData={listingData}
        getListing={getListing}
      />
    </Card>
  }

  return (
    <div className="layer-nofilter">
      {listingData && products ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Button onClick={() => showModal(true, 'Create Landing Page')} className="frz-dwn"
                  type="primary">Create Landing Page</Button>
              </Col>
            </Card>
          </Row>
          {listingData.length > 0 ?
              <Row className="frz-log-detail-humanize" >
                <Card className="freebieCard">
                  <Row>
                    <Col span={24}>
                      <DndProvider backend={HTML5Backend}>
                        <Table dataSource={listingData} columns={columns}
                          pagination={false}
                          scroll={{ x: 240, y: 'calc(100vh - 305px)' }}
                          expandedRowRender={expandedRow}
                          rowKey={'id'}
                        />
                      </DndProvider>
                    </Col>
                  </Row>
                </Card>
                <Pagination className='frz-tm-10' current={current}
                        pageSize={50} total={total} onChange={pagination} showSizeChanger={false} />
              </Row>
            : (spinEnable ?
              <div className="spin-center"><Spin tip="...Loading" /></div>
              :
              <div className="spin-center"><Spin tip="...Loading" /></div>)
          }
          <PromotionPageModal products={products} modalSubmit={modalSubmit} ref={modalRef} status={status} templates={templates} 
          clutserTags ={clutserTags} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default PromotionPage




