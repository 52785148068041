import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import {
  Modal, Button, Select, Input, InputNumber, DatePicker,
  Radio, ConfigProvider, Row, Col, Checkbox, Popconfirm, Spin
} from 'antd';
import { openNotificationWithIcon } from '../../_modules/notification';

const { Option } = Select;

const AllowCancelReschedule = forwardRef((props, ref) => {

  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [loading, setLoading] = useState(false)
  
  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

  }));

  function handleCancelModal() {
    setVisible(false)
  }

  function updateOrder(e, val){
    setLoading(val.action_type)
    const payload = {
      order_id: data.id,
      action_initiated: val.action_type
    }
    props.order_service.update_allow_cr(payload).subscribe((r) => {
      setLoading(false)
      openNotificationWithIcon('success','Update Sucessfully')
      props.getOrders()
      setVisible(false)
    },
    (err) => {
      console.log(err)
      openNotificationWithIcon('error',err?.response?.errors?.[0] ?? 'API Error')
      setLoading(false)
    })
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onCancel={handleCancelModal}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
          ]}
        >
          <Row gutter={[16, 16]}>
            {data.order_action_buttons?.buttons.map((val, index) => {
              return (
                <Col span={12} className="frz-text-center" key={index}>
                  <Popconfirm
                    title="Are you sure you want to update this?"
                    onConfirm={(e) => updateOrder(e, val)}
                    okText="Yes"
                    cancelText="No"
                    placement="leftTop"
                    disabled={val.show === 'inactive'}
                  >
                    <Spin size="small" spinning={loading === val.action_type}>
                      <Checkbox
                        className={val.show === 'inactive' ? "frz-custom-checkbox-test frz-w-100" 
                          : "frz-custom-checkbox-disable-test frz-w-100"}
                        checked={val.show === 'inactive'}
                        defaultChecked={val.show === 'inactive'}
                        disabled={val.show === 'inactive'}>
                        <div className={val.show  === 'inactive' ? "frz-custom-checkbox-text frz-w-100 left-0" 
                          : "frz-custom-checkbox-text-disable frz-w-100 left-0"}>
                          {val.action_label}
                        </div>
                      </Checkbox>
                    </Spin>
                    <div className='frz-tp-20'>{val.text}</div>
                  </Popconfirm>
                </Col>
              )
            })}
          </Row>
        </Modal>
      : null}
    </div>
  )
})

export default AllowCancelReschedule
