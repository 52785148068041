import React, {useEffect, useState} from 'react';
import {Card , Radio, Spin, Select, DatePicker} from 'antd';
import { Pie} from 'react-chartjs-2';
import {StatisticsService} from '../../_services/statistics';
import moment from 'moment';
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;

const stats_service =  new StatisticsService()

function Review(){
    const [statisticalData, setStatisticalData] = useState(false)
    const [renderData, setRenderData] = useState(false)
    const [renderDataGraph, setRenderDataGraph] = useState(false)
    const [state, setState] = useState({type: 'daily', start_date: '', end_date: '', showDateValue: false});
    const presetData = {
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [
            '#FF6384','#36A2EB','#FFCE56','green','orange','brown','cyan','darkgrey','pink','purple','#99ff66','#808000','#145252','#4d0019','#b3d1ff','#660066','#804000','#669999','#808000'
            ],
            data1: [],
            data2: [],
            data3: [],
        }]
    } 
    const [graphData, setGraphData] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [
                '#FF6384','#36A2EB','#FFCE56','green','orange','brown','cyan','darkgrey','pink',
                'purple','#99ff66','#808000','#145252','#4d0019','#b3d1ff','#660066','#804000','#669999','#808000',
                '#cacfd2', '#e67e22', '#1e8449', '#16a085', '#f4d03f', '#DAF7A6',
                '#2980b9', '#cb4335', '#af7ac5', '#884ea0', '#1f618d', '#138d75',
                '#f5b041', '#f5cba7', '#f9e79f', '#73c6b6', '#f5b7b1', '#c39bd3',
                '#2e4053', '#f5b7b1', '#d7bde2', '#a569bd', '#f5b041', '#e59866',
                '#7dcea0','#1abc9c','#2e86c1','#cb4335','#5b2c6f','#b7950b'
            ],
            data1: [],
            data2: [],
            data3: [],
        }]
    })
    const { type, start_date, end_date, showDateValue } = state

    useEffect(() => {
        if(!renderData){
          setRenderData(true)
          getReview()
        }
      })

    function getReview(){
        const payload = {
          type: type === undefined ? '' : type,
          start_date: start_date,
          end_date: end_date
        }
        stats_service.get_review(payload).subscribe((r) => {
          setStatsData(r.response.data)
          setStatisticalData(r.response.data)
        },
        (err)=>{
          console.log(err)
        })
    }

    function onChangeRangePicker(value, dateString){
        setState(prevState =>({...prevState, showDateValue: true, start_date :dateString[0], end_date:dateString[1], type: undefined}))
        setRenderDataGraph(false)
        setRenderData(false)
        setGraphData(presetData)
    }

    function onChange(value, type) {
        setState(prevState =>({...prevState, [type]: value, start_date: '', end_date: '', showDateValue: false}))
        setRenderDataGraph(false)
        setRenderData(false)
        setGraphData(presetData)
     }

    function setStatsData(data) {
        if(data.stats_data){
            data.stats_data.map(val => {
                graphData.labels.push(val.darkstore_data.name.slice(20))
                graphData.datasets[0].data.push(val.reviews_count)
                graphData.datasets[0].data1.push(val.good_review_count)
                graphData.datasets[0].data2.push(val.bad_review_count)
                graphData.datasets[0].data3.push(val.orders_delivered_count)
            })
        }
        // console.log(graphData)
        setGraphData(graphData)
        setRenderDataGraph(true)
    }

    function disabledDate(current){
        // Can select days before today and today
        return current && current > moment().add(0, "day");
      }

    return(
        <div className="">
            <Card>
                <h2 style={{margin: 10}}>Reviews : </h2>
                <div style={{margin: 10, borderBottom: '1px solid rgb(240,240,240', paddingBottom: 20}}>
                    <Select style={{ width: 150}} value={type}
                            placeholder="Select Type" onChange={(e) => onChange(e, 'type')}>
                        <Option value='daily'>Yesterday</Option>
                        <Option value='weekly'>Weekly</Option>
                        <Option value='this_month'>This Month</Option>
                        <Option value='last_month'>Last Month</Option>
                        <Option value='quarterly'>Quarterly</Option>
                        {/* <Option value='half_yearly'>Half Yearly</Option> */}
                    </Select>
                    <RangePicker onChange={onChangeRangePicker} style={{float:'right'}}
                            value={showDateValue ? [moment(start_date, dateFormat),
                            moment(end_date, dateFormat)] : '' } format={dateFormat}
                            allowClear={false} disabledDate={disabledDate}/>
                </div>
                <div style={{margin: 10, borderBottom: '1px solid rgb(240,240,240', 
                             paddingBottom: 10, display: 'flex', justifyContent: 'space-between'}}>
                    <div><b>Total Orders:</b> {statisticalData.total_orders_count}</div>
                    <div><b>Total Reviews:</b> {statisticalData.total_reviews_count}</div>
                </div>
                {renderDataGraph ? 
                   <div>
                        <Pie data={graphData}
                            options={{
                                    tooltips: {
                                        titleFontSize:13,
                                        bodyFontSize:15,
                                        enabled: true,
                                        displayColors:false,
                                        callbacks: {
                                            title: (tooltipItem, data) => {
                                                return data['labels'][tooltipItem[0]['index']];;
                                            },
                                            label: (tooltipItem, data) => {
                                                let allData = data.datasets[tooltipItem.datasetIndex].data.reduce((a, b) => a + b, 0);
                                                let tooltipData = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                                let percentage =(tooltipData/allData * 100).toFixed(2);
                                                return `Review Count : ${tooltipData.toLocaleString('en-IN')} (${percentage}%)`;
                                            },
                                            beforeLabel: (tooltipItem, data) => {
                                                let tooltipData = data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index];
                                                let tooltipData2 = data.datasets[tooltipItem.datasetIndex].data2[tooltipItem.index];
                                                return `Good Review Count : ${tooltipData.toLocaleString('en-IN')} || Bad Review Count : ${tooltipData2.toLocaleString('en-IN')}`
                                                        
                                            },
                                            // afterLabel: (tooltipItem, data) => {
                                            //     let allData = data.datasets[tooltipItem.datasetIndex].data.reduce((a, b) => a + b, 0);
                                            //     let tooltipData = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                            //     let percentage =(tooltipData/allData * 100).toFixed(2);
                                            //     return `Percentage : ${percentage}%`;
                                            // }
                                        }
                                    }
                            }}
                            legend={{"display": false}}
                            width={50} height={35} redraw={true}
                        />
                   </div>
                : 
                  <div className="spin-center">
                    <Spin tip="...Loading"/>
                  </div>
                }
            </Card>
        </div>
    )
}

export default Review