import React, { useEffect, useState } from 'react';
import MainTable from '../antd/table';
import { Row, Col, Table} from 'antd';
import {refundItemColumns} from '../antd/columns/orders';
import {handleDateFormat} from '../../_modules/date';

function RefundItems(props){
  const [columns, setColumns] = useState([])

  useEffect(() => {
    getColumns()
  }, [])

  function getColumns(){
    let tempCol = []
    refundItemColumns.map(data => {
      tempCol.push(data)
    })
    tempCol.splice(1, 1, {
      title: props.title  + ' Qty',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return(
          <div>
            {record.refund_qty} {record.refunded_unit_line_items_count ? ' (' + record.refunded_unit_line_items_count + ') ' : null}
          </div>
        )
      }
    });
    tempCol.splice(2, 3, {
      title: props.title  + ' Amt',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return(
          <div>
            {record.refund_amount}
          </div>
        )
      }
    });
    tempCol.push({
      title: 'Created By',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return(
          <div>
            {record.admin_name}
          </div>
        )
      }
    });
    tempCol.push({
      title: 'Created At',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return(
          <div>
            {handleDateFormat(record.line_item.updated_at)}
          </div>
        )
      }
    });
    setColumns(tempCol)
  }

  return(
    <div>
      <Row>
        <Col span={24}>
          {props.orderDetail.length > 0 ?
            <Table columns={columns} dataSource={props.orderDetail}
                   pagination={false} scroll={{ x: 240 }}
                   rowKey="id"
                   expandable={{
                    expandedRowRender: record => <Table columns={columns} dataSource={record.sub_line_items}
                        pagination={false} scroll={{ x: 240 }}
                        rowKey="id"/>,
                    rowExpandable: record => record?.sub_line_items?.length > 0,
                  }}/>
          :
            <h3 style={{textAlign: 'center', paddingTop: 24}}> No {props.title } Created</h3>
          }
        </Col>
      </Row>
    </div>
  )
}

export default RefundItems
