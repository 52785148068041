import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber, Table,
         Pagination, notification } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {CustomerService} from '../../_services/customer';
import Search from '../../components/antd/search';
import MainTable from '../../components/antd/table';
import {referralRewardColumns} from '../../components/antd/columns/customers';

const customer_service = new CustomerService()

function ReferralReward() {
  const detailRef = useRef(null);
  const [referralData, setReferralData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [state, setState] = useState({
    valueByMobno: undefined
  });
  const { valueByMobno } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getReffral()
    }
  })

  useEffect(() => {
    getColumns()
    getReffral()
  }, [])

  function getReffral(){
    setSpinEnable(true)
    setReferralData([])
    const payload = {
      page: current,
      valueByMobno: valueByMobno === undefined ? '' : valueByMobno
    }
    customer_service.get_customer_reward_list(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setSpinEnable(false)
      setReferralData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    referralRewardColumns.map(data => {
      columns.push(data)
    })
    setColumns(columns)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function reset() {
    setState({
      valueByMobno: undefined
    })
    setDisable(true)
    setCurrent(1)
    setReferralData([])
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setReferralData([])
    setRenderData(false)
  }

  return(
    <div className="layer">
      {referralData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <InputNumber placeholder="Mobile No"
                             onChange={(e) => onChange(e, 'valueByMobno')}
                             value={valueByMobno} style={{width: 200, margin: 10}}/>
                <Button disabled={disable} onClick={getReffral} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
              </Col>
            </Card>
          </Row>
          {referralData.length > 0 ?
            <Row>
              <Col span={24}>
                {/* <MainTable dataSource={referralData} columns={columns}/> */}
                <Card style={{ paddingTop: 5, marginTop: 20 }}>
                    <Row>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                dataSource={referralData}
                                pagination={false}
                                scroll={{ x: 240 }}
                                rowKey="customer_name"
                            />
                        </Col>
                    </Row>
                </Card>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default ReferralReward
