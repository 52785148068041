import React, { useState } from 'react'
import { Modal, Button, Form, Upload, message, Space, Input } from 'antd';
import { formItemLayout } from '../../../_modules/controllayout';
import { LoadingOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import FRZUpload from '../../../_controls/FRZUpload';
import FRZRadioButton from '../../../_controls/FRZRadioButton';
import FRZInput from '../../../_controls/FRZInput';
import { ErrorNotification } from './notification';
import { ProductService } from '../../../_services/product'
import { openNotificationWithIcon } from '../../../_modules/notification';

const product_service = new ProductService()

let tempList = []
const ModalImage = ({ showModal, handleModal, rowData, uploadButton, handleChange,
  imageUrl, handleImageUrl, onImageAddUpdate, loading, handleLoading, productId, getMultipleImages }) => {

  const [form] = Form.useForm()
  const [imageList, setImageList] = useState(false)

  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  function getBase64File(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const beforeUpload = (file) => {
    console.log("test 11", form.getFieldValue());

    const reader = new FileReader();
    reader.addEventListener('load', file);
    reader.readAsDataURL(file);
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml' || file.type === 'image/webp';

    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG/SVG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    console.log("first", isJpgOrPng && isLt2M);

    return isJpgOrPng && isLt2M;
  }


  const handleChangeMultipleUpload = (info, index) => {

    if (info.file.status === undefined) {
      form.setFieldsValue({ imageList: [undefined] })
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        //if editing the existing image
        if (tempList[index]) {
          tempList[index] = imageUrl
          setImageList({ ...tempList })
        } else {
          tempList.push(imageUrl)
          setImageList({ ...tempList })
          // tempList.push(imageUrl)
        }
      }
        // this.setState({
        //   imageUrl,
        //   loading: false,
        // }),
      );
    }
  };

  const handleOnImageRemove = (index) => {
    tempList.splice(index, 1);
    setImageList(tempList)
  }


  const onCreate = async (value) => {
    if (rowData) {
      let imgValid = true
      let payload = {
        ...value,
      }
      if (imageUrl) {
        payload.image = imageUrl
        imgValid = await imageVerification(imageUrl)
      }
      if (rowData) {
        payload.id = rowData.id
      }
      if (imgValid || imgValid === undefined) {
        onImageAddUpdate(payload)
      }
    } else {
      onFinish(value)
    }
  }

  const onFinish = async values => {
    let allPrimis = []
    let tempPayload = [];
    if (values.imageList) {
      values.imageList.map((data) => {
        allPrimis
          .push(getBase64File(data?.image?.file.originFileObj));
      })
      let res = await Promise.all(allPrimis)
      values.imageList.map((data, index) => {
        tempPayload.push({ image: res[index], tag: data.tag, fileDetail: data.image })
      })
    }

    handleOk(tempPayload)
  };

  const imageVerificationList = async (fileData) => {
    for (let i = 0; i <= fileData.length - 1; i++) {
      var dimensions = await getImageDimensions(fileData[i].image, fileData[i].fileDetail)
      let isValidate = checkImageDimension(dimensions.w, dimensions.h, dimensions.n)
      if (!isValidate) {
        return false
      }
    }
  }

  const handleOk = async (imageListPayload) => {
    let imgValid = await imageVerificationList(imageListPayload)
    if (!imgValid && imgValid !== undefined) {
      return
    }
    if (!imageListPayload.length) {
      ErrorNotification("Please upload at least one image");
      return;
    }
    const payload = { images_attributes: imageListPayload }

    handleLoading(true)
    product_service.add_multiple_images_to_existing(payload, productId).subscribe((r) => {
      openNotificationWithIcon("success", "Images Added Successfully");
      handleModal(false)
      handleImageUrl(undefined)
      setImageList([])
      handleLoading(false)
      //update latest data
      const payloadGetData = {
        type: "Product",
        id: productId
      }
      getMultipleImages(payloadGetData)
      tempList = []
    }, ((err) => {
      console.log("err", err)
      handleLoading(false)
    }))
  }

  function getImageDimensions(file, detail) {
    return new Promise(function (resolved, rejected) {
      var img = new Image()
      img.onload = function () {
        resolved({
          w: img.width, h: img.height, n: detail ? detail.file.name : false
        })
      };
      img.src = file
    })
  }

  const checkImageDimension = (w, h, n) => {
    let isAccepted = false;
    if (w <= 512 && h <= 512) {
      if (h === w) {
        isAccepted = true
      } else {
        isAccepted = false
        ErrorNotification(`Uploaded image ${n ? n : ""} dimension ${w} x ${h} is not equal,
           please upload equal dimension's image.`)
      }
      // upload logic here
    } else {
      isAccepted = false
      ErrorNotification(`Uploaded image dimension ${w} x ${h} is too large,
         please upload standard 512 x 512 dimension image.`)
    }
    return isAccepted;
  }

  const imageVerification = async (imageUrl) => {
    var dimensions = await getImageDimensions(imageUrl, false)
    let isValidate = checkImageDimension(dimensions.w, dimensions.h, false)
    if (!isValidate) {
      return false
    }
  }

  function handleClick({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }


  return (
    <div>
      <Modal
        visible={showModal}
        title={!rowData ? "Add Image" : "Edit Image"}
        okText={!rowData ? "Add" : "Update"}
        okButtonProps={{ loading: loading }}
        cancelText="Cancel"
        onCancel={() => {
          handleModal(false)
          handleImageUrl(undefined)
          setImageList([])
          tempList = []
        }}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onCreate(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}>
        {rowData ?
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            initialValues={rowData ? { ...rowData } : null}
            name="form">

            <>
              {/* /---------------------------/ uncomment below if image is required in edit /---------------------------/  */}

              {/* <div className="block" style={{ display: "flex", marginLeft: 66 }}>
                Upload Image :
                <span style={{ paddingLeft: 10 }}>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    customRequest={handleClick}
                    onChange={handleChange}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </span>
              </div> */}

              {/* /---------------------------/ -------------------------------------- /---------------------------/  */}

              <FRZInput name="tag" isRequired={true}
                label="Tag" placeholder="Tag" width={200} />

              <FRZRadioButton isRequired={true} label="Is Active" name="is_active" />
            </>
          </Form>
          :
          <div className="frz-multiple-image-uploader">
            <Form form={form} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
              <Form.List name="imageList">
                {(fields, { add, remove }) => (
                  <React.Fragment key={uuidv4()}>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Space key={key}>
                        <Form.Item
                          {...restField}
                          name={[name, 'image']}
                          rules={[{ required: true, message: 'Missing image' }]}
                        // getValueFromEvent={getFile}
                        >
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="/upload.do"
                            customRequest={handleClick}
                            beforeUpload={beforeUpload}
                            onChange={(e) => handleChangeMultipleUpload(e, name)}>
                            {imageList[name] ? <img src={imageList[name]} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                          </Upload>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'tag']}
                          rules={[{ required: true, message: 'Missing tag' }]}>
                          <Input placeholder="Tag" />
                        </Form.Item>
                        <MinusCircleOutlined style={{ marginBottom: 25 }} onClick={() => {
                          remove(name)
                          handleOnImageRemove(name)
                        }} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add Image
                      </Button>
                    </Form.Item>
                  </React.Fragment>
                )}
              </Form.List>
            </Form>
          </div>
        }
      </Modal>
    </div>
  )
}

export default ModalImage
