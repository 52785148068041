import React, { useEffect, useState } from "react";
import { Select, Button, Spin, Radio } from "antd";
import { ProductService } from "../../../_services/product";
import { ErrorNotification, SuccessNotification } from "./notification";
import { useSelector } from "react-redux";

const { Option } = Select;

function ProductInStockActivation(props) {
  const darkstore = useSelector(state => state.auth.darkstore);
  const productService = new ProductService();
  const [productDetails, setProductDetails] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [details, setDetails] = useState(false);

  const inputStyle = {
    marginRight: 100,
    textAlign: "right",
  };

  useEffect(() => {
    getProductDetails();
  }, []);

  function getProductDetails() {
    productService.getProductInstock(props.productId).subscribe(
      (r) => {
        setProductDetails(r.response.data);
        setDetails(r.response.data.all_darkstores)
      },
      (err) => {
        ErrorNotification("Failed to get details");
      }
    );
  }

  function saveProductDetails() {
    if(productDetails.all_darkstores === null){
      ErrorNotification("Please set darkstore");
      return;
    }
    if(productDetails.all_darkstores === false && productDetails.active_darkstores.length === 0){
      ErrorNotification("Please select darkstores");
      return;
    }
    if(productDetails.all_darkstores){
      productDetails.active_darkstores = []
    }
    setButtonLoading(true);
    productDetails.id = props.productId
    productDetails.by_city = localStorage.getItem('city')
    productService
      .updateProductInstock(productDetails)
      .subscribe(
        (r) => {
          SuccessNotification("Updated successfully");
          setButtonLoading(false);
        },
        (err) => {
          ErrorNotification("Failed to update details");
          setButtonLoading(false);
        }
      );
  }

  function onInputChange(key, value) {
    productDetails[key] = value;
    if (value === "") {
      productDetails[key] = null;
    }
    if(key === 'all_darkstores'){
      setDetails(value)
    }
  }

  const FZSelectorMultiple = ({ inputName, keyName, data }) => {
    return (
      <div className="block" style={inputStyle}>
        <span>
          <b>{inputName} :</b>
        </span>
        <span style={{ paddingLeft: 10 }}>
          <Select
            style={{ width: 400, textAlign: 'left' }}
            defaultValue={productDetails[keyName] ?? []}
            placeholder={inputName}
            mode="multiple"
            onChange={(e) => onInputChange(keyName, e)}
            showSearch={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {data.map((item) =>
                <Option value={item.id} key={item.id}>{item.name}</Option>
            )}
          </Select>
        </span>
      </div>
    );
  };

  const FZInputRadio = ({ inputName, keyName }) => {
    return (
      <div className="block" style={inputStyle}>
      <span>
        <b>{inputName} :</b>
      </span>
      <span style={{ paddingLeft: 10 }}>
        <Radio.Group
          style={{ width: 400, textAlign: 'left' }}
          placeholder={inputName}
          onChange={(e) => onInputChange(keyName, e.target.value)}
          defaultValue={productDetails[keyName] ?? undefined}
        >
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </span>
    </div>
    );
  };

  return (
    <div style={{ margin: "20px auto" }}>
      {productDetails ? (
        <div style={{ textAlign: "center" }}>
          <FZInputRadio inputName="All Darkstores" keyName="all_darkstores" />
          {details === false ? 
            <FZSelectorMultiple
                inputName="Darkstores"
                keyName="active_darkstores"
                data={darkstore}
            />
          : null}
          <Button
            type="primary"
            style={{ marginTop: 20, width: "100px" }}
            onClick={saveProductDetails}
            loading={buttonLoading}
          >
            Save
          </Button>
        </div>
      ) : (
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      )}
    </div>
  );
}

export default ProductInStockActivation;
