import React, { useEffect, useState } from 'react';
import { Spin, Button, Popconfirm, Form, Slider } from 'antd';
import { DistributionService } from "../../../_services/distribution";
import { openNotificationWithIcon } from '../../../_modules/notification'
import { useSelector } from "react-redux";
import FRZSelect from '../../../_controls/FRZSelect';
import FRZDatepicker from '../../../_controls/FRZDatepicker';
import { formItemLayout } from '../../../_modules/controllayout';
import { tailLayout } from '../../../_modules/controllayout';
import moment from 'moment';

const distribution_service = new DistributionService();

const DeliveryDistribution = () => {

  const [form] = Form.useForm();
  const [spinEnable, setSpinEnable] = useState(false)
  const [count, setCount] = useState(false)
  const [fromDs, setFromDs] = useState(false)
  const [toDs, setToDs] = useState(false)
  const [date, setDate] = useState(false)
  const [slot, setSlot] = useState(false)
  const [slotData, setSlotData] = useState(false)
  const [slotTimeData, setSlotTimeData] = useState(false)
  const [transferCount, setTransferCount] = useState(0)
  const [buttonLoad, setButtonLoad] = useState(false)
  const darkstore = useSelector(state => state.auth.darkstore);

  const marks = {
    0: '0 km',
    1: '1 km',
    2: '2 km',
    3: '3 km',
    4: '4 km',
    5: '5 km',
  };

  function getListing() {
    const payload = { 
      list: 'delivery',
      by_darkstore: fromDs,
      date: date,
      slot: slot
    }
    distribution_service.get_distribution(payload).subscribe((r) => {
      setTransferCount(0)
      form.setFieldsValue({ transfer_percentage: 0 })
      setSpinEnable(false);
      setCount(r.response.count);
    },
      (err) => {
        console.log(err);
      }
    );
  }

  function getSlot(id) {
    const payload = { 
      by_darkstore: id,
    }
    distribution_service.get_distribution_slot(payload).subscribe((r) => {
      let newdata = r.response.map(data => ({...data, 
        time_slot: data.time_slot.map(v => ({ ...v, full_time: v.from_time + " to " + v.to_time + " " + v.am_pm }))
      }))
      setSlotData(newdata)
    },
      (err) => {
        console.log(err);
      }
    );
  }

  function onChange(value, type) {
    if(type === 'fromDs'){
      setFromDs(value)
      getSlot(value)
      setCount(false)
      setDate(false)
      setSlot(false)
      setSlotTimeData(false)
      setToDs(false)
      form.setFieldsValue({ transfer_percentage: 0, by_delivery_date: undefined, 
                            by_delivery_slot: undefined, to_darkstore: undefined })
    }else if(type === 'sliderPercent'){
      setTransferCount((count * value) / 100)
    }else if(type === 'slot'){
      setSlot(value)
    }else if(type === 'date'){ 
      setDate(value)
      setSlot(false)
      form.setFieldsValue({ transfer_percentage: 0, by_delivery_slot: undefined })
      setSlotTimeData(slotData.find(x => x.date === value).time_slot)
    }else{
      setToDs(value)
    }
  }

  useEffect(() => {
    if(fromDs && date && slot){
      setSpinEnable(true)
      getListing()
    }
  }, [fromDs, date, slot])

  function formatter(value) {
    return `${value}%`;
  }

  function onFinish(values){
    if(values.transfer_percentage === 0 || values.radius_in_km === 0){
      openNotificationWithIcon('error', 'Transfer Percentage & Radius cannot be 0')
      return
    }else{
      setButtonLoad(true)
      values.list = 'delivery'
      distribution_service.update_distribution(values).subscribe((r) => {
        setButtonLoad(false);
        form.resetFields()
        setFromDs(false)
        setToDs(false)
        setCount(false)
        setDate(false)
        setSlot(false)
        setSlotData(false)
        setSlotTimeData(false)
        setTransferCount(0)
        openNotificationWithIcon('success', 'Delivery Distribution Success')
      },
        (err) => {
          console.log(err);
          setButtonLoad(false);
          openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
        }
      );
    }
  }

  return (
    <div>
      <h2>Delivery Distribution</h2>
      <div className="frz-mt-40">
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">

          <FRZSelect DropDownData={darkstore} name="from_darkstore" isRequired={true}
            label="From Darkstore" placeholder="From Darkstore" value="id" option="name"
            isShowSearch={true} width={300} setOnChange={(e) => onChange(e, 'fromDs')} isDisabledVal={[toDs]}
            optionDisabledKey='id'/>

          {slotData ? 
          <>
            <FRZSelect DropDownData={slotData} name="by_delivery_date" isRequired={true}
                       label="Delivery Date" placeholder="Delivery Date" value="date" option="date"
                       isShowSearch={true} width={200} setOnChange={(e) => onChange(e, 'date')}
                       isDataKey={true} />
            
            {slotTimeData ? 
              <FRZSelect DropDownData={slotTimeData} name="by_delivery_slot" isRequired={true}
                        label="Time Slot" placeholder="Time Slot" value="id" option="full_time"
                        isShowSearch={true} width={200} setOnChange={(e) => onChange(e, 'slot')} />
            : null}
          </>           
          : null}           

          {count !== false ?   
            <div className="delivery-count">
              Delivery Count :
              <span className="frz-lm-10">
                {!spinEnable ? 
                  <b>{count}</b>
                : 
                  <Spin></Spin>
                }
              </span>
            </div>
          : null}

          <FRZSelect DropDownData={darkstore} name="to_darkstore" isRequired={true}
            label="To Darkstore" placeholder="To Darkstore" value="id" option="name"
            isShowSearch={true} width={300} selectDisabled={count === false} isDisabledVal={[fromDs]}
            optionDisabledKey='id'
            setOnChange={(e) => onChange(e, 'toDs')}/>
          <div className="transfer-count">
            Transfer Count :
            <span className="frz-lm-10">
              <b>{Math.floor(transferCount)}</b>
            </span>
          </div>
          <div className="frz-mt-60"></div>
          <Form.Item
            {...tailLayout}
            name={'transfer_percentage'}
            label={'Transfer Percent'}
            rules={[
              {
                required: true,
                message: `Please input the value of transfer percentage!`,
              }
            ]}>
            <Slider tipFormatter={formatter} tooltipVisible disabled={count === false}
                    onChange={(e) => onChange(e, 'sliderPercent')}/>
          </Form.Item>
          <div className="frz-mt-60"></div>
          <Form.Item
            {...tailLayout}
            name={'radius_in_km'}
            label={'Radius'}
            rules={[
              {
                required: true,
                message: `Please input the value of radius!`,
              }
            ]}>
              <Slider marks={marks} step={null} min={0} max={5} disabled={count === false}/>
          </Form.Item>
          <div className="frz-mt-60"></div>
          <Form.Item wrapperCol={{ offset: 10, span: 24 }}>
            <Popconfirm
                title="Are you sure you want to create this delivery distribution ?"
                onConfirm={() => {
                    form
                    .validateFields()
                    .then(values => {
                        onFinish(values)
                    })
                    .catch(info => {
                        // console.log('Validate Failed:', info);
                    });
                }}
                okText="Yes"
                cancelText="No"
                placement="leftTop"
                disabled={count === false}
            >
              <Button type="primary" disabled={count === false} loading={buttonLoad}>
                Transfer
              </Button>
            </Popconfirm>
          </Form.Item>

        </Form>
      </div>
    </div>
  )
}

export default DeliveryDistribution
