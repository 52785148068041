import React, { useEffect, useState, useRef, useCallback } from "react";
import { Spin, Button, Card, Row, Col, Tabs, Table, Pagination, DatePicker, Input } from "antd";
import { SpecialDiscountService } from "../../_services/specialdiscount";
import { specialDiscountColumns, specialDiscountSeqColumns } from '../../components/antd/columns/specialdiscount';
// import ReferralModal from '../../components/ads/referral_modal';
import { openNotificationWithIcon } from '../../_modules/notification';
import update from 'immutability-helper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, useDrag, useDrop, createDndContext } from 'react-dnd';
import ModalSpecialDiscount from "../../components/master/special_discount/ModalSpecialDiscount";
import { OperationsService } from '../../_services/operations'
import { GeneralService } from '../../_services/general'
import { BannersService } from '../../_services/banners'
import { handleDateFormat } from '../../_modules/date'
import Search from '../../components/antd/search';
import moment from 'moment'
import ModalActivaition from "../../components/master/category/modalactivaition";
import { ReloadOutlined } from '@ant-design/icons';

const special_discount_service = new SpecialDiscountService()
const operations_service = new OperationsService()
const general_service = new GeneralService()
const banner_service = new BannersService()

const { TabPane } = Tabs;
const RNDContext = createDndContext(HTML5Backend);
const type = 'DragableBodyRow';

const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const orderType = [{id: true, name: 'Is First Order'}, {id: false, name: 'Is Not First Order'}]

const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

function SpecialDiscount() {
  const [listingData, setListingData] = useState(false);
  const [renderData, setRenderData] = useState(false);
  const [columns, setColumns] = useState([]);
  const [spinEnable, setSpinEnable] = useState(false);
  const [disable, setDisable] = useState(true);
  const [current, setCurrent] = useState(1);
  const [currentTab, setCurrentTab] = useState(1);
  const [updateData, setUpdateData] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [productListing, setProductListing] = useState(false)
  const [dsListing, setDsListing] = useState(false)
  const [rowData, setRowData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activationModal, setActivationModal] = useState(false)
  const [productList, setProductList] = useState(false);
  const [discountId, setDiscountId] = useState(false)
  const [state, setState] = useState({start_date: '', end_date: '', showDateValue: false,
                                      valueByOrderType: undefined, starts_with: undefined});
  const { start_date, end_date, showDateValue, valueByOrderType, starts_with } = state

  const handleOnActivationModal = (value, record) => {
    if (record) {
      setRowData(record)
      setDiscountId(record.id)
    } else {
      setRowData(false)
      setDiscountId(false)
    }
    setActivationModal(value)
  }


  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = listingData[dragIndex];
      setListingData(
        update(listingData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
      setUpdateData(true)
    },
    [listingData],
  );

  const manager = useRef(RNDContext);

  useEffect(() => {
    if (!renderData) {
      setRenderData(true);
      getListingData(currentTab);
    }
  });

  useEffect(() => {
    getColumns()
  }, [currentTab])

  useEffect(() => {
    if (updateData) {
      setUpdateData(false)
      updateListingSequence()
    }
  })

  useEffect(() => {
    getProductList()
    getDsList()
  }, [])

  const getProductList = () => {
    // operations_service.get_product_list().subscribe((r) => {
    banner_service.get_product_list().subscribe((r) => {
      const obj =[];
      r.response.data.forEach(function (elem) {
        obj.push({
          id: elem.id,
          name: elem.name + ' - ' + elem.sku
        });
      });
      setProductList(obj)
      setProductListing(r.response.data)
    }, ((err) => {
      console.log("err", err)
    }))
  }

  const getDsList = () => {
    general_service.get_darkstore().subscribe((r) => {
      setDsListing(r.response.data)
    }, ((err) => {
      console.log("err", err)
    }))
  }

  function updateListingSequence() {
    let tempData = []
    console.log("listingData", listingData)
    listingData.map((data, index) => {
      console.log("data", data)
      tempData.push({ product_id: data.product_details.id, sequence: index + 1 })
    })
    const payload = {
      special_discounts: tempData
    }
    special_discount_service.update_special_discount_sequence(payload).subscribe((r) => {
      openNotificationWithIcon("success", "Sequence Updated Successfully");
      getListingData(currentTab)
    },
      (err) => {
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
        console.log(err)
      })
  }


  function getListingData(key) {
    setSpinEnable(true);
    setListingData([]);
    const payload = {
      page: current,
      by_valid_from: start_date ,
      by_valid_to: end_date,
      by_first_order_flag: valueByOrderType ?? '',
      starts_with: starts_with ?? ''
    }
    if (key == 1) {
      special_discount_service.get_special_discount(payload).subscribe(
        (r) => {
          setSpinEnable(false);
          setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
          setListingData(r.response.data);
        },

        (err) => {
          console.log(err);
        }
      );
    } else {
      if (localStorage.getItem('city') !== '') {
        special_discount_service.get_special_discount_sequence(payload).subscribe(
          (r) => {
            let tempdata = r.response.data.sort(function (a, b) { return a.sequence - b.sequence });
            setSpinEnable(false);
            setListingData(tempdata);
          },
          (err) => {
            console.log(err);
          }
        );
      } else {
        setListingData([{}])
      }
    }
  }

  function getColumns() {
    let tempCol = []
    if (currentTab == 1) {
      specialDiscountColumns.map(data => {
        tempCol.push(data)
      })
      tempCol.push({
        title: "Actions",
        dataIndex: "",
        key: "",
        align: "center",
        render: (_, record) => {
          return (
            <div className="frz-dis-flex frz-hrz-center">
              <Button
                type="primary"
                onClick={() => {
                  handleModalShow(true, record);
                }}
              >
                Edit
              </Button>
              <Button className="frz-lm-10" type="primary" onClick={() => handleOnActivationModal(true, record)}>Active / Deactive</Button>
            </div>
          );
        },
      });
    } else {
      specialDiscountSeqColumns.map(data => {
        tempCol.push(data)
      })
    }
    setColumns(tempCol);
  }

  function changeTab(key) {
    setCurrentTab(key);
    setListingData([]);
    setCurrent(1);
    setDisable(true);
    setRenderData(false);
  }

  const handleModalShow = (value, data) => {
    if (data) {
      setRowData(data)
    } else {
      setRowData(false)
    }
    setShowModal(value)
  }

  const submitSpecialDiscount = (data) => {
    setLoading(true)
    const payload = {
      valid_from: moment(data.valid_from).format('YYYY-MM-DD'),
      valid_to: moment(data.valid_to).format('YYYY-MM-DD'),
      first_order_flag: data.first_order_flag
      // is_active: data.is_active,
      // active_stores: data.all_stores ? [] : data.active_stores,
      // is_special_discount: false,
      // all_stores: data.all_stores
    }

    if (rowData) {
      payload.id = rowData.id
      // payload.first_order_flag = data.first_order_flag
    } else {
      payload.product_id = parseInt(data.product_id)
    }

    if (rowData) {
      special_discount_service.update_special_discount(payload).subscribe((r) => {
        setShowModal(false)
        setLoading(false)
        getListingData(currentTab)
        openNotificationWithIcon('success', 'Successfully Updated')
      }, ((err) => {
        setLoading(false)
        console.log("err", err)
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      }))
    } else {
      special_discount_service.create_special_discount(payload).subscribe((r) => {
        setShowModal(false)
        setLoading(false)
        getListingData(currentTab)
        openNotificationWithIcon('success', 'Successfully Created')
      }, ((err) => {
        setLoading(false)
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
        console.log("err", err)
      }))
    }
  }

  function pagination(page) {
    setCurrent(page)
    setListingData(false)
    setRenderData(false)
  }

  function onChangeRangePicker(value, dateString){
    setState(prevState =>({...prevState, showDateValue: true, start_date :dateString[0], end_date:dateString[1]}))
    setDisable(false)
    setCurrent(1)
  }

  function disabledDate(current){
    return current && (current < moment().subtract(91, "day"));
  }

  function reset() {
    setState({start_date: '', end_date: '', showDateValue: false, 
              valueByOrderType: undefined, starts_with: undefined})
    setDisable(true)
    setCurrent(1)
    setRenderData(false)
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    setDisable(false)
    setCurrent(1)
  }

  return (
    <div className="layer">
      <div className="ost">
        <Tabs defaultActiveKey="1" onChange={changeTab}>
          <TabPane tab="Create Special Discounts" key="1"></TabPane>
          <TabPane tab="Special Discounts Sequence" key="2"></TabPane>
        </Tabs>
      </div>
      {listingData ? (
        <div>
          {currentTab == 1 ?
            <Row>
              <Card>
                <Col span={24}>
                  <RangePicker onChange={onChangeRangePicker} className="frz-m-10"
                              value={showDateValue ? [moment(start_date, dateFormat),
                              moment(end_date, dateFormat)] : '' } format={dateFormat}
                              allowClear={false} disabledDate={disabledDate}/>
                  <Search placeholder="Order Type" value={valueByOrderType}
                          onChange={onChange} type="valueByOrderType" data={orderType} />
                  <Input placeholder="Product Name" onChange={(e) => onChange(e.target.value, "starts_with")}
                         value={starts_with} className="frz-w-200 frz-m-10"/>
                  <Button disabled={disable} onClick={() => getListingData(1)}
                          className="frz-w-100px frz-m-10" type="primary">Search</Button>
                  <Button disabled={disable} onClick={reset} type="primary"
                          className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
                </Col>
              </Card>
              <Card style={{ marginBottom: 20 }}>
                <Col span={24}>
                  <Button className="frz-m-10" type="primary" onClick={() => handleModalShow(true)}>Create Special Discounts</Button>
                </Col>
              </Card>
            </Row>
            : null}
          {listingData.length > 0 ? (
            <Row>
              <Col span={24}>
                <Card style={{ paddingTop: 5 }}>
                  <Row>
                    <Col span={24}>
                      {currentTab == 1 ? (
                        <>
                          <Table
                            columns={columns}
                            dataSource={listingData}
                            pagination={false}
                            scroll={{ x: 240 }}
                            rowKey="id"
                          />
                          <Pagination className="frz-tm-10 frz-bm-10" current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false} />
                        </>
                      ) : (
                        <>
                          {localStorage.getItem('city') === '' ?
                            <div className="no-data">Please select city</div> :
                            <DndProvider manager={manager.current.dragDropManager}>
                              <Table
                                columns={columns}
                                dataSource={listingData}
                                pagination={false}
                                scroll={{ x: 240 }}
                                rowKey="id"
                                components={components}
                                onRow={(record, index) => ({
                                  index,
                                  moveRow,
                                })}
                              />
                            </DndProvider>}
                        </>
                      )}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          ) : disable || spinEnable ? (
            spinEnable ? (
              <div className="spin-center">
                <Spin tip="...Loading" />
              </div>
            ) : (
              <div className="no-data">No Data Available</div>
            )
          ) : (
            <div className="no-data">No Data Available On Selected Filters</div>
          )}
          {showModal ?
            <ModalSpecialDiscount showModal={showModal} handleModalShow={handleModalShow}
              productListing={productListing} dsListing={dsListing} submitSpecialDiscount={submitSpecialDiscount}
              rowData={rowData} loading={loading} productList={productList} />
            :
            null
          }
        </div>
      ) : (
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      )}
      {activationModal ?
        <ModalActivaition handleOnActivationModal={handleOnActivationModal}
          activationModal={activationModal} discountId={discountId} rowData={rowData} /> : null}
    </div>
  );
}

export default SpecialDiscount;
