import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Spin, Modal, Button, Row, Col, Tabs, notification} from 'antd';
import {SocietyService} from '../../_services/society'
import { ContainerOutlined} from '@ant-design/icons';
import PickupOrderDetails from './pickup_order_detail';

const { TabPane } = Tabs;

const society_service = new SocietyService()

const PickupDetail = forwardRef((props, ref) => {
  const [pickupDetail, setPickupDetail] = useState(false)
  const [pickupStats, setPickupStats] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [spinEnable, setSpinEnable] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
      getPickupDetail(data.id)
      getPickupStats(data.id)
    },

  }));

  function getPickupDetail(id){
    const payload = {
      id: id
    }
    society_service.get_pickup_points_order(payload).subscribe((r) => {
      setPickupDetail(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getPickupStats(id){
    const payload = {
      id: id
    }
    society_service.get_pickup_points_stats(payload).subscribe((r) => {
      setPickupStats(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
  }

  function getDownloadOrder(){
    setSpinEnable(true)
    const payload = {
      code: pickupStats.pickup_point_detail.pickup_code
    }
    society_service.download_order_sheet(payload).subscribe((r) => {
      const linkSource = `data:application/octet-stream;base64,${r.response.csv_data}`;
      const downloadLink = document.createElement("a");
      const fileName = `Pickup_Order_Sheet_${(new Date).toDateString().replace(/ /g,'_')}.csv`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      openNotificationWithIcon('success','Downloaded Successfully')
      setSpinEnable(false)
    }, (err) => {
      setSpinEnable(false)
      openNotificationWithIcon('error','Download Failed')
    })
  }

  function getDownloadQty(){
    setSpinEnable(true)
    const payload = {
      id: pickupStats.pickup_point_detail.id
    }
    society_service.download_qty_sheet(payload).subscribe((r) => {
      const linkSource = `data:application/octet-stream;base64,${r.response.csv_data}`;
      const downloadLink = document.createElement("a");
      const fileName = `Pickup_Qty_Sheet_${(new Date).toDateString().replace(/ /g,'_')}.csv`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      openNotificationWithIcon('success','Downloaded Successfully')
      setSpinEnable(false)
    }, (err) => {
      setSpinEnable(false)
      openNotificationWithIcon('error','Download Failed')
    })
  }

  function getDownloadDelivery(){
    setSpinEnable(true)
    const payload = {
      code: pickupStats.pickup_point_detail.pickup_code
    }
    society_service.download_delivery_sheet(payload).subscribe((r) => {
      const linkSource = `data:application/octet-stream;base64,${r.response.csv_data}`;
      const downloadLink = document.createElement("a");
      const fileName = `Pickup_Delivery_Sheet_${(new Date).toDateString().replace(/ /g,'_')}.csv`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      openNotificationWithIcon('success','Downloaded Successfully')
      setSpinEnable(false)
    }, (err) => {
      setSpinEnable(false)
      openNotificationWithIcon('error','Download Failed')
    })
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onCancel={handleCancelModal}
          width='85%'
          style={{float: 'right'}}
          className="order"
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Close
            </Button>,
          ]}
        >
          <div className="order-modal-container">
            {pickupDetail && pickupStats ?
              <div>
                <Row>
                  <Col md={7}>
                    <div style={{borderRight: '1px solid #f0f0f0', paddingRight: 24,
                                 height: '100%'}}>
                      <div>
                        <b>Pickup Code :</b>
                        <span style={{paddingLeft:10}}>
                          {pickupStats.pickup_point_detail.pickup_code}
                        </span>
                      </div>
                      <div className="order-detail-padding">
                        <b>Delivery Date :</b>
                        <span style={{paddingLeft:10}}>
                          {pickupStats.pickup_point_detail.delivery_date}
                        </span>
                      </div>
                      {pickupStats.pickup_point_detail.delivery_slot_details ?
                        <div className="order-detail-padding">
                          <b>Time Slot :</b>
                          <span style={{paddingLeft:10}}>
                            {pickupStats.pickup_point_detail.delivery_slot_details?.slot_string ?? 
                             pickupStats.pickup_point_detail.delivery_slot_details.from_time + ' to ' + 
                             pickupStats.pickup_point_detail.delivery_slot_details.to_time + ' ' +
                             pickupStats.pickup_point_detail.delivery_slot_details.am_pm}
                          </span>
                        </div>
                      : null}
                      <div className="order-detail-padding">
                        <b>Expired :</b>
                        <span style={{paddingLeft:10}}>
                          {pickupStats.pickup_point_detail.is_expired.toString()}
                        </span>
                      </div>
                      <div className="order-detail-padding">
                        <b>Order Count :</b>
                        <span style={{paddingLeft:10}}>
                          {pickupStats.order_count}
                        </span>
                      </div>
                      <div className="order-detail-padding">
                        <b>Cashback :</b>
                        <span style={{paddingLeft:10}}>
                          Rs. {pickupStats.cashback}
                        </span>
                      </div>
                      <div className="order-detail-padding">
                        <b>Expected Sales :</b>
                        <span style={{paddingLeft:10}}>
                          Rs. {pickupStats.pickup_point_expected_revenue}
                        </span>
                      </div>
                      <div className="order-detail-padding">
                        <b>Final Sales :</b>
                        <span style={{paddingLeft:10}}>
                          Rs. {pickupStats.pickup_point_revenue}
                        </span>
                      </div>
                      <div className="order-detail-padding">
                        <b>Virtual Darkstore :</b>
                        <span style={{paddingLeft:10}}>
                          {pickupStats.pickup_point_detail.darkstore_name}
                        </span>
                      </div>
                      <div className="order-detail-padding"
                           style={{paddingTop: 20, color: '#262624'}}>
                        <b>#Point Details</b>
                      </div>
                      <div className="order-detail-padding">
                        <b>Point Type :</b>
                        <span style={{paddingLeft:10}}>
                          {pickupStats.pickup_point_detail.point_type}
                        </span>
                      </div>
                      {pickupStats.pickup_point_detail.point_details ?
                        <div>
                          <div className="order-detail-padding">
                            <b>Verified :</b>
                            <span style={{paddingLeft:10}}>
                              {pickupStats.pickup_point_detail.point_details.is_verified.toString()}
                            </span>
                          </div>
                          <div className="order-detail-padding">
                            <b>Society Code :</b>
                            <span style={{paddingLeft:10}}>
                              {pickupStats.pickup_point_detail.point_details.society_code}
                            </span>
                          </div>
                          <div className="order-detail-padding">
                            <b>Name :</b>
                            <span style={{paddingLeft:10}}>
                              {pickupStats.pickup_point_detail.point_details.name}
                            </span>
                          </div>
                          <div className="order-detail-padding">
                            <b>Address :</b>
                            <span style={{paddingLeft:10}}>
                              {pickupStats.pickup_point_detail.point_details.address}
                            </span>
                          </div>
                        </div>
                        : null}
                    </div>
                  </Col>
                  <Col md={17}>
                    <div style={{paddingLeft: 24}}>
                      {!spinEnable ?
                        <div className="order-detail-padding" style={{marginBottom: 10}}>
                          <Button onClick={getDownloadOrder} style={{width: 200}}
                                  type="primary">Download Order Sheet</Button>
                          <Button onClick={getDownloadQty} style={{width: 200, marginLeft:20}}
                                  type="primary">Download Qty Sheet</Button>
                          <Button onClick={getDownloadDelivery} style={{width: 200, marginLeft:20}}
                                  type="primary">Download Delivery Sheet</Button>
                        </div>
                      :
                        <div style={{marginBottom: 10}}>
                          <div style={{textAlign: 'center', margin: '0 auto'}}>
                            <Spin tip="...Download in Progress"/>
                          </div>
                        </div>
                      }
                      <Tabs defaultActiveKey="1">
                        <TabPane
                          tab={<span>
                                <ContainerOutlined />
                                Pickup Order Details
                              </span>}
                          key="1"
                        >
                          <PickupOrderDetails pickupDetail={pickupDetail} />
                        </TabPane>
                      </Tabs>
                    </div>
                  </Col>
                </Row>
              </div>
              :
              <div className="spin-center">
                <Spin tip="...Loading"/>
              </div>
            }
          </div>
        </Modal>
      : null }
    </div>
  )
})

export default PickupDetail
