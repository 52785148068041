import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import FRZInput from '../../../_controls/FRZInput'
import { formItemLayout } from '../../../_modules/controllayout';
import FRZSelect from '../../../_controls/FRZSelect';
import FRZRadioButton from '../../../_controls/FRZRadioButton';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import FRZUpload from '../../../_controls/FRZUpload';
import FRZColorPickerTemp from '../../../_controls/FRZColorPickerTemp';


const TagModal = forwardRef((props, ref) => {

  const { modalSubmit } = props

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [pickerBGColor, setPickerBGColor] = useState("#36c")
  const [pickerTextColor, setPickerTextColor] = useState("#36c")
  const [imageState, setImageState] = useState({})
  const [isClose, setIsClose] = useState(false)
  const [id, setId] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    if ((data && (title == "Edit Tags" || title === "Edit Product Tag Mapping")||isClose) && visible) {
      form.setFieldsValue({ ...data })
      setId(data.id)
      // setIsParent(data.is_parent)
      setPickerBGColor(data.background_color)
      setPickerTextColor(data.text_color)
      resetUploads()
    } else {
      setId(false)
      resetUploads()
      setPickerBGColor("#36c")
      setPickerTextColor("#36c")
    }
    if (isClose) {
      setIsClose(false)
    }
  }, [data, isClose])

  const tagGroupData = [
    { id: "for_your_needs", name: "for_your_needs", option: "For Your Needs" },
    { id: "discounts", name: "discounts", option: "Discounts" },
    { id: "health", name: "health", option: "Health" }
  ]

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      form.resetFields()
      setVisible(false)
      setLoading(false)
      setIsClose(true)
      setData(false)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleCancelModal() {
    form.resetFields()
    setVisible(false)
    setLoading(false)
    setIsClose(true)
    setData(false)
  }

  function handleClick({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  function changePickerBGColor(colors) {
    setPickerBGColor(colors)
  }

  function changePickerTEXTColor(colors) {
    setPickerTextColor(colors)
  }

  const uploadButton = (
    <div>
      {props.imgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleOnChangeImage = (type, item) => {
    setImageState(prevState => ({ ...prevState, [type]: item }))
  }

  const resetUploads = () => {
    setImageState({})
  }

  function handleOnSubmit(values) {
    setLoading(true)
    const payload = {
      ...values, ...imageState,
      background_color: pickerBGColor ? pickerBGColor : null,
      text_color: pickerTextColor ? pickerTextColor : null
    }
    modalSubmit(payload, id)
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}
        >
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">
            {title !== 'Edit Product Tag Mapping' ?
              <div>
                <FRZInput name="name" isRequired={true}
                  label="Tag Name" placeholder="Tag Name" width={200} />

                <FRZInput name="description" isRequired={true}
                  label="Description" placeholder="Description" width={200} isTextArea={true} />

                <FRZColorPickerTemp
                  label="Background Color"
                  value={pickerBGColor}
                  pickerColor={pickerBGColor}
                  changePickerColor={changePickerBGColor}
                  setPickerColor={setPickerBGColor}
                  columnSpan={7}
                />

                <FRZColorPickerTemp
                  label="Text Color"
                  value={pickerTextColor}
                  pickerColor={pickerTextColor}
                  changePickerColor={changePickerTEXTColor}
                  setPickerColor={setPickerTextColor}
                  columnSpan={7}
                />

                <FRZSelect DropDownData={tagGroupData} name="tag_group" isRequired={true}
                  label="Tag Group" placeholder="Select Tag Group" value="id" option="option"
                  isShowSearch={true} width={"100%"} />

                <FRZSelect DropDownData={props.tagType} name="product_tag_type_id" isRequired={true}
                  label="Tag Type" placeholder="Select Tag Group" value="id" option="name"
                  isShowSearch={true} width={"100%"} />
              </div>
              : null}

            <FRZInputNumber isRequired={true} label="Sequence No" name="sequence" width={200} />

            <FRZRadioButton isRequired={true} label="Is Active" name="is_active" />

            {title !== 'Edit Product Tag Mapping' ?
              <div>

                <FRZRadioButton isRequired={true} label="Is Category" name="is_category" />

                <FRZUpload name="product_tag_image" className="avatar-uploader"
                  customRequest={handleClick} imgWidth={"100%"} label={"Icon Image"} listType="picture-card" setOnChangeType={"product_tag_image"}
                  showUploadList={false} uploadButton={uploadButton} handleOnChangeImage={handleOnChangeImage}
                  imageState={imageState} />

              </div>
              : null}
          </Form>
        </Modal>
        : <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})

export default TagModal
