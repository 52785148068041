import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button, Select, Input, InputNumber, DatePicker,
         Spin, Radio, ConfigProvider} from 'antd';
import LocationSearch from './location_search';
const { Option } = Select;

const OfflineCustomerModal = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOk() {
    setLoading(true)
    props.validateOTP()
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.onCancelModal();
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          width='40%'
          onOk={handleOk}
          onCancel={handleCancelModal}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            props.mobileLock && props.showOTP ?
                <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                    Submit
                </Button>
            : null
          ]}
        >
          <div className="block">Order Type :
              <span style={{paddingLeft:10}}>
                  <Radio.Group value={props.valueByRadioWhatsapp}
                               onChange={(e) => props.onChangeModal(e.target.value, 'valueByRadioWhatsapp')}>
                      <Radio value={true}>WhatsApp</Radio>
                      <Radio value={false}>Phone Call</Radio>
                  </Radio.Group>
              </span>
          </div>
          <div className="block">Mobile No :
            <span style={{paddingLeft:10}}>
              <Input
                placeholder="Enter Mobile No"
                value={props.modalMobileNo}
                style={{width:200, marginRight: 20}}
                disabled={props.mobileLock}
                maxLength={10}
                onChange={(e) => props.onChangeModal(e.target.value, 'modalMobileNo')}/>
                {props.modalSpinner ? 
                      <Spin/>
                    :
                      <>
                        {props.mobileLock ?
                            <Button type="primary" ghost onClick={props.editMobile}>
                                Edit
                            </Button> 
                            :
                            <Button type="primary" onClick={props.validateMobile}>
                                Verify
                            </Button>
                        }
                      </>
                }
            </span>
          </div>
          {props.mobileLock && props.showAddressSearch ?
            <div style={{margin: '10px 0px'}}>
                <LocationSearch searchAddress={props.searchAddress}
                                addressLock={props.addressLock}
                                editAddress={props.editAddress}
                                isServiceable={props.isServiceable}
                                tempAddressSearch={props.tempAddressSearch}
                                showEdit={true}/>
            </div>
          : null}
          {props.mobileLock && props.showOTP ? 
            <div className="block">OTP :
                <span style={{paddingLeft:10}}>
                <Input
                    placeholder="Enter OTP"
                    value={props.modalOTP}
                    maxLength={6}
                    style={{width:200, marginRight: 5}}
                    onChange={(e) => props.onChangeModal(e.target.value, 'modalOTP')}/>
                <Button type="link" onClick={props.generateOTP} style={{fontSize: '12px'}}>
                    Resend
                </Button>
                </span>
            </div>
          : null}
        </Modal>
      : null }
    </div>
  )
})

export default OfflineCustomerModal
