import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber, Select,
         Pagination, DatePicker, Popconfirm, notification, Table } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {DarkstoreService} from '../../_services/darkstore';
import {handleDateFormat} from '../../_modules/date';
import FrzDownload from '../../_controls/FRZDownload';

const { Option } = Select;

const darkstore_service = new DarkstoreService()

function DarkstoreDeliverySlot(){
  const [darkstoreData, setDarkstoreData] = useState(false)
  const [darkstoreConsolidatedData, setDarkstoreConsolidatedData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [mode, setMode] = useState(undefined)
  const [state, setState] = useState({date: 'today'});

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getDarkstores()
    }
  })

  function getDarkstores(){
    setSpinEnable(true)
    setDarkstoreData([])
    const payload = {
      date: state.date
    }
    darkstore_service.get_darkstores_deliveryslot(payload).subscribe((r) => {
      setSpinEnable(false)
      if(columns.length == 0){
        getColumns(r.response.data.column_attributes)
        setDarkstoreConsolidatedData(r.response.data.consolidated_data)
      }
      setDarkstoreData(r.response.data.darkstore_slots_data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(data){
    data.map(data => {
      columns.push({
        title: data.replace(/_/g, ' '),
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
          return (
            <>
              {
                data === 'express' ? 
                <div style={record[data].is_active ? {color: 'green'} : {color: 'red'}}>
                    {record[data].off_count} ({record[data].last_status_changed_by.toUpperCase()})
                </div>
              : 
                data === 'exp_start_time' ? 
               <div>
                   {record.exp_start_time}
               </div> 
              :
                data === 'exp_active_hrs' ? 
               <div>
                   {record.exp_active_hrs}
               </div> 
              :
                data === 'total_slots' ? 
               <div>
                   {record.total_slots}
               </div> 
              :
               data === 'darkstore_name' ? 
                <div>{record[data]}</div>
              : data === 'delivery_limit' ? 
                <div style={record[data].in_limit ? {color: 'green'} : {color: 'red'}}>
                  {record[data].active_deliveries_count} ({record[data].max_limit})
                </div> 
              : data.includes('_am') || data.includes('_pm') ?
                <div style={record[data]?.is_active??false ? {color: 'green'} : {color: 'red'}}>
                 {record[data]?.order_count??''} ({record[data]?.slot_limit??''})
                </div> : null}
            </>
          );
        },
      })
    })
    setColumns(columns)
  }

  function onChange(value, type) {
    setState(prevState =>({...prevState, [type]: value}))
    setDisable(false)
 }

 function reset() {
   setState({date: 'today'})
   setDisable(true)
   setRenderData(false)
 }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  return(
    <div className="layer">
      {darkstoreData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Select style={{ width: 200, margin: 10}} value={state.date}
                        onChange={(e) => onChange(e, 'date')}>
                      <Option key='today' value='today'>Today</Option>
                      <Option key='tomorrow' value='tomorrow'>Tomorrow</Option>
                      <Option key='overmorrow' value='overmorrow'>Day After Tomorrow</Option>
                </Select>
                <Button disabled={disable} onClick={getDarkstores} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                        <div style={{float:"right", display:"flex", justifyContent:"center",alignContent:"center",alignItems:"center"}}>
                          <span style={{ padding: 15 }}>
                            <b>Avg Exp Active Hrs: </b>
                            {darkstoreConsolidatedData.avg_exp_active_hrs}
                            </span>
                          <span style={{ padding: 15 }}>
                            <b>Avg Exp Start Time: </b>
                            {darkstoreConsolidatedData.avg_exp_start_time}
                            </span>
                            <span style={{ padding: 15 }}>
                              <b>Total Slots Count: </b>
                              {darkstoreConsolidatedData.total_slots_count}
                            </span>
                        </div>
                <Button onClick={reset} style={{margin:10}} disabled={disable}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
                <FrzDownload 
                  setSpinEnable={setSpinEnable} 
                  url={'darkstore_wise_capactiy_report'}
                />
              </Col>
            </Card>
          </Row>
          {darkstoreData.length > 0 ?
            <Row>
              <Col span={24}>
                {/* <MainTable dataSource={darkstoreData} columns={columns}/> */}
                <Card style={{ paddingTop: 5, marginTop: 20 }}>
                    <Row>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                dataSource={darkstoreData}
                                pagination={false}
                                scroll={{ x: 240 }}
                                rowKey="darkstore_name"
                            />
                        </Col>
                    </Row>
                </Card>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default DarkstoreDeliverySlot
