import React from 'react'
import { Button, Modal, Form, Input, Radio } from 'antd';
import ProductActivation from '../product/product_activation';

const ModalActivaition = ({ handleOnActivationModal, activationModal, catId, rowData, discountId, clusterId }) => {
  return (
    <div>
      <Modal
        visible={activationModal}
        title={discountId ? rowData.product_details.name : clusterId ? rowData.title + "- Activaiton / Deactivation" : rowData.name + "- Activaiton / Deactivation"}
        onCancel={() => handleOnActivationModal(false)}
        width="85%"
        style={{ float: "right" }}
        className="order"
        footer={[
          <Button key="back" onClick={() => handleOnActivationModal(false)}>
            Close
          </Button>,
        ]}
      >
        <div className="frz-cat-activaiton">
          <ProductActivation catId={catId} discountId={discountId} clusterId={clusterId} />
        </div>
      </Modal>
    </div>
  )
}

export default ModalActivaition
