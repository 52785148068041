import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Form, InputNumber, Select, Row, Col, Button, Spin } from 'antd';
import FRZSelect from '../../../_controls/FRZSelect';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZDatepicker from '../../../_controls/FRZDatepicker';
import FRZInput from '../../../_controls/FRZInput';
import moment from 'moment';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { tailLayout } from '../../../_modules/controllayout';
import { openNotificationWithIcon } from '../../../_modules/notification';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import FRZTimePicker from '../../../_controls/FRZTimePicker';
import { OperationsService } from '../../../_services/operations';

const ops_service = new OperationsService()
const { Option } = Select;

const CreateStoModal = forwardRef((props, ref) => {
  const { modalSubmit } = props

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState(false)
  const [data, setData] = useState(false)
  const [isClose, setIsClose] = useState(false)
  const [skuData, setSkuData] = useState(false)
  const [isSelected, setIsSelected] = useState([]);
  const [disable, setDisable] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [node, setNode] = useState()

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setData(false)
      form.resetFields()
      setVisible(false)
      setLoading(false)
      setIsSelected([])
      setIsClose(true)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleCancelModal() {
    setData(false)
    form.resetFields()
    setVisible(false)
    setLoading(false)
    setIsSelected([])
    setIsClose(true)
  }

  const handleOnSubmit = (values) => {

    if (values.items === undefined || values.items.length === 0) {
      openNotificationWithIcon('error', "Please fetch and add at least one sku")
      ref.stopLoading()
    } else {
      const payload = { ...values, transfer_date: moment(values.transfer_date).format("YYYY-MM-DD"),
                        expected_arrival_time: values.expected_arrival_time ? moment(values.expected_arrival_time).format("HH:mm") : ""}
      setLoading(true)
      modalSubmit(payload)
    }
  }

  function disabledDate(current){
    return current && (current < moment().subtract(90, "day") || current > moment().add(10, "day"));
  }

  const handleChange = (e) => {
    let res = form.getFieldValue("items")
    setIsSelected(res)
  };

  function fetchSkus() {
    setIsLoading(true)
    setDisable(true)
    const payload = { id: node }
    ops_service.get_sto_skus(payload).subscribe((r) => {
      setIsLoading(false)
      setSkuData(r.response.data)
    },
    (err) => {
      console.log(err)
      setIsLoading(false)
      setDisable(false)
    })
  }

  const onChangeNode = (value) => {
    setNode(value)
    setSkuData(false)
    setDisable(false)
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'60%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            <FRZSelect DropDownData={props.nodes} name="source_node_id" isRequired={true}
              label="Source Name" placeholder="Select Source Name" value="id" option="name"
              isShowSearch={true} width={"100%"} setOnChange={onChangeNode}/>
            
            <FRZSelect DropDownData={props.destinations} name="destination_node_id" isRequired={true}
              label="Destination" placeholder="Select Destination" value="id" option="name"
              isShowSearch={true} width={"100%"} />

            <FRZDatepicker placeholder="Select Transfer Date" label="Transfer Date" isRequired={true}
              name="transfer_date" isRequired={true} isDisabled={false} disabledDate={disabledDate}/>

            <FRZInputNumber isRequired={false} label="Driver Mobile No" name="driver_mobile_number" width={200} />

            <FRZTimePicker isRequired={false} label="Extended Arrival Time" name="expected_arrival_time"/>

            <FRZInput name="vehicle_number" label="Vehicle No" isRequired={false}
                      placeholder="Vehicle No" width={200}/>
            <div className="text-center frz-bm-20">
              <Button onClick={fetchSkus} disabled={disable || node === undefined}
                className="frz-w-100px frz-m-10" type="primary">Fetch SKU's</Button>
            </div>
            
            {skuData ? 
              <div>
                <div className="sto-modal-border"></div>
                <Row className="frz-bm-20 text-center">
                  <Col offset={7} span={4}><b>Current Stock</b></Col>
                </Row>
                <Form.List name="items" key={Math.random(1, 100)}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <div key={index}>
                          <Row gutter={41} key={index}>
                            <Col>
                              <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) =>
                                  prevValues.area !== curValues.area || 
                                  prevValues.sights !== curValues.sights}>
                                {() => (
                                  <Form.Item
                                    {...field}
                                    {...tailLayout}
                                    name={[field.name, 'sku']}
                                    fieldKey={[field.fieldKey, 'sku']}
                                    rules={[{ required: true, message: 'Missing sku' }]}>

                                    <Select className="frz-width-220p" onChange={handleChange} 
                                      placeholder="Select SKU"
                                      showSearch
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                      {skuData.map(item => (
                                        <Option disabled={isSelected?.map(e => e?.sku).includes(item.sku)} 
                                          key={item.sku} value={item.sku} >
                                          {item.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                )}
                              </Form.Item>
                            </Col>

                            <Col span={3}>
                              <div className="text-center">
                                {isSelected.map((data, i) => (
                                  i === index ? 
                                    (JSON.parse(skuData.find(x => x.sku === data.sku).product_current_stock))
                                  : null
                                ))}
                              </div>
                            </Col>

                            <Col>
                              <Form.Item
                                {...field}
                                {...tailLayout}
                                name={[field.name, 'transfer_unit_qty']}
                                fieldKey={[field.fieldKey, 'transfer_unit_qty']}
                                rules={[{ required: true, message: 'Missing Qty' }]}>
                                <InputNumber placeholder="Transfer Qty" required={true} name="transfer_unit_qty" 
                                  className="frz-width-110p" />
                              </Form.Item>
                            </Col>

                            {/* <Col>
                              <Form.Item
                                {...field}
                                {...tailLayout}
                                name={[field.name, 'unit_price']}
                                fieldKey={[field.fieldKey, 'unit_price']}
                                rules={[{ required: true, message: 'Missing Price' }]}>
                                <InputNumber placeholder="Unit Price" required={true} name="unit_price" 
                                  className="frz-width-110p" />
                              </Form.Item>
                            </Col> */}

                            <Col>
                              <Form.Item
                                {...field}
                                {...tailLayout}
                                name={[field.name, 'transfer_unit_type']}
                                fieldKey={[field.fieldKey, 'transfer_unit_type']}
                                rules={[{ required: true, message: 'Missing Unit type' }]}>

                                <Select className="frz-width-110p" onChange={handleChange} 
                                  placeholder="Select Unit type">
                                    <Option key='po_unit' value='po_unit'>PO Unit</Option>
                                    <Option key='packets' value='packets'>Packets</Option>
                                    <Option key='crates' value='crates'>Crates</Option>
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col>
                              <Button className="frz-lm-20 frz-p-0 frz-width-36p frz-tp-2" type="primary" 
                                icon={<DeleteOutlined />}
                                shape="round" onClick={() => {
                                  remove(field.name)
                                  let response = form.getFieldValue("items")
                                  setIsSelected(response)
                                }}>
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      ))}

                      <Button className="frz-width-104p" type="primary" onClick={() => add()} block 
                        icon={<PlusCircleOutlined />}>
                        Add SKU
                      </Button>
                    </>
                  )}
                </Form.List>
              </div> 
            : 
            isLoading ? 
              <div className="spin-center">
                <Spin tip="...Loading" />
              </div>
            : null}
          </Form>
        </Modal>
        :   
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})

export default CreateStoModal
