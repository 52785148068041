import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Form } from 'antd';
import FRZSelect from '../../../_controls/FRZSelect';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZDatepicker from '../../../_controls/FRZDatepicker';
import FRZInput from '../../../_controls/FRZInput';
import moment from 'moment';

const PaymentModal = forwardRef((props, ref) => {
  const { modalSubmit } = props

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState(false)
  const [data, setData] = useState(false)
  const [id, setId] = useState(false)
  const [statusData] = useState([{id: 'approved', name: 'Approved'}, {id:'paid', name: 'Paid'}, 
                                 {id:'rejected', name: 'Rejected'}])
  const [rejectReason] = useState([{id: 'INVOICE_RATE_AND_QUANTITY_NOT_MATCHING', name: 'Invoice Rate & Qty Not Matching'},
                                   {id: 'INVOICE_RATE_NOT_MATCHING', name: 'Invoice Rate Not Matching'},
                                   {id: 'INVOICE_QUANTITY_NOT_MATCHING', name: 'Inovice Qty Not Matching'},
                                   {id: 'INVOICE_NOT_SIGNED', name: 'Invoice Not Signed'}])
  const [isClose, setIsClose] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
      setId(data.id)
    },

    handleCancel() {
      setData(false)
      setId(false)
      form.resetFields()
      setVisible(false)
      setLoading(false)
      setIsClose(true)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleCancelModal() {
    setData(false)
    setId(false)
    form.resetFields()
    setVisible(false)
    setLoading(false)
    setIsClose(true)
  }

  const handleOnSubmit = (values) => {
    const payload = { ...values, delivery_date: moment(values.delivery_date).format("YYYY-MM-DD") }
    setLoading(true)
    modalSubmit(payload, id)
  }

  function disabledDate(current){
    return current && (current < moment().subtract(20, "day") || current > moment().add(0, "day"));
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            <FRZSelect DropDownData={statusData} name="status" isRequired={true}
              label="Status" placeholder="Select Status" value="id" option="name"
              isShowSearch={true} width={"100%"} />

            <Form.Item className="frz-form-dependency"
                       shouldUpdate={(prevValues, curValues) => prevValues.status !== curValues.status}>
              {({ getFieldValue }) => {
                return (
                  getFieldValue('status') === 'rejected' ? 
                    <>
                      <FRZSelect DropDownData={rejectReason} name="rejection_reason" isRequired={true}
                        label="Rejection Reason" placeholder="Select Reason" value="id" option="name"
                        isShowSearch={true} width={"100%"} /> 
                      <FRZInput name="comment" label="Comments" isRequired={false}
                        placeholder="Comments (Optional)" width={300}/>
                    </>
                  : getFieldValue('status') === 'paid' ? 
                      <>
                        <FRZDatepicker placeholder="Payment Date" label="Payment Date" isRequired={true}
                          name="payment_date" isDisabled={false} disabledDate={disabledDate}/>
                        <FRZInput name="transaction_detail" label="Txn Details" isRequired={false}
                          placeholder="Txn Details (Optional)" width={300}/>
                      </>
                  : null
                );
              }}
            </Form.Item>

          </Form>
        </Modal>
        :   
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})

export default PaymentModal
