import React from 'react'
import { Button } from 'antd'
import { FolderViewOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import FrzDynamicDownload from '../../_controls/FRZDynamicDownload';

export const segmentColumns = (onEdit, onDownload) => {
  return [
    {
      title: 'Segment Name',
      dataIndex: 'name',
      key: 'name',
      align: "center",
    },
    {
      title: 'Is Active',
      dataIndex: '',
      key: '',
      align: "center",
      render: (record) => {
        return record?.is_active?.toString()
      }
    },
    {
      title: 'Total Customers',
      dataIndex: 'total_customers',
      key: 'total_customers',
      align: "center",
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      align: "center",
    },
    {
      title: 'View Details',
      dataIndex: '',
      key: '',
      align: "center",
      render: (record) => <div>
        <Button onClick={() => onEdit(record)} icon={<FolderViewOutlined />}></Button>
      </div>
    },
    {
      title: 'Download',
      dataIndex: '',
      key: '',
      align: "center",
      render: (record) => <div>
        <FrzDynamicDownload name="Report" reportFunc={'download_segment_report'}
          url={`admin/api/v1/segments/${record.id}/customers/export`} fileName={'segments_customer_id'}
          type={'job'} jobReportFunc={'download_job_segment_report'} base={'customer_segments'}/>
      </div>
    }
  ]
}