import { Http } from '../_modules/http'

export class SocietyService {

  http = new Http()

  get_society(payload){
    const url = process.env.REACT_APP_API_URL +
                'store_dashboard/v1/store/society?page=' + payload.page +
                '&by_darkstore=' + payload.by_darkstore + '&by_city=' + payload.by_city +
                '&by_name=' + payload.by_name
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_society_campaign(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/campaigns/get_society_campaign?page=' + payload.page +
                '&by_society=' + payload.by_society + '&by_darkstore=' + payload.by_darkstore +
                '&by_delivery_date=' + payload.by_delivery_date + '&by_status=' + payload.by_status +
                '&by_pincode=' + payload.by_pincode
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_virtual_ds(){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/pickup_points/list_virtual_stores'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_society_list(payload){
    const url = process.env.REACT_APP_API_URL +
                'store_dashboard/v1/store/get_societies?by_pincode=' + payload.by_pincode
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_allsociety_list(){
    const url = process.env.REACT_APP_API_URL +
                'store_dashboard/v1/store/get_list'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_campaign_delivery_slot(){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/campaigns/delivery_slot'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_society_campaign_status(){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/campaigns/society_campaign_status'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  update_society(payload){
    const url = process.env.REACT_APP_API_URL +
                'store_dashboard/v1/store/society/' + payload.id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload.body, headers)
  }

  create_society(payload){
    const url = process.env.REACT_APP_API_URL +
                'store_dashboard/v1/store/society'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload.body, headers)
  }

  create_campaign(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/campaigns/society_campaigns'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload.body, headers)
  }

  update_campaign(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/campaigns/' + payload.id +'/society_campaigns'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload.body, headers)
  }

  create_campaign_discount(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/campaigns/' + payload.id + '/society_campaign_discounts'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload.body, headers)
  }

  update_campaign_discount(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/campaigns/' + payload.id + '/society_campaign_discounts'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload.body, headers)
  }

  delete_campaign_discount(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/campaigns/' + payload.id + '/society_campaign_discounts'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.delete(url, payload.body, headers)
  }

  get_pickup_points(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/pickup_points/pickup_points?page=' + payload.page +
                '&by_pickup_code=' + payload.by_pickup_code + '&start_date=' + payload.start_date +
                '&end_date=' + payload.end_date +
                '&by_point_type=' + payload.by_point_type + '&by_point_id=' + payload.by_point_id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  update_pickup(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/pickup_points/pickup_points/' + payload.id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload.body, headers)
  }

  create_pickup(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/pickup_points/pickup_points'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload.body, headers)
  }

  get_pickup_points_stats(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/pickup_points/stats?pickup_point_id=' + payload.id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_pickup_points_order(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/pickup_points/orders?pickup_point_id=' + payload.id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  merge_societies(payload){
    const url = process.env.REACT_APP_API_URL +
                'store_dashboard/v1/store/merge_societies'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers)
  }

  download_order_sheet(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/downloads/pickup_order_sheet?pickup_code=' + payload.code
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  download_qty_sheet(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/downloads/' + payload.id + '/product_qty_sheet'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  download_delivery_sheet(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/downloads/pickup_delivery_report?pickup_code=' + payload.code
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  download_delivery_report(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/downloads/consolidate_delivery_sheet?by_pickup_code=' + payload.by_pickup_code +
                '&start_date=' + payload.start_date + '&end_date=' + payload.end_date +
                '&by_point_type=' + payload.by_point_type + '&by_point_id=' + payload.by_point_id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_pickup_timeslot(){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/campaigns/delivery_slot?for_pickup=true'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  send_notification(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/pickup_points/send_custom_notifications'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload, headers)
  }

  get_logic_pos(){
    const url = process.env.REACT_APP_API_URL + 
                'admin_dashboard/v1/admin/logic_pos_accounts'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  update_society_logic_pos(payload){
    const url = process.env.REACT_APP_API_URL +
                'store_dashboard/v1/store/update_logic_pos_account'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers)
  }

  update_logic_pos(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/admin/logic_pos_accounts/' + payload.id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload.body, headers)
  }

  get_virtual_ds(){
    const url = process.env.REACT_APP_API_URL + 
                'admin_dashboard/v1/clusters/get_all_stores'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  update_store_targets(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/clusters/update_store_target'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers)
  }

}
