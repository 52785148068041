import React, { useEffect } from 'react'
import { Button, Form } from 'antd';
import { formItemLayout } from '../../../../../_modules/controllayout';
import FRZInput from '../../../../../_controls/FRZInput';
import FRZRadioButton from '../../../../../_controls/FRZRadioButton';
import FRZSelect from '../../../../../_controls/FRZSelect';
import { PaymentsService } from "../../../../../_services/payments";
import { openNotificationWithIcon } from '../../../../../_modules/notification';

const payments_service = new PaymentsService();

const PaymentTabsChild = ({ name, isSubmit, setIsSubmit, statusData, PGAType, item, rowData,
  payType, activeParent, setActivePayments, activePayments, getPayments }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (isSubmit === "PGA" && item === PGAType) {
      setIsSubmit(false)
      form
        .validateFields()
        .then(values => {
          const payload = {
            id: rowData.id,
            body: {
              payment_gateway: PGAType,
              gateway_code: values.gateway_code,
              ios_gateway_code: values.ios_gateway_code,
              status: values.status,
              load_percentage: values.load_percentage
            }
          }
          payments_service.update_payments(payload).subscribe((r) => {
            setActivePayments(r.response.data.payment_gateway_details)
            openNotificationWithIcon('success', "Updated Successfully")
          }, ((err) => {
            openNotificationWithIcon('error', err.response.errors[0])
            console.log("err", err)
          }))
        })
        .catch(info => {
          // console.log('Validate Failed:', info);
        });
    }
  }, [isSubmit])

  useEffect(() => {
    if (PGAType) {
      // console.log("update the values ibn form", rowData.payment_gateway_details.find((x) => x.payment_gateway === PGAType))
      form.setFieldsValue(activePayments.find((x) => x.payment_gateway === PGAType))
    }
  }, [PGAType])

  // const handleOnSubmit = () => {
  //   form
  //     .validateFields()
  //     .then(values => {
  //       console.log("values", values)
  //     })
  //     .catch(info => {
  //       // console.log('Validate Failed:', info);
  //     });
  // }

  return (
    <div>
      <Form
        form={form}
        {...formItemLayout}
        layout="horizontal"
        id={name}
        name={name}>

        <FRZInput name="gateway_code" label="Gateway Code" placeholder='Gateway Code' isRequired={true} />
        {!payType && activeParent.internal_code === 'upi' ?
          <FRZInput name="ios_gateway_code" label="IOS Code" placeholder='IOS Code' isRequired={true} />
          : null}
        {/* <FRZRadioButton name="status" isRequired={true} label="Status" /> */}

        <FRZSelect name="status" isRequired={true} label="Status" placeholder="Status" width={200} DropDownData={statusData} value="option" option="name" />

        {/* <Button form="myForm" key="submit" htmlType="submit" onClick={handleOnSubmit}>
          Submit
        </Button> */}

      </Form>
    </div >
  )
}

export default PaymentTabsChild