import React from 'react'
import {
  Card, Divider, Steps, Row, Col, Button, Form, DatePicker, Checkbox, Input, Avatar,
  Segmented, Spin, Select, Image, Modal,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { openNotificationWithIcon } from '../../../_modules/notification'

const StepOne = ({ setDynamicData, unitData, setActiveUnit, activeUnit, form }) => {

  const onUnitSelect = (id, data) => {
    setDynamicData(data)
    // let dynamicForm = []
    // let defaultData = data.meta_data.fields_meta.filter((x) => x.default !== "NA").map((itm) => {
    //   dynamicForm.push({ [itm.field_type + "^" + itm.field_key]: itm.deaf })
    // })
    // form.setFieldsValue(...dynamicForm)
    // console.log(defaultData)
    // console.log("step one data", data)
    if (id == activeUnit) {
      setActiveUnit(false)
      return
    }
    setActiveUnit(id)
  }

  return (
    <div>
      <Row gutter={[15, 15]}>
        {unitData.map((item, index) => {
          // console.log("item", item)
          return (
            <Col key={index} span={6}>
              <div onClick={() => item.attached_page_units_count !== item.max_page_units_attach_count ? onUnitSelect(item.id, item):openNotificationWithIcon("error", "Max limit reached for this unit")}
              className={`${activeUnit === item.id ? "frz-unit-card-box-active" : "frz-unit-card-box"} frz-cursor-pointer`}>
              <img src={item.example_image_url} className="frz-w-100" style={{ height: 170 }} />
              <div className='frz-p-10'>
                <div className='frz-height-label'>
                  {item.display_name}
                </div>
                <div className="frz-dis-flex frz-clr-green">
                  Used: {item.attached_page_units_count}/{item.max_page_units_attach_count}
                </div>
              </div>
            </div>
              {/* <div className={`${activeUnit === item.id ? "frz-unit-card-box-active" : "frz-unit-card-box"} frz-cursor-pointer`}>
                <div className="frz-dis-flex frz-hrz-center frz-tm-10">
                  <PlusOutlined />
                </div>
                <div className="frz-dis-flex frz-hrz-center frz-tm-10 frz-unit-title">
                  {item.display_name}
                </div>
                <div className="frz-dis-flex frz-hrz-center frz-clr-green frz-mt-15">
                  Used: {item.attached_page_units_count}/{item.max_page_units_attach_count}
                </div>
              </div> */}
            </Col>
      )
        })}
    </Row>
    </div >
  )
}

export default StepOne