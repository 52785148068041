import React, {useEffect, forwardRef, useImperativeHandle} from 'react';
import { Form, Input } from 'antd';
import FRZSelect from '../../_controls/FRZSelect';
import { formItemLayout } from '../../_modules/controllayout';
import { CapacityService } from '../../_services/capacity'

const capacity_service = new CapacityService()

const ReasonModal = forwardRef((props, ref) => {
const [form] = Form.useForm();

useEffect(() => {
  props.setFormValue()
}, []);

useImperativeHandle(ref, () => ({
  validateForm() {
    form
    .validateFields()
    .then(values => {
      let reason = values.reasons === 'Others' ? values.other_reason : values.reasons
      props.handleOnRowSave(reason)
    })
    .catch(info => {
      // console.log('Validate Failed:', info);
    });
  }
}));

  return (
    <Form
      form={form}
      {...formItemLayout}
      layout="horizontal"
      name="form">
      <FRZSelect DropDownData={props.data} name="reasons"
        label="Reason" placeholder="Select Reason"
        width={200} setOnChange={props.onChange} />
        {props.selectedReason === 'Others' && <Form.Item
          name="other_reason"
          label="Other Reason"
          rules={[{
            required: true,
            message: "Reason is mandatory"
          }]}>
          <Input />
        </Form.Item>}
    </Form>
  )
})

export default ReasonModal;