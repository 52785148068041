import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { useSelector } from "react-redux";
import { Modal, Button, Select, Input, InputNumber,
         Radio} from 'antd';
const { Option } = Select;

const SpecialDealsModal = forwardRef((props, ref) => {
  const darkstore = useSelector(state => state.auth.darkstore);
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      props.onCancelModal();
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleOk() {
    setLoading(true)
    props.modalSubmit()
  }

  function closeModal() {
    setVisible(false)
  }

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    props.onCancelModal();
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          onOk={handleOk}
          onCancel={handleCancelModal}
          width='50%'
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              Submit
            </Button>,
          ]}
        >
          <div className="block">Darkstore Coverage :
            <span style={{paddingLeft:10}}>
            <Radio.Group
                    value={props.valueByRadio}
                    onChange={(e) => props.onChangeModal(e.target.value, 'valueByRadio')}>
                <Radio value={true}>All Darkstores</Radio>
                <Radio value={false}>Individual Darkstores</Radio>
            </Radio.Group>
            </span>
          </div>
          {props.valueByRadio === false ? 
            <div className="block" style={{marginLeft:54}}>Darkstores :
                <span style={{paddingLeft:10}}>
                    {darkstore ?   
                        <Select mode="multiple" style={{ width: 400}}
                                value={props.darkstore_list}
                                placeholder="Select Darkstores" onChange={(e) => props.onChangeTags(e, 'darkstore_list')}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {darkstore.map(data => {
                                return(
                                    <Option value={data.id} key={data.id}>{data.name}</Option>
                                )
                            })}
                        </Select>
                        : null
                    }
                </span>
            </div>
          : null}
          {props.productData ? 
            <div className="block" style={{marginLeft:64}}>Products :
                <span style={{paddingLeft:10}}>  
                    <Select mode="multiple" style={{ width: 400}}
                            value={props.product_list} disabled={title === 'Edit Deals'}
                            placeholder="Select Products" onChange={(e) => props.onChangeTags(e, 'product_list')}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {props.productData.map(data => {
                            return(
                                <Option value={data.id} key={data.id}>{data.name}</Option>
                            )
                        })}
                    </Select>
                </span>
            </div>
          : null}
          <div className="block" style={{marginLeft:70}}>Is Active :
            <span style={{paddingLeft:10}}>
                <Radio.Group
                        value={props.valueByRadioActive}
                        onChange={(e) => props.onChangeModal(e.target.value, 'valueByRadioActive')}>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                </Radio.Group>
            </span>
          </div>
        </Modal>
      : null }
    </div>
  )
})

export default SpecialDealsModal
