import { Http } from '../_modules/http'

export class AdsService {

  http = new Http()

  get_ads(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/notifications_log?page='
      + payload.page + '&by_notification_type=' + payload.by_notification_type + '&by_date=' + payload.by_date
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_customers(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/marketing/customers'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  create_ads(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/marketing/notify_customer'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  create_undelivered_sms(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/marketing/send_delayed_msg'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  get_customer_type(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/customers_tags_list'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_customer_segment(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/customer_segmentation?tag_type='
      + payload.tag_type + '&page=' + payload.page
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_discount_performance(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/get_discount_performance?by_code='
      + payload.by_code + '&page=' + payload.page + '&by_discount_type=' + payload.by_discount_type
      + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  create_deeplink(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/marketing/generic_firebase_deeplink'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  get_discount(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/discounts?page='
      + payload.page
      + '&by_coupon=' + payload.valueByCouponCode
      + '&by_segment=' + payload.by_segment
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  create_discount(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/discounts'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  update_discount(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/discounts'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  get_referral_discount(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/referral_discounts?page='
      + payload.page + '&referent_mobile_number=' + payload.referent_mobile_number
      + '&recipient_mobile_number=' + payload.recipient_mobile_number
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_product_discount(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/get_product_discounts?page='
      + payload.page + '&by_coupon=' + payload.valueByCouponCode + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
      + '&variant=' + payload.variant
      + '&by_sku=' +payload.by_sku + '&by_active_date='+ payload.by_active_date
      + '&by_segment='+ payload.by_segment +'&by_discount_qty='+ payload.by_discount_qty
      + '&activated=' + payload.activated 
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  create_product_discount(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/create_product_discount'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  update_product_discount(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/update_product_discount'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  get_customer_source(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/customer_source_details?page='
      + payload.page + '&by_source=' + payload.by_source + '&by_campaign=' + payload.by_campaign
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_customer_source_download(payload) {
    const url = process.env.REACT_APP_API_URL + 'store_dashboard/v1/store/reports/customer_signup_source_report?by_source='
      + payload.by_source + '&by_campaign=' + payload.by_campaign
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_source_list() {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/signup_sources'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_cashback(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/get_cashback_discounts?page='
      + payload.page
      + '&by_coupon=' + payload.valueByCouponCode 
      + '&by_segment=' + payload.by_segment
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  create_cashback(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/create_cashback'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  update_cashback(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/update_cashback'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  get_special_deals(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/special_deals?page=' + payload.page
      + '&by_product=' + payload.by_product + '&by_darkstore=' + payload.by_darkstore
      + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  update_special_deals(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/activate_deactivate_deals'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  get_discount_type() {
    const url = process.env.REACT_APP_API4_URL + 'admin_dashboard/v1/marketing/get_discount_types'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_bank_details() {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/bank_details'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_payment_type() {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/payment_types'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_webapp_campaign(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/campaign/campaign_landing_page?page=' + payload.page
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  create_webapp_campaign(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/campaign/campaign_landing_page'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload.body, headers)
  }

  update_webapp_campaign(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/campaign/campaign_landing_page/' + payload.id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload.body, headers)
  }

  update_deals_sequence(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/update_deals_sequence'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  get_referral_discounts() {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/referral_discounts'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  create_referral_discounts(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/create_referral_discount'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  get_referral_discounts_city() {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/referral_discount_city_mappings'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  update_referral_discounts_city(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/update_referral_discount_city_mapping'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload, headers)
  }

  get_pages(page) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/promotional_pages?page='+page
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }


  update_pages(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/promotional_pages/' + payload.id
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, payload.body, headers)
  }

  create_pages(payload) {
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/promotional_pages'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.post(url, payload, headers)
  }

  update_cluster_pages_sequence(id,payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/marketing/promotional_pages/'+ id +'/update_cluster_promotional_pages_sequence'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.patch(url, payload, headers)
  }

  get_freebies(payload){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/freebies?&by_segment=' + payload.by_segment
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  update_freebies(payload){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/freebies/' + payload.id 
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload.body, headers)
  }

  create_freebies(payload){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/admin/freebies' 
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload, headers)
  }

  get_segment(){
    const url = process.env.REACT_APP_SEGMENT_URL + 'admin/api/v1/segments?pagination=off'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  get_bulk_discount(payload){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/bulk_coupons_list' + payload
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  update_bulk_discount(payload){
    const url = process.env.REACT_APP_API_URL +'admin_dashboard/v1/marketing/' + payload.id +'/bulk_coupon_update'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload.body, headers)
  }

  create_bulk_discount(payload){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/marketing/bulk_coupon_create' 
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload, headers)
  }

}
