export const dsfulfillmentColumns = [
  {
      title: 'Darkstore Name',
      dataIndex: 'name',
      key: 'name',
      align: "center",
      width:270,
      sorter: (a, b) => a.name.localeCompare(b.name)
  },
  {
      title: 'Unfulfilled Count',
      dataIndex: 'unfulfilled_count',
      key: 'unfulfilled_count',
      align: 'center',
      sorter: (a, b) => a.unfulfilled_count - b.unfulfilled_count,
  },
  {
      title: 'Total Orders',
      dataIndex: 'total_orders',
      key: 'total_orders',
      align: 'center',
      width:150,
      sorter: (a, b) => a.total_orders - b.total_orders,
  },
  {
      title: 'Fulfillment Percentage',
      dataIndex: 'fulfillment_percentage',
      key: 'fulfillment_percentage',
      align: 'center',
      sorter: (a, b) => a.fulfillment_percentage - b.fulfillment_percentage,
  },
  {
      title: 'Product Count',
      dataIndex: 'product_count',
      key: 'product_count',
      align: 'center',
      width: 190,
      sorter: (a, b) => a.product_count - b.product_count,
  }
]

export const productunfulfillmentColumns = [
  {
      title: 'Product',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: 380,
      sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      align: 'center',
      sorter: (a, b) => a.sku.localeCompare(b.sku),
  },
  {
      title: 'Unfulfilled Count',
      dataIndex: 'unfulfilled_count',
      key: 'unfulfilled_count',
      align: 'center',
      sorter: (a, b) => a.unfulfilled_count - b.unfulfilled_count,
  },
  {
      title: 'Node Count',
      dataIndex: 'node_count',
      key: 'node_count',
      align: 'center',
      width:150,
      sorter: (a, b) => a.node_count - b.node_count,
  },
  {
      title: 'Wastage',
      dataIndex: 'wastage',
      key: 'wastage',
      align: 'center',
      width:120,
      sorter: (a, b) => a.wastage - b.wastage,
  }
]