import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { Modal, Form, Button} from 'antd';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import FRZRadioButton from '../../../_controls/FRZRadioButton'
import {openNotificationWithIcon} from '../../../_modules/notification';

const initialModal = {
  data: false,
  title: false,
  loading: false,
  visible: false,
}

const DsProperties = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const [modalState, setModalState] = useState(initialModal)

  useEffect(() => {
    if (modalState.data && modalState.title === 'Edit Properties') {
      form.setFieldsValue({...modalState.data})
    }
  }, [modalState.data])
  
  useEffect(() => {
    return () => {
      handleCancelModal()
    };
  },[]);

  useImperativeHandle(ref, () => ({
    handleCancel: handleCancelModal,
    showModal(data, title) {
      setModalState(prevState => ({ ...prevState, data: data, title: title, visible: true }))
    },
    stopLoading() {
      setModalState(prevState => ({ ...prevState, loading: false }))
    }
  }));

  function handleCancelModal() {
    form.resetFields()
    setModalState(initialModal)
  }

  const handleOnSubmit = (values) => {
    setModalState(prevState => ({ ...prevState, loading: true }))
    const payload = {
      id: modalState.data.id, 
      body: values
    }
    props.dsproperties_service.update_ds_properties(payload).subscribe((r) => {
      openNotificationWithIcon('success','Updated Successfully')
      props.updateTableList(r.response.data)
      handleCancelModal()
    }, (err) => {
      openNotificationWithIcon('error', err?.response?.errors ?? 'API Error')
      setModalState(prevState => ({ ...prevState, loading: false }))
    })
  }

  return (
    <div>
      {modalState.data ?
        <Modal
          visible={modalState.visible}
          title={modalState.title}
          width={'50%'}
          footer={[
            <Button key="back" onClick={handleCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={modalState.loading} onClick={() => {
              form
                .validateFields()
                .then(values => {
                  handleOnSubmit(values)
                })
                .catch(info => {
                  console.log('Validate Failed:', info);
                });
            }}>
              Submit
            </Button> 
          ]}
          onCancel={handleCancelModal}>
            <Form
              form={form}
              {...formItemLayout}
              layout="horizontal"
              name="form">

              <FRZRadioButton name="masking_enabled" label="Masking Enabled" isRequired={true} />

              <FRZInputNumber isRequired={true} label='Max Batch Size' name="max_batch_size" width={200} 
                placeholder="Max Batch Size" minValueZero/>
              
              <FRZInputNumber isRequired={true} label='Max Retries' name="max_retries" width={200} 
                placeholder="Max Retries" minValueZero/>

              <FRZInputNumber isRequired={true} label='Batching Threshold' name="batching_threshold" width={200} 
                placeholder="Batching Threshold" minValueZero/>

            </Form>
        </Modal>
      : null}
    </div>
  )
})

export default DsProperties
