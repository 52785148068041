import { Http } from '../_modules/http'

export class GrowthAndPlanningService {

  http = new Http()

  get_growth_and_planning(payload){
    const url = process.env.REACT_APP_API_URL + 
    `admin_dashboard/v1/admin/${payload.url}?date=${payload.date}&download=${payload.download}&by_darkstores=${payload.by_darkstores ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  update_growth_and_planning(minUrl, payload){
    const url = process.env.REACT_APP_API_URL + `admin_dashboard/v1/admin/${minUrl}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload, headers)
  }

  get_logs(minUrl) {
    const url = process.env.REACT_APP_API_URL + `admin_dashboard/v1/admin/${minUrl}`
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }
}
