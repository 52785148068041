import React, { useEffect, useState, useRef } from "react";
import {
  Spin,
  Button,
  Card,
  Row,
  Col,
  Input,
  InputNumber,
  Tag,
  Tabs,
  Pagination,
  DatePicker,
  Popconfirm,
  notification,
  Table,
} from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { StoreService } from "../../_services/store";
import Search from "../../components/antd/search";
import {
  stockColumns,
  stockDsColumns,
} from "../../components/antd/columns/store";
import { useSelector } from "react-redux";
import FrzDownload from '../../_controls/FRZDownload';
import FrzDynamicDownload from '../../_controls/FRZDynamicDownload';

const store_service = new StoreService();
const { TabPane } = Tabs;

function OutOfStock() {
  const [stockData, setStockData] = useState(false);
  const [renderData, setRenderData] = useState(false);
  const [columns, setColumns] = useState([]);
  const [dsColumns, setDsColumns] = useState([]);
  const [spinEnable, setSpinEnable] = useState(false);
  const [disable, setDisable] = useState(true);
  const [current, setCurrent] = useState(1);
  const [state, setState] = useState({
    valueSKU: undefined,
    valueBySource: undefined,
    valueByCategory: 'all'
  });
  const [darkstoreRender, setDarkstoreRender] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [categoryFilter] = useState([{id: 'all', name: 'All'}, {id: 'fnb', name: 'FnV'}, {id: 'non_fnb', name: 'Non - FnV'}]);

  const { valueSKU, valueBySource, valueByCategory } = state;

  useEffect(() => {
    if (!renderData) {
      setRenderData(true);
      getStock(currentTab);
    }
  });

  useEffect(() => {
    getColumns();
    getDsColumns();
  }, []);

  // useEffect(() => {
  //   if(stockData?.length > 0 && currentTab === 1 && columns.length === 0){
  //     getColumns();
  //   }else if(stockData?.length > 0 && currentTab === '2' && dsColumns.length === 0){
  //     getDsColumns();
  //   }
  //   console.log(stockData, currentTab, dsColumns, columns)
  // }, [stockData]);

  function getStock(key) {
    setSpinEnable(true);
    setStockData([]);
    if (key == 1) {
      const payload = {
        page: current,
        by_name: !valueSKU ? "" : valueSKU,
        category: valueByCategory ?? ''
      };
      store_service.get_stock(payload).subscribe(
        (r) => {
          setState((prevState) => ({
            ...prevState,
            total: r.response.meta.total_pages * 50,
          }));
          setSpinEnable(false);
          setStockData(r.response.data);
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      const payload = {
        page: current,
        darkstore_id: !valueBySource ? "" : valueBySource,
        category: valueByCategory ?? ''
      };
      store_service.get_ds_stock(payload).subscribe(
        (r) => {
          setState((prevState) => ({
            ...prevState,
            total: r.response.meta.total_pages * 50,
          }));
          setSpinEnable(false);
          setStockData(r.response.data);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  function getColumns() {
    stockColumns.map((data) => {
      columns.push(data);
    });
    columns.push({
      title: "Out Of Stock DS",
      dataIndex: "",
      key: "",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.darkstore_count - b.darkstore_count,
      render: (record) => {
        return (
          <>
            <div>
              {record?.darkstore_list?.map((data, index) => {
                return (
                  <Tag key={index} color="red">
                    {data}
                  </Tag>
                );
              })}
            </div>
            <div>  
              {record?.manual_oos_dps?.map((data, index) => {
                return (
                  <Tag key={index} color="blue">
                    {data}
                  </Tag>
                );
              })}
            </div>
            <div>  
              {record?.order_edited_oos_dps?.map((data, index) => {
                return (
                  <Tag key={index} color="yellow">
                    {data}
                  </Tag>
                );
              })}
            </div>
          </>
        )
      },
    });
    setColumns(columns);
  }

  function getDsColumns() {
    stockDsColumns.map((data) => {
      dsColumns.push(data);
    });
    dsColumns.push({
      title: "Out Of Stock SKU",
      dataIndex: "",
      key: "",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.product_count - b.product_count,
      render: (record) => {
        return (
          <>
            <div>
              {record?.product_list?.map((data, index) => {
                return (
                  <Tag key={index} color="red">
                    {data[0] + ' (' + data[1] + ')'}
                  </Tag>
                );
              })}
            </div>
            <div>  
              {record?.manual_oos_products?.map((data, index) => {
                return (
                  <Tag key={index} color="blue">
                    {data[0] + ' (' + data[1] + ')'}
                  </Tag>
                );
              })}
            </div>
            <div>  
              {record?.order_edited_oos_products?.map((data, index) => {
                return (
                  <Tag key={index} color="yellow">
                    {data[0] + ' (' + data[1] + ')'}
                  </Tag>
                );
              })}
            </div>
          </>
        )
      },
    });
    setDsColumns(dsColumns);
  }

  function pagination(page) {
    setCurrent(page);
    setStockData([]);
    setRenderData(false);
  }

  function onChange(value, type) {
    setState((prevState) => ({ ...prevState, [type]: value }));
    setDisable(false);
    setCurrent(1);
  }

  function reset() {
    setState({ valueSKU: undefined, valueBySource: undefined, valueByCategory: 'all' });
    setDisable(true);
    setCurrent(1);
    setStockData([]);
    setRenderData(false);
  }

  function changeTab(key) {
    setState({ valueSKU: undefined, valueBySource: undefined, valueByCategory: 'all' });
    setCurrentTab(key);
    setStockData([]);
    setCurrent(1);
    setDisable(true);
    setRenderData(false);
  }

  function openNotificationWithIcon(type, msg) {
    notification[type]({
      message: type.toUpperCase(),
      duration: 3,
      description: msg,
    });
  }

  return (
    <div className="layer">
      <div className="ost">
        <Tabs defaultActiveKey="1" onChange={changeTab}>
          <TabPane tab="SKU Wise" key="1"></TabPane>
          <TabPane tab="Darkstore Wise" key="2"></TabPane>
        </Tabs>
      </div>
      {stockData ? (
        <div>
          <Row>
            <Card>
              <Col span={24}>
                {currentTab == 1 ? (
                  <Input
                    placeholder="SKU Name"
                    onChange={(e) => onChange(e.target.value, "valueSKU")}
                    value={valueSKU}
                    style={{ width: 200, margin: 10 }}
                  />
                ) : (
                  <Search
                    placeholder="Darkstore"
                    value={valueBySource}
                    onChange={(e) => onChange(e, "valueBySource")}
                    type="valueByDarkstore"
                  />
                )}
                <Search
                  placeholder="Category"
                  value={valueByCategory}
                  onChange={onChange}
                  type="valueByCategory"
                  data={categoryFilter}
                />
                <Button
                  disabled={disable}
                  onClick={(e) => getStock(currentTab)}
                  style={{ width: 100, margin: 10 }}
                  type="primary"
                >
                  Search
                </Button>
                <Button
                  disabled={disable}
                  onClick={reset}
                  style={{ margin: 10 }}
                  type="primary"
                  ghost
                  shape="circle"
                  icon={<ReloadOutlined />}
                />
                {currentTab == 1 ? 
                  <FrzDynamicDownload name={'Report'} fileName={'Out of Stock SKU Wise'} 
                    url={'admin_dashboard/v1/downloads/out_of_stock_skus_report'}
                    payload={`?starts_with=${valueSKU ?? ''}&category=${valueByCategory ?? ''}`} type={'job'} reportFunc={'fraazo_report'} 
                    jobReportFunc={'download_reports_job_fraazo'} base={'fraazo'} />
                :
                  <FrzDownload 
                    setSpinEnable={setSpinEnable} 
                    url={'darkstore_wise_oos_report'}
                    startsWith={{filter: 'darkstore_id',
                                 value: valueBySource ? valueBySource : ''}}
                  />
                }
                <div className="frz-highlight">
                  <div className="frz-red-square"></div>
                  <div>System</div>
                  <div className="frz-blue-square"></div>
                  <div>Manual</div>
                  <div className="frz-yellow-square"></div>
                  <div>Order Edited</div>
                </div>    
              </Col>
            </Card>
          </Row>
          {stockData.length > 0 ? (
            <Row>
              <Col span={24}>
                {/* <MainTable dataSource={darkstoreData} columns={columns}/> */}
                <Card style={{ paddingTop: 5, marginTop: 20 }}>
                  <Row>
                    <Col span={24}>
                      {currentTab == 1 ? (
                        <Table
                          columns={columns}
                          dataSource={stockData}
                          pagination={false}
                          scroll={{ x: 280 }}
                          rowKey={"sku"}
                        />
                      ) : (
                        <Table
                          columns={dsColumns}
                          dataSource={stockData}
                          pagination={false}
                          scroll={{ x: 280 }}
                          rowKey={"darkstore_name"}
                        />
                      )}
                    </Col>
                  </Row>
                </Card>
                <Pagination
                  style={{ marginTop: 10, marginBottom: 10 }}
                  current={current}
                  pageSize={50}
                  total={state.total}
                  onChange={pagination}
                  showSizeChanger={false}
                />
              </Col>
            </Row>
          ) : disable || spinEnable ? (
            spinEnable ? (
              <div className="spin-center">
                <Spin tip="...Loading" />
              </div>
            ) : (
              <div className="no-data">No Data Available</div>
            )
          ) : (
            <div className="no-data">No Data Available On Selected Filters</div>
          )}
        </div>
      ) : (
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      )}
    </div>
  );
}

export default OutOfStock;
