import React, { useEffect, useState } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber, Select,
         Pagination, DatePicker, Popconfirm, notification, Tag } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {AdsService} from '../../_services/ads';
import MainTable from '../../components/antd/table';
import Search from '../../components/antd/search';
import {segmentColumns} from '../../components/antd/columns/ads';
import {handleDateFormat} from '../../_modules/date';
import {getStaticTime} from '../../_modules/time';

const ads_service = new AdsService()
const { Option } = Select;

function CustomerSegment(){
  const [segmentData, setSegmentData] = useState(false)
  const [customerCount, setCustomerCount] = useState(false)
  const [customerTypeData, setCustomerTypeData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [state, setState] = useState({valueByTag: undefined});
  const { valueByTag } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getCustomerSegment()
    }
  })

  useEffect(() => {
    getColumns()
    getNotificationType()
  }, [])

  function getCustomerSegment(){
    setSpinEnable(true)
    setSegmentData([])
    const payload = {
      page: current,
      tag_type: valueByTag === undefined ? '' : valueByTag,
    }
    ads_service.get_customer_segment(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setSpinEnable(false)
      setCustomerCount(r.response.customer_count)
      setSegmentData(r.response.customer_data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getNotificationType(){
    ads_service.get_customer_type().subscribe((r) => {
        setCustomerTypeData(r.response.data)
    },
    (err)=>{
        console.log(err)
    })
  }

  function getColumns(){
    segmentColumns.map(data => {
      columns.push(data)
    })
    columns.push({
        title: 'Active Tags',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (record) => {
            return (
                <div>
                    {record.customer_active_tags.map((data, index) => {
                        return  <Tag key={index} color='purple'>{data.display_name}</Tag>
                    })}
                </div>
            )
        }
    })
    setColumns(columns)
  }

  function pagination(page) {
    setCurrent(page)
    setSegmentData([])
    setRenderData(false)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function reset() {
    setState({valueByTag: undefined})
    setDisable(true)
    setCurrent(1)
    setSegmentData([])
    setRenderData(false)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  return(
    <div className="layer">
      {segmentData && customerTypeData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Select style={{ width: 430, marginLeft: 10, marginRight: 10}} value={valueByTag}
                            placeholder="Customer Type" onChange={(e) => onChange(e, 'valueByTag')}>
                      {customerTypeData.map(data => {
                        return(
                          <Option key={data.id} value={data.id}>
                            {data.description}
                          </Option>
                        )
                      })}
                </Select>
                <Button disabled={disable} onClick={getCustomerSegment} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
                <span style={{float: 'right', padding: 15}}><b>Customers: {customerCount}</b></span>
              </Col>
            </Card>
          </Row>
          {segmentData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={segmentData} columns={columns}/>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default CustomerSegment
