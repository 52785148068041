import React, { useEffect, useState, useRef } from 'react';
import { Spin, Button, Card, Row, Col, Input, InputNumber,
         Pagination, DatePicker, Popconfirm, notification } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {AdsService} from '../../_services/ads';
import MainTable from '../../components/antd/table';
import Search from '../../components/antd/search';
import {adsColumns} from '../../components/antd/columns/ads';
import AdsModal from '../../components/ads/ads_modal';
import FBPixelModal from '../../components/ads/pixel_modal';
import {handleDateFormat} from '../../_modules/date';
import {getStaticTime} from '../../_modules/time';

const ads_service = new AdsService()

function AdCampaign(){
  const modalRef = useRef(null);
  const pixelRef = useRef(null);
  const [adsData, setAdsData] = useState(false)
  const [customerCount, setCustomerCount] = useState(undefined)
  const [renderData, setRenderData] = useState(false)
  const [renderDataCustomers, setRenderDataCustomers] = useState(true)
  const [columns, setColumns] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [current, setCurrent] = useState(1)
  const [imageUrl, setImageUrl] = useState(undefined)
  const [imgLoading, setImgLoading] = useState(false)
  const [typeData, setTypeData] = useState([{name: 'mobile_sms'}, {name: 'push_notify'}])
  const [state, setState] = useState({valueByType: undefined, valueByDate: undefined, date: ''});
  const [modalState, setModalState] = useState({valueByDarkstore: [], customer_type: undefined,
                                                valueByRadio: undefined, mob_includes: [], mob_excludes: [],
                                                notification_type: undefined, campaign_name: undefined,
                                                title: undefined, message: undefined, url: undefined,
                                                firebaseLink: undefined, is_serviceable: null, exclude_customer_type: [],
                                                exclude_darkstore: [], currentType: undefined});
  const {valueByDarkstore, customer_type, valueByRadio, mob_includes, mob_excludes, exclude_customer_type, currentType,
         notification_type, campaign_name, title, message, url, firebaseLink, is_serviceable, exclude_darkstore} = modalState
  const { valueByType, valueByDate, date } = state

  useEffect(() => {
    if(!renderData){
      setRenderData(true)
      getAds()
    }
  })

  useEffect(() => {
    getColumns()
  }, [])

  function getAds(){
    setSpinEnable(true)
    setAdsData([])
    const payload = {
      page: current,
      by_notification_type: valueByType === undefined ? '' : valueByType,
      by_date: valueByDate === undefined ? '' : valueByDate,
    }
    ads_service.get_ads(payload).subscribe((r) => {
      setState(prevState =>({...prevState, total: r.response.meta.total_pages * 50}))
      setSpinEnable(false)
      setAdsData(r.response.data)
    },
    (err)=>{
      console.log(err)
    })
  }

  function getColumns(){
    adsColumns.map(data => {
      columns.push(data)
    })
    columns.unshift(
    {
      title: 'Darkstores',
      dataIndex: '',
      key: '',
      width: 700,
      align: 'center',
      render: (record) => {
        return(record.included_darkstores.length > 0 ?
                <div>
                    {record.included_darkstores.map(data => {
                    return (
                        <span key={data.id}>{data.name.split('-').slice(-1).pop()}, </span>
                    )
                    })}
                </div>
                :
                <div>All Darkstores</div>
        )
      }
    },
    {
      title: 'Exluded Darkstores',
      dataIndex: '',
      key: '',
      width: 700,
      align: 'center',
      render: (record) => {
        return(record.excluded_darkstores.length > 0 ?
                <div>
                    {record.excluded_darkstores.map(data => {
                    return (
                        <span key={data.id}>{data.name.split('-').slice(-1).pop()}, </span>
                    )
                    })}
                </div>
                :
                <div>None</div>
        )
      }
    })
    columns.push({
        title: "Image",
        dataIndex: "",
        key: "",
        align: "center",
        render: (record) => {
          return (
            record.image_url ? <img src={record.image_url} height={80} /> : null
          );
        },
    },
    {
      title: "Created On",
      dataIndex: "",
      key: "",
      align: "center",
      width: 150,
      render: (record) => {
        return (
          <>
            <div>{handleDateFormat(record.created_at)}</div>
            <div>{getStaticTime(record.created_at)}</div>
          </>
        );
      },
  })
    setColumns(columns)
  }

  function pagination(page) {
    setCurrent(page)
    setAdsData([])
    setRenderData(false)
  }

  function onChange(value, type) {
     setState(prevState =>({...prevState, [type]: value}))
     setDisable(false)
     setCurrent(1)
  }

  function onChangeDate(date, dateString) {
    // console.log(date)
    setState(prevState =>({...prevState, date: date, valueByDate: dateString}))
    setDisable(false)
    setCurrent(1)
  }

  function reset() {
    setState({valueByType: undefined, valueByDate: undefined, date: ''})
    setDisable(true)
    setCurrent(1)
    setAdsData([])
    setRenderData(false)
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  function showModal(data, type) {
    modalRef.current.showModal(type, data)
  }

  function showModalURL(data, type) {
    setModalState(prevState =>({...prevState, currentType: type}))
    pixelRef.current.showModal(type, data)
  }

  function onChangeModal(value, type) {
    if(type === 'valueByRadio'){
      setModalState(prevState =>({...prevState, [type]: value}))
    }else{
      setModalState(prevState =>({...prevState, [type]: value === "" ? undefined : value}))
    }
  }

  function onChangeModalFilter(value, type) {
    if(type === 'valueByRadio' || type === 'is_serviceable'){
      setModalState(prevState =>({...prevState, [type]: value}))
      if(value === 'all_darkstores'){
        setModalState(prevState =>({...prevState, valueByDarkstore: []}))
        setRenderDataCustomers(false)
      }
      if(type === 'is_serviceable'){
        setRenderDataCustomers(false)
      }
    }else{
      setModalState(prevState =>({...prevState, [type]: value === "" ? undefined : value}))
      setRenderDataCustomers(false)
    }
  }

  useEffect(() => {
    if(!renderDataCustomers){
      setRenderDataCustomers(true)
      setCustomerCount(undefined)
      getCustomers()
    }
  })

  function handleinclude(values){
    setModalState(prevState =>({...prevState, mob_includes: values}))
  }

  function handleexcludes(values){
    setModalState(prevState =>({...prevState, mob_excludes: values}))
  }

  function onCancelModal() {
    setModalState({valueByDarkstore: [],  valueByRadio: undefined, customer_type: undefined, 
                   mob_includes: [], mob_excludes: [], notification_type: undefined, campaign_name: undefined,
                   title: undefined, message: undefined, url: undefined, firebaseLink: undefined, 
                   is_serviceable: null, exclude_customer_type: [], exclude_darkstore: [], currentType: undefined})
    setCustomerCount(undefined)
    setImageUrl(undefined)
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function handleChange(info){ 
    if (info.file.status === 'uploading') {
      setImgLoading(true)
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        setImageUrl(imageUrl),
        setImgLoading(false)
      )
    }
  };

  function getCustomers(){
    const payload = {
        must_include: [],
        not_include: [],
        by_darkstore: valueByDarkstore,
        type_id: customer_type === undefined ? null : customer_type,
        is_serviceable: is_serviceable,
        exclude_tag: exclude_customer_type,
        exclude_darkstore: exclude_darkstore
      }
      ads_service.get_customers(payload).subscribe((r) => {
        setCustomerCount(r.response.data.customers_count)
      },
      (err)=>{
        console.log(err)
      })
  }

  function modalSubmit(){
    if(valueByRadio === undefined || !notification_type || !message){
        openNotificationWithIcon('error', "Please fill required fields")
        modalRef.current.stopLoading()
    }else{
        if(notification_type === 'push_notify'){
            if(!title || !campaign_name){
                openNotificationWithIcon('error', "Please fill required fields")
                modalRef.current.stopLoading()
            }else{
                if(valueByRadio === 'individual_darkstores'){
                    if(valueByDarkstore.length == 0){
                        openNotificationWithIcon('error', "Please Select a Darkstore")
                        modalRef.current.stopLoading()
                        return
                    }
                }
                const payload = {
                    must_include: mob_includes,
                    not_include: mob_excludes,
                    by_darkstore: valueByDarkstore,
                    type_id: customer_type === undefined ? null : customer_type,
                    notification_type: notification_type,
                    title: title,
                    campaign_name: campaign_name,
                    image: imageUrl === undefined ? null : imageUrl,
                    message: message,
                    is_serviceable: is_serviceable,
                    exclude_tag: exclude_customer_type,
                    exclude_darkstore: exclude_darkstore
                }
                ads_service.create_ads(payload).subscribe((r) => {
                    openNotificationWithIcon('success','Created Successfully')
                    getAds()
                    modalRef.current.handleCancel()
                },
                (err)=>{
                    console.log(err)
                    modalRef.current.stopLoading()
                    openNotificationWithIcon('error', err.response.errors[0])
                })
            }
        }else{
            if(valueByRadio === 'individual_darkstores'){
                if(valueByDarkstore.length == 0){
                    openNotificationWithIcon('error', "Please Select a Darkstore")
                    modalRef.current.stopLoading()
                    return
                }
            }
            const payload = {
                must_include: mob_includes,
                not_include: mob_excludes,
                by_darkstore: valueByDarkstore,
                type_id: customer_type === undefined ? null : customer_type,
                notification_type: notification_type,
                message: message,
                is_serviceable: is_serviceable,
                exclude_tag: exclude_customer_type,
                exclude_darkstore: exclude_darkstore
            }
            ads_service.create_ads(payload).subscribe((r) => {
                openNotificationWithIcon('success','Created Successfully')
                getAds()
                modalRef.current.handleCancel()
            },
            (err)=>{
                console.log(err)
                modalRef.current.stopLoading()
                openNotificationWithIcon('error', err.response.errors[0])
            })
        }
    }
  }

  function modalSubmitURL(){
    if(currentType === 'Add Deep Link'){
      if(!url){
        openNotificationWithIcon('error', "Please add url")
        pixelRef.current.stopLoading()
      }else{
        const payload = {
          link: url
        }
        ads_service.create_deeplink(payload).subscribe((r) => {
          openNotificationWithIcon('success','Created Successfully')
          pixelRef.current.stopLoading()
          setModalState(prevState =>({...prevState, firebaseLink: r.response.data.firebase_dynamic_link}))
        },
        (err)=>{
            console.log(err)
            pixelRef.current.stopLoading()
            openNotificationWithIcon('error', err.response.errors[0])
        })
      }
    }else{
      if(valueByRadio === 'individual_darkstores' && valueByDarkstore.length === 0){
        openNotificationWithIcon('error', "Please Select Darktore")
        pixelRef.current.stopLoading()
        return
      }
      if(!message){
        openNotificationWithIcon('error', "Please Input Message")
        pixelRef.current.stopLoading()
        return
      }
      const payload = {
        darkstore_id: valueByDarkstore,
        msg: message
      }
      if(valueByRadio === 'all_darkstores'){
        payload.exclude_darkstores = exclude_darkstore
      }
      ads_service.create_undelivered_sms(payload).subscribe((r) => {
        openNotificationWithIcon('success','SMS Sent Successfully')
        getAds()
        pixelRef.current.handleCancel()
      },
      (err)=>{
          console.log(err)
          pixelRef.current.stopLoading()
          openNotificationWithIcon('error', err.response.errors[0])
      })
    }
  }
  
  function onChangeModalExclude(values) {
    setModalState(prevState =>({...prevState, exclude_customer_type: values}))
    setRenderDataCustomers(false)
  }

  function onChangeModalExcludeDS(values) {
    setModalState(prevState =>({...prevState, exclude_darkstore: values}))
    if(currentType !== 'Send SMS'){
      setRenderDataCustomers(false)
    }
  }
  
  function onChangeDarkstore(values){
    setModalState(prevState =>({...prevState, valueByDarkstore: values}))
    if(currentType !== 'Send SMS'){
      setRenderDataCustomers(false)
    }
  }

  return(
    <div className="layer">
      {adsData ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Notification Type" value={valueByType}
                        onChange={onChange} type="valueByType" data={typeData}/>
                <DatePicker value={date} onChange={onChangeDate} placeholder="Date"
                            style={{width: 200, marginLeft: 10, marginRight: 10}}/>
                <Button disabled={disable} onClick={getAds} style={{width: 100, margin:10}}
                        type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} style={{margin:10}}
                        type="primary" ghost shape="circle" icon={<ReloadOutlined />}/>
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                {/* <Button onClick={() => showModal(true, 'Send Notifications')} style={{width: 220, margin:10}}
                        type="primary">Create Notification Campaign</Button> */}
                <Button onClick={() => showModalURL(true, 'Add Deep Link')} style={{width: 220, margin:10}}
                        type="primary">Create Firebase Deeplink</Button>
                <Button onClick={() => showModalURL(true, 'Send SMS')} style={{width: 260, margin:10}}
                        type="primary">Send SMS To Undelivered Orders</Button>
              </Col>
            </Card>
          </Row>
          {adsData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={adsData} columns={columns}/>
                <Pagination style={{marginTop: 10, marginBottom: 10}} current={current}
                            pageSize={50} total={state.total} onChange={pagination}
                            showSizeChanger={false}/>
              </Col>
            </Row>
            : (disable || spinEnable ?
                (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Ads Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <AdsModal modalSubmit={modalSubmit} ref={modalRef}
                    onChangeModal={onChangeModal}
                    handleinclude={handleinclude}
                    handleexcludes={handleexcludes}
                    onChangeModalFilter={onChangeModalFilter}
                    onCancelModal={onCancelModal}
                    onChangeModalExclude={onChangeModalExclude}
                    onChangeModalExcludeDS={onChangeModalExcludeDS}
                    onChangeDarkstore={onChangeDarkstore}
                    customerCount={customerCount}
                    valueByDarkstore={valueByDarkstore}
                    customer_type={customer_type}
                    valueByRadio={valueByRadio}
                    notification_type={notification_type}
                    campaign_name={campaign_name}
                    title={title}
                    message={message}
                    imageUrl={imageUrl}
                    imgLoading={imgLoading}
                    is_serviceable={is_serviceable}
                    exclude_customer_type={exclude_customer_type}
                    exclude_darkstore={exclude_darkstore}
                    handleChange={handleChange}/>
          <FBPixelModal modalSubmit={modalSubmitURL} ref={pixelRef}
                        onChangeModal={onChangeModal}
                        onChangeDarkstore={onChangeDarkstore}
                        onChangeModalFilter={onChangeModalFilter}
                        onChangeModalExcludeDS={onChangeModalExcludeDS}
                        onCancelModal={onCancelModal} url={url}
                        valueByRadio={valueByRadio} message={message}
                        exclude_darkstore={exclude_darkstore}
                        valueByDarkstore={valueByDarkstore}
                        firebaseLink={firebaseLink}/>
        </div>
      :
        <div className="spin-center">
          <Spin tip="...Loading"/>
        </div>
      }
    </div>
  )
}

export default AdCampaign
