import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form, Steps } from 'antd';
import FRZInput from '../../../_controls/FRZInput';
import FRZSelect from '../../../_controls/FRZSelect';
import FRZCustomRadioButon from '../../../_controls/FRZCustomRadioButon';
import FRZRadioButton from '../../../_controls/FRZRadioButton';
import FRZUpload from '../../../_controls/FRZUpload';
import { formItemLayout } from '../../../_modules/controllayout';
import { LoadingOutlined, PlusOutlined, InteractionOutlined, DollarCircleOutlined, FormOutlined } from '@ant-design/icons';
import PGGateway from './steps/pg_gateway';
import PGDistribution from './steps/pg_distribution';

const { Step } = Steps;

const PaymentsModal = forwardRef((props, ref) => {

  const { modalSubmit } = props
  const PGActivationType = props.paymentsType

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [form] = Form.useForm();
  const [payType, setPayType] = useState(true)
  const [parentId, setParentId] = useState(false)
  const [PGAType, setPGAType] = useState(props.paymentsType[0])
  const [isSubmit, setIsSubmit] = useState(false)
  const paymentType = [
    { value: true, option: "Main Payment Methods" },
    { value: false, option: "Sub - Payment Methods" },
  ]
  const statusData = [
    { name: "active", option: "active" },
    { name: "inactive", option: "inactive" },
    { name: "deactivated", option: "deactivated" },
    { name: "testing_active", option: "testing_active" }
  ]
  const [imageState, setImageState] = useState({})
  const [current, setCurrent] = useState(0);
  const [activePayments, setActivePayments] = useState([])

  useEffect(() => {
    setPGAType(props.paymentsType[0])
  }, [current])

  useEffect(() => {
    if (props.rowData) {
      setActivePayments(props.rowData.payment_gateway_details)
    }
  }, [props.rowData])

  useEffect(() => {
    if (data && title == "Edit Payment Methods") {
      form.setFieldsValue({ ...data, payType: data.parent_id ? false : true, gateway_code: data.payment_gateway_details[0].gateway_code })
      setPayType(data.parent_id ? false : true)
      setParentId(data.parent_id)
    } else {
      form.setFieldsValue({ payType: true })
    }
    setImageState({})
  }, [data])

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      if (!title !== 'Create Payment Methods' && !props.rowData.has_distribution) {
        setVisible(false)
        setData(false)
        form.resetFields()
        setLoading(false)
        setCurrent(0)
      } else {
        setLoading(false)
        setCurrent(0)
      }
      // setData(false)
      // form.resetFields()
    },

    stopLoading() {
      setLoading(false)
    },

    // setPayTrue() {
    //   console.log("setting true")
    //   setPayType(true)
    // }

  }));

  function handleOnSubmit(values) {
    if (current == 0) {
      delete values.payType
      setLoading(true)
      if (imageState.image) {
        delete values.image
        modalSubmit({ ...values, image_attributes: imageState }, data?.id ?? null)
      } else {
        delete values.image
        modalSubmit(values, data?.id ?? null)
      }
    } else if (current == 1) {
      setIsSubmit('PGA')
    } else {
      setIsSubmit('PGD')
      //distribution
      let data = form.getFieldValue()
      // console.log("data", data)
    }
  }

  function handleCancelModal() {
    // setVisible(false)
    setLoading(false)
    setCurrent(0)
    setData(false)
    setPayType(true)
    setParentId(false)
    form.resetFields()
  }

  const handlePaymentType = (value) => {
    setPayType(value)
    if (!value) {
      form.setFieldsValue({ actual_payment: true })
    } else {
      form.setFieldsValue({ actual_payment: undefined })
    }
  }

  const uploadButton = (
    <div>
      {props.imgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleOnChangeImage = (type, item) => {
    setImageState(prevState => ({ ...prevState, [type]: item }))
  }

  function handleClick({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  function onChange(value) {
    setParentId(value);
  }

  const onChangeSteps = (value) => {
    setCurrent(value);
  };

  const validateCurrentForm = () => {
    let stepOneValidation = ['payType', 'parent_id', 'name', 'internal_code', 'ios_gateway_code',
      'gateway_code', 'status', 'actual_payment', 'auto_expand', 'image']
    let stepTwoValidation = ['']
    form
      .validateFields(current == 0 ? stepOneValidation : stepTwoValidation).then(values => {
        setCurrent(current + 1)
      }).catch(info => {
        console.log('Validate Failed:', info);
      })
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          // cancelText={current == 0 ? "Cancel" : "Back"}
          // okText={title === 'Create Payment Methods' && current !== 2 ? "Next" : "Submit"}
          okText={"Submit"}
          width={'50%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            // if (title === 'Create Payment Methods') {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
            // } else {
            //   if (current == 2) {
            //     return
            //   }
            //---// validate the current form only then let user move to next
            //   validateCurrentForm()
            // }
          }}
          // onCancel={current == 0 ? handleCancelModal : () => setCurrent(current - 1)}>
          onCancel={handleCancelModal}>
          <div>
            <Steps current={current} onChange={onChangeSteps} style={{ marginBottom: 20 }} >
              {title !== 'Create Payment Methods' && props.rowData.has_distribution ?
                <>
                  <Step title="Basic Information" icon={<FormOutlined />} />
                  <Step title="PG Activation" icon={<DollarCircleOutlined />} />
                  <Step title="PG Distrubution" icon={<InteractionOutlined />} />
                </>
                : null
              }
            </Steps>

            {/* step -1 */}
            {current === 0 &&
              <div className={current !== 0 ? `frz-dis-none` : ''}>
                {/* <BasicInfo /> */}
                <Form
                  form={form}
                  {...formItemLayout}
                  layout="horizontal"
                  name="form">

                  <FRZCustomRadioButon name="payType" label="Payment Type" isRequired={true} disabled={title === "Edit Payment Methods"}
                    optionData={paymentType} option="option" value="value" setOnChange={handlePaymentType} />

                  {!payType ?
                    <FRZSelect DropDownData={props.parentData} name="parent_id" isRequired={true}
                      label="Main Payment Method" placeholder="Main Payment Method" value="payment_mode_id" option="name"
                      isShowSearch={true} width={200} selectDisabled={title === "Edit Payment Methods"}
                      isDisabledVal={props.parentData.filter(x => x.actual_payment === true).map(a => a.payment_mode_id)}
                      optionDisabledKey='payment_mode_id' setOnChange={(e) => onChange(e)} />
                    : null}

                  <FRZInput name="name" isRequired={true} label="Name" placeholder="Name" width={200} />

                  <FRZInput name="internal_code" isRequired={true} label="Internal Code" placeholder="Internal Code" width={200} />

                  {/* {!payType && props.activeParent.internal_code === 'upi' ?
                    <FRZInput name="ios_gateway_code" isRequired={true} label="IOS Gateway Code" placeholder="IOS Gateway Code" width={200} />
                    : null} */}

                  {title === "Create Payment Methods" || !props.rowData.has_distribution ?
                    <FRZInput name="gateway_code" isRequired={true} label="Gateway Code" placeholder="Gateway Code" width={200} />
                    : null}

                  <FRZInput name="short_name" label="Short Name" placeholder="Short Name" width={200} />

                  <FRZSelect name="status" isRequired={true} label="Status" placeholder="Status" width={200} DropDownData={statusData} value="option" option="name" />

                  <FRZRadioButton name="actual_payment" label="Actual Payment" isRequired={true} disabled={title === "Edit Payment Methods" || !payType} />

                  {payType ? <FRZRadioButton name="auto_expand" label="Auto Expand" isRequired={true} />
                    : null}
                  <FRZUpload name="image" className="avatar-uploader" customRequest={handleClick}
                    imgWidth={"100%"} label={"Upload Img Icon"} listType="picture-card" setOnChangeType={"image"}
                    showUploadList={false} uploadButton={uploadButton} handleOnChangeImage={handleOnChangeImage}
                    imageState={imageState} dimension="(72px * 72px)" />

                </Form>
              </div>}

            {/* step -2 */}
            {current === 1 &&
              <div className={`${current !== 1 ? 'frz-dis-none' : ''}`}>
                <PGGateway statusData={statusData} form={form} PGActivationType={PGActivationType} setPGAType={(value) => setPGAType(value)}
                  isSubmit={isSubmit} setIsSubmit={setIsSubmit} PGAType={PGAType} rowData={props.rowData} payType={payType}
                  activeParent={props.activeParent} setActivePayments={(value) => setActivePayments(value)}
                  activePayments={activePayments} getPayments={props.getPayments} />
              </div>}

            {/* step -3 */}
            {current === 2 &&
              <div>
                <PGDistribution isSubmit={isSubmit} setIsSubmit={setIsSubmit} form={form} PGActivationType={PGActivationType}
                  rowData={props.rowData} activePayments={activePayments} setActivePayments={(value) => setActivePayments(value)}
                  getPayments={props.getPayments} activeParent={props.activeParent} payType={payType} />
              </div>}

          </div>
        </Modal>
        :
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}

    </div >
  )
})

export default PaymentsModal
