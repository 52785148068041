import React from "react"
import { handleDateFormat } from "../../../_modules/date"
export const orderColumns = [
  {
    title: 'Customer Name',
    dataIndex: 'customer_name',
    key: 'customer_name',
    align: 'center'
  },
  {
    title: 'Mobile No',
    dataIndex: 'mobile_number',
    key: 'mobile_number',
    align: 'center',
  },
  {
    title: 'Order No',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return <>
        <div>{record.order_number}</div>
        {record.priority ?
          <div className='order-priority-table'>
            Priority
          </div>
          : null}
      </>
    }
  },
  {
    title: 'Darkstore Name',
    dataIndex: 'darkstore_name',
    key: 'darkstore_name',
    align: 'center'
  },
  {
    title: 'Confirmed At',
    dataIndex: 'confirmed_at',
    key: 'confirmed_at',
    align: 'center',
  },
  {
    title: 'Nett Amount',
    dataIndex: 'nett_amount',
    key: 'nett_amount',
    align: 'center',
  },
  {
    title: 'Order Status',
    dataIndex: 'order_status',
    key: 'order_status',
    align: 'center',
  },
  {
    title: 'Order Type',
    dataIndex: 'order_type_detail',
    key: 'order_type_detail',
    align: 'center',
  }
]

export const deliveryItemColumns = [
  {
    title: 'Product Name',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return <div>
        {record.product.name}
        {record?.freebie_info?.visibility ?
          <div className="frz-freebie-info">Visibility : {record?.freebie_info?.visibility ?? ''}</div>
          : null}
      </div>
    }
  },
  {
    title: 'Pack Size',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.product.pack_size
    }
  },
  {
    title: 'Ordered Qty',
    dataIndex: 'ordered_qty',
    key: 'ordered_qty',
    align: 'center',
  },
  {
    title: 'Final Qty',
    dataIndex: 'qty',
    key: 'qty',
    align: 'center',
  },
  {
    title: 'Qty Changed',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.is_qty_changed.toString()
    }
  },
  {
    title: 'Unit Price',
    dataIndex: 'moq_effective_unit_price',
    key: 'moq_effective_unit_price',
    align: 'center',
  },
  {
    title: 'Nett Amt',
    dataIndex: 'nett_amount',
    key: 'nett_amount',
    align: 'center',
  },
  {
    title: 'Review',
    dataIndex: 'review_parameter',
    key: 'review_parameter',
    align: 'center',
  },
  {
    title: 'Reason',
    dataIndex: 'review_detail_reason',
    key: 'review_detail_reason',
    align: 'center',
  },
]

export const refundItemColumns = [
  {
    title: 'Product Name',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.product.name
    }
  },
  {
    title: 'Refund Qty',
    dataIndex: 'refund_qty',
    key: 'refund_qty',
    align: 'center',
  },
  {
    title: 'Refund Amt',
    dataIndex: 'refund_amount',
    key: 'refund_amount',
    align: 'center',
  },
  {
    title: 'Refund Reason',
    dataIndex: 'refund_reason_detail',
    key: 'refund_reason_detail',
    align: 'center',
  },
  {
    title: 'Refund Razorpay Id',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.refund_payment_details ? record.refund_payment_details.razorpay_refund_id : null
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
  },
  {
    title: 'Reason',
    dataIndex: 'refund_reason_detail',
    key: 'refund_reason_detail',
    align: 'center',
  }
]

export const refundColumns = [
  {
    title: 'Refund Amt',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
  },
  {
    title: 'Mode',
    dataIndex: 'mode_of_refund',
    key: 'mode_of_refund',
    align: 'center',
  },
  {
    title: 'Razorpay Refund Id',
    dataIndex: 'razorpay_refund_id',
    key: 'razorpay_refund_id',
    align: 'center',
  },
  {
    title: 'Reference No',
    dataIndex: 'reference_number',
    key: 'reference_number',
    align: 'center',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'amostatusunt',
    align: 'center',
  },
]

export const paymentColumns = [
  {
    title: 'Payment Type',
    dataIndex: 'payment_type',
    key: 'payment_type',
    align: 'center',
  },
  {
    title: 'Payment Mode',
    dataIndex: 'mode_of_payment',
    key: 'mode_of_payment',
    align: 'center',
  },
  {
    title: 'Order Id',
    dataIndex: 'paymentmode_order_id',
    key: 'paymentmode_order_id',
    align: 'center',
  },
  {
    title: 'Payment Id',
    dataIndex: 'paymentmode_payment_id',
    key: 'paymentmode_payment_id',
    align: 'center',
  },
  {
    title: 'Reason To Customer',
    dataIndex: '',
    key: '',
    align: 'center',
    width: 200,
    render: (record) => {
      return record?.error_reason?.reason_to_customer
    }
  },
  {
    title: 'Resolution',
    dataIndex: '',
    key: '',
    align: 'center',
    width: 200,
    render: (record) => {
      return record?.error_reason?.resolution
    }
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
  },
]

export const replacementColumns = [
  {
    title: 'Name',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.customer_details.name
    }
  },
  {
    title: 'Mobile No',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.customer_details.mobile_number
    }
  },
  {
    title: 'Address',
    dataIndex: '',
    key: '',
    width: 200,
    align: 'center',
    render: (record) => {
      return record.delivery_address.flat_detail + ' ' + record.delivery_address.landmark
    }
  },
  {
    title: 'Order No',
    dataIndex: 'order_number',
    key: 'order_number',
    align: 'center',
  },
  {
    title: 'Order Placed At',
    dataIndex: 'order_placed_at',
    key: 'order_placed_at',
    width: 150,
    align: 'center',
  },
  {
    title: 'Delivery Date',
    dataIndex: 'delivery_date',
    key: 'delivery_date',
    width: 150,
    align: 'center',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
  },
  {
    title: 'Nett Amount',
    dataIndex: 'nett_amount',
    key: 'nett_amount',
    align: 'center',
  },
]

export const nestedReplacementColumns = [
  {
    title: 'Product Name',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.product.name
    }
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    key: 'qty',
    align: 'center',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
  },
  {
    title: 'Nett Amount',
    dataIndex: 'nett_amount',
    key: 'nett_amount',
    align: 'center',
  },
]

export const customerColumns = [
  {
    title: 'Mobile No',
    dataIndex: 'mobile_number',
    key: 'mobile_number',
    align: 'center',
  },
  {
    title: 'Name',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.customer_profile ?
        record.customer_profile.title + ' ' + record.customer_profile.first_name + ' ' + record.customer_profile.last_name : ''
    }
  },
  {
    title: 'Email',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.customer_profile?.email ?? ''
    }
  },
  {
    title: 'Darkstore',
    dataIndex: '',
    key: '',
    align: 'center',
    render: (record) => {
      return record.customer_address?.darkstore_details?.name ?? ''
    }
  },
]

export const fraudLogsColumns = [
  {
    title: 'Customer Name',
    dataIndex: 'customer_name',
    key: 'customer_name',
    align: 'center',
    sorter: (a, b) => a.customer_name.localeCompare(b.customer_name)
  },
  {
    title: 'Mobile Number',
    dataIndex: 'mobile_number',
    key: 'mobile_number',
    align: 'center',
    sorter: (a, b) => a.mobile_number - b.mobile_number,
  },
  {
    title: 'Order Number',
    dataIndex: 'order_number',
    key: 'order_number',
    align: 'center',
    sorter: (a, b) => a.order_number - b.order_number,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    sorter: (a, b) => a.status.localeCompare(b.status)
  },
  {
    title: 'Cancellation Reason',
    dataIndex: 'cancellation_reason',
    key: 'cancellation_reason',
    align: 'center',
    sorter: (a, b) => a.cancellation_reason.localeCompare(b.cancellation_reason)
  },
  {
    title: 'Confirmed At',
    dataIndex: 'confirmed_at',
    key: 'confirmed_at',
    align: 'center',
    sorter: (a, b) => new Date(a.confirmed_at) - new Date(b.confirmed_at),
    render: (record) => handleDateFormat(record)
  },
  {
    title: 'Darkstore Name',
    dataIndex: 'darkstore_name',
    key: 'darkstore_name',
    align: 'center',
    sorter: (a, b) => a.darkstore_name.localeCompare(b.darkstore_name)
  },
  {
    title: 'Store Manager Name',
    dataIndex: 'store_manager_name',
    key: 'store_manager_name',
    align: 'center',
    sorter: (a, b) => a.store_manager_name.localeCompare(b.store_manager_name)
  },
  {
    title: 'Nett Amount',
    dataIndex: 'nett_amount',
    key: 'nett_amount',
    align: 'center',
    sorter: (a, b) => a.nett_amount - b.nett_amount,
  },
  {
    title: 'Delivery Amount',
    dataIndex: 'delivery_amount',
    key: 'delivery_amount',
    align: 'center',
    sorter: (a, b) => a.delivery_amount - b.delivery_amount,
  },  
  {
    title: 'Order Edited',
    dataIndex: 'order_edited',
    key: 'order_edited',
    align: 'center',
    sorter: (a, b) => a.order_edited - b.order_edited,
  },
  {
    title: 'Packer Name',
    dataIndex: 'packer_name',
    key: 'packer_name',
    align: 'center',
    sorter: (a, b) => a.packer_name.localeCompare(b.packer_name)
  },
  
  {
    title: 'Past Order Cancellation',
    dataIndex: '',
    key: '',
    align: 'center',
    sorter: (a, b) => a.past_order_cancellation - b.past_order_cancellation,
    render: (record) => {
      return record.past_order_cancellation?.toFixed(2) + ' %' ?? '0' + ' %'
    }
  },
 
]