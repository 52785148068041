import React, { useEffect, useState, useRef } from "react"
import { Row, Col, Spin } from "antd"
import CityPriceUpdate from "./city_price_update"
import DarkstorePriceUpdate from "./darkstore_price_update"
import { ProductService } from '../../../../_services/product';
import PriceUpdateModal from "./price_update_modal";
import { openNotificationWithIcon } from "../../../../_modules/notification";

const product_service = new ProductService()

const CityLevelPriceUpdate = (props) => {
  const [activeCity, setActiveCity] = useState(false);
  const [activeCityId, setActiveCityId] = useState(false);
  const [cityWiseDs, setCityWiseDs] = useState(false);
  const [allCityList, setAllCityList] = useState(false);
  const [renderData, setRenderData] = useState(false)
  const [spinEnable, setSpinEnable] = useState(false)
  const [darkstoreSpinEnable, setDarkstoreSpinEnable] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    if (!renderData) {
      setRenderData(true);
      getCities();
    }
  }, []);

  useEffect(() => {
    if (allCityList) {
      if (!activeCityId && activeCity) {
        getDarkstores();
      }
    }
  }, [allCityList, activeCityId, activeCity])

  function getCities() {
    setSpinEnable(true);
    product_service.get_city_level_price(props.productId).subscribe((r) => {
      setSpinEnable(false);
      if (!activeCity) setActiveCity(r.response.data[0].name);
      if (activeCityId) getDarkstores(activeCityId);
      setAllCityList(r.response.data);
    }, (err) => {
      console.log("err", err)
    });
  }

  function getDarkstores(id) {
    setDarkstoreSpinEnable(true);
    let code;
    if (id) {
      code = allCityList.find(x => x.id === id).code;
      setActiveCityId(id);
      setActiveCity(allCityList.find(x => x.id === id).name)
    }
    else {
      code = allCityList[0].code;
    }

    product_service.get_darkstores_price(props.productId, code).subscribe((r) => {
      setDarkstoreSpinEnable(false);
      setCityWiseDs(r.response.data)
    }, (err) => {
      console.log("err", err)
    })
  }

  function modalSubmit(params, type) {
    let code = allCityList.filter(x => x.name === params.city_name).map(x => x.code);
    delete params.city_name;
    const payload = {
      ...params,
      by_city: code[0],
      product_id: props.productId
    }
    if (type === 'Edit Single DarkStore') {
      product_service.update_single_darkstore_price(payload).subscribe((r) => {
        cityWiseDs[cityWiseDs.findIndex(x => (x.darkstore_detail.id) === r.response.data.darkstore_id)] = r.response.data
        setCityWiseDs([...cityWiseDs])
        getCities();
        openNotificationWithIcon('success', 'Updated Successfully')
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', 'API Error')
        })
    }
    else {
      product_service.update_all_darkstores_price(payload).subscribe((r) => {
        cityWiseDs[cityWiseDs.findIndex(x => (x.darkstore_detail.id) === r.response.data.darkstore_id)] = r.response.data;
        allCityList[allCityList.findIndex(x => x.code === code)] = r.response.data
        setCityWiseDs([...cityWiseDs])
        setAllCityList([...allCityList]);
        getCities();
        openNotificationWithIcon('success', 'Updated Successfully')
        modalRef.current.handleCancel()
      },
        (err) => {
          console.log(err)
          modalRef.current.stopLoading()
          openNotificationWithIcon('error', 'API Error')
        })
    }
  }

  function showModal(data, type, attr) {
    modalRef.current.showModal(data, type, attr);
  }

  return (
    <div className="layer-nofilter">
      {allCityList ? (
        <div>
          <Row>
            <Col span={12}>
              <CityPriceUpdate spinEnable={spinEnable} activeCity={activeCity} showModal={showModal} getDarkstores={getDarkstores} allCityList={allCityList} />
            </Col>
            <Col span={12}>
              <DarkstorePriceUpdate darkstoreSpinEnable={darkstoreSpinEnable} activeCity={activeCity} showModal={showModal} cityWiseDs={cityWiseDs} />
            </Col>
          </Row>
        </div>)
        : (spinEnable ? <div className="spin-center">
          <Spin tip="...Loading" />
        </div> :
          <div className="no-data">No Data Available</div>)
      }
      <PriceUpdateModal ref={modalRef} modalSubmit={modalSubmit} activeCity={activeCity} />
    </div>
  )
}

export default CityLevelPriceUpdate;

