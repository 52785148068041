import { Http } from '../_modules/http'

export class StatisticsService {

  http = new Http()

  get_sales(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/admin/darkstore_sales_data?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  get_payment(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/mode_of_payment?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  get_refund(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/refund?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date 
                + '&by_darkstore=' + payload.by_darkstore + '&refund_method=' + payload.refund_method
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  get_out_stock(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/most_demanded_sku?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date 
                + '&by_darkstore=' + payload.by_darkstore
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  get_all_delivery_chart(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/all_deliveries_chart?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date 
                + '&by_darkstore=' + payload.by_darkstore
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  get_quality_complaint(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/quality_complaint?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date 
                + '&by_darkstore=' + payload.by_darkstore
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  get_review(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/darkstores_review?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  get_cancelled_orders(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/cancelled_orders?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  get_top_skus(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/top_seller_skus?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date 
                + '&darkstore_id=' + payload.by_darkstore + '&product_id=' + payload.by_product
                + '&limit=' + payload.limit
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  get_top_skus_value(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/top_revenue_skus?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date 
                + '&darkstore_id=' + payload.by_darkstore + '&product_id=' + payload.by_product
                + '&limit=' + payload.limit
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  get_top_customers(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/top_customers?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date 
                + '&by_darkstore=' + payload.by_darkstore
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  get_dea(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/dea_data?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  get_revenue_chart(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/revenue_chart?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date 
                + '&by_darkstore=' + payload.by_darkstore
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  get_customer_signup(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/customer_signups?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date
                + '&darkstore_id=' + payload.by_darkstore
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  get_first_customer_orders(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/first_customer_orders?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  get_out_stock_express(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/express_most_stock_out_products?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date 
                + '&by_darkstore=' + payload.by_darkstore
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  get_delivery_chart(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/deliveries_chart?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date 
                + '&by_darkstore=' + payload.by_darkstore
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  tagging_stats(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/tagging_stats?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date
                + '&by_darkstore=' + payload.by_darkstore + '&parameter_type=' + payload.param_type
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  get_sku_stock_out(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/express_stock_out_product?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date 
                + '&by_darkstore=' + payload.by_darkstore + '&product_id=' + payload.by_product
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  get_discount_chart(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/discount_line_chart?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date
                + '&by_darkstore=' + payload.by_darkstore
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

  get_valet_sales(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/stats/valet_stats?type=' + payload.type 
                + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date
                + '&fraazo_cycle=' + payload.valet_cycle
                + '&by_city=' + `${localStorage.getItem('city') ?? ''}`
    let headers = {...JSON.parse(localStorage.getItem('auth'))}
    return this.http.get(url, headers)
  }

}