import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form } from 'antd';
import { useSelector } from "react-redux";
import FRZSelect from '../../../_controls/FRZSelect';
import FRZInput from '../../../_controls/FRZInput';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import FRZRadioButton from '../../../_controls/FRZRadioButton';


const AdminModal = forwardRef((props, ref) => {
  const { modalSubmit, roles } = props

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState(false)
  const [id, setId] = useState(false)
  const [data, setData] = useState(false)
  const [isClose, setIsClose] = useState(false)
  const darkstore = useSelector(state => state.auth.darkstore);


  useEffect(() => {
    if ((data && title == "Edit Users") || isClose) {
      form.setFieldsValue({ ...data, new_roles: data.get_roles })
      setId(data.id)
    } else {
      setId(false)
    }
    if (isClose) {
      setIsClose(false)
    }
  }, [data])

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setData(false)
      form.resetFields()
      setVisible(false)
      setLoading(false)
      setIsClose(true)
    },

    stopLoading() {
      setLoading(false)
    }

  }));

  function handleCancelModal() {
    setData(false)
    form.resetFields()
    setVisible(false)
    setLoading(false)
    setIsClose(true)
  }

  const handleOnSubmit = (values) => {
    setLoading(true)
    modalSubmit(values, id)
  }

  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">
            {title === 'Add Users' ?
              <FRZSelect DropDownData={darkstore} name="darkstore_id" isRequired={true}
                label="Darkstore Name" placeholder="Select Darkstore Name" value="id" option="name"
                isShowSearch={true} width={"100%"} />
              : null}

            <FRZInput name="first_name" isRequired={true}
              label="First Name" placeholder="First Name" width={200} />

            <FRZInput name="last_name" isRequired={true}
              label="Last Name" width={200} />

            <FRZInput name="email" isRequired={true}
              label="Email" width={200} ofType="email" isDisabled={title === 'Edit Users'} />

            <FRZInputNumber isRequired={true} label="Mobile No" name="mobile_number"
              width={200} isDisabled={title === 'Edit Users'} />

            {title === 'Edit Users' ?
              <FRZRadioButton name="is_verified" label="Is Active" isRequired={true} />
              : null}

            <FRZSelect DropDownData={roles} name="new_roles" isRequired={true}
              label="Roles" option="data" isDataKey={true} isShowSearch={true}
              width={"100%"} mode="tags" />
          </Form>
        </Modal>
        :   <Form
        form={form}
        {...formItemLayout}
        layout="horizontal"
        name="form">
        </Form>}
    </div>
  )
})

export default AdminModal
