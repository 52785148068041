import React, { useEffect, useState } from 'react'
import { Button, Modal, Form, } from 'antd';
import FRZInput from '../../../_controls/FRZInput';
import FRZSelect from '../../../_controls/FRZSelect';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZDatepicker from '../../../_controls/FRZDatepicker';
import FRZRadioButton from '../../../_controls/FRZRadioButton';
import moment from 'moment';

const ModalSpecialDiscount = ({ showModal, handleModalShow, productList, dsListing, submitSpecialDiscount, rowData, loading }) => {
  const [form] = Form.useForm();
  const [isAllStores, setIsAllStores] = useState(undefined)

  useEffect(() => {
    if (rowData) {
      setIsAllStores(rowData.all_stores)
    }
  }, [rowData])

  const onCreate = (value) => {
    submitSpecialDiscount(value)
  }

  const onCancel = () => {
    handleModalShow(false)
  }

  const onChangeAllStore = (value) => {
    setIsAllStores(value)
  }

  return (
    <div>
      <Modal
        visible={showModal}
        title={rowData ? "Update Special Discount" : "Create Special Discount"}
        okText={rowData ? "Update" : "Create"}
        width="50%"
        cancelText="Cancel"
        onCancel={onCancel}
        okButtonProps={{ loading: loading }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onCreate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}>
        <Form
          form={form}
          name="form"
          {...formItemLayout}
          layout="horizontal"
          initialValues={rowData ? {
            ...rowData,
            product_id: rowData.product_details.id,
            valid_from: moment(rowData.valid_from),
            valid_to: moment(rowData.valid_to),
          } : null}
        >
          {console.log("rowData", rowData)}
          {/* <FRZInput label="" name=""/> */}
          <FRZSelect selectDisabled={rowData} DropDownData={productList} name="product_id" isRequired={true}
            label="Product" placeholder="Select Product" value="id" option="name"
            isShowSearch={true} width={300} />

          <FRZDatepicker name="valid_from" label="Valid from" isRequired={true}
            placeholder="Valid from" />

          <FRZDatepicker name="valid_to" label="Valid to" isRequired={true}
            placeholder="Valid to" />
          {/* {rowData ? */}
          <FRZRadioButton name="first_order_flag" label="First Order" isRequired={true} />
          {/* : null} */}

          {/* <FRZRadioButton name="all_stores" label="All Darkstores" isRequired={true} setOnChange={onChangeAllStore} /> */}
          {/* 
          {isAllStores === false ?
            <FRZSelect mode="multiple" DropDownData={dsListing} name="active_stores" isRequired={true}
              label="Darkstore" placeholder="Select Darkstore" value="id" option="name"
              isShowSearch={true} width={400} />
            : null} */}

        </Form>
      </Modal>
    </div>
  )
}

export default ModalSpecialDiscount
