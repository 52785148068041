import React, {useState, useEffect} from 'react';
import { Card, Row, Col, Select, Button, Spin, notification } from 'antd';
import {DownloadService} from '../../_services/downloads';
import { ProductService } from "../../_services/product";
import DarkstoreSales from '../../components/downloads/sales';
import DateWise from '../../components/downloads/datewise';
import Store from '../../components/downloads/store';
import SingularDate from  '../../components/downloads/singulardate';
import DeliveryAppDownloads from '../../components/downloads/deliveryapp';
import MonthlyDownloads from '../../components/downloads/monthly';

const download_service = new DownloadService()
const productService = new ProductService();

const { Option } = Select;

function DownloadList(){
  const [downloadOption, setDownloadOption] = useState(undefined)
  const [spinEnable, setSpinEnable] = useState(false)
  const [d1, setD1] = useState(false)
  const [d2, setD2] = useState(false)
  const [productCategories, setProductCategories] = useState(false);
  const [productSubCategories, setProductSubCategories] = useState(false);

  useEffect(() => {
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if(['admin','developer','super_admin','accounts','operations_download'].includes(data)){
        setD1(true)
      }
    })
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if(['admin','developer','super_admin','accounts','rider_download'].includes(data)){
        setD2(true)
      }
    })
    getProductCategories()
    getProductSubCategories()
  }, [])

  function onChange(val) {
    setDownloadOption(val)
  }

  function getDownloadList(){
    setSpinEnable(true)
    const payload = {
      url: downloadOption
    }
    download_service.get_downloads(payload).subscribe((r) => {
      const linkSource = `data:application/octet-stream;base64,${r.response.csv_data}`;
      const downloadLink = document.createElement("a");
      const fileName = `${downloadOption}_Report_${(new Date).toDateString().replace(/ /g,'_')}.zip`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      openNotificationWithIcon('success','Downloaded Successfully')
      setSpinEnable(false)
    }, (err) => {
      setSpinEnable(false)
      openNotificationWithIcon('error','Download Failed')
    })
  }

  function openNotificationWithIcon(type, msg) {
     notification[type]({ message: type.toUpperCase(), duration: 3, description: msg});
  };

  function getProductCategories() {
    productService.getProductCategories().subscribe(
      (r) => {
        setProductCategories(r.response.data.sort((a, b) => a.name > b.name ? 1 : -1));
      },
      (err) => {
        console.log(err);
      }
    );
  }

  function getProductSubCategories() {
    productService.getProductSubCategories().subscribe(
      (r) => {
        setProductSubCategories(r.response.data.sort((a, b) => a.name > b.name ? 1 : -1));
      },
      (err) => {
        console.log(err);
      }
    );
  }

  return(
    <div className="layer">
      {/* {!spinEnable ?
        <Row>
          <Card>
            <Col span={24}>
              <h3 style={{marginTop: 10, marginBottom: 0, marginLeft: 10}}>Procurement List</h3>
              <Select style={{ width: 200, margin:10}}
                      value={downloadOption}
                      placeholder="Select Download Item" onChange={(e) => onChange(e)}
                      filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                <Option key="download_procurement_list" value="download_procurement_list">
                  Procurement List
                </Option>
              </Select>
              <Button disabled={!downloadOption} onClick={getDownloadList} style={{width: 100, margin:10}}
                      type="primary">Download</Button>
            </Col>
          </Card>
        </Row>
        :
        <div className="spin-center">
          <Spin tip="...Download in Progress"/>
        </div>
      } */}
      <DateWise />
      <DarkstoreSales />
      {d1 && productCategories && productSubCategories?
        <> 
          <Store productCategories={productCategories} productSubCategories={productSubCategories}/>
          <SingularDate productCategories={productCategories} productSubCategories={productSubCategories}/>
        </>  
      : null}
      {d2 ? 
        <DeliveryAppDownloads />
      : null}
      {/* <MonthlyDownloads /> */}
    </div>
  )
}

export default DownloadList
