import React, { useEffect, useState } from 'react';
import MainTable from '../antd/table';
import { Row, Col, Table} from 'antd';
import {customerHistoryColumns} from '../antd/columns/customers';
import NestedOrderTable from './nested_order';

function OrderHistory(props){
  const [columns, setColumns] = useState([])

  useEffect(() => {
    getColumns()
  }, [])

  function getColumns(){
    let temCol = []
    customerHistoryColumns.map(data => {
      temCol.push(data)
    })
    setColumns(temCol)
  }

  function expandedRow(record){
    return <NestedOrderTable data={record.order_number}/>
  }

  return(
    <div>
      <Row>
        <Col span={24}>
          {props.customerDetail.length > 0 ?
            <Table columns={columns} dataSource={props.customerDetail}
                   pagination={true} scroll={{ x: 240 }} expandedRowRender={expandedRow}
                   rowKey="id"/>
          :
            <h3 style={{textAlign: 'center', paddingTop: 24}}>No Order History Found</h3>
          }
        </Col>
      </Row>
    </div>
  )
}

export default OrderHistory
