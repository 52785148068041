import React, { useEffect, useState } from 'react';
import { Layout, Menu, Select } from 'antd';
import { NavLink, useLocation } from "react-router-dom";
import CustomRoutes from '../../_routes/routes';
import { GoogleLogout } from 'react-google-login';
import { GeneralService } from '../../_services/general';
import { getPreLoadData } from '../../_actions';
import { useDispatch } from "react-redux";
// import 'antd/dist/antd.dark.less';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;
const general_service = new GeneralService()

function CustomLayout(props) {
  const [openKeys, setOpenKeys] = useState([])
  const [rootSubmenuKeys, setRootSubmenuKeys] = useState([])
  const [currentKey, setCurrentKey] = useState([])
  const [stateUpdate, setStateUpdate] = useState(false)
  const [cityChanged, setCityChanged] = useState(false)
  const [showOrders, setShowOrders] = useState(true)
  const [todaysOrder, setTodaysOrder] = useState(false)
  const [topStats, setTopStats] = useState(false)
  const [tommsOrder, setTommsOrder] = useState(false)
  const [cityData, setCityData] = useState(false)
  const [selectCity, setSelectCity] = useState('')
  const [dayAfterTommsOrder, setDayAfterTommsOrder] = useState(false)
  const location = useLocation()
  const dispatch = useDispatch();

  const [customRoutes, setCustomRoutes] = useState(false)
  const [renderData, setRenderData] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('roles') !== null) {
      if (!renderData) {
        setRenderData(true)
        if (localStorage.getItem('city') === null) {
          localStorage.setItem('city', '')
        } else {
          setSelectCity(localStorage.getItem('city'))
        }
        getAccessControl(CustomRoutes)
      }
    }
  })

  useEffect(() => {
    if (!localStorage.getItem('cityid') && cityData.length) {
      let cityId = cityData.find((x) => x.code == localStorage.getItem('city'))?.id
      localStorage.setItem('cityid', cityId ? cityId : "")
    }
  }, [cityData])

  useEffect(() => {
    if (localStorage.getItem('roles').includes('offline_partner') || localStorage.getItem('roles').includes('catalog_admin_third_party')) {
      setTodaysOrder(false)
      setTopStats(false)
      setTommsOrder(false)
      setDayAfterTommsOrder(false)
    } else {
      getTodaysOrders()
      getCustomerType()
      setInterval(() => {
        getTodaysOrders()
      }, 60000)
    }
    getDs()
    getCity()
  }, [])

  function getDs() {
    general_service.get_darkstore().subscribe((r) => {
      dispatch(getPreLoadData(r.response.data.sort((a, b) => a.name.localeCompare(b.name)), 'DARKSTORE'))
      setCityChanged(true)
    },
      (err) => {
        console.log(err)
      })
  }

  function getCustomerType() {
    general_service.get_customer_type().subscribe((r) => {
      dispatch(getPreLoadData(r.response.data, 'CUSTOMER_TYPE'))
    },
      (err) => {
        console.log(err)
      })
  }

  function getCity() {
    general_service.get_city().subscribe((r) => {
      setCityData(r.response.data)
      dispatch(getPreLoadData(r.response.data, 'CITY'))
    },
      (err) => {
        console.log(err)
      })
  }

  function getTodaysOrders() {
    general_service.get_todays_order().subscribe((r) => {
      setTodaysOrder(r.response.data.todays_deliveries)
      setTopStats(r.response.data)
      setTommsOrder(r.response.data.tomorrows_deliveries)
      setDayAfterTommsOrder(r.response.data.overmorrow_deliveries)
    },
      (err) => {
        console.log(err)
      })
  }

  function getAccessControl(routes) {
    let accessControl = []
    // let manualDevRoute
    // if (process.env.REACT_APP_API_URL.includes("staging")) {
    //   manualDevRoute = routes.developers
    // } else {
    //   manualDevRoute = {
    //     name: "Developers",
    //     children: [routes.developers.children[0], routes.developers.children[2], routes.developers.children[3]]
    //   }
    // }

    if (localStorage.getItem('roles').includes('offline_partner')) {
      let partnerRoute = {
        name: "Orders",
        children: [routes.orders.children[2]]
      }
      accessControl.push(partnerRoute)
    } else if (localStorage.getItem('roles').includes('catalog_admin_third_party')) {
      let partnerRoute = {
        name: "Master",
        children: [routes.master.children[0]]
      }
      accessControl.push(partnerRoute)
    } else {
      accessControl = [routes.dsstats]

      if (localStorage.getItem('roles').includes('store_admin')) {
        accessControl.push(routes.store, routes.capacity_plan)
      }

      if (localStorage.getItem('roles').includes('coldex')) {
        setShowOrders(false)
      }
      if (localStorage.getItem('roles').includes('category') || localStorage.getItem('roles').includes('category_admin')) {
        if (JSON.parse(localStorage.getItem('roles')).indexOf('admin') > -1 ||
          JSON.parse(localStorage.getItem('roles')).indexOf('developer') > -1 ||
          JSON.parse(localStorage.getItem('roles')).indexOf('super_admin') > -1) {

        } else {
          let ccRoute = {
            name: "Master",
            children: [routes.master.children[0], routes.master.children[3], routes.master.children[4], routes.master.children[5]]
          }
          accessControl.push(routes.dsstats, ccRoute, routes.logs)
        }
      }
      if (localStorage.getItem('roles').includes('capacity_planner')) {
        accessControl.push(routes.capacity_plan)
      }
      if (localStorage.getItem('roles').includes('capacity_planner_admin')) {
        accessControl.push(routes.capacity_plan)
      }
      if (localStorage.getItem('roles').includes('city_head')) {
        accessControl.push(routes.distribution)
      }
      if (localStorage.getItem('roles').includes('developer_admin')) {
        accessControl.push(routes.capacity_plan, routes.society, routes.orders, routes.valet, routes.customers, routes.store, routes.operations, routes.pricing, routes.deliveryapp,
          routes.distribution, routes.master, routes.marketing, routes.segments, routes.sdui, routes.discounts, routes.dunzo, routes.logs, routes.developers)
      }
      if (JSON.parse(localStorage.getItem('roles')).indexOf('admin') > -1) {
        accessControl.push(routes.society, routes.orders, routes.valet, routes.customers, routes.store, routes.operations, routes.pricing, routes.deliveryapp,
          routes.master, routes.marketing, routes.discounts, routes.dunzo, routes.logs)
      }
      if (localStorage.getItem('roles').includes('super_admin')) {
        accessControl.push(routes.capacity_plan, routes.society, routes.orders, routes.valet, routes.customers, routes.store, routes.operations, routes.pricing, routes.deliveryapp,
          routes.distribution, routes.master, routes.marketing, routes.segments, routes.discounts, routes.sdui, routes.dunzo, routes.logs, routes.developers)
      }
      if (localStorage.getItem('roles').includes('developer')) {
        accessControl.push(routes.capacity_plan, routes.society, routes.orders, routes.valet, routes.customers, routes.store, routes.operations, routes.pricing, routes.deliveryapp,
          routes.distribution, routes.master, routes.marketing, routes.segments, routes.discounts, routes.sdui, routes.dunzo, routes.logs, routes.developers)
      }

      if (!localStorage.getItem('roles').includes('developer_admin') &&
        !localStorage.getItem('roles').includes('customer_address_property_editor') &&
        !localStorage.getItem('roles').includes('super_admin')
      ) {
        accessControl.filter((x) => x.name == "Orders").map((data) => {
          data.children.map((itm) => {
            if (itm.key === "CustomerLocation") {
              let index = data.children.indexOf(itm)
              data.children.splice(index, 1)
            }
          })
        })
      }

      if (localStorage.getItem('roles').includes('pricing_executive')) {
        accessControl.push(routes.society, routes.pricing, routes.master)
      }
      if (!localStorage.getItem('roles').includes('developer') &&
        !localStorage.getItem('roles').includes('accounts') &&
        !localStorage.getItem('roles').includes('admin') &&
        !localStorage.getItem('roles').includes('super_admin')
      ) {
        accessControl[0].children.map((e) => {
          if (e.name === "Demand Forecasting") {
            const index = accessControl[0].children.indexOf(e);
            accessControl[0].children.splice(index, 1);
          }
        })
      }

      if (localStorage.getItem('roles').includes('society_sales_manager')) {
        let partnerRoute = {
          name: "Orders",
          children: [routes.orders.children[2]]
        }
        accessControl.push(routes.society, partnerRoute)
      }
      if (localStorage.getItem('roles').includes('customer_support_executive') || localStorage.getItem('roles').includes('customer_support_admin')) {
        accessControl.push(routes.society, routes.orders, routes.valet, routes.customers, routes.dunzo)
      }
      if (localStorage.getItem('roles').includes('marketing') || localStorage.getItem('roles').includes('marketing_admin')) {

        if (JSON.parse(localStorage.getItem('auth')).uid === 'kanupriya@freshvnf.com' ||
          JSON.parse(localStorage.getItem('auth')).uid === 'karthik.sake@fraazo.com') {
          let catRoute = {
            name: "Master",
            children: [routes.master.children[3]]
          }
          accessControl.push(catRoute)
        }

        accessControl.push(routes.orders, routes.valet, routes.customers, routes.marketing, routes.segments, routes.discounts)

      }
      if (localStorage.getItem('roles').includes('accounts')) {

        accessControl.push(routes.society, routes.orders, routes.valet, routes.operations)

      }
      accessControl.splice(10, 0, routes.updown);
    }

    setCustomRoutes([...new Set(accessControl)])
    // console.log([...new Set(accessControl)])
    let newAccess = [...new Set(accessControl)]
    newAccess.map(data => {
      rootSubmenuKeys.push(data.name)
      if (data.children) {
        for (var i = 0; i < data.children.length; i++) {
          if (data.children[i].children) {
            for (var j = 0; j < data.children[i].children.length; j++) {
              if (data.children[i].children[j].path === location.pathname) {
                openKeys.push(data.name)
                openKeys.push(data.children[i].name)
                currentKey.push(data.children[i].children[j].key)
                break
              }
            }
          } else if (data.children[i].path === location.pathname) {
            openKeys.push(data.name)
            currentKey.push(data.children[i].key)
            break
          }
        }
      } else {
        if (data.path === location.pathname) {
          openKeys.push(data.name)
          currentKey.push(data.key)
        }
      }
    })
    setOpenKeys(openKeys)
    setCurrentKey(currentKey)
    setRootSubmenuKeys(rootSubmenuKeys)
    setStateUpdate(true)
  }

  function onOpenChange(keys) {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  function onChangeCity(val) {
    localStorage.setItem('city', val)
    let cityId = cityData.find((x) => x.code == val)?.id
    localStorage.setItem('cityid', cityId ? cityId : "")
    setSelectCity(val)
    setCityChanged(false)
    getTodaysOrders()
    getDs()
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ position: 'fixed', zIndex: 2, width: '100%' }}>
        <span className="fraazo-color"><b>Fraazo </b></span>
        {showOrders && topStats ?
          <>
            {/* <span style={{paddingLeft: 30}}><b>{todaysOrder ? "Today's Orders : " + todaysOrder : null}</b></span> */}
            {/* <span style={{ paddingLeft: 30 }}><b>{"Repeat Orders : " + topStats?.repeat_orders ?? 0}</b></span>
            <span style={{ paddingLeft: 30 }}><b>{"Today's NPS : " + topStats?.today_average_rating + " %" ?? 0}</b></span>
            <span style={{ paddingLeft: 30 }}><b>{"Yesterday's NPS : " + topStats?.yesterday_average_rating + " %" ?? 0}</b></span> */}
            {/* <span style={{paddingLeft: 30}}><b>{tommsOrder ? "Tomorrow's Orders : " + tommsOrder : null}</b></span>
            <span style={{paddingLeft: 30}}><b>{dayAfterTommsOrder ? "Day After Tomorrow's Orders : " + dayAfterTommsOrder : null}</b></span> */}
          </>
          : null}
        {cityData && localStorage.getItem('city') !== null ?
          <span className="city">
            <Select style={{ width: 150 }} value={selectCity}
              placeholder="Select City" onChange={onChangeCity}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              <Option key={''} value={''}>All Cities</Option>
              {cityData.map(data => {
                return (
                  <Option key={data.code} value={data.code}>{data.name}</Option>
                )
              })}
            </Select>
          </span>
          : null}
        <Menu theme="light" mode="horizontal">
          <Menu.Item key={'1'}>
            <GoogleLogout
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              render={renderProps => (
                <button onClick={() => { renderProps.onClick(); props.userlogout() }}
                  disabled={renderProps.disabled} className="sign-out">
                  Logout
                </button>
              )}
              buttonText="Logout"
              onLogoutSuccess={props.responseGoogleLogout}
            />
          </Menu.Item>
        </Menu>
      </Header>
      <Layout>
        {stateUpdate ?
          <>
            <Sider width={220} style={{
              background: 'white', position: 'fixed', zIndex: 1,
              width: '100%', height: 'calc(100vh - 50px)', marginTop: '50px', overflow: 'auto', left: 0
            }}>
              <Menu
                theme="light"
                mode="inline"
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                defaultSelectedKeys={currentKey}
                style={{ width: 220 }}
              >
                {customRoutes.map(data => (
                  (data.children ?
                    <SubMenu
                      key={data.name}
                      title={
                        <span>
                          <span>{data.name}</span>
                        </span>
                      }
                    >
                      {data.children.map(child => (
                        (child.children ?
                          <SubMenu
                            key={child.name}
                            title={
                              <span>
                                <span>{child.name}</span>
                              </span>
                            }
                          >
                            {child.children.map(val => {
                              return (
                                <Menu.Item key={val.key}>
                                  <NavLink to={val.path}>
                                    {val.name}
                                  </NavLink>
                                </Menu.Item>
                              )
                            })}
                          </SubMenu>
                          :
                          <Menu.Item key={child.key}>
                            <NavLink to={child.path}>
                              {child.name}
                            </NavLink>
                          </Menu.Item>
                        )
                      ))}
                    </SubMenu>
                    :
                    <Menu.Item key={data.key}>
                      <NavLink to={data.path}>
                        {data.name}
                      </NavLink>
                    </Menu.Item>
                  )
                ))}
              </Menu>
            </Sider>
            {cityChanged ?
              <Layout style={{ marginTop: 50, marginLeft: 220 }}>
                <Content
                  // style={{
                  //   padding: 24,
                  //   height: `calc(70vh-50px)`,
                  //   overflow: 'initial'
                  // }}
                  className="content-container frz-mt-11"
                >
                  {props.children}
                </Content>
              </Layout>
              : null}
          </>
          : null}
      </Layout>
    </Layout>
  )
}

export default CustomLayout
