import { Http } from '../_modules/http'

export class LogsService {

  http = new Http()

  get_delivery_slot_logs(payload) {
    const url = process.env.REACT_APP_API_URL +
      'store_dashboard/v1/store/darkstore_delivery_slot_log?page='
      + payload.page + '&by_darkstore=' + payload.by_darkstore
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_is_serviceable_logs(payload) {
    const url = process.env.REACT_APP_API_URL +
      'store_dashboard/v1/store/darkstore_serviceable_log?page='
      + payload.page + '&by_darkstore=' + payload.by_darkstore
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_stock_logs(payload) {
    const url = process.env.REACT_APP_API_URL +
      'store_dashboard/v1/store/products_stock_log?page='
      + payload.page + '&by_darkstore=' + payload.by_darkstore
      + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date
      + '&by_name=' + payload.by_sku
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_notification_logs(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/marketing/sent_notification_logs?page='
      + payload.page + '&by_message_type=' + payload.by_message_type + '&by_customer=' + payload.by_customer
      + '&by_mobile_number=' + payload.by_mobile_number
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  download_stock_sheet(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/downloads/darkstore_product_stock_log?by_darkstore=' + payload.by_darkstore
      + '&start_date=' + payload.start_date + '&end_date=' + payload.end_date
      + '&type=' + payload.type + '&by_name=' + payload.by_sku
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  download_slot_sheet(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/downloads/darkstore_delivery_slot_log?by_darkstore=' + payload.by_darkstore
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  resend_notification(id) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/marketing/' + id + '/resend_otp_with_mtalkz'
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.put(url, {}, headers)
  }

  get_log_catalog(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/products/catalog_log?page='
      + payload.page + '&item_type=' + payload.type + "&item_id=" + payload.itemId
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

  get_log_catalog_by_ds(payload) {
    const url = process.env.REACT_APP_API_URL +
      'admin_dashboard/v1/products/darkstores_search?item_type=' + payload.type + "&item_id=" + payload.itemId + "&darkstore_name=" + payload.dsName
    let headers = { ...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json' }
    return this.http.get(url, headers)
  }

}
