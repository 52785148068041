import React, { useEffect, useState, useRef } from "react";
import { LandingClusterService } from '../../../_services/sdui/landing_cluster';
import { ReloadOutlined } from "@ant-design/icons";
import { Row, Card, Col, Button, Table, Pagination, Spin, Input } from "antd";
import { landingColumns } from './columns';
import CreateLanding from "./create_landing";
import { BannersService } from '../../../_services/banners'

const lc_service = new LandingClusterService()
const banner_service = new BannersService()

const initialFilters = {
  page: 1,
  landingSearch: null,
  isSearch: true
}

const initialState = {
  btnDisable: true,
  spinner: false,
  err: false
}

function Landing(props) {
  const modalRef = useRef(null);
  const [landingData, setLandingData] = useState(false)
  const [filters, setFilters] = useState(initialFilters)
  const [state, setState] = useState(initialState)
  const [productList, setProductList] = useState(false)

  useEffect(() => {
    getProductList()
  }, [])

  useEffect(() => {
    filters.isSearch && getLandingList()
  }, [filters])


  const getProductList = () => {
    lc_service.getProductSearch().subscribe((r) => {
      setProductList(r.response.data)
    }, ((err) => {
      console.error({ err })
    }))
  }

  function getLandingList() {
    setState(prevState => ({ ...prevState, spinner: true }))
    // console.log("filters.page", filters.page)
    let urlParam = `page=${filters.page}&by_type_of_cluster=products&by_title=${filters.landingSearch ?? ''}`
    lc_service.getLandingList(urlParam).subscribe((r) => {
      setState(prevState => ({ ...prevState, spinner: false, total: r.response.meta.total_pages * 50, err: false }))
      setLandingData(r.response.data)
    }, (err) => {
      setState(prevState => ({ ...prevState, spinner: false, err: true }))
      setLandingData([])
    })
  }

  function onChangeFilters(value, type, searchNow) {
    setFilters(prevState => ({ ...prevState, [type]: value, isSearch: searchNow }))
    setState(prevState => ({ ...prevState, btnDisable: false }))
  }

  return (
    <div className="layer">
      {landingData && productList ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <Input placeholder="Search Landing" onChange={(e) => onChangeFilters(e.target.value, "landingSearch", false)}
                  value={filters.landingSearch} className="frz-w-200 frz-m-10" />
                <Button disabled={state.btnDisable} onClick={() => { onChangeFilters(1, 'page', true) }}
                  className="frz-btn" type="primary">Search</Button>
                <Button disabled={state.btnDisable} onClick={() => { setFilters(initialFilters); setState(initialState) }}
                  type="primary" className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
                <span className="fl-right">
                  <Button onClick={() => modalRef.current.showModal(true, 'Create Landing Page')} className="frz-btn"
                    type="primary">
                    Create Landing Page</Button>
                </span>
              </Col>
            </Card>
          </Row>
          {landingData.length > 0 ?
            <Spin spinning={state.spinner}>
              <Row>
                <Col span={24}>
                  <Card className="frz-mt-5">
                    <Table dataSource={landingData} columns={landingColumns((rowdata) => modalRef.current.showModal(rowdata, 'Edit Landing Page'))} pagination={false}
                      scroll={{ y: 'calc(100vh - 331px)' }} rowKey={'id'} />
                  </Card>
                  <Pagination current={filters.page} pageSize={50} total={state.total} className="frz-mt-5"
                    onChange={(e) => onChangeFilters(e, 'page', true)} showSizeChanger={false} />
                </Col>
              </Row>
            </Spin>
            :
            <div className="no-data">{state.err ? 'Backend API System Down' : 'No Data Available'}</div>
          }
          <CreateLanding ref={modalRef} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  )
}

export default Landing