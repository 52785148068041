import React, { useEffect, useState, useRef } from 'react';
import {Spin, Button, Card, Row, Col, InputNumber, Pagination, Switch, Table, DatePicker, 
        Checkbox, Popconfirm} from 'antd';
import { ReloadOutlined, EditOutlined, DownloadOutlined } from '@ant-design/icons';
import { OperationsService } from '../../../_services/operations';
import Search from '../../../components/antd/search';
import MainTable from '../../../components/antd/table';
import { poColumns, productGrnColumns } from '../../../components/antd/columns/operations/category';
import AddProductModal from '../../../components/operations/category/product_modal';
import UploadBills from '../../../components/operations/category/upload_bill';
import { openNotificationWithIcon } from '../../../_modules/notification';
import FrzDynamicDownload from '../../../_controls/FRZDynamicDownload';
import moment from 'moment';
import ViewDetails from '../../../components/operations/category/ViewDetails';

const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const ops_service = new OperationsService()

function GrnList() {
  const modalRef = useRef(null);
  const detailModalRef = useRef(null);

  const modalRefProducts = useRef(null);
  const [grnData, setGrnData] = useState(false)
  const [renderData, setRenderData] = useState(false)
  const [current, setCurrent] = useState(1)
  const [columns, setColumns] = useState([])
  const [columnsProducts, setColumnsProducts] = useState([])
  const [payIds, setPayIds] = useState([])
  const [spinEnable, setSpinEnable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [nodes, setNodes] = useState(false)
  const [suppliers, setSuppliers] = useState(false)
  const [products, setProducts] = useState(false)
  const [status] = useState([{ id: true, name: 'Active' }, { id: false, name: 'Inactive' }])
  const [payments] = useState([{ id: 'online', name: 'Online' }, { id: 'cash', name: 'Cash' }])
  const [paymentLoaders, setPaymentLoaders] = useState(false) 
  const [operations, setOperations] = useState(false)
  const [showPay, setShowPay] = useState(false)
  const [allowEdit, setAllowEdit] = useState(false)
  const [fetchColm, setFetchColm] = useState(false) 
  const [state, setState] = useState({ valueByNode: undefined, valueBySupplier: undefined, valueByOrder: undefined,
                                       start_date: '', end_date: '', showDateValue: false, valueByStatus: undefined,
                                       valueByPayments: undefined, valueByProductCode: undefined});
  const { valueByNode, valueBySupplier, valueByOrder, start_date, end_date, 
          showDateValue, valueByStatus, valueByPayments, valueByProductCode } = state

  useEffect(() => {
    if (!renderData) {
      setRenderData(true)
      getGrn()
    }
  })

  useEffect(() => {
    getNodes()
    getSuppliers()
    getProducts()

    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if(['admin','developer','super_admin','procurement_admin','procurement','cc_admin','coldex','warehouse_admin','category','category_admin'].includes(data)){
        setOperations(true)
      }
    })
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if(['developer','super_admin','admin','procurement_admin'].includes(data)){
        setShowPay(true)
      }
    })
    JSON.parse(localStorage.getItem('roles')).forEach(data => {
      if(['developer','super_admin','procurement_admin','admin'].includes(data)){
        setAllowEdit(true)
      }
    })
    setFetchColm(true)
  }, [])

  useEffect(() => {
    if(fetchColm){
      setGrnData(fetchColm)
      setFetchColm(false)
      getColumns()
    }
  })

  function getNodes() {
    const payload = {
      nodeType: 'CC'
    }
    ops_service.get_node_listing(payload).subscribe((r) => {
      setNodes(r.response.data)
    },
    (err) => {
      console.log(err)
    })
  }

  function getSuppliers() {
    ops_service.get_suppliers().subscribe((r) => {
      setSuppliers(r.response.data)
    },
    (err) => {
      console.log(err)
    })
  }

  function getProducts() {
    ops_service.get_product_list().subscribe((r) => {
      setProducts(r.response.data)
    },
    (err) => {
      console.log(err)
    })
  }

  function getGrn(onEdit) {
    setSpinEnable(true)
    setGrnData([])
    if(payIds.length > 0 || onEdit){
      setPayIds([])
      setColumns([])
      setColumnsProducts([])
    }
    const payload = {
      page: current,
      by_node: !valueByNode ? '' : valueByNode,
      by_supplier: !valueBySupplier ? '' : valueBySupplier,
      by_order_number: !valueByOrder ? '' : valueByOrder,
      start_date: start_date,
      end_date: end_date,
      by_status: valueByStatus === undefined ? '' : valueByStatus,
      admin_id: JSON.parse(localStorage.getItem('auth')).id,
      by_product: valueByProductCode ?? ''
    }

    // JSON.parse(localStorage.getItem('roles')).forEach(data => {
    //   if(['category','coldex'].includes(data)){
    //     payload.admin_id = JSON.parse(localStorage.getItem('auth')).id
    //   }
    // })

    ops_service.get_grn_list(payload).subscribe((r) => {
      setState(prevState => ({ ...prevState, total: r.response.meta.total_pages * 50 }))
      setSpinEnable(false)
      if(payIds.length > 0 || onEdit){
        setFetchColm(r.response.data)
      }else{
        setGrnData(r.response.data)
      }
    },
      (err) => {
        console.log(err)
      })
  }
  
  function showDetailModal(data, type) {
    detailModalRef.current.showDetailModal(type, data)
  }

  const handleOnViewDetails = (values, type)=>{
    showDetailModal(values, type)
  }

  function getColumns() {
    poColumns.map(data => {
      columns.push(data)
    })
    columns.unshift(
      {
        title: 'Pay', 
        dataIndex: '',
        key:'',
        render:(record) => {
          return(record.is_active && record.can_initiate_payment ?
            <div>
              <Checkbox onChange={(e) => onChangeCheckbox(e.target.checked, record)} disabled={!showPay}/>
            </div>
          : null
          )
        } 
      },
    )
    columns.push({
      title: 'Payments Status',
      dataIndex: 'payment_status',
      key: 'payment_status',
      align: 'center',
    },
    {
      title: 'Allow Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return(record.is_active ?
          <div>
            <Switch checked={record.is_editable} onChange={(e) => onChangeEdit(e, record.id)} disabled={!allowEdit}/> 
          </div>
        : null
        )
      }
    },
    {
      title: 'Upload Bills',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return(record.is_active ?
          <div>
            <Button onClick={() => uploadBills(record)} type="primary" 
                    disabled={record.payment_status === 'paid'}>
              Upload Bills
            </Button>  
          </div>
        : null
        )
      }
    },
    {
      title: 'View Details',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return <Button title="View Details" disabled={!record.payment_status}
          onClick={() => handleOnViewDetails(record, "View Details")}>
          View Details
        </Button>
      }
    },
    {
      title: 'Downloads',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
                return(
                  <div>
                    {record.invoice_url || record.grn_image_url ? 
                      <Popconfirm placement="leftTop" title='Select Download Document Type?' 
                                  onConfirm={() => downloadURL(record.invoice_url, 'Invoice')} 
                                  onCancel={() => downloadURL(record.grn_image_url, 'GRN')}
                                  okText="Invoice" cancelText="GRN">
                        <Button type="primary" icon={<DownloadOutlined />}></Button>
                      </Popconfirm>
                      : null
                    }
                  </div>
                )

      }
    })  
    productGrnColumns.map(data => {
      columnsProducts.push(data)
    })
    columnsProducts.push({
      title: 'Edit',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (record) => {
        return <Button title="Edit" icon={<EditOutlined />} disabled={!record.is_editable || !operations}
                       onClick={() => showModalProducts(record, 'Edit Graded Qty')}>
              </Button>
      }
    })
    setColumnsProducts(columnsProducts)
    setColumns(columns)
  }

  function downloadURL(url, type) {
    if(url){
      window.open(url, '_blank');
    }else{
      openNotificationWithIcon('error','No Documents Found For ' + type)
    }
  }

  function onChangeCheckbox(value, record) {
    if (value === true) {
      payIds.push(record.id);
      setPayIds(payIds)
    }else{
      const index = payIds.indexOf(record.id);
      if (index > -1) {
        payIds.splice(index, 1);
      }
      setPayIds(payIds)
    }
  }

  function createPayments(){
    if(payIds.length === 0 || !valueByPayments){
      openNotificationWithIcon('error', "Please Select PO's & Payment Method")
    }else{
      setPaymentLoaders(true)
      const payload = {
        po_ids: payIds,
        admin_id: JSON.parse(localStorage.getItem('auth')).id,
        mode_of_payment: valueByPayments
      }
      ops_service.create_payments(payload).subscribe((r) => {
        openNotificationWithIcon('success','Payment Created Successfull')
        setState(prevState => ({ ...prevState, valueByPayments: undefined }))
        getGrn()
        setPaymentLoaders(false)
      },
      (err) => {
        console.log(err)
        setPaymentLoaders(false)
        openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
      })
    }
  }

  function onChangeEdit(value, id) {
    setSpinEnable(true)
    setGrnData([])
    const payload = {
      id: id,
      is_editable: value
    }
    ops_service.allow_edit_grn(payload).subscribe((r) => {
      openNotificationWithIcon('success','Update Successfull')
      getGrn(true)
    },
    (err) => {
      console.log(err)
      getGrn(true)
      openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
    })
  }

  function onChange(value, type) {
    setState(prevState => ({ ...prevState, [type]: value }))
    if(type !== 'valueByPayments'){
      setDisable(false)
      setCurrent(1)
    }
  }

  function reset() {
    setState({valueByNode: undefined, valueBySupplier: undefined, valueByOrder: undefined,
              start_date: '', end_date: '', showDateValue: false, valueByStatus: undefined, 
              valueByPayments: undefined, valueByProductCode: undefined})
    setDisable(true)
    setCurrent(1)
    setRenderData(false)
  }

  function pagination(page) {
    setCurrent(page)
    setRenderData(false)
  }

  function expandedRow(record) {
    let productsList = record.line_items.map(v => ({...v, is_editable: record.is_editable, po_id: record.id}))
    return <>
      <Card className="nested-table">
        <Table columns={columnsProducts} pagination={false} rowKey="id" dataSource={productsList} />
      </Card>
    </>
  }

  function showModalProducts(data, type) {
    modalRefProducts.current.showModal(type, data)
  }

  function modalSubmitProduct(payloadData, poid, id){
    const payload = {...payloadData, purchase_order_id: poid, po_line_item_id: id}
    ops_service.grn_products(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Success')
      getGrn()
      modalRefProducts.current.handleCancel()
    },
    (err) => {
      console.log(err)
      modalRefProducts.current.stopLoading()
      openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
    })
  }

  function onChangeRangePicker(value, dateString){
    setState(prevState =>({...prevState, showDateValue: true, start_date :dateString[0], end_date:dateString[1]}))
    setDisable(false)
    setCurrent(1)
  }

  function disabledDate(current){
    return current && (current < moment().subtract(91, "day") || current > moment().add(10, "day"));
  }

  function uploadBills(data){
    modalRef.current.showModal('Upload Bills', data)
  }

  function modalSubmitBills(payloadData){
    const payload = {...payloadData}
    ops_service.upload_bills(payload).subscribe((r) => {
      openNotificationWithIcon('success', 'Uploaded Successfully')
      getGrn()
      modalRef.current.handleCancel()
    },
    (err) => {
      console.log(err)
      modalRef.current.stopLoading()
      openNotificationWithIcon('error', err?.response?.errors[0] ?? 'API Error')
    })
  }

  return (
    <div className="layer">
      {grnData && nodes && suppliers && products ?
        <div>
          <Row>
            <Card>
              <Col span={24}>
                <RangePicker onChange={onChangeRangePicker} className="frz-m-10"
                             value={showDateValue ? [moment(start_date, dateFormat),
                             moment(end_date, dateFormat)] : '' } format={dateFormat}
                             allowClear={false} disabledDate={disabledDate}/>
                <Search placeholder="Select Nodes" value={valueByNode} data={nodes}
                        onChange={(e) => onChange(e, 'valueByNode')} type="valueByCategory" />
                <Search placeholder="Select Suppliers" value={valueBySupplier} data={suppliers} 
                        onChange={(e) => onChange(e, 'valueBySupplier')}  type="valueByCategory" />
                <Search placeholder="Select Status" value={valueByStatus} data={status}
                        onChange={(e) => onChange(e, 'valueByStatus')}  type="valueByCategory" />
                <Search placeholder="Select Product" value={valueByProductCode} data={products}
                        onChange={onChange} type="valueByProductCode" />
                <InputNumber
                  placeholder="Order No"
                  onChange={(e) => onChange(e, "valueByOrder")}
                  value={valueByOrder}
                  className="frz-width-150p frz-m-10" />
                <Button disabled={disable} onClick={getGrn}
                  className="frz-w-100px frz-m-10" type="primary">Search</Button>
                <Button disabled={disable} onClick={reset} type="primary"
                  className="frz-m-10" ghost shape="circle" icon={<ReloadOutlined />} />
              </Col>
            </Card>
          </Row>
          <Row>
            <Card>
              <Col span={24}>
                <Search placeholder="Select Payment" value={valueByPayments} data={payments}
                        onChange={(e) => onChange(e, 'valueByPayments')}  type="valueByCategory" />
                <Button onClick={createPayments} className="frz-dwn" type="primary" loading={paymentLoaders}>
                  Create Payments
                </Button> 
                <span className="fl-right">
                  <FrzDynamicDownload name={'GRN'} fileName={'GRN'} url={'admin_dashboard/procurement/grn_report'}
                                      payload={'?by_node='+`${valueByNode ?? ''}`+'&by_supplier='+`${valueBySupplier ?? ''}`
                                              +'&by_order_number='+`${valueByOrder ?? ''}`+'&start_date='+`${start_date}`
                                              +'&end_date='+`${end_date}`+'&by_status='+`${valueByStatus === undefined ? '' : valueByStatus}`
                                              +'&admin_id='+`${JSON.parse(localStorage.getItem('auth')).id}`+'&hide='
                                              +`${JSON.parse(localStorage.getItem('roles')).includes('coldex')}`} 
                                      type={'job'} reportFunc={'zapper_report'} jobReportFunc={'zapper_job_report'} base={'zapper'}/>                         
                </span>
              </Col>
            </Card>
          </Row>
          {grnData.length > 0 ?
            <Row>
              <Col span={24}>
                <MainTable dataSource={grnData} columns={columns} expandedRow={expandedRow}/>
                <Pagination className="frz-tm-10 frz-bm-10" current={current}
                  pageSize={50} total={state.total} onChange={pagination}
                  showSizeChanger={false} />
              </Col>
            </Row>
            : (disable || spinEnable ?
              (spinEnable ?
                <div className="spin-center"><Spin tip="...Loading" /></div>
                :
                <div className="no-data">No Data Available</div>)
              :
              <div className="no-data">No Data Available On Selected Filters</div>)
          }
          <AddProductModal modalSubmit={modalSubmitProduct} ref={modalRefProducts}/>
          <UploadBills modalSubmit={modalSubmitBills} ref={modalRef} />
          <ViewDetails ref={detailModalRef} />
        </div>
        :
        <div className="spin-center">
          <Spin tip="...Loading" />
        </div>
      }
    </div>
  );
}

export default GrnList