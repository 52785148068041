import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Radio, Button, InputNumber } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'

function ImsJ(props) {
  const onFormChange = () => {
    props.handleOnFormChange(true)
  }

  return (
    <div className="frz-ims-box">
      <Form
        form={props.form}
        layout="horizontal"
        name="form"
        onChange={() => onFormChange()}
      >
        <Row>
          <Col span={12}>
            <div className="frz-ims-text">Rain Surge Incentive (J)</div>
          </Col>
          <Col span={12}>
            <div className="">
              <Form.List name="J"
              // initialValue={[{}]}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name }, index) => (
                      <div
                        key={key}
                        className="frz-dis-flex frz-justify-end frz-ims-placeholder"
                      >
                        <Form.Item
                          name={[name, 'min_order_count']}
                          label={''}
                          rules={[{ required: true, message: 'count required' }]}
                        >
                          <InputNumber
                            placeholder="Min Orders"
                            width={100}
                            className="frz-w-100px"
                          />
                        </Form.Item>

                        <div className="frz-lp-10"></div>

                        <Form.Item
                          name={[name, 'min_login_hours']}
                          label={''}
                          rules={[{
                            required: true,
                            message: 'Please enter login hrs'
                          }]}
                        >
                          <InputNumber
                            placeholder="Min Login Hrs"
                            width={140}
                            className="frz-w-140px"
                          />
                        </Form.Item>

                        <div className="frz-lp-10"></div>

                        <Form.Item
                          name={[name, 'percent']}
                          label={''}
                          rules={[{
                            required: true,
                            message: 'Please enter percentage',
                          }]}
                        >
                          <InputNumber
                            placeholder="Percentage of earning (%)"
                            width={200}
                            className="frz-w-200"
                          />
                        </Form.Item>

                        <div className="frz-lp-10"></div>

                        <div className="frz-tp-5">
                          {/* {index != 0 && */}
                          <>
                            <MinusCircleOutlined className='frz-lp-10' onClick={() => remove(name)} />
                          </>
                          {/* } */}
                        </div>
                      </div>
                    ))}
                    <div className="frz-ims-add" onClick={() => add()}>
                      + Add Field
                    </div>
                  </>
                )}
              </Form.List>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default ImsJ
