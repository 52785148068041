import React, { useEffect, useState, useCallback } from 'react';
import { Table, Card, Spin } from 'antd';
import { DarkstoreService } from '../../../_services/darkstore';
import { openNotificationWithIcon } from '../../../_modules/notification.js';

const darkstore_service = new DarkstoreService();

const AttendanceTable = ({ nestedCol, nestedTableData, valueByRoles, valueByPnpVendors }) => {
  const [cityWiseColm, setCityWiseColm] = useState(false);
  const [updateNestedRow, setUpdateNestedRow] = useState(false);
  const [spinEnable, setSpinEnable] = useState(false);

  useEffect(() => {
    if (!updateNestedRow) {
      setUpdateNestedRow(false)
      cityWiseAttendance(nestedTableData);
    }
  }, [updateNestedRow, cityWiseColm]);


  function cityWiseAttendance(nestedTableData) {
    setSpinEnable(true)
    setUpdateNestedRow(true);
    const rolesPayload = [];
    rolesPayload.push(valueByRoles);

    const payload = {
      date: !nestedTableData ? '' : nestedTableData.date,
      roles: !valueByRoles ? [] : rolesPayload,
      pnp_vender_id: !valueByPnpVendors ? '' : valueByPnpVendors,
    }

    darkstore_service.get_city_wise_attendance(payload).subscribe((r) => {
      setSpinEnable(false);
      setCityWiseColm(r.response.data);
    },
      (err) => {
        console.log(err);
        openNotificationWithIcon('error', err?.response?.errors ?? "API Error")
      }
    );
  }

  return (
    <div>
      {cityWiseColm.length > 0 ?
        (<Card className="nested-table">
          <Table columns={nestedCol}
            pagination={false} rowKey="city"
            scroll={{ x: 240}} dataSource={cityWiseColm}
          />
        </Card>
        )
        : (spinEnable ?
          <div className="spin-center"><Spin tip="...Loading" /></div>
          :
          <div className="no-data">No Data Available</div>
        )}
    </div>
  )
}

export default AttendanceTable;
