import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { Modal, Form } from 'antd';
import FRZInput from '../../../_controls/FRZInput';
import FRZInputNumber from '../../../_controls/FRZInputNumber';
import { formItemLayout } from '../../../_modules/controllayout';
import FRZSelect from '../../../_controls/FRZSelect';
import FRZRadioButton from '../../../_controls/FRZRadioButton';

const CCWHModal = forwardRef((props, ref) => {
  const { modalSubmit } = props
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [id, setId] = useState(false)
  const [form] = Form.useForm();
  const [isClose, setIsClose] = useState(false)

  useEffect(() => { 
    if ((data && title == "Edit")) {
      let adminData = []
      data.cc_admin.map((e) => {
        adminData.push(e.id) 
      })
      form.setFieldsValue({ ...data, cc_admins: adminData })
      setId(data.id)
    } else {
      setId(false)
    }
  }, [data])

  function handleOnSubmit(values) {
    setLoading(true)
    modalSubmit(values, id)
  }

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      setIsClose(true)
      setData(false)
      form.resetFields()
    },

    stopLoading() {
      setLoading(false)
    }

  }));


  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    setIsClose(true)
    setData(false)
    form.resetFields()
  }
  
  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            <FRZInput name="name" isRequired={true} label="Name" placeholder="Name" />

            <FRZInput name="code" isRequired={true} label="Code" placeholder="Code" isDisabled={title === "Edit"}  />

            <FRZSelect DropDownData={props.nodeTypeData} name="node_type" isRequired={true}
              label="Node Type" placeholder="Node Type" value="value" option="option"
              isShowSearch={true} width={"100%"} selectDisabled={title === "Edit"} />
            
            <FRZSelect DropDownData={props.city} name="city_id" isRequired={true}
              label="City" placeholder="City" value="id" option="name"
              isShowSearch={true} width={200} />

            <FRZInput name="address" isRequired={true} label="Address" placeholder="Address"
              isTextArea={true} />

            <FRZInputNumber name="latitude" isRequired={true}
              label="Latitude" placeholder="Latitude" width={200} />

            <FRZInputNumber name="longitude" isRequired={true}
              label="Longitude" placeholder="Longitude" width={200} />

            <FRZInput name="POC_name" isRequired={true} label="POC Name" placeholder="POC Name" />

            <FRZInputNumber name="POC_contact" isRequired={true}
              label="POC Contact" placeholder="POC Contact" width={200} />

            <FRZRadioButton name="is_active" label="Is Active" isRequired={true} />

            {/* <FRZRadioButton name="is_virtual" label="Is Virtual" isRequired={true} /> */}

            <FRZRadioButton name="is_hub" label="Is Hub" isRequired={true} />

            <FRZSelect mode="multiple" DropDownData={props.managedBy} name="cc_admins"
              label="Managed By" placeholder="Managed By" value="id" option="admin_name"
              isShowSearch={true} width={"100%"}/>

          </Form>
        </Modal>
        : <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>}
    </div>
  )
})

export default CCWHModal