import { Http } from '../_modules/http'

export class SlotService {

  http = new Http()

  get_delivery_slot(){
    const url = process.env.REACT_APP_API_URL + 'admin_dashboard/v1/campaigns/delivery_slot'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.get(url, headers)
  }

  update_delivery_slot(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/campaigns/delivery_slot/' + payload.id
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.put(url, payload.body, headers)
  }

  create_delivery_slot(payload){
    const url = process.env.REACT_APP_API_URL +
                'admin_dashboard/v1/campaigns/delivery_slot'
    let headers = {...JSON.parse(localStorage.getItem('auth')), 'Content-Type': 'application/json'}
    return this.http.post(url, payload.body, headers)
  }

}
