import React from 'react'
export const bannerColumns = [
  {
    title: "Banner Name",
    dataIndex: "banner_name",
    key: "banner_name",
    align: "center",
  },
  {
    title: "Is Active",
    dataIndex: "",
    key: "",
    align: "center",
    render: (record) => {
      return record.is_active.toString()
    }
  },
  {
    title: "Sequence",
    dataIndex: "sequence",
    key: "sequence",
    align: "center",
  },
  {
    title: 'Is For First Order',
    dataIndex: 'first_order_flag',
    key: 'first_order_flag',
    align: 'center',
    render: (e) =>
      <div>
        {e ? "Yes" : "No"}
      </div>
  }
]
