import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react'

import { Modal, Form, Button } from 'antd';
import { formItemLayout } from '../../../_modules/controllayout';

import FRZInput from '../../../_controls/FRZInput';
import FRZDatepicker from '../../../_controls/FRZDatepicker';
import FRZSelect from '../../../_controls/FRZSelect';
import FRZCustomRadioButon from '../../../_controls/FRZCustomRadioButon';
import FRZColorPickerTemp from '../../../_controls/FRZColorPickerTemp';

import moment from 'moment';
import { BannersService } from '../../../_services/banners';
import FRZUpload from '../../../_controls/FRZUpload';
import { LoadingOutlined, PlusOutlined, CopyOutlined } from '@ant-design/icons';

import { openNotificationWithIcon } from '../../../_modules/notification';


const banner_service = new BannersService();

const PromotionPageModal = forwardRef((props, ref) => {
  const { modalSubmit } = props
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(false)
  const [form] = Form.useForm();
  const [templateType] = useState([{ value: 'row_boxes', option: 'Row Boxes' },
  { value: 'row_boxes_with_banner', option: 'Row Boxes With Banner' }])
  const [templateTypeVal, setTemplateTypeVal] = useState(false)
  const [imageState, setImageState] = useState({})
  const [clusterState, setClusterState] = useState({})
  const [pickerBGColor, setPickerBGColor] = useState('#FFFFFF')
  const [pickerFontColor, setPickerFontColor] = useState('#000000')
  const [spinEnable, setSpinEnable] = useState(false)
  const [products, setProducts] = useState(false)

  function getProducts(id) {
    setSpinEnable(true)
    banner_service.get_cluster_items(id).subscribe((r) => {
      let newResponse = r.response.data.cluster_promotional_pages
      let newResponse1 = r.response.data
      setPickerBGColor(newResponse1.background_color)
      setPickerFontColor(newResponse1.font_color)
      setProducts(newResponse1)
      setSpinEnable(false);
      setTemplateTypeVal(newResponse1.template)

      form.setFieldsValue({
        ...data,
        cluster_promotional_pages_attributes: newResponse.map(a => a.cluster_tag),
        start_at: data.start_at === null ? "" : moment(data.start_at),
        end_at: data.end_at === null ? "" : moment(data.end_at),
        body_type: newResponse1.body_type,header_type:newResponse1.header_type,
      })
    },
      (err) => {
        console.log(err)
      })

  }

  useEffect(() => {
    if (data && title == "Edit") {
      getProducts(data.id)
    }
    setPickerBGColor('#FFFFFF')
    setPickerFontColor('#000000')
    setImageState({})
    setClusterState({})
  }, [data])

  function handleOnSubmit(values) {
    setLoading(true)
    const payload = {
      ...values, start_at: moment(values.start_at).format("YYYY-MM-DD"), end_at: moment(values.end_at).format("YYYY-MM-DD"),
      background_color: pickerBGColor ? pickerBGColor : null,
      font_color: pickerFontColor ? pickerFontColor : null,
      ...clusterState
    }
    if(title === 'Edit'){
      if(payload.cluster_promotional_pages_attributes.find(x => x.cluster_tag)){
        payload.cluster_promotional_pages_attributes = payload.cluster_promotional_pages_attributes
      }else{
        payload.cluster_promotional_pages_attributes = payload.cluster_promotional_pages_attributes.map(x => ({cluster_tag: x}))
      }
    }
    if (imageState.image) {
      delete payload.image
      modalSubmit({ ...payload, image_attributes: imageState }, data?.id ?? null)
    } else {
      delete payload.image
      modalSubmit(payload, data?.id ?? null)
    }
  }

  useImperativeHandle(ref, () => ({

    showModal(value, data) {
      setVisible(true)
      setData(data)
      setTitle(value)
    },

    handleCancel() {
      setVisible(false)
      setLoading(false)
      setData(false)
      setProducts(false)
      form.resetFields()
    },

    stopLoading() {
      setLoading(false)
    }
  }));

  function handleCancelModal() {
    setVisible(false)
    setLoading(false)
    setData(false)
    form.resetFields()
    setProducts(false)
  }

  function disabledDate(current) {
    return current && current < moment().add(-1, "day");
  }

  function handleOnChangeType(value) {
    setTemplateTypeVal(value)
  }

  const handleOnChangeImage = (type, item) => {
    setImageState(prevState => ({ ...prevState, [type]: item }))
  }

  const handleOnChangeCluster = (item) => {
    let newResponse = item.map(x => ({ cluster_tag: x }))
    setClusterState(prevState => ({ ...prevState, cluster_promotional_pages_attributes: newResponse }))
  }

  function handleClick({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  const uploadButton = (
    <div>
      {props.imgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  function changePickerBGColor(colors) {
    setPickerBGColor(colors)
  }

  function changePickerFONTColor(colors) {
    setPickerFontColor(colors)
  }

  function copyToClipboard(text){
    navigator.clipboard.writeText(text)
    openNotificationWithIcon('success', 'Text Copied To Clipboard')
  }
  
  return (
    <div>
      {data ?
        <Modal
          visible={visible}
          title={title}
          okText="Submit"
          width={'40%'}
          okButtonProps={{ loading: loading }}
          onOk={() => {
            form
              .validateFields()
              .then(values => {
                handleOnSubmit(values)
              })
              .catch(info => {
                // console.log('Validate Failed:', info);
              });
          }}
          onCancel={handleCancelModal}>
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            name="form">

            <FRZInput name="title" isRequired={true}
              label="Promotion Name" placeholder="Promotion Name" width={200} />

            <FRZInput name="header_type" isRequired={false}
              label="Header Type" placeholder="Header Type" width={200} />

            <FRZInput name="body_type" isRequired={false}
              label="Body Type" placeholder="Body Type" width={200} />

            <FRZColorPickerTemp
              label="Background Color"
              value={pickerBGColor}
              pickerColor={pickerBGColor}
              changePickerColor={changePickerBGColor}
              setPickerColor={setPickerBGColor}
              columnSpan={7}
              isRequired={true}
            />

            <FRZColorPickerTemp
              label="Font Color"
              value={pickerFontColor}
              pickerColor={pickerFontColor}
              changePickerColor={changePickerFONTColor}
              setPickerColor={setPickerFontColor}
              columnSpan={7}
              isRequired={true}
            />

            <FRZSelect setOnChange={handleOnChangeCluster} mode="multiple" DropDownData={props.clutserTags} 
              name="cluster_promotional_pages_attributes" isRequired={true}
              label="Select Cluster" placeholder="Select Cluster" 
              isShowSearch={true} breakOption={true} width={300}
            />

            <FRZSelect DropDownData={props.status} name="status" isRequired={true}
              label="Status" placeholder="Select Status"
              isShowSearch={true} breakOption={true} width={300}
            />

            <FRZCustomRadioButon name="template" label="Template" isRequired={true}
              optionData={templateType} option="option" value="value" setOnChange={handleOnChangeType} />

            {templateTypeVal === 'row_boxes_with_banner' ?
              <>
                <FRZUpload isRequired={true} name="image" className="avatar-uploader" customRequest={handleClick}
                  imgWidth={"100%"} label={"Upload Banner"} listType="picture-card" setOnChangeType={"image"}
                  showUploadList={false} uploadButton={uploadButton} handleOnChangeImage={handleOnChangeImage}
                  imageState={imageState} />
              </>
              : null}

            <FRZDatepicker placeholder="Select Date" label="Start At"
              name="start_at" isRequired={true} disabledDate={disabledDate} />

            <FRZDatepicker placeholder="Select Date" label="End At"
              name="end_at" isRequired={true} disabledDate={disabledDate} />

            {products?.short_link_data ? 
              <div className='frz-search-layer'>
                <h3 className='frz-tp-20'>
                  Use below keys and values on AppsFlyer custom params for Onelink, copy paste it as it is.
                </h3>  
                {Object.keys(products.short_link_data).map(function(key, index) {
                  return(
                    <div className='frz-tp-10' key={index}>
                      <Button type="primary" ghost icon={<CopyOutlined />} 
                              onClick={() => copyToClipboard(key)} 
                              size="small" className='frz-rm-10'></Button>
                      {key} &nbsp; : &nbsp; <b>{products.short_link_data[key]}</b> 
                      <Button type="primary" ghost icon={<CopyOutlined />} 
                              onClick={() => copyToClipboard(products.short_link_data[key])} 
                              size="small" className='frz-lm-10'></Button>
                    </div>
                  )
                })}
              </div>
            : null}

          </Form>
        </Modal>
        : <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          name="form">
        </Form>
      }
    </div>
  )
})

export default PromotionPageModal
