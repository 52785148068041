import React, { useState, useEffect } from 'react'
import ColorPicker from 'rc-color-picker'
import {Form, Input} from 'antd'
import 'rc-color-picker/assets/index.css';

const FRZColorPicker = ({changePickerColor, pickerColor, columnSpan, label, value}) => {
    
    const onchangeHandler = (color) =>{
        changePickerColor(color.color)
    }

    return (
        <div style={{ textAlign: 'start', justifyContent:"center", alignContent:"center", marginTop: "1em", marginBottom: "1em" }}>
       <Form
       colon={false}
       labelCol={{ span: columnSpan }}
       >
           <Form.Item 
           style={{ marginBottom: 0 }}
           label={<div className="block" style={{marginLeft: 0, color:"rgba(0, 0, 0, 0.65)"}}>{label} :</div>}>
        <div style={{marginTop:5}}>
        <ColorPicker
            animation="slide-up"
            color={value === undefined ? pickerColor : value}
            onChange={onchangeHandler}
        />
        </div>
        </Form.Item>
        </Form>
        </div>
    )
}

export default FRZColorPicker
