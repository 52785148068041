import React from 'react'

export const stateColumn = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center'
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    align: 'center',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    align: 'center',
  },
  {
    title: 'GST No',
    dataIndex: 'gst_no',
    key: 'gst_no',
    align: 'center',
  }
]

export const cityColumn = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center'
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    align: 'center',
  },
  {
    title: 'is_active',
    dataIndex: 'is_active',
    key: 'is_active',
    align: 'center',
    render: (record) => {
      return (
        record ? "Yes" : "No"
      )
    }
  }
]